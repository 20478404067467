import axios from 'axios'
import authHeader from './auth-header'
// import moment from 'moment'

class EstudAIService {

  getMaterias() {
    return axios.get(process.env.VUE_APP_API_URL + 'solucoes/escolares/conteudos/get/materias', { headers: authHeader() })
  }

  getAnos() {
    return axios.get(process.env.VUE_APP_API_URL + 'solucoes/escolares/conteudos/get/anos', { headers: authHeader() })
  }

  getConteudosProgramaticos( data ) {
    const idMateria = data.materia ? data.materia : 0
    const periodoLetivo = data.periodoLetivo ? data.periodoLetivo : 0
    const descricao = data.descricao ? data.descricao : 0

    return axios.get(process.env.VUE_APP_API_URL + `solucoes/escolares/conteudos/programaticos/${idMateria}/${periodoLetivo}/${descricao}`, { headers: authHeader() })
  }

  getTopicos ( idConteudoProgramatico ) {
    return axios.get(process.env.VUE_APP_API_URL + `solucoes/escolares/conteudos/topicos/${idConteudoProgramatico}`, { headers: authHeader() })
  }

  refineConteudo ( data ) {
    const idConteudo = data.idConteudo
    const tipo = data.tipo
    const texto = data.texto ? data.texto : 0
    const prompt = data.prompt ? data.prompt : 0

    return axios.get(process.env.VUE_APP_API_URL + `solucoes/escolares/conteudos/edit/${idConteudo}/${tipo}/${texto}/${prompt}`, { headers: authHeader() })
  }

  saveConteudo ( data ) {
    const idConteudo = data.idConteudo
    const tipo = data.tipo
    const texto = data.texto

    return axios.post(process.env.VUE_APP_API_URL + `solucoes/escolares/conteudos/edit/${idConteudo}/${tipo}`, { texto: texto }, { headers: authHeader() })
  }

  getPrompts () {
    return axios.get(process.env.VUE_APP_API_URL + 'solucoes/escolares/prompts/list', { headers: authHeader() })
  }

  regerarConteudo (idConteudo,idPrompt) {
    console.log("idConteudo:",idConteudo)
    console.log("idPrompt:",idPrompt)

    return axios.get(process.env.VUE_APP_API_URL + `solucoes/escolares/conteudos/regerar/todos/${idConteudo}/${idPrompt}`, { headers: authHeader() })
  }

  savePrompt (data) {
    return axios.post(process.env.VUE_APP_API_URL + `solucoes/escolares/conteudos/prompts/create`, data, { headers: authHeader() })
  }

  getAssistants () {
    return axios.get(process.env.VUE_APP_API_URL + `assistants/list`, { headers: authHeader() })
  }

  createAssistant ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + `assistants/create`, data, { headers: authHeader() })
  }

  updateAssistant ( data ) {
    return axios.put(process.env.VUE_APP_API_URL + `assistants/edit`, data, { headers: authHeader() })
  }

  addFilesToVector ( idAssistant, data ) {
    var headers = authHeader()
        headers['Content-Type'] = 'multipart/form-data'

    console.log("headers:", headers)
    return axios.post(process.env.VUE_APP_API_URL + `assistants/enviar/arquivos/${idAssistant}`, data, { headers: headers })

  }

  getFilesFromVector ( idAssistant ) {
    return axios.get(process.env.VUE_APP_API_URL + `assistants/list/files/${idAssistant}`, { headers: authHeader() })
  }

  removeFilesFromVector ( idFile ) {
    return axios.delete(process.env.VUE_APP_API_URL + `assistants/remover/arquivos/${idFile}`, { headers: authHeader() })
  }
}

export default new EstudAIService()
