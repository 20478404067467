<template>
  <section class="mat-main">
    <header>
      <p>Etapa {{ step.index + 1 }} de {{ totalSteps }}</p>
      <h4>Envio de Documentos</h4>
      <p>Atualize os documentos da matrícula, caso falte algum documento você poderá anexá-lo neste momento.</p>
    </header>
    <section class="form-wrap" v-if="matricula">
      <documentos :id="idMatricula" :validation="validation" ref="documentos" v-if="idMatricula" :auto="auto" :action="action"></documentos>
      <div class="integraAlert frame nano" v-else>
        <fa :icon="['fal','face-meh']" class="icon"></fa>
        <h1 class="color-text">Ops. Ainda não da pra você ver os documentos.</h1>
        <p>Antes de mais nada, você precisa integrar o Aluno e o Responsável Financeiro. Se você ainda não fez, volte a estas etapas e as conclua primeiro.</p>
      </div>
    </section>
  </section>
</template>

<script>
import Documentos from '@/components/matriculas/utilities/Documentos'

export default {
  components: {
    Documentos
  },
  props: {
    idMatricula: {
      type: [String, Number]
    },
    respFin: {
      type: [String, Number]
    },
    validation: {
      type: Boolean
    },
    auto: {
      type: Boolean,
      default: false
    },
    action: {
      type: String
    },    
    step: {
      type: Object
    },
    totalSteps: {
      type: Number
    }
  },
  data () {
    return {
      matricula: null
    }
  },
  mounted () {
    console.log('idMatricula:', this.idMatricula)
    console.log('respFin:', this.respFin)

    // console.log("validation in middle component:", this.validation)
    this.$store.dispatch('matricula/get').then(response => {
      console.log("matricula:", response)
      this.matricula = response

      this.$emit('ready')
    })
  },
  methods: {
    validate () {
      return this.$refs.documentos.validate()
      // return { response: true }
    }
  }
}
</script>

<style lang="scss" scoped>
  .integraAlert {
    margin-top: $mg;
    text-align: center;

    .icon {
      font-size: 64px;
      text-align: center;
      margin-bottom: $hmg;
    }
  }
</style>