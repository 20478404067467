<template>
  <controller>
    <section class="home">
      <!-- <div class="frame mid">
        <atividades-list></atividades-list>
      </div> -->

      <!-- <div>
        <dashboard-atividades></dashboard-atividades>
      </div> -->
      <div>
        <atividades-list></atividades-list>
      </div>
      <!-- <div>
        <paineis-favoritos></paineis-favoritos>
      </div> -->
      <div>
        <favoritos></favoritos>
      </div>
    </section>    
  </controller>
</template>

<script>
// import PaineisFavoritos from '@/components/navigation/PaineisFavoritos.vue'
import Favoritos from '@/components/utilities/favorites/Favorites.vue'
// import DashboardAtividades from '@/components/atividades/utilities/HomeDashboard.vue'
import AtividadesList from '@/components/atividades/utilities/HomeList.vue'

export default {
  name: 'Home',
  components: {
    AtividadesList, Favoritos
    // PaineisFavoritos,  DashboardAtividades
  },
  data (){
    return {
      hasFastMenu: false
    }
  },
  computed: {
    currentUser() {
      return localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null
    }
  },
  mounted () {

  },
  methods: {
    fastMenuActive (active) {
      // console.log("hasFastMenu - active:", active)
      this.hasFastMenu = active
    }
  }
}

</script>
<style lang="scss" scoped>
  .home {
    width: 100%;
    display: flex; gap: $hmg_mid;
    align-items: flex-start; align-content: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: $hmg_mid $hmg_mid $mg $hmg_mid;
    
    @media screen and (max-width: 1024px) {
      gap: $hmg
    }

    >div {
      flex: 1;
      position: sticky; top: $hmg_mid;

      @media screen and (max-width: 1024px) {
        position: relative; top: 0;
      }

      &:nth-child(1) {
        width: 42%;
        max-width: 880px
      }
      &:nth-child(2) {
        width: 18%;
        max-width: 260px;
      }
      // &:nth-child(3) {
      //   width: 18%;
      //   max-width: 260px;
      // }
      // &:nth-child(4) {
      //   width: 18%;
      //   max-width: 260px;
      // }
    }

    @media screen and ( max-width: 1024px ) {
      > div {
        max-width: 100% !important;
        min-width: 100% !important;
        width: 100% !important;
      }
    }
  }
</style>