<template>
  <controller :goToTopButton="true">
    
  </controller>
</template>

<script>
export default {
  name: "Produtos"
}
</script>

<style lang="scss">

</style>