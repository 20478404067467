<template>
  <section class="mat-main">
    <header>
      <p>Etapa {{ step.index + 1 }} de {{ totalSteps }}</p>
      <h4>Ficha de Saída</h4>
      <p>Atualize as permissões de saída e mantenha atualizado quem pode retirar a criança da escola.</p>
    </header>
    <section class="form-wrap">
      <h5 class="color-text">{{ step.index + 1 }}.1. Autorização de Saída</h5>
      <saida ref="saida" v-if="loaded" :action="action"></saida>
    </section>
    <section class="form-wrap">
      <h5 class="color-text">{{ step.index + 1 }}.2. Pessoas Autorizadas a Retirar o Aluno</h5>
      <pessoas-autorizadas ref="autorizadas" v-if="loaded" :action="action"></pessoas-autorizadas>
    </section>
    <section class="form-wrap">
      <h5 class="color-text">{{ step.index + 1 }}.3. Vale Transporte</h5>
      <vale-transporte ref="vtrans" v-if="loaded" :action="action"></vale-transporte>
    </section>
  </section>
</template>

<script>
import Saida from '@/components/matriculas/form/Saida'
import PessoasAutorizadas from '@/components/matriculas/form/PessoasAutorizadas'
import ValeTransporte from '@/components/matriculas/form/ValeTransporte'
import MatriculaService from '@/services/matricula.service'

export default {
  components: {
    Saida, PessoasAutorizadas, ValeTransporte
  },
  props: {
    ready: {
      type: Boolean,
      default: false
    },
    idMatricula: {
      type: [String, Number]
    },
    action: {
      type: String
    },
    step: {
      type: Object
    },
    totalSteps: {
      type: Number
    }
  },
  data () {
    return {
      matricula: null,
      loaded: false
    }
  },
  methods: {
    validate () {
      const saidaValid = this.$refs.saida.validate()
      const autorizadasValid = this.$refs.autorizadas.validate()
      
      const valid = saidaValid.response && autorizadasValid.response

      console.log('saidaValid:', saidaValid)
      console.log('valid:', valid)
      console.log('autorizadasValid:', autorizadasValid)
      
      if ( !saidaValid.response || !autorizadasValid.response ) {
        var invalids = []

        if ( saidaValid.valids ) {
          Object.keys(saidaValid.valids).map(k => {
            if ( !saidaValid.valids[k] ) {
              invalids.push(k)
            }
          })
        }
        if ( autorizadasValid.valids ) {
          Object.keys(autorizadasValid.valids).map(k => {
            if ( !autorizadasValid.valids[k] ) {
              invalids.push(k)
            }
          })
        }
        
        var msg = invalids.join(', ')
        window.toaster.emit('open', {style: "error", message: "Existem campos não preenchidos: <b>" + msg + "</b>", floater: true})
      }

      return {response: valid , valids: invalids}
    },
    get () {
      this.$store.dispatch('matricula/get').then(response => {
        this.matricula = response
        // console.log("get alunos - matricula response:", response)
        // console.log("idMatricula:", this.idMatricula)
        if ( this.idMatricula ) {
          MatriculaService.getFichaSaida( this.idMatricula ).then(
            response => {
              console.log("getFichaSaida:", response)

              if ( response.data && Object.keys(response.data).length > 0 ) {
                this.matricula.fichaSaida.autorizacaoSaida = response.data.autorizacaoSaida ? response.data.autorizacaoSaida : this.matricula.fichaSaida.autorizacaoSaida
                this.matricula.fichaSaida.autorizacaoRetirarAluno = response.data.autorizacaoRetirarAluno && response.data.autorizacaoRetirarAluno[0]?.nome ? response.data.autorizacaoRetirarAluno : this.matricula.fichaSaida.autorizacaoRetirarAluno
                this.matricula.fichaSaida.transporteEscolar = response.data.transporteEscolar ? response.data.transporteEscolar : this.matricula.fichaSaida.transporteEscolar
                this.matricula.fichaSaida.valeTransporte = response.data.valeTransporte ? response.data.valeTransporte : this.matricula.fichaSaida.valeTransporte

                console.log("autorizacaoSaida 1:", this.matricula.fichaSaida.autorizacaoSaida)

                if ( this.matricula.fichaSaida.autorizacaoSaida.idTipoAutorizacaoSaida ) {
                  this.matricula.fichaSaida.autorizacaoSaida.idTipoAutorizacaoSaida = this.matricula.fichaSaida.autorizacaoSaida.idTipoAutorizacaoSaida.toString()
                  this.matricula.fichaSaida.autorizacaoSaida.mae = this.matricula.fichaSaida.autorizacaoSaida.mae == 1
                  this.matricula.fichaSaida.autorizacaoSaida.outros = this.matricula.fichaSaida.autorizacaoSaida.outros == 1
                  this.matricula.fichaSaida.autorizacaoSaida.pai = this.matricula.fichaSaida.autorizacaoSaida.pai == 1
                  this.matricula.fichaSaida.autorizacaoSaida.responsavelFinanceiro = this.matricula.fichaSaida.autorizacaoSaida.responsavelFinanceiro == 1
                  this.matricula.fichaSaida.autorizacaoSaida.responsavelPedagogico = this.matricula.fichaSaida.autorizacaoSaida.responsavelPedagogico == 1

                  // console.log("autorizacaoSaida 2:", this.matricula.fichaSaida.autorizacaoSaida)
                }

                if ( this.matricula.fichaSaida.valeTransporte ) {
                  this.matricula.fichaSaida.valeTransporte.bom = this.matricula.fichaSaida.valeTransporte.bom == 1
                  this.matricula.fichaSaida.valeTransporte.naoSolicitado = this.matricula.fichaSaida.valeTransporte.naoSolicitado == 1
                  this.matricula.fichaSaida.valeTransporte.sptrans = this.matricula.fichaSaida.valeTransporte.sptrans == 1

                  delete this.matricula.fichaSaida.valeTransporte.idValeTransporte
                }
                
              }

              this.$store.dispatch('matricula/save').then(() => {
                this.$emit('ready')
                this.$nextTick(() => {
                  this.loaded = true
                })
              })
              
            },
            error => {
              console.error(error.response.data.message)
            }
          )
        } else {
          this.loaded = true
          this.$emit('ready')
        }   
      })      
    },
  }
}
</script>

<style lang="scss" scoped>
  header {
    padding: 0 $hmg_small $hmg;
  }
  .form-wrap {
    h5 {
      padding: 0 0 $hmg_small $hmg_small;
    }
  }
</style>