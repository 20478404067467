<template>
  <section>
    <div class="row no-padd">
      <div class="col-md-12 form-group no-padd-left" v-if="matricula">
        <p class="subtitle">Escolha uma das opções abaixo <fa :icon="['fas', 'asterisk']" class="color-primary" /></p>
        <ol class="saidaSelect">
          <li :class="['verde', { invalid: !valid.idTipoAutorizacaoSaida }]">
            <div>
              <label :class=" { checked: matricula.fichaSaida.autorizacaoSaida.idTipoAutorizacaoSaida == '1' } ">
                <input 
                  type="radio" 
                  value="1"
                  v-model="matricula.fichaSaida.autorizacaoSaida.idTipoAutorizacaoSaida" 
                  name="idTipoAutorizacaoSaida" 
                  @change="valid.idTipoAutorizacaoSaida = true"
                  :disabled="action == 'ver'"
                  required
                >
                <p>Verde - O aluno está autorizado a sair sozinho ao término das aulas, provas e simulados.</p>
              </label>
            </div>
          </li>
          <!-- <li :class="['amarelo', { invalid: !valid.idTipoAutorizacaoSaida }]">
            <div>
              <label :class=" { checked: matricula.fichaSaida.autorizacaoSaida.idTipoAutorizacaoSaida == '2' } ">
                <input 
                  type="radio"
                  value="2"
                  v-model="matricula.fichaSaida.autorizacaoSaida.idTipoAutorizacaoSaida"  
                  name="idTipoAutorizacaoSaida" 
                  @change="valid.idTipoAutorizacaoSaida = true" 
                  required
                >
                <p>Amarelo - O aluno está autorizado a sair sozinho, desde que o responsável comunique através do portal da família.</p>
              </label>
            </div>
          </li> -->
          <li :class="['vermelho', { invalid: !valid.idTipoAutorizacaoSaida }]">
            <div>
              <label :class=" { checked: matricula.fichaSaida.autorizacaoSaida.idTipoAutorizacaoSaida == '3' } ">
                <input 
                  type="radio"
                  value="3"
                  v-model="matricula.fichaSaida.autorizacaoSaida.idTipoAutorizacaoSaida"  
                  name="idTipoAutorizacaoSaida"  
                  @change="valid.idTipoAutorizacaoSaida = true" 
                  required
                  :disabled="action == 'ver'"
                >
                <p>Vermelho - O aluno não poderá sair sozinho em nenhum momento.</p>
              </label>
            </div>
          </li>
          <li :class="['azul', { invalid: !valid.idTipoAutorizacaoSaida }]">
            <div>
              <label :class=" { checked: matricula.fichaSaida.autorizacaoSaida.idTipoAutorizacaoSaida == '4' } ">
                <input 
                  type="radio"
                  value="4"
                  v-model="matricula.fichaSaida.autorizacaoSaida.idTipoAutorizacaoSaida"  
                  name="idTipoAutorizacaoSaida" 
                  @change="valid.idTipoAutorizacaoSaida = true" 
                  required
                  :disabled="action == 'ver'"
                >
                <p>Azul - O aluno irá de transporte escolar.</p>
              </label>
            </div>
          </li>
        </ol>
      </div>
    </div>
    <div class="row no-padd" ref="condutorForm">
      <transition-group name="fadein">
        <template v-if="matricula && matricula.fichaSaida.autorizacaoSaida.idTipoAutorizacaoSaida == 4">
          <div class="col-md-4 form-group no-padd-left" key="item" >
            <label>Nome do Condutor</label>
            <input-control
              v-model="matricula.fichaSaida.transporteEscolar.nomeCondutor"
              placeholder="Nome do Condutor" 
              name="nomeCondutor"
              type="text"
              :valid="valid.nomeCondutor"
              :disabled="loadingInputContent || action == 'ver'" 
              @keyup="valid.nomeCondutor = true"
              required
            />
          </div>
          <div class="col-md-3 form-group no-padd-left" key="item">
            <label>Telefone do Condutor</label>
            <input-control
              v-model="matricula.fichaSaida.transporteEscolar.telefoneCondutor"
              placeholder="(11) 9 8765 4321"
              name="telefoneCondutor"
              type="text"
              :valid="valid.telefoneCondutor"
              :disabled="loadingInputContent || action == 'ver'" 
              @keyup="valid.telefoneCondutor = true"
              @keydown="addCountryCode('telefoneCondutor', $event)"
              required
              masking="celular"
            />
          </div>
        </template>
      </transition-group>
    </div>
  </section>
</template>

<script>
import FormService from '@/services/form.service'

export default {
  props: {
    action: {
      type: String
    }
  },
  data () {
    return {
      matricula: null,
      valid: {
        idTipoAutorizacaoSaida: true,
        nomeCondutor: true,
        telefoneCondutor: true
      },
      loadingInputContent: false
    }
  },
  mounted () {
    this.$store.dispatch('matricula/get').then(response => {
      console.log("matricula:", response)
      this.matricula = response
    })
  },
  methods: {
    validate () {
      const idAutorizacaoSaida = this.matricula.fichaSaida.autorizacaoSaida.idTipoAutorizacaoSaida
      const formValid = FormService.validate(this.$refs.condutorForm, this.valid)

      if ( idAutorizacaoSaida && formValid.response ) {
        return { response: true , valids: this.valid }
      } else {
        if ( !idAutorizacaoSaida ) this.valid.idTipoAutorizacaoSaida = false

        return { response: false, valids: this.valid }
      }
    },
    addCountryCode ( src, e ) {
      var target = this.matricula.fichaSaida.transporteEscolar[src]
      const iniCode = '+55'
      // console.log("key:", e.key)
      const escape = ['Enter', 'Backspace', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Tab', 'Escape']
      const allowAddCode = !escape.includes(e.key)


      // console.log("target.length:", target.length)
      
      if ( !target && allowAddCode ) {
        const newValue = iniCode + target
        console.log("newValue:", newValue)

        // this.matricula.fichaSaida.transporteEscolar.telefoneCondutor

        this.matricula.fichaSaida.transporteEscolar[src] = newValue
        // target = iniCode + target
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .saidaSelect {
    width: 100%;
    padding: 0; margin: 0;

    li {
      list-style: none;
      width: 100%; padding: $hmg_small;
      display: flex; align-items: center; align-content: center;
      
      > div {
        display: flex; align-items: center; align-content: center;
        position: relative; 
        z-index: 101;

        label {
          display: flex; align-items: center;
          padding: 0 0 0 30px; margin: 0;
          position: relative;

          p {
            line-height: 1; padding: 0; margin: 0;
            font-weight: normal; font-size: 13px;
          }

          &::after {
            content: "";
            display: block; width: 20px; height: 20px;
            position: absolute; z-index: 103;
            left: 0; top: 7px; transform: translateY(-50%);
            border: 3px solid;
            box-sizing: border-box;
            border-radius: 100%;
            z-index: 100;
          }
          &::before {
            content: "";
            border-radius: 100%;
            left: 5px; top: 7px; transform: translateY(-50%);
            display: block; width: 10px; height: 10px;
            position: absolute; background-color: $color-secondary;
            z-index: 101;
            @extend %transition_3o;
          }

          input {
            appearance: none;
          }
        }
      }

      &.verde {
        label {
          &::after {
            border-color: $color-awesome;
          }
          &.checked {
            &::before {
              background-color: $color-awesome;
            }
          }
          
        }
      }

      &.amarelo {
        label {
          &::after {
            border-color: $color-good;
          }
          &.checked {
            &::before {
              background-color: $color-good;
            }
          }
          
        }
      }

      &.vermelho {
        label {
          &::after {
            border-color: $color-bad;
          }
          &.checked {
            &::before {
              background-color: $color-bad;
            }
          }
        }
      }

      &.azul {
        label {
          &::after {
            border-color: $color-success;
          }
          &.checked {
            &::before {
              background-color: $color-success;
            }
          }
          
        }
      }

      &.invalid {
        background-color: $color-neutro;
        
        &:first-child {
          border-top-left-radius: $border-radius;
          border-top-right-radius: $border-radius;
        }
        &:last-child {
          border-bottom-left-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
        }
      }
    }
  }

  .subtitle {
    font-weight: bold;
    padding-left: $hmg_small;
  }
</style>