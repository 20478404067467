<template>
  <controller>
    <div class="page-inner">
      
      <input type="text" v-model="newNodeText" @keyup.enter="addNewNode" />
      <button @click.prevent="confirmSync()">Remover Selecionados</button>
      <br />
      <br />
      <br />

      <div class="row no-padd" >

        <Draggable ref="tree" class="mtl-tree" :ondragend="onDragEnd" v-model="treeData" treeLine>
          <template #default="{ node }">
            <input type="checkbox" v-model="node.selected">
            <span class="mtl-ml">{{ node.index }} - {{ node.text }}</span>
          </template>
        </Draggable>
        <confirm 
      :backdrop="false"
      class="confirm"
      ref="confirm"
    ></confirm>
      </div>
    </div>
  </controller>
</template>

<script>
import { Draggable } from '@he-tree/vue'
import '@he-tree/vue/style/default.css'
import FerramentasService from '@/services/ferramentas.service'

export default {
  components: {
    Draggable
  },
  data() {
    return {
      treeData: [],
      newNodeText: '',
      config: {
        draggable: true
      }
    };
  },
  mounted () {
    this.getIndicadoresTreeView()
  },
  methods: {
    confirmSync () {
      this.$refs.confirm.run({
        message: "Tem certeza que deseja remover os itens selecionados?",
        confirmButton: "Sim",
        denyButton: "Não",
        callback: this.sync,
      })
    },
    sync ( response ) {

      if ( response ) {

        this.onDragEnd()

      }


    },
    addNewNode() {
      if (this.newNodeText.trim() === '') return;
      const newNode = { text: this.newNodeText, idIndicador: null, idIndicadorAntecessor: null, selected: false };
      this.$refs.tree.add(newNode, this.$refs.tree.rootChildren[0], this.$refs.tree.rootChildren[0].children.length);
      this.newNodeText = '';
      this.addIndexesToTree(this.treeData);
      console.log('addIndexesToTree:', this.treeData);
    },
    addIndexesToTree(treeData, parentIndex = '') {
      treeData.forEach((node, index) => {
        const currentIndex = parentIndex === '' ? `${index + 1}` : `${parentIndex}.${index + 1}`;
        node.index = `${currentIndex}`;
        if (node.children) {
          this.addIndexesToTree(node.children, currentIndex);
        }
      });
    },
    removeSelectedNodesRecursively(nodes) {
      return nodes.reduce((acc, node) => {
        if (node.selected) {
          // Se o nó for selecionado, não o adicionamos ao novo array
          return acc;
        } else if (node.children) {
          // Se o nó tiver filhos, removemos os filhos selecionados e mantemos os não selecionados
          const children = this.removeSelectedNodesRecursively(node.children);
          if (children.length > 0) {
            acc.push({ ...node, children });
          }
        } else {
          // Se o nó não for selecionado e não tiver filhos, o mantemos
          acc.push(node);
        }
        return acc;
      }, []);
    },
    onDragEnd() {
      console.log('Drag end:', this.treeData);
      this.addIndexesToTree(this.treeData);
      FerramentasService.updateIndicadores(this.treeData).then(
        response => {
          console.log("updateIndicadores:", response.data)
          this.getIndicadoresTreeView()
        }
      )
    },
    getIndicadoresTreeView() {
      FerramentasService.getIndicadoresTreeView().then(
        response => {
          console.log("getIndicadoresTreeView:", response.data)
          this.treeData = response.data
          this.addIndexesToTree(this.treeData);
        }
      )
    },
  }
};
</script>

<style lang="scss" scoped>
.page-inner {
  margin-bottom: $mg_extra;

  @media screen and ( max-width: 640px) {
    padding: $hmg 0 !important;
  }

  .page-inner {
    header {
      // padding: $hmg_small $hmg 0;
    }
  }
}

.gatilho {
  @extend %transition_4e;
}
</style>
