<template>
  <div class="empty">
    <p>{{ message }}</p>
  </div>
</template>
<script>
export default {
  name: "Empty",
  props:{
    message: {
      type: String,
      default: 'Ops. Não tem nada para mostrar aqui.'
    }
  }
}
</script>
<style lang="scss" scoped>
  .empty {
    display: flex; flex: 0; width: auto;
    margin: $mg auto 0;
    align-content: center; align-items: center; justify-content: center;

    p {
      display: table; width: auto; 
      text-align: center;
      padding: .4em 1em; 
      border-radius: 3em;
      background-color: #FFF;
    }
    
  }
</style>