<template>
   <controller>
      <div class="page-title">
        <h3 class="text-capitalize">Planilhas Atividades</h3>
      </div>
      <div class="page-inner">
        <planilhas-controller tipo="atividades"></planilhas-controller>
      </div>
    </controller>
</template>

<script>
import PlanilhasController from '@/components/planilhas/template/PlanilhasController'

export default {
  components: {
    PlanilhasController
  },
  data () {
    return {

    }
  }
}
</script>

<style>

</style>