<template>
  <controller>
    <section class="frame mini text-center">
      <fa :icon="['fal','face-meh']" class="icon"></fa>
      <h4>Você não tem permissão para acessar esta página</h4>
      <a href="#" @click.prevent="goToHome()" class="btn btn-primary">
        Ir para a página inicial
      </a>
    </section>
  </controller>
</template>

<script>
export default {
  name: 'Forbidden',
  data () {
    return {

    }
  },
  methods: {
    goToHome () {
      this.$router.push("/")
    }
  }
}
</script>

<style>

</style>