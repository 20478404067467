import axios from 'axios'
import authHeader from './auth-header'

class PlanilhasService {
  getTipoPlanilhas ( tipo ) {
    return axios.get(process.env.VUE_APP_API_URL + 'sheets/get/tipoplanilha/' + tipo,  { headers: authHeader() })
  }

  getPlanilhas ( idTipoPlanilha ) {
    return axios.get(process.env.VUE_APP_API_URL + 'sheets/get/all/' + idTipoPlanilha,  { headers: authHeader() })
  }

  getPlanilha ( idPlanilha ) {
    return axios.get(process.env.VUE_APP_API_URL + 'sheets/get/planilha/' + idPlanilha,  { headers: authHeader() })
  }
  
  syncPlanilha ( idPlanilha ) {
    return axios.post(process.env.VUE_APP_API_URL + 'sheets/sync/planilha/' + idPlanilha , {},  { headers: authHeader() })
  }

  createPlanilha ( planilha ) {
    return axios.post(process.env.VUE_APP_API_URL + 'sheets/create', planilha, { headers: authHeader() })
  }

  removePlanilha ( idPlanilha ) {
    return axios.delete(process.env.VUE_APP_API_URL + 'sheets/delete/planilha/' + idPlanilha, { headers: authHeader() })
  }
}

export default new PlanilhasService()