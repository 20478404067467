<template>
  <controller :goToTopButton="true">
    <div class="page-title">
      <h4 class="text-capitalize">Editar Compras {{ checkout ? ' - ' + checkout?.idcheckoutMarketplace : '' }}</h4>
      <template v-if="details">
        <a href="#" class="btn btn-secondary" @click.prevent="goBack()">Voltar</a>
      </template>
    </div>
    <transition name="slideup">
      <div class="page-inner" v-if="!details">
        <div class="recompra-filter frame mini">
          <input-spinner v-if="loading" class="ac-spinner"/>
          <a href="#" v-else @click.prevent="searchCheckouts(true)"><fa :icon="['far', 'search']" class="icon" ></fa></a>
          <input-control placeholder="Busque por Responsável" v-model="query" cssClass="large" @keyup.enter="searchCheckouts()" :readonly="loading"/>
        </div>
        <div class="recompra-list">
          <empty v-if="!loading && list.length == 0 && searched" message="Não encontramos checkouts para este reponsável"></empty>
          <checkout-list :list="list" @getDetails="getDetails"></checkout-list>
        </div>
      </div>
    </transition>
     <transition name="slideup">
      <div class="page-inner" v-if="details && checkout">
        <div class="row no-padd">
          <div class="col-md-6">
            <div class="row no-padd">
              <div class="form-group col-md-12 sessionTitle">
                <h4>Detalhes do Consumidor</h4>
                <h5>Dados Pessoais</h5>
              </div>
              <div class="form-group col-md-8">
                <label>Nome</label>
                <input-control v-model="checkout.nome" placeholder="Ex. João da Silva" readonly/>
              </div>
              <div class="form-group col-md-4">
                <label>CPF</label>
                <input-control v-model="checkout.cpf" masking="cpf" placeholder="000.000.000-00" readonly/>
              </div>
              <div class="form-group col-md-4">
                <label>Telefone</label>
                <input-control v-model="checkout.telefone" masking="telefone" placeholder="+55 11 9 8888 7777" readonly/>
              </div>
              <div class="form-group col-md-8">
                <label>Email</label>
                <input-control v-model="checkout.email" placeholder="pessoa@email.com" readonly/>
              </div>
              <div class="form-group col-md-12 sessionTitle">
                <h5>Endereço</h5>
              </div>
              <div class="form-group col-md-8">
                <label>Endereço</label>
                <input-control v-model="checkout.endereco" readonly/>
              </div>
              <div class="form-group col-md-4">
                <label>Complemento</label>
                <input-control v-model="checkout.complemento" readonly/>
              </div>
              <div class="form-group col-md-4">
                <label>CEP</label>
                <input-control v-model="checkout.cep" readonly/>
              </div>
              <div class="form-group col-md-6">
                <label>Cidade</label>
                <input-control v-model="checkout.cidade" readonly/>
              </div>
              <div class="form-group col-md-2">
                <label>UF</label>
                <input-control v-model="checkout.UF" readonly/>
              </div>
            </div>
            <div class="row no-padd">
              <div class="form-group col-md-12 sessionTitle">
                <h4>Transações</h4>
                <h5>Parcelas</h5>
              </div>
              <div class="form-group col-md-12" v-if="transacoes.parcelas.length > 0">
                <datatable
                  :headers="transacoes.headers" 
                  :data="transacoes.parcelas"
                  :keys="transacoes.keys"
                  :startLoader="false"
                  :hasNew="false"
                  :compact="true"
                ></datatable>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group col-md-8">
              <label>Formas de pagamento</label>
                <select-control 
                  :options="formas"
                  v-model="checkout.idformapagamentomaterial"
                  placeholder="Forma de pagamento"
                />
                <p class="payAlert">*Os descontos de Formas de Pagamento não serão aplicados a esta venda.</p>
            </div>
            <div class="form-group col-md-12 sessionTitle">
              <h4>Produtos</h4>
              <h5>Itens venda</h5>
            </div>
            <card>
              <section class="itensVenda">
                <table>
                  <thead>
                    <tr>
                      <th class="full">Produto</th>
                      <th class="text-right">Valor (R$)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="total">
                      <td colspan="2" class="text-right">
                        <p>Total: <b>{{ total }}</b></p>
                      </td>
                    </tr>
                    <tr v-for="item in vendamaterial" :key="item">
                      <td class="full">
                        {{ item.descricaovenda }} {{ item.descricaoGrade ? ' - ' + item.descricaoGrade : ''}}
                      </td>
                      <td>
                        <input type="number" class="clean" placeholder="" v-model="item.valorvenda" @change="sum()"/>
                      </td>
                    </tr>
                    <tr class="empty">
                      <td></td>
                    </tr>
                    <tr class="total">
                      <td colspan="2" class="text-right">
                        <p>Total: <b>{{ total }}</b></p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </card>
            <div class="row no-padd">
              <div class="form-group col-md-12">
                <a href="#" @click.prevent="save()" class="btn btn-primary" v-if="allowEdit">Salvar edição</a>
                <span class="editNotAllowed" v-else>
                  <h5>Atenção!</h5>
                  <p>Esta venda tem parcelas pagas. Por isso você não pode editá-la. Para editar as transações utilize a função em: Lojinha > Histórico de Transações</p>
                </span>
              </div>
            </div>
          </div>
          
        </div>
      </div>
     </transition>
  </controller>
</template>

<script>
import MarketplaceService from '@/services/marketplace.service'
import CheckoutList from '@/components/datatable/CheckoutList'
import { createSelectOptions, formatMoney, formatDate } from '@/services/utils.service'
import InputSpinner from '@/components/utilities/InputSpinner'

export default {
  name: 'Recompra',
  components: {
    CheckoutList, InputSpinner
  },
  props: {
    checkoutId: {
      type: [Number, String],
      default: null
    }
  },
  watch: {
    checkoutId ( value ) {
      console.log('checkoutId:', value)
      if ( value ) {
        this.fetchFormasPagamento(true, value)
        // this.fetchDetails(value)
      }
    }
  },
  data () {
    return {
      loading: false,
      searched: false,
      filter: true,
      list: [],
      cart: null,
      total: 0,
      query: null,
      details: false,
      checkout: null,
      formas: [],
      formasPagamento: [],
      pessoa: [],
      vendamaterial: [],
      transacoesVendas: [],
      transacoes: {
        parcelas: [],
        headers: ['ID', 'Parcela', 'Vencimento', 'Valor', 'Forma de pagamento', 'Pago', 'Valor Pago', 'Pago em'],
        keys: ['idtransacao', 'parcela', 'vencimentoboleto', 'valortransacao', 'formapagamento', 'pago', 'valorpago', 'pagoem'],
      },
      allowEdit: true
    }
  },
  mounted () {
    if ( this.checkoutId ) {
      this.fetchFormasPagamento(true, this.checkoutId)
      // this.fetchDetails(this.checkoutId)
    } else {
      this.details = false
    }
    // this.fetchCheckouts()
  },
  methods: {
    searchCheckouts ( click ) {
      const query = this.query
      
      const drop = !query || query?.length === 0 ? 0 : query

      // console.log("drop:", drop)
      if ( drop.length >= 3 || click ) {
        window.spinner.emit("open")
        this.loading = true
        this.searched = true
        MarketplaceService.getCheckoutsMarketplace( drop ).then(
          response => {
            this.list = response.data
            console.log(response)
            window.spinner.emit("close")
            this.loading = false
          },
          error => {
            console.error(error)
            window.spinner.emit("close")
            this.loading = false
          }
        )
      }
    },
    getDetails ( id ) {
      console.log("getDetails:", id)
      this.$router.push({ name: 'Recompra', params: { checkoutId: id } })
    },
    fetchDetails ( id ) {
      console.log("getDetails:", id)

      if ( id ) {
        window.spinner.emit("open")
        this.details = true
         MarketplaceService.getCheckoutDetails( id ).then(
          response => {
            if ( response.data ) {
              this.checkout = response.data.checkout
              this.vendamaterial = response.data.vendamaterialCheckout
              this.transacoesVenda = response.data.transacoesvendaCheckout

              // console.log('this.checkout:', this.checkout)
              this.sum()

              this.loading = false

              this.formatTransacoes()

              window.spinner.emit("close")
            }
          },
          error => {
            console.error(error)
            window.spinner.emit("close")
            this.loading = false
          }
        )
      }
    },
    fetchFormasPagamento ( keepOnGoing, value ) {
      if ( keepOnGoing ) window.spinner.emit("open")
      MarketplaceService.getFormasPagamentoFree().then(
        response => {
          console.log("getFormasPagamento:", response.data)

          this.formas = createSelectOptions(response.data, 'idformapagamentomaterial', 'descricao')
          this.formasPagamento = response.data
          if ( keepOnGoing ) this.fetchDetails(value)
          // response.data.map( k => {
          //   k.label =  k.descricao + ' (' + k.datainicial + ' - ' + k.datafinal + ')' + ' - ' + k.idformapagamentomaterial
          //   k.value = k.idformapagamentomaterial
          //   formas.push(k)
          // })
        },
        error => {
          console.log("error:", error)
        } 
      )
    },
    sum () {
      let total = 0

      this.vendamaterial.map( k => {
        console.log("k", k)
        total += Number(k.valorvenda)
      })

      total = formatMoney(total)
      this.total = total
    },
    formatTransacoes () {
      const data = []
      const payed = []

      this.transacoesVenda.map( k => {
        const item = k
        item.vencimentoboleto = k.vencimentoboleto ? formatDate(k.vencimentoboleto) : '-'
        item.valortransacao = formatMoney(k.valortransacao)
        item.valorpago = formatMoney(k.valorpago)
        item.formapagamento = k.cartao == 1 ? 'Cartão' : ( k.boleto == '1' ? 'Boleto' : '-' )
        item.pago = k.pago === 1 ? 'Sim' : 'Não'
        item.parcela = k.parcela ? k.parcela : 1
        item.pagoem = k.pagoem ? formatDate(k.pagoem) : '-'

        if ( k.pago === 1 ) payed.push(k)

        data.push(item)
      })

      this.allowEdit = payed.length == 0
      this.transacoes.parcelas = data
    },
    goBack () {
      this.details = false
      this.searched = false
      this.list = []
      this.checkout = null
      this.$router.push({name: 'Recompra'})
    },
    checkFormasPagamento () {
      // console.log("this.formas:", this.checkout.idformapagamentomaterial)
      const check = this.formasPagamento.filter ( k => {
        // console.log("k",k)
        return k.idformapagamentomaterial == this.checkout.idformapagamentomaterial
      })
       console.log("check",check)
      return check.length > 0
    },
    save () {
      console.log("this.checkout.idformapagamentomaterial:", this.checkout.idformapagamentomaterial )
      const hasFormaPag = this.checkFormasPagamento()
      // console.log("hasFormaPag",hasFormaPag)

      if ( hasFormaPag ) {
        const data = {
          idCheckoutMarketplace: parseFloat(this.checkoutId),
          idFormaPagamentoMaterial: this.checkout.idformapagamentomaterial,
          itensVendaMaterial: this.vendamaterial
        }

        // console.log("data:", data)
        window.spinner.emit("open")
        MarketplaceService.saveRecompra( data ).then(
          response => {
            console.log('saveRecompra:', response)
            if ( response.status == 200 ) {
              window.location.reload()
            }
            window.spinner.emit("close")
          },
          error => {
            console.error(error)
            window.spinner.emit("close")
          }
        )
      } else {
        window.toaster.emit('open', {style: "error", message: "Para continuar, escolha uma forma de pagamento.", floater: true})
        window.pagescroll.emit('goto', 0)
      }
      
    } 
  }
}
</script>

<style lang="scss">
  .recompra-filter {
    position: relative;
    margin-top: $mg !important;
    
    .icon {
      position: absolute;
      right: .8em; top: 50%;
      transform: translateY(-50%);
      z-index: 10;
      font-size: 28px;
      color: $color-primary;
    }

    .ac-spinner {
      position: absolute; top: 50%; right: .8em;
      transform: translateY(-55%); z-index: 11;
    }
  }

  .form-group > h5 {color: $color-text; margin: 0 !important}
  .sessionTitle {
    margin: $hmg_small 0 $hmg_small 0 !important;
  }

  .itensVenda {
    padding: $hmg;

    table {
      table-layout: auto;
      width: 100%;
      
      td,th {
        padding: 0 .6em;
        width: 20%;
      }
      .full { width: 80%; }
      thead {
        th {
          padding-bottom: .6em !important
        }
      }
      tbody {
        tr {
          @extend %transition_3o;

          &:hover:not(.empty):not(.total) {
            .td{
              border-bottom: 1px solid $color-light
            }
          }
          &.empty {
            height: $hmg;
          }
        }
      }
    }
  }

  .total {
    // height: 40px;

    td {
      border-top: 1px solid $color-light;
      padding-top: 1em !important;
    }
    td:first-child {
      border-bottom: 1px solid $color-light;
      padding-bottom: 1em !important;
    }
    
    p {
      padding: 0; margin: 0;
    }
  }

  input.clean {
    border: 0; outline: 0;
    width: 100%; height: 34px;
    vertical-align: middle;
    background-color: transparent; color: $color-text;
    padding: 0;
    text-align: right;
    @extend %transition_4e;

    &:focus{
      background-color: $color-light;
      padding: 0 .6em;
      font-weight: bold;
    }
  }

  p.payAlert {
    margin-top: $hmg_small;
    padding-left: $hmg_small
  }

  .editNotAllowed{
    display: block;
    max-width: 60%;
  }

  
</style>