<template>
  <div class="amountSelectorWrap">
    <h3>E a quantidade?</h3>
    <div class="amountSelector">
      <a href="#" @click.prevent="sub()" :class="['btMinus', { disabled: amount === 1 }]">
        <fa :icon="['fas', 'minus']" class="icon"></fa>
      </a>
      <span>
        <input type="number" class="" v-model="amount" @keyup="trackValue()" @blur="trackValue(true)" maxlength="2">
      </span>
      <a href="#" @click.prevent="add()" class="btPlus">
        <fa :icon="['fas', 'plus']" class="icon"></fa>
      </a>
    </div>
    <a href="#" class='btn btn-primary btn-super' @click.prevent="addToCart()">
      Colocar na sacola
    </a>
    <a href="#" @click.prevent="goBack()" class="btGoBack">
      <fa :icon="['far', 'arrow-left-long']" class="icon"></fa>
      Alterar os detalhes
    </a>
  </div>
</template>

<script>

export default {
  name: 'AmountSelector',
  props: {

  },
  data () {
    return {
      amount: 1
    }
  },
  mounted () {
    this.$emit('updateamount', this.amount)
  },
  methods: {
    add () {
      this.amount++

      this.$emit('updateamount', this.amount)
    },
    sub () {
      this.amount--

      this.$emit('updateamount', this.amount)
    },
    trackValue (blur) {
      if ( blur ) {
        this.amount = this.amount < 1 ? 1 : this.amount
      } else {
        this.amount = this.amount && this.amount < 1 ? 1 : this.amount
      }
    },
    goBack () {
      this.$emit('goback')
    },
    addToCart () {
      this.$emit('addtocart')
    }
  }
}
</script>

<style lang="scss">
.amountSelectorWrap {
  width: 100%;
  h3 {text-align: center; color: $color-primary !important}
  .btn {display: table; margin: $mg auto 0;}
  .btGoBack {
    display: table; margin: $hmg auto 0;
    .icon {margin-right: 3px;}
  }

  .amountSelector {
    width: auto; margin: $hmg auto;
    display: flex; align-content: center; align-items: center; justify-content: center;

    input {
      font-size: 34px; padding: 0; height: 60px; width: 60px;
      border: 0; border-radius: $border-radius; outline: 0;
      background-color: $color-light; color: $color-text;
      font-weight: bold; text-align: center;
      margin: 0 $hmg;

      &:focus {
        outline: 0; border: 0;
      }
    }

    a {
      &:hover{
        color: $color-primary;
        @extend %transition_3o;
      }
      &.disabled {
        opacity: .5; pointer-events: none;
      }
    }
  }
}
  
</style>