<template>
  <controller>
    <page-header title="Gatilhos de Atividades"></page-header>
    <filter-controller @onFilter="runFilter" v-if="!idCategoria && !idFormulario"></filter-controller>
    <gatilhos-atividades v-if="idCategoria && idFormulario" :idCategoria="idCategoria" :idFormulario="idFormulario" :perfilPessoa="perfilPessoa"></gatilhos-atividades>
    <list-controller :idCategoria="idCategoria" :idFormulario="idFormulario" :perfilPessoa="perfilPessoa" v-else></list-controller>
  </controller>
</template>

<script>
import FilterController from '@/components/ferramentas/gatilhosAtividades/utilities/Filter.vue'
import ListController from '@/components/ferramentas/gatilhosAtividades/utilities/List.vue'
import GatilhosAtividades from '@/components/ferramentas/gatilhosAtividades/form/CriarEditar.vue'

export default {
  components: {
    FilterController, GatilhosAtividades, ListController
  },
  props: {
    idCategoria: {
      type: [String, Number]
    },
    idFormulario: {
      type: [String, Number]
    },
    perfilPessoa: {
      type: [String, Number]
    }
  },
  data() {
    return {
      categoria: null
    }
  },
  mounted () {

  },
  methods: {
    runFilter ( categoria ) {
      this.$router.push({ name: 'Gatilhos Atividades', params: { idCategoria: categoria.idAtividadeCategoria, idFormulario: categoria.idFormulario, perfilPessoa: categoria.idPerfilPessoa }})
    }
  }
}
</script>

<style>

</style>