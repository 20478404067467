<template>
  <section class="filterForms">
    <div class="search-control">
      <input-control 
        v-model="filter.nome" 
        name="nome" 
        placeholder="Buscar..." 
        cssClass="mid input" 
        preventSearchButton search :disabled="loading"
        @keyup="filterByNome"
      />
      <a href="#" class="btn btn-bg btn-icon btn-mid" @click.prevent="clearSearch()">
        <fa :icon="['fal','trash-xmark']"></fa>
      </a>
    </div>
    <div class="toggle">
      <switch-control v-model:checked="onlyActive" labelOn="Apenas Ativos" labelOff="Todos" invert @change="onlyActiveChange()"/>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      filter: {
        nome: null
      },
      typeWait: null,
      onlyActive: false
    }
  },
  mounted () {

  },
  methods: {
    filterByNome () {
      if ( this.filter.nome.length > 1 ) {
        if ( this.typeWait ) clearTimeout(this.typeWait)
        
        setTimeout(() => {
          const search = {
            action: "search",
            query: this.filter.nome
          }
          this.$emit("onSearch", search)
        },300)
      } else if ( !this.filter || this.filter.length == 0 ) {
        this.$emit("onClear")
      }
    },
    clearSearch () {
      this.$emit("onClear")
    },
    onlyActiveChange () {
      this.$emit('toggleActive', this.onlyActive)
    }
  }
}
</script>

<style lang="scss" scoped>
  .filterForms {
    display: flex; align-content: center; align-items: center;
    width: 100%; height: 50px;
    border-bottom: $border-component;
    padding: 0 $hmg_mid;
    gap: $hmg_mid;

    .search-control {
      display: flex; align-items: center; align-content: center;
      gap: $hmg_mini;
      width: 280px;
    }
    .toggle {
      padding-top: $hmg_mini
    }

  }
</style>