<template>
  <section v-if="matricula && active">
    <modal ref="modal">
      <div v-if="loading">
        <input-spinner></input-spinner>
      </div>
      <template v-else>
        <transition name="slideLeftToRightSmall">
          <div class="crud-actions">
            <a href="#" @click.prevent="toggleNovo()" class="btn btn-primary" v-if="!nova">
              <span>Adicionar Desconto</span>&nbsp;
              <fa :icon="['fas','badge-percent']"></fa>
            </a>
            <a href="#" @click.prevent="goBackToList()" class="btn btn-primary" v-if="nova || edit">
              <fa :icon="['far','arrow-left-long']"></fa>
              &nbsp;<span>Voltar</span>
            </a>
          </div>
        </transition>
        <span class="empty" v-if="descontos.descontos.length == 0 && !nova">Esta matrícula ainda não tem descontos concedidos.</span>
        <transition-group name="fadein">
          <template v-if="descontos.descontos.length > 0 && !nova">
            <h5 class="color-text" key="1">Esta matrícula tem {{ descontos.descontos.length + ( descontos.descontos.length > 1 ? ' descontos' : ' desconto' )}}</h5>
            <div class="tableWrap" key="2">
              <table class="content-table" >
                <thead>
                  <tr>
                    <th>Tipo Arrecadação</th>
                    <th>Tipo Desconto</th>
                    <th>Valor</th>
                    <th>Limite Matrícula</th>
                    <th>Início</th>
                    <th>Final</th>
                    <th>Motivo</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="( desconto, i ) in descontos.descontos" :key="desconto" :class="{ off: desconto.percentual == 0 }">
                    <td>{{ filterArrecadacao(desconto.idTipoArrecadacao).label }}</td>
                    <td>{{ desconto.descricao }}</td>
                    <td>{{ desconto.percentual }}</td>
                    <td>{{ desconto.limiteMatricula ? formatDate(desconto.limiteMatricula) : '-' }}</td>
                    <td>{{ desconto.datainicio }}</td>
                    <td>{{ desconto.datafinal }}</td>
                    <td>{{ desconto.observacao }}</td>
                    <td>
                      <a href="#" @click.prevent="toggleEdit(i)">
                        <fa :icon="['far','pencil']" class="icon"></fa>
                      </a>
                      <a href="#" @click.prevent="toggleRemove(i)">
                        <fa :icon="['far','trash-can']" class="icon"></fa>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            
          </template>
        </transition-group>
        <transition name="fadein">
          <div class="row no-padd" v-if="nova" ref="descontoForm">
            <div class="col-md-4 form-group no-padd-left">
              <label>Tipo de Arrecadação</label>
              <select-control
                v-model="create.idTipoArrecadacao" 
                :options="options.tipoArrecadacao"
                placeholder="Selecione" 
                name="tipoArrecadacao" 
                type="text" 
                :valid="valid.tipoArrecadacao"
                @change="valid.tipoArrecadacao = true" 
                required
                cssClass="invert"
              />
            </div>
            <div class="col-md-4 form-group no-padd-left">
              <label>Tipo de Desconto</label>
              <select-control
                v-model="create.idTipoDesconto" 
                :options="options.tipoDescontos"
                placeholder="Selecione" 
                name="tipoDescontos" 
                type="text"
                :valid="valid.tipoDescontos"
                @change="valid.tipoDescontos = true" 
                required
                cssClass="invert"
              />
            </div>
            <div class="col-md-4 form-group no-padd-left">
              <label>Valor(%)</label>
              <input-control
                v-model="create.percentual" 
                placeholder="Apenas Numeral" 
                name="percentual" 
                type="number"
                :valid="valid.percentual"
                @change="valid.percentual = true" 
                required
                cssClass="invert"
                min="0"
                max="100"
                step="0"
              />
            </div>
            <div class="col-md-4 form-group no-padd-left">
              <label>Limite Matrícula</label>
              <input-control
                v-model="create.limiteMatricula" 
                name="limiteMatricula" 
                type="date"
                cssClass="invert"
              />
            </div>
            <div class="col-md-4 form-group no-padd-left">
              <label>Data Início</label>
              <input-control
                v-model="create.datainicio" 
                name="datainicio" 
                type="date"
                :valid="valid.datainicio"
                @change="valid.datainicio = true" 
                required
                cssClass="invert"
              />
            </div>
            <div class="col-md-4 form-group no-padd-left">
              <label>Data Fim</label>
              <input-control
                v-model="create.datafinal" 
                name="datafinal" 
                type="date"
                :valid="valid.datafinal"
                @change="valid.datafinal = true" 
                required
                cssClass="invert"
              />
            </div>
            <div class="col-md-12 form-group no-padd-left">
              <label>Motivo Desconto</label>
              <textarea-control
                v-model="create.observacao" 
                name="observacao" 
                placeholder="Detalhe.."
                :valid="valid.observacao"
                @change="valid.observacao = true" 
                required
                cssClass="invert"
              />
            </div>
            <div class="col-md-12 form-group no-padd-left form-actions">
              <a href="#" @click.prevent="save()" class="btn btn-primary">
                Salvar
              </a>
              <a href="#" @click.prevent="closeModal()" class="btn btn-secondary">
                Cancelar
              </a>
            </div>
          </div>
        </transition>
      </template>
      
    </modal>
  </section>
</template>

<script>
import Modal from "@/components/utilities/Modal"
import InputSpinner from '@/components/utilities/InputSpinner'

import MatriculaService from '@/services/matricula.service'
import FormService from '@/services/form.service'
import { createSelectOptions, formatDate, unformatDate } from '@/services/utils.service'

import moment from 'moment'

export default {
  components: {
    Modal, InputSpinner
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    idMatricula: {
      type: [Number, String]
    },
    scope: {
      type: [Object, Array]
    } 
  },
  watch: {
    active ( value ) {
      if ( value == true ) {
        this.toggleModal()
      }
    }
  },
  data () {
    return {
      matricula: null,
      loading: false,
      periodoLetivo: null,
      nova: null,
      desconto: { //MODEL
        idTipoDescontoMatricula: null,
        percentual: null,
        observacao: null,
        data: null,
        datainicio: null,
        datafinal: null,
        idTipoDesconto: null,
        idMatricula: null,
        idTipoArrecadacao: null,
        descricao: null,
        acao: null,
        limiteMatricula: null
      },
      descontos: null,
      valid: {
        tipoArrecadacao: true,
        tipoDescontos: true,
        percentual: true,
        datainicio: true,
        datafinal: true,
        observacao: true
      },
      wait: {
        tipoArrecadacao: false,
        descontos: false,
        tipoDescontos: false,
        periodoLetivo: false
      },
      options: {
        tipoArrecadacao: [],
        tipoDescontos: [],
      },
      create: null,
      edit: false
    }
  },
  mounted () {
    
  },
  methods: {
    toggleModal () {
      this.loading = true

      const options = {
        title: 'Descontos',
        titleAlign: "left",
        // message: "Se quiser uma mensagem...", //não obrigatório
        size: "large", //small, mid, large, full
        backdrop: true,
        backdropColor: "transparent",  //se o backdrop for false, não precisa
        backdropClose: true, //se o backdrop for false, não precisa
        onClose: this.closeModal
      }

      // console.log("scope:", this.scope)
      if ( this.scope ) {
        this.matricula = Object.assign({}, this.scope)
        this.getData(this.idMatricula)

        setTimeout(() => {
          this.$refs.modal.open(options)
        },200)
      } else {
        this.$store.dispatch('matricula/get').then(response => {
          this.matricula = response
          const idMatricula = this.matricula.matricula.idMatricula
          
          this.$store.dispatch('desconto/get').then(response => {
            this.descontos = response
            // console.log("descontos:", this.descontos)

            this.getData(idMatricula)
          })
          

          setTimeout(() => {
            this.$refs.modal.open(options)
          },200)
          
          // this.toggleModal()
        })
      }
    },
    getData ( idMatricula ) {
      // console.log("getData:", idMatricula)

      this.getTipoArrecadacao()
      this.getDescontos()
      this.getDescontosMatricula(idMatricula)
      this.getPeriodoLetivo(idMatricula)
    },
    closeModal () {
      this.$emit('close')
      this.create = []
      this.nova = false
      this.edit = false
    },
    getTipoArrecadacao () {
      MatriculaService.getTipoArrecadacao().then(
        response => {
          // console.log('getTipoArrecadacao:', response.data)
          this.options.tipoArrecadacao = createSelectOptions(response.data.data, 'idTipoArrecadacao', 'descricao')
          this.wait.tipoArrecadacao = true
          this.stopSpinner()
        },
        error => {
          console.error(error)
          this.loading = false
        }
      )
    },
    getDescontosMatricula ( idMatricula ) {
      MatriculaService.getTipoDescontos( idMatricula ).then(
        response => {
          // console.log('getTipoDescontos:', response.data)
          if ( response.data.length > 0 ) {
            // console.log("descontos:", this.descontos)
            this.descontos.descontos = response.data
            // this.descontos = createSelectOptions(response.data, 'idTipoDesconto', 'descricao')
          } else {
            //
          }

          this.wait.tipoDescontos = true
          this.stopSpinner()
        },
        error => {
          console.error(error)
          this.loading = false
        }
      )
    },
    getPeriodoLetivo ( idMatricula ) {
      MatriculaService.getPeriodoLetivoByMatricula( idMatricula ).then(
        response => {
          // console.log('getPeriodoLetivoByMatricula:', response.data)
          this.periodoLetivo = response.data
          this.wait.periodoLetivo = true
          this.stopSpinner()
        },
        error => {
          console.error(error)
          this.loading = false
        }
      )
    },
    getDescontos () {
      MatriculaService.getDescontos().then(
        response => {
          // console.log('getDescontos:', response.data)
          this.options.tipoDescontos = createSelectOptions(response.data, 'idTipoDesconto', 'descricao')
          this.wait.descontos = true
          this.stopSpinner()
        },
        error => {
          console.error(error)
          this.loading = false
        }
      )
    },
    stopSpinner () {
      const done = !Object.values(this.wait).includes(false)
      if ( done ) this.loading = false
    },
    filterArrecadacao ( idArrecadacao ) {
      return this.options.tipoArrecadacao.filter( k => {
        return k.value == idArrecadacao
      })[0]
    },
    toggleNovo () {
      this.$store.dispatch('desconto/model').then(response => {
        const model = Object.assign({},response)
        this.create = model
        this.create.acao = '3-insert'
        this.nova = true
        this.edit = false
      })
    },
    goBackToList () {
      this.edit = false
      this.nova = false
      this.create = []
    },
    toggleEdit ( index ) {
      // console.log("toggleEdit:", index)
      // console.log("descontos:", this.descontos)
      
      const edit = this.descontos.descontos[index]
      this.create = edit
      this.create['acao'] = '2-update'
      this.create.datainicio = unformatDate(this.create.datainicio)
      this.create.datafinal = unformatDate(this.create.datafinal)
      this.nova = true
      this.edit = true
    },
    toggleRemove ( index ) {
      // console.log("toggleEdit:", index)
      // console.log("descontos:", this.descontos)
      
      const remove = this.descontos.descontos[index]

      this.delete(remove.idTipoDescontoMatricula)

      // this.nova = true
      // this.edit = true
    },
    validate () {
      const valid = FormService.validate(this.$refs.descontoForm, this.valid)
      return valid
    },
    save () {
      const valid = this.validate()

      // console.log("validate:", valid)
      if ( valid.response ) {
        this.create.data = moment().format('DD/MM/YYYY HH:mm:ss')
        this.create.datainicio = formatDate(this.create.datainicio)
        this.create.datafinal = formatDate(this.create.datafinal)
        this.create.idMatricula = this.matricula.matricula.idMatricula
        this.loading = true

        MatriculaService.saveDescontoMatricula( this.create ).then(
          () => {
            // console.log("saveDescontoMatricula:", response)
            if ( this.matricula.matricula.idSituacao == 1 ) {
              MatriculaService.integraMatricula( this.idMatricula ).then(
                () => {
                  // console.log("integraDesconto:", response.data)
                  window.toaster.emit('open', {style: "success", message: "Matricula Integrada com Sucesso.", floater: true})

                  MatriculaService.integraDesconto( this.idMatricula ).then(
                    () => {
                      // console.log("integraDesconto:", response.data)
                      this.loading = false
                      window.toaster.emit('open', {style: "success", message: "Desconto Integrado com Sucesso.", floater: true})

                      setTimeout(() => {
                        this.closeModal()
                        this.$emit('save')
                      },1000)
                    },  
                    error => {
                      const msg = error.response.data.error
                      window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não conseguimos integrar a matrícula: <b>" + msg + "</b>", floater: true})
                    }
                  )
                },  
                error => {
                  const msg = error.response.data.error
                  window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não conseguimos integrar os descontos: <b>" + msg + "</b>", floater: true})
                }
              )
            } else {
              this.loading = false
              window.toaster.emit('open', {style: "success", message: "Desconto Salvo com Sucesso.", floater: true})

              setTimeout(() => {
                this.closeModal()
                this.$emit('save')
              },1000)
            }
            
          },
          error => {
            // console.error(error.response.data)
            const msg = error.response.data.error
            window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não conseguimos salvar o desconto com os dados enviados: <b>" + msg + "</b>", floater: true})
            this.closeModal()
          }
        )
        console.log("valid, go...")
      }
    },
    delete ( descontoMatricula ) {
      this.loading = true

      MatriculaService.deleteDescontoMatricula( descontoMatricula ).then(
        () => {
          this.loading = false

          this.$store.dispatch('desconto/clear', descontoMatricula).then(response => {
            this.descontos = response
            setTimeout(() => {
              this.closeModal()
            },500)
            this.$emit('save')
            window.toaster.emit('open', {style: "success", message: "Tudo certo! Desconto removido com sucesso.", floater: true})
          })

        },
        error => {
          console.error(error.response.data)
          const msg = error.response.data.error
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não conseguimos remover o desconto com os dados enviados: <b>" + msg + "</b>", floater: true})
          this.closeModal()
        }
      )
    },
    formatDate ( date ) {
      return formatDate(date)
    }
  }
} 
</script>

<style lang="scss" scoped>
  .empty {
    padding: $mg_mini $hmg_small; 
    background-color: $color-bg;
    border-radius: $border-radius;
    margin-left: -$hmg_small; margin-top: $mg_mini
  }

  .crud-actions {
    position: absolute; top: calc($mg - $mg_mini); right: $mg;
  }

  .form-actions {
    a {
      width: auto !important
    }
  }

  .tableWrap {
    margin-left: -$mg_mini; margin-top: $hmg_small;
    width: calc(100% + $mg_mini);
  }

</style>