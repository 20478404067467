<template>
  <div class="filiacao">
    <ul>
      <li v-if="filiacao['1']">
        <card boxed :listed="false" fixed>
          <div class="filWrap">
            <header>
              <label>Filiação 1</label>
              <h5>{{ filiacao['1'].Basicos.nome }}</h5>
              <p>{{ filiacao['1'].Documento.cpf }}</p>
            </header>
            <div>
              <a href="#" class="phoneLink email" @click.prevent="copy(filiacao['1'].Basicos.email, 'email')">
                <fa :icon="['far', 'envelope']" class="icon" />
                <div>
                  <span>{{ filiacao['1'].Basicos.email }}</span>
                </div>
              </a>
              <div class="phoneWrap">
                <a :href="'tel:' + filiacao['1'].Basicos.telefonecelular" class="phoneLink">
                  <fa :icon="['far', 'phone']" class="icon" />
                  <span>{{ filiacao['1'].Basicos.telefonecelular }}</span>
                </a>
                <a href="#" @click.prevent="copy(filiacao['1'].Basicos.telefonecelular, 'telefone')">
                  <fa :icon="['fas', 'copy']" />
                </a>
              </div>
            </div>
          </div>
        </card>
      </li>
      <li v-if="filiacao['2']">
        <card boxed :listed="false" fixed>
          <div class="filWrap">
            <header>
              <label>Filiação 2</label>
              <h5>{{ filiacao['2'].Basicos.nome }}</h5>
              <p>{{ filiacao['2'].Documento.cpf }}</p>
            </header>
            <div>
              <a href="#" class="phoneLink email" @click.prevent="copy(filiacao['2'].Basicos.email, 'email')">
                <fa :icon="['far', 'envelope']" class="icon" />
                <div>
                  <span>{{ filiacao['2'].Basicos.email }}</span>
                </div>
              </a>
              <div class="phoneWrap">
                <a :href="'tel:' + filiacao['2'].Basicos.telefonecelular" class="phoneLink">
                  <fa :icon="['far', 'phone']" class="icon" />
                  <span>{{ filiacao['2'].Basicos.telefonecelular }}</span>
                </a>
                <a href="#" @click.prevent="copy(filiacao['2'].Basicos.telefonecelular, 'telefone')">
                  <fa :icon="['fas', 'copy']" />
                </a>
              </div>
            </div>
          </div>
        </card>
      </li>
    </ul>
  </div>
</template>

<script>
import MatriculaService from '@/services/matricula.service.js'

export default {
  props: {
    aluno: {
      type: Object
    }
  },
  data () {
    return {
      filiacao: {
        '1': null,
        '2': null
      }
    }
  },
  mounted () {
    console.log("this.aluno.Basicos.idPessoaPai:", this.aluno.Basicos.idPessoaPai)
    console.log("this.aluno.Basicos.idPessoaMae:", this.aluno.Basicos.idPessoaMae)

    if ( this.aluno.Basicos.idPessoaMae ) this.getFiliacao('1', this.aluno.Basicos.idPessoaMae)
    if ( this.aluno.Basicos.idPessoaPai ) this.getFiliacao('2', this.aluno.Basicos.idPessoaPai)
  },
  methods: {
    getFiliacao ( type, target ) {
      MatriculaService.getPessoaByIdPessoaOuCpf(target,0).then(
        response => {
          console.log("getFiliacao:", response.data)
          this.filiacao[type] = response.data.dados
        },
        () => {

        }
      )
    },
    copy ( text, type ) {
      navigator.clipboard.writeText(text).then(() => {
        window.toaster.emit('open', {style: "success", message: type + " copiado com sucesso.", floater: true})
      },() => {
        console.error('Ops! Não foi possível copiar o ' + type + ' selecionado.')
      });
    }
  }
}
</script>

<style lang="scss" scoped>
  .filiacao {
    padding: 0;

    ul {
      padding: 0; margin: 0;
      display: flex; align-items: stretch; align-content: stretch;
      gap: $hmg_mid;

      li {
        list-style: none; 
        flex: 1;
        padding: 0; margin: 0;
        max-width: 50%;
      }
    }
  }
  
  .phoneLink {
    display: flex; align-content: center;
    gap: $hmg_mini;
    margin-top: $mg_mini;
    line-height: 1;
    
    .icon {
      font-size: $font-size;
    }
    span {
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:hover {
      color: $color-primary
    }

    &.email {
      >div {
        width: 100%;
        position: relative;
        overflow: hidden;

        span { 
          display: block;
          position: absolute;
          top: 0; left: 0;
          max-width: 100%;
        }
      }
    }
  }

  .filWrap {
    display: flex; flex-direction: column;
    justify-content: space-between;
    height: 100%;
    
    header {
      margin-bottom: $mg_mini;

      p {
        margin: 0; padding: 0;
      }
      label {
        font-size: $font-size-small;
      }
    }
  }

  .phoneWrap {
    display: flex; align-items: center;
    gap: $mg_mini; align-content: center;
    line-height: 1;
    margin-top: $mg_mini;

    a {
      margin: 0 !important;

      &:last-child {
        margin-top: -1px !important;
      }
      &:hover {
        color: $color-primary
      }
    }
  }
</style>