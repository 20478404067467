<template>
  <controller :goToTopButton="true">
    <div class="page-title">
      <h3 class="text-capitalize">Alçada de Descontos</h3>
      <!-- <transition name="slideRightToLeft">
        <a href="#" @click.prevent="toggleState('criar')" class="btn btn-primary" v-if="!criar">
          <span>Criar Alçadas</span>&nbsp;
          <fa :icon="['fas', 'badge-percent']"></fa>
        </a>
      </transition> -->
      <transition name="slideRightToLeft">
        <a href="#" @click.prevent="scope ? returnToList() : toggleState()" class="btn btn-secondary" v-if="alcadas || scope">
          <fa :icon="['far', 'arrow-left-long']"></fa>&nbsp;
          <span>Voltar</span>
        </a>
      </transition>
    </div>
    <div class="page-inner">
      <transition name="slideup-small">
        <filter-controller :state="state" @onFilter="filterAlcadas" ref="filter" v-if="!filter && !editar && !criar"></filter-controller>
      </transition>
      <alcada
        v-if="scope" 
        :scope="scope" 
        :state="state"
        :filterScope="filter" 
        @onReturn="toggleState()" 
        @onUpdate="returnToList(); filterAlcadas()"
      ></alcada>
      <alcadas 
        v-if="alcadas && !scope" 
        :scope="alcadas" 
        :filterScope="filter" 
        :state="state" 
        @onReturn="toggleState()" 
        @onUpdate="filterAlcadas()"
        @onEdit="toggleState('editar', $event)"
        @onCreate="toggleState('criar', $event)"
      ></alcadas>
    </div>
  </controller>
</template>

<script>
import FilterController from '@/components/financeiro/alcada/utilities/FilterAlcada.vue'
import Alcadas from '@/components/financeiro/alcada/form/Alcadas.vue'
import Alcada from '@/components/financeiro/alcada/form/Alcada.vue'
import FinanceiroService from '@/services/financeiro.service.js'

export default {
  components: {
    FilterController, Alcadas, Alcada
  },
  props: {
    action: {
      type: String
    }
  },
  data () {
    return {
      criar: false,
      editar: false,
      state: "editar",
      alcadas: null,
      scope: null,
      filter: null
    }
  },
  mounted () {
    if ( this.action ) {
      this.$router.push({ name: "Alçada de Descontos" })
    }
  },
  methods: {
    filterAlcadas ( filter ) {    
      console.log("filter:", this.filter)
      
      if ( filter ){
        this.filter = filter
      }
      
      window.spinner.emit("open")
      FinanceiroService.getAlcadas(this.filter).then(
        response => {
          if ( response.data.length > 0 ) {
            const drop = {
              alcadas: response.data,
              ...this.filter
            }
            
            this.alcadas = drop.alcadas
          } else {
            window.toaster.emit('open', {style: "error", message: "Não encontramos alçadas com as dados informados.", floater: true})
          }
          
          window.spinner.emit("close")
          console.log("getAlcadas:", response.data)
        },
        () => {
          window.spinner.emit("close")
        }
      )
    },
    returnToList () {
      this.scope = null
    },
    toggleState ( target, alcada ) {
      console.log("toggleState:", target)
      console.log("alcada:", alcada)

      this.state = target ? target : null
      this.$router.push({ name: "Alçada de Descontos" , params: { action: this.state } })

      this.scope = alcada ? alcada : null
      this.criar = false
      this.editar = false

      if ( target ) {
        this[target] = true
      } else {
        this.scope = null
        this.filter = null
        this.alcadas = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .start {
    padding: $hmg;

    > h4 {
      // text-align: center;
      margin-bottom: $hmg;
    }
    ul {
      padding: 0; margin: 0;
      display: flex;
      align-items: center; align-content: center;

      li {
        list-style: none;
        display: flex; flex-direction: column;
        align-items: center; align-content: center;
        justify-content: center;
        text-align: center;
        width: 150px; height: 130px;
        background-color: $color-secondary;
        border-radius: $border-radius;
        @extend %transition_4e;
        margin: 0 $hmg_small 0 0;
        padding: $hmg_small;
        cursor: pointer;

        .icon {
          font-size: 44px;
          margin-bottom: $hmg_small;
          color: $color-primary
        }

        h5 {
          padding: 0; margin: 0
        }

        &:hover {
          @extend %shadow-mid;
          transform: scale(1.1);
        }
      }
    }
  }
</style>