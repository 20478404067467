<template>
  <section>
    <header>
      <h5>
        Filtrar Planos
      </h5>
    </header>
    <div class="frame fixed" ref="planosForm">
      <div class="row no-padd" >
        <div class="col-md-6 form-group">
          <label>Unidade</label>
          <select-control 
            v-model="filter.idUnidade" 
            :options="options.unidades"  
            :disabled="loading"
            :valid="valid.idUnidade" 
            name="idUnidade" 
            placeholder="Selecione"
            @change="getPeriodosLetivos(); valid.idUnidade = true" 
            required
          />
        </div>
        <div class="col-md-4 form-group">
          <label>Período Letivo</label>
          <select-control 
            v-model="filter.idPeriodoLetivo" 
            :options="options.periodosLetivos" 
            :disabled="loading"
            :valid="valid.idPeriodoLetivo" 
            name="idPeriodoLetivo"
            placeholder="Selecione"
            @change="valid.idPeriodoLetivo = true; getSeries(); clearFilter('periodoLetivo')"
            required
          />
        </div>
        <div class="col-md-4 form-group">
          <label>Tipo de Curso</label>
          <select-control 
            v-model="filter.tipoCurso" 
            name="tipoCurso"
            placeholder="Selecione"
            :options="options.tipoCurso" 
            :disabled="loading" 
            @change="clearFilter('tipoCurso'); selectTipoCurso()"
          />
        </div>
        <div class="col-md-4 form-group">
          <label>Curso</label>
          <select-control 
            v-model="filter.idCurso" 
            :options="options.cursos" 
            placeholder="Selecione" 
            :disabled="loading" 
            @change="clearFilter('curso'); selectCurso()"
          />
        </div>
        <div class="col-md-4 form-group">
          <label>Série</label>
          <select-control 
            v-model="filter.idSerie" 
            :options="options.series" 
            placeholder="Selecione" 
            :disabled="loading" 
          />
        </div>
      </div>
      <div class="row no-padd" v-if="!filter.ativos">
        <div class="col-md-3 form-group">
          <label>Operador Início</label>
          <select-control
            v-model="filter.operadorInicio"
            placeholder="Selecione"
            :options="options.operadores"
            type="date"
            :disabled="loading"
          />
        </div>
        <div class="col-md-3 form-group">
          <label>Data Início</label>
          <input-control
            v-model="filter.dataInicio"
            placeholder="DD/MM/YYYY"
            type="date"
            :disabled="loading"
          />
        </div>
        <div class="col-md-3 form-group">
          <label>Operador Final</label>
          <select-control
            v-model="filter.operadorFinal"
            placeholder="Selecione"
            :options="options.operadores"
            type="date"
            :disabled="loading"
          />
        </div>
        <div class="col-md-3 form-group">
          <label>Data Final</label>
          <input-control
            v-model="filter.dataFinal"
            placeholder="DD/MM/YYYY"
            type="date"
            :disabled="loading"
          />
        </div>
      </div>
      <div class="row no-padd">
        <div class="col-md-4 form-group">
          <label>Status</label>
          <div class="switchWrapMini">
            <switch-control v-model:checked="filter.ativos" labelOn="Apenas Ativos" labelOff="Todos" @change="toggleDateRange()" invert></switch-control>
          </div>
        </div>
      </div>
      <div class="row no-padd">
        <div class="col-md-12 form-actions form-group">
          <a href="#" @click.prevent="runFilter()" class="btn btn-primary">
            Filtrar
          </a>
          <a href="#" @click.prevent="clearFilter()" class="btn btn-secondary">
            Limpar
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import UserService from '@/services/user.service.js'
import AcademicoService from '@/services/academico.service.js'
// import FormService from '@/services/form.service.js'
import { createSelectOptions } from '@/services/utils.service.js'

export default {
  data () {
    return {
      loading: false,
      filter: {
        idUnidade: null,
        idPeriodoLetivo: null,
        idSerie: null,
        dataInicio: null,
        dataFinal: null,
        idCurso: null
      },
      options: {
        unidades: [],
        periodosLetivos: [],
        tipoCurso: [],
        cursos: [],
        series: [],
        operadores: [
          { label: 'Menor', value: '<' },
          { label: 'Menor e igual', value: '<=' },
          { label: 'Maior', value: '>' },
          { label: 'Maior e igual', value: '>=' },
          { label: 'Igual', value: '==' }
        ]
      },
      valid: {
        idUnidade: true,
        idPeriodoLetivo: true,
      },
      wait: {
        unidades: false,
        tipoCurso: false,
        cursos: false
      },
      series: [],
    }
  },
  mounted () {
    window.spinner.emit("open")
    
    this.getUnidades()
    this.getTipoCurso()
    this.getCursos()
  },
  methods: {
    getUnidades () {
      UserService.getMinhasUnidades().then(
        response => {
          console.log("getMinhasUnidades:", response.data)
          
          if ( response.data.length > 0 ) {
            this.options.unidades = createSelectOptions(response.data, 'idUnidade' ,'descricao')
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhuma unidade encontrada. Verifique sua conexão e tente novamente.", floater: true})
          }
          
          this.wait.unidades = true
          this.finishLoad()
        },
        () => {
          window.spinner.emit("close")
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma unidade encontrada. Verifique sua conexão e tente novamente.", floater: true})
        }
      )
    },
    getPeriodosLetivos () {
      this.loading = true
      AcademicoService.getPeriodosLetivos(this.filter.idUnidade).then(
        response => {
          console.log("getPeriodosLetivos:", response.data)
          
          if ( response.data.length > 0 ) {
            this.options.periodosLetivos = createSelectOptions(response.data, 'idPeriodoLetivo' ,'descricao').reverse()
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum período letivo encontrado para esta unidade. Isto parece ser um problema de parametrização.", floater: true})
          }
          this.loading = false
        },
        error => {
          console.error(error)
          this.loading = false
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum período letivo encontrado para esta unidade. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    getTipoCurso () {
      AcademicoService.getTipoCurso().then(
        response => {
          console.log("getTipoCurso:", response.data)
          if ( response.data.length > 0 ) {
            this.options.tipoCurso = createSelectOptions(response.data, 'idTipoCurso' ,'descricao')
            this.wait.tipoCurso = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum tipo de curso encontrado. Verifique sua conexão e tente novamente.", floater: true})
          }
          
          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum tipo de curso encontrado. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    getCursos () {
      AcademicoService.getCursos().then(
        response => {
          console.log("getCursos:", response.data)
          if ( response.data.length > 0 ) {
            this.cursos = response.data
            this.options.cursos = createSelectOptions(response.data, 'idCurso' ,'descricao')
            this.wait.cursos = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum curso encontrado. Isto parece ser um problema de parametrização.", floater: true})
          }

          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum curso encontrado. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    getSeries () {
      this.loading = true
      const tipoCurso = this.filter.tipoCurso ? this.filter.tipoCurso : 0
      AcademicoService.getSeries(this.filter.idPeriodoLetivo, tipoCurso).then(
        response => {
          console.log("getSeries:", response.data)
          this.options.series = []

          if ( response.data.length > 0 ) {
            this.series = response.data
            response.data.map( k => {
              k.label = k.descricao + ' ' + k.descricaoTurno
            })
            this.options.series = createSelectOptions(response.data, 'idSerie' ,'label')
            
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhuma série encontrada para este período letivo. Isto parece ser um problema de parametrização.", floater: true})
          }

          this.loading = false
        },
        error => {
          console.error(error)
          this.loading = false
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma série encontrada para este período letivo. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    selectCurso () {
      const selected = this.filter.idCurso
      this.loading = true
      
      const result = this.series.filter( k => {
        return k.idCurso == selected
      })

      if ( result.length > 0 ) {
        this.options.series = createSelectOptions(result, 'idSerie' ,'label')
      } else {
        this.options.series = []
      }
      this.loading = false
    },
    selectTipoCurso () {
      const selected = this.filter.tipoCurso
      this.loading = true
      
      const result = this.cursos.filter( k => {
        return k.idTipoCurso == selected
      })
      
      if ( result.length > 0 ) {
        this.options.cursos = createSelectOptions(result, 'idCurso' ,'descricao')
      } else {
        this.options.cursos = []
      }
      this.loading = false
    },
    clearFilter ( part ) {
      if ( !part ) {
        this.filter = {
          idUnidade: null,
          idPeriodoLetivo: null,
          tipoCurso: null,
          cursos: null,
          idSerie: null
        }

        this.$emit('clear')
      } else if ( part === 'tipoCurso' ) {
        this.filter.idCurso = null
        this.filter.idSerie = null
        this.options.series = this.series
      } else if ( part === 'periodoLetivo' ) {
        this.filter.idCurso = null
        this.filter.idSerie = null
        this.options.series = this.series
      } else if ( part === 'curso' ) {
        this.filter.idSerie = null
        this.options.series = this.series
      }

    },
    finishLoad () {
      const finished = !Object.values(this.wait).some( a => !a)

      if ( finished ) {
        window.spinner.emit("close")
        this.loading = false
      }
    },
    toggleDateRange () {
      if ( this.filter.ativos ) {
        this.filter.operadorInicio = null
        this.filter.operadorFinal = null
        this.filter.dataInicio = null
        this.filter.dataFinal = null
      }
    },
    runFilter () {
      this.$emit('onFilter', this.filter)
    }
  }
}
</script>

<style lang="scss" scoped>
  header {
    padding: $hmg;

    h5 {
      margin: 0; padding: 0;
    }
    p {
      margin: 0; padding: 0;
    }
  }

  .form-actions {
    margin-top: $hmg !important
  }
</style>