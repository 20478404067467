<template>
  <div class="filterController">
    <label>Filtrar</label>
    <div v-if="filterBy.length > 0">
      <select-control name="matriculas" id="matriculas" :options="filters" v-model="selected" placeholder="Status" @change="filter"/>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    filters: {
      type: Array,
      default: () => {
        return []
      }
    },
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    find: {
      type: String
    }
  },
  watch: {
    filters (to,from) {
      if ( to != from ) {
        to.push({label: "Todos", value: "All"})
        this.filterBy = to
      }
    },
    data (to,from) {
      if (Object.keys(from).length === 0) {
        // console.log("mounted data:", this.data)
        to.map( k => {
          this.rawData.push(k)
          this.parsedData.push(k)
        })
      }
    }
  },
  data () {
    return {
      selected: null,
      filterBy: [],
      rawData: [],
      parsedData: []
    }
  },
  mounted () {
   
  },
  methods: {
    filter (selected) {
      selected = selected.target.value
      let drop

      if ( selected === "All" ) {
        console.log("is all")
        drop = this.rawData

      } else {
        drop = this.parsedData.filter((k) => {
          return k[this.find] == selected
        })
      }
      
      // console.log("drop: ", drop)
      // this.$emit("onFilter")
      this.$emit("onFilter", drop)
    }
  }
}
</script>
<style lang="scss" scoped>
  .filterController {
    display: flex; align-content: center; align-items: center;
    padding-left: $hmg_small;
    // margin-bottom: $hmg;

    label {
      margin: 0 15px 0 0;
      font-size: 10px; text-transform: uppercase;
      color: $color-primary;
    }
    
  }
</style>