<template>
   <controller :goToTopButton="true">
    <div class="page-title">
      <h3 class="text-capitalize">Documentos Requeridos</h3>
      <transition name="slideRightToLeft">
        <a href="#" @click.prevent="toggleNew()" class="btn btn-primary" v-if="!novo && !editar">
          <span>Novo Documento</span>&nbsp;
          <fa :icon="['fas', 'folder-open']"></fa>
        </a>
      </transition>
    </div>
    <div class="page-inner">
      <transition name="slideup-small">
        <documento v-if="novo || editar" :state="state" :scope="documento" @onCancel="cancelCreateEdit()" @onSave="saveDocumento()"></documento>
      </transition>
      <documentos v-if="documentos && !novo && !editar" :scope="documentos" @onSelect="editDocumento"></documentos>
    </div>
  </controller>
</template>

<script>
import Documento from '@/components/estruturaacademica/documentosrequeridos/form/Documento.vue'
import Documentos from '@/components/estruturaacademica/documentosrequeridos/form/Documentos.vue'
// import MatriculaService from '@/services/matricula.service.js'
import AcademicoService from  '@/services/academico.service.js'

export default {
  components: {
    Documento, Documentos
  },  
  data () {
    return {
      novo: false,
      editar: false,
      documentos: null,
      documento: null,
      state: null,
      model: {
        descricao: null,
        idcliente: 2,
        documentoresponsavel: null,
        validade: null,
        contrato: null,
        anexoContrato: null,
        vinculoMatricula: null
      }
    }
  },
  mounted () {
    this.getDocumentos()
  },  
  methods: {
    toggleNew () {
      this.editar = false
      this.novo = true
      this.state = 'Novo'
      this.documento = Object.assign({}, this.model)
    },
    editDocumento ( documento ) {
      this.editar = true
      this.novo = false
      this.state = 'Editar'
      this.documento = documento
      console.log("documento:", documento)
    },
    cancelCreateEdit () {
      this.documento = null
      this.editar = false
      this.novo = false
      this.state = null
    },
    saveDocumento () {
      this.documentos = null
      this.cancelCreateEdit()
      this.getDocumentos()
    },
    getDocumentos () {
      window.spinner.emit("open")
      AcademicoService.getDocumentosRequeridos().then(
        response => {
          if ( response.data.length > 0 ) {
            console.log("getDocumentosRequeridos:", response.data)
            this.documentos = response.data
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum documento requerido encontrado. Isto parece ser um problema de parametrização.", floater: true})
          }
          window.spinner.emit("close")
        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops. Algo deu errado. Não foi possível resgatar os documentos requeridos.", floater: true})
          window.spinner.emit("close")
        }
      )
    }
  }
}
</script>

<style>

</style>