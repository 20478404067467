<template>
  <div class="spinnerWrap">
    <span class="loader"></span>
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang="scss" scoped>
  .spinnerWrap {
    width: 100%;
    
    .loader {
      width: 100%;
      height: 6px;
      display: block;
      position: relative;
      overflow: hidden;
      background-color: $color-bg;
    }
    .loader::after {
      content: '';  
      width: 96px; height: 6px;
      background-color: $color-primary;
      position: absolute; top: 0; left: 0;
      box-sizing: border-box;
      animation: hit 0.6s ease-in-out infinite alternate;
    }

    @keyframes hit {
      0% {
        left: 0;
        transform: translateX(-1%);
      }
      100% {
        left: 100%;
        transform: translateX(-99%);
      }
    }
  }
</style>