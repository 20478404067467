<template>
  <div :class="['textarea-control', { pop: popActive }]">
    <label v-if="label != '' && pop && popActive">{{ label }}</label>
    <div> 
      <header>
        <label v-if="label != ''">{{ label }}</label>
        <a href="#" @click.prevent="togglePop" v-if="pop && popActive">Fechar</a>
      </header>
      
      <!-- <a href="#" @click.prevent="togglePassView()" v-if="type === 'password'" class="btPassWatch">
        <fa :icon="['fas', 'eye']" v-if="!showPass"></fa>
        <fa :icon="['fas', 'eye-slash']" v-else></fa>
      </a> -->
      <fa :icon="['fas', 'asterisk']" class="requiredIcon color-primary" v-if="required" />
      <textarea
        v-if="!masking"
        :type="type"
        :class="[
          'form-control',
          { 
            error: !valid,
            resize: resize,
            textonly: textonly,
            popped: popActive,
          },
          cssClass,
          size,
        ]"
        :name="name"
        :placeholder="placeholder"
        @focus="focus"
        @blur="blur"
        @input="input"
        @keyup="keyup"
        @keydown="keydown"
        v-bind="$attrs"
        v-bind:value="modelValue"
        :autocomplete="autocomplete"
        ref="input"
        :required="required"
        :disabled="disabled"
        :readonly="textonly"
      ></textarea>
      <span v-if="maxlength && modelValue" class="counter">
        Restam <b>{{ parseFloat(maxlength) - modelValue.length }}</b> caracteres
      </span>
      <a href="#" class="popToggle" v-if="pop && !popActive" @click.prevent="togglePop">
        <fa :icon="['fas', 'chevron-up']" class="ic" />
        <fa :icon="['fas', 'chevron-down']" class="ic" />
      </a>
    </div>
    <div class="popReplace" :style="{ height: fakeHeight + 'px' }" v-if="pop && popActive">
      <fa :icon="['fas', 'pen-field']" />
    </div>
  </div>
</template>
<script>
import { mask } from 'vue-the-mask'

export default {
  name: "textarea-control",
  components: {

  },
  directives: { 
    mask
  },
  props: {
    type: {
      type: String,
      default: "text"
    },
    class: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    valid: {
      type: Boolean,
      default: true
    },
    autocomplete: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    modelValue: {
      default: ""
    },
    cssClass: {
      type: String,
      default: ""
    },
    masking: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: [Number, String]
    },
    size: {
      type: String,
      default: 'regular' //small, regular, large 
    },
    resize: {
      type: Boolean,
      default: false
    },
    textonly: {
      type: Boolean,
      default: false
    },
    pop: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
     showPass: false,
     masks: {
      telefone: ['+## (##) ####-####', '+## (##) #####-####'],
      cpf: ['###.###.###-##', '##.###.###/####-##'],
      cep: '#####-###',
      uf: 'AA',
      cartao: ['####.######.#####', '####.###.##.###', '####.######.####', '####.####.####.####'],
      validade_cartao: ['##/##'],
      cvv: ['###', '####', '#####', '######'],
      percentual: ['#%', '##%', '###%']
     },
     masked: true,
     popActive: false,
     fakeHeight: null
    }
  },
  watch: {
    value (newVal, oldVal) {
      console.log("newVal:", newVal)
      console.log("oldVal:", oldVal)
    }
  },
  mounted () {
    if ( this.maxlength ) {
      console.log("maxlength:", this.maxlength)
    }
    
    console.log("textonly - el:", this.textonly)

    if ( this.textonly ) {
      const el = this.$refs.input
      console.log("textonly - el:", el)

      el.style.height = "1px"
      el.style.height = el.scrollHeight + "px"
    }

    if ( this.pop ) {
      // console.log("input height:", this.$refs.input.clientHeight)
      this.fakeHeight =  this.$refs.input.clientHeight
    }
    // this.inputType = this.type
    // console.log("type:", this.inputType)
  },
  methods: {
    focus (data) {
      this.$emit("focus", data)
    },
    blur (data) {
      this.$emit("blur", data)
    },
    // change (data) {
    //   this.$emit("update:modelValue", data.target.value)
    // },
    keyup (data) {
      this.$emit("keyup", data)
    },
    keydown (data) {
      this.$emit("keydown", data)
    },
    input (data) {
      this.$emit("update:modelValue", data.target.value)
    },
    togglePassView () {
      if ( this.showPass ) {
        this.showPass = false
        this.inputType = 'password'
      } else {
        this.showPass = true
        this.inputType = 'text'
      }
    },
    getMask () {
      console.log("masking:", this.masking)

      let theMask

      if ( this.masking ) {
        theMask = this.masks[this.masking]

        console.log("masking:", theMask)

        // console.log("result:", this.masks[this.mask])

        return theMask
      }
      // console.log("getMask - masks:", this.masks)
      
    },
    togglePop () {
      this.popActive = !this.popActive
    }
  }
}
</script>
<style lang="scss" scoped>
//forms
.textarea-control {
  position: relative; resize: none;
  width: 100%; overflow: visible;

  header {
    display: flex; align-items: center;
    justify-content: space-between;
    margin: 0; padding: 0;

    a {
      padding: $hmg_mini $mg_mini;
      border-radius: 2em;
      background-color: $color-bg;
      margin-bottom: $mg_mini;
    }
  }
  
  .popReplace {
    width: 100%;
    border-radius: $border-radius;
    border: 2px dashed $color-component;
    display: flex; flex-direction: column;
    justify-content: center; align-items: center;
    font-size: 64px;
  }

  >div {
    @extend %transition_4e;
  }
  &.pop {
    >div:not(.popReplace) {
      position: fixed; z-index: 200;
      max-width: 680px;
      left: 0; right: 0; margin: auto;
      top: 50%;
      transform: translateY(-50%);
      box-shadow: 0 3px 16px rgba(0,0,0,0.1);
      padding: $hmg_mid;
      border-radius: $border-radius-large;
      background-color: $color-secondary;
    }
  }

  .btPassWatch {
    position: absolute; top: 50%;
    transform: translateY(-50%);
    right: 15px;
    color: $color-primary;
  }
  .requiredIcon {
    position: absolute;
    top: -5px;
    right: $hmg_small;
    transform: none;
    z-index: 11;
    font-size: 10px;
  }
}
.form-group {
  margin-bottom: 20px;
  position: relative;
}

.counter {
  font-size: 12px;
  padding: $hmg_small;
}
.form-control {
  width: 100%; height: 120px;
  border: $border-component; border-radius: $border-radius;
  box-shadow: none; outline: none;
  padding: $hmg_small;
  resize: none;
  color: $color-text;
  @extend %transition_3o;
  @extend %scrollbar_card_small;

  &.resize {
    resize: vertical;
  }

  &.large {
    height: 300px;
  }
  &.xlarge {
    height: 440px;
  }
  &.small {
    height: 30px;
    font-size: 12px;
    padding: 0 $mg_mini;
  }

  &.small-radius {
    border-radius: $border-radius-small;
  }

  &:focus {
    @extend %shadow-mid;
    border: 0;
    outline: none;
  }

  &::-webkit-input-placeholder {
    color: $color-fade-low;
  }

  &:-ms-input-placeholder {
    color: $color-fade-low;
  }

  &::placeholder {
    color: $color-fade-low;
  }

  &.error {
    border: 2px solid $color-alert;
  }
  &.invert {
    background-color: $color-bg;
  }
  &:disabled,
  &[disabled='true'], 
  &[readonly='true']
  &.disabled,
  &.readonly { 
    background-color: $color-fade;
    pointer-events: none;
  }

  &.textonly {
    padding: 0;
    border: 0 !important;
    border-radius: 0;
    @extend %transition_3o;
    background-color: transparent !important;

    &:focus, &:hover {
      box-shadow: none !important;
      border: 0 !important;
    }
  }

  &.transparent {
    padding: 0;
    border: 0 !important;
    border-radius: 0;
    @extend %transition_3o;
    background-color: transparent !important;
    
    &.large {
      width: 100%;
      font-weight: bold;
    }
    &:focus, &:hover {
      box-shadow: none !important;
      border-bottom: $border-component-double;
    }
    &:disabled {
      background-color: transparent !important;
    }
  }

  &.mid-font {
    font-weight: bold;
    font-size: 16px;
  }

  &.popped {
    border: 2px solid $color-success-2 !important;
    min-height: 560px; max-height: 80vh
  }
}

.popToggle {
  display: flex; flex-direction: column;
  font-size: 8px; align-content: center;
  transform-origin: center; transform: rotate(45deg);
  position: absolute; bottom: $mg_mini; right: 12px;
  padding: $hmg_mini;

  .ic {
    @extend %transition_3o;
  }

  &:hover {
    .ic {
      &:first-child {
        transform: translateY(-10%)
      }
      &:last-child {
        transform: translateY(10%)
      }
    }
  }
}
</style>