<template>
  <controller>
    <page-header title="Tipos de Atendimentos">
      <template v-slot:side>
        <a href="#" @click.prevent="newCategoria()" class="btn btn-primary" v-if="!novo">
          <span>Nova Categoria</span>
        </a>
      </template>
    </page-header>
    <div>
      <form-spinner v-if="loading"/>
      <categorias v-if="categorias" :scope="categorias" @onSelectCategoria="editCategoria" @onNewCategoria="newCategoria" @onSave="cancelCreateEdit()"></categorias>
      <criar-editar-categorias v-if="novo || editar" :scope="categoria" @onCancel="closeNew()" @onSave="cancelCreateEdit(true)"></criar-editar-categorias>
    </div>
  </controller>
</template>

<script>
import Categorias from '@/components/atividades/template/atendimentos/categorias/CategoriasList.vue'
import Atendimentos from '@/services/atendimento.service.js'
import CriarEditarCategorias from '@/components/atividades/form/atendimentos/categorias/CriarEditarCategorias.vue'
import FormSpinner from '@/components/utilities/FormSpinner.vue'

export default {
  components: {
    Categorias, CriarEditarCategorias, FormSpinner
  },
  props: {
    action: {
      type: String
    }
  },
  data () {
    return {
      novo: false,
      editar: false,
      categorias: null,
      categoria: null,
      filter: null,
      loading: false
    }
  },
  mounted () {
    this.getCategoriasAtendimentos()
  },
  methods: {
    closeNew () {
      this.$emit("lockScroll", false)
      this.novo = false
      this.editar = false
    },
    getCategoriasAtendimentos () {
      this.loading = true
      Atendimentos.getCategoriasAtendimentos().then(
        response => {
          console.log("getCategoriasAtendimentos:", response.data)
          if ( response.data.length > 0 ) {
            this.categorias = response.data
          } 

          this.loading = false
        },
        () => {
          this.loading = false
        }
      )
    },
    editCategoria ( categoria ) {
      this.editar = true
      this.novo = false
      this.state = 'Editar'
      this.categoria = categoria
    
    },
    newCategoria () {
      this.editar = false
      this.novo = true
      this.state = 'Criar'
      this.categoria = null
    },
    cancelCreateEdit ( refresh ) {
      this.categoria = null
      this.novo = false
      this.editar = false

      if ( refresh ) this.getCategoriasAtendimentos()
    }
  }
}
</script>

<style>

</style>