<template>
  <div class="listBox">
    <div>
      <h6 class="color-text">Formas de Pagamento</h6>
    </div>
    <div class="listWrap">
      <ul>
        <li v-for="formas in formasPagamento" :key="formas">
          <label class="check-control large">
            <span>{{ formas.descricao }}</span>
            <input type="checkbox" class="check " :value="formas.idformapagamentomaterial" v-model="formas.selected" @change="select" />
          </label>
        </li>
      </ul>
    </div>
    <div>
      *Aqui são apresentadas apenas formas de pagamento vigentes
    </div>
  </div>
</template>

<script>
export default {
  props: {
    scope: {
      type: Array
    }
  },
  watch: {
    scope ( value ) {
      // console.log("watch scope:", value) 
      this.formasPagamento = value
      this.$nextTick(() => {
        this.setup()
      })
    }
  },
  data () {
    return {
      selected: [],
      formasPagamento: []
    }
  },
  mounted () {

  },
  methods: {
    setup () {
      this.formasPagamento.map( k => {
        if ( k.selected ) k.selected = true
      })
    },
    select() {
      this.$emit('select', this.formasPagamento)
    }
  }
}
</script>

<style lang="scss" scoped>
  .listBox {
    display: flex; flex-direction: column;
    align-content: stretch; align-items: stretch;
    width: 100%;
    border-radius: $border-radius;
    background-color: $color-secondary;
    margin-top: $mg;

    >div {
      h6 {
        margin: 0;
        padding: 0;
      }
      p {
        line-height: 1.1
      }
      
      &:not(.listWrap) {
        padding: $hmg_small;
      }
      // &.listWrap {
      //   margin-bottom: $hmg
      // }

      ul {
        height: 250px; width: 100%;
        overflow: auto;
        @extend %scrollbar;
        padding: 0; margin: 0;
        border-top: 1px solid $color-bg;
        border-bottom: 1px solid $color-bg;

        li {
          width: 100%;
          padding: 0; margin: 0;
          list-style: none;
          @extend %transition_3o;

          &:hover {
            background-color: $color-bg
          }
        }
      }
    }
  }
</style>