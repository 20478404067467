<template>
  <section>
    <header>
      <h4>Selecione o motivo do cancelamento</h4>
      <p>Conta para a gente por que você quer cancelar estes itens.</p>
    </header>
    <div>
      <template v-if="motivos.length > 0">
        <ul class="item-list" >
          <li v-for="motivo in motivos" :key="motivo">
            <label>
              <span>{{ motivo.descricao }}</span>
              <input type="radio" name="motivos_cancelamento" v-model="selected" :value="motivo" class="radio">
            </label>
          </li>
        </ul>
        <div class="form-group">
          <h5>Você quer dar mais detalhes?</h5>
          <textarea-control v-model="observacao" placeholder="Nos conte mais sobre o acontecido.." cssClass="invert"></textarea-control>
        </div>
      </template>
      <input-spinner v-else></input-spinner>
    </div>
    <footer>
      <a href="#" class="btn btn-primary" @click.prevent="save">Continuar</a>
    </footer>
  </section>
</template>

<script>
import MarketplaceServices from '@/services/marketplace.service.js'
import InputSpinner from '@/components/utilities/InputSpinner.vue'

export default {
  components: {
    InputSpinner
  },
  data () {
    return {
      motivos: [],
      selected: null,
      observacao: null
    }
  },
  mounted () {
    this.getMotivosCancelamento()
  },
  methods: {
    getMotivosCancelamento () {
      MarketplaceServices.getMotivosCancelamento().then(
        response => {
          console.log("getMotivosCancelamento:", response.data)
          this.motivos = response.data
        },
        () => {
          
        }
      )
    },
    save () {
      if ( this.selected ) {
        const drop = {
          motivo: this.selected,
          observacao: this.observacao
        }
        this.$emit('onSave', drop)
      } else {
        window.toaster.emit('open', {style: "warning", message: 'Selecione o motivo para prosseguir...', floater: true})
      }
      
    }
  }
}
</script>

<style lang="scss" scoped>
  section {
    @extend %transition_3o;
  }
  
  header {
    margin-bottom: $hmg
  }

  .item-list {
    padding: 0; margin: 0 0 $mg 0;

    li {
      list-style: none;
      padding: 0;
      border-bottom: 1px solid $color-bg;

      label {
        display: flex;
        padding: $hmg_mid $hmg $hmg_mid 0;
        justify-content: space-between;
        font-size: 1.1rem;
        font-weight: bold;
        @extend %transition_3o;
        margin: 0;
        
        &:hover{
          background-color: $color-bg;
          padding: $hmg_mid $hmg $hmg_mid $hmg_mid;
        }
        
        .radio {
          position: relative;
          appearance: none !important;

          &::after {
            content: "";
            width: 24px; height: 24px;
            background-color: #DDD;
            -webkit-mask-image: url('@/assets/img/check-solid.svg');
            mask-image: url('@/assets/img/check-solid.svg');
            mask-repeat: no-repeat; mask-size: contain;
            position: absolute; top: 50%; left: 50%;
            transform: translate(-50%, -50%) scale(.5);
            transform-origin: center;
            font-weight: 900;
            font-variant: normal; font-style: normal;
            text-align: center;
            line-height: 1;
            font-size: 24px !important;
            line-height: 1;
            @extend %transition_3e;
          }
          &:checked {
            &::after {
              transform: translate(-50%, -50%) scale(1);
              background-color: $color-primary;
            }
          }
        }
      }
    }
  }
  footer {
    margin-top: $hmg;
    display: flex; justify-content: flex-end;
  }

  .form-group {
    h5 { margin-bottom: $mg_mini !important }
  }
</style>