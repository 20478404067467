<template>
  <section ref="alcadaForm">
    <div v-if="alcada" class="frame fixed">
      <div class="row no-padd">
        <div class="col-md-4 form-group">
          <label>Unidade</label>
          <select-control 
            v-model="alcada.idUnidade"
            name="idUnidade"
            :options="options.unidades"
            placeholder="Selecione"
            @change="getPeriodosLetivos(); valid.idUnidade = true"
            :disabled="loading || state == 'editar'  || state == 'criar'"
            :valid="valid.idUnidade"
            required
          />
        </div>
        <div class="col-md-4 form-group">
          <label>Período Letivo</label>
          <select-control
            v-model="alcada.idPeriodoLetivo"
            :options="options.periodosLetivos"
            placeholder="Selecione"
            name="idPeriodoLetivo"
            @change="valid.idPeriodoLetivo = true; getSeries()"
            :disabled="loading || state == 'editar' || state == 'criar'"
            :valid="valid.idPeriodoLetivo" 
            required
          />
        </div>
        <div class="col-md-4 form-group">
          <label>Série</label>
          <select-control
            v-model="alcada.idSerie"
            :options="options.series"
            placeholder="Selecione"
            name="idPeriodoLetivo"
            @change="valid.idSerie = true"
            :disabled="loading || !options.series || state == 'editar' || state == 'criar'"
            :valid="valid.idSerie" 
            required
          />
        </div>
      </div>
      <div class="row no-padd">
        <div class="col-md-4 form-group" >
          <label>Tipo Matrícula</label>
          <select-control 
            v-model="alcada.tipoMatricula" 
            :options="options.tipoMatricula" 
            placeholder="Selecione" 
            name="tipoMatricula"
            :disabled="loading || filterScope.tipoMatricula"
            :valid="valid.tipoMatricula"
            @change="valid.tipoMatricula = true"
            required
          />
        </div>
        <div class="col-md-4 form-group">
          <label>Tipo Arrecação</label>
          <select-control 
            v-model="alcada.idTipoArrecadacao" 
            :options="options.arrecadacoes" 
            placeholder="Selecione" 
            :disabled="loading || state == 'editar' || state == 'criar'"
            name="idTipoArrecadacao"
            :valid="valid.idTipoArrecadacao"
            @change="valid.idTipoArrecadacao = true"
            required
          />
        </div>
        <div class="col-md-4 form-group">
          <label>Função</label>
          <select-control 
            v-model="alcada.idFuncao" 
            :options="options.funcoes" 
            placeholder="Selecione" 
            :disabled="loading || state == 'editar' || state == 'criar'"
            name="idFuncao"
            :valid="valid.idFuncao"
            @change="valid.idFuncao = true"
            required
          />
        </div>
      </div>
      <div class="row no-padd">
        <div class="col-md-4 form-group">
          <label>Percentual</label>
          <input-control 
            v-model="alcada.percentualLimite" 
            placeholder="Ex. 25" 
            type="number"
            name="percentualLimite"
            :valid="valid.percentualLimite"
            @change="valid.percentualLimite = true; setLimit()"
            required
          />
        </div>
      </div>
      <div class="row no-padd">
        <div class="col-md-12 form-actions form-group">
          <a href="#" @click.prevent="save()" class="btn btn-primary">
            Salvar Alçada
          </a>
          <a href="#" @click.prevent="cancel()" class="btn btn-secondary">
            Cancelar
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AdmService from '@/services/adm.service.js'
import UserService from '@/services/user.service.js'
import FinanceiroService from '@/services/financeiro.service.js'
import AcademicoService from '@/services/academico.service.js'
import { createSelectOptions } from '@/services/utils.service.js'

export default {
  props: {
    scope: {
      type: Object
    },
    filterScope: {
      type: Object
    },
    state: {
      type: String
    }
  },
  data () {
    return {
      alcada: null,
      loading: false,
      options: {
        unidades: [],
        periodosLetivos: [],
        funcoes: [],
        series: [],
        tipoMatricula: [
          { label: 'Matrícula', value: "1" },
          { label: 'Rematrícula', value: "2" }
        ],
        arrecadacoes: []
      }, 
      wait: {
        unidades: false,
        funcoes: false,
        arrecadacoes: false
      },
      valid: {
        idUnidade: true,
        idPeriodoLetivo: true,
        idSerie: true,
        idFuncao: true,
        percentualLimite: true,
        tipoMatricula: true,
        idTipoArrecadacao: true
      }
    }
  },
  mounted () {
    this.alcada = Object.assign({}, this.scope)

    console.log("alcada:", this.alcada)

    this.getUnidades()
    this.getArrecadacoes()
    this.getFuncoes()

    if ( this.alcada.idUnidade ) {
      this.getPeriodosLetivos()
    }
    if ( this.alcada.idPeriodoLetivo ) {
      this.getSeries()
    }
  },
  methods: {
    getUnidades () {
      UserService.getMinhasUnidades().then(
        response => {
          console.log("getMinhasUnidades:", response.data)
          
          if ( response.data.length > 0 ) {
            this.options.unidades = createSelectOptions(response.data, 'idUnidade' ,'descricao')
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhuma unidade encontrada. Verifique sua conexão e tente novamente.", floater: true})
          }
          
          this.wait.unidades = true
          this.finishLoad()
        },
        () => {
          window.spinner.emit("close")
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma unidade encontrada. Verifique sua conexão e tente novamente.", floater: true})
        }
      )
    },
    getPeriodosLetivos () {
      this.loading = true
      AcademicoService.getPeriodosLetivos(this.alcada.idUnidade).then(
        response => {
          console.log("getPeriodosLetivos:", response.data)
          
          if ( response.data.length > 0 ) {
            this.options.periodosLetivos = createSelectOptions(response.data, 'idPeriodoLetivo' ,'descricao').reverse()
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum período letivo encontrado para esta unidade. Isto parece ser um problema de parametrização.", floater: true})
          }
          this.loading = false
        },
        error => {
          console.error(error)
          this.loading = false
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum período letivo encontrado para esta unidade. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    getArrecadacoes () {
      window.spinner.emit('open')
      FinanceiroService.getArrecadacoes().then(
        response => {
          console.log("getArrecadacoes:", response)
          if ( response.data.length > 0 ) {
            this.options.arrecadacoes = createSelectOptions(response.data, 'idTipoArrecadacao' ,'descricao')
          } else {
            window.toaster.emit('open', {style: "error", message: "Não foi possível recuperar as arrecadações. Isto parece ser um problema de integração com o GestorClass.", floater: true})
          }
          
          this.wait.arrecadacoes = true
          this.finishLoad()
        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível recuperar as arrecadações.", floater: true})
          window.spinner.emit('close')
        }
      )
    },
    getFuncoes () {
      AdmService.getFuncoes().then(
        response => {
          console.log("getFuncoes:", response.data)
          if ( response.data.length > 0 ) {
            this.options.funcoes = createSelectOptions(response.data, 'idFuncao' ,'nome')
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhuma função encontrada. Isto parece um problema de parametrização.", floater: true})
          }

         this.wait.funcoes = true
         this.finishLoad()
        },
        () => {
          window.spinner.emit("close")
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma função encontrada. Verifique sua conexão e tente novamente.", floater: true})
        }
      )
    },
    getSeries () {
      this.loading = true

      AcademicoService.getSeries(this.alcada.idPeriodoLetivo, 0).then(
        response => {
          console.log("getSeries:", response.data)
          this.options.series = []

          if ( response.data.length > 0 ) {
            this.series = response.data
            response.data.map( k => {
              k.label = k.descricao + ' ' + k.descricaoTurno
            })
            this.options.series = createSelectOptions(response.data, 'idSerie' ,'label')
            
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhuma série encontrada para este período letivo. Isto parece ser um problema de parametrização.", floater: true})
          }

          this.loading = false
        },
        error => {
          console.error(error)
          this.loading = false
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma série encontrada para este período letivo. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    finishLoad () {
      const finished = !Object.values(this.wait).some( a => !a)

      if ( finished ) {
        window.spinner.emit("close")
        this.loading = false
      }
    },
    cancel() {
      this.$emit('onCancel')
    },
    save () {
      FinanceiroService.saveAlcada( [this.alcada] ).then(
        response => {
          console.log("response:", response)
          if ( response.status == 200 ) {
            this.$emit("onUpdate")
            window.toaster.emit('open', {style: "success", message: "Tudo certo! Alçada criada com sucesso.", floater: true})
          }
        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops. Algo Aconteceu. Não foi possível salvar esta alçada.", floater: true})
        }
      )
      
    },
    setLimit () {
      if ( this.alcada.percentualLimite > 100 ) {
        this.alcada.percentualLimite = 100
      } else if ( this.alcada.percentualLimite == 0 ) {
        this.alcada.percentualLimite = 1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  
</style>