<template>
  <loja-controller :goToTopButton="true">
    <loja-page-header>{{ decodeURI(nome) }}</loja-page-header>
    <div class="frame">
      <!-- <spinner color="#FFF"/> -->
      <div class="productsList" v-if="list.length > 0 && !loading">
        <div v-for="item in list" :key="item" >
          <loja-product-card :produto="item"></loja-product-card>
        </div>
      </div>
      <div v-if="list.length == 0 && !loading">
        <div class="emptyMessage">
          <fa :icon="['fal', 'face-meh' ]" class="icon"></fa>
          <p>Ops. Parece que ainda não existem produtos nesta categoria.</p>
        </div>
      </div>
    </div>
  </loja-controller>
</template>

<script>
import LojaService from '@/services/loja.service';
import LojaProductCard from '@/components/loja/template/ProductCard'

export default {
  name: "Categoria",
  components: {
    LojaProductCard
  },
  props: {
    idCategoria: {
      type: [Number, String]
    },
    nome: {
      type: String
    }
  },
  watch: {
    idCategoria ( value ) {
      if ( value ) {
        this.fetchCategory()
        this.fetchProducts()
      }
    }
  },
  data () {
    return {
      list: [],
      loading: false,
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null
    }
  },
  beforeMount () {
    // var unidades =  []
    // this.currentUser?.alunos?.map( k => {
    //   unidades.push(k.idUnidade)
    // })

    // if ( unidades.includes(27) && ( this.idCategoria == 1 || this.idCategoria == 2 ) ) {
    //   this.$router.push("/loja")
    // }
  },
  mounted () {
    if ( this.idCategoria ) {
      // this.fetchCategory()
      this.fetchProducts()
    }
  },
  methods: {
    fetchCategory () {
      LojaService.getDetahesCategoria(this.idCategoria).then(
          response => {
            if ( response.data ) {
              console.log("response cat:", response.data)
            }
          },
          error => {
            console.log('error', error)
          }
        )
    },
    fetchProducts () {
      this.list = []
      this.loading = true
      window.spinner.emit("open")

      const data = {
        categoriasIds: [this.idCategoria],
        subCategoriasIds: [],
        text: null,
        idPessoaResponsavel: this.currentUser?.idPessoa
      }

      console.log("search: ", data)

      LojaService.buscaProdutosLoja(data).then(
        response => {
          if ( response.data ) {
            console.log("response busca:", response.data)
            
            const eligibilty = LojaService.processProductsAccess(response.data, this.currentUser, this.idCategoria)
            
            this.list = eligibilty
            window.spinner.emit("close")
            this.loading = false

            console.log("eligibilty:", eligibilty)
          }
        },
        error => {
          console.log('error', error)
          window.spinner.emit("close")
          this.loading = false
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
  
</style>