<template>
  <div class="page-filter">
    <header>
      <h4>{{ title }} Permissão</h4>
    </header>
    <form method="POST">
      <div class="row nop nom">
        <div class="form-group col-md-4">
          <input-control type="text" placeholder="Nome" v-model="nome" />
        </div>
        <div class="form-group col-md-4">
          <select-control type="text" placeholder="Setor" v-model="setor" :options="setores" />
        </div>
      </div>
      <div class="dragPanel">
        <div v-if="criarID != null">
          <div>
            <h4>Permissões disponíveis</h4>
            <div class="dragPanelWrap">
              <draggable
                class="list-group"
                :list="listRoles"
                group="roles"
                itemKey="name"
              >
                <template #item="{ element }">
                  <div class="list-group-item">{{ element.name }}</div>
                </template>
              </draggable>
            </div>
          </div>
          <div>
            <h4>Permissões selecionada(s)</h4>
            <div class="dragPanelWrap">
              <draggable
                class="list-group"
                :list="listRolesSelected"
                group="roles"
                itemKey="name"
              >
                <template #item="{ element }">
                  <div class="list-group-item selected-item">
                    <span>
                      {{ element.name }}
                    </span>
                    <div v-if="element.home != null" class="addToHome" @click="addToHome(element)" :class="{selected: isSelected(element.id)}">
                      <div>
                        <span>
                          {{ isSelected(element.id) ? 'Remover da home' : 'Adicionar a home' }}
                        </span>
                        <fa :icon="['far','house']"></fa>
                      </div>
                    </div>
                  </div>
                </template>
              </draggable>
            </div>
          </div>
        </div>
      </div>
      <div class="form-actions row">
        <a href="#" class="btn btn-primary" @click.prevent="saveFuncao" v-if="criarID === null">
          Salvar
        </a>
        <a href="#" class="btn btn-primary" @click.prevent="editFuncao" v-else>
          Salvar
        </a>
        <a href="#" class="btn btn-primary" @click.prevent="close">
          Cancelar
        </a>
      </div>
    </form>
  </div>
</template>
<script>
import axios from 'axios'
import authHeader from '../../../services/auth-header'
import draggable from 'vuedraggable'

export default {
  name: "criarFuncoes",
  components: {
    draggable
  },
  props: {
    criarID: {
      type: [Number, String, Object],
      default: null
    }
  },
  watch: {
    criarID (newVal) {
      this.id = newVal.idFuncao
      this.nome = newVal.nome
      this.setor = newVal.idSetor
      this.home_url = newVal.home_url
      this.listRolesSelected = []
      this.listRoles = []
      this.getAllPermissionsFromFuncao()
    }
  },
  data () {
    return {
      title: null,
      id: null,
      nome: null,
      setor: null,
      // home_url: null,
      urls_home: [],
      setores: [],
      listRolesSelected: [],
      listRoles: [],
      toHome: []
    }
  },
  mounted () {
    setTimeout(() => {
      this.getSetores()
      this.title = this.criarID === null ? "Criar" : "Editar"
      this.criarID != null ? this.getAllPermissionsFromFuncao() : this.getAllPermissions()
    },100)
  },
  methods: {
    close () {
      this.$emit('close','')
    },
    getSetores () {
      axios.get(process.env.VUE_APP_API_URL + 'auth/getlistasetores',{ headers: authHeader() })
      .then( response => {
        for (let index = 0; index < response.data.length; index++) {
          this.setores.push({label: response.data[index]['descricao'], value: response.data[index]['idSetor']})
        }
      })
      .catch( error => {
        console.error("error:", error)
      })
    },
    getAllPermissionsFromFuncao () {
      axios.get(process.env.VUE_APP_API_URL + 'auth/allpermissionsfromfuncao/' + this.id,{ headers: authHeader() })
      .then( response => {
        this.listRolesSelected = []
        console.log("listRolesSelected", response.data)
        
        response.data.map(item => {
          if ( 
            item.angular === 1 && item.externo === 1 && item.numeroDashboard === null && item.router_link == '0' ||
            item.angular === 1 && item.externo === 1 && item.nome_modulo === null && item.router_link == '0'
          ) {
            item.home = null
          }
          
          this.listRolesSelected.push(item)
        })
        // for (let index = 0; index < response.data.length; index++) {
        //   this.listRolesSelected.push({name: response.data[index]['name'], id: response.data[index]['id'], home: response.data[index]['home']})
        // }

        console.log("listRolesSelected", this.listRolesSelected)

        this.getAllPermissions ()

      })
      .catch( error => {
        console.error("error:", error)
      })
    },
    getAllPermissions () {
      axios.get(process.env.VUE_APP_API_URL + 'auth/getallpermissions',{ headers: authHeader() })
      .then( response => {
        this.listRoles = []
        // console.log("data - listRoles", response.data)

        response.data.forEach((item) => {
          const itemFiltro = this.listRolesSelected.find(
            (itemLista) =>
              itemLista.id == item.id
          )
          if (!itemFiltro) {
            this.listRoles.push({name: item['name'], id: item['id'], home: 0})
          }
          
          if (item['tipo_url'] != 'blank' && item['tipo_url'] != 'self' && item['router_link'] != 0) {
              this.urls_home.push({label: item['name'], value: item['id']})
          }
          
        })

        console.log("listRoles", this.listRoles)

      })
      .catch( error => {
        console.error("error:", error)
      })
    },
    editFuncao () {
      window.spinner.emit('open')

      // console.log("this.id:", this.id)
      console.log("this.listRolesSelected:", this.listRolesSelected)
      // console.log("this.setor:", this.setor)
      // console.log("this.home_url:", this.home_url)
      // console.log("this.nome:", this.nome)

      axios.put(process.env.VUE_APP_API_URL + 'funcao/' + this.id, {
        nome: this.nome,
        idSetor: this.setor,
        idFuncao: this.id,
        home_url: this.home_url,
        listRolesSelected: this.listRolesSelected
      },{ headers: authHeader() })
      .then(response => {
        this.close()
        window.toaster.emit('open', {style: "success", message: "Função editada com sucesso."})
        this.$emit('updateParentList','')
        return response.data;
      }).then(() =>{
        window.spinner.emit('close')
      })
    },
    saveFuncao () {
      window.spinner.emit('open')
      axios.post(process.env.VUE_APP_API_URL + 'funcao', {
          nome: this.nome,
          home_url: this.home_url,
          idSetor: this.setor
      },{ headers: authHeader() })
      .then(response => {
        this.close()
        window.toaster.emit('open', {style: "success", message: "Função criada com sucesso."})
        this.$emit('updateParentList','')
        return response.data;
      }).then(() =>{
        window.spinner.emit('close')
      })
    },
    addToHome (el) {
      this.listRolesSelected.map(k => {
        if ( k.id === el.id ) {
          k.home = k.home ? 0 : 1 
        }
      })

      // console.log("addToHome: ", this.listRolesSelected)
    },
    isSelected (el) {
      let is = false
      this.listRolesSelected.map(k => {
        if (k.id == el) is = k.home
      })

      return is
      // toHome?.includes(element.id)
    }
  }
}
</script>
<style lang="scss" scoped>
  form {
    padding: 0 $mg_mini
  }
  header {
    padding: $hmg_mid
  }
  footer {
    padding: 0 $hmg_mid $hmg_mid $hmg_mid;
    display: flex; align-items: center;
    gap: $mg_mini
  }
  .dragPanel {
    max-height: 60vh; overflow: hidden;
    padding: $hmg_small; margin-bottom: $hmg;

    @media screen and (max-width: 1366px) {
      padding: 0 $hmg $hmg_small $hmg;
    }
    // @media screen and (max-width: 1024px) {
    //   padding: 0 $hmg;
    // }

    > div {
      display: flex; align-items: stretch; align-content: stretch;
      border-radius: 10px; background-color: #FFF;
      border: $border-component; gap: $hmg_mid;
      padding: $hmg_mid; max-height: 60vh;

      > div {
        width: 100%; 
        display: block;
        flex: 1; padding: $hmg_mid;
        border-radius: $border-radius-small;
        background-color: $color-bg;
        border: $border-component;
        overflow-y: auto;
        @extend %scrollbar;

        h4 {
          flex: 0;
          margin-bottom: 10px;
        }
      }
    }

    .selected-item {
      position: relative;

      .addToHome {
        position: absolute; right: 10px;
        top: 50%; transform: translateY(-50%);
        cursor: pointer;

        >div{
          display: flex; align-items: center; align-content: center; justify-content: center;
          height: 24px; width: 24px; overflow: hidden;
          line-height: 1; text-transform: uppercase; font-size: 14px;
          border-radius: 2em; padding: 0;
          transform-origin: right center; 
          @extend %transition_4e;

          >span{
            font-size: 11px; padding-right: 0;
            opacity: 0; position: absolute;
            transform: translate3d(0,12px,32px) rotate(25deg);
            transform-origin: right center; 
            @extend %transition_4e;
          }
        }

        &:hover {
          > div{
            width: auto; 
            padding: 0 8px 0 10px;
            background: $color-success; color: #FFF;
            @extend %shadow-low-success;
            
            >span{
              padding: 1px 6px 0 0; transform: none;
              opacity: 1; position: relative;
            }
          }
        }

        &.selected {
          > div {
            background: $color-success; color: #FFF;
          }
        }
      }
    }
  }

  .list-group {
    padding: 0
  }
  
  .list-group-item {
    border: 0; border-radius: 20px !important;
    margin: 5px;
    @extend %shadow-low-light;
  }

</style>