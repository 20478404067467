<template>
  <section>
    <header>
      <h4>Informe seus dados bancários</h4>
      <p>Caso sua compra precise de estorno financeiro, precisamos do seus dados bancários para fazer o depósito.</p>
    </header>
    <div v-if="bank_account" ref="bankForm">
      <div class="row no-padd">
        <div class="col-md-2 form-group no-padd-left">
          <label>Código banco</label>
          <input-control v-model="bank_account.bank_code" placeholder="0000" name="bank_code" type="number" :valid="refundValid.bank_code" @keyup="refundValid.bank_code = true" cssClass="invert" required/>
        </div>
      </div>
      <div class="row no-padd">
        <div class="col-md-2 form-group no-padd-left">
          <label>Agência</label>
          <input-control v-model="bank_account.agencia" name="agencia" :valid="refundValid.agencia" @keyup="refundValid.agencia = true" placeholder="0000" type="number" cssClass="invert" required/>
        </div>
        <div class="col-md-1 form-group">
          <label>Dígito</label>
          <input-control v-model="bank_account.agenciaDV" name="agenciaDV" placeholder="0" type="text" cssClass="invert" />
        </div>
        <div class="col-md-2 form-group">
          <label>Conta</label>
          <input-control v-model="bank_account.conta" name="conta" :valid="refundValid.conta" @keyup="refundValid.conta = true" placeholder="0000" type="number" cssClass="invert" required/>
        </div>
        <div class="col-md-1 form-group">
          <label>Dígito</label>
          <input-control v-model="bank_account.contaDV" name="contaDV" placeholder="0" type="text" cssClass="invert" />
        </div>
      </div>
    </div>
    <footer>
      <a href="#" class="btn btn-primary" @click.prevent="saveBankInfo">Continuar</a>
    </footer>
  </section>
</template>

<script>
import FormService from '@/services/form.service'

export default {
  props: {
    scope: {
      type: Object
    }
  },
  data () {
    return {
      bank_account: null,
      refundValid: {
        bank_code: true,
        refundValid: true,
        conta: true,
        agencia: true
      }
    }
  },
  mounted () {
    console.log("this.scope", this.scope)
    this.bank_account = Object.assign({}, this.scope)
  },
  methods: {
    validate () {
      const form = this.$refs.bankForm
      const valids = this.refundValid

      const validate = FormService.validate(form, valids)

      // console.log("validate:", validate)
      return validate.response
    },
    saveBankInfo () {
      const valid = this.validate()

      if ( valid ) {
        console.log("this.bank:", this.bank_account)
        this.$emit('onSave', this.bank_account)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  section {
    @extend %transition_3o;
  }
  header {
    margin-bottom: $hmg
  }
</style>