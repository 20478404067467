<template>
  <card :listed="false" boxed>
    <header>
      <div>
        <span :style="{ backgroundImage: `url('/img/apollinho.png')` }"></span>
      </div>
      <h4 v-if="perfilPedagogico">Perfil Pedagógico</h4>
      <h4 v-else>Perfis</h4>
    </header>
    <template v-if="perfis && perfilPedagogico">
      <div class="profile" >
        <h5>{{ perfis.tipo }}</h5>
        <textarea-control v-model="perfis.resumoPerfil" textonly/>
        <!-- <p v-html="perfil.resumoPerfil"></p> -->
        <p>Última Atualização: {{ formatDate(perfis.updatedAt) }}</p>
      </div>
    </template>
  </card>
</template>

<script>
import { formatDate } from '@/services/utils.service.js'
import UserService from '@/services/user.service.js'

export default {
  props: {
    id: {
      type: [String, Number]
    },
    atividade: {
      type: Object,
    }
  },
  data () {
    return {
      perfis: null,
      perfilPedagogico: false,
      perfilFinanceiro: false
    }
  },
  mounted () {
    const regex = /\?(.*?)\?/g;

    if ( this.atividade.relevantes.perfil.length > 0 ) {
      const clearChars = this.atividade.relevantes.perfil[0].resumoPerfil.replace(regex, "")
      this.perfis = {
        resumoPerfil: clearChars,
        updatedAt: this.atividade.relevantes.perfil[0].updatedAt
      }
    }

    this.perfilPedagogico = UserService.filterPermissions('perfilPedagogico')
    this.perfilFinanceiro = UserService.filterPermissions('perfilFinanceiro')
  },
  methods: {
    formatDate ( date ) {
      return formatDate(date)
    }
  }
}
</script>

<style lang="scss" scoped>
  header {    
    h4 {
      padding: 0; margin: $hmg_mid 0 0 0;
    }
    div {
      width: 50px;
      height: 50px;
      
      >span {
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background-size: cover; background-position: center;
        background-repeat: no-repeat;
        background-color: $color-bg;
        image-rendering: -webkit-high-quality;
        image-rendering: -webkit-optimize-contrast;
      }
    }
  }
  .profile {
    margin-top: $hmg_mid;
    
    h5 {
      text-transform: capitalize;
    }

    p {
      margin-top: $hmg_mid
    }
  }
</style>