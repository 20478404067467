<template>
  <controller :goToTopButton="true">
    <div class="page-title">
      <h3 class="text-capitalize">Próxima Turma</h3>
    </div>
    <div class="page-inner">
      <transition name="slideup-small">
        <filter-proxima-turma @onFilter="setupProximaTurma" @clear="clear" ref="filter"></filter-proxima-turma>
      </transition>
      <proxima-turma
        v-if="turmasOrigem && turmasDestino"
        :periodo="periodo"
        :unidade="unidade"
        :origem="turmasOrigem" 
        :destino="turmasDestino"
      ></proxima-turma>
    </div>
  </controller>
</template>

<script>
import ProximaTurma from '@/components/estruturaacademica/progressao/form/ProximaTurma.vue'
import FilterProximaTurma from '@/components/estruturaacademica/progressao/utilities/FilterProximaTurma.vue'
import AcademicoService from '@/services/academico.service.js'

export default {
  components: {
    FilterProximaTurma, ProximaTurma
  },
  data () {
    return {
      turmasOrigem: null,
      turmasDestino: null,
      unidade: null,
      periodo: null,
      wait: {
        origem: false,
        destino: false
      }
    }
  },
  methods: {
    setupProximaTurma ( data ) {
      window.spinner.emit("open")
      this.getTurmasOrigem(data.unidade, data.periodoLetivoOrigem)
      this.getTurmasDestino(data.unidade, data.periodoLetivoDestino)
      this.unidade = data.unidade
      this.periodo = data.periodoLetivoDestino
    },
    clear () {
      this.turmasOrigem = null
      this.turmasDestino = null
      this.unidade = null
      this.periodo = null
    },
    getTurmasOrigem (unidade, periodoLetivo) {
      const data = {
        idUnidade: unidade,
        idPeriodoLetivo: periodoLetivo
      }
      
      AcademicoService.getTurmasProximoCiclo( data ).then(
        response => {
          console.log("response:", response)
          if ( response.data.length > 0 ) {
            // window.toaster.emit('open', {style: "success", message: "Tudo certo! Turma criada com sucesso.", floater: true})
            this.turmasOrigem = response.data
          } else {
            window.toaster.emit('open', {style: "error", message: "Não encontramos turmas para o período letivo informado", floater: true})
          }
          this.wait.origem = true
          this.finishLoad()
        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível buscar turmas para o período letivo informao.", floater: true})
          window.spinner.emit('close')
        }
      )
    },
    getTurmasDestino (unidade, periodoLetivo) {
      const data = {
        idUnidade: unidade,
        idPeriodoLetivo: periodoLetivo
      }
      AcademicoService.getTurmasProximoCiclo( data ).then(
        response => {
          console.log("response:", response)
          if ( response.data.length > 0 ) {
            // window.toaster.emit('open', {style: "success", message: "Tudo certo! Turma criada com sucesso.", floater: true})
            this.turmasDestino = response.data
          } else {
            window.toaster.emit('open', {style: "error", message: "Não encontramos turmas para o período letivo informado", floater: true})
          }
          
          this.wait.destino = true
          this.finishLoad()
        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível buscar turmas para o período letivo informao.", floater: true})
          window.spinner.emit('close')
        }
      )
    },
    finishLoad () {
      const finished = !Object.values(this.wait).some( a => !a)

      if ( finished ) {
        window.spinner.emit("close")
      }
    },
  }
}
</script>

<style>

</style>