<template>
  <controller>
    <page-header title="Pesquisar Atividades"></page-header>
    <main ref="main">
      <filter-controller @onFilter="runFilter" @onProcessing="processing = $event" ref="filter"/>
      <form-spinner v-if="processing"/>
      <list-controller :scope="filter" v-if="filter" @paginate="paginate"/>
    </main>
  </controller>
</template>

<script>
import FilterController from '@/components/atividades/utilities/PesquisaFilter.vue'
import ListController from '@/components/atividades/template/PesquisaList.vue'
import FormSpinner from '@/components/utilities/FormSpinner.vue'

export default {
  components: {
    FilterController, ListController,
    FormSpinner
  },
  data () {
    return {
      processing: false,
      filter: null
    }
  },
  mounted () {

  },
  methods: {
    runFilter ( data ) {
      this.filter = data
    },
    paginate ( next ) {
      this.$refs.main.scrollIntoView({ behavior: "smooth", block: 'start' })
      this.$refs.filter.paginate(next)
    }
  }
}
</script>

<style lang="scss" scoped>
  main {

  }
</style>