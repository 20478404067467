<template>
  <controller :goToTopButton="true">
    <div class="page-title">
      <h3 class="text-capitalize">Planos de Pagamento</h3>
      <!-- <transition name="slideRightToLeft">
        <a href="#" @click.prevent="confirmSync()" class="btn btn-primary" v-if="!syncing">
          <span>Sincronizar Parâmetros</span>&nbsp;
          <fa :icon="['fas', 'cubes']"></fa>
        </a>
      </transition> -->
    </div>
    <div class="page-inner">
      <transition name="slideup-small">
        <filter-controller ref="filter" v-if="!precificacoes" @onFilter="runFilter"></filter-controller>
      </transition>
      <precificacoes :scope="precificacoes" v-if="precificacoes" @onReturn="precificacoes = null" @onSave="runFilter"></precificacoes>
    </div>
  </controller>
</template>

<script>
import filterController from '@/components/financeiro/planospagamento/utilities/FilterPlanos.vue'
import Precificacoes from '@/components/financeiro/planospagamento/form/Precificacoes.vue'
import FinanceiroService from  '@/services/financeiro.service.js'

export default {
  components: {
    filterController, Precificacoes
  },  
  props: {
    section: {
      type: String
    }
  },
  data () {
    return {
      filter: null,
      precificacoes: null
    }
  },
  mounted () {

  },
  methods: {
    runFilter ( filter ) {
      if ( filter ) {
        this.filter = filter
      }
      
      window.spinner.emit("open")
      FinanceiroService.getPrecificacao( this.filter ).then(
        response => {
          if ( response.data.length > 0 ) {
            this.precificacoes = response.data
          } else {
            window.toaster.emit('open', {style: "error", message: "Não foi possível encontrar precificações com os dados informados.", floater: true})
          }
          console.log("getPrecificacao:", response.data)
          window.spinner.emit("close")
        },
        () => {
          window.spinner.emit("close")
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não conseguimos processar sua solicitação.", floater: true})
        }
      )
      console.log("filter:", this.filter)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>