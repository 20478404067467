<template>
  <div class="ac-control">
    <input-control placeholder="Buscar" @keyup="search"></input-control>
    <div class="ac-list-wrap">
      <card class="ac-select-list">
        <div class="ac-select-list-inner">
          <ul class="autocomplete-list">
            <li v-for="item in options" :key="item" @click.prevent="select(item.id)">
              {{ item.value }}
            </li>
          </ul>
        </div>
      </card>
    </div>
    
    
  </div>
</template>

<script>
export default {
  props: {
    scope: {
      type: Array
    }
  },
  data () {
    return {
      options: null,
      backup: null
    }
  },
  mounted () {
    this.createOptions()
  },
  methods: {
    select ( item ) {
      console.log("item:", item)
    },
    createOptions () {
      this.options = JSON.parse(JSON.stringify(this.scope))
    }
  }
}
</script>

<style lang="scss" scoped>
  .ac-control{
    width: 100%;
    position: relative;

    .ac-list-wrap {
      position: relative; height: 300px;

      .ac-select-list{
        position: absolute;
        top: 0; left: 0;
        height: 100%; max-height: 300px;
        overflow: hidden;

        .ac-select-list-inner {
          padding: $hmg_small;
          height: 100%;
          
          ul {
            height: 100%; overflow: auto;
          }
        }
      }
    }
    
  }
  
</style>