<template>
  <section class="external-controller">
    <header>
      <div>
        <logo color="#ED1C24" size="40px" />
        <h2>{{ title }}</h2>
      </div>
      <a v-if="back" href="#" @click.prevent="back.action" class="btn btn-secondary">
        <fa :icon="['far', 'arrow-left-long']" class="icon"></fa>
        <span>{{ back.label }}</span>
      </a>
    </header>
    <slot></slot>
  </section>
</template>

<script>
import Logo from '@/components/template/logo.vue'

export default {
  name: 'External Controller',
  components: {
    Logo
  },
  props: {
    title: {
      type: String,
      default: 'Título da Sessão Externa'
    },
    back: {
      type: [Object, Boolean],
      default: () => {
        return {
          label: 'Sair',
          action: null
        }
      }
    }
  },
  data () {
    return {
      
    }
  },
  mounted () {

  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
  .external-controller {
    position: relative; height: 100vh; width: 100%;
    background-color: $color-bg;
    overflow-y: auto;
    @extend %scrollbar;

    header {
      display: flex; align-content: center; align-items: center; justify-content: space-between;
      width: 100%; padding: $hmg $hmg 0 $hmg; 

      >div {
        display: flex; align-content: flex-start; align-items: center;

        h2 {
          margin: 0 0 0 $hmg_small; padding: 0;
        }
      }
    }
  }
</style>