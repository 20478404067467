<template>
  <loja-controller>
    <detalhes :detalhes="productDetails"></detalhes>
  </loja-controller>
</template>

<script>
import LojaService from '@/services/loja.service'
import detalhes from '@/components/loja/template/Product'

export default {
  name: 'Produto',
  components: {
    detalhes                                    
  },
  props: {
    item: [String, Number],
    tipo: [String, Number],
    extra: [String,Number]
  },
  data () {
    return {
      productDetails: {},
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null
    }
  },
  mounted () {
    if ( this.item && this.tipo ) this.getProduct()
  },
  methods: {
    getProduct () {
      console.log("getProduct - item:", this.item)
      console.log("getProduct - tipo:", this.tipo)
      console.log("getProduct - extra:", this.extra)

      if ( this.extra == 1 ) {
        LojaService.getDetalhesServicoExtra(this.tipo).then(
          response => {
            console.log("getDetalhesServicoExtra:", response.data)
            const detalhes = response.data
            
            detalhes.idtipomaterial = !detalhes.idtipomaterial ? parseFloat(this.tipo) : detalhes.idtipomaterial
            // //push fake remaining content
            //       detalhes.detalhes = 'Maecenas nec lorem a risus tincidunt auctor vel rhoncus erat. Vivamus feugiat nisi quis nisl aliquam, eget congue sem tincidunt.'
            //       detalhes.precoVenda = '79.90'
            //       detalhes.categoria = 'Material Didático'
            
            this.productDetails = detalhes
          },
          error => {
            console.log('error', error)
          }
        )
      } else {
        LojaService.buscaProdutoTipoMaterial(this.tipo).then(
          response => {
            console.log("buscaProdutoTipoMaterial:", response.data)
            const eligibilty = LojaService.processProductsAccess([response.data], this.currentUser, response.data.idcategorialoja)
            const detalhes = eligibilty[0]
            detalhes.idtipomaterial = !detalhes.idtipomaterial ? parseFloat(this.tipo) : detalhes.idtipomaterial
            // //push fake remaining content
            //       detalhes.detalhes = 'Maecenas nec lorem a risus tincidunt auctor vel rhoncus erat. Vivamus feugiat nisi quis nisl aliquam, eget congue sem tincidunt.'
            //       detalhes.precoVenda = '79.90'
            //       detalhes.categoria = 'Material Didático'
            
            
            this.productDetails = detalhes
          },
          error => {
            console.log('error', error)
          }
        )
      }
      
    }
  }
}
</script>

<style lang="scss">

</style>