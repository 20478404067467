<template>
  <footer>
    <nav>
      <a href="#" class="btn btn-primary" :disabled="!allowPrev" @click.prevent="prev()">
        <fa :icon="['far','arrow-left-long']"></fa>
        &nbsp; Voltar
      </a>
      <div>
        <a href="#" class="btn btn-primary" v-if="activeStep.slug == 'contrato' && !auto" @click.prevent="finalize()" :disabled="!allowNext">
          Concluir Matrícula
        </a>
        <a href="#" class="btn btn-primary" :disabled="!allowNext" v-else-if="action == 'ver'" @click.prevent="next(true)">
          Continuar &nbsp; 
          <fa :icon="['far','arrow-right-long']"></fa>
        </a>
        <template v-else-if="action != 'ver' ">
          <a href="#" class="btn btn-primary" :disabled="!allowNext" v-if="activeStep.slug != 'contrato' || !auto " @click.prevent="next()">
            Salvar e Continuar &nbsp; 
            <fa :icon="['far','arrow-right-long']"></fa>
          </a>
        </template>
      </div>
    </nav>
  </footer>
</template>

<script>
export default {
  props: {
    allowPrev: {
      type: Boolean,
      default: false
    },
    allowNext: {
      type: Boolean,
      default: true
    },
    final: {
      type: Boolean,
      default: false
    },
    posted: {
      type: Object
    },
    activeStep: {
      type: Object
    },
    action: {
      type: String
    },
    auto: {
      type: Boolean,
      default: false
    },
  },
  watch: {
    activeStep ( value ) {
      if ( value ) {
        this.isPosted = this.activeStep.done && ( this.activeStep.slug == 'aluno' )
      }
    }
  },
  data () {
    return {
      isPosted: false
    }
  },
  mounted () {
    // console.log("activeStep....................", this.activeStep)
  },
  methods: {
    prev () {
      this.$emit('prev')
    },
    next ( nopost ) {
      this.$emit('next', nopost)
    },
    finalize () {
      this.$emit('finalize')
    }
  }
}
</script>

<style lang="scss" scoped>
  footer {
    display: block; width: 100%;
    height: auto; min-height: 40px;
    margin: $hmg 0; padding: 0 $hmg_small;
  
    nav {
      display: flex; width: 100%;
      align-content: center; align-items: center; justify-content: space-between;

      div {
        display: flex; width: 100%;
        align-content: center; align-items: center; justify-content: flex-end;

        .btn {
          margin-left: $mg_mini;
        }
      }
    }

    @media screen and ( max-width: 640px ) {
      margin-bottom: $mg
    }
  }
</style>