<template>
  <section>
    <header class="page-title inner">
      <h4 v-if="editar">Editar Fornecedor</h4>
      <h4 v-else-if="novo">Novo Fornecedor</h4>
      <h4 v-else>Fornecedores</h4>
      <a href="#" class="btn btn-primary" @click.prevent="addFornecedor()" v-if="!novo && !editar">
        Novo Fornecedor &nbsp;
        <fa :icon="['fas','person-carry-box']"></fa>
      </a>
      <a href="#" class="btn btn-secondary" @click.prevent="closeForm()" v-if="novo || editar">
        <fa :icon="['far','arrow-left-long']"></fa>
        &nbsp; Voltar
      </a>
    </header>
    <div v-if="novo || editar">
      <fornecedor :scope="fornecedor" @onClose="closeForm()"></fornecedor>
    </div>
    <div v-else>
      <div class="planilhasListWrap" v-if="fornecedores.length > 0 && loaded">        
        <ol class="cardList">
          <li>
            <div class="plItemInner">
              <span>
                ID
              </span>
              <span>
                Nome
              </span>
              <span>
                Contato
              </span>
              <span>
                Email
              </span>
              <span>
                CNPJ
              </span>
              <span></span>
            </div>
          </li>
          <li v-for="fornecedor in fornecedores" :key="fornecedor">
            <card spacing="small">
              <div class="plItemInner">
                <span>
                  {{ fornecedor.idFornecedor }}
                </span>
                <span class="fixed">
                  {{ fornecedor.nome }}
                </span>
                <span>
                  {{ fornecedor.contato }}
                </span>
                <span>
                  {{ fornecedor.email }}
                </span>
                <span>
                  {{ fornecedor.cnpj }}
                </span>
                <span class="actionWrap">
                  <a href="#" @click.prevent="getFornecedor(fornecedor, i)">
                    <fa :icon="['far','magnifying-glass']"></fa>
                  </a>
                </span>
              </div>
            </card>
          </li>
        </ol>
      </div>
    </div>
  </section>
</template>

<script>
import Fornecedor from './Fornecedor.vue'
import MarketplaceService from '@/services/marketplace.service.js'

export default {
  components: {
    Fornecedor
  },  
  data () {
    return {
      editar: false,
      novo: false,
      fornecedores: [],
      fornecedor: null,
      activeIndex: null,
      loaded: false,
      model: {
        nome: null,
        contato: null,
        fone: null,
        cnpj: null,
        endereco: null,
        numero: null,
        complemento: null,
        bairro: null,
        cep: null,
        idcidade: null,
        idcliente: 2,
        chaveProdutoNotaFiscal: null,
        gerarPedido: 0,
        emitirNota: 0,
        gerarPedidoVenda: 0,
        gerarNotaEntrega: 0,
        webmaniaConsumerKey: null,
        webmaniaConsumerSecret: null,
        webmaniaAccessToken: null,
        webmaniaAccessTokenSecret: null,
        classeImposto: null,
        email: null
      }
    }
  },
  mounted () {
    this.getFornecedores()
  },
  methods: {
    getFornecedores () {
      window.spinner.emit("open")

      MarketplaceService.getFornecedores().then(
        response => {
          this.loaded = true
          this.fornecedores = response.data
          window.spinner.emit("close")
        },
        () => {
          window.spinner.emit("close")
        }
      )
    },
    addFornecedor () {
      this.novo = true
      this.editar = false
      this.fornecedor = this.scope
    },
    closeForm () {
      this.novo = false
      this.editar = false
      this.fornecedor = null
    },
    getFornecedor ( fornecedor, i ) {
      console.log("fornecedor:", fornecedor)
      this.fornecedor = fornecedor
      this.editar = true
      this.activeIndex = i
    }
  }
}
</script>

<style lang="scss" scoped>
  .plItemInner {
    grid-template-columns: .3fr 2fr 1fr 1.5fr 1.5fr .3fr;
  }
</style>