<template>
  <section>
    <div class="frame etiqueta" v-if="data">
      <header>
        <div class="logoWrap">
          <logo color="#ED1C24"></logo>
        </div>
        <h3 class="color-text">Etiqueta de devolução de pedido</h3>
        <div>
          <span>
            <p>Solicitação</p>
            <h1>{{ data.idSolicitacaoCancelamento }}</h1>
          </span>
        </div>
      </header>
      <main>
        <ul>
          <li><b>Solicitante:</b> {{ data.nome }}</li>
          <li><b>Data da Solicitação:</b> {{ formatDate(data.dataAbertura) }}</li>
          <li><b>Valor da devolução:</b> {{ formatMoney(data.valorCancelamento) }}</li>
        </ul>
        <div class="produtos">
          <h5 class="color-text">Itens para devolução</h5>
          <table>
            <thead>
              <tr>
                <th>Produto</th>
                <th>Aluno</th>
                <th>Série</th>
                <th>Unidade</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in data.itensSolicitados" :key="item">
                <td>{{ item.nomeProduto }}</td>
                <td>{{ item.nomeAluno }}</td>
                <td>{{ item.serie }}</td>
                <td>{{ item.unidade }}</td>
                <td>{{ formatMoney(item.valorProduto) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="instruc">
          <h4>Instruções</h4>
          <ol>
            <li>Imprima esta etiqueta e entregue-a junto de seus itens</li>
            <li>Entregue os itens para devolução na secretaria de sua unidade</li>
            <li>Vocês tem 2 dias para devolver seus itens, após a aprovação de sua solicitação</li>
            <li>Lembre-se de levar seus produtos embalados e com a tag do fornecedor</li>
            <li>Não serão aceitos itens que não estiverem listados acima</li>
            <li>Os itens não podem estar usados, lavados ou danificados</li>
            <li>Qualquer dúvida entre em contato com nosso atendimento pelo canal "UNIFORMES - Dúvidas e Informações" via app</li>
          </ol>
        </div>
      </main>
    </div>
  </section>
</template>

<script>
import MarketplaceService from '@/services/marketplace.service.js'
import Logo from '@/components/template/logo.vue'
import { formatDate, formatMoney } from '@/services/utils.service.js'

export default {
  components: {
    Logo
  },
  props: {
    solicitacao: {
      type: [String, Number]
    }
  },
  data () {
    return {
      data: null
    }
  },
  mounted () {
    this.getSolicitacao()
  },
  methods: {
    getSolicitacao() {
      MarketplaceService.getCancelamento( this.solicitacao ).then(
        response => {
          console.log("response:", response)
          this.data = response.data[0]
          window.spinner.emit("close")

          setTimeout(() => {
            print()
          },1000)
        },
        () => {
          window.spinner.emit("close")
        }
      )
    },
    formatDate (date) {
      return formatDate(date)
    },
    formatMoney ( value ) {
      return formatMoney(value)
    }
  }
}
</script>

<style lang="scss" scoped>
  section {
    padding: $mg;
  }

  header {
    display: flex;
    align-items: center; 
    align-content: center;
    justify-content: space-between;
    gap: $hmg;
    // text-align: center;

    >div {
      &:not(.logoWrap) {
        display: flex; flex-direction: column;
        align-items: flex-end; flex: 1;
      }
    }
    span {
      display: inline-flex;
      width: auto;
      padding: $hmg;
      border-radius: 2em;
      background-color: $color-bg;
      justify-content: space-between;
      align-items: center;
      border: 1px solid $color-text;

      p {
        margin: 0; padding: 0;
      }
      h1 {
        font-size: 3rem; line-height: 1;
        margin-left: $mg
      }
    }
  }
  .etiqueta {
    border: 1px solid $color-text;
    padding: $mg;
    border-radius: $border-radius;
  }
  .logo {
    display: flex; width: 60px;
    margin: 0 auto $mg;
  }

  main {
    margin-top: $mg;

    ul {
      padding: 0;
      margin: 0;
      border-top: 3px $color-text solid;
      border-bottom: 3px $color-text solid;

      li {
        list-style: none;
        padding: $mg_mini;
        border-bottom: 1px solid $color-text;

        &:last-child{
          border: 0
        }
      }
    }
  }

  .instruc {
    margin-top: $mg;
    padding: $hmg $mg_mid;
    background-color: $color-bg;
    border-radius: $border-radius;
    border: 1px solid $color-text;

    ol {
      padding: 0;
      margin: 0;

      li {
        padding: $hmg_mini 0;
        margin: 0;
        list-style-position: inside;
      }
    }
  }

  .produtos {
    margin-top: $mg;

    h5 {
      margin-bottom: $hmg_small
    }

    table {
      border-collapse: collapse;
      width: 100%;

      tbody {
        tr {
          td {
            border-bottom: 1px solid $color-text;
          }
        }
      }
    }
  }
</style>