
const data = {
  Basicos: {
    tipo: 'fisica',
    nome: null,
    email: null,
    telefonefixo: null,
    telefonecelular: null,
    m_telefonefixo: null,
    m_telefonecelular: null
  },
  Documento: {
    cpf: null,
    m_cpf: null,
    rg: null
  },
  Endereco: {
    cep: null,
    m_cep: null,
    logradouro: null,
    numero: null,
    complemento: null,
    bairro: null,
    nomeCidade: null,
    nomeUf: null
  },
  entrega: {
    idUnidade: null,
    descricao: null,
    observacoes: null
  },
  pagamento: {
    forma: null,
    idFormaPagamento: null,
    formasPagamento: [],
    valorPago: null,
    cartao: {
      numero: null,
      m_numero: null,
      nome: null,
      validade: null,
      cvv: null,
    }
  },
  produtos: []
}

const initialState = data

export const checkout = {
  namespaced: true,
  state: initialState,
  mutations: {
    validate ( state, target ) {
      console.log("target:", target)

      switch (target) {
        case 'informacoes':
          var telefonefixo = state.Basicos.m_telefonefixo
          if ( telefonefixo ) telefonefixo = telefonefixo.replace(/\s/g,'').replace('(', '').replace(')', '').replace('-', '').replace('+', '').replace('55', '')

          var telefonecelular = state.Basicos.m_telefonecelular
          if ( telefonecelular ) telefonecelular = telefonecelular.replace(/\s/g,'').replace('(', '').replace(')', '').replace('-', '').replace('+', '').replace('55', '')

          var cpf = state.Documento.m_cpf
          if ( cpf ) cpf = cpf.replace(/\./g,'').replace('-', '')

          var cep = state.Endereco.m_cep
          if ( cep ) cep = cep.replace('-', '')

          state.Basicos = {...state.Basicos, telefonefixo: telefonefixo, telefonecelular: telefonecelular}
          state.Documento = {...state.Documento, cpf: cpf}
          state.Endereco = {...state.Endereco, cep: cep}
        break
        case 'pagamento':
          // var numero_cartao = state.pagamento.cartao.m_numero
          // if ( numero_cartao ) {
          //   numero_cartao = numero_cartao.replace(/\./g,'')
          //   console.log("numero_cartao:", numero_cartao)

          //   state.pagamento.cartao.numero = numero_cartao
          // }
          
        break
      }
      
      // console.log("validate final:", state)
    }
  },
  actions: {
    get () {
      return Promise.resolve(data)
    },
    validate ({commit, state}, target) {
      commit('validate',target)

      console.log("target:", target)
      console.log("usable state:", state)

      return Promise.resolve(data)
    }
  },
  // mutations: {
  //   get(state) {
  //     console.log('state:', state)
  //     // console.log('checkout:', this.checkout)
  //   }
  // }
}


const cartData = {
  total: 0
}

const initialCartState = cartData

export const cart = {
  namespaced: true,
  state: initialCartState,
  actions: {
    get () {
      // commit('data', data)
      return Promise.resolve(data)
    }
  }
}