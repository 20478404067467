<template>
  <div class="searchControl">
    <!-- <div class="toggleCatMenu">
      <a href="#" @click.prevent="toggleCatMenu()">
        <span>
          {{ selectedCatLabel }}
        </span>
        <fa :icon="['fas', 'sort-down']" class="icon"></fa>
      </a>
    </div> -->
    <template v-if="allowSearch">
      <div class="searchButton">
        <a href="#" :class="['btn', 'btn-primary', { disabled: searchOptions.query.length < minLength }]" @click.prevent="submit">Buscar</a>
      </div>
      <input-control
        cssClass="searchInput"
        placeholder="O que você procura?"
        ref="searchInput"
        v-model="searchOptions.query"
        @keyup.enter="submit"
      ></input-control>
    </template>
    
    <!-- <category-menu :active="catMenuActive" @close="toggleCatMenu('close')" :categories="categories" @select="selectCategory"></category-menu> -->
  </div>
</template>
<script>
// import CategoryMenu from '../navigation/CategoryMenu.vue'

export default {
  name: 'searchbar',
  components: {
    // CategoryMenu
  },
  props: {
     categories: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      selectedCatLabel: 'Todas as categorias',
      catMenuActive: false,
      searchOptions: {
        level: null, //categoria ou subcategoria
        filter: null, //id da cat ou sub selecionada
        query: '' //texto da busca
      },
      minLength: 2,
      campinas: [29,31],
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
      allowSearch: true
    }
  },
  mounted () {
    const query = this.$router.currentRoute.value.params.query
    console.log('this.$router', this.$router.currentRoute.value.params.query)
    if ( query ) this.searchOptions.query = unescape(decodeURIComponent(query))

    // var unidades =  []
    // this.currentUser?.alunos?.map( k => {
    //   unidades.push(k.idUnidade)
    // })

    // if ( unidades.some(a => this.campinas.includes(a)) ) {
    //   this.allowSearch = false
    // }
  },
  methods: {
    toggleCatMenu (state) {
      if ( state ) {
        this.catMenuActive = state === 'open'
      } else {
        this.catMenuActive = this.catMenuActive ? false : true
      }

      if (this.catMenuActive) window.pagescroll.emit('lock')
      else window.pagescroll.emit('unlock')
    },
    selectCategory (category) {
      // console.log("selectCategory:", category)

      if ( category ) {
        this.selectedCatLabel = category.value
        
        this.searchOptions.level = category.type
        
        this.searchOptions.filter = category.id

        this.$refs.searchInput.$refs.input.focus()

        setTimeout(() => {
          this.toggleCatMenu('close')
        }, 500)

      } else {
        this.selectedCatLabel = 'Todas as categorias'
      }
    },
    submit () {
      const level = this.searchOptions.level ? this.searchOptions.level : 'todos'
      const filter = this.searchOptions.filter ? this.searchOptions.filter : 'todos'
      const query = escape(encodeURIComponent(this.searchOptions.query))

      // const route = "/loja/busca/" + level + '/' + filter + "/" + escape(encodeURIComponent(query))
      // console.log("level:", level)
      // console.log("filter:", filter)
      // console.log("query:", query)
      
      if ( this.searchOptions.query.length >= this.minLength ) {
        this.$router.push({name:'Busca Loja', params: { level: level, filter: filter, query: query }})
      }
    },
    validate () {
      
    }
  }
}
</script>
<style lang="scss">
  .searchControl{
    width: 100%; height: 75px;
    position: relative;

    @media screen and ( max-width: 1024px ) {
      height: auto;
    }

    .searchInput {
      width: 100%; height: 75px !important;
      font-size: 23px !important;
      padding: 0 260px 0 1em !important;
      font-family: $title-family !important; font-weight: bold !important;

      @media screen and ( max-width: 1024px ) {
        font-size: 20px !important;
        height: auto !important;
        padding: 40px 80px $mg_small $mg_small !important;
      }
    }
  
    .toggleCatMenu {
      position: absolute; top: 50%; right: 96px;
      transform: translateY(-50%); z-index: 100;

      > a {
        padding: .4em .6em;
        border-radius: 10px;
        background-color: #FFF;
        
        @extend %transition_3o;
        
        .icon {
          margin-left: 6px;
          transform: translateY(-2px);
        }
        &:hover {
          background-color: $color-bg;
        } 
      }

      @media screen and ( max-width: 1024px ) {
        top: $hmg; right: initial; left: $hmg_mini;
      }
    }

    .searchButton {
      position: absolute; top: 50%; transform: translateY(-50%);
      right: 1em; z-index: 100;
      
      .btn {
        padding: 1em; border-radius: .6em;
      }

      @media screen and ( max-width: 1024px ) {
        top: 61%; 
      }
    }
  }
  
</style>