<template>
  <div ref="form">
    <h4>Formulário</h4>
    <ul v-if="formulario.length > 0 && formularioReady">
      <li v-for="pergunta in formulario" :key="pergunta">
        <respostas
          :pergunta="pergunta"
          :required="pergunta.obrigatorio"
          :valid="pergunta.valid"
          :disabled="action == 'ver' || disabled"
          @onChange="getChange"
          v-if="pergunta.visible"
          :ref="`pergunta_${pergunta.idFormularioPergunta}`"
        ></respostas>
      </li>
    </ul>
  </div>
</template>
<script>
import Respostas from '@/components/ferramentas/formularios/form/Respostas.vue'
import FormService from '@/services/form.service.js'

export default {
  components: {
    Respostas
  },
  props: {
    form: {
      type: Object
    },
    action: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    asw: {
      type: Boolean
    }
  },
  watch: {
    form ( value ) {
      if ( value ) {
        this.createForm()
      }
    }
  },
  data () {
    return {
      formulario: [],
      formularioReady: false,
      valid: {}
    }
  },
  mounted () {
    // console.log("------------------------------------------asw:", this.asw)
    this.createForm()
  },
  methods: {
    createForm () {
      this.formulario = []
      
      this.form.perguntas.map( k => {
        console.log("k:",k)
        const pergunta = k
        pergunta.obrigatorio = pergunta.obrigatorio == 1

        if ( k.obrigatorio ) {
          this.valid[k.idFormularioPergunta] = true
          k['required'] = true
        }
        
        if ( this.asw ) {
          k['visible'] = k.idFormularioPerguntaCondicao && k.resposta.length == 0 ? false : true
          console.log("k:", k)
        } else {
          k['visible'] = k.resposta.length > 0 || !k.idFormularioPerguntaCondicao
        }
        

        this.formulario.push(k)
      })

      console.log("this.formulario:", this.formulario)
      this.formularioReady = true

    },
    getChange ( value ) {
      // console.log("change-value", value)
      
      const pergunta = this.formulario.filter( a => a.idFormularioPergunta == value.pergunta.idFormularioPergunta)[0]

      pergunta.resposta = value.resposta

      if ( pergunta || !this.asw ) {
        this.fitsCondition(pergunta, value)
      }
      
    },
    validate () {
      const valid = FormService.validate(this.$refs.form, this.valid)

      return valid
    },
    save () {
      const validate = this.validate()
      if ( validate.response ) {
        this.$emit('onSave', this.formulario)
      } else {
        window.toaster.emit('open', {style: "error", message: "Existem campos requeridos não preenchidos. Valide as respostas antes de continuar.", floater: true})
      }

    },
    fitsCondition ( pergunta ) {
      // console.log("fitsCondition - pergunta:", pergunta)
      // console.log("fitsCondition - value:", value)
      // console.log("fitsCondition - formulario:", this.formulario)
      const resp = pergunta.resposta

      if ( pergunta.condicao.length > 0 ) {
        const conditional = pergunta.condicao.filter( a => {
          // const is = a.idFormularioAlternativa == resp
          var is

          if ( typeof resp == 'object' && resp.length > 0 ) {
            is = resp.includes(a.idFormularioAlternativa)
            // console.log("resp is object:", is)
          } else {
            is = a.idFormularioAlternativa == resp
            // console.log("resp not object:", is)
          }
          // return a.idFormularioAlternativa == resp || resp.includes(a.idFormularioAlternativa)
          
          return is
        })

        const condicoes = []
        pergunta.condicao.map( c => {
          condicoes.push(c.idFormularioPerguntaCondicao)
        })

        this.formulario.map(f => {
          if ( condicoes.includes(f.idFormularioPerguntaCondicao)) {
            f.visible = false
            f.resposta = []
          }

          const fit = conditional.filter( a => a.idFormularioPerguntaCondicao == f.idFormularioPerguntaCondicao)

          if ( f.idFormularioPerguntaCondicao && fit.length > 0) {
            f.visible = true
          }
          
        })

      }
    }
  }
}
</script>

<style lang="scss" scoped>
  ul {
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-bottom: $hmg
    }
  }
</style>