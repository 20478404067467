<template>
  <section @keydown.esc="closeList">
    <header>
      <h4 v-if="plano">Editar Plano de Pagamento - {{ plano.idPlanoPagamento }}</h4>
    </header>
    <div v-if="plano" class="frame fixed" ref="planoForm">
      <div class="row no-padd">
        <div class="col-md-5 form-group">
          <label>Tipo Arrecação</label>
          <select-control 
            v-model="plano.idTipoArrecadacao"
            placeholder="Selecione" 
            name="idTipoArrecadacao"
            :options="options.arrecadacoes" 
            :disabled="loading"
            :valid="valid.idTipoArrecadacao"
            @change="valid.idTipoArrecadacao = true"
            cssClass="invert"
            required
          />
        </div>
        <div class="col-md-3 form-group">
          <label>Mês Início</label>
          <input-control 
            v-model="plano.mesInicio" 
            placeholder="MM/YYYY" 
            name="mesInicio"
            masking="mes_ano"
            @change="valid.mesInicio = true" 
            :valid="valid.mesInicio"
            :disabled="loading"
            cssClass="invert"
            required
          />
        </div>
        <div class="col-md-2 form-group">
          <label>Vencimento</label>
          <input-control 
            v-model="plano.diaVencimento1" 
            placeholder="Ex. 5" 
            name="diaVencimento1"
            type="number"
            @change="valid.diaVencimento1 = true; trackMax('dias', 'diaVencimento1')" 
            :valid="valid.diaVencimento1"
            :disabled="loading"
            cssClass="invert"
            required
          />
        </div>
      </div>
      <div class="row no-padd">
        <div class="col-md-3 form-group">
          <label>Data Inicial</label>
          <input-control 
            v-model="plano.dataInicio" 
            placeholder="DD/MM/YYYY" 
            name="dataInicio"
            type="date"
            @change="valid.dataInicio = true" 
            :valid="valid.dataInicio"
            :disabled="loading"
            cssClass="invert"
            required
          />
        </div>
        <div class="col-md-3 form-group">
          <label>Data Final</label>
          <input-control 
            v-model="plano.dataFinal" 
            placeholder="DD/MM/YYYY" 
            name="dataFinal"
            type="date"
            @change="valid.dataFinal = true" 
            :valid="valid.dataFinal"
            :disabled="loading"
            cssClass="invert"
            required
          />
        </div>
        <div class="col-md-3 form-group">
          <label>Competência Inicial</label>
          <input-control 
            v-model="plano.competenciaInicial" 
            placeholder="MM/YYYY" 
            name="competenciaInicial"
            @change="valid.competenciaInicial = true" 
            :valid="valid.competenciaInicial"
            :disabled="loading"
            masking="mes_ano"
            cssClass="invert"
            required
          />
        </div>
        <div class="col-md-3 form-group">
          <label>Competência Final</label>
          <input-control 
            v-model="plano.competenciaFinal" 
            placeholder="MM/YYYY" 
            name="competenciaFinal"
            @change="valid.competenciaFinal = true" 
            :valid="valid.competenciaFinal"
            :disabled="loading"
            masking="mes_ano"
            cssClass="invert"
            required
          />
        </div>
      </div>
      <div class="row no-padd">
        <div class="col-md-2 form-group">
          <label>Parcelas</label>
          <input-control 
            v-model="plano.numeroParcelas" 
            placeholder="Ex. 12" 
            name="numeroParcelas"
            type="number"
            @change="valid.numeroParcelas = true; trackMax('meses', 'numeroParcelas')" 
            :valid="valid.numeroParcelas"
            :disabled="loading"
            cssClass="invert"
            required
          />
        </div>
        <div class="col-md-4 form-group">
          <label>Valor Parcela</label>
          <input-control 
            v-model="plano.valorParcela" 
            placeholder="MM/YYYY" 
            name="valorParcela"
            @change="valid.valorParcela = true" 
            :valid="valid.valorParcela"
            :disabled="loading"
            cssClass="invert"
            required
          />
        </div>
      </div>
      <div class="row no-padd">
        <div class="col-md-5 form-group">
          <label>Tipo Desconto</label>
          <select-control 
            v-model="plano.idTipoDesconto"
            placeholder="Selecione" 
            name="idTipoDesconto"
            :options="options.descontos" 
            :disabled="loading"
            :valid="valid.idTipoDesconto"
            @change="valid.idTipoDesconto = true"
            cssClass="invert"
            required
          />
        </div>
        <div class="col-md-3 form-group">
          <label>Desconto Plano</label>
          <input-control 
            v-model="plano.descontoPlano" 
            placeholder="Ex. 5" 
            name="descontoPlano"
            @change="valid.descontoPlano = true; trackMax('desconto', 'descontoPlano')" 
            :valid="valid.descontoPlano"
            :disabled="loading"
            cssClass="invert"
            required
          />
        </div>
      </div>
      <div class="row no-padd">
        <div class="col-md-2 form-group">
          <label>Matrícula</label>
          <div class="switchWrapMini">
            <switch-control 
              v-model:checked="plano.matricula" 
              labelOn="Sim" 
              labelOff="Não" 
              invert
            ></switch-control>
          </div>
        </div>
        <div class="col-md-2 form-group">
          <label>Rematrícula</label>
          <div class="switchWrapMini">
            <switch-control 
              v-model:checked="plano.rematricula" 
              labelOn="Sim" 
              labelOff="Não" 
              invert
            ></switch-control>
          </div>
        </div>
        <div class="col-md-2 form-group">
          <label>Status</label>
          <div class="switchWrapMini">
            <switch-control 
              v-model:checked="plano.ativo" 
              labelOn="Ativo"
              labelOff="Inativo" 
              invert
            ></switch-control>
          </div>
        </div>
      </div>
      <div class="row no-padd">
        <div class="col-md-12 form-group title-wrap">
          <h5 class="color-text">Material Didático</h5>
        </div>
        <div class="col-md-6 form-group">
          <label>Produto</label>
          <div class="searchWrap">
            <input-spinner v-if="material.loading" class="searchSpinner"></input-spinner>
            <input-control 
              v-model="material.query" 
              placeholder="Buscar Produto.." 
              @keydown.esc="closeList('material')"
              :disabled="material.loading" 
              cssClass="invert" 
              @keyup="searchMaterial"
              search
            ></input-control>
            <input type="hidden" v-model="plano.idTipoMaterial">
            <div class="listWrap" v-if="material.list && material.list.length > 0">
              <div class="list">
                <ul>
                  <li v-for="item in material.list" :key="item" @click="selectMaterial(item)">
                    {{ item.descricao }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 form-group">
          <label>Forma de Pagamento</label>
          <!-- <input-control 
            v-model="plano.idFormaPagamentoMaterial"
            placeholder="" 
            name="idFormaPagamentoMaterial"
            :disabled="loading"
            cssClass="invert"
          /> -->
          <div class="searchWrap">
            <input-spinner v-if="formasPagamento.loading" class="searchSpinner"></input-spinner>
            <input-control 
              v-model="formasPagamento.query" 
              placeholder="Buscar Formas de Pagamento.." 
              @keydown.esc="closeList('formasPagamento')"
              :disabled="formasPagamento.loading" 
              cssClass="invert"
              @keyup="searchFormasPagamento"
              search
            ></input-control>
            <input type="hidden" v-model="plano.idFormaPagamentoMaterial">
            <div class="listWrap" v-if="formasPagamento.list && formasPagamento.list.length > 0">
              <div class="list">
                <ul>
                  <li v-for="item in formasPagamento.list" :key="item" @click="selectFormasPagamento(item)">
                    {{ item.descricao }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer class="action-wrap">
        <a href="#" @click.prevent="savePlanos()" class="btn btn-primary">
          Salvar
        </a>
        <a href="#" @click.prevent="cancel()" class="btn btn-secondary">
          Voltar
        </a>
      </footer>
    </div>
  </section>
</template>

<script>

import FinanceiroService from '@/services/financeiro.service.js'
import MarketplaceService from '@/services/marketplace.service'
import FormService from '@/services/form.service.js'
import { createSelectOptions } from '@/services/utils.service.js'
import InputSpinner from '@/components/utilities/InputSpinner.vue'

export default {
  components: {
    InputSpinner
  },
  props: {
    scope: {
      type: Object
    }
  },
  data () {
    return {
      plano: null,
      loading: false,
      options: {
        arrecadacoes: [],
        descontos: [],
      },
      valid: {
        diaVencimento1: true,
        vencimentoDiaUtil: true,
        descontoVencimento1: true,
        mesInicio: true,
        inicianamatricula: true,
        dataInicio: true,
        dataFinal: true,
        diasvencimento: true,
        valorParcela: true,
        numeroParcelas: true,
        idTipoArrecadacao: true,
        descontoPlano: true,
        idTipoDesconto: true,
        competenciaInicial: true,
        competenciaFinal: true,
      },
      wait: {
        arrecadacoes: false,
        descontos: false
      },
      material: {
        loading: false,
        list: [],
        query: null,
      },
      formasPagamento: {
        loading: false,
        list: [],
        query: null,
      },
      searchTimeout: null
    }
  },
  mounted () {
    if ( this.scope ) {
      this.plano = Object.assign({}, this.scope)

      if ( this.plano.idTipoMaterial ) {
        this.material.query = this.plano.descricaoMaterial
      }
      if ( this.plano.idFormaPagamentoMaterial ) {
        this.formasPagamento.query = this.plano.descricaoFormaPagamentoMaterial
      }
      
    }

    this.getArrecadacoes()
    this.getDescontos()
  },
  methods: {
    getArrecadacoes () {
      window.spinner.emit('open')
      FinanceiroService.getArrecadacoes().then(
        response => {
          console.log("getArrecadacoes:", response)
          if ( response.data.length > 0 ) {
            this.options.arrecadacoes = createSelectOptions(response.data, 'idTipoArrecadacao' ,'descricao')
          } else {
            window.toaster.emit('open', {style: "error", message: "Não foi possível recuperar as arrecadações. Isto parece ser um problema de integração com o GestorClass.", floater: true})
          }
          
          this.wait.arrecadacoes = true
          this.finishLoad()
        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível recuperar as arrecadações.", floater: true})
          window.spinner.emit('close')
        }
      )
    },
    getDescontos () {
      window.spinner.emit('open')
      FinanceiroService.getDescontos().then(
        response => {
          console.log("response:", response)
          if ( response.data.length > 0 ) {
            this.options.descontos = createSelectOptions(response.data, 'idTipoDesconto' ,'descricao')                       
          } else {
            window.toaster.emit('open', {style: "error", message: "Não foi possível recuperar os descontos. Isto parece ser um problema de integração com o GestorClass.", floater: true})
          }
          
          this.wait.descontos = true
          this.finishLoad()
        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível recuperar os descontos.", floater: true})
          window.spinner.emit('close')
        }
      )
    },
    getMaterial ( filter ) {
      this.material.loading = true
      MarketplaceService.filterProdutos( filter ).then(
        response => {
          console.log("runFilter:", response)
          if ( response.data.length > 0 ) {
            this.material.list = response.data
          } else {
            window.toaster.emit('open', {style: "warning", message: "Não encontramos produtos...", floater: true})
          }
          this.material.loading = false
        },
        error => {
          console.log("error:", error)
          window.toaster.emit('open', {style: "warning", message: "Ops! Algo aconteceu. Não conseguimos processar sua solicitação. Tente novamente. Se o erro persistir, procure o suporte. Err: " + error.response.data.message, floater: true})
          this.material.loading = false
        }
      )
    },
    getFormasPagamento ( filter ) {
      this.formasPagamento.loading = true
      MarketplaceService.filterFormasPagamentoMaterial( filter ).then(
        response => {
          console.log("runFilter:", response)
          if ( response.data.length > 0 ) {
            this.formasPagamento.list = response.data
          } else {
            window.toaster.emit('open', {style: "warning", message: "Não encontramos formas de pagamento...", floater: true})
          }
          this.formasPagamento.loading = false
        },
        error => {
          console.log("error:", error)
          window.toaster.emit('open', {style: "warning", message: "Ops! Algo aconteceu. Não conseguimos processar sua solicitação. Tente novamente. Se o erro persistir, procure o suporte. Err: " + error.response.data.message, floater: true})
          this.formasPagamento.loading = false
        }
      )
    },
    trackMax ( src, target ) {
      var min, max

      if ( src == 'meses' ) {
        min = 1
        max = 12
      }
      if ( src == 'dias' ) {
        min = 1
        max = 31
      }
      if ( src == 'desconto' ) {
        min = 0
        max = 100
      }

      if ( this.plano[target] < min ) this.plano[target] = min
      if ( this.plano[target] > max ) this.plano[target] = max
    },
    finishLoad () {
      const finished = !Object.values(this.wait).some( a => !a)

      if ( finished ) {
        window.spinner.emit("close")
        this.loading = false
      }
      // console.log("finished:", finished)
    },
    savePlanos () {
      const valid = FormService.validate(this.$refs.planoForm, this.valid)

      if ( valid.response ) {
        window.spinner.emit('open')
        FinanceiroService.savePlanosPagamento( [this.plano] ).then(
            response => {
              if ( response.status == 200 ) {
                window.toaster.emit('open', {style: "success", message: "Tudo certo! Plano de Pagamento salvo com sucesso.", floater: true})

                setTimeout(() => {
                  this.$emit('onSave')
                  this.cancel()
                }, 500)
                
              }
              window.spinner.emit('close')
            },
            () => {
              window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível salvar este Plano de Pagamento.", floater: true})
              window.spinner.emit('close')
            }
          )
      }
    },
    cancel () {
      this.$emit("onCancel")
    },
    searchMaterial () {
      if ( this.material.query && this.material.query.length > 3 ) {
        if ( this.searchTimeout ) clearInterval(this.searchTimeout)

        this.searchTimeout = setTimeout ( () => {
          const filter = {
            descricao: this.material.query
          }
          this.getMaterial(filter)
        }, 500)
      }
    },
    selectMaterial ( material ) {
      // console.log("selectMaterial:", material)

      this.material.query = material.descricao
      this.plano.idTipoMaterial = material.idtipomaterial
      this.material.list = []
    },
    searchFormasPagamento () {
      if ( this.formasPagamento.query && this.formasPagamento.query.length > 3 ) {
        if ( this.searchTimeout ) clearInterval(this.searchTimeout)

        this.searchTimeout = setTimeout ( () => {
          const filter = this.formasPagamento.query
          this.getFormasPagamento(filter)
        }, 500)
      }
    },
    selectFormasPagamento ( formas ) {
      console.log("selectFormasPagamento:", formas)

      this.formasPagamento.query = formas.descricao
      this.plano.idFormaPagamentoMaterial = formas.idformapagamentomaterial
      this.formasPagamento.list = []
    },
    closeList ( target ) {
      this[target].list = []
    }
  }
}
</script>

<style lang="scss" scoped>
  section {
    padding-bottom: $mg
  }

  header {
     padding: $hmg_small $hmg $hmg $hmg
  }
  .action-wrap {
    padding: $hmg_small;

    .btn {
      margin-right: $hmg_mid;
    }
  }
  .title-wrap {
    padding: $hmg_small $hmg 0;
  }

  .searchWrap {
    position: relative;
  }
  .listWrap {
    position: relative;
    width: 100%;

    .list {
      position: absolute; 
      width: 100%; height: 180px;
      left: $hmg_mini; top: -$hmg_mini;
      overflow: hidden;
      z-index: 100;
      padding: $hmg_small;
      background-color: $color-secondary;
      border-radius: $border-radius;
      @extend %shadow-high;

      ul {
        padding: 0; margin: 0; max-height: 100%;
        overflow-y: auto;
        @extend %scrollbar;

        li {
          list-style: none;
          padding: $hmg_mini;
          margin: 0;
          @extend %transition_3o;
          cursor: pointer;

          &:hover {
            background-color: $color-bg;
            border-radius: $border-radius-small;
          }
        }
      }
    }
  }
  .searchSpinner { 
    position: absolute; top: 50%;
    right: $hmg_small;
    transform: translateY(-55%);
    z-index: 100;
  }
</style>