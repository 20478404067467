<template>
  <section class="section">
    <header>
      <div class="search-form">
        <input-control v-model="query" placeholder="O que você procura?" @keyup="search()" search preventSearchButton></input-control>
        <a href="@" @click.prevent="clearSearch()">
          <fa :icon="['fal','trash-xmark']" class="icon"></fa>
        </a>
      </div>
    </header>
    <div class="planilhasListWrap" v-if="categorias">
      <ol class="cardList">
        <li>
          <div class="plItemInner">
            <span> ID </span>
            <span> Nome </span>
            <span></span>
          </div>
        </li>
        <li v-for="categoria in categorias" :key="categoria">
          <card spacing="small">
            <div class="plItemInner">
              <span>
                {{ categoria.idAtendimentoCategoria }}
              </span>
              <span class="fixed">
                {{ categoria.nome }}
              </span>
              <span class="actionWrap">
                <a href="#" @click.prevent="selectCategoria(categoria)">
                  <fa :icon="['fal', 'pen-to-square']"></fa>
                </a>
              </span>
            </div>
          </card>
        </li>
      </ol>
    </div>
  </section>
</template>

<script>

export default {
  props: {
    scope: {
      type: Array,
    },
  },
  watch: {
    scope(value) {
      if (value) {
        this.categorias = Object.assign([], this.scope);
      }
    },
  },
  data() {
    return {
      categorias: null,
      query: null,
      searchWait: null
    };
  },
  mounted() {
    // this.categorias = this.scope
    this.categorias = Object.assign([], this.scope)
    console.log("scope", this.scope)
  },
  methods: {
    selectCategoria(categoria) {
      this.$emit("onSelectCategoria", categoria);
    },
    toggleNew () {
      this.$emit("onNewCategoria");
    },
    search () {
      if ( this.searchWait ) clearTimeout(this.searchWait)

      if ( this.query && this.query.length >= 1 ) {
        this.searchWait = setTimeout(() => {
          this.categorias = this.scope.filter( a => {
            const nome = a.nome.toLowerCase()
            const id = a.idAtendimentoCategoria.toString()

            const existNome = nome.includes(this.query)
            const existID = id.includes(this.query)

            return existNome || existID
          })
        }, 200)
      }
    },
    clearSearch() {
      this.categorias = Object.assign([], this.scope)
      this.query = null
    }
  },
};
</script>

<style lang="scss" scoped>

.section {
  padding: $hmg_mid;

  header {
    margin-bottom: $hmg_mid;

    .search-form {
      max-width: 440px;
      display: flex; align-items: center; align-content: center;
      margin-top: $hmg;

      a {
        margin-left: $hmg_small;
        .icon {
          font-size: 16px;
        }

        &:hover {
          .icon {
            color: $color-primary
          }
        }
      }
    }

    h4 {
      margin: 0; padding: 0;
    }
  }
}
.plItemInner {
  grid-template-columns: 0.5fr 2fr 0.3fr;
}

.action-wrap {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  padding: $hmg $mg;

  a {
    margin-left: $hmg_small;
  }

  label {
    margin-right: $hmg;
  }
}
</style>
