<template>
  <div :class="['toaster', scope.type]">
    <span v-if="scope" class="iconWrap">
      <fa :icon="parseContent(scope.type, 'icon')" class="icon" />
    </span>
    <div>
      <header>
        <h6>{{ scope.title ? scope.title : parseContent(scope.type,'title') }}</h6>
      </header>
      <p v-html="scope.msg"></p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Array
    },
    scope: {
      type: Object,
    }
  },
  data () {
    return {
      
    }
  },
  methods: {
    parseContent (type, target) {
      const content = this.config.filter( a => a.type == type)[0][target]
      return content
    },
    limitMsg ( message ) {
      const limit = 75
      return message.length > limit ? message.substring(0,limit) + ' (...)' : message
    }
  }
}
</script>

<style lang="scss" scoped>
  .toaster {
    width: 100%; min-height: 80px; height: auto;
    border-radius: $border-radius;
    border: $border-component;
    background-color: $color-secondary;
    display: flex; align-items: center; align-content: center;
    padding: $hmg_mid;
    position: relative;
    .iconWrap {
      display: flex; flex-direction: column;
      align-items: center; justify-content: center;
      width: 40px; height: 40px; min-width: 40px; min-height: 40px;
      border-radius: 100%;
      margin-right: $hmg_small;
      text-align: center;

      .icon {
        font-size: 22px;
        color: $color-secondary
      }
    }

    > div {
      flex: 1;

      header {
        display: flex; align-items: center; justify-content: space-between;
        width: 100%; margin-bottom: $hmg_mini;

        h6 { margin: 0; padding: 0; }
      }
      p { margin: 0; padding: 0; font-size: $font-size-small; line-height: 1.1;  white-space: normal; word-break: break-all;}
    }

    &.apollo {
      .iconWrap {
        background-color: $color-notificacao
      }
      h6 {
        color: $color-notificacao
      }
    }
    &.sincronismo {
      .iconWrap {
        background-color: $color-sincronismo
      }
      h6 {
        color: $color-sincronismo
      }
    }
    &.atividades {
      .iconWrap {
        background-color: $color-atividades
      }
      h6 {
        color: $color-atividades
      }
    }
    &.relatorios {
      .iconWrap {
        background-color: $color-relatorio
      }
      h6 {
        color: $color-relatorio
      }
    }
    &.jarvis {
      .iconWrap {
        background-color: $color-jarvis
      }
      h6 {
        color: $color-jarvis
      }
    }
  }
</style>



<!-- {          
  type: "apollo" //apollo, sincronismo, atividades, relatorios, jarvis,
  title: "Seu título",
  msg: "Sua mensagem",
  timestamp: "0000-00-00 00:00:00"
} -->