<template>
  <section>
    <header>
      <div>
        <p>Etapa {{ step.index + 1 }} de {{ totalSteps }}</p>
        <h4>Planos de Pagamento</h4>
        <p>Clique em <b>MATRICULE-SE JÁ para selecionar</b> como você prefere pagar sua <b>Plataforma Educacional Rede Decisão</b></p>
        <div class="intro">
          <p><b>Ouvimos vocês e simplificamos a visualização dos planos de pagamento e produtos no aplicativo.</b></p>
          <p>A partir deste ano, vocês terão à disposição dois planos de pagamento para a compra unificada da nossa Plataforma Educacional. Ela inclui serviços escolares e materiais didáticos de alta qualidade, além de projetos exclusivos e recursos digitais interativos, para ajudar seu filho a atingir seu pleno potencial e realizar seus sonhos.</p>
          <p>Lembramos que o valor do desconto aplicado na sua mensalidade em 2024 foi convertido em um desconto sobre o pacote completo em 2025. Mas não se preocupe, seu desconto continua o mesmo.</p>
        </div>
        <h4 v-if="finalDate" class="finalDate">Ofertas disponíveis até <span>{{ formatDate(finalDate) }}</span></h4>
      </div>
      <div v-if="!auto">
        <a href="#" @click.prevent="toggleDescontos()" class="btn btn-primary">
          <span>Descontos</span>&nbsp;
          <fa :icon="['fas','badge-percent']"></fa>
        </a>
      </div>
    </header>
    <section>
      <desconto-matricula :active="descontoActive" @close="descontoActive = false" @save="refreshPlanos" :idMatricula="idMatricula"></desconto-matricula>
      <plano-pagamento ref="plano" @ready="setReady()" :auto="auto" :action="action" :idMatricula="idMatricula" @finalDate="finalDate = $event"></plano-pagamento>
    </section>
  </section>
</template>

<script>
import PlanoPagamento from '@/components/matriculas/form/PlanoPagamento'
import DescontoMatricula from '@/components/matriculas/form/DescontoMatricula'
import { formatDate } from '@/services/utils.service.js'

export default {
  components: {
    PlanoPagamento, DescontoMatricula
  },
  props: {
    ready: {
      type: Boolean,
      default: false
    },
    auto: {
      type: Boolean,
      default: false
    },
    action: {
      type: String
    },
    idMatricula: {
      type: [Number, String]
    },    
    step: {
      type: Object
    },
    totalSteps: {
      type: Number
    }
  },
  data () {
    return {
      descontoActive: false,
      finalDate: null
    }
  },
  mounted () {
    
  },
  methods: {
    validate () {
      return this.$refs.plano.validate()
    },
    toggleDescontos () {
      this.descontoActive = !this.descontoActive
    },
    refreshPlanos () {
      this.$refs.plano.getPlanosPagamento()
    },
    setReady () {
      this.$emit('ready')
    },
    formatDate ( date ) {
      return formatDate(date)
    }
  }
}
</script>

<style lang="scss" scoped>
  header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .finalDate {
      display: inline-block;
      padding: $mg_mini;
      border-radius: $border-radius;
      background-color: $color-secondary;
      margin: $mg_mini 0 0 -10px;
      border: $border-component;

      span {
        color: $color-primary;
      }
    }

    .intro {
      padding: $hmg_mini 0;
      border: $border-component;
      border-radius: $border-radius;
      margin: $mg_mini 0 0 -10px;
      max-width: 70%;
      background-color: $color-secondary;

      p {
        font-size: $font-size-larger;
        padding: $hmg_mini $mg_mini; 
        margin: 0;

        &:last-child {
          border-top: $border-component;
          padding: $mg_mini $mg_mini $hmg_mini;
          margin-top: $hmg_mini !important;
          font-weight: bold
        }
      }
      @media screen and ( max-width: 1024px ) {
        max-width: 100%;
      }
    }
  }
</style>