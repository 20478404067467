<template>
  <nav>
    <a href="#" v-for="item in Object.keys(data)" :key="item" :class="[item, { active: active == item}]" @click.prevent="setActive(item)">
      <h5>{{ config[item] }}</h5>
      <span>{{ data[item].length }}</span>
    </a>
  </nav>
</template>

<script>
export default {
  props: {
    config: {
      type: Object
    },
    data: {
      type: Object
    }
  },
  data () {
    return {
      active: 'pendente'
    }
  },
  mounted () {
    console.log("config", this.config)
    console.log("data", this.data)
  },
  methods: {
    setActive ( item ) {
      this.active = item

      this.$emit("onToggle", item)
    }
  }
}
</script>

<style lang="scss" scoped>
  nav {
    display: flex;
    align-items: center; align-content: center;
    gap: $hmg_mid;
    padding: $hmg_mid $hmg_mid $mg_mini $hmg_mid;

    a {
      display: flex; align-items: center; align-content: center;
      padding: 0; border-radius: 2em;
      gap: $mg_mini;
      @extend %transition_3o;

      h5 {
        padding: 0; margin: 0;
      }

      > span {
        display: flex; flex-direction: column;
        align-items: center; justify-content: center;
        width: 27px; height: 27px;
        min-width: 27px; min-height: 27px;
        font-size: $font-size-small;
        border-radius: 100%;
        color: $color-secondary;
        @extend %transition_3o;
      }

      &.pendente {
        > span {
          background-color: $color-pendente;
        }
      }
      &.andamento {
        > span {
          background-color: $color-andamento;
        }
      }
      &.finalizado {
        > span {
          background-color: $color-finalizado;
        }
      }
      
      &.active {
        padding: $hmg_mini $hmg_mini $hmg_mini $mg_mini;
        background-color: $color-secondary
      }
    }

    @media screen and (max-width: 1024px) {
      display: block;
      overflow-y: auto;
      white-space: nowrap;
      scroll-behavior: smooth;

      a {
        display: inline-flex;
        white-space: nowrap;
        margin-right: $hmg_mid;
        justify-content: space-between;
        width: 58%;
        padding: $hmg_mini $hmg_mini $hmg_mini $mg_mini;
        border: $border-component;
      }
    }
  }
</style>