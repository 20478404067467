<template>
  <section class="matWrap">
    <div v-if="matriculas">
      <header >
        <h4>Seu filtro retornou {{ total + ( total > 1 ? ' matrículas' : ' matrícula') }}</h4>
        <div :class="{ opened: togglePrint}">
          <a href="#" :class="['btn', { 'btn-primary': !togglePrint, 'btn-secondary': togglePrint }]" @click.prevent="togglePrintList()">
            {{ togglePrint ? 'Cancelar' : 'Imprimir lista' }}
          </a>
          <div v-if="togglePrint">
            <switch-control v-model:checked="onlyActive" labelOn="Apenas Ativos" labelOff="Todos" invert />
          </div>
          <a href="#" class="btn btn-primary" @click.prevent="printList()" v-if="togglePrint">
            Imprimir
          </a>
        </div>
      </header>
      <div v-for="turma in Object.keys(matriculas)" :key="turma" class="turmaWrap">
        <header>
          <h4>{{ matriculas[turma][0].nomeTurma }}</h4>
        </header>
        <ol>
          <li v-for="item in matriculas[turma]" :key="item">
            <card-mat :matricula="item" />
          </li>
        </ol>
      </div>
    </div>
    <modal ref="modal">
      <div class="modalWrap">
        <div>
          <input-control v-model="printTitle" placeholder="Ex.: Reunião de Pais Primeiro Trimestre"/>
          <p>*Você pode deixar sua lista sem nome.</p>
        </div>
        <footer>
          <a href="#" class="btn btn-primary" @click.prevent="goToPrintPage()">
            Imprimir lista
          </a>
          <a href="#" class="btn btn-secondary" @click.prevent="closePrintModal()">
            Cancelar
          </a>
        </footer>
      </div>
    </modal>
  </section>
</template>

<script>
import CardMat from '@/components/matriculas/utilities/CardMatricula.vue'
import Modal from "@/components/utilities/Modal"

export default {
  components: {
    CardMat, Modal
  },
  props: {
    scope: {
      type: Array
    },
    filter: {
      type: Object
    }
  },
  watch: {
    scope (value) {
      if ( value ) {
        this.total = value.length
        this.createList(value)
      }
    }
  },
  data () {
    return {
      matriculas: null,
      togglePrint: false,
      onlyActive: true,
      total: 0,
      drop: null,
      printTitle: null
    }
  },
  mounted () {
    this.total = this.scope.length
    this.createList(this.scope)
  },
  methods: {
    createList ( list, respond ) {
      // this.matriculas = []

      const groupedData = list.reduce((acc, curr) => {
          const { idTurma } = curr
          if (!acc[idTurma]) {
              acc[idTurma] = []
          }
          acc[idTurma].push(curr)
          return acc
      }, {})

      // Sorting the grouped data by nomeAluno
      for (const key in groupedData) {
          groupedData[key].sort((a, b) => (a.nomeAluno > b.nomeAluno ? 1 : -1))
      }
      
      if ( respond ) {
        return groupedData
      } else {
        this.matriculas = groupedData
      }
      
      // console.log(groupedData)
    },
    togglePrintList () {
      this.togglePrint = !this.togglePrint
    },
    printList () {
      const list = this.onlyActive ? this.scope.filter( a => a.idSituacao == 1 ) : this.scope
      console.log('list:', list)

      const grouped = this.createList(list, true)
      const f = this.filter
      const filter = {
        ...f,
        active: this.onlyActive 
      }
      this.drop = {
        filter: filter,
        list: grouped
      }

      console.log("printList:", this.drop)

      const options = {
        title: 'Dê um nome para sua lista',
        titleAlign: "center",
        // message: "Dê um nome para sua lista", //não obrigatório
        size: "mid", //small, mid, normal, large, full
        backdrop: true,
        // backdropColor: "transparent",  //se o backdrop for false, não precisa
        backdropClose: true,
        // onClose: this.closeEdit
      }

      console.log("toggleTransfer:", options)
      this.$refs.modal.open(options)

    },
    goToPrintPage() {
      this.drop.title = this.printTitle
      this.$refs.modal.close()
      localStorage.setItem('matList', JSON.stringify(this.drop))
      window.open('/matriculas/lista-matriculas','_blank')
    },
    closePrintModal () {
      this.$refs.modal.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.matWrap {
  // padding: $hmg_mid;
  
  >div {
    padding: 0 !important;

    >header {
      display: flex;
      padding: $hmg $hmg $hmg $mg_mid;
      align-items: center;
      justify-content: space-between;
      @extend %transition_4e;

      h4 {
        padding: 0; margin: 0;
      }

      >div {
        display: flex; align-items: center; align-content: center;
        justify-content: center; gap: $hmg_mid;
        @extend %transition_4e;
        
        >div {
          padding-right: $mg_mini;
          line-height: 1;
          @extend %transition_4e;
        }

        &.opened {
          padding: $hmg_mini;
          background-color: $color-secondary;
          border-radius: 2em;
        }
      }
    }
    ol {
      padding: 0; margin: 0;
      width: 100%;

      li {
        list-style: none;
        padding: 0; margin-bottom: $hmg_small;
      }
    }
  }
}

.turmaWrap {
  padding: $hmg_mid $hmg_mid $mg_mini $hmg_mid;
  border-bottom: $border-component;

  header {
    padding: $mg_mini $hmg_mid; margin: 0;

    h4 {
      color: $color-primary
    }
  }
}

.modalWrap {
  padding-top: $hmg_mid;
  
  p {
    margin-top: $hmg_mini
  }
  footer {
    display: flex;
    align-content: center;
    justify-content: center;
    gap: $mg_mini;
    margin-top: $hmg
  }
}
</style>