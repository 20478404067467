<template>
  <div class="form-question">
    <template v-if="current">
      <div v-if="current.itens" class="alternativas">
        <h5>Alternativas</h5>
        <ul class="alt-options">
          <transition-group name="slideLeftToRightSmall">
            <li v-for="(alt, i) in alternativas" :key="alt">
              <span :class="{ radioStyle: current.id == 2, checkStyle: current.id == 3 }">
                <fa :icon="['far','check']" class="icon" v-if="current.id == 3" />
              </span>
              <input-control v-model="alt.alternativa" :placeholder="alt.placeholder ? alt.placeholder : ''" @blur="saveAlternativa(i)" @focus="lockSave = true" :disabled="alt.waiting"/>
              <a href="#" @click.prevent="toggleRemoveAlternativa(i)" class="btnRemove">
                <fa :icon="['far','xmark']" />
              </a>
            </li>
          </transition-group>
        </ul>
      </div>
      <div v-else-if="current.placeholder">
        <input-control v-model="example.placeholder" placeholder="Texto de apoio"/>
      </div>
      <a href="#" v-if=" current.itens " @click.prevent="addNewAlternativa()" class="btn btn-secondary">
        <fa :icon="['far','circle-check']" />&nbsp;
        Adicionar alternativa
      </a>
      <div v-if="allowCondition && !conditionScape && conditions && conditions.length > 0 && scope.idFormularioPergunta" class="condition">
        <h5>Condição</h5>
        <div>
          <div class="">
            <select-control v-model="current.condicao.idFormularioPergunta" :options="options.conditions.perguntas" placeholder="Pergunta" @change="getAlternativas(current.condicao.idFormularioPergunta, true)"/>
          </div>
          <div class="">
            <select-control v-model="current.condicao.idFormularioAlternativa" :options="options.conditions.alternativas" placeholder="Alternativa" :disabled="loadingAlternativas"/>
          </div>
        </div>
      </div>
      <footer>
        <div>
          
        </div>
        <a href="#" :class="['btn btn-primary', { disabled: lockSave }]" @click.prevent="savePergunta()">
          Salvar pergunta
        </a>
      </footer>
    </template>
    <span class="loader" v-else-if="loading"></span>
    <confirm 
      :backdrop="false"
      class="confirm"
      ref="confirm"
    ></confirm>
  </div>
</template>

<script>
import FormulariosService from "@/services/formularios.service.js"
import { createSelectOptions } from '@/services/utils.service.js'

export default {
  props: {
    scope: {
      type: Object
    },
    allowCondition: {
      type: Boolean,
      default: false
    },
    conditions: {
      type: Array
    }
  },
  data () {
    return {
      config: [
        {
          id: 1,
          label: "Dissertativa",
          itens: false,
          placeholder: true
        },
        {
          id: 2,
          label: "Múltipla Escolha",
          itens: true,
          placeholder: false
        },
        {
          id: 3,
          label: "Caixa de Seleção",
          itens: true,
          placeholder: false
        },
        {
          id: 4,
          label: "Anexo",
          itens: false,
          placeholder: true
        },
        {
          id: 5,
          label: "Números",
          itens: false,
          placeholder: true
        },
        {
          id: 6,
          label: "Data",
          itens: false,
          placeholder: false
        }
      ],
      condition: {
        pergunta: null,
        alternativa: null
      },
      options: {
        conditions: {
          perguntas: null,
          alternativas: null
        }
      },
      example: null,
      current: null,
      alternativas: null,
      loading: false,
      loadingAlternativas: false,
      conditionScape: false,
      lockSave: false
    }
  },
  watch: {
    conditions ( value ) {
      if ( value.length > 0 ) {
        this.setConditional()
      }
    }
  },
  mounted () {
    console.log("pergunta scope:", this.scope)
    
    if ( this.conditions && this.conditions.length > 0 ) {
      // this.conditionScape = this.conditions.some( a => a.idFormularioPergunta == this.scope.idFormularioPergunta)

      this.setConditional()
    }
    this.loading = true
    this.trackConfig()
  },
  methods: {
    trackConfig () {
      const config = this.config.filter( a => a.id == this.scope.tipoResposta)[0]
      this.current = config

      console.log("trackConfig:", this.scope)
      if ( this.scope.condicao ) {
        this.current.condicao = this.scope.condicao
        this.getAlternativas(this.current.condicao.idFormularioPergunta, true)
      } else {
        this.current.condicao = {
          idFormularioAlternativa: null,
          idFormularioPergunta: null
        }
      }
      
      console.log("current:", this.current)
      this.set()
    },
    set () {
      if ( this.current.itens ) {
        this.getAlternativas(this.scope.idFormularioPergunta)
      } else if ( this.current.placeholder ) {
        this.example = {
          placeholder: ""
        }
        this.loading = false
      }
    },
    getAlternativas ( id, conditions ) {
      this.loadingAlternativas = true
      FormulariosService.getAlternativas(id).then(
        response => {
          console.log("getAlternativas:", response.data)

          if ( conditions ) {
            this.options.conditions.alternativas = createSelectOptions(response.data, "idFormularioAlternativa", "alternativa")
          } else {
            this.alternativas = response.data
          }
          this.loadingAlternativas = false
          this.loading = false
        },
        () => {
          this.loading = false
          this.loadingAlternativas = false
        }
      )
    },
    setConditional () {
      this.options.conditions.perguntas = createSelectOptions(this.conditions, "idFormularioPergunta", "descricao")
    },
    addNewAlternativa () {
      const alternativa = {
        alternativa: null,
        checked: false,
        idFormularioAlternativa: null,
        idFormularioPergunta: this.scope.idFormularioPergunta,
        placeholder: "Nova Alternativa"
      }
      
      if ( !this.alternativas ) this.alternativas = []
      this.alternativas.push(alternativa)
    },
    toggleRemoveAlternativa ( index ) {
      const saved = this.alternativas[index].idFormularioAlternativa
      if ( saved ) {
        console.log('toggleRemoveAlternativa:', index)
        this.$refs.confirm.run({
          message: "Tem certeza que deseja remover esta alternativa?",
          confirmButton: "Sim",
          denyButton: "Não",
          data: index,
          callback: this.removeAlternativa,
        })
      } else {
        this.alternativas.splice(index,1)
      }
      
    },
    removeAlternativa ( response, index ) {
      if ( response ) {
        
        // console.log("removeAlternativa:", this.alternativas[data])
        const id = this.alternativas[index].idFormularioAlternativa
        this.alternativas[index].waiting = true
        FormulariosService.deleteAlternativa(id).then(
          response => {
            console.log("response:", response.data)
            if ( response.data ) {
              this.alternativas.splice(index,1)
            }
          },
          () => {
            // this.alternativas[index].waiting = false
          }
        )
      }
    },
    saveAlternativa ( index ) {
      if ( !this.alternativas[index].waiting ) {
        this.alternativas[index].waiting = true

        const drop = {
          alternativa: this.alternativas[index].alternativa,
          idFormularioAlternativa1: 0,
          idFormularioPergunta: this.alternativas[index].idFormularioPergunta
        }

        if ( this.alternativas[index].idFormularioAlternativa ) {
          FormulariosService.updateAlternativa(this.alternativas[index].idFormularioAlternativa, drop).then(
            response => {
              if ( response.data ) {
                this.alternativas[index].waiting = false
              }

              this.lockSave = false
            },
            () => {
              this.alternativas[index].waiting = false
              this.lockSave = false
            }
          )
        } else {
          FormulariosService.saveAlternativa(drop).then(
            response => {
              console.log("response:", response.data)
              this.alternativas[index] = response.data
              this.lockSave = false
            },
            () => {
              this.alternativas[index].waiting = false
              this.lockSave = false
            }
          )
        }
      }
    },
    update () {
      // console.log("update pergunta...", this.scope)
      this.pergunta = this.scope
      this.$nextTick(() => {
        this.trackConfig()
      })
    },
    savePergunta () {
      this.loading = true
      this.$emit("onSave", this.current)
    }
  }
}
</script>

<style lang="scss" scoped>
  .form-question {
    padding: $hmg_mid;

    footer {
      display: flex;
      align-items: center; align-content: center;
      justify-content: space-between;
      margin-top: $hmg_mid;
    }
  }

  .alternativas {
    margin-bottom: $hmg_small;
  }
  .alt-options {
    padding: 0; margin: 0;

    li {
      display: flex; align-items: center;
      gap: $mg_mini;
      padding: $hmg_mini 0;
      margin: 0;
      list-style: none;
      @extend %transition_4e;

      >span {
        display: flex; flex-direction: column;
        align-items: center; justify-content: center;
        width: 20px; height: 20px;
        border: $border-component;

        &.checkStyle {
          text-align: center;
          font-size: 12px;
          color: $color-component;
          border-radius: $border-radius-small
        }
        &.radioStyle {
          border-radius: 100%;
        }
      }

      .btnRemove {
        font-size: 18px;
        margin-left: $hmg_mini 
      }
    }
  }

  .loader {
    width: 100%;
    height: 6px;
    display: block;
    position: relative;
    overflow: hidden;
    background-color: $color-bg;
  }
  .loader::after {
    content: '';  
    width: 96px; height: 6px;
    background-color: $color-primary;
    position: absolute; top: 0; left: 0;
    box-sizing: border-box;
    animation: hit 0.6s ease-in-out infinite alternate;
  }

  @keyframes hit {
    0% {
      left: 0;
      transform: translateX(-1%);
    }
    100% {
      left: 100%;
      transform: translateX(-99%);
    }
  }

  .condition { 
    width: 100%;
    margin-top: $hmg;

    >div {
      width: 100%;
      display: flex;
      gap: $hmg_mid;

      > div {
        flex: 1
      }
    }
  }
</style>