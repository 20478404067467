<template>
<section class="autocompleteBoxWrap">
  <h5 class="color-text">{{ 'Selecione ' + ( single ? 'o ' : 'os ' )  + target }}</h5>
  <div :class="['autocompleteBox',{ closed: single && selected.length > 0 }]">
    <div class="wait" v-if="loadingItems">
      <input-spinner></input-spinner>
    </div>
    <div v-else-if=" single && selected.length > 0 " class="selectedBox">
      <div>
        <span>
          <h4 class="color-text" :title="this.selected[0].descricao">{{ this.selected[0].descricao }}</h4>
        </span>
        <a href="#" class="btn btn-primary btn-small" @click.prevent="changeSelected()">Alterar Seleção</a>
      </div>
      <fa :icon="['fas','check']" class="color-success icon"></fa>
    </div>
    <template v-else>
      <div class="input">
        <input-control cssClass="invert " :placeholder="'Filtrar ' + target" v-model="itemFilter" @keyup="filterItems" @change="filterItems"></input-control>
      </div>
      <div>
        <ol>
          <li v-for="(item,index) in items" :key="item" :class="{ lock: item.lock }">
            <label>
              <span>{{ item.descricao }}</span>
              <input type="checkbox" v-model="item.active" :name="single ? 'selectedItem' : 'selectedItem_' + index " @change="selectItem(item, $event)" />
            </label>
          </li>
        </ol>
      </div>
    </template>
  </div>
</section>
  
</template>

<script>
import MarketplaceService from '@/services/marketplace.service'
import inputSpinner from '@/components/utilities/InputSpinner'

export default {
  components: {
    inputSpinner
  },
  props: {
    src: {
      type: String
    },
    target: {
      type: String,
      default: 'itens'
    },
    single: {
      type: Boolean,
      default: false
    },
    reference: {
      type: String
    }
  },
  data () {
    return {
      items: [],
      itemsBkp: [],
      filterTimeout: null,
      selected: [],
      itemFilter: null,
      loadingItems: false
    }
  },
  mounted () {
    if ( this.src ) {
      this.get(this.src)
    }
  },
  methods: {
    get ( src ) {
      this.loadingItems = true

      MarketplaceService.getParams(src).then(
        response => {
          console.log("response:", response)
          if ( response.data.data ) {
              if ( response.data.data.length > 0 ) {
              this.items = response.data.data
              this.itemsBkp = response.data.data

              this.$emit('done')
            }
          } else{
            if ( response.data.length > 0 ) {
              this.items = response.data
              this.itemsBkp = response.data

              this.$emit('done')
            }
          }
          
          this.loadingItems = false
        },
        error => {
          console.log("error:", error)
          this.loadingItems = false
        }
      )
    },
    selectItem ( item, event ) {
      var itemIndex = null
      const checked = event.target.checked
      console.log("checked:", checked)

      var exists = this.selected.filter( ( k , i ) => {
        const id = k[this.reference]
        if ( item[this.reference] == id ) itemIndex = i
        return item[this.reference] == id
      })

      if ( exists.length == 0 && checked ) {
        console.log("não existe, insere..")
        this.selected.push(item)
      } else {
        console.log("existe, remove..")
        this.selected.splice(itemIndex,1)
      }
      
      this.$nextTick(() => {
        this.$emit('select', this.selected)
      })
      
      // console.log("selectItem:", this.selectedItem)
    },
    filterItems () {
      var query = this.itemFilter
      if ( this.filterTimeout ) clearTimeout(this.filterTimeout)

      this.filterTimeout = setTimeout(() => {
        if ( query.length > 1 ) {
          query = query.toLowerCase()
          const items = this.itemsBkp.filter( k => {
            var value = k.descricao.toLowerCase()
            return value.includes(query)
          })

          this.items = items
        } else {
          this.restore()
        }
      },200)
    },
    changeSelected () {
      this.$emit("changeSelected")
      this.selected = []
      this.items.map( k => {
        k.active = false
      })
    },
    stateItem ( lock, item ) {
      console.log("item:", item)
      const id = item[this.reference]
      var itemIndex
      this.items.map( (k,i) => {
        if ( k[this.reference] == id ) itemIndex = i
      })

      this.items[itemIndex].active = lock
      this.items[itemIndex].lock = lock

      console.log("id:", id)

      // this.selected.splice(index, 1)
      // this.items[index].active = false
    },
    restore () {
      this.items = []
      this.itemsBkp.map( k => {
        this.items.push(k)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .autocompleteBoxWrap {
    h5 {
      padding: 0 $hmg_mid;
    }
  }
  .autocompleteBox {
    display: flex; flex-direction: column;
    width: 100%; height: auto;
    min-height: 400px; max-height: 70vh;
    border-radius: $border-radius-larger;
    padding: $hmg_small;
    background-color: $color-secondary;
    overflow: hidden;
    position: relative;
    @extend %transition_4e;
    
    &.closed {
      min-height: 0;
    }

    .selectedBox {
      display: flex;
      align-items: center; align-content: center;
      justify-content: space-between;
      line-height: 1;
      padding: 0 $hmg_mini;

      h4 {
        margin: 0; padding: 0;
        line-height: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .icon {
        font-size: 24px;
        line-height: 1;
      }

      div {
        display: flex;
        align-items: center; align-content: center;
        max-width: 90%;

        a {
          margin-left: $hmg_small;
        }

        span {
          display: block;
          overflow: hidden;
        }
      }
    }

    .input {
      position: relative; z-index: 100; 
      flex: 0;
    }
    > div {
      flex: 1;
      position: relative;
    }
    
    ol {
      display: block;
      top: 0; left: 0;
      padding: $hmg_small 0; margin: 0;
      width: 100%; height: 100%; max-height: 100%;
      overflow-y: auto;
      @extend %scrollbar;
      position: absolute;
      z-index: 99;

      li {
        width: 100%;
        padding: 0; margin: 0;
        list-style: none;
        border-radius: $border-radius-small;
        @extend %transition_4o;

        &:hover {
          background-color: $color-bg;
          color: $color-primary
        }

        label { 
          width: 100%; padding: $hmg_small;
          display: flex; align-items: center;
          justify-content: space-between;
          margin: 0;
          line-height: 1;
        }

        &.lock {
          pointer-events: none; opacity: .5;

          > * {
            pointer-events: none;
          }
        }
      }
    }

    .wait {
      position: absolute;
      top: 50%; transform: translateY(-50%);
      left: 0; right: 0; margin: auto;
      text-align: center;

      >* {
        margin: auto;
      }
    }
  }
</style>