<template>
  <section>
    
  </section>
</template>

<script>
export default {
  data () {
    return {
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null
    }
  },
  mounted () {
    window.location.href = 'https://renegociacao.app.rededecisao.com/?token=' + process.env.VUE_APP_LARAVEL_API_LOGIN_TOKEN + '&email=' + this.currentUser.email
  }
} 
</script>

<style>

</style>