<template>
  <div class="spinner-controller" :class="{on: on}">
    <div class="spinner" :style="{borderColor: color}">
      <svg version="1.1" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 60 60" overflow="visible" xml:space="preserve">
        <g id="XMLID_4_">
          <path :fill="color" d="M46.3,26.6c0,0.3,0.1,0.5,0.1,0.7c0,1.1-0.3,2-0.9,2.8c-0.5,0.6-1.2,1.1-1.9,1.4c-0.9,0.3-1.7,0.6-2.6,0.9
            c-1.1,0.4-2.2,0.7-3.3,1.1c-0.4,0.1-0.4,0.2-0.2,0.5c1.1,1.6,2.2,3.2,3.4,4.7c0.4,0.5,0.7,1,0.8,1.6c0.3,1.7-0.1,3.1-1.4,4.2
            c-0.5,0.5-1.1,0.9-1.8,1.1c-1.5,0.5-3.5,0.1-4.6-1.4c-1-1.3-1.9-2.6-2.9-3.9c-0.1-0.1-0.2-0.3-0.3-0.4c-0.3-0.4-0.9-0.4-1.2,0
            c-0.2,0.3-0.4,0.5-0.6,0.8c-0.9,1.2-1.8,2.4-2.7,3.5c-0.6,0.8-1.4,1.3-2.4,1.5c-1.2,0.2-2.3,0.1-3.3-0.6c-0.5-0.3-1-0.7-1.4-1.2
            c-1.1-1.5-1.2-3.6,0-5.1c1.2-1.5,2.3-3.1,3.4-4.8c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0-0.2-0.1-0.3c-0.1,0-0.1,0-0.2-0.1
            c-1.7-0.6-3.4-1.1-5.1-1.7c-0.5-0.2-0.9-0.3-1.3-0.5c-1.3-0.7-2-1.8-2.2-3.3c-0.3-2.2,1.1-4.2,3.2-4.7c0.8-0.2,1.6-0.2,2.4,0
            c1.1,0.3,2.3,0.6,3.4,0.8c0.6,0.2,1.3,0.3,1.9,0.5c0.1,0,0.3,0,0.4,0c0.3-0.1,0.5-0.3,0.5-0.7c0-0.2,0-0.5,0-0.7
            c0-1.2,0.1-2.3,0.1-3.5c0-0.5,0-1.1,0.1-1.6c0.2-1.7,1.1-2.5,1.5-2.9c0.2-0.2,1.1-0.9,2.7-0.9c1.3,0,2.1,0.4,2.8,0.9
            c1,0.8,1.5,1.8,1.6,3.1c0,1.5,0.1,2.9,0.1,4.4c0,0.4,0,0.8,0.1,1.2c0,0.5,0.4,0.8,0.9,0.6c1-0.2,1.9-0.4,2.9-0.7
            c0.9-0.2,1.8-0.5,2.8-0.7C43.4,22.6,45.8,24.2,46.3,26.6z"/>
        </g>
      </svg>
    </div>
    <span
      v-if="backdrop.visible" 
      class="spinner-backdrop" 
      :style="{ 
        backgroundColor: backdrop.color,
        opacity: backdrop.opacity
      }"
    >
    </span>
  </div>
</template>
<script>
export default {
  props: {
    backdrop: {
      type: Object,
      default: () => {
        return {
          visible: false,
          color: "#FFF",
          opacity: .8
        }
      }
    },
    color: {
      type: String,
      default: "#ED1C24"
    }
  },
  data () {
    return {
      on: false
    }
  },
  mounted () {
    window.spinner.on('open', () => {
      // console.log("open spinner")
      this.on = true
    })
    window.spinner.on('close', () => {
      // console.log("close spinner")
      this.on = false
    })
  },
  methods: {

  }
}
</script>
<style lang="scss">
  .spinner-controller {
    display: block; width: 100vw; height: 100vh;
    position: fixed; z-index: 10000;
    left: 0; top: 0;
    @extend %transition_3e;
    opacity: 0; pointer-events: none;
    transform: scale(0,0);

    .spinner {
      position: absolute;
      z-index: 402;
      left: 0; right: 0; margin: auto;
      top: 50%; transform: translateY(-50%);
      width: 60px; height: 60px;
      border-radius: 100%;
      box-shadow: 0 0 5px $color-primary;
      border-width: 5px;
      border-style: solid;
      // border-color: $color-primary;
      // @extend %shadow-low-primary;
      // transition: .4s ease;
      box-shadow: 2px 6px 12px rgba(131, 84, 85, 0.5);
      animation-name: spinnerLine;
      animation-duration: 2s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;

      svg {
        animation-name: spinnerStar;
        animation-duration: 2s;
        animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
        animation-iteration-count: infinite;
        transform-origin: center;
        
        path {
          // fill: $color-primary
        }
      }
    }

    .spinner-backdrop {
      display: block;
      position: absolute; z-index: 401;
      width: 100%; height: 100%;
      @extend %transition_3o;
      border-radius: 100%;
      // filter: blur(50px)
    }

    &.on {
      opacity: 1; pointer-events: all;
      transform: scale(1,1);

      .spinner-backdrop {
        border-radius: 0;
        // filter: blur(0)
      }
    }
  }

  
  @keyframes spinnerLine {
    0%   {
      transform: scale(1, 1) translateY(-50%);
      box-shadow: 2px 6px 12px rgba(131, 84, 85, 0.5), inset 0 2px 5px rgba(131, 84, 85, .3)
    }
    50%  {
      transform: scale(1.1, 1.1) translateY(-50%);
      box-shadow: 2px 8px 16px rgba(131, 84, 85, .6), inset 0 2px 8px rgba(131, 84, 85, .6);
    }
    100% {
      transform: scale(1, 1) translateY(-50%);
      box-shadow: 2px 6px 12px rgba(131, 84, 85, 0.5), inset 0 2px 5px rgba(131, 84, 85, .3)
    }
  }
  @keyframes spinnerStar {
    0%   {transform: rotate(0) scale(1,1);}
    50% {transform: rotate(180deg) scale(.8,.8);}
    50% {transform: rotate(360deg) scale(1,1);}
    100% {transform: rotate(360deg) scale(1,1);}
  }
</style>