<template>
  <section class="panelAcompanhamento">
    <a href="#" :class="['panelToggle', { opened: opened}]" @click.prevent="togglePanel">
      <fa :icon="['fas', 'eye']"/>
      <h5>Acompanhamento</h5>
      <fa :icon="['fal','chevron-down']" class="icon"/>
    </a>
    <div :class="['panelWrap', { opened: opened}]">
      <div class="totalBar" v-if="categorias">
        <header>
          <span>
            <b>{{ total }}</b> Pendentes
          </span>
          <span>
            <b>{{ vencidos }}</b> Atrasados ({{ percent }}%)
          </span>
        </header>
        <div class="bar">
          <span :style="{ width: percent + '%' }"></span>
        </div>
      </div>
      <ul class="catList">
        <li v-for="item in categorias" :key="item">
          <div>
            <span class="overdue" v-if="item.vencidos">{{ item.vencidos > 1000 ? '999+' : item.vencidos }}</span>
            <span :class="['total', { lonely: !item.vencidos }]">{{ item.quantidade > 1000 ? '999+' : item.quantidade}}</span>
          </div>
          <p>{{ item.nomeCategoria }}</p>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import AtividadesService from '@/services/atividades.service.js'

export default {
  props: {
    type: {
      type: Object
    },
    filter: {
      type: Object
    }
  },
  data () {
    return {
      categorias: null,
      opened: true,
      total: 0,
      vencidos: 0,
      percent: 0
    }
  },
  mounted () {
    // this.getAcompanhamento(this.type, this.filter)
  },
  methods: {
    getAcompanhamento ( type, filter ) {
      this.categorias = null
      const slug = type ? type.slug : 0
      const f = filter ?? this.filter

      AtividadesService.getAcompanhamentoAtividades( slug, f ).then(
        response => {
          // console.log("getAcompanhamento:", response.data)
          this.categorias = response.data
          this.getTotal()
        },
        () => {

        }
      )
    },
    togglePanel () {
      this.opened = !this.opened
    },
    getTotal () {
      this.total = 0

      this.categorias.map( k => {
        this.total += k.quantidade
        this.vencidos += parseFloat(k.vencidos)
      })

      setTimeout(() => {
        this.percent = this.vencidos > 0 ? ( ( this.vencidos * 100 ) / this.total ).toFixed(2) : 0
      },1000)
      
    }
  }
}
</script>

<style lang="scss" scoped>
  .panelAcompanhamento {
    padding: $hmg_mid;
    overflow: hidden;
    border-bottom: $border-component;
  }
  
  .panelToggle {
    width: auto;
    display: inline-flex; align-items: center; align-content: center;
    gap: $hmg_mini;

    h5 {
      margin: 0; padding: 0
    }

    .icon {
      transform: rotate(0);
      @extend %transition_4e;
    }

    &.opened {
      .icon {
        transform: rotate(180deg);
      }
    }
  }

  .catList {
    display: flex; width: 100%;
    align-content: stretch; align-items: center;
    margin: 0; padding: 0;
    flex-wrap: wrap; @extend %transition_4o;
    opacity: 0;

    li {
      display: flex; align-content: center; align-items: center;
      flex: 1; min-width: 20%;
      margin: $hmg_mid 0 0 0; padding: 0;

      p { 
        margin: 0 0 0 $mg_mini; padding: 0
      }

      > div {
        display: flex; align-content: center; align-items: center;
       
        >span {
          width: 32px; height: 32px;
          text-align: center; display: flex;
          flex-direction: column; align-items: center;
          justify-content: center;
          font-size: 1em; font-weight: bold;
          position: relative;
          z-index: 9;

          &.overdue {
            border-radius: 0;
            border-top-left-radius: 2em;
            border-bottom-left-radius: 2em;
            background-color: $color-error;
            color: $color-secondary;
            padding-left: 5px;
            position: relative;
            z-index: 11
          }
          &.total {
            border-radius: 0;
            border-top-right-radius: 2em;
            border-bottom-right-radius: 2em;
            background-color: $color-secondary;
            width: 42px;

            &::before {
              content: '';
              display: block; width: 10px; height: 32px;
              transform-origin: left;
              transform: skewX(-10deg);
              background-color: $color-error;
              position: absolute;
              top: 0; left: -5px;
              z-index: 10;
            }

            &.lonely {
              border-radius: 2em;
              width: 32px;

              &::before {
                display: none
              }
            }
          }
        }
      }
    }
  }

  .panelWrap {
    width: 100%; height: 0; overflow: hidden;
    @extend %transition_4o;
    
    &.opened {
      height: auto;
      
      .catList {
        position: relative; opacity: 1;
      }
    }

    .totalBar {
      width: 100%; margin: $hmg_mid 0 0 0;

      header {
        display: flex; align-content: center; justify-content: space-between;
        >span {
          &:last-child{
            text-align: right;
          }
        }
      }

      .bar {
        width: 100%; height: 8px;
        border-radius: 2em;
        background-color: $color-secondary;
        margin-top: $mg_mini; 
        position: relative;

        span {
          display: block; height: 100%; width: 0;
          position: absolute;
          right: 0; top: 0;
          border-radius: 2em;
          background-color: $color-error;
          @extend %transition_6o;
        }
      }
    }
  }
</style>