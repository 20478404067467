<template>
  <section class="details">
    <header v-if="solicitacao">
      <div>
        <div>
          <fa :icon="['fal', 'cart-shopping']"></fa>
          <p>{{ solicitacao.idCheckoutMarketplace }}</p>
        </div>
        <div>
          <fa :icon="['fal', 'calendar']"></fa>
          <p>{{ formatDate(solicitacao.dataAbertura) }}</p>
        </div>
        <div :class="['timeframe', { alert1: ( inAction >= alert1 && inAction < alert2 ) , alert2: inAction >= alert2 }]">
          <fa :icon="['fal', 'calendar-range']"></fa>
          <p>{{ inAction + ( inAction == 1 ? " dia" : " dias" ) }}</p>
        </div>
        <div class="actions">
          <template v-if="!solicitacao.dataInicioTratativaAtendimeto && !solicitacao.validado && solicitaCancelamento_at">
            <fa :icon="['fal', 'arrow-right-long']" class="arrow"></fa>
            <a href="#" class="btn btn-alert" @click="engageAtendimento()" >Iniciar Atendimento</a>
          </template>
          <template v-else-if="!solicitacao.dataTratativaInicioCr && solicitacao.validado && solicitaCancelamento_cr">
            <fa :icon="['fal', 'arrow-right-long']" class="arrow"></fa>
            <a href="#" class="btn btn-success-2" @click="engageCR()" >Iniciar Financeiro</a>
          </template>
        </div>
      </div>
      <div>
        <div>
          <fa :icon="['fal', 'money-bill-transfer']"></fa>
          <p><b>Compra: </b>{{ formatMoney(solicitacao.valorvenda) }}</p>
        </div>
        <div>
          <fa :icon="['fal', 'money-from-bracket']"></fa>
          <p><b>Cancelado: </b>{{ formatMoney(solicitacao.valorCancelamento) }}</p>
          <span>{{ solicitacao.qnt_itens + ( solicitacao.qnt_itens == 1 ? ' item' : ' itens' ) }}</span>
        </div>
      </div>
    </header>
    <main v-if="solicitacao">
      <section class="tratamento">
        <div>
          <div>
            <div>
              <h4 class="color-text">{{ solicitacao.descricaoMotivoCancelamento }}</h4>
              <p v-if="solicitacao.observacaoAbertura">{{ solicitacao.observacaoAbertura }}</p>
            </div>
            <ul class="product-list">
              <li v-for="item in solicitacao.itensSolicitados" :key="item">
                <div @click.prevent="toggleImage(item)" class="thumb" v-if="item.imagemEnviada">
                  <fa :icon="['fas', 'magnifying-glass-plus']" class="icon"></fa>
                  <span :style="{ 'background-image': `url('${ item.imagemEnviada }')`}" ></span>
                </div>
                <div>
                  <h4 class="color-text">{{ item.nomeProduto }}</h4>
                  <p>{{ item.nomeAluno }} - {{  item.serie }} - {{ item.unidade  }}</p>
                  <p class="product-value"><b>{{ formatMoney(item.valorProduto) }}</b></p>
                </div>
              </li>
            </ul>
            <div class="actions" v-if="allowValidation">
              <a href="#" @click.prevent="toggleApproval(true)" :class="['btn btn-success',{ lock: approve}]">
                <fa :icon="['fal', 'check-to-slot']"></fa>
                &nbsp; Aprovar Solicitação
              </a>
              <a href="#" @click.prevent="toggleApproval(false)" :class="['btn btn-warn',{ lock: deny}]" >
                <fa :icon="['fal', 'xmark-to-slot']"></fa>
                &nbsp; Recusar Solicitação
              </a>
            </div>
            <div class="valid-alert" v-if="!allowValidation && solicitacao.validado == 1 || solicitacao.validado == 0">
              <span class="valid" v-if="solicitacao.validado == 1">
                <fa :icon="['fas', 'check']"></fa>
                Solicitação validada
              </span>
              <span class="invalid" v-if="solicitacao.validado == 0">
                <fa :icon="['fas', 'xmark']"></fa>
                Solicitação invalidada
              </span>
            </div>
            <transition-group name="fadein">
              <template v-if="approve || ( solicitacao.observacaoTratativaAtendimento && !deny && solicitacao.validado != 0 )">
                <div class="form-group">
                  <label>Observação</label>
                  <textarea-control
                    v-model="solicitacao.observacaoTratativaAtendimento"
                    placeholder="Se necessário, fale mais sobre a aprovação.."
                    name="observacaoTratativaAtendimento"
                  />
                </div>
                <div class="form-group" v-if="solicitacao.validado == 0 || !solicitacao.validado">
                  <a href="#" @click.prevent="approval('approve')" class="btn btn-primary">
                   Confirmar
                  </a>
                </div>
              </template>
            </transition-group>
            <transition-group name="fadein">
              <template v-if="deny || solicitacao.idMotivoRecusaCancelamento">
                <div class="form-group">
                  <label>Motivo da Recusa</label>
                  <select-control
                    v-model="solicitacao.idMotivoRecusaCancelamento"
                    :options="options.motivosRecusa"
                    placeholder="Selecione"
                    name="motivoRecusa"
                  />
                </div>
                <div class="form-group">
                  <label>Observação</label>
                  <textarea-control
                    v-model="solicitacao.observacaoTratativaAtendimento"
                    placeholder="Se necessário, fale mais sobre o motivo da recusa.."
                    name="motivoRecusa"
                  />
                </div>
                <div class="form-group" v-if="( solicitacao.validado == 0 || !solicitacao.validado ) && solicitaCancelamento_at">
                  <a href="#" @click.prevent="approval('deny')" class="btn btn-primary">
                   Confirmar
                  </a>
                </div>
              </template>
            </transition-group>
          </div>
          <div ref="crForm">
            <div class="voucher-alert" v-if="!solicitacao.podeGerarVoucher">
              <b>Essa compra foi paga com voucher!</b> &nbsp;
              Por esse motivo, a devolução só pode ser feita com estorno financeiro.
            </div>
            <div class="row no-padd">
              <div class="col-md-12 form-group no-padd-left">
                <h5>Dados Bancários</h5>
              </div>
              <div class="col-md-2 form-group no-padd-left">
                <label>Código banco</label>
                <input-control v-model="solicitacao.bank_code" placeholder="0000" name="bank_code" type="number" :required="!solicitacao.idTipoVoucher" :valid="valid.bank_code" @keyup="clearInvalids()"/>
              </div>
            </div>
            <div class="row no-padd">
              <div class="col-md-3 form-group no-padd-left">
                <label>Agência</label>
                <input-control v-model="solicitacao.agencia" name="agencia" placeholder="0000" type="number" :required="!solicitacao.idTipoVoucher" :valid="valid.agencia" @keyup="clearInvalids()"/>
              </div>
              <div class="col-md-2 form-group no-padd-left">
                <label>Dígito</label>
                <input-control v-model="solicitacao.agenciaDV" name="agenciaDV" placeholder="0" type="text" :required="!solicitacao.idTipoVoucher" :valid="valid.agenciaDV" @keyup="clearInvalids()"/>
              </div>
              <div class="col-md-3 form-group no-padd-left">
                <label>Conta</label>
                <input-control v-model="solicitacao.conta" name="conta" placeholder="0000" type="number" :required="!solicitacao.idTipoVoucher" :valid="valid.conta" @keyup="clearInvalids()"/>
              </div>
              <div class="col-md-2 form-group no-padd-left">
                <label>Dígito</label>
                <input-control v-model="solicitacao.contaDV" name="contaDV" placeholder="0" type="text" :required="!solicitacao.idTipoVoucher" :valid="valid.contaDV" @keyup="clearInvalids()"/>
              </div>
            </div>
            <div class="row no-padd">
              <div class="col-md-4 form-group no-padd-left">
                <label>Ajuste de valor</label>
                <input-control v-model="solicitacao.valorDevolvido" name="valorDevolvido" placeholder="Ex. 350,00" type="text" />
              </div>
              <div class="col-md-4 form-group no-padd-left" v-if="solicitacao.podeGerarVoucher">
                <label>Tipo de Voucher</label>
                <span class="selectWithAction">
                  <select-control 
                    v-model="solicitacao.idTipoVoucher" 
                    :options="options.tipoVoucher" 
                    name="idTipoVoucher"
                    placeholder="Selecione" 
                    :required="hasBankData()" 
                    :valid="valid.idTipoVoucher"  
                    @change="clearInvalids(); clearBankData()"
                  />
                  <a href="#" @click.prevent="clearVoucher()">
                    <fa :icon="['fas', 'trash-can']"></fa>
                  </a>
                </span>
              </div>
              <div class="col-md-12 form-group no-padd-left">
                <label>Observação</label>
                <textarea-control
                  v-model="solicitacao.observacaoTratativaFimCr"
                  placeholder="Se necessário, fale mais sobre essa tratativa.."
                  name="motivoRecusa"
                />
              </div>
              <div class="col-md-12 form-group no-padd-left actions-right" v-if="allowFinalize && solicitaCancelamento_cr && !working">
                <a href="#" @click.prevent="finalize()" class="btn btn-primary">
                  Finalizar
                </a>
              </div>
              <div class="valid-alert" v-else-if="solicitacao.dataFimTratativaCr">
                <span class="valid" >
                  <fa :icon="['fas', 'check']"></fa>
                  Solicitação finalizada
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <modal ref="modal" >
      <img :src="imagem" class="large-image"/>
    </modal>
  </section>
</template>

<script>
import MarketplaceService from '@/services/marketplace.service.js'
import { formatDate, formatMoney, formatDateTime, createSelectOptions } from '@/services/utils.service.js'
import moment from 'moment'
import Modal from '@/components/utilities/Modal'
import UserService from '@/services/user.service.js'
import FormService from '@/services/form.service.js'

export default {
  components: {
    Modal
  },
  props: {
    detail: {
      type: [String, Number]
    }
  },
  data () {
    return {
      solicitacao: null,
      inAction: null,
      inStatus: null,
      alert1: 2,
      alert2: 5,
      options: {
        motivosRecusa: null,
        tipoVoucher: null
      },
      wait: {
        solicitacao: false,
        motivosRecusa: false,
        tipoVoucher: false
      },
      valid: {
        bank_code: true,
        agencia: true,
        agenciaDV: true,
        conta: true,
        contaDV: true,
        idTipoVoucher: true
      },
      approve: false,
      deny: false,
      imagem: null,
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
      allowValidation: false,
      allowFinalize: false,
      solicitaCancelamento_at: false,
      solicitaCancelamento_cr: false,
      solicitaCancelamento_sec: false,
      working: false
    }
  },
  mounted () {
    // console.log("detail:", this.detail)
    window.spinner.emit("open")
    this.getSolicitacao()
    this.getMotivosRecusa()
    this.getTipoVoucher()

    this.solicitaCancelamento_at = UserService.filterPermissions('solicitaCancelamento_at')
    this.solicitaCancelamento_cr = UserService.filterPermissions('solicitaCancelamento_cr')
    this.solicitaCancelamento_sec = UserService.filterPermissions('solicitaCancelamento_sec')
  },
  methods: {
    getSolicitacao() {
      MarketplaceService.getCancelamento( this.detail ).then(
        response => {
          console.log("response:", response)
          this.solicitacao = response.data[0]
          this.calcPeriodo()

          this.wait.solicitacao = true
          this.trackActions()
          this.finishLoad()
          this.setPreValue()
          window.spinner.emit("close")
        },
        () => {
          window.spinner.emit("close")
        }
      )
    },
    formatDate( date ) {
      return formatDate(date)
    },
    formatDateTime( date ) {
      return formatDateTime(date)
    },
    formatMoney( value ) {
      return formatMoney(value)
    },
    calcPeriodo () {
      console.log("solicitacao:", this.solicitacao)

      this.inAction = moment().diff(this.solicitacao.dataAbertura,'days')

      if ( this.active == 'atendimento' ) {
        if ( this.solicitacao.dataTratativaFimAtendimento ) {
          this.inStatus = moment(this.solicitacao.dataTratativaFimAtendimento).diff(this.solicitacao.dataInicioTratativaAtendimeto,'days')
        } else {
          this.inStatus = moment().diff(this.solicitacao.dataInicioTratativaAtendimeto,'days')
        }
      }
      if ( this.active == 'financeiro' ) {
        if ( this.solicitacao.dataFimTratativaCr ) {
          this.inStatus = moment(this.solicitacao.dataFimTratativaCr).diff(this.solicitacao.dataTratativaInicioCr,'days')
        } else {
          this.inStatus = moment().diff(this.solicitacao.dataTratativaInicioCr,'days')
        }
      }
      if ( this.active == 'devolucao' ) {
        //
      }
    },
    getMotivosRecusa () {
      MarketplaceService.getMotivosRecusaCancelamento().then(
        response => {
          console.log("getMotivosRecusaCancelamento:", response.data)
          this.options.motivosRecusa = createSelectOptions(response.data, 'idMotivoRecusaCancelamento' ,'descricao')

          this.wait.motivosRecusa = true
          this.finishLoad()
        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível recuperar os motivos de recusa. Verifique sua conexão e tente novamente.", floater: true})
        }
      )
    },
    getTipoVoucher () {
      MarketplaceService.getTipoVoucher().then(
        response => {
          console.log("getTipoVoucher:", response.data)
          this.options.tipoVoucher = createSelectOptions(response.data, 'idTipoVoucher' ,'nomeTipoVoucher')

          this.wait.motivosRecusa = true
          this.finishLoad()
        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível recuperar os motivos de recusa. Verifique sua conexão e tente novamente.", floater: true})
        }
      )
    },
    finishLoad () {
      const finished = !Object.values(this.wait).some( a => !a)

      if ( finished ) {
        window.spinner.emit("close")
        // this.loading = false
      }
      // console.log("finished:", finished)

    },
    toggleApproval ( status ) {
      if ( status ) {
        this.approve = true
        this.deny = false
      } else {
        this.deny = true
        this.approve = false
      }
    },
    engage ( origem ) {
      console.log("origem:", origem)
    },
    toggleImage ( item ) {
      this.imagem = item.imagemEnviada

      const options = {
        title: item.nomeProduto,
        titleAlign: "center",
        // message: "Se quiser uma mensagem...", //não obrigatório
        size: "large", //small, mid, large, full
        backdrop: true,
        backdropColor: "transparent",  //se o backdrop for false, não precisa
        backdropClose: true, //se o backdrop for false, não precisa
        fullHeight: false,
        onClose: this.closeModal
      }
      
      this.$refs.modal.open(options)
    },
    closeModal () {
      this.imagem = null
    },
    engageAtendimento () {
      const data = {
        idUserTratativaAtendimento: this.currentUser.colaborador.idUser,
        dataInicioTratativaAtendimeto: moment()
      }

      window.spinner.emit('open')
      MarketplaceService.engageAtendimento( this.solicitacao.idSolicitacaoCancelamento, data ).then(
        response => {
          if ( response.status == 200 ) {
            this.getSolicitacao()
            window.spinner.emit('close')  
          }
        },
        () => {
          window.spinner.emit('close')
        }
      )
    },
    engageCR () {
      const data = {
        idUserTratativaCr: this.currentUser.colaborador.idUser,
        dataTratativaInicioCr: moment()
      }

      window.spinner.emit('open')
      MarketplaceService.engageCR( this.solicitacao.idSolicitacaoCancelamento, data ).then(
        response => {
          if ( response.status == 200 ) {
            this.getSolicitacao()
            window.spinner.emit('close')  
          }
        },
        () => {
          window.spinner.emit('close')
        }
      )
    },
    trackActions () {
      this.allowValidation = this.solicitacao.dataInicioTratativaAtendimeto && ( !this.solicitacao.validado && this.solicitacao.validado != 0 )
      this.allowFinalize = this.solicitacao.validado && this.solicitacao.dataTratativaInicioCr && !this.solicitacao.dataFimTratativaCr
    },
    approval ( action ) {
      const data = {
        valido: action == 'approve' ? 1 : 0,
        observacaoTratativaAtendimento: this.solicitacao.observacaoTratativaAtendimento,
        idMotivoRecusaCancelamento: action == 'approve' ? null : this.solicitacao.idMotivoRecusaCancelamento
      }

      window.spinner.emit("open")
      
      MarketplaceService.approveSolicitacaoCancelamento( this.solicitacao.idSolicitacaoCancelamento, data ).then(
        response => {
          console.log("approveSolicitacaoCancelamento:", response)
          if ( response.status == 200 ) {
            window.toaster.emit('open', {style: "success", message: "Tudo certo! Solicitação aprovada com sucesso", floater: true})

            this.approve = false
            this.deny = false
            this.solicitacao = response.data[0]
            setTimeout(() => {
              this.getSolicitacao()
            },1000)
          }

          window.spinner.emit("close")
        },
        error => {
          console.log("error - approveSolicitacaoCancelamento:", error)
          window.spinner.emit("close")
        }
      )
    },
    hasBankData () {
      const bankData = {
        bank_code: this.solicitacao.bank_code,
        agencia: this.solicitacao.agencia,
        agenciaDV: this.solicitacao.agenciaDV,
        conta: this.solicitacao.conta,
        contaDV: this.solicitacao.contaDV
      }
      
      return Object.values(bankData).some( a => !a )
    },
    clearInvalids () {
      this.valid = {
        bank_code: true,
        agencia: true,
        agenciaDV: true,
        conta: true,
        contaDV: true,
        idTipoVoucher: true
      }
    },
    clearBankData () {
      this.solicitacao.bank_code = null
      this.solicitacao.agencia = null
      this.solicitacao.agenciaDV = null
      this.solicitacao.conta = null
      this.solicitacao.contaDV = null
    },
    finalize () {
      this.working = true

      const valid = FormService.validate(this.$refs.crForm, this.valid)

      if ( valid.response ) {
        var valor = this.solicitacao.valorDevolvido
        const hasPeriod = valor.split(".").length > 1

        if ( hasPeriod ) {
          valor = this.solicitacao.valorDevolvido.replace(".","")
          valor = valor.replace(",",".")
          valor = parseFloat(valor)
        } else {
          valor = parseFloat(this.solicitacao.valorDevolvido.replace(",","."))
        }

        const data = {
          observacaoTratativaFimCr: this.solicitacao.observacaoTratativaFimCr,
          idTipoVoucher: this.solicitacao.idTipoVoucher,
          deposito: this.solicitacao.idTipoVoucher ? null : 1,
          valorDevolvido: valor,
          bank_account: {
            bank_code: this.solicitacao.bank_code ? this.solicitacao.bank_code : null,
            agencia: this.solicitacao.agencia ? this.solicitacao.agencia : null,
            agenciaDV: this.solicitacao.agenciaDV ? this.solicitacao.agenciaDV : null,
            conta: this.solicitacao.conta ? this.solicitacao.conta : null,
            contaDV: this.solicitacao.contaDV ? this.solicitacao.contaDV : null
          }
        }

        console.log("finalize - data:", data)

        window.spinner.emit("open")
        MarketplaceService.finalizarSolicitacaoCancelamento(this.solicitacao.idSolicitacaoCancelamento, data).then(
          response => {
            if ( response.status == 200 ) {
              window.toaster.emit('open', {style: "success", message: "Tudo certo! Solicitação concluída com sucesso", floater: true})

              setTimeout(() => {
                this.getSolicitacao()
                this.working = false
              },1000)

              window.spinner.emit("close")
            }
          },
          () => {
            window.spinner.emit("close")
            window.toaster.emit('open', {style: "error", message: "Ops! Algo aconteceu. Não foi possível finalizar esta solicitação.", floater: true})
            this.working = false
          }
        )
      } else {
        this.working = false
        window.toaster.emit('open', {style: "error", message: "Ops. Valide os dados do formulário e tente novamente.", floater: true})
      }
      
    },
    setPreValue() {
      this.solicitacao.valorDevolvido = this.solicitacao.valorCancelamento.toLocaleString('pt-BR')
    },
    clearVoucher () {
      this.solicitacao.idTipoVoucher = null
    }
  }
}
</script>

<style lang="scss" scoped>
  .details {
    padding: $hmg;

    >header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: $mg 0;
      
      > div {
        display: flex;
        align-items: center;
        gap: $hmg;

        > div {
          display: flex;
          align-items: center;
          gap: $hmg_mini;
          
          p {
            padding: 0; margin: 0;
          }

          span {
            padding: $hmg_mini $mg_mini;
            border-radius: 2em;
            background-color: $color-secondary;
            text-align: center;
            margin-left: $hmg_small;
            font-weight: bold;
            // display: flex; flex-direction: column;
            // align-content: center; justify-content: center;
          }
          
          &.timeframe {
            padding: $hmg_mini $mg_mini;
            border-radius: 2em;
            background-color: $color-bg;

            &.alert1 {
              background-color: $color-warning;
              color: $color-secondary
            }
            &.alert2 {
              background-color: $color-error;
              color: $color-secondary
            }
          }
        }
      }

      .arrow {
        font-size: 16px;
        margin-right: $hmg_small
      }
    }

    .tratamento {
      > div {
        display: flex; width: 100%;
        align-content: stretch;

        > div {
          flex: 1;
          padding: $hmg $hmg;

          &:first-child {
            border-right: 1px solid $color-secondary;
            padding-left: 0;
            padding-right: $hmg;
            max-width: 40%
          }

          .product-list {
            padding: 0; margin: $hmg 0;

            li {
              list-style: none;
              padding: $hmg_small;
              margin: 0 0 $hmg_small 0;
              gap: $hmg_small;
              display: flex; align-items: center;
              border-radius: $border-radius;
              background-color: $color-secondary;
              
              .thumb {
                width: 80px; height: 80px;
                position: relative;

                > span {
                  display: block;
                  width: 80px; height: 80px;
                  border-radius: $border-radius-small;
                  background-color: $color-bg;
                  background-size: cover;
                  background-repeat: no-repeat;
                  background-position: center;
                }
                
                .icon {
                  position: absolute;
                  color: $color-secondary;
                  font-size: 34px;
                  opacity: 0;
                  left: 0; right: 0; top: 50%;
                  margin: auto;
                  transform: translateY(-50%);
                  @extend %transition_3o;
                }

                &:hover {
                  .icon {
                    opacity: .8;
                  }
                }
              }

              h4 {
                margin: 0; padding: 0;
              }

              label {
                font-weight: bold;
              }

              p {
                margin: 0; padding: 0;
              }

              .product-value {
                margin-top: $hmg_mini
              }
            }
          }

          .actions {
            display: flex;
            align-content: center;
            gap: $hmg_small;
            margin: $hmg 0;

            .btn {
              &.lock {
                transform: scale(1.1);
                pointer-events: none;
                margin: 0 $mg_mini
              }
            }
          }

          .actions-right {
            display: flex;
            align-content: center;
            justify-content: flex-end
          }
          .form-group {
            @extend %transition_4o;
          }
        }
        
      }
    }
  }
  
  .large-image {
    display: block;
    width: 100%;
  }

  .valid-alert {
    display: flex;
    margin-bottom: $hmg;

    span {
      padding: $mg_mini $hmg_small;
      border-radius: $border-radius;
      display: flex; align-items: center;
      gap: $hmg_mini;
      color: $color-secondary;

      &.valid {
        background-color: $color-success-2
      }
      &.invalid {
        background-color: $color-warning
      }
    }
  }

  .selectWithAction {
    display: flex;
    align-content: center;
    align-items: center;

    a {
      margin-left: $mg_mini;
      font-size: 14px;

      &:hover {
        color: $color-primary
      }
    }
  }

  .voucher-alert {
    display: inline-flex;
    padding: $mg_mini $hmg_mid;
    background-color: $color-alert;
    color: $color-secondary;
    border-radius: $border-radius;
    margin-bottom: $hmg
  }
</style>