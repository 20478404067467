<template>
  <section class="mat-main" v-if="idMatricula">
    <header>
      <p>Etapa {{ step.index + 1 }} de {{ totalSteps }}</p>
      <h4>Mensalidades</h4>
      <p>Estas são as sua parcelas</p>
    </header>
    <section class="form-wrap">
      <mensalidades :idMatricula="idMatricula" ref="mensalidades"/> 
    </section>
  </section>
</template>

<script>
// import MatriculaService from '@/services/matricula.service'
// import { formatDate, clearMask, unformatDate } from '@/services/utils.service'
import Mensalidades from '@/components/matriculas/form/Mensalidades.vue'
export default {
  components: {
    Mensalidades
  },
  props: {
    idMatricula: {
      type: [String, Number]
    },
    auto: {
      type: Boolean,
      default: false
    },
    action: {
      type: String
    },
    step: {
      type: Object
    },
    totalSteps: {
      type: Number
    }
  },
  data () {
    return {
      matricula: null,
      loaded: false
    }
  },
  mounted () {
    this.$store.dispatch('matricula/get').then(response => {
      console.log("response get matricula on aluno", response)
      if ( response.Aluno.Basicos.idPessoa ) {
        this.matricula = response
        this.loaded = true
      } else {
        this.get()
      }
    })
  },
  methods: {
    clearLoading ( target ) {
      this.wait[target] = true
      this.stopSpinner()
    },
    stopSpinner () {
      const done = !Object.values(this.wait).includes(false)
      if ( done ) {
        window.spinner.emit("close")
        this.$emit('ready')
      }
    },
    validate () {
      return this.$refs.mensalidades.validate()
    },
    get () {
      this.$store.dispatch('matricula/get').then(response => {
        this.matricula = response
        // console.log("get filiacao - matricula response:", response)
        // console.log("idMatricula:", this.idMatricula)
        this.$nextTick(() => {
          this.$emit('ready')
          // this.loaded = true
        })
      })
    },
    save () {
      
    }
  }
}
</script>

<style lang="scss" scoped>
  
</style>