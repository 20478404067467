<template>
  <section class="section" ref="promptForm">
    <div v-if="prompt" class="frame fixed">
      <div class="row no-padd">
        <div class="col-md-5 form-group">
          <label>Descrição</label>
          <input-control 
            v-model="prompt.descricao"
            name="descricao"
            :valid="valid.descricao"
            @keyup="valid.descricao = true"
            required
          />
        </div>
      </div>
      <div class="row no-padd">
        <div class="col-md-12 form-group">
          <label>Teoria (Eu)</label>
          <textarea-control 
            v-model="prompt.promptTeoria"
            name="promptTeoria"
            ref="promptTeoria"
            :valid="valid.promptTeoria"
            @keyup="valid.promptTeoria = true"
            @change="validateVar('promptTeoria')"
            @blur="validateVar('promptTeoria')"
            size="large"
            required
            resize
          />
          <a href="#" @click.prevent="addVar('promptTeoria')" class="btn btn-primary btnInsertVar">Inserir variável</a>
        </div>
        <div class="col-md-12 form-group">
          <label>Questões (Nós)</label>
          <textarea-control 
            v-model="prompt.promptPratica"
            name="promptPratica"
            ref="promptPratica"
            :valid="valid.promptPratica"
            @keyup="valid.promptPratica = true"
            @change="validateVar('promptPratica')"
            @blur="validateVar('promptPratica')"
            size="large"
            required
            resize
          />
          <a href="#" @click.prevent="addVar('promptPratica')" class="btn btn-primary btnInsertVar">Inserir variável</a>
        </div>
        <div class="col-md-12 form-group">
          <label>Teoria (Eles)</label>
          <textarea-control 
            v-model="prompt.promptOrientacaoEstudos"
            name="promptOrientacaoEstudos"
            ref="promptOrientacaoEstudos"
            :valid="valid.promptOrientacaoEstudos"
            @keyup="valid.promptOrientacaoEstudos = true"
            @change="validateVar('promptOrientacaoEstudos')"
            @blur="validateVar('promptOrientacaoEstudos')"
            size="large"
            required
            resize
          />
          <a href="#" @click.prevent="addVar('promptOrientacaoEstudos')" class="btn btn-primary btnInsertVar">Inserir variável</a>
        </div>
      </div>
      <div class="row no-padd">
        <div class="col-md-12 form-actions form-group">
          <a href="#" @click.prevent="save()" class="btn btn-primary">
            Salvar Prompts
          </a>
          <a href="#" @click.prevent="cancel()" class="btn btn-secondary">
            Cancelar
          </a>
        </div>
      </div>
    </div>
</section>
</template>

<script>
import FormService from '@/services/form.service.js'
import EstudAIService from '@/services/estudai.service.js'

export default {
  props: {
    scope: {
      type: Object
    }
  },
  watch: {
    promptTeoria ( value, old ) {
      if ( value != old ) {
        this.validateVar('promptTeoria')
      }
    },
    promptPratica ( value, old ) {
      if ( value != old ) {
        this.validateVar('promptPratica')
      }
    },
    promptOrientacaoEstudos ( value, old ) {
      if ( value != old ) {
        this.validateVar('promptOrientacaoEstudos')
      }
    }
  },
  data () {
    return {
      prompt: null,
      valid: {
        descricao: true,
        promptTeoria: true,
        promptPratica: true,
        promptOrientacaoEstudos: true
      },
      contentVar: "[[CONTEUDO]]",
      totalVarErros: null,
    }
  },
  mounted () {
    if ( this.scope ) {
      this.prompt = Object.assign({}, this.scope)
    }
  },
  methods: {
    save () {
      const valid = FormService.validate(this.$refs.promptForm, this.valid)
      const promptTeoriaValid = this.validateVar('promptTeoria', true)
      const promptPraticaValid = this.validateVar('promptPratica', true)
      const promptOrientacaoEstudosValid = this.validateVar('promptOrientacaoEstudos', true)
      
      if ( valid.response && promptTeoriaValid && promptPraticaValid && promptOrientacaoEstudosValid ) {
        window.spinner.emit("open")
        EstudAIService.savePrompt( this.prompt ).then(
          response => {
            console.log("response:", response.data)
            window.toaster.emit('open', {style: "success", message: "Tudo certo! Prompts salvos com sucesso.", floater: true})
            window.spinner.emit("close")

            setTimeout(() => {
              this.$emit("onSave")
            },1000)
          },
          () => {
            window.spinner.emit("close")
            window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível salvar os prompts.", floater: true})
          }
        )
      }
    },
    cancel () {
      this.$emit("onCancel")
    },
    addVar ( target ) {
      const input = this.$refs[target].$refs.input

      var start = input.selectionStart
      var end = input.selectionEnd

      const newValue = this.prompt[target].substring(0, start) + this.contentVar + " " + this.prompt[target].substring(end, input.value.length)

      this.prompt[target] = newValue
      this.totalVarErros = null

    },
    validateVar ( target, respond ) {
      const text = this.prompt[target]
      const words = text.split(" ")
      const total = words.filter(k => k == this.contentVar || k.includes(this.contentVar)).length
      var response

      if ( total != 1 ) {
        this.valid[target] = false
        response = false
        const msg = total == 0 ? "A variável de conteúdo é obrigatória." : "Você deve inserir apenas uma variável de conteúdo por prompt."
        window.toaster.emit('open', {style: "error", message: msg, floater: true})
      } else {
        this.totalVarErros = null
        response = true
      }

      if ( respond ) return response
    }
  }
}
</script>

<style lang="scss" scoped>
  .form-actions {
    margin-top: $hmg !important
  }

  .btnInsertVar { 
    position: absolute;
    bottom: $hmg_small; right: $hmg;
  }

  .form-group {
    >span {
      position: relative
    }
  }
  
</style>