<template>
  <controller :goToTopButton="true">
    <div class="page-title">
      <h4 class="text-capitalize">Descontos</h4>
      <template v-if="!creatingDesconto || !creatingVoucher">
        <a 
          href="#" 
          :class="['btn', { 'btn-primary': !creatingDesconto , 'btn-secondary': creatingDesconto }]" @click.prevent="toggleCreateDesconto(true)">Novo Desconto</a>
        <a href="#" :class="['btn', { 'btn-primary': !creatingVoucher , 'btn-secondary': creatingVoucher }]" @click.prevent="toggleCreateVoucher(true)">Novo Voucher</a>
      </template>
    </div>
    <div class="page-inner" v-if="!loading">
      <transition name="fade">
        <funil-produtos 
          v-if="filter" 
          @filter="createList" 
          @close="toggleFunilFilter(false)"
          :can-cancel="funil.canCancel"
        ></funil-produtos>
      </transition>
      <section class="addNew" v-if="creatingDesconto || creatingVoucher || target === 'detalhes' ">
        <div class="addNewIntro">
          <template v-if="creatingDesconto">
            <h3>Criar Desconto</h3>
            <p>
              Para criar um desconto, preencha os campos abaixo. <br>
              Antes de salvar, valide as informações.
            </p>
          </template>
          
          <template v-if="creatingVoucher">
            <h3>Criar Voucher</h3>
            <p>
              Para criar um voucher, preencha os campos abaixo. <br>
              Antes de salvar, valide as informações.
            </p>
          </template>
        </div>
        <div class="row no-padd">
          <div class="form-group col-md-12">
            <a href="#" @click.prevent="toggleFunilFilter()" class="btn btn-primary">Selecionar Produtos</a>
          </div>
          <div class="form-group col-md-3">
            <label>Nome</label>
            <input-control :placeholder=" creatingDesconto ? 'Ex: Black Friday' : 'Ex: NATAL20' " name="nome" v-model="desconto.nome" :valid="valid.nome" @keyup="valid.nome = true" required />
          </div>
          <div class="form-group col-md-3">
            <label>Valor (%)</label>
            <input-control type="number" min="0" max="100.000" step="0.001" placeholder="Percentual" v-model="desconto.valor" name="valor" :valid="valid.valor" @keyup="valid.valor = true" required />
          </div>
          <div class="form-group col-md-3">
            <label>Início Vigência</label>
            <input-control placeholder="Início" v-model="desconto.inicio" name="inicio" type="date" :valid="valid.inicio" @keyup="valid.inicio = true" required/>
          </div>
          <div class="form-group col-md-3">
            <label>Fim Vigência</label>
            <input-control placeholder="Fim" v-model="desconto.fim" name="fim" type="date" :valid="valid.fim" @keyup="valid.fim = true" required/>
          </div>
          <div class="form-group col-md-3" v-if="creatingVoucher">
            <label>Quantidade</label>
            <input-control placeholder="Quantas vezes?" v-model="desconto.quantidade"/>
          </div>
          <template v-if="creatingDesconto || descontoId"> 
            <div class="form-group col-md-6">
              <label>Formas de pagamento</label>
              <select-control 
                :options="fPag"
                v-model="desconto.formaPagamento"
                placeholder="Forma de pagamento"
                @change.stop="selectFormaPagamento"
              />
            </div>
            <div class="form-group col-md-6">
              <label>Unidade</label>
              <select-control 
                :options="unidades"
                v-model="desconto.unidade"
                placeholder="Unidade"
              />
            </div>
          </template>
          <div class="form-group col-md-9" v-if="creatingVoucher">
            <label>Pessoas</label>
            <autocomplete
              :source="{
                type: 'service',
                target: 'user.service',
                method: 'getPessoasByNome'
              }"
              :result="{
                id: 'idPessoa',
                value: 'nome'
              }"
              :multiple="true"
              placeholder="Pra quem?"
            ></autocomplete>
          </div>
        </div>
      </section>
      <template v-if="!creatingDesconto && !creatingVoucher">
        <datatable
          :headers="descontos.headers" 
          :data="descontos.content"
          :align="descontos.align"
          :keys="descontos.keys"
          :actions="descontos.actions"
          :hasNew="false"
        ></datatable>
      </template>
      <template v-if="creatingDesconto || descontoId">
        <descontos-list :list="list" :desconto="desconto"></descontos-list>
        <div class="form-group col-md-12 form-actions actions-right">
          <a href="#" @click.prevent="cancel()" class="btn btn-secondary">Cancelar {{ descontoId ? 'Edição' : 'Criação' }}</a>
          <a href="#" @click.prevent="save()" class="btn btn-primary">Salvar Desconto</a>
        </div>
      </template>
      <confirm 
        @callback="onConfirmDelete" 
        ref="confirm"
      ></confirm>
    </div>
  </controller>
</template>

<script>
import FunilProdutos from '@/components/filter/FunilProdutos'
import { formatMoney, formatDate } from '@/services/utils.service'

import MarketplaceService from '@/services/marketplace.service'
import Autocomplete from '@/components/filter/Autocomplete'
import DescontosList from '@/components/datatable/DescontoList'

import LojaService from '@/services/loja.service'
import UserService from '@/services/user.service'
import { createSelectOptions } from '@/services/utils.service'

export default {
  components: { 
    FunilProdutos, Autocomplete, DescontosList
  },
  props: {
    target: String,
    descontoId: [Number, String]
  },
  watch: {
    descontoId ( value ) {
      console.log("descontoId:", this.descontoId)

      if ( value ) {
        this.desconto.editDesconto = 1
        this.desconto.idDesconto = value
        this.fetchDescontoDetails(value)
      }
    }
  },
  data () {
    return {
      loading: false,
      filter: false,
      creatingDesconto: false,
      creatingVoucher: false,
      list: [],
      unidades: [],
      desconto: {
        nome: null,
        valor: null,
        inicio: null,
        fim: null,
        quantidade: null,
        voucher: false,
        formaPagamento: null,
        unidade: null,
        editDesconto: 0,
        idDesconto: null
      },
      funil: {
        canCancel: false
      },
      valid: {
        nome: true,
        valor: true,
        inicio: true,
        fim: true
      },
      descontos: {
        headers: ['Nome', 'Desconto', 'Início Vigência', 'Fim Vigência'],
        keys: ['nome', 'percentual', 'dataInicio', 'dataTermino'],
        content: [],
        alignt: ['left', 'left', 'left', 'left'],
        actions: [
          {
            icon: ['far', 'pencil'],
            label: "Editar",
            showLabel: false,
            callback: item => {
              this.$router.push({name: 'Descontos', params: { target: 'detalhes', descontoId: item.iddescontosLista }})
            }
          },
          {
            icon: ["far", "trash-can"],
            label: "Remover",
            showLabel: false,
            callback: item => {
              console.log(item)
              this.$refs.confirm.run({
                message: "Tem certeza que deseja remover este item?",
                confirmButton: "Sim",
                denyButton: "Não",
                callback: this.onConfirmDelete,
                data: item
              })
            }
          }
        ],
      },
      fPag: []
    }
  },
  mounted () {
    // console.log("descontoId on load:", this.descontoId)
    // console.log("target", this.target)

    if ( this.target === 'criar-desconto' ) {
      this.cancel(true)
      setTimeout(() => {
        this.toggleCreateDesconto(true)
        this.fetchFormasPagamento()
        this.getUnidades()
        window.spinner.emit('close')
      },100)
      

    } else if ( this.target === 'criar-voucher' ) {
      this.cancel(true)
      this.toggleCreateVoucher(true)
      window.spinner.emit('close')

    } else {
      if ( this.descontoId ) {
        this.fetchDescontoDetails( this.descontoId )
        this.fetchFormasPagamento()
      }
      else this.fetchDescontos()
    }
  },
  methods: {
    toggleCreateDesconto ( state ) {
      this.creatingDesconto = state

      if ( this.creatingDesconto ) {
        // this.cancel()
        this.creatingVoucher = false
        this.fetchFormasPagamento()
        this.getUnidades()
        // this.toggleFunilFilter()
        this.funil.canCancel = true
        
        this.$router.push({ name: 'Descontos', params: { target: 'criar-desconto'}})
        this.desconto.editDesconto = 0
        this.desconto.idDesconto = null
      } else {
        this.filter = false
      }
    },
    toggleCreateVoucher ( state ) {
      this.creatingVoucher = state

      if ( this.creatingVoucher ) {
        // this.cancel()
        this.filter = false
        this.creatingDesconto = false
        this.funil.canCancel = true
        this.$router.push({ name: 'Descontos', params: { target: 'criar-voucher'}})
      }
    },
    toggleFunilFilter (state) {
      if ( state ) {
        this.filter = state
      } else {
        this.filter = !this.filter
      }
    },
    getUnidades ( ) {
      UserService.getUnidades().then(
        response => {
          console.log('getUnidades:', response.data)
          if ( response.data ) {
            this.unidades = createSelectOptions(response.data, 'idUnidade', 'descricao')
            window.spinner.emit('close')
            // this.loading = false
          }
        },
        err => {
          console.log('err:', err)
          window.spinner.emit('close')
        }
      )
    },
    createList ( data ) {
      // console.log("createList:", data)

      if ( data ) {
        // this.filter = false
        if ( data.produtos.length > 0 ) {
          //se tiver produtos
          // this.list.headers = ['Produto', 'Categoria', 'Menor Valor', 'Maior Valor', 'Início Venda', 'Fim Venda']
          // this.list.keys = ['Produto', 'nomeCategoria', 'valorVendaGrade', 'valorVenda', 'iniciovenda', 'terminovenda']
          this.list = data.produtos
          window.pagescroll.emit('goto', 600)
        } else if ( data.subcategorias.length > 0 ) {
          //se não tiver produtos mas tiver subs

        } else {
           //se não tiver produtos nem subs mas tiver cat
        }
      }
    },
    formatMoney ( value ) {
      return formatMoney(value)
    },
    formatDate ( value ) {
      return formatDate(value)
    },
    calcDesconto ( valor, desconto ) {
      console.log("desconto - valor:", valor)
      console.log("desconto - desconto:", desconto)

      return LojaService.calcDesconto(valor, desconto) 
    },
    cancel ( clear ) {
      this.toggleCreateDesconto(false)
      this.toggleCreateVoucher(false)

      this.list = []
      this.desconto = {
        nome: null,
        valor: null,
        inicio: null,
        fim: null,
        quantidade: null,
        voucher: false,
        formaPagamento: null,
        unidade: null,
        editDesconto: 0
      }

      if ( !clear ) {
        this.fetchDescontos()
        
      }
      
      this.$router.push({name: 'Descontos'})
    },
    validate () {
      var required = document.querySelectorAll(':required')

      const notValids = []

      if ( this.creatingDesconto ) {
        if ( !this.desconto.formaPagamento ) {
          if ( this.list.length == 0  || !this.list ) {
            window.toaster.emit('open', {style: "error", message: "Selecione os produtos para qual deseja criar o desconto."})
            notValids.push('list')
            this.loading = false
            window.spinner.emit("close")
          }
        }
      }
      required.forEach (k => {
        const value = k.value
        const nome = k.name

        if ( !value ) {
          notValids.push(k)
          this.valid[nome] = false
          window.toaster.emit('open', {style: "error", message: "Preencha todos os campos obrigatórios, para criar o desconto."})
          // k.className += ' error'; 
          this.loading = false
          window.spinner.emit("close")
        }
    })

      return notValids.length === 0

    },
    save () {
      this.loading = true
      const valid = this.validate()
      window.spinner.emit("open")
      // console.log("valid:", valid)
      if ( valid ) {
        //pegar pessoas selecionadas
        this.desconto.voucher = this.creatingVoucher
        this.desconto.produtos = this.list

        LojaService.createDescontoMarketPlace(this.desconto).then(
          response => {
            if ( response.data === 'Sucesso') {
              window.toaster.emit('open', {style: "success", message: "Desconto criado com sucesso."})
              this.cancel()
            } else {
              window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível criar o desconto."})
            }

            this.loading = false
            window.spinner.emit("close")
          },
          error => {
            console.log("error:", error.request)
            const msg = error.request.responseText == "\"CUPOM J\\u00c1 EXISTE NO SISTEMA, UTILIZE OUTRO NOME.\"" ? 'Já existe um cupom com o nome informado.' : 'Ops. Algo aconteceu. Não foi possível criar o desconto.'

            window.toaster.emit('open', {style: "error", message: msg})
            
            this.loading = false
            window.spinner.emit("close")
            
          }
        )
      }

    },
    fetchDescontos () {
      LojaService.getDescontosMarketPlace().then(
        response => {
          console.log("getDescontosMarketPlace:", response)

          const descontos = []
          if ( response.data ) {
            // this.descontos.content = response.data

            response.data.map( k => {
              k.dataInicio = formatDate(k.dataInicio)
              k.dataTermino = formatDate(k.dataTermino)
              k.percentual = k.percentual + '%'
              descontos.push(k)

            })
            this.descontos.content = descontos;

            console.log("descontos:", descontos)
            window.spinner.emit("close")
          }
        },
        error => {
          console.log("error:", error)
          window.spinner.emit("close")
        }
      )
    },
    confirm ( item ) {
      console.log('item:', item)
    },
    onConfirmDelete ( confirm, data ) {
      console.log('confirm:', confirm)
      console.log('data:', data)
      const idDesconto = data.iddescontosLista

      MarketplaceService.deleteDescontosListaById( idDesconto ).then(
        response => {
          if ( response.data ) {
            const desconto = response.data
            console.log("DeleteDescontoDetails:", desconto)

            this.fetchDescontos()
            window.spinner.emit("close")
          }
        },
        error => {
          console.log("error:", error)
          window.spinner.emit("close")
        }
      )
    },
    fetchDescontoDetails ( idDesconto ) {
      window.spinner.emit('open')

      this.descontos.content = []
      MarketplaceService.getDescontoListById( idDesconto ).then(
        response => {
          if ( response.data ) {
            
            const desconto = response.data
            console.log("fetchDescontoDetails:", desconto)
            
            this.desconto = {
              nome: desconto[0].nomeDesconto,
              valor: desconto[0].percentual,
              inicio: desconto[0].dataInicio,
              fim: desconto[0].dataTermino,
              quantidade: desconto[0].quantidade ? desconto[0].quantidade : null,
              voucher: desconto[0].voucher ? desconto[0].voucher : false,
              formaPagamento: desconto[0].idformapagamento ? desconto[0].idformapagamento : null,
              unidade: desconto[0].idUnidade ? desconto[0].idUnidade : null,
            }

            // desconto.map(k => {
            //   console.log('k:', k)
            //   k.valor = k.percentual
            // })

            if ( !desconto[0].idformapagamento ) {
              this.list = desconto
            }
            

            console.log("desconto:", this.desconto)
            window.spinner.emit("close")
          }
        },
        error => {
          console.log("error:", error)
          window.spinner.emit("close")
        }
      )
    },
    fetchFormasPagamento () {
      MarketplaceService.getFormasPagamento().then(
        response => {
          console.log("response:", response)

          const formas = []
          response.data.map( k => {
            k.label =  k.descricao + ' (' + k.datainicial + ' - ' + k.datafinal + ')' + ' - ' + k.idformapagamentomaterial
            k.value = k.idformapagamentomaterial
            formas.push(k)
          })

          this.fPag = formas
        },
        error => {
          console.log("error:", error)
        } 
      )
    },
    selectFormaPagamento ( data ) {
      console.log("selectFormaPagamento:", data)
    }
  }
}
</script>

<style lang="scss" scoped>
  

  .addNew {
    margin-bottom: $mg;

    .addNewIntro {
      padding: 0 $hmg_mid;
      margin-bottom: $hmg;
    } 
  }

  

  .form-actions {
    padding-bottom: 100px;
  }
  
</style>