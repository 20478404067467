<template>
  <section>
    <header>
      <h5>
        Filtrar Alçadas
      </h5>
    </header>
    <div class="frame fixed">
      <div class="row no-padd" ref="filterAlcadaForm">
        <div class="col-md-6 form-group">
          <label>Unidade</label>
          <select-control 
            v-model="filter.idUnidade" 
            :options="options.unidades"  
            :disabled="loading"
            :valid="valid.idUnidade" 
            name="idUnidade" 
            placeholder="Selecione"
            @change="getPeriodosLetivos(); valid.idUnidade = true" 
            required
          />
        </div>
        <div class="col-md-4 form-group">
          <label>Período Letivo</label>
          <select-control 
            v-model="filter.idPeriodoLetivo" 
            :options="options.periodosLetivos" 
            :disabled="loading"
            :valid="valid.idPeriodoLetivo" 
            name="idPeriodoLetivo"
            placeholder="Selecione"
            @change="valid.idPeriodoLetivo = true"
            required
          />
        </div>
        <div class="col-md-4 form-group">
          <label>Funções</label>
          <select-control 
            v-model="filter.idFuncao" 
            :options="options.funcoes" 
            :disabled="loading"
            :valid="valid.idFuncao" 
            name="idFuncao"
            placeholder="Selecione" 
            @change="valid.idFuncao = true"
            required
          />
        </div>
        <div class="col-md-4 form-group" >
          <label>Tipo Matrícula</label>
          <select-control 
            v-model="filter.tipoMatricula" 
            :options="options.tipoMatricula" 
            :disabled="loading"
            name="tipoMatricula"
            placeholder="Selecione" 
          />
        </div>
        <div class="col-md-4 form-group">
          <label>Tipo Arrecação</label>
          <select-control 
            v-model="filter.idTipoArrecadacao" 
            :options="options.arrecadacoes" 
            :disabled="loading"
            :valid="valid.idTipoArrecadacao" 
            name="idTipoArrecadacao"
            placeholder="Selecione"
            @change="valid.idTipoArrecadacao = true"
            required
          />
        </div>
      </div>
      <div class="col-md-12 form-actions form-group">
        <a href="#" @click.prevent="filterAlcadas()" class="btn btn-primary">
          Buscar
        </a>
        <a href="#" @click.prevent="clearFilter()" class="btn btn-secondary">
          Limpar
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import AdmService from '@/services/adm.service.js'
import UserService from '@/services/user.service.js'
import FinanceiroService from '@/services/financeiro.service.js'
import AcademicoService from '@/services/academico.service.js'
import FormService from '@/services/form.service.js'
import { createSelectOptions } from '@/services/utils.service.js'

export default {
  props: {
    state: {
      type: String
    }
  },
  data () {
    return {
      filter: {
        idUnidade: null,
        idPeriodoLetivo: null,
        idFuncao: null,
        tipoMatricula: null,
        idTipoArrecadacao: null
      },
      options: {
        unidades: [],
        periodosLetivos: [],
        funcoes: [],
        tipoMatricula: [
          { label: 'Matrícula', value: "1" },
          { label: 'Rematrícula', value: "2" }
        ],
        arrecadacoes: []
      },
      wait: {
        funcoes: false,
        unidades: false,
        arrecadacoes: false
      },
      valid: {
        idUnidade: true,
        idPeriodoLetivo: true,
        idFuncao: true,
        idTipoArrecadacao: true
      },
      loading: false
    }
  },
  mounted () {
    this.getUnidades()
    this.getArrecadacoes()
    this.getFuncoes()

    window.spinner.emit("open")
  },
  methods: {
    getUnidades () {
      UserService.getMinhasUnidades().then(
        response => {
          console.log("getMinhasUnidades:", response.data)
          this.options.series = []
          this.options.periodosLetivos = []
          
          if ( response.data.length > 0 ) {
            this.options.unidades = createSelectOptions(response.data, 'idUnidade' ,'descricao')
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhuma unidade encontrada. Verifique sua conexão e tente novamente.", floater: true})
          }
          
          this.wait.unidades = true
          this.finishLoad()
        },
        () => {
          window.spinner.emit("close")
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma unidade encontrada. Verifique sua conexão e tente novamente.", floater: true})
        }
      )
    },
    getPeriodosLetivos () {
      this.loading = true
      AcademicoService.getPeriodosLetivos(this.filter.idUnidade).then(
        response => {
          console.log("getPeriodosLetivos:", response.data)
          
          if ( response.data.length > 0 ) {
            this.options.periodosLetivos = createSelectOptions(response.data, 'idPeriodoLetivo' ,'descricao').reverse()
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum período letivo encontrado para esta unidade. Isto parece ser um problema de parametrização.", floater: true})
          }
          this.loading = false
        },
        error => {
          console.error(error)
          this.loading = false
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum período letivo encontrado para esta unidade. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    getArrecadacoes () {
      window.spinner.emit('open')
      FinanceiroService.getArrecadacoes().then(
        response => {
          console.log("getArrecadacoes:", response)
          if ( response.data.length > 0 ) {
            this.options.arrecadacoes = createSelectOptions(response.data, 'idTipoArrecadacao' ,'descricao')
          } else {
            window.toaster.emit('open', {style: "error", message: "Não foi possível recuperar as arrecadações. Isto parece ser um problema de integração com o GestorClass.", floater: true})
          }
          
          this.wait.arrecadacoes = true
          this.finishLoad()
        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível recuperar as arrecadações.", floater: true})
          window.spinner.emit('close')
        }
      )
    },
    getFuncoes () {
      AdmService.getFuncoes().then(
        response => {
          console.log("getFuncoes:", response.data)
          if ( response.data.length > 0 ) {
            this.options.funcoes = createSelectOptions(response.data, 'idFuncao' ,'nome')
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhuma função encontrada. Isto parece um problema de parametrização.", floater: true})
          }

         this.wait.funcoes = true
         this.finishLoad()
        },
        () => {
          window.spinner.emit("close")
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma função encontrada. Verifique sua conexão e tente novamente.", floater: true})
        }
      )
    },
    filterAlcadas () {
      const valid = FormService.validate(this.$refs.filterAlcadaForm, this.valid)
      console.log("valid:", valid)

      if ( valid.response ) {
        this.$emit('onFilter', this.filter)
      }
      
    },
    finishLoad () {
      const finished = !Object.values(this.wait).some( a => !a)

      if ( finished ) {
        window.spinner.emit("close")
        this.loading = false
      }
    },
    clearFilter () {
      this.filter = {
        unidade: null,
        periodoLetivo: null
      }
    }
  }
}
</script>

<style scoped lang="scss">
  header {
    padding: $hmg;

    h5 {
      margin: 0; padding: 0;
    }
    p {
      margin: 0; padding: 0;
    }
  }

  // .frame {
  //   padding: 0 $hmg_small
  // }
  .form-actions {
    margin-top: $hmg !important
  }
</style>