<template>
  <nav>
    <a 
      v-for="item in types" 
      :key="item" 
      :class="[item.slug, { hidden: selectedType == item.slug }]"
      @click.prevent="filterType(item)"
      href="#"
    >
      <p>{{ item.label }}</p>
      <span></span>
    </a>
    <a 
      @click.prevent="filterType('todos')"
      class="todos"
      href="#"
      v-if="selectedType"
    >
      <p>Todos</p>
      <span></span>
    </a>
  </nav>
</template>

<script>
export default {
  props: {
    types: {
      type: Array
    }
  },
  data () {
    return {
      // types: [
      //   {
      //     label: "Atendimentos",
      //     slug: "atendimentos"
      //   },
      //   {
      //     label: "Tarefas",
      //     slug: "tarefas"
      //   },
      //   {
      //     label: "Tickets",
      //     slug: "tickets"
      //   },
      //   {
      //     label: "Solicitações",
      //     slug: "solicitacoes"
      //   },
      //   {
      //     label: "Prospects",
      //     slug: "prospects"
      //   }
      // ],
      selectedType: null
    }
  },
  mounted () {

  },
  methods: {
    filterType (item) {
      if ( item == 'todos' ) {
        this.selectedType = null
        this.$emit('filterType', null)
      } else {
        this.selectedType = item.slug
        this.$emit('filterType', item)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  nav {
    display: flex;
    align-items: center; align-content: flex-start;
    gap: $mg_mini;
    margin: $hmg_mid 0 0 0;

    a {
      display: flex; align-items: center; align-content: center; 
      justify-content: center; flex-direction: column;
      width: auto; padding: $hmg_mini $mg_mini;
      position: relative;
      @extend %transition_3o;

      &.hidden {
        display: none
      }

      > span {
        display: block;
        width: 100%; height: 100%;
        border-radius: 2em;
        position: absolute; z-index: 1;
        @extend %transition_4e;
        top: 0; left: 0; right: 0;
        margin: auto;
        // transform: translateY(-50%) scale(1);
        transform-origin: center center;
      }
      > p {
        position: relative; z-index: 2;
        margin: 0; padding: 0;
        color: $color-secondary;
      }

      &:hover {
        > span {
          transform: scale(1.1)
        }
      }

      &.atendimentos {
        > span {
          background-color: $color-atendimentos;
        }
      }
      &.tarefas {
        > span {
          background-color: $color-tarefas;
        }
      }
      &.tickets {
        > span {
          background-color: $color-tickets;
        }
      }
      &.solicitacoes {
        > span {
          background-color: $color-solicitacoes;
        }
      }
      &.prospects {
        > span {
          background-color: $color-prospects;
        }
      }
      &.todos {
        > p {
          color: $color-text
        }
        > span {
          background-color: $color-bg;
        }
      }
    }
  }
</style>