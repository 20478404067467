import axios from 'axios'
import authHeader from './auth-header'
import moment from 'moment'

class LojaService {
  getMenuLoja() {
    return axios.get(process.env.VUE_APP_API_URL + 'categoria/getcategorias/0', { headers: authHeader() })
  }
  
  getDetahesCategoria( categoria ) {
    return axios.get(process.env.VUE_APP_API_URL + 'categoria/getcategorias/' + categoria, { headers: authHeader() })
  }

  getMenuCategoriasLoja() {
    return axios.get(process.env.VUE_APP_API_URL + 'categoria/getcategoriasmenu', { headers: authHeader() })
  }

  // getMenuProdutosLoja( id ) {
  //   return axios.get(process.env.VUE_APP_API_URL + 'categoria/getprodutosmenu/' + id, { headers: authHeader() })
  // }

  getCart () {
    const carrinho = localStorage.getItem('carrinho')
    const cart = JSON.parse(carrinho)
    console.log("cart", cart)

    if ( cart ) {
      console.log("carrinho.createdAt:", cart.createdAt)
      const cartDate = moment(cart.createdAt)
      const today = moment()
      const dif = today.diff(cartDate, 'days')
      
      console.log("cart age:", dif)

      if (dif < 2) {
        console.log("dif < 5.. KEEP CART")
        return cart
        
      } else {
        this.clearCart()
      }
    }
  }
  
  buscaProdutosLoja( data ) {
    // console.log("LojaService.buscaProdutosLoja:", data)
    // data: {
    //   "categoriasIds": [5],
    //   "subCategoriasIds": [],
    //   "text": "Blusa",
    //   "idPessoaResponsavel": 12312
    // }
    return axios.post(process.env.VUE_APP_API_URL + 'buscaprodutosloja', data, { headers: authHeader() })
  }

  buscaProdutosLojaSemSegmento( data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'loja/buscaprodutosloja/semseguimento/tela/parametros', data, { headers: authHeader() })
  }

  buscaProdutoTipoMaterial( idTipoMaterial ) {
    return axios.get(process.env.VUE_APP_API_URL + 'produto/detalhe/' + idTipoMaterial + '/0/1', { headers: authHeader() })
  }
  
  getDetalhesServicoExtra ( idTipoMaterial, idMatricula ) {
    // console.log("idTipoMaterial:", idTipoMaterial)
    const matricula = idMatricula ? idMatricula + '/0' : '0/1'

    return axios.get(process.env.VUE_APP_API_URL + 'produto/detalheprodutomp/' + idTipoMaterial + '/' + matricula, { headers: authHeader() })
    // return axios.get(process.env.VUE_APP_API_URL + 'produto/detalhe/708/91083/0', { headers: authHeader() })
  }

  setImageRoute ( route ) {
    // return process.env.VUE_APP_PRODUCT_URL + encodeURIComponent(route?.trim())
    return axios.get(process.env.VUE_APP_API_URL + 'produto/imagem/s3/' + route, { headers: authHeader() })
  }
  
  serializeCart () {
    let carrinho = localStorage.getItem('carrinho')
        carrinho = JSON.parse(carrinho)   
    
    const serialized = []

    carrinho.produtos.map(k => {
      // console.log("item.produto:", k)
      if ( k.selecao.length > 0 ) {
        if ( k.produto.servicoextra ) {
          k.selecao.map(ki => {
            serialized.push(ki)
          })
        } else {
          k.selecao.map(ki => {
            serialized.push(ki)
          })
        }
        
      } else {
        serialized.push(k)
      }
      
    })

    // console.log("serializeCart:", carrinho)
    // console.log("serializeCart:", serialized)

    return serialized
  }

  clearCart () {
    let cart = localStorage.getItem('carrinho')
    if ( cart ) {
      localStorage.removeItem('carrinho')
    }
    let checkoutCart = localStorage.getItem('carrinho-checkout')
    if ( checkoutCart ) {
      localStorage.removeItem('carrinho-checkout')
    }
  }

  createCheckoutCart ( cart ) {
    localStorage.removeItem('carrinho-checkout')
    localStorage.setItem('carrinho-checkout',JSON.stringify(cart))
  }

  getCheckoutCart () {
    return JSON.parse(localStorage.getItem('carrinho-checkout'))
  }

  updateCart ( action, produto, pessoa ) {
    const carrinho = localStorage.getItem('carrinho')
    let cart_config

    if ( action == 'update' ) {
      localStorage.setItem('carrinho',JSON.stringify(produto))
    } else {
      if ( !carrinho ) {
        cart_config = {
          timestamp: moment().toISOString(),
          createdAt: moment().toISOString(),
          produtos: []
        }
        // console.log("cart_config new:", JSON.stringify(cart_config))
        localStorage.setItem('carrinho',JSON.stringify(cart_config))
      } else {
        cart_config = JSON.parse(localStorage.getItem('carrinho'))
        
      }
      
      if ( action === 'add' ) {
        // console.log("add - cart_config:", cart_config)
  
        const add = this.addToCart(cart_config, produto, pessoa)
        // console.log("add:", add)
  
        if ( add.status ) {
          window.cart.emit("update", true)
          return add
        } else {
          // window.cart.emit("update")
          return add
        }
      }
      if ( action === 'remove' ) {
        const remove = this.removeFromCart(cart_config, produto, pessoa)
        
        // console.log("remove:", remove)
        if ( remove ) {
          window.cart.emit("update")
          return remove
        } else {
          // window.cart.emit("update")
          return remove
        }
  
      }
    }
    
    // return axios.post(process.env.VUE_APP_API_URL, cart ,{ headers: authHeader() });
  }
  
  addToCart ( carrinho, produto, pessoa ) {
    // console.log("add produto:", produto)
    
    let response = {
      status: false,
      message: '',
      carrinho: null
    }

    const productID = produto.produto.iditemmaterial ? produto.produto.iditemmaterial : produto.produto.idtipomaterial
    
    // console.log("productID:", productID)
    // console.log("gradeProduto:", produto.produto.gradeProduto)

    if ( !produto.produto.gradeProduto || produto.produto.gradeProduto === 0 ) {
      if (!produto.produto.comprasMultiplas || produto.produto.comprasMultiplas === 0 ) {
        var produtoExiste, produtoExistente
        
        carrinho.produtos.map((k,i) => {
          // console.log("carrinho - k:", k)
          const itemID = k.produto.iditemmaterial ? k.produto.iditemmaterial : k.produto.idtipomaterial
          // console.log('itemID:', itemID)
          // console.log('productID:', productID)
          // if ( k.produto.descontosUnidade ) {
          //   console.log("existe desconto unidade, remover...")
          //   delete k.produto.descontosUnidade
          // }
          // if ( k.produto.selectedAluno[0].descontosUnidade ) {
          //   console.log("existe desconto unidade aluno, remover...")
          //   delete k.produto.selectedAluno[0].descontosUnidade
          // }
          
          if ( itemID === productID  && k.produto.selectedAluno[0].idAluno == produto.produto.selectedAluno[0]?.idAluno ) {
            produtoExiste = true
            produtoExistente = i
          }
        })

        if ( !produtoExistente ) //

        // console.log("produtoExiste 1:", produtoExiste)
        // console.log("produtoExistente:", produtoExistente)

        if ( produtoExiste ) {
          response.status = false
          response.message = "Ops. Infelizmente este produto só pode ser comprado uma vez."
        } else {
          console.log("produto não existe 1")
          carrinho.produtos.push(produto)

          response.status = true
          response.message = "Produto(s) inserido(s) com sucesso"
        }
      } else {
        
        let produtoExiste, produtoExistente
        
        carrinho.produtos.map((k,i) => {
          if ( k.produto.descontosUnidade ) {
            // console.log("existe desconto unidade, remover...")
            delete k.produto.descontosUnidade
          }

          if ( k.produto.selectedAluno[0].descontosUnidade ) {
            // console.log("existe desconto unidade aluno, remover...")
            delete k.produto.selectedAluno[0].descontosUnidade
          }

          if ( k.produto.idtipomaterial === productID && k.produto.selectedAluno[0].idAluno == produto.produto.selectedAluno[0]?.idAluno ) {
            produtoExiste = true
            produtoExistente = i
          }
        })

        if ( produtoExiste ) {
          // console.log("produto existe 2:", produtoExistente)
          // console.log("produtos:", carrinho.produtos)
          carrinho.produtos[produtoExistente].produto.quantidade += produto.produto.quantidade

          if ( carrinho.produtos[produtoExistente].selecao ) carrinho.produtos[produtoExistente].selecao[0].quantidade += produto.produto.quantidade
          
          // carrinho.produtos[produtoExistente].produto.selectedAluno = carrinho.produtos[produtoExistente].produto.selectedAluno.concat(produto.produto.selectedAluno)
          // carrinho.produtos[produtoExistente].produto.selectedTurma = carrinho.produtos[produtoExistente].produto.selectedTurma.concat(produto.produto.selectedTurma)

          carrinho.produtos[produtoExistente].produto.selectedAluno = Object.assign([], carrinho.produtos[produtoExistente].produto.selectedAluno, produto.produto.selectedAluno)
          carrinho.produtos[produtoExistente].produto.selectedTurma = Object.assign([], carrinho.produtos[produtoExistente].produto.selectedTurma, produto.produto.selectedTurma)
        } else {
          // console.log("produto não existe 2")
          carrinho.produtos.push(produto)
        }
        
        response.status = true
        response.message = "Produto(s) inserido(s) com sucesso"
      }
    } else if ( produto.produto.gradeProduto === 1 ) {
      const composID = produto.produto.servicoextra == 1 ? produto.produto.idtipomaterial : produto.selecao[0].composicao.idGradeProduto
      // console.log("composID:", composID)
      
      let produtoExiste, produtoExistente, composicaoExiste, composicaoExistente
      
      carrinho.produtos.map((k,i) => {
        if ( k.produto.descontosUnidade ) {
          // console.log("existe desconto unidade, remover...")
          delete k.produto.descontosUnidade
        }
        if ( k.produto.selectedAluno[0].descontosUnidade ) {
          // console.log("existe desconto unidade aluno, remover...")
          delete k.produto.selectedAluno[0].descontosUnidade
        }

        // console.log("carrinho - k 3:", k)
        if ( k.produto.iditemmaterial === productID  && k.produto.selectedAluno[0].idAluno == produto.produto.selectedAluno[0]?.idAluno ) {
          produtoExiste = true
          produtoExistente = i
        }
        
        k.selecao.map((ks,ksi) => {
          // console.log("ks:", ks)

          if ( ks.composicao?.idGradeProduto === composID ) {
            composicaoExiste = true
            composicaoExistente = ksi
          }
        })
      })
      
      if ( produtoExiste && composicaoExiste ) {
        // console.log("produto existe / composição existe")
        const quantidadeAtual = carrinho.produtos[produtoExistente].selecao[composicaoExistente].quantidade
        const novaQuantidade = quantidadeAtual + produto.selecao[0].quantidade

        carrinho.produtos[produtoExistente].selecao[composicaoExistente].quantidade = novaQuantidade

        // carrinho.produtos[produtoExistente].produto.selectedAluno = carrinho.produtos[produtoExistente].produto.selectedAluno.concat(produto.produto.selectedAluno)
        // carrinho.produtos[produtoExistente].produto.selectedTurma = carrinho.produtos[produtoExistente].produto.selectedTurma.concat(produto.produto.selectedTurma)
        carrinho.produtos[produtoExistente].produto.selectedAluno = Object.assign([], carrinho.produtos[produtoExistente].produto.selectedAluno, produto.produto.selectedAluno)
        carrinho.produtos[produtoExistente].produto.selectedTurma = Object.assign([], carrinho.produtos[produtoExistente].produto.selectedTurma, produto.produto.selectedTurma)

        
      } else if ( produtoExiste && !composicaoExiste ) {
        // console.log("produto existe / composição não existe")
        const novaComposicao = produto.selecao[0]

        carrinho.produtos[produtoExistente].selecao.push(novaComposicao)

        // carrinho.produtos[produtoExistente].produto.selectedAluno = carrinho.produtos[produtoExistente].produto.selectedAluno.concat(produto.produto.selectedAluno)
        // carrinho.produtos[produtoExistente].produto.selectedTurma = carrinho.produtos[produtoExistente].produto.selectedTurma.concat(produto.produto.selectedTurma)
        carrinho.produtos[produtoExistente].produto.selectedAluno = Object.assign([], carrinho.produtos[produtoExistente].produto.selectedAluno, produto.produto.selectedAluno)
        carrinho.produtos[produtoExistente].produto.selectedTurma = Object.assign([], carrinho.produtos[produtoExistente].produto.selectedTurma, produto.produto.selectedTurma)
      } else {
        // console.log("produto não existe / composição não existe")
        carrinho.produtos.push(produto)
      }

      response.status = true
      response.message = "Produto(s) inserido(s) com sucesso"
    }

    response.carrinho = carrinho

    carrinho.timestamp = moment().toISOString()
    localStorage.setItem('carrinho',JSON.stringify(carrinho))
    // console.log("update response:", response)
    this.saveCart(pessoa)

    return response
  }

  removeFromCart ( carrinho, produto, pessoa ) {
    carrinho.timestamp = moment()
    
    let response = {
      status: false,
      message: '',
      carrinho: null
    }

    const item = produto.item
    const sub = produto.sub

    if ( typeof sub === 'undefined' ) {
      carrinho.produtos.splice(item, 1)
    } else {
      const selecao = carrinho.produtos[item].selecao

      selecao.splice(sub, 1)
    }

    response.status = 'Item removido com sucesso'
    response.carrinho = carrinho
    response.status = true
    
    this.saveCart(pessoa)

    return response
  }

  getProductGrid ( idComposicao ) {
    return axios.get(process.env.VUE_APP_API_URL + 'gradesproduto/0/' + idComposicao, { headers: authHeader() })
  }

  saveCart ( idPessoa, step ) {
    let cart = localStorage.getItem('carrinho')
    const now = moment().format("YYYY-MM-DD HH:mm:ss")
    // console.log("now:", now)
    // console.log("idPessoa:", idPessoa)

    const data = {
      "etapas": {
        "idPessoaResponsavel": idPessoa,
        "viewConfirmaDados": step == 'viewConfirmaDados' ? now : null,
        "viewUnidadeRetirada": step == 'viewUnidadeRetirada' ? now : null,
        "viewCupom": step == 'viewCupom' ? now : null,
        "viewPagamento" : step == 'viewPagamento' ? now : null
      },
      produtos: JSON.parse(cart)
    }
    // console.log("saveCart:", data)

    return axios.post(process.env.VUE_APP_API_URL + 'checkoutmarketplace/checkout/conversao', data, { headers: authHeader() })
  }
  // saveCart ( nome ) {
  //   let cart = localStorage.getItem('carrinho');
  //   cart = JSON.parse(cart)
  //   cart['nomeCart'] = nome;

  //   return axios.post(process.env.VUE_APP_API_URL + 'carrinho/addcarrinho', cart, { headers: authHeader() })
  // }

  getTotalItensFromCart () {
    const cart = JSON.parse(localStorage.getItem('carrinho'))
    var total = 0

    if ( cart ) {
      const items = cart.produtos

      if ( items ) {
        items.map(k => {
          if ( k.selecao.length > 0 ) {
            k.selecao.map(ki => {
              total += ki.quantidade
            })
          } else {
            total += k.produto.quantidade
          }
        }) 
      }
    }
  
    return total
  }

  getTotalValueFromCart ( desc, model ) {
    var cart = model ? JSON.parse(localStorage.getItem(model)) : JSON.parse(localStorage.getItem('carrinho'))
    var total = 0

    if ( cart ) {
      const items = cart.produtos
      items.map(k => {
        const desconto = k.produto.percentual
        const allowDesconto = k.produto.allowDesconto
        const unidadeDesconto = k.produto.UnidadeDesconto

        // console.log("item.produto:", k)

        if ( k.selecao.length > 0 ) {
          if ( k.produto.servicoextra ) {
            k.selecao.map(ki => {
              if ( desc && desconto || allowDesconto && unidadeDesconto) {
                if ( unidadeDesconto && desconto ) {
                  total += ( this.calcDesconto(this.calcDesconto(k.produto.PrecoVenda, desconto), unidadeDesconto) * ki.quantidade )
                } else if ( unidadeDesconto && !desconto ) {
                  total += ( this.calcDesconto(k.produto.PrecoVenda, unidadeDesconto) * ki.quantidade )
                } else {
                  total += ( this.calcDesconto(k.produto.PrecoVenda, desconto) * ki.quantidade )
                }
                
              } else {
                total += ( k.produto.PrecoVenda * ki.quantidade )
              }
            })
          } else {
            k.selecao.map(ki => {
              if ( desc && desconto || allowDesconto && unidadeDesconto ) {
                if ( unidadeDesconto && desconto ) {
                  total += ( this.calcDesconto(this.calcDesconto(ki.composicao.valorVendaGrade, desconto), unidadeDesconto) * ki.quantidade )
                } else if ( unidadeDesconto && !desconto ) {
                  total += ( this.calcDesconto(ki.composicao.valorVendaGrade, unidadeDesconto) * ki.quantidade )
                } else {
                  total += ( this.calcDesconto(ki.composicao.valorVendaGrade, desconto) * ki.quantidade )
                }
                
              } else {
                total += ( ki.composicao.valorVendaGrade * ki.quantidade )
              }
            })
          }
          
        } else {
          const valor = k.produto.precoitem > 0 ? k.produto.valorVenda : k.produto.PrecoVenda
          if ( desc && desconto || allowDesconto && unidadeDesconto ) {
            if ( unidadeDesconto && desconto ) {
              total += ( this.calcDesconto(this.calcDesconto(valor, desconto), unidadeDesconto) * k.produto.quantidade )
            } else if ( unidadeDesconto && !desconto ) {
              total += ( this.calcDesconto(valor, unidadeDesconto) * k.produto.quantidade )
            } else {
              total += ( this.calcDesconto(valor, desconto) * k.produto.quantidade )
            }

            
          } else {
            total += ( valor * k.produto.quantidade )
          }
        }
      })
    }
    

    // console.log("valor total:", total)
    // this.$emit('total', total)
    return total
  }

  getTotalItemValue ( items ) {
    let total = 0

    items?.map(k => {
      total += ( k.composicao.valorVendaGrade * k.quantidade )
    })

    return total
  }

  // setDiscountToCart () {
  //   const cart = JSON.parse(localStorage.getItem('carrinho'))
  //   var total = 0

  //   if ( cart ) {
  //     const items = cart.produtos
  //     items.map(k => {
  //       const desconto = k.produto.percentual
  //       // console.log("item.produto:", k)
  //       if ( k.selecao.length > 0 ) {
  //         if ( k.produto.servicoextra ) {
  //           k.selecao.map(ki => {
  //             if ( desconto ) {
  //               k.produto.PrecoVendaDesconto = this.calcDesconto(k.produto.PrecoVenda, desconto)
  //               // total += ( this.calcDesconto(k.produto.PrecoVenda, desconto) * ki.quantidade )
  //             }
  //           })
  //         } else {
  //           k.selecao.map(ki => {
  //             if ( desconto ) {
  //               ki.composicao.valorVendaGradeDesconto = this.calcDesconto(ki.composicao.valorVendaGrade, desconto)
  //               // total += ( this.calcDesconto(ki.composicao.valorVendaGrade, desconto) * ki.quantidade )
  //             }
  //           })
  //         }
          
  //       } 
  //       // else {
  //       //   const valor = k.produto.valorVenda ? k.produto.valorVenda : k.produto.PrecoVenda
  //       //   if ( desconto ) {
  //       //     total += ( this.calcDesconto(valor, desconto) * k.produto.quantidade )
  //       //   }
  //       // }
  //     })
  //   }
    
  //   cart.timestamp = moment().toISOString()
  //   localStorage.setItem('carrinho',JSON.stringify(cart))

  //   return cart
  // }

  buscaProdutoPorNome ( query ) {
    const data = {
      "categoriasIds": [],
      "subCategoriasIds": [],
      "text": query
    }
    return axios.post(process.env.VUE_APP_API_URL + 'buscaprodutosloja', data, { headers: authHeader() })
  }

  calcDesconto ( valor, desconto ) {
    let newValue = valor - ( valor * ( desconto / 100 ) )
        newValue = newValue.toFixed(2)
    return newValue
  }

  getSubcategoriaLoja ( categoria ) {
    return axios.get(process.env.VUE_APP_API_URL + 'categorialoja/subcategoriasloja/' + categoria, { headers: authHeader() })
  }

  createDescontoMarketPlace ( query ) {
    return axios.post(process.env.VUE_APP_API_URL + 'descontoloja/create/descontomarketplace', query, { headers: authHeader() })
  }

  getDescontosMarketPlace () {
    return axios.get(process.env.VUE_APP_API_URL + 'descontoloja/get/descontomarketplace', { headers: authHeader() })
  }

  getDescontListById ( id ) {
    return axios.get(process.env.VUE_APP_API_URL + 'descontoloja/get/descontolistabyid/' + id, { headers: authHeader() })
  }

  deleteDesconto ( id ) {
    return axios.get(process.env.VUE_APP_API_URL + 'descontoloja/deletedescontobyid/' + id, { headers: authHeader() })
  }

  verificaDescontoExists ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'descontoloja/verificaDescontoExists/', data, { headers: authHeader() })
  }

  getAlunosUnidades () {
    const currentUser = localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null
    const unidades = []

    if ( currentUser ) {
      currentUser.alunos.map ( k => {
        unidades.push(k.matriculas[0]?.idUnidade)
      })
  
      // console.log("getAlunosUnidades:", currentUser)
      return unidades
    } else {
      return []
    }
    
  }

  getAlunosDescontosUnidade () {
    const currentUser = localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null
    const unidades = []

    if ( currentUser ) {
      currentUser.alunos.map ( k => {
        if ( k.descontosUnidade ) {
          k.descontosUnidade.map ( k => {
            unidades.push(k)
          })
        }
      })
      return unidades
    } else {
      return []
    }
  }

  clearEmptyProducts ( produtos ) {
    produtos.map( (k,i) => {
      if ( k.precoitem == 1 && ( !k.valorVenda || k.valorVenda == 0 ) ) {
        produtos.splice(i, 1)
      }
    })
    return produtos
  }

  processProductsAccess ( produtos, user, categoria ) {
    // console.log("processProductsAccess - produtos:",produtos)
    // console.log("user:", user)
    // console.log("categoria:", categoria)
    produtos = this.clearEmptyProducts(produtos)

    if ( categoria == 1 ) produtos = produtos.filter( a => !a.comprado ) // em caso de MD não mostrar produtos que já foram comprados

    const userProducts = user?.alunosProdutos

    const descontosUnidade = this.getAlunosDescontosUnidade()
    const bloqueios = user?.alunos[0]?.bloqueios
    const bloqueioCompra = !bloqueios ? false : bloqueios.filter( k => {
      return k.bloquearVenda == 1
    }).length > 0
    
    // console.log("user final data:", user)
    // console.log("bloqueios:", bloqueios)
    // console.log("bloqueioCompra:", bloqueioCompra)
    // console.log("userProducts:", userProducts)

    if ( produtos && user && categoria && userProducts ) {
      const productList = userProducts.filter( k => {
        return k.idcategorialoja == categoria
      })
      // console.log("productList:", productList)

      if ( productList.length > 0 ) {
        produtos.map ( (k, i) => {
          k.podeComprar = false
          k.bloqueioCompra = k.aceitaBloqueio ? bloqueioCompra : false
          k.idPessoaAluno = null
          k.idPessoaRespFin = null
          k.selectedAluno = k.selectedAluno ? k.selectedAluno : []
          k.selectedTurma = k.selectedTurma ? k.selectedTurma : []
          // k.comprado = 0
          k.allowDesconto = k.percentual && k.percentual > 0 && !k.UnidadeDesconto

          // console.log("k.aceitaBloqueio:", k.aceitaBloqueio)
          // console.log("k.bloqueioCompra:", k.bloqueioCompra)
          // console.log("k.bloqueia:", k.bloqueia)
          // console.log("k.ignorarBloqueioCategoria:", k.ignorarBloqueioCategoria)

          productList[0].produtos.map( kf => {
            // console.log("kf.comprado:", kf)
            // console.log(kf.idtipomaterial + ' = ' + k.idtipomaterial + '?')
            if ( kf.idtipomaterial === k.idtipomaterial) {
              produtos[i].podeComprar = k.aceitaBloqueio ? !bloqueioCompra : true
              produtos[i].bloqueioCompra = k.aceitaBloqueio ? bloqueioCompra : false
              produtos[i].idPessoaAluno = kf.idPessoa
              produtos[i].idPessoaRespFin = kf.idPessoaRespFin
              // console.log("bloqueioCompra:", bloqueioCompra)
              // console.log("podeComprar:", produtos[i].podeComprar)

              if ( kf.comprado == 0 && kf.servicoextra != 1 && kf.comprasMultiplas == 0 || !kf.comprado && kf.servicoextra != 1 && kf.comprasMultiplas == 0 ) {
                const exists = produtos[i].selectedAluno.filter ( kpf => {
                  return kpf.matriculas[0].idMatricula == kf.idMatricula
                }).length > 0

                if ( !exists && produtos[i].selectedAluno == 0) {
                  produtos[i].selectedAluno.push({
                    matriculas:[
                      {
                        idMatricula: kf.idMatricula,
                      }
                    ],
                    idAluno: kf.idAluno
                  })
                }
              }
              
              if ( descontosUnidade && descontosUnidade.length > 0 ) {
               const hasDescUni = descontosUnidade.filter( kd => {
                  if (kd.idtipomaterial == kf.idtipomaterial) {
                    produtos[i].UnidadeDesconto = kd.percentual
                    produtos[i].IdUnidadeDesconto = kd.idUnidade
                  }
                  return kd.idtipomaterial == kf.idtipomaterial
                })
                produtos[i].allowDesconto = hasDescUni.length > 0
              }
              
              if (kf.comprado == 1) {
                produtos[i].comprado = produtos[i].comprado === 0 ? 0 : 1
              } else {
                produtos[i].comprado = 0
              }
            } 
            // console.log("produtos[i].allowDesconto:", produtos[i])
          })
        })        
      } else {
        if ( produtos.length > 0  ) {
          produtos.map ( k => {
            // console.log("produtos - k:", k)
            k.podeComprar = !bloqueioCompra
            k.bloqueioCompra = k.aceitaBloqueio ? bloqueioCompra : false
            k.selectedAluno = k.selectedAluno ? k.selectedAluno : []
            k.selectedTurma = k.selectedTurma ? k.selectedTurma : []
          })

          // console.log("-----produtos:", produtos)

        }
      }
    }
    
    // console.log("produtos after analise:", produtos)
    return produtos
  }

  aplicarCupom ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'loja/verificacarrinhocupons', data, { headers: authHeader() })
  }
}

export default new LojaService()
