<template>
  <header>
    <div>
      <h2 v-html="title"></h2>
      <div>
        <slot name="side"></slot>
      </div>
    </div>
    <slot></slot>
  </header>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Título Sessão'
    }
  }
}
</script>

<style lang="scss" scoped>
  header {
    width: 100%; height: auto;
    padding: $hmg_mid;
    background-color: $color-secondary;
    border-bottom: $border-component;
    position: relative; z-index: 500;
    // position: sticky; top: 60px;
    // z-index: 400;

    h2 {
      margin: 0; padding: 0;
      // color: $color-text;
    }

    >div {
      display: flex; align-items: center;  align-content: center;
      justify-content: space-between;

      >div {
        display: flex; align-items: center;
        align-content: center;
        justify-content: flex-end;
        gap: $mg_mini
      }
    }
  }

  
</style>