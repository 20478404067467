<template>
  <controller :goToTopButton="true">
    <div class="page-title">
      <h3 class="text-capitalize">Progressão de Período</h3>
    </div>
    <div class="page-inner">
      <transition name="slideup-small">
        <filter-progressao @onFilter="setupProgression" ref="filter"></filter-progressao>
      </transition>
      <progressao-periodo :filter="filter" v-if="filter"></progressao-periodo>
    </div>
  </controller>
</template>

<script>
import ProgressaoPeriodo from '@/components/estruturaacademica/progressao/form/ProgressaoPeriodo.vue'
import FilterProgressao from '@/components/estruturaacademica/progressao/utilities/FilterProgressao.vue'

export default {
  components: {
    ProgressaoPeriodo, FilterProgressao
  },
  data () {
    return {
      filter: null
    }
  },
  methods: {
    setupProgression ( filter ) {
      this.filter = null
      console.log("setupProgression:", filter)
      
      this.$nextTick(() => {
        this.filter = filter
      })
      
    },
    filterProgress () {
      this.$refs.filter.filterProgressao()
    }
  }
}
</script>

<style>

</style>