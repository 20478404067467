<template>
  <section class="mat-main">
    <header>
      <p>Etapa {{ step.index + 1 }} de {{ totalSteps }}</p>
      <h4>Contrato</h4>
      <!-- <p>Não se esqueça clicar em 'aceito' e, se desejar, você pode baixar uma cópia do contrato.</p> -->
    </header>
    <section class="form-wrap">
      <h5 class="color-text">{{ step.index + 1 }}.1. Contrato de Prestação de Serviço</h5>
      <contrato ref="contrato" :idMatricula="idMatricula" :auto="auto" @finished="finalize" @block="block()"></contrato>
    </section>
  </section>
</template>

<script>
import Contrato from '@/components/matriculas/form/Contrato'

export default {
  components: {
    Contrato
  },
  props: {
    idMatricula: {
      type: [Number, String]
    },
    auto: {
      type: Boolean
    },    
    step: {
      type: Object
    },
    totalSteps: {
      type: Number
    }
  },
  data () {
    return {
      
    }
  },
  mounted () {
    this.$emit('ready')
  },
  methods: {
    finalize ( e ) {
      if (  this.auto ) {
        this.$emit("finished", e)
      } else {
        return this.$refs.contrato.finalize()
      }
      // 
      
    },
    block () {
      console.log("block finish..")
      this.$emit("block")
    },
    get () {
      // this.$store.dispatch('matricula/get').then(response => {
      //   this.matricula = response
      //   // console.log("get alunos - matricula response:", response)
      //   console.log("idMatricula:", this.idMatricula)
      //   if ( this.idMatricula ) {
      //     MatriculaService.getContrato( this.idMatricula ).then(
      //       response => {
      //         console.log("getAuno:", response.data)
              
      //         this.$store.dispatch('matricula/save', this.steps).then(() => {
      //           // console.log(response)
      //           this.$emit('ready')
      //           this.loaded = true
      //         })
              
      //       },
      //       error => {
      //         console.error(error.response.data.message)
      //       }
      //     )
      //   }      
      // })      
    }
  }
}
</script>

<style lang="scss" scoped>

</style>