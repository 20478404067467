<template>
  <section >
    <form-spinner v-if="processing"/>
    <header>
      <h5>Solicitações em andamento</h5>
      <nav>
        <a href="#" class="btn btn-alert" @click.prevent="setActive('atendimento')">
          Atendimento
        </a>
        <a href="#" class="btn btn-success-2" @click.prevent="setActive('financeiro')">
          Financeiro
        </a>
        <a href="#" class="btn btn-primary" @click.prevent="setActive('devolucao')">
          Devolução
        </a>
      </nav>
    </header>
    <main ref="panel">
      <ul v-if="pendentes && andamento" :class="active">
        <li class="pendentes">
          <header>
            <div>
              <h5>Pendentes</h5>
              <span>{{ pendentes.length }}</span>
            </div>
            <!-- <a 
              href="#" 
              :class="['btn', 'btn-small', 'btn-' + active]" 
              @click.prevent="seeAll('pendentes')"
            >
              Ver todas
            </a> -->
          </header>
          <ol v-if="pendentes.length > 0 ">
            <li v-for="item in pendentes" :key="item">
              <card-solicitacao :props="item" :active="active" status="pendente" @onEngage="engage"></card-solicitacao>
            </li>
          </ol>
          <empty-message v-else></empty-message>
        </li>
        <li class="andamento">
          <header>
            <div>
              <h5>Em andamento</h5>
              <span>{{ andamento.length }}</span>
            </div>
            <!-- <a 
              href="#" 
              :class="['btn', 'btn-small', 'btn-' + active + '-alert']" 
              @click.prevent="seeAll('pendentes')"
            >
              Ver todas
            </a> -->
          </header>
          <ol v-if="andamento.length > 0">
            <li v-for="item in andamento" :key="item">
              <card-solicitacao :props="item" :active="active" status="andamento" @onEngage="engage" :section="section"></card-solicitacao>
            </li>
          </ol>
          <empty-message v-else></empty-message>
        </li>
      </ul>
      <ul v-if="devolucao" :class="active">
        <li class="aguardando">
          <header>
            <div>
              <h5>Aguardando Entrega</h5>
              <span>{{ devolucao.length }}</span>
            </div>
            <div class="filterWrap">
              <input-control v-model="searchQuery" @keyup="search" placeholder="Buscar..."></input-control>
            </div>
            <!-- <a 
              href="#" 
              :class="['btn', 'btn-small', 'btn-' + active]" 
              @click.prevent="seeAll('pendentes')"
            >
              Ver todas
            </a> -->
          </header>
          <ol :style="{ height: mh }" v-if="devolucao.length > 0">
            <li v-for="(item, i) in devolucao" :key="item" :ref="'devolucao_' + i">
              <card-solicitacao :props="item" :active="active" status="aguardando" @onEngage="engage" @onSave="setActive(active)" :section="section"></card-solicitacao>
            </li>
          </ol>
          <!-- <empty-message v-else></empty-message> -->
        </li>
      </ul>
      <ul v-if="filtro" :class="active">
        <li class="filter">
          <header>
            <div>
              <h5>Filtro </h5>
              <span>{{ filtro.length }}</span>
            </div>
            <!-- <a 
              href="#" 
              :class="['btn', 'btn-small', 'btn-' + active]" 
              @click.prevent="seeAll('pendentes')"
            >
              Ver todas
            </a> -->
          </header>
          <ol :style="{ height: mh }" v-if="filtro.length > 0">
            <li v-for="(item, i) in filtro" :key="item" :ref="'filtro_' + i">
              <card-solicitacao :props="item" :active="active" status="filtro" @onEngage="engage" :section="section"></card-solicitacao>
            </li>
          </ol>
          <empty-message v-else></empty-message>
        </li>
      </ul>
    </main>
  </section>
</template>

<script>
import CardSolicitacao from '@/components/marketplace/cancelamento/utilities/CardSolicitacao.vue'
import MarketplaceService from '@/services/marketplace.service.js'
import EmptyMessage from '@/components/marketplace/cancelamento/utilities/EmptyMessage.vue'
import FormSpinner from '@/components/utilities/FormSpinner.vue'

export default {
  props: {
    section: {
      type: [String, Number]
    },
    filter: {
      type: Object
    }
  },  
  components: {
    CardSolicitacao, EmptyMessage, FormSpinner
  },
  data () {
    return {
      model: {
        nomePessoa: 0,
        unidade: 0,
        periodoLetivo: 0,
        idCancelamento: 0,
        dataInicio: 0,
        dataFim: 0,
        pendente: 0,
        aprovado: 0,
        reprovado: 0,
        aguardandoEntrega: 0,
        finalizado: 0
      },
      pendentes: null,
      andamento: null,
      devolucao: null,
      filtro: null,
      wait: {
        pendente: false,
        aprovado: false,
        finalizado: false
      },
      active: 'atendimento',
      mh: "2000px",
      searchQuery: null,
      dataBkp: null,
      processing: false
    }
  },
  watch: {
    filter ( value ) {
      this.runFilter(value)
    },
    section ( value ) {
      if ( value ) {
        this.active = value
      } 
    }
  },
  mounted () {
    this.setActive(this.section)
  },
  methods: {
    setActive ( active ) { 
      this.clearData()

      this.processing = true

      if ( this.$refs.panel ) {
        setTimeout(() => {
          console.log("found panel:", this.$refs.panel)
          this.$refs.panel.scrollIntoView({ behavior: "smooth", block: 'start' })
        },1000)
      }

      if ( active == 'atendimento' ) {
        this.getSolicitacoesAtendimento()
        // this.getEmAndamento(true)
      } else if ( active == 'financeiro' ) {
        this.getSolicitacoesCR() 
      } else if ( active == 'devolucao' ) {
        const data = Object.assign({}, this.model)
        data.aguardandoEntrega = 1
        // console.log("getEmAndamento:", data)
        this.getSolicitacoes(data, 'devolucao')
      } else {
        //
      }

      this.$router.push({name: 'Cancelamento e Estorno', params: { section: active }})
      this.active = active
    },
    getPendentes ( wait ) {
      const data = Object.assign({}, this.model)
      data.pendente = 1
      // console.log("getPendentes:", data)
      this.getSolicitacoes(data, 'pendentes', wait)
    },
    getEmAndamento ( wait ) {
      const data = Object.assign({}, this.model)
      data.aprovado = 1
      // console.log("getEmAndamento:", data)
      this.getSolicitacoes(data, 'andamento', wait)

    },
    getSolicitacoesAtendimento () {
      MarketplaceService.getSolicitacoesAtendimento().then(
        response => {
          console.log("getSolicitacoesAtendimento:", response)
          this.pendentes = response.data.pendentes
          this.andamento = response.data.andamento
          this.dataBkp = { ...this.pendentes, ...this.andamento}
          console.log("dataBkp:", this.dataBkp)
          this.processing = false
        },
        () => {
          this.processing = false
        }
      )
    },
    getSolicitacoesCR () {
      MarketplaceService.getSolicitacoesCR().then(
        response => {
          console.log("getSolicitacoesCR:", response)
          this.pendentes = response.data.pendentes
          this.andamento = response.data.andamento

          this.processing = false
        },
        () => {
          this.processing = false
        }
      )
    },
    clearData () {
      this.pendentes = null
      this.andamento = null
      this.devolucao = null
      this.filtro = null
    },
    runFilter ( filter ) {
      this.clearData()
      this.processing = true

      filter.aguardandoEntrega = filter.aguardandoEntrega ? 1 : 0
      filter.aprovado = filter.aprovado ? 1 : 0
      filter.finalizado = filter.finalizado ? 1 : 0
      filter.pendente = filter.pendente ? 1 : 0
      filter.reprovado = filter.reprovado ? 1 : 0

      this.getSolicitacoes(filter, 'filtro')
    },
    getSolicitacoes ( data, target, wait ) {
      MarketplaceService.getCancelamentos( data ).then(
        response => {
          this[target] = response.data
          this.dataBkp = response.data
          console.log("this.target", this[target])
          
          setTimeout(() => {
            this.calcMasonryHeight(this.section)
          },1000)
          
          if ( wait ) {
            this.finishLoad()
          } else {
            setTimeout(() => {
              this.processing = false
            }, 500)
          }
        },
        () => {
          this.processing = false
        }
      )
    },
    finishLoad () {
      const finished = !Object.values(this.wait).some( a => !a)

      if ( finished ) {
        // window.spinner.emit("close")
        this.processing = false
      }
      // console.log("finished:", finished)

    },
    engage ( data ) {
      console.log("engage - target:", data)
      this.active = data.id
      this.$router.push({name: 'Cancelamento e Estorno', params: { section: data.id }})
    },
    calcMasonryHeight () {
      this.$nextTick(() => {
        const hs = []
        Object.values(this.$refs).map( k => {
          const h = k.length > 0 ? k[0].clientHeight : 0
          hs.push( h )
        })

        const rowCount = hs.filter( a => a > 0 )
        // console.log("rowCount:", rowCount)
        const bigger = Math.max(...hs)
        // console.log("bigger:", bigger)
        const rows = Math.ceil(rowCount.length / 3)
        // console.log("rows:", rows)
        const gaps = ( rows - 2 ) * 20
        // console.log("gaps:", gaps)
        const height = bigger * rows + gaps
        // console.log("height:", height)
        this.mh = height + "px"
      })
    },
    search () {
      const query = this.searchQuery
      const active = this.active
      
      if ( query && query.length > 1 ) {
        if ( this.searchWait ) clearTimeout(this.searchWait)
        this.searchWait = setTimeout(() => {
          
          const currentData = this[active]
          const result = currentData.filter( k => {
            const id = k.idSolicitacaoCancelamento
            const resp = k.nome.toLowerCase()
            return id == query || resp.includes(query)
          })

          if ( result.length > 0) this[active] = result
        }, 300)
      } else {
        this[active] = Object.assign([],this.dataBkp)
        // console.log("active:", this[active])
        // console.log("bkp:", this.dataBkp)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  section {
    padding: 0;

    > header {
      padding: $hmg;
      display: flex; align-items: center;
      gap: $hmg;
      
      h5 {
        margin: 0; padding: 0;
      }

      nav {
        display: flex; align-items: center;
        gap: $hmg_small;
      }
    }

    > main {
      ul {
        padding: 0; margin: $hmg 0 0 0;
        display: flex; align-items: stretch;

        > li {
          flex: 1;
          // background-color: $color-secondary;
          // border-radius: $border-radius;
          // border-top-width: 6px; border-top-style: solid;
          margin: 0 $hmg_small;
          list-style: none;
          min-height: 400px;
          @extend %transition_4e;

          > header {
            // display: flex; align-content: center; align-items: center;
            // justify-content: space-between;
            padding: 0 $mg_mini;
            margin-bottom: $hmg_small;
            gap: $mg_mini;

            > div  {
              display: flex; align-content: center;
              
              h5 {
                color: $color-text;
                margin: 0; padding: 0;
              }

              span {
                display: flex; flex-direction: column;
                justify-content: center; align-items: center;
                width: 20px; height: 20px;
                border-radius: 100%;
                margin-left: $mg_mini;
                font-size: 10px;
                font-weight: bold;
                text-align: center;
                line-height: 1;
                background-color: $color-primary;
                color: $color-secondary;
              }

              &.filterWrap {
                max-width: 320px; margin-top: $hmg_mid;

                p {
                  padding: 0; margin: 0;
                }
              }
            }
          }

          ol {
            display: flex; flex-flow: column wrap;
            gap: $hmg;
            padding: 0; margin: 0;

            li {
              padding: 0; margin: 0;
              list-style: none;
            }
          }

          &.filter,
          &.aguardando {
            ol {
              display: flex;
              flex-direction: column;
              height: 2000px;
              flex-wrap: wrap;
              gap: $hmg_mid $mg_mini;

              &::before,
              &::after {
                content: "";
                flex-basis: 100%;
                width: 0;
                order: 2;
              }

              li {
                width: 32.5%;
                @extend %transition_4e;

                &:nth-child(3n + 1) {
                  order: 1;
                }

                &:nth-child(3n + 2) {
                  order: 2;
                }

                &:nth-child(3n) {
                  order: 3;
                }
              }
            }
          }
        }
        &.atendimento {
          > li {
            &.pendentes {
              header {
                > div  {
                  span {
                    background-color: $color-atendimento;
                  }
                }
              }
            }
            &.andamento {
              header {
                > div  {
                  span {
                    background-color: $color-atendimento-alert;
                  }
                }
              }
            }
          }
        }

        &.financeiro {
          > li {
            &.pendentes {
              header {
                > div  {
                  span {
                    background-color: $color-financeiro;
                  }
                }
              }
            }
            &.andamento {
              header {
                > div  {
                  span {
                    background-color: $color-financeiro-alert;
                  }
                }
              }
            }
          }
        }

        &.devolucao {
          header {
            > div  {
              span {
                background-color: $color-secretaria;
              }
            }
          }
        }
      }
    }
  }
  
</style>