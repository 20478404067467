<template>
  <nav>
    <a 
      v-for="tab in tabs" 
      :key="tab" 
      href="#" 
      :class="{ active: tab.slug == activeTab }"
      @click.prevent="togglechange(tab.slug)"
    >
      {{ tab.label}} &nbsp;
      <fa :icon="['fas', tab.icon]"></fa>
    </a>
  </nav>
</template>

<script>
export default {
  props: {
    active: {
      type: String
    }
  },
  data () {
    return {
      tabs: [
        {
          label: 'Parâmetros',
          slug: 'parametros',
          icon: 'table-list'
        },
        {
          label: 'Vínculos',
          slug: 'vinculos',
          icon: 'list-tree'
        }
      ],
      activeTab: null
    }
  },
  watch: {
    active ( value ) {
      if ( value ) {
        this.activeTab = value
      }
    }
  },
  mounted () {
    if ( this.active ) {
      this.activeTab = this.active
    }
  },
  methods: {
    togglechange ( target ) {
      this.activeTab = target
      this.$emit('change', target)
    }
  }
}
</script>

<style lang="scss" scoped>
  nav {
    display: flex; align-items: center; align-content: flex-start;
    justify-content: flex-start;
    width: 100%;
    // border-bottom: 1px solid $color-primary;

    a {
      padding: $hmg_mini $hmg_small;
      // border-top-left-radius: $border-radius; border-top-right-radius: $border-radius;
      border-radius: $border-radius-small;
      background-color: $color-secondary;
      margin-right: $hmg_small;
      transform-origin: bottom center;
      @extend %transition_3e;

      &:hover {
        color: $color-primary;
      }

      &.active {
        border-radius: $border-radius;
        padding: $mg_mini $hmg_small;
        background-color: $color-primary;
        color: $color-secondary
      }
    }
  }
</style>