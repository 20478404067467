<template>
  <section class="pesquisa">
    <header v-if="atividades.length > 0">
      <h4>Sua busca retornou {{ total }} {{ total == 1 ? 'resultado' : 'resultados' }}</h4>
      <p>Exibindo {{ from }} de {{ to }}</p>
      <!-- <div class="pesquisa-dash" v-if="totais">
        <ul>
          <li>
            <label>Pendentes</label>
            <h2>{{ totais.pendentes }}</h2>
          </li>
          <li>
            <label>Em Andamento</label>
            <h2>{{ totais.andamentos }}</h2>
          </li>
          <li>
            <label>Finalizados</label>
            <h2>{{ totais.finalizados }}</h2>
          </li>
          <li>
            <label>Atrasados</label>
            <h2>{{ totais.atrasados }}</h2>
          </li>
          <li>
            <label>Expirados</label>
            <h2>{{ totais.expirados }}</h2>
          </li>
        </ul>
      </div> -->
    </header>
    <div>
      <table class="transparent-table" v-if="atividades.length > 0">
        <thead>
          <tr>
            <th>ID</th>
            <th>Tipo</th>
            <th>Categoria</th>
            <th>Observação</th>
            <th>Operador</th>
            <th>Atendido</th>
            <th>Data Ocorrência</th>
            <th>Data Expiração</th>
            <th>Status</th>
            <th>Finalizado em</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="atividade in atividades" :key="atividade">
            <td>{{ atividade.idAtividade }}</td>
            <td>{{ atividade.tipoLabel }}</td>
            <td>{{ atividade.nomeCategoria }}</td>
            <td :title="atividade.observacoes">{{ parseObs(atividade.observacoes) }}</td>
            <td>{{ atividade.nome ? atividade.nome : ( atividade.funcao ? atividade.funcao : 'Não Atribuído' ) }}</td>
            <td><b>{{ perfil[atividade.idPerfilPessoa].label }}:</b> {{ atividade[perfil[atividade.idPerfilPessoa].nameTag] }}</td>
            <td>{{ formatDate(atividade.dataOcorrencia) }}</td>
            <td>{{ formatDate(atividade.dataExpiracao) }}</td>
            <td>{{ dropStatus(atividade) }}</td>
            <td>{{ atividade.dataFimAtividade ? formatDate(atividade.dataFimAtividade) : '...' }}</td>
            <td class="actions">
              <router-link :to="{ name: 'Painel Atividades', params: { section: atividade.tiposlug, action: 'ver', id: atividade.idAtividade }}">
                <fa :icon="['far', 'magnifying-glass-plus']" />
              </router-link>
              <router-link :to="{ name: 'Painel Atividades', params: { section: atividade.tiposlug, action: 'ver', id: atividade.idAtividade }}" target="_blank" title="Abrir em nova aba">
                <fa :icon="['far', 'rectangle-history-circle-plus']" />
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="paging">
        <a href="#" @click.prevent="paginate('prev')" :class="{ disabled: currentPage == 1 }">
          <fa :icon="['fas', 'chevron-left']" />
        </a>
        <div>
          <input-control type="number" cssClass="text-center" v-model="currentPage" @change="paginate"/>
        </div>
        <a href="#" @click.prevent="paginate('next')" :class="{ disabled: currentPage == lastPage }">
          <fa :icon="['fas', 'chevron-right']" />
        </a>
      </div>
    </div>
  </section>

</template>

<script>
import { formatDate } from '@/services/utils.service.js'

export default {
  props: {
    scope: {
      type: Object
    }
  },
  watch: {
    scope ( value ) {
      if ( value ) {
        console.log("update page:", value)
        this.atividades = Object.assign([], value.data)
        this.set()
      }
    }
  },
  data () {
    return {
      atividades: [],
      perfil: {
        1: {
          label: 'Aluno',
          nameTag: 'alunoNome'
        },
        2: {
          label: 'Colaborador',
          nameTag: 'nomeColaborador'
        },
        3: {
          label: 'Responsável',
          nameTag: 'responsavelNome'
        }
      },
      total: 0,
      to: 0,
      from: 0,
      totais: null,
      currentPage: 0,
      lastPage: 0
    }
  },
  mounted () {
    if ( this.scope ) {
      this.atividades = Object.assign([], this.scope.data)
      this.set()

      console.log('atividades:', this.atividades)
      this.$nextTick(() => {
        // this.createDash()
      })
    }
  },
  methods: {
    set () {
      this.currentPage = this.scope.current_page
      this.lastPage = this.scope.last_page
      this.total = this.scope.total
      this.to = this.scope.to
      this.from = this.scope.from
    },
    dropStatus ( atividade ) {
      var status = atividade.status == 0 ? 'Pendente' : 'Finalizado'

      if ( atividade.statusSLA == 'SLA Vencido' && atividade.status == 0 ) { 
        status = 'Vencido'
      } 
      if ( atividade.statusExpiracao == 'SLA Expirado' && atividade.status == 0 ) { 
        status = 'Expirado'
      }

      return status
    },
    formatDate ( date ) {
      return formatDate(date)
    },
    createDash () {
      var totalPendente = 0
      var totalAndamento = 0
      var totalFinalizado = 0
      var totalVencido = 0
      var totalExpirado = 0

      this.atividades.map( k => {
        if ( k.status == 1 ) {
          totalFinalizado += 1
        }
        if ( k.status == 0 ) {
          if ( k.dataInicioAndamento ) {
            totalAndamento += 1
          } else {
            totalPendente += 1
          }
        }
        if ( k.statusSLA == 'SLA Vencido' && k.status == 0) { 
          totalVencido += 1
        } 
        if ( k.statusExpiracao == 'SLA Expirado' && k.status == 0 ) { 
          totalExpirado += 1
        }
      })

      this.totais = {
        pendentes: totalPendente,
        andamentos: totalAndamento,
        finalizados: totalFinalizado,
        atrasados: totalVencido,
        expirados: totalExpirado
      }
    },
    goTo( atividade ) {
      this.$router.push({ name: 'Painel Atividades', params: { section: 'atendimentos', action: 'ver', id: atividade.idAtividade }})
    },
    paginate ( direction ) {
      console.log("scope", this.scope)
      var next
      if ( direction ) {
        next = direction == 'prev' ? this.currentPage -= 1 : this.currentPage += 1
      } else {
        next = this.currentPage
      }
      
      this.$emit("paginate", next)
    },
    parseObs ( text ) {
      const max = 80
      if ( text && text.length > max ) {
        return text.substring(0, max) + '...'
      } else {
        return text
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .pesquisa {
    padding: $hmg_mid;

    header {
      h4 {
        margin: 0; padding: 0
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: $hmg_small;
      
      a {
        font-size: 16px;
        padding: $hmg_mini 0;

        &:hover {
          color: $color-primary
        }
      }
    }

    .pesquisa-dash {
      margin-top: $hmg;

      ul {
        padding: 0; margin: 0 0 $hmg 0;
        display: flex; align-items: center;
        gap: $mg_mini;

        li {
          width: 120px; height: 80px;
          list-style: none;
          padding: 0; margin: 0;
          display: flex; flex-direction: column;
          justify-content: space-between;
          border: $border-component;
          background-color: $color-secondary;
          padding: $mg_mini;
          border-radius: $border-radius;

          h2{
            margin: 0; padding: 0;
          }
          label {
            padding: 0; margin: 0;
            font-size: $font-size-small;
          }
        }
      }
    }
  }

  .paging {
    display: flex; width: auto;
    align-items: center; align-content: center;
    margin: $hmg_mid auto; justify-content: center;
    gap: $hmg_mini;

    >div {
      width: 50px;
    }

    a {
      font-size: 16px;
      padding: $hmg_mini;

      &:hover {
        color: $color-primary;
      }

      &.disabled {
        opacity: .5;
        pointer-events: none;
      }
    }
  }
</style>