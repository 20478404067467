<template>
  <nav>
    <template v-if="steps">
      <a href="#" v-for="step in steps" :key="step" @click.prevent="( ( !step.locked && navigate ) || step.done ) && go(step)" :class="{ active: step.active, locked: step.locked, /*freeze: !navigate && !step.done */ }">
        <div>
          <div class="icon-wrap">
            <fa :icon="step.icon" class="icon"></fa>
          </div>
          <p v-html="step.label"></p>
        </div>
        <template v-if="trackCheck">
          <div :class="['step-check', { 's-done': step.done, 's-active': step.active, 's-alert': step.alert }]" v-if="step.done || step.active || step.alert">
            <fa :icon="['fas', 'check']" class="icon"></fa>
          </div>
        </template>
        <span></span>
      </a>
      <span class="spacer"></span>
    </template>
  </nav>
</template>

<script>
export default {
  props: {
    steps: {
      type: Object,
      default: null
    },
    navigate: {
      type: Boolean,
      default: false
    },
    trackCheck: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    steps ( value ) {
      console.log("steps change:", value)
    }
  },
  data () {
    return {

    }
  },
  methods: {
    go ( item ) {
      this.$emit('go', item)
    }
  }
}
</script>

<style lang="scss" scoped>
  nav {
    display: flex; flex-direction: column;
    align-content: flex-start; align-items: flex-start;

    @media screen and ( max-width: 640px) {
      flex-direction: row; padding-right: $hmg;

      .spacer {
        display: block; width: $hmg; min-width: $hmg; height: $mg;
      }
    }

    a {
      display: block; width: 100%;
      position: relative;
      margin-bottom: $hmg_small;

      >div:not(.step-check) {
        display: flex; align-items: center; align-content: center;
        position: absolute; width: 100%; z-index: 10;
        left: 0; top: 50%; transform: translateY(-50%);

        .icon-wrap {
          width: 50px; text-align: center;
          
          .icon {
            font-size: 18px;
          }
        }

        p {
          font-size: 13px; margin: 0 0 0 $mg_mini; padding: 0;
          font-weight: bold; line-height: 1.1;
          display: block; max-width: 60%;
        }
      }
      
      .step-check {
        position: absolute; left: 35px; top: 0; 
        z-index: 11;
        transform: translateY(0%) scale(.6,.6);
        width: 24px; height: 24px;
        min-width: 24px; min-height: 24px;
        max-width: 24px; max-height: 24px;
        display: flex; flex-direction: column; 
        align-content: center; align-items: center; justify-content: center;
        text-align: center; border-radius: 100%;
        background-color: $color-bg;
        @extend %transition_4e;

        .icon {
          color: $color-secondary;
          font-size: 16px;
        }

        &.s-alert {
          background-color: $color-alert;
        }
        &.s-done {
          background-color: $color-success-2;
        }
      }

      >span {
        display: block;
        width: 50px; height: 50px;
        border-radius: $mg;
        background-color: $color-secondary;
        position: relative; z-index: 9;

        @extend %transition_4e;
      }

      &.active {
        >div:not(.step-check) {
          p {
            display: block;
          }
        }

        >span {
          width: 100%; 
        }

        .step-check {
          right: $hmg_small; top: 50%; left: auto;
          transform: translateY(-50%) scale(1,1);
        }

        
      }

      &.locked {
        opacity: .7; pointer-events: none;
      }
      &.freeze {
        pointer-events: none;
      }
      &:hover {
        >span {
          transform: scale(1.05);
        }
      }

      @media screen and ( max-width: 640px) {
        margin-left: $hmg_small;

        &.active {
          width: 200px; min-width: 200px;
        }
        >div:not(.step-check) {
          p {
            display: none;
          }
        }
      }
    }
  }
</style>