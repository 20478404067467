<template>
  <section>
    <section class="start" v-if="!started">
      <h4 class="color-text">Escolha abaixo como você quer {{ state == 'add' ? 'adicionar' : 'remover' }} as propostas</h4>
      <ul>
        <li @click="setFilterType('periodoLetivo')">
          <fa :icon="['fal', 'calendar-star']" class="icon"></fa>
          <h5 class="color-text">Período Letivo</h5>
        </li>
        <li @click="setFilterType('periodoLetivoUnidade')">
          <fa :icon="['fal', 'school-flag']" class="icon"></fa>
          <h5 class="color-text">Período Letivo e Unidade</h5>
        </li>
        <li @click="setFilterType('serie')">
          <fa :icon="['fal', 'chalkboard-user']" class="icon"></fa>
          <h5 class="color-text">Série</h5>
        </li>
        <li @click="setFilterType('turma')">
          <fa :icon="['fal', 'screen-users']" class="icon"></fa>
          <h5 class="color-text">Turma</h5>
        </li>
      </ul>
    </section>
    <transition name="slideup-small">
      <section v-if="started">
        <header>
          <h5>
            Filtrar
          </h5>
          <a href="#" class="btn btn-secondary" @click.prevent="preset()">
            <fa :icon="['far','arrow-left-long']"></fa> &nbsp;
            <span>Voltar</span>
          </a>
        </header>
        <div class="frame fixed">
          <div class="row no-padd" ref="produtoFilterForm">
            <div class="col-md-6 form-group" v-if="filterType != 'periodoLetivo' ">
              <label>Unidade</label>
              <select-control v-model="filter.unidade" name="unidade" :options="options.unidades" placeholder="Selecione" @change="getPeriodosLetivos()" :disabled="loading"/>
            </div>
            <div class="col-md-4 form-group" v-if="filterType == 'serie' || filterType == 'turma' ">
              <label>Tipo de Curso</label>
              <select-control v-model="filter.tipoCurso" name="tipoCurso" :options="options.tipoCurso" placeholder="Selecione" :disabled="loading" @change="clearFilter('tipoCurso')"/>
            </div>
            <div class="col-md-4 form-group" v-if="filterType == 'periodoLetivo'">
              <label>Período Letivo</label>
              <select-control 
                v-model="filter.periodoLetivo" 
                :options="options.todosPeriodosLetivos" 
                @change="addPerCodigo('todosPeriodosLetivos')"
                placeholder="Selecione" 
                :disabled="loading"
              />
              <input type="hidden" v-model="filter.perCodigo">
            </div>
            <div class="col-md-4 form-group" v-if="filterType != 'periodoLetivo' ">
              <label>Período Letivo</label>
              <select-control 
                v-model="filter.periodoLetivo" 
                :options="options.periodosLetivos" 
                placeholder="Selecione" 
                @change="
                  getSeries(); 
                  clearFilter('periodoLetivo');
                  addPerCodigo('periodosLetivos')
                " 
                :disabled="loading"
              />
              <input type="hidden" v-model="filter.perCodigo">
            </div>
            <div class="col-md-4 form-group" v-if="filterType == 'serie' || filterType == 'turma' ">
              <label>Curso</label>
              <select-control v-model="filter.cursos" :options="options.cursos" placeholder="Selecione" :disabled="loading" @change="clearFilter('curso'); selectCurso(); "/>
            </div>
            <div class="col-md-4 form-group" v-if="filterType == 'turma' ">
              <label>Série</label>
              <select-control v-model="filter.series" :options="options.series" placeholder="Selecione" @change="getTurmas()" :disabled="loading"/>
            </div>
            <div class="col-md-6 form-group" v-if="filterType == 'serie'">
              <label>Séries</label>
              <div>
                <select-multiple :options="options.series" v-model="filter.series" model="idSerie" @change="trackSerieChange" :disabled="loading" />
              </div>
            </div>
            <div class="col-md-6 form-group" v-if="filterType == 'turma' ">
              <label>Turmas</label>
              <div>
                <select-multiple :options="options.turmas" v-model="filter.turmas" model="idTurma" :disabled="loading" />
              </div>
            </div>
          </div>
          <div class="col-md-12 form-actions form-group">
            <a href="#" @click.prevent="runFilter()" class="btn btn-primary">
              Buscar
            </a>
            <a href="#" @click.prevent="clearFilter()" class="btn btn-secondary">
              Limpar
            </a>
          </div>
        </div>
      </section>
    </transition>
    
  </section>
</template>

<script>
import UserService from '@/services/user.service.js'
import AcademicoService from '@/services/academico.service.js'
import { createSelectOptions } from '@/services/utils.service.js'

export default {
  props: {
    state: {
      type: String
    }
  },  
  data () {
    return {
      started: false,
      filterType: null,
      filter: {
        unidade: null,
        periodoLetivo: null,
        perCodigo: null,
        tipoCurso: null,
        cursos: null,
        series: null,
        turmas: null
      },
      wait: {
        unidades: false,
        tipoCurso: false,
        cursos: false
      },
      options: {
        unidades: [],
        tipoCurso: [],
        periodosLetivos: [],
        series: [],
        turmas: [],
        cursos: [],
        todosPeriodosLetivos: []
      },
      series: [],
      loading: false
    }
  },
  mounted () {
    this.getUnidades()
    this.getTipoCurso()
    this.getCursos()
    this.getTodosPeriodosLetivos()

    window.spinner.emit("open")
  },
  methods: {
    trackSerieChange () {
      console.log("trackSerieChange:", this.filter.series)
    },  
    preset () {
      this.started = false
      this.filterType = null
      this.clearFilter()
    },
    setFilterType ( type ) {
      this.started = true
      this.filterType = type
      this.$emit("onFilterType", type)
    },
    getUnidades () {
      UserService.getMinhasUnidades().then(
        response => {
          console.log("getMinhasUnidades:", response.data)
          this.options.series = []
          this.options.periodosLetivos = []
          
          if ( response.data.length > 0 ) {
            this.options.unidades = createSelectOptions(response.data, 'idUnidade' ,'descricao')
            this.wait.unidades = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhuma unidade encontrada. Verifique sua conexão e tente novamente.", floater: true})
          }
          
          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma unidade encontrada. Verifique sua conexão e tente novamente.", floater: true})
        }
      )
    },
    getTipoCurso () {
      AcademicoService.getTipoCurso().then(
        response => {
          console.log("getTipoCurso:", response.data)
          if ( response.data.length > 0 ) {
            this.options.tipoCurso = createSelectOptions(response.data, 'idTipoCurso' ,'descricao')
            this.wait.tipoCurso = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum tipo de curso encontrado. Verifique sua conexão e tente novamente.", floater: true})
          }
          
          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum tipo de curso encontrado. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    getCursos () {
      AcademicoService.getCursos().then(
        response => {
          console.log("getCursos:", response.data)
          if ( response.data.length > 0 ) {
            this.cursos = response.data
            this.options.cursos = createSelectOptions(response.data, 'idCurso' ,'descricao')
            this.wait.cursos = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum curso encontrado. Isto parece ser um problema de parametrização.", floater: true})
          }

         
          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum curso encontrado. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    getTodosPeriodosLetivos () {
      this.loading = true
      AcademicoService.getTodosPeriodosLetivos().then(
        response => {
          console.log("getTodosPeriodosLetivos:", response.data)
          if ( response.data.length > 0 ) {
            this.options.todosPeriodosLetivos = createSelectOptions(response.data, 'idPeriodoLetivo' ,'descricao').reverse()
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum período letivo encontrado. Isto parece ser um problema de parametrização.", floater: true})
          }
          this.loading = false
        },
        () => {
          this.loading = false
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum período letivo encontrado. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    getPeriodosLetivos () {
      this.loading = true
      AcademicoService.getPeriodosLetivos(this.filter.unidade).then(
        response => {
          console.log("getPeriodosLetivos:", response.data)
          
          if ( response.data.length > 0 ) {
            this.options.periodosLetivos = createSelectOptions(response.data, 'idPeriodoLetivo' ,'descricao').reverse()
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum período letivo encontrado para esta unidade. Isto parece ser um problema de parametrização.", floater: true})
          }
          this.loading = false
        },
        error => {
          console.error(error)
          this.loading = false
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum período letivo encontrado para esta unidade. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    getSeries () {
      if ( this.filter.tipoCurso ) {
        this.loading = true
        AcademicoService.getSeries(this.filter.periodoLetivo, this.filter.tipoCurso).then(
          response => {
            console.log("getSeries:", response.data)
            this.options.series = []

            if ( response.data.length > 0 ) {
              this.series = response.data
              response.data.map( k => {
                k.label = k.descricao + ' ' + k.descricaoTurno
              })
              this.options.series = createSelectOptions(response.data, 'idSerie' ,'label')
              
            } else {
              window.toaster.emit('open', {style: "error", message: "Nenhuma série encontrada para este período letivo. Isto parece ser um problema de parametrização.", floater: true})
            }

            this.loading = false
          },
          error => {
            console.error(error)
            this.loading = false
            window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma série encontrada para este período letivo. Isto parece ser um problema de parametrização.", floater: true})
          }
        )
      }
    },
    getTurmas () {
      this.loading = true

      const options = {
        idUnidade: this.filter.unidade,
        idTipoCurso: this.filter.tipoCurso,
        idPeriodoLetivo: this.filter.periodoLetivo,
        idCurso: this.filter.cursos,
        idSerie: this.filter.series
      }
      
      // window.spinner.emit("open")

      AcademicoService.getTurmasProximoCiclo( options ).then(
        response => {
          console.log("getTurmas:", response.data)
          if ( response.data.length > 0 ) {
            // this.$emit('onFilter', response.data)
            this.options.turmas = createSelectOptions(response.data, 'idTurma' ,'descricao')
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhuma turma encontrada para esta série. Isto parece ser um problema de parametrização.", floater: true})
          }
          this.loading = false
          window.spinner.emit("close")
        },
        error => {
          console.error(error)
          this.loading = false
          window.spinner.emit("close")
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma turma encontrada para esta série. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    selectCurso () {
      const selected = this.filter.cursos
      this.loading = true
      
      const result = this.series.filter( k => {
        return k.idCurso == selected
      })

      if ( result.length > 0 ) {
        this.options.series = createSelectOptions(result, 'idSerie' ,'label')
      } else {
        this.options.series = []
      }
      this.loading = false
    },
    addPerCodigo ( src ) {
      const perCodigo = this.options[src].filter( k => k.value == this.filter.periodoLetivo )[0].label
      this.filter.perCodigo = perCodigo
    },
    finishLoad () {
      const finished = !Object.values(this.wait).some( a => !a)

      if ( finished ) {
        window.spinner.emit("close")
        this.loading = false
      }
      // console.log("finished:", finished)

    },
    runFilter () {
      const filter = {
        type: this.filterType,
        data: this.filter
      }
      console.log("filter;",filter)
      this.$emit('onFilter', filter)
    },
    clearFilter ( part, resetState ) {
      this.$emit('onClearFilter', resetState)

      if ( !part ) {
        this.filter = {
          unidade: null,
          periodoLetivo: null,
          tipoCurso: null,
          cursos: null,
          series: null
        }
        this.series = null
        this.options.series = []
        this.options.turmas = []
        this.$emit('clear')
      } else if ( part === 'tipoCurso' ) {
        this.filter.cursos = null
        this.filter.periodoLetivo = null
        this.filter.series = null
        this.options.series = this.series
      } else if ( part === 'periodoLetivo' ) {
        this.filter.cursos = null
        this.filter.series = null
        this.options.series = this.series
      } else if ( part === 'curso' ) {
        this.filter.series = null
        this.options.series = this.series
      }
      
    }
  }
}
</script>

<style scoped lang="scss">
  header {
    display: flex;
    align-items: center; align-content: center;
    margin: $hmg $hmg_small;
    padding: 0 $hmg_mini;

    h5 {
      margin: 0 0 $hmg_mini 0; padding: 0;
    }
    p {
      margin: 0; padding: 0;
    }

    a { 
      margin-left: $hmg
    }
  }

  // .frame {
  //   padding: 0 $hmg_small
  // }
  .form-actions {
    margin-top: $hmg !important
  }

  .start {
    padding: $hmg;

    > h4 {
      // text-align: center;
      margin-bottom: $hmg;
    }
    ul {
      padding: 0; margin: 0;
      display: flex;
      align-items: center; align-content: center;

      li {
        list-style: none;
        display: flex; flex-direction: column;
        align-items: center; align-content: center;
        justify-content: center;
        text-align: center;
        width: 150px; height: 130px;
        background-color: $color-secondary;
        border-radius: $border-radius;
        @extend %transition_4e;
        margin: 0 $hmg_small 0 0;
        padding: $hmg_small;
        cursor: pointer;

        .icon {
          font-size: 44px;
          margin-bottom: $hmg_small;
          color: $color-primary
        }

        h5 {
          padding: 0; margin: 0
        }

        &:hover {
          @extend %shadow-mid;
          transform: scale(1.1);
        }
      }
    }
  }
</style>