
import axios from 'axios'
// import authHeader from './auth-header'

class TecService {
  getProjectData () {
    return axios.get('https://sheets.googleapis.com/v4/spreadsheets/16mT9lkjXu4Eqw436tVtXOkexgquQWFP1ELbD4m89osY/values/Envios?key=AIzaSyDCFIuaS2YPz2lC4qmyme2nRS-S3364J0o')
  }
}

export default new TecService()