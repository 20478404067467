import axios from 'axios'
import authHeader from './auth-header'

class AgendaEduService {

  getCanais () {
    return axios.get(process.env.VUE_APP_API_URL + 'agendaedu/integracao/canais/listar', { headers: authHeader() })
  }
  
  getCanal ( idCanal ) {
    return axios.get(process.env.VUE_APP_API_URL + 'agendaedu/integracao/canais/ver/' + idCanal, { headers: authHeader() })
  }

  getTurmas () {
    return axios.get(process.env.VUE_APP_API_URL + 'agendaedu/turmas/cursos/2024', { headers: authHeader() })
  }

  saveCanal ( data ) {
    if ( data.idCanal ) {
      return axios.put(process.env.VUE_APP_API_URL + 'agendaedu/integracao/canais/editar/' + data.idCanal, data, { headers: authHeader() })
    } else {
      return axios.post(process.env.VUE_APP_API_URL + 'agendaedu/integracao/canais/criar', data, { headers: authHeader() })
    }
  }

  deleteCanal ( idCanal ) {
    return axios.delete(process.env.VUE_APP_API_URL + 'agendaedu/integracao/canais/deletar/' + idCanal, { headers: authHeader() })
  }

  getCategoriasComunicados () {
    // return axios.get(process.env.VUE_APP_API_URL + 'agendaedu/integracao/comunicados/listar', { headers: authHeader() })
    return axios.get('https://api.agendaedu.com/v2/handout_categories', { 
      headers: {
        'x-school-token': 'XLUsT79PiYJEinZlM9rN3eBmMVxnwKUR9sGxYakqLsuWQ_9h6RdPoLTa5CljMi19p_kUTqIXlpYl0eNcjVWhzogFPi2hasCsUkMspskayx5zkfTRdZ6GQo-3LOO29VSkJmNpnQ',
        Accept: 'application/json',
        Authorization: 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJpc3MiOiJwcm9kdWN0aW9uLmF1dGhfc2VydmVyIiwic2NvcGUiOiJzY2hvb2xfZGF0YSIsInN1YiI6bnVsbCwiYXVkIjoibFNfZFBwOVp5T01HZEk3NUNFMGxCOG9DRTU5LWVHa1RHb2lwdXBsSnZvUSIsImV4cCI6MTcyOTc5OTU5OSwiaWF0IjoxNzI5NzkyMzk5LCJ0YXJnZXRfcHJvZmlsZSI6bnVsbH0.UiB0otUHb2aFXzaOj8_6sHQX-qaKriEzFdkG48RNsyqt56-MlrelNLdtOlNyXNaB4w0KfGd0zfgSCj6_SWblXg'
      }
     })
  }
}

export default new AgendaEduService()