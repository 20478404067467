<template>
  <div class="row no-padd">
    <div class="col-md-12 no-padd-left">
      <ul class="checkbox-card-list">
        <li v-for="optativa in optativas" :key="optativa">
          <label class="check-control full">
            <input type="checkbox" class="check" :value="optativa.idTurmaOptativa" v-model="matricula.matricula.turmasOptativasIds">
            <span>{{ optativa.descricao }} - {{ optativa.descricaoCompleta }}</span>
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import UserService from '@/services/user.service'
import AcademicoService from '@/services/academico.service'
// import { createSelectOptions } from '@/services/utils.service'

export default {
  data () {
    return {
      options:{
        unidades: [],
        periodosLetivos: [],
        series: [],
        turmas: []
      },
      wait: {
        unidades:  false,
        periodosLetivos:  false,
        series:  false,
        turmas:  false
      },
      unidade: null,
      periodoLetivo: null,
      serie: null,
      turma: null,
      loading: false,
      loadingFilterData: false,
      matricula: null,
      optativas: []
    }
  },
  mounted () {
    this.$store.dispatch('matricula/get').then(response => {
      this.matricula = response
      this.getOptativas()
    })
  },
  methods: {
    getOptativas () {
      // window.spinner.emit("open")
      AcademicoService.getOptativas().then(
        response => {

          if ( response.data.length > 0 ) {
            this.$emit('empty')
          } else {
            console.log("getOptativas:", response.data)
            const optativas = response.data.filter( k => {
              // return k.idSerie === this.matricula.matricula.idSerie
              return k.idSerie === this.matricula.matricula.idTurma
            })

            this.optativas = optativas

            console.log("optativas:", this.optativas)
          }
          
          // this.wait.unidades = true
          // this.options.unidades = createSelectOptions(response.data, 'idUnidade' ,'descricao')
          
          // this.stopSpinner()
        },
        error => {
          console.error(error)
        }
      )
    },
  }
}
</script>

<style lang="scss" scoped>
  
</style>