<template>
  <controller>
    <h3 class="page-title">Módulos</h3>
    <section class="page-content">
      <transition name="fade">
        <criarModulos v-if="criar" :criarID="criarID" @close="close" @updateParentList="updateParentList"></criarModulos>
      </transition>
      <div class="page-inner">
        <datatable
          :headers="headers" 
          :data="content"
          :actions="actions"
          :align="align"
          :keys="keys"
          @onAdd="novo"
        ></datatable>
      </div>
      <confirm 
        @callback="onConfirm" 
        ref="confirm"
      ></confirm>
    </section>
  </controller>
</template>
<script>
import axios from 'axios'
import authHeader from '../../../services/auth-header'
import criarModulos from './Criar'
import { scrollUp } from '../../../services/utils.service'

export default {
  name: "Módulos",
  components: {
    criarModulos
  },
  data () {
    return {
      focused: false,
      aluno: null,
      responsavel: null,
      filiacao: null,
      unidade: null,
      api: null,
      headers: ['Nome', 'Módulo', 'Caminho', ''],
      keys: ['name', 'nome_modulo', 'path'],
      content: [],
      criar: false,
      criarID: null,
      actions: [
        {
          icon: ['far', 'pencil'],
          label: "Editar",
          showLabel: false,
          callback: (item) => {
            this.criar = true
            scrollUp()
            setTimeout(() => {
              this.criarID = item
            }, 100)
          }
        },
        {
          icon: ["far", "trash-can"],
          label: "Remover",
          showLabel: false,
          callback: (item) => {
             this.$refs.confirm.run({
               message: "Tem certeza que deseja remover este item?",
               confirmButton: "Sim",
               denyButton: "Não",
               callback: this.onConfirm,
               data: item
             })
          }
        }
      ],
      // align: [
      //   'left', 'left', 'center', '', 'right'
      // ]
    }
  },
  mounted () {
    this.listModulos()
  },
  methods: {
    listModulos () {
      window.spinner.emit('open')
      axios.get(process.env.VUE_APP_API_URL + 'auth/getallroles',{ headers: authHeader() })
      .then( response => {
          this.content = response.data
      })
      .catch( error => {
        console.error("error:", error)
      }).then(() =>{
        window.spinner.emit('close')
      })
    },
    close () {
      this.criar = false
      this.criarID = null
    },
    delete (id) {
      window.spinner.emit('open')
      axios.delete(process.env.VUE_APP_API_URL + 'auth/removerole/' + id, { headers: authHeader() })
      .then(response => {
        window.toaster.emit('open', {style: "success", message: "Módulo removido com sucesso."})
        this.listModulos()
        return response.data
      }).then(() =>{
        window.spinner.emit('close')
      });
    },
    updateParentList (){
      this.listModulos()
    },
    onConfirm (data,item) {
      this.delete(item.id)
    },
    novo () {
      console.log("adicionar novo item...")
      this.criar = true
      scrollUp()  
    }
  }
}
</script>
<style lang="scss" scoped>

</style>