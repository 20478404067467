<template>
  <div>
    <div :class="['toggle', { toggled: toggled}]">
      <div @click.prevent="toggle" v-if="!toggled" class="thumb">
        <img :src="pic" v-if="pic"/>
        <!-- <figure :style="{ 'background-image': `url('${pic}')`}" v-if="pic"></figure> -->
        <h4 v-else>{{ setInitials() }}</h4>
      </div>
      <div v-if="toggled" class="userBox">
        <header>
          <figure :style="{ 'background-image': `url('${pic}')`}">
            <!-- <label class="profile-pic-upload">
              <input type="file" @change="handleFileUpload"/>
            </label> -->
          </figure>
          <h5>{{ currentUser.nome }}</h5>
        </header>
        <p><b>{{ currentUser.funcao.nome }}</b></p>
        <p>{{ currentUser.email }}</p>
        <a href="#" @click.prevent="logOut()" class="btSair btn btn-bg btn-small">
          Sair
        </a>
      </div>
      <span></span>
      <span class="backdrop" @click.prevent="toggle(false)"></span>
    </div>
  </div>
</template>

<script>
import UserService from '@/services/user.service.js'
import { toBase64 } from '@/services/utils.service.js'

export default {
  data () {
    return {
      toggled: false,
      pic: null,
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
      file: {
        file: null,
        name: null,
        ext: null,
        size: null,
        src: null,
        error: null
      },
    }
  },
  mounted() {
    this.setInitials()

    // if ( this.currentUser.urlFoto ) {
    //   UserService.storePicture(this.currentUser)
    // }
    
    this.pic = UserService.getPictureFromStorage(this.currentUser.email)

    console.log("this.pic:", this.pic)
  },
  methods: {
    toggle ( state ) {
      this.toggled = state ? state : !this.toggled
    },
    setInitials () {
      // console.log("currentUser:", this.currentUser)
      const parsed = this.currentUser.email.split("@")[0].split(".")
      const initials = parsed.length > 1 ? parsed[0].split("")[0] + parsed[1].split("")[0] : parsed[0].split("")[0]

      return initials
    },
    handleFileUpload ( e ) {
      console.log("handleFileUpload:", e)

      const file = e.target.files[0]
      const name = file.name
      const ext = file.type
      const size = file.size
      const src = URL.createObjectURL(file)

      this.file.file = file
      this.file.name = name
      this.file.ext = ext
      this.file.size = size
      this.file.src = src
      this.file.error = null
      // this.documentos[type][i].documentoEntregue.src = URL.createObjectURL(file)

      toBase64(file).then(
        response => {
          this.fake = response
          console.log("toBase64", response)
          UserService.uploadPicture(response).then(
            response => {
              this.pic = response.data.urlFoto
              UserService.storePicture(this.currentUser,response.data.urlFoto)
            },
            () => {
              window.toaster.emit('open', {style: "warning", message: 'Ops. Algo deu errado! Não foi possível salvar sua foto de perfil.', floater: true})
            }
          )
        }
      )
    },
    logOut() {
      this.$store.dispatch('auth/logout')
      this.$router.go()
    },
  }
}
</script>

<style lang="scss" scoped>
  .toggle {
    display: block;
    width: 40px; height: 40px;
    position: relative;
    overflow: visible;
    margin: auto;

    figure {
      width: 40px; height: 40px;
      border-radius: $border-radius;
      background-color: $color-secondary;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border: $border-component;
    }
    .thumb {
      position: relative; height: 40px; width: 40px;
      z-index: 501; overflow: hidden;
      border-radius: $border-radius;
      position: relative;
      @extend %transition_4e;
      cursor: pointer;

      img {
        display: block; width: 40px; height: 40px;
        position: absolute; top: 0; left: 0;
        z-index: 10;
      }
    }

    h4 { 
      text-transform: uppercase;
      text-align: center;
      line-height: 1;
      position: absolute; top: 50%;
      left: 0; right: 0;
      margin: auto;
      z-index: 51;
      transform: translateY(-50%)
    }
    span:not(.backdrop) {
      width: 100%; height: 100%;
      border-radius: $border-radius;
      border: $border-component;
      background-color: $color-bg;
      position: absolute;
      top: 0; left: 0;
      z-index: 50;
      padding: 0;
      @extend %transition_4e;
    }

    &:hover:not(.toggled) {
      span:not(.backdrop),
      .thumb {
        transform: scale(1.1);
      }
      .thumb {
        z-index: 502;
      }
    }
    .backdrop {
      position: fixed;
      bottom: 0; left: 0;
      width: 100vw; height: 100vh;
      background-color: transparent;
      z-index: 40;
      opacity: 0; pointer-events: none;
      @extend %transition_4o;
    }
    &.toggled {
      overflow: visible;
      width: 260px;
      height: 180px;

      .backdrop {
        opacity: 1;
        pointer-events: all;
      }
    }

    .userBox {
      padding: $hmg_mid;
      text-align: left;
      position: relative;
      z-index: 500;

      header {
        display: flex; 
        align-items: center; align-content: center;
        justify-content: center;
        gap: $mg_mini;

        figure {
          width: 50px; height: 50px;
          min-width: 50px; min-height: 50px;
          border-radius: $border-radius;
          background-color: $color-secondary;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        }
      }

      p {
        margin: 0; padding: 0;
      }
    }

    .profile-pic-upload {
      width: 100%; height: 100%;

      input {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  .btSair {
    display: inline-block;
    margin-top: $mg_mini;
    
    &:hover {
      color: $color-primary
    }
  }
</style>