<template>
  <router-view v-slot="{ Component }">
    <transition name="fade">
      <component :is="Component"/>
    </transition>
    <h4 class="aguarde" v-if="!Component">Aguarde. Carregando...</h4>
  </router-view>
</template>

<style lang="scss">

#app {
  width: 100%; height: 100%
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to{ 
  opacity: 0;
}

.aguarde {
  position: absolute;
  width: auto; max-width: 100vw;
  z-index: 0; left: 50%; top: 50%; transform: translate(-50%);
  line-height: 1;
  padding: .4em 1em; border-radius: 2em;
  background-color: $color-bg;
  white-space: nowrap;
}

</style>
