import axios from 'axios'
import authHeader from './auth-header'

class NotificacaoService {

  getNotificacoes (idUser,status) {
    return axios.post(process.env.VUE_APP_API_URL + `get/notificacoes/user/${idUser}/${status}`, {}, { headers: authHeader() })
  }
  markAsRead ( notificacoes ) {
    return axios.post(process.env.VUE_APP_API_URL + `read/notificacoes/user`, { ids: notificacoes }, { headers: authHeader() })
  }
  saveNotificacoes ( ) {
    // const notificacoes = JSON.stringify(items)
    // localStorage.setItem(notificacoes)
    // return true
  }
  deleteNotificacoes () {
    
  }
}

export default new NotificacaoService()