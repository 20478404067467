import axios from 'axios'
import authHeader from './auth-header'

class PbiService {
  // async getAccessToken () {
  //   const token = await axios.post(`https://login.microsoftonline.com/${process.env.VUE_APP_PBI_TENANT}/oauth2/token `, 
  //     {
  //       'grant_type': 'client_credentials',
  //       'scope': 'openai',
  //       'resource': 'https://analysis.windows.net/powerbi/api',
  //       'client_id': process.env.VUE_APP_PBI_CLIENT_ID,
  //       'client_secret': process.env.VUE_APP_PBI_CLIENTE_SECRET
  //     },{ 
  //     headers: { 
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //       'Cookie': 'fpc=AjihsdwrD3VHrrVG7Pj-w8NGAozTAQAAAOCrmN4OAAAA'
  //     }
  //   }).then( response => {
  //     console.log("getAccessToken:", response)
  //     return response.data
  //   })

  //   return token
  // }

  async getPainel ( idPainel ) {
    const painel = await axios.get(process.env.VUE_APP_API_URL + 'integracao/powerbi/' + idPainel, { headers: authHeader() })

    return painel
  }

  dropPainel ( token, group, report ) {
    // /?filter=unidade/unidade in ('DEC')
    return axios.get(`https://app.powerbi.com/groups/${group}/reports/${report}/ReportSection?filter=unidade/unidade in ('DEC')`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
  }
}

export default new PbiService()
  