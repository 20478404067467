<template>
  <div 
    class="select-control" 
    :class="{
      error: !valid
    }">
    <fa :icon="['fas', 'asterisk']" :class="['requiredIcon','color-primary', { hasLabel: label != '' }]" v-if="required" />
    <label v-if="label != ''">{{ label }}</label>
    <div 
      :class="[
        'select', 
        size,
        cssClass,
        {
          transparent: mode === 'transparent',
          error: !valid,
          readonly: readonly || disabled,
          focus: focused
        }
      ]"
    >
      <span class="placeholder" v-if="!modelValue">{{ placeholder }}</span>
      <fa :icon="['fas', 'chevron-down']" class="selectToggle"></fa>
      <select
        :name="name"
        @focus="focus"
        @blur="blur"
        @input="input" 
        v-bind:value="modelValue"
        :valid="valid"
        ref="select"
        :required="required"
      >
        <option selected disabled >{{ placeholder }}</option>
        <option v-for="(option, index) in options" :key="index" :value="option.value" >
          {{ option.label }}
        </option>
      </select>
    </div>
  </div>
</template>
<script>
export default {
  name: "select-control",
  props: {
    type: {
      type: String,
      default: "text"
    },
    class: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    cssClass: {
      type: String,
      default: ""
    },
    valid: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ""
    },
    mode: {
      type: String,
      default: "default"
    },
    modelValue: {
      default: null
    },
    options: {
      type: Array,
      default: function () {
        return []
      }
    },
    size: {
      type: String,
      default: "default" //default, large
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    // modelValue (newVal, oldVal) {
    //   console.log("newVal:", newVal)
    //   console.log("oldVal:", oldVal)
    // }
  },
  data () {
    return {
     value: null,
     focused: false
    }
  },
  mounted () {
    // this.inputType = this.type
    // console.log("ini value:", this.value)
    // console.log("ini modelValue:", this.modelValue)
    if ( this.isIOS() && !this.modelValue ) {
      // console.log("this.options:", this.options)
      // this.$emit("update:modelValue", this.options[0].value)
      // this.$emit("change", this.options[0].value)
    } 
  },
  methods: {
    focus (data) {
      this.$emit("focus", data)
      this.focused = true
    },
    blur (data) {
      this.$emit("blur", data)
      this.focused = false
    },
    // change (data) {
    //   this.$emit("update:modelValue", data.target.value)
    // },
    input (data) {
      this.$emit("update:modelValue", data.target.value)
    },
    isIOS () {
      // console.log("navigator:", navigator)
      const nP = navigator.vendor
      // console.log("platform:", nP)
      const isIOS = nP.includes('Apple')
      // console.log("isIOS:", isIOS)
      return isIOS

    }
  }
}
</script>
<style lang="scss" scoped>
//forms
.select-control {
  position: relative;

  .requiredIcon {
    position: absolute;
    top: -5px;
    right: $hmg_small;
    transform: none;
    z-index: 11;
    font-size: 10px;

    &.hasLabel {
      top: 17px
    }
  }
  
  .select {
    position: relative;
    background-color: #FFF;
    border: $border-component;
    width: 100%; height: 50px; min-width: 140px;
    border-radius: $border-radius;
    box-shadow: none; outline: none;
    color: $color-text;
    @extend %transition_3o;
    
    &.invert {
      background-color: $color-bg;
    }

    &[disabled='true'], 
    &[readonly='true'],
    &.disabled,
    &.readonly,
    optgroup {
      background-color: $color-fade;
      pointer-events: none;
    }

    &.focus {
      @extend %shadow-mid;
      border: 0;
      outline: none;
    }

    .placeholder {
      position: absolute;
      left: 0; top: 50%;
      width: 100%;
      padding: 0 30px 0 15px;
      transform: translateY(-50%);
      pointer-events: none;
      color: $color-fade-low
    }

    select {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-color: transparent;
      width: 100%; height: 100%;
      min-width: 140px;
      outline: 0; border: 0;
      padding: 0 38px 0 15px;
      box-shadow: none;
      color: $color-text;
      font-weight: 400;
      // font-size: 16px;
      @extend %transition_3o;

      option:disabled {
        color: $color-fade
      }
      
    }

    .selectToggle {
      position: absolute;
      right: 15px; top: 50%;
      transform: translateY(-50%);
      font-size: 10px; color: $color-text;
    }
    
    &.transparent {
      background-color: rgba(0,0,0,0.1);

      select {
        color: #FFF;

        option {
          color: $color-text;
        }
      }

      .placeholder {
        color: #FFF;
      }
      .selectToggle {
        color: #FFF;
      }
    }

    &.large {
      height: 75px;
      
      select {
        font-size: 18px;
      }
      .placeholder {
        font-size: 18px;
        padding: 0 40px 0 20px;
      }

      .selectToggle {
        right: 20px;
      }
    }

    &.small {
      height: 30px;
      font-size: 12px;
      min-width: 0;

      select {
        padding: 0 38px 0 $mg_mini;
        min-width: 0
      }
      .selectToggle {
        right: $mg_mini;
      }
    }

    &.error {
      border: 2px solid $color-alert;
    }
  }
}

</style>  