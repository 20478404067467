<template>
  <section class="tips">
    <header>
      <fa :icon="['fas', 'message-bot']" class="icon color-primary" />
      <h3>Dicas</h3>
    </header>
    <div>
      <a href="https://www.rededecisao.com.br/ud_programa-embaixadores/" target="_blank">
        <img :src="require('@/assets/img/selo_embaixadores_dourado_cta.png')" class="selo"/>
      </a>
      <ul>
        <transition-group name="slideRightToLeftLong">
          <li v-for="(tip, i ) in tips" :key="tip">
            <b class="color-primary">
            {{ counter(i) }}.
            </b>
            {{ tip.message }}
          </li>
        </transition-group>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      dicas: {
        "Aluno": {
          "nomeSocial": {
            message: 'Você sabia que consegue escolher por qual nome você identifica ou se reconhece? Lembre-se que seu nome social não é seu apelido.',
            link: ''
          },
          "email": {
            message: 'Este campo deve ser preenchido com o email do aluno.',
            link: ''
          }
        },
        "respFin": {
          "email": {
            message: 'O email do responsável financeiro não pode ser igual ao do responsável pedagógico.',
            link: ''
          }
        },
        "documentos": {
          "documentos-obrigatorios": {
            message: 'Fique atento aos documentos obrigatórios, caso ainda não estejam anexados não será possível seguir com a matrícula',
            link: ''
          },
          "documentos-nao-obrigatorios": {
            message: 'Você notou que está faltando algum documento? Você pode anexar o documento que está pendente ou enviar diretamente a sua unidade.',
            link: ''
          }
        }
      },
      tips: []
    }
  },
  mounted () {
    this.start()
  },
  methods: {
    start () {
      window.mattip.on('add', tip => {
        console.log("tip: ", tip)
        const base = this.dicas[tip.branch]
        if ( base ) {
          const findTip = base[tip.field]
          console.log("findTip: ", findTip)

          if ( findTip ) {
            const exists = this.tips.filter( k => {
              return k.message == findTip.message
            }).length > 0 
            console.log("exists: ", exists)
            if ( !exists ) {
              this.tips.unshift(findTip)
            }
            
          }

          console.log("findTip:", findTip)
        }
        
      })
    },
    counter(i) {
      const index = this.tips.length - i
      const count = index < 10 ? '0' + index : index

      return count
     }
  }
}
</script>

<style lang="scss" scoped>
  .tips {
    header {
      display: flex; align-content: flex-start; align-items: center;
      width: 100%;

      .icon {
        font-size: 18px; margin-right: $hmg_mini
      }

      h3 { 
        padding-top: $hmg_mini;
      }
    }

    ul { 
      width: 100%;
      height: auto;
      padding: 0; margin: $hmg 0 0 0;

      li { 
        list-style: none;
        padding: $hmg;
        border-radius: $border-radius;
        background-color: $color-secondary;
        width: calc( 100% + $hmg);
        margin-left: -$hmg; margin-bottom: $hmg_small;
        @extend %transition_4e;

        &:first-child {
          @extend %shadow-low-light
        }
      }
    }
  }

  .selo {
    display: block; width: 80%; height: auto;
  }
</style>