import axios from 'axios'
import authHeader from './auth-header'
// import moment from 'moment'

class CheckoutLojaService {

  checkoutMarketPlace(data) {
    return axios.post(process.env.VUE_APP_API_URL + 'loja/checkoutmarketplace', data, { headers: authHeader() })
  }

  generatePixQR ( code ) {
    return axios.get('https://gerarqrcodepix.com.br/api/v1?brcode=' + code + '&tamanho=256', { responseType: 'arraybuffer', 'Accept-Encoding': 'gzip, deflate, br' })
  }
}

export default new CheckoutLojaService();
