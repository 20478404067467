<template>
  <controller :goToTopButton="true">
    <div class="page-title">
      <h4 class="text-capitalize">Categorias</h4>
      <template v-if="details">
        <a href="#" class="btn btn-secondary" @click.prevent="goBack()">Voltar</a>
      </template>
    </div>
  </controller>
</template>

<script>
export default {
  name: "Categorias",
  components: {

  },
  props: {

  },
  data () {
    return {

    }
  },
  mounted () {

  },
  methods: {
    
  }
}
</script>

<style lang="scss">

</style>