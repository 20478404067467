<template>
  <section class="simulador">
    <a href="#" class="btnClose large" @click.prevent="close()">
    </a>
    <div>
      <div class="plano" v-if="plano">
        <div>
          <span v-if="plano.interno" class="internoTag">Interno</span>
          <h2 class="color-primary">{{ plano.descricao }}</h2>
          <div class="valorTotal">
            <p>De: <del>{{ formatMoney(parseFloat(plano.valorTotal)) }}</del></p>
            <p>
              <b>Por: {{ formatMoney(parseFloat(plano.valorTotalDesconto)) }} 
              <span class="color-success">com {{ plano.desconto }}% de desconto</span>
              </b>
            </p>
          </div>
          <div class="discountMessage">
            <fa :icon="['fas','badge-dollar']" class="icon"></fa>
            <span>Economia de {{ formatMoney(calcDiscountDif(plano)) }}</span>
          </div>
        </div>
        <div>
          <div class="planoBox" v-if="plano.entrada">
            <header>
              Primeira Mensalidade
            </header>
            <div>
              <div class="months">
                <p>{{ dropDate(plano.entrada.mesInicio) }}</p>
                <div>
                  <span></span>
                  <p>{{ plano.entrada.numeroParcelas }} Parcelas</p>
                </div>
                <p>{{ dropDate(plano.entrada.mesFinal) }}</p>
              </div>
              <div class="price">
                <div>
                  <p>De</p>
                  <p>{{ plano.entrada.numeroParcelas }}x de <b><del>{{ formatMoney(plano.entrada.valorParcela) }}</del></b></p>
                  <p><del>{{ formatMoney(plano.entrada.valorTotal) }}</del></p>
                </div>
                <div>
                  <p>Por</p>
                  <p>{{ plano.entrada.numeroParcelas }}x de <b><span>{{ formatMoney(plano.entrada.valorParcelaDesconto) }}</span></b></p>
                  <p>{{ formatMoney(plano.entrada.valorTotalDesconto) }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="planoBox" v-if="plano.mensalidades">
            <header>
              {{ plano.entrada && 'Demais' }} Mensalidades
            </header>
            <div>
              <div class="months">
                <p>{{ dropDate(plano.mensalidades.mesInicio) }}</p>
                <div>
                  <span></span>
                  <p>{{ plano.mensalidades.numeroParcelas }} Parcelas</p>
                </div>
                <p>{{ dropDate(plano.mensalidades.mesFinal) }}</p>
              </div>
              <div class="price">
                <div>
                  <p>De</p>
                  <p>{{ plano.mensalidades.numeroParcelas }}x de <b><del>{{ formatMoney(plano.mensalidades.valorParcela) }}</del></b></p>
                  <p><del>{{ formatMoney(plano.mensalidades.valorTotal) }}</del></p>
                </div>
                <div>
                  <p>Por</p>
                  <p>{{ plano.mensalidades.numeroParcelas }}x de <b><span>{{ formatMoney(plano.mensalidades.valorParcelaDesconto) }}</span></b></p>
                  <p>{{ formatMoney(plano.mensalidades.valorTotalDesconto) }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="planoBox" v-if="plano.anuidade">
            <header>
              Anuidade
            </header>
            <div>
              <div class="months">
                <p><b>1 Parcela</b></p>
                <div>
                  <span></span>
                  <!-- <p>{{ plano.anuidade.numeroParcelas }} Parcelas</p> -->
                </div>
                <p>{{ dropDate(plano.anuidade.mesFinal) }}</p>
              </div>
              <div class="price">
                <div>
                  <p>De</p>
                  <!-- <p>{{ plano.anuidade.numeroParcelas }}x de <b><del>{{ formatMoney(plano.anuidade.valorParcela) }}</del></b></p> -->
                  <p><del>{{ formatMoney(plano.anuidade.valorTotal) }}</del></p>
                </div>
                <div>
                  <p>Por</p>
                  <!-- <p>{{ plano.anuidade.numeroParcelas }}x de <b><span>{{ formatMoney(plano.anuidade.valorParcelaDesconto) }}</span></b></p> -->
                  <p><b><span>{{ formatMoney(plano.anuidade.valorTotalDesconto) }}</span></b></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="calculadora">
        <nav>
          <a href="#" :class="['btn',{ 'btn-primary': calculadora == 'desconto', 'btn-bg': calculadora == 'valor' }]" @click.prevent="toggleType('desconto')">Desconto</a>
          <a href="#" :class="['btn',{ 'btn-primary': calculadora == 'valor', 'btn-bg': calculadora == 'desconto' }]" @click.prevent="toggleType('valor')">Valor</a>
          <div>
            <switch-control v-model:checked="lockAlcada"  labelOn="Respeitar Alçada" labelOff="Cálculo Livre" invert/>
          </div>
        </nav>
        <div v-if="calculadora == 'desconto'">
          <header>
            <h4>Calcular Desconto</h4>
            <p>Você pode utilizar valores ou porcentagem de desconto para recalcular o plano.</p>
          </header>
          <template v-if="type == 'mensalidade'">
            <div v-if="!noEntrada">
              <h5>Entrada</h5>
              <div class="form-group">
                <span>
                  <input-control 
                    label="Percentual"
                    v-model="desconto.entrada.percent" 
                    placeholder="ex. 30" 
                    type="number"
                    :valid="desconto.entrada.validPercent"
                    @change="sync('desconto', 'entrada', 'percent')"
                  />
                  <p>Seu limite: <b>{{ desconto.entrada.maxPercent }}%</b></p>
                </span>
                <span>
                  <input-control 
                    label="Valor"
                    v-model="desconto.entrada.value" 
                    placeholder="ex. 16,20" 
                    type="number"
                    :valid="desconto.entrada.validValue"
                    @change="sync('desconto', 'entrada', 'value')"
                  />
                  <p>Seu limite: <b>{{ formatMoney(desconto.entrada.maxValue) }}</b></p>
                </span>
                <!-- <a href="#" >
                  <fa :icon="['far','calculator']" />
                </a> -->
              </div>
            </div>
            <div>
              <h5>Mensalidade</h5>
              <div class="form-group">
                <span>
                  <input-control
                    label="Percentual"
                    v-model="desconto.mensalidade.percent" 
                    placeholder="ex. 30" 
                    type="number"
                    :valid="desconto.mensalidade.validPercent"
                    @change="sync('desconto', 'mensalidade', 'percent')"
                  />
                  <p>Seu limite: <b>{{ desconto.mensalidade.maxPercent }}%</b></p>
                </span>
                <span>
                  <input-control
                    label="Valor" 
                    v-model="desconto.mensalidade.value" 
                    placeholder="ex. 129,45" 
                    type="number"
                    :valid="desconto.mensalidade.validValue"
                    @change="sync('desconto', 'mensalidade', 'value')"
                  />
                  <p>Seu limite: <b>{{ formatMoney(desconto.mensalidade.maxValue) }}</b></p>
                </span>
                <!-- <a href="#" >
                  <fa :icon="['far','calculator']" />
                </a> -->
              </div>
            </div>
          </template>
          <div v-else-if="type == 'anuidade'">
            <h5>Anuidade</h5>
            <div class="form-group">
              <span>
                <input-control 
                  label="Percentual"
                  v-model="desconto.anuidade.percent" 
                  placeholder="ex. 30" 
                  type="number"
                  :valid="desconto.anuidade.validPercent"
                  @change="sync('desconto', 'anuidade', 'percent')"
                />
                <p>Seu limite: <b>{{ desconto.anuidade.maxPercent }}%</b></p>
              </span>
              <span>
                <input-control 
                  label="Valor"
                  v-model="desconto.anuidade.value" 
                  placeholder="ex. 16,20" 
                  type="number"
                  :valid="desconto.anuidade.validValue"
                  @change="sync('desconto', 'anuidade', 'value')"
                />
                <p>Seu limite: <b>{{ formatMoney(desconto.anuidade.maxValue) }}</b></p>
              </span>
              <!-- <a href="#" >
                <fa :icon="['far','calculator']" />
              </a> -->
            </div>
          </div> 
        </div>
        <div v-else-if="calculadora == 'valor'">
          <header>
            <h4>Alterar Valor</h4>
            <p>Você pode definir valores para recalcular o plano.</p>
          </header>
          <template v-if="type == 'mensalidade'">
            <div v-if="!noEntrada">
              <h5>Entrada</h5>
              <div class="form-group">
                <span>
                  <input-control 
                    v-model="valor.entrada.value" 
                    placeholder="ex. 280,10" 
                    label="Valor"
                    type="number"
                    :valid="valor.entrada.valid"
                    @keyup="sync('valor', 'entrada', 'value')"
                  />
                  <p>Seu limite: <b>{{ formatMoney(valor.entrada.min) }}</b></p>
                </span>
                <span>
                  <input-control 
                    v-model="valor.entrada.percent" 
                    placeholder="" 
                    label="Percentual"
                    type="number"
                    disabled
                  />
                  <p>&nbsp;</p>
                </span>
                <!-- <a href="#" >
                  <fa :icon="['far','calculator']" />
                </a> -->
              </div>
            </div>
            <div>
              <h5>Mensalidade</h5>
              <div class="form-group">
                <span>
                  <input-control
                    v-model="valor.mensalidade.value" 
                    placeholder="ex. 1450,90"
                    label="Valor"
                    type="number"
                    :valid="valor.mensalidade.valid"
                    @keyup="sync('valor', 'mensalidade','value')"
                  />
                  <p>Seu limite: <b>{{ formatMoney(valor.mensalidade.min) }}</b></p>
                </span>
                <span>
                  <input-control 
                    v-model="valor.mensalidade.percent" 
                    placeholder="" 
                    label="Percentual"
                    type="number"
                    disabled
                  />
                  <p>&nbsp;</p>
                </span>
                <!-- <a href="#" >
                  <fa :icon="['far','calculator']" />
                </a> -->
              </div>
            </div>
          </template>
          <div v-else-if="type == 'anuidade'">
            <h5>Anuidade</h5>
            <div class="form-group">
              <span>
                <input-control 
                  v-model="valor.anuidade.value" 
                  placeholder="ex. 280,10" 
                  label="Valor"
                  type="number"
                  :valid="valor.anuidade.valid"
                  @keyup="sync('valor', 'anuidade', 'value')"
                />
                <p>Seu limite: <b>{{ formatMoney(valor.anuidade.min) }}</b></p>
              </span>
              <span>
                <input-control 
                  v-model="valor.anuidade.percent" 
                  placeholder="" 
                  label="Percentual"
                  type="number"
                  disabled
                />
                <p>&nbsp;</p>
              </span>
            </div>
          </div>
        </div>
        <footer>
          <a href="#" class="btn btn-success" @click.prevent="calc()">
            <fa :icon="['far','calculator']" />
            &nbsp;Calcular</a>
          <a href="#" class="btn btn-bg" @click.prevent="clear()">
            <fa :icon="['far','trash-can']" />
            &nbsp;Limpar</a>
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
import { formatMoney } from '@/services/utils.service'
import MatriculaService from '@/services/matricula.service.js'

export default {
  props: {
    scope: {
      type: Object
    }
  },
  data () {
    return {
      plano: null,
      calculadora: 'desconto',
      lockAlcada: true,
      type: 'mensalidade',
      options: {
        type: [
          {
            value: 'value',
            label: 'Valor'
          },
          {
            value: 'percent',
            label: 'Porcentagem'
          }
        ],
        meses: {
          1: 'jan',
          2: 'fev',
          3: 'mar',
          4: 'abr',
          5: 'mai',
          6: 'jun',
          7: 'jul',
          8: 'ago',
          9: 'set',
          10: 'out',
          11: 'nov',
          12: 'dez'
        },
      },
      desconto: {
        entrada: {
          percent: null,
          value: null,
          maxValue: 0,
          maxPercent: 0,
          grupo: 'ENT',
          sigla: 'RES',
          validPercent: true,
          validValue: true
        },
        mensalidade: {
          percent: null,
          value: null,
          maxValue: 0,
          maxPercent: 0,
          grupo: 'MENS',
          sigla: 'MEN',
          validPercent: true,
          validValue: true
        },
        anuidade: {
          percent: null,
          value: null,
          maxValue: 0,
          maxPercent: 0,
          grupo: 'MENS',
          sigla: 'ANUAL',
          validPercent: true,
          validValue: true
        }
      },
      valor: {
        entrada: {
          value: null,
          percent: null,
          min: 0,
          max: 0,
          alcada: 0,
          grupo: 'ENT',
          sigla: 'RES',
          parcelas: 1,
          valid: true
        },
        mensalidade: {
          value: null,
          percent: null,
          min: 0,
          max: 0,
          alcada: 0,
          grupo: 'MENS',
          sigla: 'MEN',
          parcelas: 1,
          valid: true
        },
        anuidade: {
          value: null,
          percent: null,
          min: 0,
          max: 0,
          alcada: 0,
          grupo: 'MENS',
          sigla: 'ANUAL',
          valid: true
        }
      },
      calcTimeout: null,
      bkpDesconto: null,
      bkpValor: null,
      noEntrada: false
    }
  },
  mounted () {
    this.plano = Object.assign({}, JSON.parse(JSON.stringify(this.scope)))
    this.bkpDesconto = Object.assign({}, JSON.parse(JSON.stringify(this.desconto)))
    this.bkpValor = Object.assign({}, this.valor)

    this.$nextTick(() => {
      this.setup()
    })
    
    window.pagescroll.emit('lock')
  },
  methods: {
    setup () {
      const planos = this.plano.planosPagamento
      var anuidade = planos.filter( a => a.grupo == 'MENS' && a.sigla == 'ANUAL')
      var anuidadeMaterial = planos.filter( a => a.grupo == 'MENS' && a.sigla == 'MAV')
      
      if ( anuidade.length > 0 ) {
        this.type = 'anuidade'
        anuidade = anuidade[0]
        anuidadeMaterial = anuidadeMaterial[0]

        const valorAnuidade = parseFloat(anuidade.valorTotal)
        const valorAnuidadeMaterial = parseFloat(anuidadeMaterial.valorTotal)

        const parcelasAnuidade = anuidade.numeroParcelas
        const parcelaAnuidadeMaterial = anuidadeMaterial.numeroParcelas

        const alcadaAnuidade = anuidade.alcadasDesconto.length > 0 ? anuidade.alcadasDesconto[0].percentualLimite : parseFloat(anuidade.descontoPlano)

        const valorLimiteAnuidade = ( valorAnuidade * alcadaAnuidade ) / 100

        this.valor.anuidade.max = ( valorAnuidade / parcelasAnuidade ) + ( valorAnuidadeMaterial / parcelaAnuidadeMaterial )
        this.valor.anuidade.min = ( ( valorAnuidade / parcelasAnuidade ) + ( valorAnuidadeMaterial / parcelaAnuidadeMaterial ) ) - ( valorLimiteAnuidade / parcelasAnuidade )
        this.valor.anuidade.parcelas = parcelasAnuidade

        this.desconto.anuidade.maxPercent = alcadaAnuidade
        this.desconto.anuidade.maxValue = valorLimiteAnuidade / parcelasAnuidade
      } else {
        this.type = 'mensalidade'
        
        const mensalidade = planos.filter( a => a.grupo == 'MENS' && a.sigla == 'MEN')[0]
        const entrada = planos.filter( a => a.grupo == 'ENT' && a.sigla == 'RES')[0]
        const entradaMaterial = planos.filter( a => a.grupo == 'ENT' && a.sigla == 'EMD')[0]
        const mensalidadeMaterial = planos.filter( a => a.grupo == 'MENS' && a.sigla == 'MATD')[0]
        
        this.noEntrada = !entrada
        console.log('noEntrada:', this.noEntrada)
        // console.log('entrada:', entrada)
        // console.log('entradaMaterial:', entradaMaterial)
        // console.log('mensalidadeMaterial:', mensalidadeMaterial)

        const valorEntrada = entrada ? parseFloat(entrada.valorTotal) : 0
        const valorMensalidade = parseFloat(mensalidade.valorTotal)

        const valorEntradaMaterial = entradaMaterial ? parseFloat(entradaMaterial.valorTotal) : 0
        const valorMensalidadeMaterial = parseFloat(mensalidadeMaterial.valorTotal)

        const parcelasEntrada = entrada ? entrada.numeroParcelas : 0
        const parcelasMensalidade = mensalidade.numeroParcelas

        const parcelaEntradaMaterial = entradaMaterial ? entradaMaterial.numeroParcelas : 0
        const parcelaMensalidadeMaterial = mensalidadeMaterial.numeroParcelas

        const alcadaMensalidade = mensalidade.alcadasDesconto.length > 0 ? mensalidade.alcadasDesconto[0].percentualLimite : parseFloat(mensalidade.descontoPlano)
        const alcadaEntrada = entrada ? ( entrada.alcadasDesconto.length > 0 ? entrada.alcadasDesconto[0].percentualLimite : parseFloat(entrada.descontoPlano) ) : 0

        const valorLimiteMensalidade = ( valorMensalidade * alcadaMensalidade ) / 100
        const valorLimiteEntrada = entrada ? ( ( valorEntrada * alcadaEntrada ) / 100 ) : 0
        
        if ( entrada ) {
          this.valor.entrada.max = ( valorEntrada / parcelasEntrada ) + ( valorEntradaMaterial / parcelaEntradaMaterial )
          this.valor.entrada.min = ( ( valorEntrada / parcelasEntrada) + ( valorEntradaMaterial / parcelaEntradaMaterial ) ) - ( valorLimiteEntrada / parcelasEntrada )
          this.valor.entrada.parcelas = parcelasEntrada

          this.desconto.entrada.maxPercent = alcadaEntrada
          this.desconto.entrada.maxValue = valorLimiteEntrada / parcelasEntrada
        }
        
        
        this.valor.mensalidade.max = ( valorMensalidade / parcelasMensalidade ) + ( valorMensalidadeMaterial / parcelaMensalidadeMaterial )
        this.valor.mensalidade.min = ( ( valorMensalidade / parcelasMensalidade ) + ( valorMensalidadeMaterial / parcelaMensalidadeMaterial ) ) - ( valorLimiteMensalidade / parcelasMensalidade )
        this.valor.mensalidade.parcelas = parcelasMensalidade

        this.desconto.mensalidade.maxPercent = alcadaMensalidade
        this.desconto.mensalidade.maxValue = valorLimiteMensalidade / parcelasMensalidade
      }
    },
    formatMoney ( value ) {
      return formatMoney(value)
    },
    calcDiscountDif ( plano ) {
      const valorTotal = parseFloat(plano.valorTotal)
      const valorTotalDesconto = parseFloat(plano.valorTotalDesconto)

      const dif = valorTotal - valorTotalDesconto
      return dif
    },
    dropDate ( date ) {
      const parsed = date.split('/')
      const year = parsed[1].slice(-2)
      return this.options.meses[parseFloat(parsed[0])] + '/' + year
    },
    toggleType ( type ) {
      this.clear()
      this.calculadora = type
    },
    sync (target, input, type) {
      const planos = this.plano.planosPagamento
      const t = this[target][input]
      const tv = t[type]
      var minLength = type == 'percent' ? 1 : 3

      if ( tv && tv.length >= minLength ) {
        if ( this.calcTimeout ) clearTimeout(this.calcTimeout)

        this.calcTimeout = setTimeout(() => {
          const plano = planos.filter( a => a.grupo == t.grupo && a.sigla == t.sigla)[0]

          //calc limit
          if ( target == 'desconto' ) {
            if ( this.lockAlcada ) {
              const limit = type == 'percent' ? t.maxPercent : t.maxValue
              const limited = tv > limit ? parseFloat(limit) : parseFloat(tv)
              this[target][input][type] = parseFloat(limited.toFixed(2))
            }
            
            if ( type == 'percent' ) {
              //calcular valor de acordo com a porcentagem
              const valorParcela = parseFloat(( ( parseFloat(plano.valorParcela) * parseFloat(this[target][input][type]) ) / 100 ).toFixed(2))
              this[target][input].value = valorParcela
            }

            if ( type == 'value' ) {
              // //calcular valor de acordo com a porcentagem
              const valorDesconto = parseFloat(( ( parseFloat(this[target][input][type]) * 100 ) / parseFloat(plano.valorParcela) ).toFixed(2))
              this[target][input].percent = valorDesconto
            }  
          }
          
          if ( target == 'valor' ) {
            if ( this.lockAlcada ) {
              const limit = t.min
              const limited = tv < limit ? parseFloat(limit) : ( tv > t.max  ? parseFloat(t.max) : parseFloat(tv) )
              console.log("limited:", limited)
              this[target][input][type] = parseFloat(limited.toFixed(2))  
            } else {
              const limited = tv > t.max ? parseFloat(t.max) : parseFloat(tv)
              this[target][input][type] = parseFloat(limited.toFixed(2)) 
            }

            const total = t.max
            var descontoTotal = total - this[target][input][type]
                descontoTotal = descontoTotal < 0 ? 0 : descontoTotal
            const valorRegular = plano.valorParcela
            const percent =  ( descontoTotal * 100 ) / valorRegular
            
            // console.log("total:", total)
            // console.log("descontoTotal:", descontoTotal)
            // console.log("valorRegular:", valorRegular)
            // console.log("percent:", percent)
            this[target][input].percent = percent > 99.99 ? 100 : percent.toFixed(2)
          }

        },600)
      }
    },
    calc () {
      const planos = this.plano.planosPagamento

      if ( this.type == 'mensalidade' ) {
        const entradaMaterial = planos.filter( a => a.grupo == 'ENT' && a.sigla == 'EMD')[0]
        const mensalidadeMaterial = planos.filter( a => a.grupo == 'MENS' && a.sigla == 'MATD')[0]

        const valorEntradaMaterial = entradaMaterial ? parseFloat(entradaMaterial.valorTotal) : 0
        const valorMensalidadeMaterial = parseFloat(mensalidadeMaterial.valorTotal)

        if ( this.calculadora == 'desconto' ) {
          const hasEntrada = this.desconto.entrada.value 
          const hasMensalidade = this.desconto.mensalidade.value

          if ( !hasEntrada && !hasMensalidade ) {
            window.toaster.emit('open', {style: "error", message: "Preencha ao menos um valor para calcular.", floater: true})
          } else {
            if ( hasEntrada ) {
              const t = this.desconto.entrada
              const plano = planos.filter( a => a.grupo == t.grupo && a.sigla == t.sigla)[0]

              plano.valorParcelaDesconto = plano.valorParcela - t.value
              plano.valorTotalDesconto = plano.valorParcelaDesconto * plano.numeroParcelas
            }
            if ( hasMensalidade ) {
              const t = this.desconto.mensalidade
              const plano = planos.filter( a => a.grupo == t.grupo && a.sigla == t.sigla)[0]

              plano.valorParcelaDesconto = plano.valorParcela - t.value
              plano.valorTotalDesconto = plano.valorParcelaDesconto * plano.numeroParcelas
            }

            const valorTotalDesconto = planos.reduce( (acc, item) => acc + parseFloat(item.valorTotalDesconto), 0)
            const desconto = (100 - ( ( valorTotalDesconto * 100 ) / this.plano.valorTotal )).toFixed(2)
            
            this.plano.valorTotalDesconto = valorTotalDesconto
            this.plano.desconto = desconto

            this.plano = MatriculaService.processPlanos([this.plano])[0]
            // console.log("this.plano:", this.plano)
          }
        }

        if ( this.calculadora == 'valor' ) {
          const hasEntrada = this.valor.entrada.value 
          const hasMensalidade = this.valor.mensalidade.value
          
          if ( hasEntrada ) {
            const t = this.valor.entrada
            const plano = planos.filter( a => a.grupo == t.grupo && a.sigla == t.sigla)[0]

            plano.valorParcelaDesconto = t.value - ( valorEntradaMaterial / plano.numeroParcelas )
            plano.valorTotalDesconto = plano.valorParcelaDesconto * plano.numeroParcelas
          }

          if ( hasMensalidade ) {
            const t = this.valor.mensalidade
            const plano = planos.filter( a => a.grupo == t.grupo && a.sigla == t.sigla)[0]

            plano.valorParcelaDesconto = t.value - ( valorMensalidadeMaterial / plano.numeroParcelas )
            plano.valorTotalDesconto = plano.valorParcelaDesconto * plano.numeroParcelas
          }

          const valorTotalDesconto = planos.reduce( (acc, item) => acc + parseFloat(item.valorTotalDesconto), 0)
          const desconto = (100 - ( ( valorTotalDesconto * 100 ) / this.plano.valorTotal )).toFixed(2)
          
          this.plano.valorTotalDesconto = valorTotalDesconto
          this.plano.desconto = desconto

          this.plano = MatriculaService.processPlanos([this.plano])[0]
        }
      }

      if ( this.type == 'anuidade' ) {
        const anuidadeMaterial = planos.filter( a => a.grupo == 'MENS' && a.sigla == 'MAV')[0]
        
        const valorAnuidadeMaterial = parseFloat(anuidadeMaterial.valorTotal) 

        if ( this.calculadora == 'desconto' ) {
          const t = this.desconto.anuidade
          const plano = planos.filter( a => a.grupo == t.grupo && a.sigla == t.sigla)[0]

          console.log("t:", t)
          plano.valorParcelaDesconto =  plano.valorParcela - t.value
          plano.valorTotalDesconto = plano.valorParcelaDesconto
          
          const valorTotalDesconto = planos.reduce( (acc, item) => acc + parseFloat(item.valorTotalDesconto), 0)
          const desconto = (100 - ( ( valorTotalDesconto * 100 ) / this.plano.valorTotal )).toFixed(2)
          console.log("valorTotalDesconto:", valorTotalDesconto)
          console.log("desconto:", desconto)

          this.plano.valorTotalDesconto = valorTotalDesconto
          this.plano.desconto = desconto
        }

        if ( this.calculadora == 'valor' ) {
          const t = this.valor.anuidade
          console.log("t:", t)
          const plano = planos.filter( a => a.grupo == t.grupo && a.sigla == t.sigla)[0]

          plano.valorParcelaDesconto = t.value - valorAnuidadeMaterial
          plano.valorTotalDesconto = plano.valorParcelaDesconto

          const valorTotalDesconto = planos.reduce( (acc, item) => acc + parseFloat(item.valorTotalDesconto), 0)
          const desconto = (100 - ( ( valorTotalDesconto * 100 ) / this.plano.valorTotal )).toFixed(2)
          
          this.plano.valorTotalDesconto = valorTotalDesconto
          this.plano.desconto = desconto
        }

        this.plano = MatriculaService.processPlanos([this.plano])[0]
      }

      
    },
    clear () {
      this.plano = Object.assign({}, JSON.parse(JSON.stringify(this.scope)))
      this.desconto = Object.assign({}, JSON.parse(JSON.stringify(this.bkpDesconto)))
      this.valor = Object.assign({}, JSON.parse(JSON.stringify(this.bkpValor)))
      // this.calcTimeout = null

      this.$nextTick(() => {
        this.setup()
      })
    },
    close () {
      window.pagescroll.emit('unlock')
      this.$emit('onClose')
    }
  }
}
</script>

<style lang="scss" scoped>
  .simulador {
    display: block;
    width: calc(100vw - 60px); height: calc(100vh - 60px);
    position: fixed;
    top: 60px; left: 60px;
    z-index: 300;
    backdrop-filter: blur(10px);
    background-color: $color-bg-transparent;
    padding: $mg;
    overflow-y: auto;
    @extend %scrollbar;

    > div {
      display: flex; 
      justify-content: flex-start; align-items: flex-start;
      gap: $mg;
      max-width: 1024px; margin: auto;
    }
  }
  .plano {
    display: flex; align-items: stretch;
    flex-direction: column; flex: 1;
    border-radius: $border-radius-large;
    border: $border-component; 
    background-color: $color-secondary;
    list-style: none; margin: 0;
    position: relative; padding-bottom: $hmg;
    
    @media screen and (max-width: 1024px) {
      width: 100%;
    }

    >div {
      &:first-child {
        padding: $hmg;
      }
      padding: 0 $hmg;

      >h2 { 
        max-width: 70%;
        @media screen and (max-width: 1024px) {
          max-width: 100%;
        }
      }

      .valorTotal {
        p {
          margin: 0; padding: 0;

          &:last-child {
            font-size: $font-size-larger
          }
        }
      }
      
      .discountMessage {
        display: flex; align-items: center;
        font-weight: bold; gap: $hmg_mini;
        margin: $hmg_mid 0 0 0;

        .icon {
          font-size: 20px;
          color: $color-success-2;
        }
      }
    }

    a:not(.toggleCalc) {
      display: flex;
      width: 100%;
      height: 50px;
      align-items: center;
      align-content: center;
      justify-content: center;
      background-color: $color-primary;
      margin-top: $hmg_mid;
      font-size: 18px; text-transform: uppercase; font-weight: bold;
      color: $color-secondary;
      border-bottom-left-radius: $border-radius-large;
      border-bottom-right-radius: $border-radius-large;
      @extend %transition_4e;

      &:hover {
        height: 60px;
      }
    }

    &.selected {
      border: 1px solid $color-success-2 !important;

      a:not(.toggleCalc) {
        background-color: $color-success-2;
      }
    }

    .planoBox {
      border-radius: $border-radius;
      border: $border-component;
      overflow: hidden;
      margin-bottom: $hmg_mid;

      &:last-child {
        margin-bottom: 0;
      }

      header {
        padding: $hmg_small;
        border-bottom: $border-component;
        font-size: 20px; font-weight: bold;
        text-align: center !important;
        background-color: $color-bg
      }
      > div {
        padding: $hmg_mid;
      }
    }

    .btn-check {
      width: calc(100% + $mg_mid);
      margin-top: $hmg; margin-left: -$hmg_small;
    }

    h2 {
      margin-bottom: $hmg;
    }

    .planosContent {
      display: flex; align-content: center; align-items: center;
      justify-content: space-between;
      width: 100%;

      >span {
        &:last-child {
          text-align: right;
        }
        &:first-child {
          text-align: left !important;
        }
        p {
          font-size: $font-size-larger; margin: 0;
        }

        &.large {
          p {
            font-size: 16px;
          }
        }
      }
    }

    .planoCardHeader {
      margin-bottom: $hmg_mini;

      h5 {
        margin: 0;
      }
    }

    .planoCard {
      width: calc( 100% + $mg_mid );
      margin-left: -$hmg_small;
      background-color: $color-secondary;
      border-radius: $border-radius;
      padding: $hmg_small;
      position: relative;

      footer {
        height: 37px; width: 100%;
        position: absolute; bottom: 0; left: 0;
        background-color: $color-success-2;
        display: flex; align-content: center; align-items: center; justify-content: space-between;
        font-weight: bold;
        padding: 0 $hmg_small;
        border-bottom-left-radius: $border-radius; border-bottom-right-radius: $border-radius;

        p {margin: 0; white-space: nowrap;}
      }

      &.hasFooter {
        padding-bottom: calc($hmg_small + 37px);
      }
    }

    

    .btDiscountDetails {
      font-size: 20px;

      .icon {
        @extend %transition_4e;
      }

      &:hover {
        .icon {
          transform: scale(1.1);
        }
      }
    }

    .planoDetails {
      width: 100%; margin-bottom: $hmg_mid;
    }

    .due {
      font-size: $font-size-larger;
      margin-top: $mg;

      @media screen and ( max-width: 640px ) {
        margin-top: $hmg;
      }

      p {
        margin: 0
      }
    }
  }

  .counterTag {
    // position: absolute; top: 0; left: 0;
    padding: $hmg_mini $hmg_small;
    border-radius: $border-radius;
    transform: translateY(-100%);
    margin-left: -$hmg_small;
    background-color: $color-secondary;
    @extend %shadow-low-light;
  }

  .months {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $mg_mini;
    
    >p{
      margin: 0; padding: 0;
    }
    
    >div {
      flex: 1;
      position: relative;

      span {
        width: 100%; height: 1px;
        background-color: $color-bg;
        display: block;
      }
      p {
        margin: 0;
        padding: 0;
        position: absolute;
        left: 0; right: 0;
        margin: auto;
        transform: translateY(10px);
        font-weight: bold;
        text-align: center;
      }
    }
  }

  .price {
    display: flex; width: 100%;
    margin-top: $hmg;
    align-items: flex-start; align-content: flex-start;
    justify-content: space-between;
    // gap: $hmg_mid;

    > div {

      &:first-child {
        p {
          color: $color-mid-grey;
          padding-right: $hmg_mini
        }
      }

      &:last-child{
        text-align: right;

        p {
          padding-left: $hmg_mini
        }
      }

      p {
        display: block;
        margin: 0; padding: 0;  

        &:nth-child(1) {
          font-weight: bold;
          margin-bottom: 3px;
        }
        &:nth-child(2) {
          font-size: $font-size-larger;
          border-bottom: $border-component;

          span {
            color: $color-success-2
          }
        }
        &:nth-child(3) {
          margin-top: 3px;
        }
        &:last-child {
          border-bottom: 0 !important;

          span {
            color: $color-success-2
          }
        }
      }
    }
  }

  .calculadora {
    width: 40%; min-width: 320px;
    padding-top: $hmg;

    nav {
      display: flex; align-items: center;
      gap: $mg_mini;
      margin-bottom: $hmg;

      div {
        margin-left: $hmg_mid;
        line-height: 1;
        display: flex;
        align-items: center;
      }
    }
    
    footer {
      display: flex; align-items: center;
      gap: $mg_mini; padding-top: $hmg_mid;
      border-top: $border-component;
    }

    >div {
      margin-bottom: $hmg_mid;

      header {
        margin-bottom: $hmg;
        padding-left: $hmg_small;

        p {
          margin: 0; padding: 0
        }
      }

      >div {
        border-top: $border-component;
        margin-top: $hmg_mid;
        padding-top: $hmg_mid;
      }
       
      h5 {
        padding-left: $mg_small;
        color: $color-primary
      }
      span {
        flex: 1;

        p {
          padding: $mg_mini 0 0 $hmg_small;
          margin: 0;
        }
      }
      
      .form-group {
        display: flex;
        align-items: center;
        gap: $mg_mini;
        position: relative;

        a {
          display: flex; justify-content: center;
          align-content: center; align-items: center;
          height: 34px; width: 34px;
          font-size: 16px;
          text-align: center; line-height: 1;
          border-radius: 2em;
          background-color: $color-primary;
          color: $color-secondary;
          margin-bottom: $hmg_mini;

          &:hover {
            transform: scale(1.1);
            @extend %shadow-low-primary
          }
        }
      }
    }
  }

  .btnClose {
    position: absolute;
    top: $mg; right: $mg;
  }
</style>