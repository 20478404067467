<template>
  <controller :goToTopButton="true">
    <div class="page-title">
      <h3 class="text-capitalize">Séries</h3>
      <transition name="slideRightToLeft">
        <a href="#" @click.prevent="toggleNew( true )" class="btn btn-primary" v-if="!novo">
          <span>Nova Série</span>&nbsp;
          <fa :icon="['fas', 'screen-users']"></fa>
        </a>
      </transition>
    </div>
    <div class="page-inner">
      <transition name="slideup-small">
        <serie v-if="novo || editar" :scope="serie" @onCancel="cancelCreateEdit" @onSave="onSave"></serie>
        <filter-controller @onFilter="getSeries" ref="filter" :filterScope="filter" v-else></filter-controller>
      </transition>
      <series v-if="series && !novo && !editar" :scope="series" @onSelect="editSerie" @onSave="refreshFilter()"></series>
    </div>
  </controller>
</template>

<script>
import Series from '@/components/estruturaacademica/series/form/Series.vue'
import Serie from '@/components/estruturaacademica/series/form/Serie.vue'
import FilterController from '@/components/estruturaacademica/series/utilities/FilterSeries.vue'
import AcademicoService from '@/services/academico.service.js'

export default {
  components: {
    Series, Serie, FilterController
  },
  props: {
    action: {
      type: String
    }
  },
  data () {
    return {
      novo: false,
      editar: false,
      series: null,
      serie: null,
      filter: null,
      model: {
        idUnidade: null,
        idTurno: null,
        idCurso: null,
        idPeriodoLetivo: null,
        descricao: null,
        serie: 0,
        ativa: false,
        nomeExibicao: null,
        apresentaFormLead: null,
        valorAnuidade: null,
        idTipoMaterial: null,
        savCodigo: null,
        savNome: null,
        cC1Codigo: null,
        cC1Nome: null,
        cC2Codigo: null,
        cC2Nome: null
      }
    }
  },
  mounted () {
    if ( this.action ) {
      if ( this.action == 'editar' ) {
        this.$router.push({name: 'Estrutura Acadêmica - Séries'})
      } else if ( this.action == 'nova') {
        this.toggleNew()
      }
    }
  },
  methods: {
    getSeries (filter) {
      if ( filter ) {
        this.filter = filter
      }

      window.spinner.emit("open")
      
      AcademicoService.getSeries(this.filter.periodoLetivo, this.filter.tipoCurso).then(
        response => {
          console.log("getSeries:", response.data)
          if ( response.data.length > 0 ) {
            this.series = response.data
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhuma série encontrada para este período letivo. Isto parece ser um problema de parametrização.", floater: true})
          }

          window.spinner.emit("close")
        },
        () => {
          window.spinner.emit("close")
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma série encontrada para este período letivo. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    editSerie ( serie ) {
      console.log("editSerie:", serie)

      window.spinner.emit("open")
      AcademicoService.getSerie( serie.idSerie ).then(
        response => {
          console.log("getSerie:", response.data)
          this.serie = response.data
          this.editar = true

          this.$router.push({name: 'Estrutura Acadêmica - Séries', params: { action: 'editar', target: serie.idSerie } })
          window.spinner.emit("close")
        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível visualizar os detalhes desta série.", floater: true})
          window.spinner.emit("close")
        }
      )
    },
    toggleNew ( route ) {
      this.editar = false
      this.novo = false
      this.serie = null

      this.$nextTick(() => {
        this.serie = this.model
        this.novo = true

        if ( route ) {
          this.$router.push({name: 'Estrutura Acadêmica - Séries', params: { action: 'criar'} })
        }
      })
    },
    cancelCreateEdit ( refresh ) {
      this.serie = null
      this.novo = false
      this.editar = false

      this.$router.push({name: 'Estrutura Acadêmica - Séries'})

      // console.log("refresh?", refresh)
      if ( refresh ) this.refreshFilter()
      else this.filter = null
    },
    refreshFilter () {
      if ( this.filter ) {
        this.getSeries()
      }
    },
    onSave () {
      this.cancelCreateEdit()
      // this.refreshFilter()
    }
  }
}
</script>

<style>

</style>