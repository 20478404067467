<template>
  <section>
    <header>
      <h5>
        Filtrar Conteúdos Programáticos
      </h5>
    </header>
    <div class="frame fixed mid">
      <div class="row no-padd" ref="produtoFilterForm">
        <div class="col-md-6 form-group">
          <label>Matérias</label>
          <select-control v-model="filter.materia" name="materia" :options="options.materias" placeholder="Selecione" :disabled="loading"/>
        </div>
        <div class="col-md-4 form-group">
          <label>Período Letivo</label>
          <select-control v-model="filter.periodoLetivo" name="periodoLetivo" :options="options.periodosLetivos" placeholder="Selecione" :disabled="loading" />
        </div>
        <div class="col-md-12 form-group">
          <label>Conteúdo</label>
          <input-control v-model="filter.conteudo" name="conteudo" placeholder="Ex. Ácidos Nucléicos" :disabled="loading" />
        </div>
      </div>
      <div class="col-md-12 form-actions form-group">
        <a href="#" @click.prevent="runFilter()" class="btn btn-primary">
          Buscar
        </a>
        <a href="#" @click.prevent="clearFilter()" class="btn btn-secondary">
          Limpar
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import EstudAIService from '@/services/estudai.service.js'
import { createSelectOptions } from '@/services/utils.service.js'

export default {
  data () {
    return {
      filter: {
        materia: null,
        periodoLetivo: null,
        conteudo: null
      },
      wait: {
        materia: false,
        periodoLetivo: false,
      },
      options: {
        materias: [],
        periodosLetivos: []
      },
      loading: false
    }
  },
  mounted () {
    this.getMaterias()
    this.getAnos()

    window.spinner.emit("open")
  },
  methods: {
    getMaterias () {
      EstudAIService.getMaterias().then(
        response => {
          console.log("getMaterias:", response.data)

          if ( response.data.length > 0 ) {
            this.options.materias = createSelectOptions(response.data, 'idMateria' ,'nomeMateria')
            this.wait.materia = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhuma matéria encontrada. Isto parece ser um erro de parametrização.", floater: true})
          }
          
          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma matéria encontrada.", floater: true})
        }
      )
    },
    getAnos () {
      EstudAIService.getAnos().then(
        response => {
          console.log("getAnos:", response.data)

          if ( response.data.length > 0 ) {
            this.options.periodosLetivos = createSelectOptions(response.data, 'ano' ,'ano').reverse()
            this.wait.periodoLetivo = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum ano encontrado. Isto parece ser um erro de parametrização", floater: true})
          }
          
          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum ano encontrado.", floater: true})
        }
      )
    },
    finishLoad () {
      const finished = !Object.values(this.wait).some( a => !a)

      if ( finished ) {
        window.spinner.emit("close")
        this.loading = false
      }
      // console.log("finished:", finished)

    },
    runFilter () {
      window.spinner.emit("open")

      EstudAIService.getConteudosProgramaticos( this.filter ).then(
        response => {
          console.log("getConteudosProgramaticos:", response.data)
          if ( response.data.length > 0 ) {
            this.$emit("onFilter", response.data)
          } else {
            window.toaster.emit('open', {style: "error", message: "Não encontramos nenhum conteúdo programático com os dados informados.", floater: true})
          }
          window.spinner.emit("close")
        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível buscar conteúdos programáticos.", floater: true})
          window.spinner.emit("close")
        }
      )
    },
    clearFilter () {
      this.filter = {
        materia: null,
        periodoLetivo: null,
        conteudo: null
      }
      this.$emit("onClear")
    }
  }
}
</script>

<style scoped lang="scss">
  header {
    padding: $hmg $hmg $hmg_small;

    h5 {
      margin: 0 0 $hmg_mini 0; padding: 0;
    }
    p {
      margin: 0; padding: 0;
    }
  }

  // .frame {
  //   padding: 0 $hmg_small
  // }
  .form-actions {
    margin-top: $hmg !important
  }
</style>