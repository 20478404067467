<template>
  <div class="row no-padd">
    <div class="col-md-12 form-group no-padd-left" v-if="matricula">
      <ul class="checkbox-card-list">
        <li>
          <label class="check-control full">
            <input type="checkbox" class="check" :value="1" v-model="matricula.fichaSaida.valeTransporte.sptrans" @change="solicita()" :disabled="action == 'ver'">
            <span>SPTrans</span>
          </label>
        </li>
        <li>
          <label class="check-control full">
            <input type="checkbox" class="check" :value="1" v-model="matricula.fichaSaida.valeTransporte.bom" @change="solicita()" :disabled="action == 'ver'">
            <span>Cartão Bom</span>
          </label>
        </li>
        <li>
          <label class="check-control full">
            <input type="checkbox" class="check" :value="1" v-model="matricula.fichaSaida.valeTransporte.naoSolicitado" @change="naoSolicita()" :disabled="action == 'ver'">
            <span>Não deseja solicitar</span>
          </label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    action: {
      type: String
    }
  },
  data () {
    return {
      matricula: null
    }
  },
  mounted () {
    this.$store.dispatch('matricula/get').then(response => {
      this.matricula = response
    })
  },
  methods: {
    solicita () {
      if ( this.matricula.fichaSaida.valeTransporte.sptrans || this.matricula.fichaSaida.valeTransporte.bom ) {
        this.matricula.fichaSaida.valeTransporte.naoSolicitado = false
      }
    },
    naoSolicita () {
      if ( this.matricula.fichaSaida.valeTransporte.naoSolicitado ) {
        this.matricula.fichaSaida.valeTransporte.sptrans = false
        this.matricula.fichaSaida.valeTransporte.bom = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>