<template>
  <div class="card-solicitacao">
    <header>
      <div>
        <b>{{ props.idSolicitacaoCancelamento }}</b>
        <div>
          <fa :icon="['fal', 'cart-shopping']"></fa>
          <p>{{ props.idCheckoutMarketplace }}</p>
        </div>
        <b class="uni">{{ props.sigla }}</b>
      </div>
      <div>
        <div>
          <fa :icon="['fal', 'calendar']"></fa>
          <p>{{ formatDate(props.dataAbertura) }}</p>
        </div>
        <template v-if="props.concluida && props.itensDevolvidos">
          <span class="done" title="Concluído">
            <fa :icon="['fas','check']" />
          </span>
        </template>
        <template v-else-if="props.validado == 0">
          <span class="invalid" title="Recusado">
            <fa :icon="['fas','xmark']" />
          </span>
        </template>
        <template v-else>
          <div :class="['timeframe', { alert1: ( inAction >= alert1 && inAction < alert2 ) , alert2: inAction >= alert2 }]">
            <fa :icon="['fal', 'calendar-range']"></fa>
            <p>{{ inAction + ( inAction == 1 ? " dia" : " dias" ) }}</p>
          </div>
          <template v-if="status == 'andamento'">
            <div :class="['timeframe', { alert1: ( inStatus >= alert1 && inStatus < alert2 ) , alert2: inStatus >= alert2 }]">
              <fa :icon="['fal', 'arrow-progress']"></fa>
              <p>{{ inStatus + ( inStatus == 1 ? " dia" : " dias" ) }}</p>
            </div>
          </template>
        </template>
      </div>
    </header>
    <main>
      <h4 class="color-text">{{ props.descricaoMotivoCancelamento }}</h4>
      <p v-if="props.observacaoAbertura">{{ props.observacaoAbertura }}</p>
      <ul class="values-info">
        <li>
          <fa :icon="['fal', 'money-bill-transfer']"></fa>
          <p><b>Compra: </b>{{ formatMoney(props.valorvenda) }}</p>
        </li>
        <li>
          <fa :icon="['fal', 'money-from-bracket']"></fa>
          <p><b>Cancelado: </b>{{ formatMoney(props.valorCancelamento) }}</p>
          <span>{{ props.qnt_itens + ( props.qnt_itens == 1 ? ' item' : ' itens' ) }}</span>
        </li>
      </ul>
      <ul class="user-info">
        <li>
          <fa :icon="['fal', 'user-hair']"></fa>
          <p><b>Solicitante: </b>{{ props.nomePessoaAbertura }}</p>
        </li>
      </ul>
    </main>
    <footer>
      <div>
        <template v-if="active == 'atendimento' && solicitaCancelamento_at">
          <a href="#" class="btn btn-alert" @click.prevent="engage('atendimento')">Ver detalhes</a>
        </template>
        <template v-if="active == 'financeiro' && solicitaCancelamento_cr">
          <a href="#" class="btn btn-success-2" @click.prevent="engage('financeiro')">Ver detalhes</a>
        </template>
        <template v-if="( active == 'devolucao' || section == 'filtro' ) && solicitaCancelamento_sec && !props.itensDevolvidos">
          <template v-if="props.validado == 0">
            <a href="#" class="btn btn-secondary disabled">Solicitação Recusada</a>
          </template>
          <template v-else>
            <a href="#" class="btn btn-primary" @click.prevent="toggleRecebimento(true)">Receber</a>
            <a href="#" class="btn btn-secondary" @click.prevent="printTag()">Imprimir Etiqueta</a>
          </template>
        </template>
      </div>
      <div>
        <span class="pill" v-if="props.envioTrf">
          <b>COBRANÇA GERADA</b>
        </span>
      </div>
      <ul class="operadores" v-if="props.emailPessoaAtendimento || props.emailPessoaFinanceiro">
        <li v-if="props.emailPessoaAtendimento">
          <h4>{{ initials(props.emailPessoaAtendimento) }}</h4>
        </li>
        <li v-if="props.emailPessoaFinanceiro">
          <h4>{{ initials(props.emailPessoaFinanceiro) }}</h4>
        </li>
      </ul>
    </footer>
    <modal ref="modal">
      <div class="confirmRecebimento">
        <h3>Digite o código da solicitação</h3>
        <input-control v-model="codigoPedidoRecebimento" cssClass="invert text-center large" type="number" placeholder="123456"></input-control>
        <template v-if="codigoPedidoValido">
          <transition name="slideup-small">
            <span class="msgValidacaoCodigo valido" v-if="codigoPedidoValido == 'valido'">
              Código validado!
            </span>
          </transition>
          <transition name="slideup-small">
            <span class="msgValidacaoCodigo invalido" v-if="codigoPedidoValido == 'invalido'">
              Código inválido!
            </span>
          </transition>
        </template>
        <footer>
          <a href="#" @click.prevent="validateRecebCode()" class="btn btn-primary">Validar</a>
          <a href="#" @click.prevent="toggleRecebimento()" class="btn btn-secondary">Cancelar</a>
        </footer>
      </div>
    </modal>
  </div>
</template>

<script>
import { formatDate, formatMoney, formatDateTime } from '@/services/utils.service.js'
import moment from 'moment'
import Modal from "@/components/utilities/Modal"
import MarketplaceService from '@/services/marketplace.service.js'
import UserService from '@/services/user.service.js'

export default {
  components: {
    Modal
  },
  props: {
    props: {
      type: Object
    },
    active: {
      type: [String, Number]
    },
    status: {
      type: String
    },
    section: {
      type: String
    }
  },
  watch: {
    active ( value ) {
      if ( value ) {
        this.calcPeriodo()
      }
    }
  },
  data () {
    return {
      inAction: null,
      inStatus: null,
      alert1: 2,
      alert2: 5,
      codigoPedidoRecebimento: null,
      codigoPedidoValido: null,
      solicitaCancelamento_at: false,
      solicitaCancelamento_cr: false,
      solicitaCancelamento_sec: false
    }
  },
  mounted () {
    this.calcPeriodo()

    this.solicitaCancelamento_at = UserService.filterPermissions('solicitaCancelamento_at')
    this.solicitaCancelamento_cr = UserService.filterPermissions('solicitaCancelamento_cr')
    this.solicitaCancelamento_sec = UserService.filterPermissions('solicitaCancelamento_sec')
  },
  methods: {
    calcPeriodo () {
      this.inAction = moment().diff(this.props.dataAbertura,'days')

      if ( this.active == 'atendimento' ) {
        if ( this.props.dataTratativaFimAtendimento ) {
          this.inStatus = moment(this.props.dataTratativaFimAtendimento).diff(this.props.dataInicioTratativaAtendimeto,'days')
        } else {
          this.inStatus = moment().diff(this.props.dataInicioTratativaAtendimeto,'days')
        }
      }
      if ( this.active == 'financeiro' ) {
        if ( this.props.dataFimTratativaCr ) {
          this.inStatus = moment(this.props.dataFimTratativaCr).diff(this.props.dataTratativaInicioCr,'days')
        } else {
          this.inStatus = moment().diff(this.props.dataTratativaInicioCr,'days')
        }
      }
      if ( this.active == 'devolucao' ) {
        //
      }
    },
    formatDate( date ) {
      return formatDate(date)
    },
    formatDateTime( date ) {
      return formatDateTime(date)
    },
    formatMoney( value ) {
      return formatMoney(value)
    },
    initials ( email ) {
      if ( email ) {
        const parsed = email.split("@")[0].split(".")
        
        const first = parsed[0].split("")[0]
        const last = parsed[parsed.length - 1].split("")[0]

        return first + last
      }
    },
    engage (target) {
      const id = this.props.idSolicitacaoCancelamento
      this.$emit("onEngage", {target, id})
    },
    toggleRecebimento ( state ) {

      if ( state ) {
        const options = {
          size: "mid", //small, mid, normal, large, full
          backdrop: true,
          backdropColor: "transparent", 
          backdropClose: true,
          // fullHeight: true,
          onClose: this.toggleRecebimento(false)
        }
        this.$refs.modal.open(options)
      } else {
        this.cancelReceb()
        this.$refs.modal.close()
      }
    },
    cancelReceb () {
      this.codigoPedidoRecebimento = null,
      this.codigoPedidoValido = null
    },
    validateRecebCode () {
      if ( this.codigoPedidoRecebimento == this.props.idSolicitacaoCancelamento ) {
        this.codigoPedidoValido = 'valido'

        setTimeout(() => {
          this.receber()
        },1000)
        
      } else {
        this.codigoPedidoValido = 'invalido'
      }
    },
    receber () {
      window.spinner.emit("open")
      // console.log('this.props:', this.props)

      MarketplaceService.receberItensCancelamento( this.props.idSolicitacaoCancelamento, { "itensDevolvidos" : this.props.qnt_itens }).then(
        response => {
          if ( response.status == 200 ) {
            window.toaster.emit('open', {style: "success", message: "Tudo certo, produto recebido com sucesso", floater: true})
            this.$emit("onSave", response.data)
          }
          window.spinner.emit("close")
        },
        () => {
          window.spinner.emit("close")
          window.toaster.emit('open', {style: "error", message: "Erro ao tentar receber este pedido. Se o problema persistir, procure o atendimento.", floater: true})
        }
      )
    },
    printTag () {
      // console.log('props:', this.props )
      this.$router.push({ name: 'Etiqueta Cancelamento', params: { solicitacao: this.props.idSolicitacaoCancelamento }})
    }
  }
}
</script>

<style lang="scss" scoped>
  .card-solicitacao {
    background-color: $color-secondary;
    border-radius: $border-radius;
    padding: $hmg_mid;
    overflow: hidden;
    // display: grid;
    // grid-template-columns: 1.5fr 4fr .1fr;

    > header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $hmg_small;

      >div {
        display: flex;
        align-items: center;
        gap: $hmg_small;

        b {
            min-width: 24px; height: 24px;
            padding: 0 $mg_mini;
            border-radius: 2em;
            display: flex; flex-direction: column; justify-content: center;
            line-height: 1;
            background-color: $color-primary;
            color: $color-secondary;            
            text-align: center;

            &.uni {
              background-color: $color-bg;
              color: $color-text;
            }
        }
        p {
          margin: 0; padding: 0;
        }

        >div {
          display: flex;
          align-items: center; align-content: center;

          p {
            margin: 0 0 0 $hmg_mini; padding: 0;
            line-height: 1;
          }
          
          &.timeframe {
            padding: $hmg_mini $mg_mini;
            border-radius: 2em;
            background-color: $color-bg;

            &.alert1 {
              background-color: $color-warning;
              color: $color-secondary
            }
            &.alert2 {
              background-color: $color-error;
              color: $color-secondary
            }
          }
        }
      }
      
      h6 {
        color: $color-text
      }
    }

    main {
      ul {
        padding: 0; margin: $hmg_small 0 0 0;

        &.values-info {
          display: flex; align-items: center;
          gap: $hmg_small;

          span {
            padding: $hmg_mini $mg_mini;
            border-radius: 2em;
            background-color: $color-bg;
            text-align: center;
            line-height: 1;
            // display: flex; flex-direction: column;
            // align-content: center; justify-content: center;
          }
        }

        &.user-info {
          border-top: 1px solid $color-bg;
          border-bottom: 1px solid $color-bg;
          padding: $mg_mini 0;
        }

        li {
          list-style: none; padding: 0;
          display: flex; align-items: center;
          gap: $hmg_mini;

          p {
            margin: 0; padding: 0;
          }
        }
      }
    }

    footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: $hmg_small;

      >div {
        display: flex;
        align-items: center;
        padding: 0; margin: 0;
        gap: $mg_mini;
      }

      ul.operadores {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0; margin: 0;
        gap: $mg_mini;
        
        li {
          width: 40px; height: 40px;
          border-radius: 100%;
          display: flex; flex-direction: column;
          line-height: 1; text-align: center;
          list-style: none;
          background-color: $color-bg;
          justify-content: center;
          gap: $mg_mini;

          h4 {
            color: $color-text;
            text-align: center;
            margin: 0; padding: 0;
            line-height: 1;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .confirmRecebimento {
    text-align: center;
    
    h3 { 
      margin-bottom: $mg_mid
    }

    .msgValidacaoCodigo { 
      display: block;
      width: 100%;
      margin-top: $hmg;
      padding: $hmg_small $mg_mid;
      border-radius: $border-radius;
      text-align: center;
      color: $color-secondary;
      @extend %transition_4e;

      &.valido {
        background-color: $color-success-2
      }
      &.invalido {
        background-color: $color-alert
      }
    }

    footer {
      display: flex;
      align-content: center; align-items: center;
      gap: $hmg_small;
      margin-top: $hmg;
      justify-content: center;
    }
  }

  .done {
    display: flex; flex-direction: column;
    align-content: center; align-items: center;  justify-content: center;
    width: 30px; height: 30px;
    border-radius: 100%;
    font-size: 18px;
    background-color: $color-success-2;
    color: $color-secondary
  }

  .invalid {
    display: flex; flex-direction: column;
    align-content: center; align-items: center;  justify-content: center;
    width: 30px; height: 30px;
    border-radius: 100%;
    font-size: 18px;
    background-color: $color-primary;
    color: $color-secondary
  }
</style>