<template>
  <section>
    <header>
      <h2>Minhas Compras {{ item && ' - ' + item }}</h2>
      <a href="#" class="btn btn-secondary" @click.prevent="toggleDetails(false)" v-if="details">
        <fa :icon="['far', 'arrow-left-long']"></fa>
        <span> Voltar</span>
      </a>
    </header>
    <transition name="fadein">
      <div class="pageDetails detailBoxWrap" v-if="item">
        <transition name="slidedown-small">
          <div class="detailBox over" v-if="details">
            <div>
              <header>
                <h3>Você comprou ({{ vendas && vendas.length > 0 && vendas.length + ( vendas.length > 1 ? ' itens' : ' item' ) }})</h3>
                <transition-group name="slideLeftToRightSmall">
                  <a href="#" @click.prevent="toggleCancel(false)" class="btn btn-secondary" v-if="cancelActive" :key="1">
                    Cancelar Solicitação
                    <fa :icon="['far','cart-xmark']"></fa>
                  </a>
                  <a href="#" @click.prevent="toggleCancel(true)" class="btn btn-primary" :key="2" v-else>
                    Solicitar cancelamento/devolução
                    <fa :icon="['far','cart-xmark']"></fa>
                  </a>
                </transition-group>
              </header>
              <transition name="slidedown-small">
                <div class="cancelMessage" v-if="cancelActive">
                  <p>Selecione na lista abaixo os itens que deseja cancelar ou devolver. Só estarão disponíveis para seleção os itens dentro do prazo de cancelamento.</p>
                </div>
              </transition>
              <table class="content-table bordered-list">
                <thead>
                  <template v-if="!isMobile">
                    <tr>
                      <th>Produto</th>
                      <th>Aluno</th>
                      <th class="text-right">Valor</th>
                      <!-- <th class="text-right">Valor</th> -->
                      <th v-if="cancelActive"></th>
                    </tr>
                  </template>
                  <template v-else>
                    <tr v-for="venda in vendas" :key="venda">
                      <th>Produto</th>
                      <th>Aluno</th>
                      <th class="text-right">Valor</th>
                      
                      <!-- <th class="text-right">Valor</th> -->
                      <th v-if="cancelActive"></th>
                    </tr>
                  </template>
                </thead>
                <tbody>
                  <tr v-for="venda in vendas" :key="venda">
                    <td>{{ venda.descricaovenda }}</td>
                    <td>{{ venda.nome }}</td>
                    <td class="text-right valueField" >{{ formatMoney(venda.valorvenda) }}</td>
                    <td v-if="cancelActive">
                      <label class="check-control" v-if="trackAllowCancel(venda)">
                        <input type="checkbox" class="check invert" v-model="forCancel" :value="venda">
                      </label>
                    </td>
                  </tr>
                  <tr class="totals">
                    <td :colspan="cancelActive ? 4 : 3" class="text-right">
                      <b>Total:</b> {{ formatMoney(getTotal(vendas, 'valorvenda')) }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <transition name="slideLeftToRightSmall">
                <div v-if="cancelActive" class="cancelActions">
                  <a href="#" class="btn btn-primary" @click.prevent="toggleCancelMotive()">
                    Prosseguir com a solicitação
                  </a>
                </div>
              </transition>
            </div>
            <div v-if="pedidos && pedidos.length > 0">
              <header>
                <h3>{{ pedidos.length > 1 ? 'Sua compra gerou os seguintes pedidos' : 'Sua compra gerou o seguinte pedido' }}</h3>
              </header>
              <table class="content-table bordered-list" >
                <thead>
                  <template v-if="!isMobile">
                    <tr>
                      <th>Pedido</th>
                      <th>Aluno</th>
                      <th>Data Compra</th>
                      <th>Previsão Entrega</th>
                      <th>NFe</th>
                      <th class="text-right">Valor</th>
                      <th></th>
                    </tr>
                  </template>
                  <template v-else>
                    <tr v-for="pedido in pedidos" :key="pedido">
                      <th>Pedido</th>
                      <th>Aluno</th>
                      <th>Data Compra</th>
                      <th>Previsão Entrega</th>
                      <th>NFe</th>
                      <th class="text-right">Valor</th>
                      <th></th>
                    </tr>
                  </template>
                </thead>
                <tbody>
                  <template v-for="pedido in pedidos" :key="pedido" >
                    <tr 
                      @click="toggleDetails(true, parseData('codigoPedido', pedido.descricao))" :class="{ active: pedido.collapse }"
                      >
                      <td>{{ parseData('codigoPedido', pedido.descricao) }}</td>
                      <td>{{ parseData('nomeAluno', pedido.descricao) }}</td>
                      <td>{{ pedido.datapedido }}</td>
                      <td>{{ pedido.entregaprevista }}</td>
                      <td>
                        <a :href="pedido.danfe" target="_blank">
                          <fa :icon="['fas','file-invoice-dollar']"></fa>
                        </a>
                      </td>
                      <td class="text-right valueField">{{ formatMoney(pedido.valorPedido) }}</td>
                      <td>
                        <fa :icon="['fas', 'chevron-down']" :class="['icon', { toggleClose: pedido.collapse }]"></fa>
                      </td>
                    </tr>
                    <transition name="fadein">
                    <tr class="details" v-if="pedido.collapse">
                      <td colspan="7">
                        <div class="detailsBox">
                          <div>
                            <h4 v-if="isMobile">Pedido {{ parseData('codigoPedido', pedido.descricao) }}</h4>
                            <img :src="getImageSrc(pedido)" class="detailTitle"/>
                            <table>
                              <thead>
                                <template v-if="!isMobile">
                                  <tr>
                                    <th class="text-left">Item</th>
                                    <th>Valor Unitário</th>
                                    <th>Quantidade</th>
                                    <th>Valor Total</th>
                                  </tr>
                                </template>
                                <template v-else>
                                  <tr v-for="it in pedido.itens" :key="it">
                                    <th class="text-left">Item</th>
                                    <th>Valor Unitário</th>
                                    <th>Quantidade</th>
                                    <th>Valor Total</th>
                                  </tr>
                                </template>
                              </thead>
                              <tbody>
                                <tr v-for="it in pedido.itens" :key="it">
                                  <td class="text-left">{{ it.descricaoGrade ? it.tipomaterial + ' - ' + it.descricaoGrade : it.tipomaterial }}</td>
                                  <td class="valueField">{{ formatMoney(it.valoritem) }}</td>
                                  <td>{{ it.quantidade }}x</td>
                                  <td class="valueField">{{ formatMoney(it.somaValorItens) }}</td>
                                </tr>
                              </tbody>
                            </table>
                            <a href="#" @click.prevent="closeDetails(item)" class="btn btn-primary hide-for-desktop">
                              fechar
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </transition>
                  </template>
                </tbody>
              </table>
            </div>
            <div>
              <header>
                <h3 v-if="selectedCompra.cartao == 1">Essa é a transação do seu cartão</h3>
                <h3 v-else-if="selectedCompra.pix == 1">Esse é seu pix</h3>
                <h3 v-else>{{ selectedCompra.parcelas > 1 ? 'E esses são seus boletos' : 'E esse é seu boleto' }}</h3>
              </header>
              <table class="content-table bordered-list">
                <thead>
                  <template v-if="!isMobile">
                    <tr>
                      <th>Parcela</th>
                      <th>Valor</th>
                      <th>Tipo</th>
                      <th>Vencimento</th>
                      <th>Status</th>
                      <th>Valor Pago</th>
                      <th>Pago em</th>
                      <th></th>
                    </tr>
                  </template>
                  <template v-else>
                    <tr v-for="transacao in transacoes" :key="transacao">
                      <th>Parcela</th>
                      <th>Valor</th>
                      <th>Tipo</th>
                      <th>Vencimento</th>
                      <th>Status</th>
                      <th>Valor Pago</th>
                      <th>Pago em</th>
                    </tr>
                  </template>
                </thead>
                <tbody>
                  <tr v-for="transacao in transacoes.itens" :key="transacao">
                    <td>{{ transacao.parcela ? transacao.parcela : 1 }}</td>
                    <td class="valueField">{{ formatMoney(transacao.valortransacao) }}</td>
                    <td>{{ transacao.cartao == 1 ? 'Cartão' : transacao.boleto == 1 ? 'Boleto' : 'Pix' }}</td>
                    <td>{{ transacao.boleto == 1 ? formatDate(transacao.vencimentoboleto) : '-'  }}</td>
                    <td>{{ getStatus(true, transacao) }}</td>
                    <td class="valueField">{{ transacao.valorpago != 0 ? formatMoney(transacao.valorpago) : '-' }}</td>
                    <td>{{ transacao.pago != 0 && transacao.pagoem ? formatDate(transacao.pagoem) : '-' }}</td>
                    <td class="text-right">
                      <a :href="transacao.boletourl" target="_blank" v-if="transacao.boletourl && transacao.cancelado == 0 || transacao.boletourl && !transacao.deleted_at">
                        <fa :icon="['fas', 'rectangle-barcode']" />
                      </a>
                      <a href="#" @click="clipboardPix(transacao.qrCodePix)" v-if="transacao.boleto == 0 && transacao.cartao == 0 && !transacao.deleted_at">
                        <fa :icon="['fas', 'qrcode']" />
                      </a>
                    </td>
                  </tr>
                  <tr class="totals" v-if="transacoes.length > 1">
                    <td></td>
                    <td>
                      {{ formatMoney(getTotal(transacoes,'valortransacao')) }}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      {{ formatMoney(getTotal(transacoes, 'valorpago')) }}
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="solicitacoes && solicitacoes.length > 0">
              <header>
                <h3>Essas são as suas solicitações de devolução/cancelamento</h3>
              </header>
              <table class="content-table bordered-list">
                <thead>
                  <template v-if="!isMobile">
                    <tr>
                      <th>ID</th>
                      <th>Data</th>
                      <th>Motivo</th>
                      <th>Observação</th>
                      <th>Qtd. Itens</th>
                      <th>Status</th>
                      <th v-if="solicitacoes.idMotivoRecusaCancelamento">Motivo Recusa</th>
                    </tr>
                  </template>
                  <template v-else>
                    <tr v-for="solicitacao in solicitacoes" :key="solicitacao">
                      <th>ID</th>
                      <th>Data</th>
                      <th class="blob">Motivo</th>
                      <th class="blob">Sua observação</th>
                      <th>Qtd. Itens</th>
                      <th>Status</th>
                    </tr>
                  </template>
                </thead>
                <tbody>
                  <tr v-for="(solicitacao, i) in solicitacoes" :key="solicitacao">
                    <td>{{ solicitacao.idSolicitacaoCancelamento }}</td>
                    <td>{{ formatDate(solicitacao.dataAbertura) }}</td>
                    <td class="blob">{{ solicitacao.motivoCancelamentoDescricao }}</td>
                    <td class="blob">{{ solicitacao.observacaoAbertura }}</td>
                    <td>{{ solicitacao.itens.length ?? '-' }}</td>
                    <td class="refusedModalWrap">
                      <a href="#" @click.prevent="toggleInfoCancelamento(true, i)" v-if="solicitacao.validado == 0" class="statusTag infoWrap reprovada">
                        <span>Reprovada</span>
                        <fa :icon="['fas','circle-info']"  class="i"/>
                      </a>
                      <span :class="['statusTag', retrieveCancStatusTag(solicitacao,true) ]" v-else>
                        {{ retrieveCancStatusTag(solicitacao) }}
                      </span>
                      <transition name="slidedown-small">
                        <div class="refusedModal" v-if="solicitacao.motivoRecusa">
                          <header>
                            <h5>Motivo da Recusa</h5>
                            <a href="#" @click.prevent="toggleInfoCancelamento(false, i)">
                              <fa :icon="['far','xmark']" />
                            </a>
                          </header>
                          <p>{{ solicitacao.motivoRecusaCancelamentoDescricao }}</p>
                        </div>
                      </transition>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </transition>
      </div>
    </transition>
    <transition name="fadein">
      <div :class="['pageDetails', { open: details }]">
        <div v-if="loading" class="tableLoader">
          <input-spinner></input-spinner>
        </div>
        <table class="content-table" v-else>
          <thead>
            <template v-if="!isMobile">
              <tr>
                <th>Compra</th>
                <th>Data Compra</th>
                <th>Nome</th>
                <th>Forma de Pagamento</th>
                <!-- <th class="hide-for-mobile"></th> -->
                <th class="text-right">Valor</th>
                <th></th>
              </tr>
            </template>
            <template v-else>
              <tr v-for="item in compras" :key="item">
                <th>Compra</th>
                <th>Data Compra</th>
                <th>Nome</th>
                <th>Forma de Pagamento</th>
                <!-- <th class="hide-for-mobile"></th> -->
                <th class="text-right">Valor</th>
                <th></th>
              </tr>
            </template>
          </thead>
          <tbody>
            <tr class="" v-for="item in compras" :key="item" @click.prevent="gotoItem(item.idcheckoutMarketplace)">
              <td>{{ item.idcheckoutMarketplace }}</td>
              <td>{{ formatDate(item.created_at) }}</td>
              <td>{{ item.nome }}</td>
              <td>{{ item.descricao }}</td>
              <!-- <td class="liner hide-for-mobile"><span></span></td> -->
              <td class="text-right valueField">{{ formatMoney(item.valorvenda) }}</td>
              <td>
                <fa :icon="['far', 'magnifying-glass-plus']" class="icon"></fa>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </transition>
    <modal ref="modal">
      <transition-group name="fadein">
        <motivos-cancelamento @onSave="toggleCancelSteps(0,$event)" v-if="cancelStep == 0 && !savingCancel"></motivos-cancelamento>
        <imagens-cancelamento v-else-if="cancelStep == 1 && !savingCancel" :scope="this.cancel" @onSave="toggleCancelSteps(1,$event)"></imagens-cancelamento>
        <dados-bancarios v-else-if="cancelStep == 2 && !savingCancel" :scope="this.cancel.bank_account" @onSave="toggleCancelSteps(2,$event)"></dados-bancarios>
        <div class="saveSpinner" v-else-if="savingCancel">
          <h4>Aguarde. Estamos criando sua solicitação.</h4>
          <input-spinner class="spn"></input-spinner>
        </div>
        <div class="saveSpinner" v-else>
          <fa :icon="['fal','face-grin-stars']" class="icon"></fa>
          <h4>Solicitação criada com sucesso.</h4>
          <p>Sua compra tem itens para devolução? Para evitar cobrança no valor dos itens cancelados, não se esqueça de devolvê-los no prazo.</p>
          <a href="#" class="btn btn-primary" @click.prevent="closeModal()">Sair</a>
        </div>
      </transition-group>
    </modal>
  </section>
</template>

<script>
import MarketplaceService from '@/services/marketplace.service.js'
import { formatDate, formatMoney } from '@/services/utils.service.js'
import InputSpinner from '@/components/utilities/InputSpinner.vue'
import moment from 'moment'
import Modal from '@/components/utilities/Modal.vue'
import MotivosCancelamento from '@/components/loja/utilities/minhascompras/MotivosCancelamento.vue'
import ImagensCancelamento from '@/components/loja/utilities/minhascompras/ImagensCancelamento.vue'
import DadosBancarios from '@/components/loja/utilities/minhascompras/DadosBancarios.vue'

export default {
  components: {
    InputSpinner, Modal,
    MotivosCancelamento, ImagensCancelamento, DadosBancarios
  },
  props: {
    item: {
      type: [String, Number],
      default: ""
    },
    detail: {
      type: [String, Number],
      default: ""
    }
  },
  data () {
    return {
      compras: [],
      pedidos: [],
      vendas: [],
      transacoes: [],
      solicitacoes: [],
      jumpToItem: false,
      isMobile: false,
      slideLoop: null,
      loading: false,
      details: false,
      selectedCompra: null,
      cancelActive: false,
      forCancel: [],
      cancel: {
        itens: [],
        vendas: [],
        idCheckoutMarketplace: null,
        idMotivoCancelamento: null,
        observacaoAbertura: null,
        bank_account: {
          bank_code: null,
          agencia: null,
          agenciaDV: null,
          conta: null,
          contaDV: null
        }
      },
      cancelStep: 0,
      savingCancel: false,
      motivoRecusa: false
    }
  },
  computed: {
    currentUser() {
      return localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null
    }
  },
  mounted () {
    this.jumpToItem = this.item ? true : false
    this.jumpToDetail = this.detail ? true : false
    
    this.fetchMeusPedidos()

    window.addEventListener('resize', this.checkSize)
    this.checkSize()

    if ( this.jumpToItem ) {
      this.details = false
    }
  },
  beforeUnmount () {
    this.cancelActive = false
  },
  methods: {
    fetchMeusPedidos () {
      console.log("currentUser:", this.currentUser)
      const idResponsavel = this.currentUser.idPessoa
      this.loading = true

      MarketplaceService.getMeusPedidos( idResponsavel ).then(
        response => {
          console.log("response:", response.data)
          if ( response.data.length > 0 ) {
            const checkouts = response.data

            checkouts.map( k => {
              const codigo = k.descricao.split(" - ")[0]
              k.codigo = codigo
            })
            this.selectedCompra = null
            this.compras = checkouts
            this.loading = false

            console.log('checkouts:', checkouts)

            if ( this.jumpToItem ) {
              this.gotoItem(this.item, true)

              if ( this.jumpToDetail ) {
                setTimeout(() => {
                  this.toggleDetails(true, this.detail, true)
                }, 500)
              }
            }

          } else {
            this.loading = false
          }

          console.log("response - getMeusPedidos:", response)
        },
        error => {
          console.error("error:", error)
          this.loading = false
        }
      )
    },
    formatDate ( value ) {
      return formatDate(value)
    },
    formatMoney ( value ) {
      return formatMoney(value)
    },
    gotoItem ( item, overlap ) {
      if ( !overlap ) {
        this.$router.push('/minhaconta/minhascompras/' + item)
      }
      
      console.log('item:', item)
      // console.log('overlap:', overlap)
      // console.log('this.compras:', this.compras)
      this.loading = true

      MarketplaceService.getVendasTransacoesDetails( item ).then(
        response => {
          console.log("getVendasTransacoesDetails:", response)
          if ( Object.keys(response.data).length > 0 ) {
            this.vendas = []
            this.transacoes = []
            this.details = true
            
            let pedidos = this.compras.filter( k => {
              if ( k.idcheckoutMarketplace == item ) this.selectedCompra = k
              return k.idcheckoutMarketplace == item
            })

            pedidos = pedidos && pedidos.length > 0 ? pedidos[0] : []
            this.pedidos = pedidos.pedidos
            console.log("pedidos:", pedidos)
            
            this.vendas = response.data.vendas
            this.transacoes = response.data.transacoes
            this.solicitacoes = response.data.solicitacoes

            if ( this.solicitacoes ) {
              this.solicitacoes.map( k => {
                k['motivoRecusa'] = false
              })
            }
            
          }

          this.loading = false
        },
        error => {
          console.error('error:', error.response)
          // target.loadingDetails = false
        }
      )
    },
    parseData (parser, data) {
      if ( parser === 'codigoPedido' ) {
        return data.split(" - ")[0]
      }
      if ( parser === 'nomeAluno' ) {
        return data.split(" - ")[1]
      }
    },
    toggleDetails ( state, item, overlap ) {
      this.details = state

      if ( state ) {
        const pedidos = this.compras.filter( k => {
          return k.idcheckoutMarketplace == this.item
        })[0].pedidos

        const details = pedidos.filter( k => {
          return k.descricao.includes(item)
        })[0]

        console.log("details:", details)

        details.collapse = !details.collapse

        if ( !overlap && details.collapse ) {
          this.$router.push({ name: 'Minha Conta', params: {section: 'minhascompras', item: this.item, detail: this.parseData('codigoPedido', item) }})
        } else if ( !details.collapse ) {
          this.$router.push({ name: 'Minha Conta', params: {section: 'minhascompras', item: this.item }})
        }

        if ( this.isMobile ) {
          if (details.collapse) window.pagescroll.emit('lock')
          else window.pagescroll.emit('unlock')
        }
      } else {
        this.resetCancel()
      }
      
    },
    closeDetails ( item ) {
      item.collapse = false
      window.pagescroll.emit('unlock')
      this.$router.push({ name: 'Minha Conta', params: {section: 'meuspedidos', item: this.item }})
    },
    getImageSrc ( item ) {
      const uri = process.env.VUE_APP_API_URL_PUBLIC + 'marketplace/regcommp/'
      const prefix = 'titulo_rmktp-'
      const ext = '.png'
      var type = 'pagamento_confirmado'

      if ( !item.entregaconfirmada && !item.saidaconfirmada ) {
        type = 'pagamento_confirmado'
      } else if (  item.entregaconfirmada && !item.saidaconfirmada ) {
        type = 'disponivel_na_unidade'
      } else if (  item.entregaconfirmada && item.saidaconfirmada ) {
        type = 'pedido_retirado_com_sucesso'
      }
      
      const path = uri + prefix + type + ext
      console.log("path:", path)

      return path
    },
    checkSize () {
      if ( this.slideLoop ) clearInterval(this.slideLoop)

      this.slideLoop = setTimeout(() => {
        this.isMobile = window.innerWidth <= 1024
      }, 200)
    },
    getStatus ( label, item ) {
      const now = moment()
      const vencimento = moment(item.vencimentoboleto, 'YYYY-MM-DD')
      const diff = now.diff(vencimento, 'days')

      // console.log("diff:", diff)

      if ( item.pago == 1 ) {
        return label ? 'Pagamento Confirmado' : 'pago'
      } else if ( diff == 0 ) {
        return label ? 'Vence Hoje' : 'atual'
      } else if ( diff >= 1 ) {
        return label ? 'Vencido' : 'vencido'
      } else {
        return label ? 'Aguardando Pagamento' : 'pendente'
      } 
    },
    getTotal ( src, target ) {
      var total = 0

      src.map( k => {
        total += k[target]  
      })

      return total
    },
    clipboardPix ( url ) {
      navigator.clipboard.writeText(url)
      window.toaster.emit('open', {style: "success", message: "Código PIX copiado.", floater: true})
    },
    trackAllowCancel( venda ) {
      var notAllow = false
      console.log("itens comprados:", venda)

      if ( venda.itensComprados.length > 0 ) {
        console.log("tem itens comprados:", venda.itensComprados)
        
        notAllow = !venda.itensComprados[0].itens[0].podeCancelar && !venda.itensComprados[0].itens[0].podeDesistir || this.pedidos.length == 0
      } else {
        console.log("não tem itens comprados:", venda.itensComprados)
        notAllow = !venda.podeCancelar && !venda.podeDesistir || this.pedidos.length == 0
      }

      console.log("notAllow:", notAllow)
      return !notAllow
    },
    toggleCancel ( state ) {
      const canCancel = this.vendas.some( a => this.trackAllowCancel(a) )
      const pedidoEmpty = this.pedidos.length == 0

      console.log("canCancel:", canCancel)
      console.log("pedidoEmpty:", pedidoEmpty)


      if ( canCancel && !pedidoEmpty ) {
        this.resetCancel()
        // console.log("this.detail:", this.detail)

        this.$nextTick(() => {
          this.cancel.idCheckoutMarketplace = this.item
          this.cancelActive = state
        })
        
      } else {
        const msg = pedidoEmpty ? "Esta compra não pedidos gerados, por isso não é possível solicitar cancelamento ou devolução" : "Não existem itens disponíveis para cancelamento/devolução"
        window.toaster.emit('open', {style: "warning", message: msg, floater: true})
      }
      
    },
    buildCancelObject () {
      console.log("forCancel:", this.forCancel)
      
      this.forCancel.map( k => {
        if ( k.iditemlistavenda ) {
          let item = {
            id: k.iditemlistavenda,
            label: k.descricaovenda,
            imagem: k.solicitaImagemCancelamento ? '' : null
          }
          let exist = this.cancel.itens.some( a => a.id === item.id)
          if ( !exist ) this.cancel.itens.push(item)
        } else {
          let item = {
            id: k.idvendamaterial,
            label: k.descricaovenda,
            imagem: k.solicitaImagemCancelamento ? '' : null
          }
          let exist = this.cancel.vendas.some( a => a.id === item.id)
          if ( !exist ) this.cancel.vendas.push(item)
        }
      })

      console.log("cancel:", this.cancel)
    },
    toggleCancelMotive () {      
      if ( this.forCancel.length > 0 ) {
        this.buildCancelObject()

        const options = {
          size: "large", //small, mid, normal, large, full
          backdrop: true,
          backdropColor: "transparent",  //se o backdrop for false, não precisa
          backdropClose: true,
          // fullHeight: true,
          onClose: this.resetCancel
        }
        this.$refs.modal.open(options)
      }
    },
    resetCancel () {
      this.cancelActive = false
      this.cancelStep = 0
      this.forCancel = []
      this.cancel = {
        itens: [],
        vendas: [],
        idCheckoutMarketplace: null,
        idMotivoCancelamento: null,
        observacaoAbertura: null,
        bank_account: {
          bank_code: null,
          agencia: null,
          agenciaDV: null,
          conta: null,
          contaDV: null
        }
      }
    },
    toggleCancelSteps ( current, data ) {
      const hasImage = this.forCancel.some( a => a.solicitaImagemCancelamento )
      // const today = moment()
      // const checkoutDate = moment(this.selectedCompra.created_at)
      // const totalDays = today.diff(checkoutDate, 'days')
      // const needBankData = ( this.selectedCompra.cartao == 0 && this.selectedCompra.pix == 0 ) || totalDays > 30
      const needBankData = false

      if ( current == 0 ) {
        this.cancel.idMotivoCancelamento = data.motivo.idMotivoCancelamento
        this.cancel.observacaoAbertura = data.observacao

        if ( hasImage ) { 
          this.cancelStep = 1
        } else {
          if ( needBankData ) {
            this.cancelStep = 2
          } else {
            this.saveCancelamento()
          }
        }
      } else if ( current == 1 ) {
        this.cancel = data
        
        if ( needBankData ) {
          this.cancelStep = 2
        } else {
          this.saveCancelamento()
        }
        
      } else if ( current == 2 ) {
        this.saveCancelamento()
      }

    },
    saveCancelamento () {
      this.savingCancel = true
      MarketplaceService.saveSolcitacaoCancelamento(this.cancel).then(
        response => {
          console.log("response", response)
          this.cancelStep = 3
          this.savingCancel = false
          this.gotoItem(this.item, true)
        },
        error => {
          console.log("save error:", error)
          this.savingCancel = false
          window.toaster.emit('open', {style: "warning", message: "Ops. Algo aconteceu. Não foi possível salvar sua solicitação. Tente novamente. Se o problema persistir, procure nosso time de atendimento.", floater: true})

          setTimeout(() => {
            this.cancelStep = 0
            this.closeModal()
            this.gotoItem(this.item, true)
          },1000)
        }
      )
    },
    closeModal () {
      this.$refs.modal.close()
    },
    toggleInfoCancelamento ( status, index ) {
      this.solicitacoes[index].motivoRecusa = status
    },
    retrieveCancStatusTag ( solicitacao, style ) {
      var response = style ? 'pendente' : 'Pendente'

      if ( solicitacao.dataInicioTratativaAtendimeto && !solicitacao.validado ) {
        response = style ? 'pendente' : 'Em Análise'
      } else if ( solicitacao.validado ) {
        const requer = solicitacao.itens && solicitacao.itens.length > 0 ? solicitacao.itens.filter( a => a.requerDevolucao).length > 0 : false
        
        if ( requer && !solicitacao.itensDevolvidos ) {
          response = style ? 'alerta' : 'Aguardando Devolução'
        } else {
          response = style ? 'aprovada' : 'Aprovada'
        }
      } else if ( solicitacao.validado == 0 ) {
        response = style ? 'reprovada' : 'Reprovada'
      }
      
      return response
    }
  }
}
</script>

<style lang="scss" scoped>
  .detailBox {
    position: relative; width: calc(100% + $mg_large); height: auto; min-height: 100%;
    background-color: rgba(255,255,255,0.9); border-radius: $hmg_mid;
    margin-bottom: $mg; margin-left: -$hmg;
    backdrop-filter: blur(5px);
    z-index: 1000;
    padding: $mg;
    @extend %shadow-high;
    @extend %transition_4e;

    > div {
      margin-bottom: $mg;

      h3, h4, h5{
        margin: 0; padding: 0;
      }
    }

    // &.over {
    //   position: absolute; top: 150px;
    // }
    
  }

  header {
    display: flex; align-content: center; align-items: center;
    justify-content: space-between;
    margin-bottom: $hmg_mid;
  }

  .pageDetails {
    @extend %transition_4o;
    
    &.open {
      position: absolute;
      top: 70px; left: 40px;
      overflow: hidden;
      pointer-events: none;
      opacity: .7;
      max-height: calc(100% - 100px);
    }
  }

  .cancelMessage {
    border-radius: $border-radius;
    background-color: $color-alert;
    padding: $mg_mini $hmg_small; margin-bottom: $hmg_mid;
    @extend %transition_4e;

    p {
      margin: 0; padding: 0;
      color: $color-secondary
    }
  }
  .cancelActions {
    display: flex; justify-content: flex-end;
    margin-top: $hmg
  }

  .saveSpinner {
    padding: $mg;
    text-align: center;
    @extend %transition_3o;

    .spn {
      margin: auto
    }
    .icon {
      font-size: 64px;
      margin-bottom: $hmg
    }
    .h4 {
      margin-bottom: $hmg
    }

    .btn {
      margin-top: $hmg
    }
  }

  .blob {
    width: 30%
  }

  .statusTag {
    padding: $hmg_mini $mg_mini;
    border-radius: 2em;
    line-height: 1;
    color: $color-secondary;
    white-space: nowrap;

    &.infoWrap {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: $hmg_mini;

      .i {
        font-size: 16px;
      }

      &:hover {
        color: $color-secondary;

        .i {
          color: $color-secondary;
        }
      }
    }

    &.pendente {
      background-color: $color-pendente;
    }
    &.aprovada {
      background-color: $color-success;
    }
    &.reprovada {
      background-color: $color-alert;
    }
    &.alerta {
      background-color: $color-primary;
    }
    
  }

  .refusedModalWrap {
    position: relative;
  }

  .refusedModal {
    width: 220px; height: auto;
    border-radius: $border-radius;
    border: $border-component;
    background-color: $color-secondary;
    padding: $mg_mini;
    position: absolute;
    top: $hmg_mini; right: $hmg_mini;
    @extend %transition_4e;
    @extend %shadow-low;
    z-index: 100;

    header {
      display: flex;
      align-items:center;
      justify-content: space-between;
      margin-bottom: $mg_mini;
      width: 100%;

      a {
        font-size: 15px;
      }
      h5 {
        margin: 0; padding: 0;
      }
    }

    p {
      margin: 0; padding: 0;
      text-align: left;
    }
  }
</style>