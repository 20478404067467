<template>
  <section v-if="matricula">
    <div class="contratoWrap erros" v-if="erros">
      <div v-if="erros.bloqueios">
        <h4>Atenção{{ !auto ? ' - Bloqueio' : '' }}</h4>
        <p v-html="erros.bloqueios"></p>
      </div>
      <div v-if="erros.documentosRequeridos">
        <h4>Você tem documentos pendentes</h4>
        <p>Para finalizar sua matrícula, você deve enviar todos os documentos obrigatórios.</p>
      </div>
       <div v-else>
        <p v-html="erros"></p>
      </div>
    </div>
    <template v-else>
      <div v-if="auto">
        <div class="contratoWrap clicksign">
          <div class="contratoBody" id="container" ref="container"></div>
          <div class="btContrato" v-if="fileURL">
            <a :href="fileURL" target="_blank" class="btn btn-primary" :download="fileName()">
              Baixar PDF&nbsp;
              <fa :icon="['fas','file-pdf']"></fa>
            </a>
          </div>
        </div>
      </div>
      <div class="contratoWrap" v-else>
        <div v-if="fileURL" class="contratoBody">
          <vue-pdf-embed :source="fileURL" />
        </div>
        <div class="btContrato" v-if="fileURL">
          <a :href="fileURL" class="btn btn-primary" :download="fileName()">
            Baixar PDF&nbsp;
            <fa :icon="['fas','file-pdf']"></fa>
          </a>
        </div>
      </div>
    </template>
  </section>
</template>

<script>

import MatriculaService from '@/services/matricula.service'
import { unformatDate } from '@/services/utils.service'
import VuePdfEmbed from 'vue-pdf-embed'


export default {
  components:{
    VuePdfEmbed
  },
  props: {
    idMatricula: {
      type: [Number, String]
    },
    auto: {
      type: Boolean,
    }
  },
  data () {
    return {
      fileURL: null,
      fileBin: null,
      termoAceite: true,
      matricula: null,
      requestSignatureId: '',
      widget: null,
      scriptLoaded: false,
      scriptError: false,
      endpoint: process.env.VUE_APP_ENV == 'PROD' ? 'https://app.clicksign.com' : 'https://sandbox.clicksign.com',
      origin: process.env.VUE_APP_ENV == 'PROD' ? 'https://apollo.rededecisao.com' : 'https://apollo.hom.rededecisao.com',
      clickSign: null,
      signedEventListener: null,
      erros: null
    }
  },
  mounted () {
    window.spinner.emit("open")
    this.$store.dispatch('matricula/get').then(response => {
      console.log("matricula on contrato:", response)
      this.matricula = response
      if ( this.matricula.matricula.idMatricula ) {
        this.validarPendencias(this.matricula.matricula.idMatricula)
      } else {
        this.getAluno()
      }
    })
  },
  methods: {
    getAluno () {
      this.$store.dispatch('matricula/get').then(response => {
        this.matricula = response
        if ( this.idMatricula ) {
          MatriculaService.getAlunoByIdMatricula( this.idMatricula ).then(
            response => {
              console.log("getAuno:", response.data)
              this.matricula.Aluno.Aluno = response.data.aluno.Aluno ? response.data.aluno.Aluno : this.matricula.Aluno.Aluno
              this.matricula.Aluno.Basicos = response.data.aluno.Basicos ? response.data.aluno.Basicos : this.matricula.Aluno.Basicos
              this.matricula.Aluno.Basicos.dataNascimento = this.matricula.Aluno.Basicos.dataNascimento ? unformatDate(this.matricula.Aluno.Basicos.dataNascimento) : this.matricula.Aluno.Basicos.dataNascimento
              this.matricula.Aluno.Documento = response.data.aluno.Documento ? response.data.aluno.Documento : this.matricula.Aluno.Documento
              this.matricula.Aluno.Endereco = response.data.aluno.Endereco ? response.data.aluno.Endereco : this.matricula.Aluno.Endereco
              
              this.matricula.matricula = response.data.matricula ? response.data.matricula : this.matricula.matricula

              if ( !this.matricula.Aluno.Basicos.nomeDocumento ) {
                this.matricula.Aluno.Basicos.nomeDocumento = this.matricula.Aluno.Basicos.nome
              }
              
              if ( !this.matricula.Aluno.Basicos.nomeDocumento ) {
                this.matricula.Aluno.Basicos.nomeDocumento = this.matricula.Aluno.Basicos.nome
              }
              
              this.$store.dispatch('matricula/save', this.steps).then(() => {
                this.validarPendencias(this.matricula.matricula.idMatricula)
              })
              
            },
            error => {
              console.error(error.response.data.message)
            }
          )
        } else {
          this.loaded = true
          this.$emit('ready')
        }
      })      
    },
    validarPendencias ( idMatricula ) {
      MatriculaService.getPendencias(idMatricula).then(
        response => {
          console.log("getPendencias", response.data)
          if ( response.status == 200 ) this.getContrato(idMatricula)
        },
        error => {
          if ( error.response.status == 400 ) {
            this.erros = error.response.data
            this.$emit("block")
          } 
          console.log("error:", error)
          window.spinner.emit('close')
        }
      )
    },
    getContrato ( idMatricula ) {
      const drop = {
        idMatricula,
        portal: this.auto ? 'portalPais' : 'portalGestao'
      }
      // console.log("drop - contrato:", drop)
      MatriculaService.gerarContrato(drop).then(
        response => {
          console.log('contrato:', response.data)
          if ( this.auto ) {
            this.clickSign = response.data
            this.runClickSignWidget()
          } else {
            const file = new Blob([response.data], { type: 'application/pdf' })
            // this.fileBin = response.data
            this.fileURL = URL.createObjectURL(file)
          }

          window.spinner.emit('close')
        },
        async error => {
          // console.error(error)
          var msg
          console.log("error - responseType:", error.response.request.responseType)
          if ( error.response.request.responseType == 'blob' ) {
            msg = JSON.parse(await error.response.data.text()).error;
            this.erros = msg
            console.log("error when blob:", msg)
          } else {
            msg = error.response.data.error ?? JSON.stringify(error.response.data)
          }
          
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. " + msg, floater: true})
          window.spinner.emit('close')
          this.$emit("block")
        }
      )
      // MatriculaService.getContrato( idMatricula, true ).then(
      //   response => {
      //     console.log(response.data)
      //     const file = new Blob([response.data], { type: 'application/pdf' })
      //     // let file = await new Promise(resolve => canvasElem.toBlob(resolve, 'image/png'));
      //     this.fileURL = URL.createObjectURL(file)

      //     window.spinner.emit('close')
      //   },
      //   error => {
      //     console.error(error)
      //     window.spinner.emit('close')
      //   }
      // )
    },
    finalize () {
      return this.termoAceite
    },
    fileName () {
      var name = "Contrato-Prestacao-Servico-RedeDecisao_"

      var nomeAluno = this.matricula.Aluno.Basicos.nome
          nomeAluno = nomeAluno.split(" ").join("-")

          name += nomeAluno
          name += '.pdf'
      console.log("fileName - contrato:", name)
      return name
    },
    runClickSignWidget () {
      const script = document.createElement('script')
      script.src = "https://cdn-public-library.clicksign.com/embedded/embedded.min-1.0.0.js"
      script.type = "text/javascript"
      script.onload = () => {
        this.scriptLoaded = true
        console.log('Clicksign script loaded successfully')
        
        this.run()
      }
      script.onerror = () => {
        this.scriptError = true
        console.error('Failed to load Clicksign script')
      }
      document.head.appendChild(script)
    },
    async waitForClicksign() {
      // Função para esperar até que Clicksign esteja definido
      while (typeof window.Clicksign === 'undefined') {
        await new Promise(resolve => setTimeout(resolve, 100))
      }
      return window.Clicksign
    },
    async run() {
      if (!this.scriptLoaded) {
        console.error('Clicksign script not loaded yet')
        return
      }

      if (this.scriptError) {
        console.error('There was an error loading the Clicksign script')
        return
      }

      const Clicksign = await this.waitForClicksign()

      if (!Clicksign) {
        console.error('Clicksign is not available')
        return
      }

      const request_signature_id = this.clickSign.AddPessoaToDocClickSign.list.request_signature_key
      this.fileURL = this.clickSign.AddPessoaToDocClickSign.list.url

      console.log("request_signature_id:", request_signature_id)
      
      if (this.widget && document.getElementById('container')) {
        try {
          this.widget.unmount()
        } catch (error) {
          console.error('Failed to unmount widget:', error)
        }
      }

      try {
        this.widget = new Clicksign(request_signature_id)
        this.widget.endpoint = this.endpoint
        this.widget.origin = this.origin
        this.widget.mount('container')

        console.log("this.widget", this.widget.on)
        this.signedEvent()
        // this.widget.on('loaded', () => { console.log('loaded!') })
        // this.widget.on('signed', (e) => { console.log("acabou!!!!!!!!!!!!!!!!!!!!!", e) })
        // this.widget.on('resized', (height) => {
        //   console.log('resized!')
        //   document.getElementById('container').style.height = height + 'px'
        // })
        
        // console.log("container: ", this.$refs)

      } catch (error) {
        console.error('Error initializing Clicksign widget:', error)
      }
    },
    signedEvent() {
      this.signedEventListener = setInterval(() => {
        const idContrato = this.clickSign.documentoClickSign.document.key

        MatriculaService.getContratoStatus(idContrato).then(
          response => {
            console.log("getContratoStatus:", response.data)
            const status = response.data.document.status

            if ( status == 'canceled' || status == 'closed' ) {
              clearInterval(this.signedEventListener)
              // console.log("acabooooooooooooou!")
              this.$emit("finished", status == 'closed' )
            }
          },
          () => {

          }
        )
      }, 5000)
    }
  }
}
</script>
<style lang="scss">
  .contratoWrap {
    iframe {
      border: 0 !important;
      overflow: hidden;
    }
  }
</style>
<style lang="scss" scoped>
  
  .contratoWrap {
    display: flex; flex-direction: column;
    align-content: stretch; align-items: stretch;
    width: 100%; height: 80vh;
    background-color: $color-secondary;
    border-radius: $border-radius-larger;
    padding: $hmg; overflow: hidden;
    border: 0 !important;
    position: relative;
    
    
    .contratoBody {
      max-height: 100%; overflow-y: auto; overflow-x: none;
      width: 100%; flex: 1;
      @extend %scrollbar_card_bg;
    }

    &.clicksign {
      padding: $hmg_mid;

      .contratoBody {
        overflow: hidden;
      }
    }


    .btContrato {
      position: absolute; bottom: $hmg_mid; left: $hmg_mid;
      margin: auto;
      display: flex;
      justify-content: flex-end;
    }

    // @media screen and ( max-width: 640px) {
    //   height: 70vh; padding: 0; 

    //   >div {
    //     padding: $hmg_small;
    //   }

    //   .check-control {
    //     max-width: 65%;
    //     line-height: 1.1;
    //   }
    // }
  }

  .erros {
    div {
      margin-bottom: $hmg_mid;
      padding: $mg_mini;
      border-radius: $border-radius-small;
      background-color: $color-warning;
      color: $color-secondary;

      p { 
        margin: 0; padding: 0;
      }
    }  
  }
  
</style>