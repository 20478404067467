<template>
  <card :listed="false" v-if="pergunta">
    <div :class="['perguntaToggle',{ active: pergunta.active }]">
      <div>
        <header :class="{ component: pergunta.active }">
          <template v-if="pergunta.active">
            <div class="typeSelect">
              <select-control 
                :options="options.tipoResposta" 
                v-model="pergunta.tipoResposta" 
                @change="updatePergunta()"
                placeholder="Selecione"
                cssClass="small"
                @mouseenter="blockToggle = true" 
                @mouseleave="blockToggle = false"
              ></select-control>
            </div>
            <div>
              <switch-control 
                v-model:checked="pergunta.obrigatorio" 
                labelOn="Obrigatório"
                labelOff="Não Obrigatório"
                @change="updatePergunta()"
                invert
                ></switch-control>
            </div>
          </template>
          <label v-else>{{ parseTipoResposta(pergunta.tipoResposta) }}</label>
        </header>
        <!-- <textarea-control 
          cssClass="transparent mid-font" 
          v-model="pergunta.descricao" 
          
          rows="1"
          @focus="resizeTextarea" 
          @keyup="resizeTextarea"
          v-if="pergunta.active"
          ref="textarea"
        />-->
        <h5 contenteditable
          v-if="pergunta.active"
          @blur="logDesc" 
          @mouseenter="blockToggle = true" 
          @mouseleave="blockToggle = false"
          v-html="pergunta.descricao"
          class="textarea"
          role="textbox"
        ></h5>
        <h5 @click.prevent="togglePergunta()" v-else>{{ pergunta.descricao }}</h5> 
      </div>
      <fa :icon="['fal', 'circle-chevron-down']" :class="['icon', { active: pergunta.active }]"  @click.prevent="togglePergunta()"/>
    </div>
    <transition name="slidedown-small" tag="div" class="perguntaDetails">
      <div v-if="pergunta.active && pergunta.ready">
        <perguntas :scope="pergunta" :allowCondition="allowCondition" :conditions="condicoes" ref="pergunta" @onSave="savePergunta"></perguntas>
      </div>
    </transition>
    <footer v-if="pergunta.active">
      <div>
        <a href="#">
          <fa :icon="['fal','chevron-up']" class="icon" />
        </a>
        <a href="#">
          <fa :icon="['fal','chevron-down']" class="icon"/>
        </a>
      </div>
      <a href="#" @click.prevent="confirmRemove()">
        <fa :icon="['fal','trash-can']" class="icon"/>
      </a>
    </footer>
    <confirm 
      :backdrop="false"
      class="confirm"
      ref="confirm"
    ></confirm>
  </card>
</template>

<script>
import Perguntas from "@/components/ferramentas/formularios/form/Perguntas.vue"
import FormulariosService from "@/services/formularios.service.js"
import { createSelectOptions } from "@/services/utils.service.js"

export default {
  components: {
    Perguntas
  },
  props: {
    scope: {
      type: Object
    },
    index: {
      type: Number
    },
    allowCondition: {
      type: Boolean,
      default: false
    },
    conditions: {
      type: Array
    }
  },
  data () {
    return {
      pergunta: null,
      blockToggle: false,
      options: {
        tipoResposta: null
      },
      condicoes: null,
      tipoResposta: [
        {
          id: 1,
          label: "Dissertativa",
          itens: false,
          placeholder: true
        },
        {
          id: 2,
          label: "Múltipla Escolha",
          itens: true,
          placeholder: false
        },
        {
          id: 3,
          label: "Caixa de Seleção",
          itens: true,
          placeholder: false
        },
        {
          id: 4,
          label: "Imagem",
          itens: false,
          placeholder: true
        },
        {
          id: 5,
          label: "Números",
          itens: false,
          placeholder: true
        },
        {
          id: 6,
          label: "Data",
          itens: false,
          placeholder: false
        }
      ],
      wait: {
        pergunta: false,
        condicao: false
      },
      lockSave: false
    }
  },
  mounted () {
    this.pergunta = this.scope
    this.pergunta.active = false

    this.options.tipoResposta = createSelectOptions(this.tipoResposta, 'id','label')
    
    if ( this.conditions ) this.filterConditions()
    console.log("pergunta:", this.pergunta)
    console.log("conditions:", this.conditions)
  },
  methods: {
    togglePergunta ( close ) {
      if ( close ) {
        this.pergunta.active = false
      } else if ( !this.blockToggle ) {
        this.pergunta.active = !this.pergunta.active
      }

      if ( this.pergunta.active && this.pergunta.idFormularioPerguntaCondicao ) {
        this.getConditions()
      } else {
        this.pergunta.ready = true
      }
    },
    filterConditions () {
      this.condicoes = this.conditions.filter( a => a.idFormularioPergunta != this.pergunta.idFormularioPergunta )
    },
    getConditions () {
      FormulariosService.getCondicao(this.pergunta.idFormularioPerguntaCondicao).then(
        response => {
          this.pergunta['condicao'] = {
            idFormularioPergunta: response.data.idFormularioPergunta,
            idFormularioAlternativa: response.data.idFormularioAlternativa,
            idFormularioPerguntaCondicao: response.data.idFormularioPerguntaCondicao
          }
          this.pergunta.ready = true
        },
        () => {

        }
      )
    },
    parseTipoResposta ( id ) {
      return this.tipoResposta.filter( a => a.id == id )[0].label
    },
    updatePergunta () {
      this.$refs.pergunta.update()
    },
    logDesc ( e ) {
      var src = e.target.innerText
      this.pergunta.descricao = src
      console.log("desc:", this.pergunta.descricao)
    },
    savePergunta ( pergunta ) {
      console.log("savePergunta:", pergunta)

      if ( !this.pergunta.ordem ) {
        this.pergunta.ordem = Number(this.index) + 1
      }
      
      const drop = {
        descricao: this.pergunta.descricao,
        tipoResposta: this.pergunta.tipoResposta,
        obrigatorio: this.pergunta.obrigatorio ? 1 : 0,
        idFormulario: this.pergunta.idFormulario,
        ordem: this.pergunta.ordem
      }

      if ( this.pergunta.idFormularioPergunta ) {
        FormulariosService.updatePergunta( this.pergunta.idFormularioPergunta, drop ).then(
          () => {
            // console.log("updatePergunta:", response.data)
            this.wait.pergunta = true
            this.finishSavePergunta()

          },
          error => {
            const err = error.response.data.descricao
            window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu! " + err, floater: true})
          }
        )
        
        if ( pergunta.condicao.idFormularioAlternativa && pergunta.condicao.idFormularioPergunta ) {

          FormulariosService.saveCondicao( pergunta.condicao ).then(
            response => {
              const data = {
                idFormularioPerguntaCondicao: response.data.idFormularioPerguntaCondicao
              }
              console.log("this.pergunta.condicao:", this.pergunta.condicao)
              console.log("pergunta:", this.pergunta)

              this.pergunta.condicao = {
                idFormularioPerguntaCondicao: response.data.idFormularioPerguntaCondicao,
                idFormularioAlternativa: pergunta.condicao.idFormularioAlternativa,
                idFormularioPergunta: response.data.idFormularioPergunta
              }

              FormulariosService.updatePergunta( this.pergunta.idFormularioPergunta, data ).then(
                () => {
                  // console.log("updatePergunta:", response.data)
                  this.wait.condicao = true
                  this.finishSavePergunta()
                },
                error => {
                    const err = error.response.data.descricao
                    window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu! " + err, floater: true})
                }
              )
            },
            () => {
              
            }
          )
        } else {
          this.wait.condicao = true
          this.finishSavePergunta()
        }
        
      } else {
        FormulariosService.savePergunta( drop ).then(
          () => {
            // console.log("savePergunta:", response.data)
            this.togglePergunta(true)
            this.wait.pergunta = true
            this.wait.condicao = true
            this.finishSavePergunta()
          },
          error => {
            const err = error.response.data.descricao
            window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu! " + err, floater: true})
            
          }
        )
      }
    },
    finishSavePergunta () {
      const finished = !Object.values(this.wait).some( a => !a)

      console.log("finished...", this.wait)

      if ( finished ) {
        this.togglePergunta(true)
        console.log("fim... update perguntas!")
        this.$emit("onSave")
      }
      
    },
    confirmRemove () {
      this.$refs.confirm.run({
        message: "Tem certeza que deseja remover esta pergunta?",
        confirmButton: "Sim",
        denyButton: "Não",
        // data: index,
        callback: this.removePergunta,
      })
    },
    removePergunta () {
      if ( this.pergunta.idFormularioPergunta ) {
        FormulariosService.deletePergunta( this.pergunta.idFormularioPergunta ).then(
          response => {
            console.log("updatePergunta:", response.data)
            this.$emit("onRemove")
            // this.togglePergunta(true)
          },
          () => {

          }
        )
      } else {
        this.$emit("onRemove", this.index)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .perguntaToggle {
    width: 100%;
    display: flex; align-content: center; align-items: center;
    justify-content: space-between;
    padding: $hmg_mid;
    cursor: pointer;

    &.active {
      align-items: flex-start; 
    }
    >div {
      flex: 1;
    }
    
    h5 {
      padding: 0; margin: 0;
    }
    .icon {
      font-size: 16px;
      @extend %transition_3o;

      &.active {
        transform: rotate(180deg);
      }
    }

    &:hover {
      .icon {
        color: $color-primary
      }
    }

    header {
      display: flex;
      align-items: center; align-content: center;
      gap: $hmg_mid;
      @extend %transition_4e;
      &.component {
        margin-bottom: $mg_small;
      }
      
    }
    .typeSelect {
      max-width: 220px;
    }

    .textarea {
      padding: $hmg_mini;
      border-bottom: $border-component-double;
      @extend %transition_4e;
      cursor: text;

      &:focus {
        box-shadow: none; outline: none;
        padding: $hmg_mini $hmg_mini $mg_mini $hmg_mini;
        border-bottom: 3px solid $color-primary !important;
      }
    }
  }

  .perguntaDetails {
    border-top: $border-component;
  }

  footer {
    border-top: $border-component;
    padding: $mg_mini $hmg_mid; margin-top: $mg_mini;
    display: flex; align-items: center; align-content: center;
    justify-content: space-between;

    >div {
      display: flex; align-items: center; align-content: center;
      gap: $mg_mini;

      a {
        &:first-child {
          margin-top: 1px;
        }
        &:last-child {
          margin-top: -1px;
        }
      }
    }

    a {
      .icon {
        font-size: 16px
      }

      &:hover {
        color: $color-primary
      }
    }
  }
</style>