<template>
  <section>
    <h2>Meus Cupons</h2>
    <div v-if="loading" class="tableLoader">
      <input-spinner></input-spinner>
    </div>
    <table class="content-table" v-else>
      <thead>
        <template v-if="!isMobile">
          <tr>
            <th>Descrição</th>
            <th>Aluno</th>
            <th>Validade</th>
            <th>Valor</th>
            <th>Status</th>
            <th class="text-right">Código</th>
          </tr>
        </template>
        <template v-else>
          <tr v-for="item in vouchers" :key="item">
            <th>Descrição</th>
            <th>Aluno</th>
            <th>Validade</th>
            <th>Valor</th>
            <th>Status</th>
            <th class="text-right">Código</th>
          </tr>
        </template>
      </thead>
      <tbody>
        <tr class="" v-for="(item, index) in vouchers" :key="item" >
          <td>{{ item.descricaoVoucher }}</td>
          <td>{{ item.nomeAluno ? item.nomeAluno : '-' }}</td>
          <td>{{ formatDate(item.fim) }}</td>
          <td v-if="item.porcentagem" class="lock">{{ item.porcentagem }}%</td>
          <td v-else-if="item.valor"  class="lock">{{ formatMoney(item.valor) }}</td>
          <td><span :class="['marker', getStatus(item)]">{{ item.status == 1 ? 'Utilizado' : 'Não utilizado' }}</span></td>
          <td class="text-right" @click.prevent="copy(item,index)"><span class="voucherMarker">{{ item.codigoVoucher }}</span></td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import MarketplaceService from '@/services/marketplace.service.js'
import { formatDate, formatMoney } from '@/services/utils.service.js'
import InputSpinner from '@/components/utilities/InputSpinner.vue'

export default {
  components: {
    InputSpinner
  },
  data () {
    return {
      loading: false,
      vouchers: [],
      isMobile: false,
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
    }
  },
  mounted () {
    this.loading = true
    window.spinner.emit("open")
    this.getVouchers()

    window.addEventListener('resize', this.checkSize)
    this.checkSize()
  },
  methods: {
    getVouchers () {
      const idPessoa = this.currentUser.idPessoa
      MarketplaceService.getVouchersResponsavel(idPessoa).then(
        response => {
          console.log("getVouchersResponsavel:", response)
          this.vouchers = response.data
          this.loading = false
          window.spinner.emit("close")
        },
        () => {
          this.loading = false
          window.spinner.emit("close")
        }
      )
    },
    formatDate ( value ) {
      return formatDate(value)
    },
    formatMoney ( value ) {
      return formatMoney(value)
    },
    checkSize () {
      if ( this.slideLoop ) clearInterval(this.slideLoop)

      this.slideLoop = setTimeout(() => {
        this.isMobile = window.innerWidth <= 1024
      }, 200)
    },
    getStatus ( item ) {
      return item.status == 1 ? 'pago' : 'vencido'
    },
    fallbackCopy (value) {
      // console.log("window.clipboardData:", window.clipboardData)
      const el = document.createElement('textarea')
      el.value = value
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      
      window.toaster.emit('open', {style: "success", message: "Voucher copiado com sucesso.", floater: true})
    },
    copy ( value ) {
      // const elm = this.$refs.pixContent
      if ( !navigator.clipboard ) {
        this.fallbackCopy(value.codigoVoucher)
      } else {
        // const selection = window.getSelection()
        // selection.removeAllRanges()

        // const range = document.createRange()
        // range.selectNodeContents(elm)
        // selection.addRange(range)

        navigator.clipboard.writeText(value.codigoVoucher)
        this.copiado = true

        window.toaster.emit('open', {style: "success", message: "Voucher copiado com sucesso.", floater: true})
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .lock {
    white-space: nowrap
  }

  .marker {
    color: $color-secondary;
  }
  .voucherMarker {
    padding: $hmg_mini $mg_small;
    border-radius: $border-radius;
    color: $color-text;
    white-space: nowrap;
    background-color: $color-secondary
  }
</style>