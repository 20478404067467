<template>
  <div>
    <div class="row nop nom" v-if="informacoes">
      <div class="col-md-12">
        <h4 class="">Informações Pessoais</h4>
      </div>
      <div class="form-group col-md-12 ">
        <label class="btn btn-secondary btn-form">
          <input type="radio" v-model="informacoes.Basicos.tipo" value="1">
          Pessoa Física
        </label>
        <label class="btn btn-secondary btn-form">
          <input type="radio" v-model="informacoes.Basicos.tipo" value="2">
          Pessoa Jurídica
        </label>
      </div>
      <div class="form-group col-md-6">
        <label>{{ informacoes.Basicos.tipo == '1' ? 'Nome' : 'Razão Social' }}</label>
        <input-control type="text" :placeholder=" informacoes.Basicos.tipo == '1' ? 'Nome' : 'Razão Social' " v-model="informacoes.Basicos.nome" name="nome" :valid="valid.nome" @keyup="valid.nome = true" required/>
      </div>
      <div class="form-group col-md-6">
        <label>Email</label>
        <input-control type="text" placeholder="Email" v-model="informacoes.Basicos.email" name="email" :valid="valid.email" @keyup="valid.email = true"  required/>
      </div>
      <div class="form-group col-md-6">
        <label>{{ informacoes.Basicos.tipo == '1' ? 'CPF' : 'CNPJ' }}</label>
        <input-control type="text" :placeholder="informacoes.Basicos.tipo == '1' ? 'CPF' : 'CNPJ' " name="m_cpf" :valid="valid.m_cpf" @keyup="valid.m_cpf = true" v-model="informacoes.Documento.m_cpf" :masking="masked ? 'cpf' : null" required/>
      </div>
      <div class="form-group col-md-6">
        <label>RG</label>
        <input-control type="text" placeholder="RG" v-model="informacoes.Documento.rg" name="rg" :valid="valid.rg" @keyup="valid.rg = true" required/>
      </div>
      <div class="form-group col-md-6">
        <label>Telefone</label>
        <input-control type="text" placeholder="Telefone" v-model="informacoes.Basicos.m_telefonefixo" :masking="masked ? 'telefone' : null" />
      </div>
      <div class="form-group col-md-6">
        <label>Celular</label>
        <input-control type="text" placeholder="Celular" v-model="informacoes.Basicos.m_telefonecelular" name="m_telefonecelular" :valid="valid.m_telefonecelular" @keyup="valid.m_telefonecelular = true" :masking="masked ? 'telefone' : null" required/>
      </div>
    </div>
    <div class="row nop nom" v-if="informacoes">
      <div class="col-md-12"></div>
      <div class="col-md-12">
        <h5 class="">Endereço</h5>
      </div>
      <div class="form-group col-md-4">
        <label>CEP</label>
        <input-control type="text" placeholder="CEP" v-model="informacoes.Endereco.m_cep" :masking="masked ? 'cep' : null" @blur="getAddressFromCEP" name="m_cep" :valid="valid.m_cep" @keyup="valid.m_cep = true" required/>
      </div>
    </div>
    <div class="row nop nom" v-if="informacoes">
      <div class="form-group col-md-8">
        <label>Endereço</label>
        <input-control type="text" placeholder="Logradouro" v-model="informacoes.Endereco.logradouro" readonly/>
      </div>
      <div class="form-group col-md-4">
        <label>Número</label>
        <input-control type="text" placeholder="Número" v-model="informacoes.Endereco.numero" name="numero" :valid="valid.numero" @keyup="valid.numero = true" required/>
      </div>
      <div class="form-group col-md-5">
        <label>Complemento</label>
        <input-control type="text" placeholder="Complemento" v-model="informacoes.Endereco.complemento" />
      </div>
      <div class="form-group col-md-7">
        <label>Bairro</label>
        <input-control type="text" placeholder="Bairro" v-model="informacoes.Endereco.bairro" required readonly/>
      </div>
      <div class="form-group col-md-10">
        <label>Cidade</label>
        <input-control type="text" placeholder="Cidade" v-model="informacoes.Endereco.nomeCidade" readonly/>
      </div>
      <div class="form-group col-md-2">
        <label>UF</label>
        <input-control type="text" placeholder="UF" v-model="informacoes.Endereco.nomeUf" maxlenght="2" :masking="masked ? 'uf' : null" readonly/>
      </div>
    </div>
  </div>
</template>

<script>
import { getCEP } from '@/services/utils.service'

export default {
  name: "Informações",
  data () {
    return {
      informacoes: null,
      masked: true,
      lookingForCep: false,
      valid: {
        tipo: true,
        nome: true,
        email: true,
        m_telefonefixo: true,
        m_telefonecelular: true,
        m_cpf: true,
        rg: true,
        m_cep: true,
        logradouro: true,
        numero: true,
      },
    }
  },
  computed: {
    currentUser() {
      return localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null
    }
  },
  mounted () {
    this.$store.dispatch('checkout/get').then(response => {
      this.informacoes = response
      this.fetchUser()
    })
  },
  methods: {
    fetchUser () {
      console.log("currentUser:", this.currentUser)
      
      const telefone = this.currentUser.cadastro.Basicos.telefonefixo
      const celular = this.currentUser.cadastro.Basicos.telefonecelular
      const cpf = this.currentUser.cadastro.Documento.cpf
      const cep = this.currentUser.cadastro.Endereco.cep

      this.informacoes.Basicos = this.currentUser.cadastro.Basicos
      this.informacoes.Documento = this.currentUser.cadastro.Documento
      this.informacoes.Endereco = this.currentUser.cadastro.Endereco

      this.informacoes.Basicos.m_telefonefixo = telefone ? telefone : celular
      this.informacoes.Basicos.m_telefonecelular = celular
      this.informacoes.Documento.m_cpf = cpf
      this.informacoes.Endereco.m_cep = cep

      // console.log('informacoes:', this.informacoes)
    },
    getAddressFromCEP ( ) {
      const cep = this.informacoes.Endereco.m_cep.replace("-","")

      console.log("cep:", cep)

      if ( !this.lookingForCep ) {
        this.lookingForCep = true
        window.spinner.emit('open')

        getCEP(cep).then(
          response => {
            console.log('response..', response)
            if ( response ) {
              if ( response.data.erro ) {
                this.valid.m_cep = false
                this.informacoes.Endereco.logradouro = null
                this.informacoes.Endereco.bairro = null
                this.informacoes.Endereco.nomecidade = null
                this.informacoes.Endereco.nomeUf = null
              } else {
                const data = response.data
                this.informacoes.Endereco.logradouro = data.logradouro
                this.informacoes.Endereco.bairro = data.bairro
                this.informacoes.Endereco.nomecidade = data.localidade
                this.informacoes.Endereco.nomeUf = data.uf
              }
              
            }

            setTimeout (() => {
              this.lookingForCep = false
              window.spinner.emit('close')
            },1000)
          },
          error => {
            console.log('error', error.request)
            setTimeout (() => {
              this.lookingForCep = false
              window.spinner.emit('close')
            },1000)
          }
        )
      }
      
    },
    trackFinishTyping ( e ) {
      console.log("trackFinishTyping:", e)
    },
    validate () {
      var required = document.querySelectorAll(':required')
    
      const notValids = []
      
      required.forEach (k => {
        const value = k.value
        const nome = k.name
        // console.log("value:", value)
        // console.log("nome:", nome)

        if ( !value ) {
          notValids.push(k)
          this.valid[nome] = false
          window.toaster.emit('open', {style: "error", message: "Preencha todos os campos obrigatórios, para criar o desconto."})
          // k.className += ' error'; 
        }
      })

      console.log('notValids:', notValids)

      return notValids.length == 0

    }
  }
}
</script>

<style lang="scss" scoped>
  h4 { 
    margin-bottom: $hmg;
    padding-left: $hmg_small
  }
  h5 { 
    margin-top: $hmg;
    color: $color-text;
    padding-left: $hmg_small
  }
</style>