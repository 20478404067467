<template>
  <div :class="['mainMenu',{ active: toggle }]">
    <a href="#" class="closeMobileMenu" @click.prevent="closeMobileMenu()">
      <span></span>
      <span></span>
    </a>
    <nav class="menu">
      <!-- <a 
          v-for="(item,index) in menu"
          :key="item.posicao"
          :href="item.route" 
          @click.prevent="goToItem(item)"
          :class="{
            'btn': item.feat != 0, 
            'btn-primary': item.feat == 1 && index == 0,
            'btn-secondary': item.feat == 1 && index == 1
          }"
        > -->
      <a
        v-for="item in menu"
        :key="item.posicao"
        :href="createRoute(item)" 
        @click.prevent="goToItem(item)"
      >
        <span>{{ item.categoria }}</span>
        <fa :icon="parseIcon(item.icon)" class="icon" v-if="item.icon && item.icon !== ''"></fa>
      </a>
    </nav>
  </div>
</template>
<script>
import { parseIcon } from '@/services/utils.service'
import LojaService from '@/services/loja.service'

export default {
  props: {
    toggle: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      menu: [],
      options: {
        align: "prev",
        bound: true,
        bounce: '10%',
        duration: 600,
        deceleration: 0.0075,
        autoResize: true,
        autoInit: false
      },
      sliderOn: false,
      slideLoop: null,
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
      campinas: [29,31]
    }
  },
  mounted () {
    this.getMenuLoja()
    // this.checkSize()

    window.addEventListener('resize', this.checkSize)
  },
  methods: {
    getMenuLoja () {
      LojaService.getMenuCategoriasLoja().then(
        response => {
          // if ( response.data ) this.formatMenu(response.data)
          // console.log("getMenuCategoriasLoja - currentUser:", this.currentUser)
          var unidades =  []
          this.currentUser?.alunos?.map( k => {
            unidades.push(k.idUnidade)
          })
          
          // if ( unidades.includes(27) ) {
          //   let menu = response.data.filter( k => k.idcategorialoja != 1 )
          //   this.menu = menu
          // } else if ( unidades.some(a => this.campinas.includes(a)) ) {
          //   let menu = response.data.filter( k => k.idcategorialoja != 5)
          //   this.menu = menu
          // } else {
          //   this.menu = response.data
          // }
          
          if ( unidades.includes(27) ) {
            let menu = response.data.filter( k => k.idcategorialoja != 1 )
            this.menu = menu
          } else {
            this.menu = response.data
          }

          console.log("this.menu:", this.menu)
        },
        error => {
          console.log('error', error)
        }
      )
    },
    formatMenu ( data ) {
      const feat = []

      const normal = data.filter( k => {
        if (k.feat == 1) feat.push(k)
        return k.feat == 0
      })

      // console.log("normal:", normal)
      // console.log("feat:", feat)
      normal.sort((a,b) => (a.posicao > b.posicao) ? 1 : ((b.posicao > a.posicao) ? -1 : 0))

      this.menu = feat.concat(normal)

      console.log("menu:", this.menu)
    },
    parseIcon ( icon ) {
      if ( icon ) {
        return parseIcon(icon)
      }      
    },
    checkSize () {
      if ( this.slideLoop ) clearInterval(this.slideLoop)

      this.slideLoop = setTimeout(() => {
        if ( window.innerWidth < 1220 && !this.sliderOn ) {
          // this.$refs.flicking.init()
          this.sliderOn = true
        } else {
          this.sliderOn = false
          // this.$refs.flicking.destroy()
        }
      }, 200)
    },
    createRoute ( item ) {
      console.log("item:", item)

      let itemTag = item.categoria
          itemTag = encodeURIComponent(itemTag)

      const route = '/loja/categoria/' + item.idcategorialoja + '/' + itemTag

      return route
    },
    goToItem ( item ) {
      const route = this.createRoute(item)

      this.$router.push(route)
      this.closeMobileMenu()
    },
    closeMobileMenu () {
      this.$emit('close')
    }
  }
}
</script>
<style lang="scss">

  .mainMenu {
    width: 100%; height: 56px;
    display: flex; align-content: center; align-items: center; justify-content: center;
    border-top: 1px solid #FFF; border-bottom: 1px solid #FFF;
    padding: 0 1.4em;
    @extend %transition_3o;

    .menu {
      display: flex; align-content: center; align-items: center;

      a {
        display: block;
        text-transform: uppercase;
        font-weight: bold;
        margin: 0 $hmg;
        cursor: pointer;
        @extend %transition_3o;

        &:hover{
          color: $color-dark;
        }
        &:active{
          color: $color-primary;
        }
        // &:not(.btn):hover {
        //   color: $color-primary
        // }
        // &:not(.btn) {
        //   margin-left: $mg_mid;
        //   margin-right: 0;
        // }
        // &:last-child {
        //   margin-right: 0;
        // }

        // @media screen and (max-width: 1366px) {
        //   margin-right: $hmg_small;

        //   &:not(.btn) {
        //     margin-left: $hmg;
        //   }
        // }
        // @media screen and (max-width: 1220px) {
        //   margin-right: $hmg_small;

        //   &:not(.btn) {
        //     margin-left: $hmg_mid;
        //   }
        // }
        // @media screen and (max-width: 1024px) {
        //   margin-right: $hmg_small;

        //   &:not(.btn) {
        //     margin-left: $hmg_small;
        //   }
        // }
      }
    }

    .closeMobileMenu {
      display: none;
      width: 34px; height: 34px;
      z-index: 1000;
      position: absolute;
      top: $mg; right: $hmg;

      > span {
        display: block; width: 3px; height: 24px;
        left: 0; right: 0; margin: auto;
        position: absolute; top: 50%;
        border-radius: 2px;
        background-color: $color-secondary;

        &:first-child {
          transform: translate(-50%,-50%) rotate(45deg);
        }
        &:last-child {
          transform: translate(-50%,-50%) rotate(-45deg);
        }
      }
    }
    @media screen and ( max-width: 1024px ) {
      position: fixed; top: 0; right: 0; z-index: 5000;
      align-items: flex-start; padding-top: 15vh;
      transform: translateX(100%);
      width: 100%; max-width: 260px; height: 100%;
      border-top: 0; border-bottom: 0;
      background-color: $color-primary;
      text-align: left;

      .menu {
        flex-direction: column; justify-content: flex-start;
        width: 100%; padding: $hmg_mid;
        text-align: left;

        a {
          width: 100%;
          text-align: left;
          text-transform: capitalize;
          color: $color-secondary;
          font-size: 18px;
          margin-bottom: $hmg_mini;
        }
      }

      .closeMobileMenu {
        display: block;
      }

      &.active {
        transform: translateX(0);
      }
    }
  }
</style>