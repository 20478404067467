<template>
  <div class="pageHeader frame">
    <h2>
      <slot></slot>
    </h2>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
  .pageHeader {
    padding: $hmg;
    
    h2 {
      text-transform: capitalize;
    }
  }
</style>