<template>
  <controller :goToTopButton="true">
    <div class="page-title">
      <h3 class="text-capitalize">Bloqueados</h3>
    </div>
    <div class="page-inner">
      <transition name="slideup-small">
        <filter-controller @onFilter="filteBloqueados()"></filter-controller>
      </transition>
      <transition name="slideup-small">
        <descontos ref="descontos" v-if="filterType == 'descontos'" @onCancel="clearFilter()"></descontos>
      </transition>
    </div>
  </controller>
</template>

<script>
// import FinanceiroService from '@/services/financeiro.service.js'
import FilterController from '@/components/financeiro/bloqueados/utilities/FilterBloqueados.vue'

export default {
  components: {
    FilterController
  },
  props: {

  },
  data () {
    return {
      syncing: false,
      filterType: null
    }
  },
  mounted () {
    
  },
  methods: {
    
  }
}
</script>

<style lang="scss" scoped>
  
</style>