<template>
  <controller :lockScroll="ls">
    <page-header title="Captação">
      <template v-slot:side>
        <a href="#" @click.prevent="sair()" class="btn btn-secondary" v-if="action == 'novo'">Voltar</a>
        <a href="#" @click.prevent="addNew()" class="btn btn-primary" v-else>Novo Lead</a>
      </template>
    </page-header>
    <main>
      <filter-controller v-if="action != 'novo' && action != 'ver'" @onFilter="runFilter" @onProcessing="processing = $event" />
      <form-spinner v-if="processing && action != 'ver'" />
      <form-controller v-if="action == 'novo'" />
      <list-controller :scope="filter" v-else-if="filter.length > 0 && action != 'ver'" />
      <detail-controller v-else-if="action == 'ver' && idProspect" :idProspect="idProspect"/>
    </main>
  </controller>
</template>

<script>
import FormController from '@/components/captacao/form/Form.vue'
import FilterController from '@/components/captacao/utilities/Filter.vue'
import ListController from '@/components/captacao/template/Leads.vue'
import DetailController from '@/components/captacao/form/Details.vue'
import FormSpinner from '@/components/utilities/FormSpinner.vue'

export default {
  components: {
    FormController, FilterController, ListController, DetailController,
    FormSpinner
  },
  props: {
    action: {
      type: String
    },
    idProspect: {
      type: [String, Number]
    }
  },
  watch: {
    idProspect ( value ) {
      if ( value ) {
        this.toggleProspect(true)
      } else {
        this.toggleProspect(false)
      }
    }
  },
  data () {
    return {
      filter: [],
      processing: false,
      ls: false
    }
  },
  mounted () {
    if ( this.idProspect ) {
      this.toggleProspect(true)
    } else {
      this.toggleProspect(false)
    }
  },
  methods: {
    runFilter ( data ) {
      this.filter = data
    },
    toggleProspect ( state ) {
      window.scroll({
        top: 0,
        behavior: 'instant'
      })

      this.ls = state
    },
    addNew () {
      this.$router.push({ name: "Leads", params: { action: 'novo' }})
    },
    sair () {
      this.$router.push({ name: "Leads" })
    }
  }
}
</script>

<style lang="scss" scoped>
  
</style>