<template>
   <div class="page-filter">
      <h1>{{ title }} Módulo</h1>
      <form method="POST">
        <div class="row">
          <div class="form-group col-md-4">
            <input-control type="text" placeholder="Nome" v-model="nome" />
          </div>
          <div class="form-group col-md-4">
            <select-control type="text" placeholder="Módulo" v-model="modulo" :options="modulos" />
          </div>
          <div class="form-group col-md-4">
            <input-control type="text" placeholder="Caminho" v-model="caminho" />
          </div>
          <div class="form-group col-md-4">
            <input-control type="text" placeholder="Router Link" v-model="loadChildren" />
          </div>
          <div class="form-group col-md-4">
            <input-control type="text" placeholder="Ícone" v-model="icone" />
          </div>
        </div>
        <div class="form-actions row">
          <a href="#" class="btn btn-primary" @click.prevent="saveModulo" v-if="criarID === null">
            Salvar
          </a>
          <a href="#" class="btn btn-primary" @click.prevent="editModulo" v-else>
            Salvar
          </a>
          <a href="#" class="btn btn-primary" @click.prevent="close">
            Cancelar
          </a>
        </div>
      </form>
    </div>
</template>
<script>
import axios from 'axios'
import authHeader from '../../../services/auth-header'

export default {
  name: "criarModulos",
  props: {
    criarID: {
      type: [Number, String, Object],
      default: null
    }
  },
  watch: {
    criarID (newVal) {
      this.id = newVal.id
      this.loadChildren = newVal.loadChildren
      this.nome = newVal.name
      this.modulo = newVal.nome_modulo
      this.caminho = newVal.path
      this.icone = newVal.icon
    }
  },
  data () {
    return {
      title: null,
      id: null,
      nome: null,
      tipo: null,
      modulo: null,
      loadChildren: null,
      caminho: null,
      icone: null,
      modulos: [],
    }
  },
  mounted () {
    setTimeout(() => {
      this.getModulos()
      this.title = this.criarID === null ? "Criar" : "Editar"
    },100)
  },
  methods: {
    close () {
      this.$emit('close','')
    },
    getModulos () {
      axios.get(process.env.VUE_APP_API_URL + 'auth/getlistangularmodulos',{ headers: authHeader() })
      .then( response => {
        for (let index = 0; index < response.data.length; index++) {
          this.modulos.push({label: response.data[index]['angularModule'], value: response.data[index]['angularModule']})
        }
      })
      .catch( error => {
        console.error("error:", error)
      })
    },
    editModulo () {
      window.spinner.emit('open')
      axios.put(process.env.VUE_APP_API_URL + 'auth/editrole/' + this.id, {role: {
          name: this.nome,
          tipo: 'modulo',
          nome_modulo: this.modulo,
          path: this.caminho,
          icon: this.icone,
          loadChildren: this.loadChildren
        }
      },{ headers: authHeader() })
      .then(response => {
        this.close()
        window.toaster.emit('open', {style: "success", message: "Módulo editado com sucesso."})
        this.$emit('updateParentList','')
        return response.data;
      }).then(() =>{
        window.spinner.emit('close')
      });
    },
    saveModulo () {
      window.spinner.emit('open')
      axios.post(process.env.VUE_APP_API_URL + 'auth/createrole', {role: {
          name: this.nome,
          tipo: 'modulo',
          nome_modulo: this.modulo,
          path: this.caminho,
          icon: this.icone,
          loadChildren: this.loadChildren
        }
      },{ headers: authHeader() })
      .then(response => {
        this.close()
        window.toaster.emit('open', {style: "success", message: "Módulo criado com sucesso."})
        this.$emit('updateParentList','')
        return response.data;
      }).then(() =>{
        window.spinner.emit('close')
      });
    }
  }
}
</script>
<style lang="scss" scoped>

</style>