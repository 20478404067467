<template>
  <div class="">
    <div class="row no-padd" ref="favForm">
      <div class="col-md-12 form-group nop">
        <label>Descrição</label>
        <input-control
          v-model="fav.descricao" 
          placeholder="Ex. Site Rede" 
          name="descricao"
          type="text"
          :valid="valid.descricao"
          @keyup="valid.descricao = true"
          required
        />
      </div>
      <div class="col-md-12 form-group nop">
        <label>URL</label>
        <input-control
          v-model="fav.url"
          placeholder="Ex. https://rededecisao.com.br"
          name="url"
          type="text"
          :valid="valid.url" 
          @keyup="valid.url = true"
          @change="fixUrl"
          required
        />
      </div>
      <footer>
        <a href="#" class="btn btn-primary" @click.prevent="save()">Salvar</a>
      </footer>
    </div>
  </div>
</template>

<script>
import UserService from '@/services/user.service.js'
import FormService from '@/services/form.service.js'

export default {
  props: {
    pessoa: {
      type: Object
    }
  },
  data () {
    return {
      fav: {
        descricao: null,
        url: null,
      },
      valid: {
        descricao: true,
        url: true,
      }
    }
  },
  methods:{
    validate () {
      const form = this.$refs.favForm

      const validate = FormService.validate(form, this.valid)

      return validate.response
    },
    fixUrl () {
      const valid = this.fav.url.includes("http://") || this.fav.url.includes("https://")

      if ( !valid ) {
        this.fav.url = "https://" + this.fav.url
      }
    },
    save () {
      const valid = this.validate()

      if ( valid ) {
        const fav = {
          idPessoa: this.pessoa.idPessoa,
          ...this.fav
        }

        window.spinner.emit("open")
        UserService.saveFavorito(fav).then(
          response => {
            if ( response.status == 200 ) {
              window.toaster.emit('open', {style: "success", message: "Favorito salvo com sucesso.", floater: true})
              this.$emit("onSave")
            }
            window.spinner.emit("close")
          },
          () => {
            window.toaster.emit('open', {style: "warning", message: "Ops. Algo aconteceu! Não foi possível salvar o seu favorito no momento ", floater: true})
            window.spinner.emit("close")
          }
        )
      }
    }
  }
}
</script>

<style>

</style>