<template>
  <loja-controller :goToTopButton="true">
    <loja-page-header>Resultado da Busca</loja-page-header>
    <div class="frame">
      <!-- <spinner color="#FFF"/> -->
      <div class="productsList" v-if="list.length > 0 && !loading">
        <div v-for="item in list" :key="item">
          <loja-product-card :produto="item"></loja-product-card>
        </div>
      </div>
      <div v-if="list.length == 0 && !loading">
        <div class="emptyMessage">
          <fa :icon="['fal', 'face-meh' ]" class="icon"></fa>
          <p>Ops. Parece que não encontramos nada com as informações que você buscou.</p>
        </div>
      </div>
    </div>
  </loja-controller>
</template>

<script>
import LojaService from '../../services/loja.service';
import LojaProductCard from '../../components/loja/template/ProductCard'

export default {
  name: 'Busca',
  props: {
    level: [String],
    filter: [String, Number],
    query: [String, Number]
  },
  components: {
    LojaProductCard
  },
  watch:{
    level (value) {
      if ( value ) {
        this.allowSearch = false
        this.search()
      }
      console.log('level:', value)
    },
    filter (value) {
      if ( value ) {
        this.allowSearch = false
        this.search()
      }
      console.log('filter:', value)
    },
    query (value) {
      if ( value ) {
        this.allowSearch = false
        this.search()
      }
      console.log('query:', value)
    }
  },
  data () {
    return {
      list: [],
      loading: false,
      allowSearch: true,
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
    }
  },
  mounted () {
    this.search()
  },
  methods: {
    search () {
      window.spinner.emit("open")
      this.loading = true

      // console.log("user:", this.currentUser)

      const data = {
        // categoriasIds: this.level === 'categoria' && this.filter != 'todos' ? [parseFloat(this.filter)] : [],
        // subCategoriasIds: this.level === 'subcategoria' && this.filter != 'todos' ? [parseFloat(this.filter)] : [],
        categoriasIds:[1,2,3,4,5,6,7,8,9,10],
        subCategoriasIds: [],
        text: unescape(decodeURIComponent(this.query)),
        idPessoaResponsavel: this.currentUser?.idPessoa
      }

      console.log("search: ", data)

      LojaService.buscaProdutosLoja(data).then(
        response => {
          if ( response.data ) {
            console.log("response busca:", response.data)

            const eligibilty = []
            response.data.map( k => {
              const singleEligibitly = LojaService.processProductsAccess([k], this.currentUser, k.idcategorialoja)
              console.log('singleEligibitly:',singleEligibitly)
              eligibilty.push(singleEligibitly[0])
            })

            
            console.log('eligibilty:',eligibilty)
            this.list = eligibilty
            // this.list = response.data
            window.spinner.emit("close")
            this.loading = false
          }
        },
        error => {
          console.log('error', error)
          window.spinner.emit("close")
          this.loading = false
        }
      )
    }
  }
}
</script>

<style lang="scss">
  
</style>