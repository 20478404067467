<template>
  <div class="imageBox">
    <div>
      <h6 class="color-text">Imagem</h6>
    </div>
    <figure>
      <input-spinner v-if="loading" class="spinner"></input-spinner>
      <img v-else-if="!loading && produto.imgproduto" :src="thumb" />
      <fa :icon="['fas','image']" class="icon" v-else></fa>
    </figure>
    <div>
      <label class="uploader">
        + {{ produto.imgproduto ? 'Alterar' : 'Adicionar' }} Imagem
        <input type="file" @change="uploadImage"/>
      </label>
    </div>
  </div>
</template>

<script>
import LojaService from '@/services/loja.service.js'
import MarketplaceService from '@/services/marketplace.service.js'
import { toBase64 } from '@/services/utils.service.js'
import InputSpinner from  '@/components/utilities/InputSpinner.vue'

export default {
  components: {
    InputSpinner
  },
  props: {
    produto: {
      type: Object
    }
  },
  data () {
    return {
      loading: false,
      thumb: null
    }
  },
  mounted () {
    this.setImageRoute( this.produto.imgproduto)
  },
  methods: {
    setImageRoute (route) {
      // console.log("setImageRoute:", route)

      if ( route ) {
        LojaService.setImageRoute(route).then(
          response => {
            this.thumb = response.data
          }
        )
        
      } else return '/img/thumb_nopic.jpg'
    },
    uploadImage ( e ) {
      const file = e.target.files[0]
      this.loading = true

      toBase64(file).then(
        image => {
          const data = {
            idtipomaterial: this.produto.idtipomaterial,
            imagebase64: image
          }

          MarketplaceService.uploadImagemProduto( data ).then(
            response => {
              this.loading = false
              this.$emit('uploaded', response.data)
            },
            error => {
              this.loading = false
              console.log("error:", error)
            }
          )
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
  .imageBox {
    display: flex; flex-direction: column;
    align-content: stretch; align-items: stretch;
    width: 100%;
    border-radius: $border-radius;
    background-color: $color-secondary;
    margin-top: $mg;

    figure {
      flex: 1;
      position: relative;
      padding-top: 100%;
      background-color: $color-fade;
      margin: 0;
      overflow: hidden;
      
      >* {
        position: absolute;
        top: 50%; left: 0; right: 0;
        margin: auto;
        transform: translateY(-50%);
      }

      img {
        display: block;
        width: 100%; height: auto;
      }

      .icon {
        font-size: 64px;
        color: $color-secondary
      }

      .spinner {

      }
    }

    >div {
      padding: $hmg_small;

      &:first-child {
        //
      }
      &:last-child {
        //
      }

      a {
        font-weight: bold;
        color: $color-primary
      }
      h6 {
        margin: 0;
        padding: 0;
      }
    }

    .uploader {
      font-weight: bold;
      color: $color-primary;
      padding: 0; margin: 0;

      input {
        opacity: 0; z-index: -1;
        position: absolute;
      }
    }
  }

</style>