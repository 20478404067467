<template>
  <section class="upsell-banner">
    <transition name="slidedown-small">
      <div v-if="ready">
        <div class="hero"></div>
        <div class="sub-content">
          <h2>Matrícula realizada com sucesso!</h2>
        </div>
        <div class="banner-content">
          <h1>Oportunidade única</h1>
          <h4>Seu filho pode fazer parte do programa complementar da Rede Decisão com uma condição especial!</h4>
          <p>Já pensou em poder aproveitar seus dias enquanto seus filhos aprendem e se desenvolvem em segurança?</p>
          <p>No curso complementar da Rede Decisão, enquanto você trabalha, seus filhos fazem as lições de casa, tem acesso ao núcleo cultural e esportivo e participa de atividades educacionais lúdicas
            que ajudam em sua formação e desenvolvimento.</p>
          <h5>Não perca essa chance! Matricule seus filhos agora mesmo, com poucos cliques e aproveite!</h5>
          <footer>
            <router-link 
              class="btn btn-primary" 
              :to="{ 
                name: 'Nova Matrícula Autônoma', 
                params: { 
                  action: 'pre',
                  step: 'inicio', 
                  idMatricula: options.idMatriculaUpsell
                }
              }">Matricular Agora!</router-link>
              <router-link 
              class="btn btn-secondary" 
              :to="{ 
                name: 'App Rematrícula',
              }">Voltar para o App</router-link>
          </footer>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
export default {
  props: {
    options: {
      type: Object
    }
  },
  data () {
    return {
      ready: false
    }
  },
  mounted () {
    setTimeout(() => {
      this.ready = true
    },1000)
  }
}
</script>

<style lang="scss" scoped>
  .upsell-banner {
    position: fixed;
    z-index: 1000;
    top: 0; left: 0;
    width: 100vw; height: 100vh;
    background-color: $color-bg-transparent;
    backdrop-filter: blur(5px);
    overflow-y: auto;

    > div {
      position: absolute; left: 0; right: 0;
      top: $mg;
      background-color: $color-secondary;
      margin: auto; overflow: hidden;
      width: 100%; max-width: 540px;
      border-radius: $border-radius-large;
      @extend %shadow-high;
      @extend %transition_4e;
      
      .hero {
        height: 280px;
        width: 100%;
        background-size: cover;
        background-position: top;
        background-repeat: no-repeat;
        background-image: url('@/assets/img/cpm.jpg')
      }

      .sub-content {
        padding: $hmg $mg;
        background-color: $color-bg;
        >*{
          margin: 0; padding: 0;
        }
      }
      
      .banner-content {
        padding: $mg;

        h1 {
          color: $color-primary;
          margin-bottom: $hmg_small
        }
        h3 {
          margin-bottom: $hmg_small
        }

        footer {
          display: flex;
          justify-content: space-between;
          margin-top: $mg
        }
      }
    }
  }
</style>