<template>
  <section :class="['cartController', { on: on }]">
    <confirm 
      :backdrop="false"
      class="confirm"
      ref="confirm"
    ></confirm>
    <div class="cartControllerBody">
      <a href="#" @click.prevent="toggleCart('close')" class="btCloseCart">
        <fa :icon="['fas', 'xmark']" class="icon"></fa>
        <span></span>
      </a>
      <perfect-scrollbar class="main-scroller">
        <div class="cartHeader">
          <a href="#" @click.prevent="toggleCart('close')" class="btFecharCarrinhoMobile">
            <fa :icon="['far' , 'arrow-left-long']"></fa>
          </a>
          <h2>Sacola</h2>
          <template v-if="Object.keys(products).length > 0">
            <p>
              {{ amountLabel }} <!-- - 
              <a href="#" @click.prevent="saveCart()">                
                <b>Salvar como lista</b>
                <fa :icon="['fas', 'heart']" class="icon"></fa>
              </a> -->
            </p>
            <a href="#" @click.prevent="confirmClear()" class="btn btn-secondary" >
              <fa :icon="['far', 'trash-can']" class="icon"></fa>
              <span>Limpar Sacola</span>
            </a>
            <!-- <a href="#" @click.prevent="saveCart()" class="btn btn-primary" >
              Salvar Como Lista
            </a> -->
          </template>
        </div>
        <!-- <transition name="fade">
          <create-list v-if="saveList" @close="saveList = false"></create-list>
        </transition> -->
        <transition name="slidedownfade">
          <div class="cartToaster" v-if="toaster">
            <div>
              <p>Produto(s) inserido(s) no carrinho com sucesso</p>
              <fa :icon="['far', 'check']" class="icon" />
            </div>          
          </div>
        </transition>
        <ul class="cartProducts" v-if="Object.keys(products).length > 0">
            <li v-for="(item, i) in products" :key="item" class="">
              <div>
                <a href="#" @click.prevent="confirmRemove(i)" class="removeItem">
                  <fa :icon="['fas', 'xmark']"></fa>
                </a>
                <div>
                  <div class="thumb" :style="{backgroundImage: 'url(' + item.produto.thumb + ')'}"></div>
                  <p>
                    {{ item.produto?.descricaovenda }}
                    <template v-if="item.selecao?.length >= 1">
                      <span v-for="variacao in item.selecao" :key="variacao" class="sizeDetail">
                        {{ variacao?.composicao?.descricaoGrade }}
                      </span>
                    </template>                  
                  </p>
                  <template v-if="item.selecao?.length <= 1 && ( !item.produto.gradeProduto || item.produto.gradeProduto != 1 )">
                    <template v-if=" item.produto.servicoextra == 1 || item.produto.precoitem == 0">
                      <!-- <p>{{ item.selecao.length > 0 ? item.selecao[0].quantidade : item.produto.quantidade }}x</p> -->
                      <p>{{  item.selecao.length == 0 ? item.produto.quantidade : item.selecao[0].quantidade }}x</p>
                      <!-- <h5 v-if="!item.produto.percentual || item.produto.percentual == 0 && !item.produto.allowDesconto">{{ formatMoney(multiply(item.produto.PrecoVenda, item.produto.quantidade)) }}</h5> -->
                      <div v-if="item.produto.percentual && parseFloat(item.produto.percentual) > 0 || item.produto.allowDesconto && item.produto.UnidadeDesconto">
                        <p class="sizeDetail offPrice"><del>{{ formatMoney(multiply(item.produto.PrecoVenda, item.selecao.length == 0 ? item.produto.quantidade : item.selecao[0].quantidade )) }}</del></p>
                        <h5 v-if="item.produto.UnidadeDesconto && item.produto.percentual">{{ formatMoney(multiply(desconto(desconto(item.produto.PrecoVenda, item.produto.percentual),item.produto.UnidadeDesconto), item.selecao.length == 0 ? item.produto.quantidade : item.selecao[0].quantidade )) }}</h5>
                        <h5 v-else-if="item.produto.UnidadeDesconto && !item.produto.percentual">{{ formatMoney(multiply(desconto(item.produto.PrecoVenda, item.produto.UnidadeDesconto), item.selecao.length == 0 ? item.produto.quantidade : item.selecao[0].quantidade )) }}</h5>
                        <h5 v-else>{{ formatMoney(multiply(desconto(item.produto.PrecoVenda, item.produto.percentual), item.selecao.length == 0 ? item.produto.quantidade : item.selecao[0].quantidade )) }}</h5>
                      </div>
                      <h5 v-else>
                       {{ formatMoney(multiply(desconto(item.produto.PrecoVenda, item.produto.percentual), item.selecao.length == 0 ? item.produto.quantidade : item.selecao[0].quantidade )) }}
                      </h5>
                    </template>
                    <template v-else>
                      <template v-if="item.produto.comprasMultiplas === 1">
                        <template v-for="variacao in item.selecao" :key="variacao">
                          <p>{{ variacao.quantidade }}x</p>
                          <div v-if="item.produto.percentual && parseFloat(item.produto.percentual) > 0 || item.produto.allowDesconto && item.produto.UnidadeDesconto">
                            <p class="sizeDetail offPrice"><del>{{ formatMoney(multiply(variacao.composicao.valorVendaGrade, variacao.quantidade)) }}</del></p>
                            <h5 v-if="item.produto.UnidadeDesconto && item.produto.percentual">{{ formatMoney(multiply(desconto(desconto(variacao.composicao.valorVendaGrade, item.produto.percentual), item.produto.UnidadeDesconto), variacao.quantidade)) }}</h5>
                            <h5 v-else-if="item.produto.UnidadeDesconto && !item.produto.percentual">{{ formatMoney(multiply(desconto(variacao.composicao.valorVendaGrade, item.produto.UnidadeDesconto), variacao.quantidade)) }}</h5>
                            <h5 v-else>{{ formatMoney(multiply(desconto(variacao.composicao.valorVendaGrade, item.produto.percentual), variacao.quantidade)) }}</h5>
                          </div>
                          <h5 v-else>{{ formatMoney(multiply(desconto(variacao.composicao.valorVendaGrade, item.produto.percentual), variacao.quantidade)) }}</h5>
                          <!-- <h6 >{{ multiply(variacao.composicao.valorVendaGrade, variacao.quantidade) }}</h6> -->
                        </template>
                      </template>
                      <template v-else>
                        <p>{{ item.produto.quantidade }}x</p>
                        <h5 v-if="!item.produto.percentual || item.produto.percentual == 0  || !item.produto.allowDesconto">{{ formatMoney(multiply(item.produto.valorVenda, item.produto.quantidade)) }}</h5>
                        <div v-else>
                          <p class="sizeDetail offPrice"><del>{{ formatMoney(multiply(item.produto.valorVenda, item.produto.quantidade)) }}</del></p>
                          <h5 v-if="item.produto.UnidadeDesconto && item.produto.percentual">{{ formatMoney(multiply(desconto(item.produto.valorVenda, item.produto.percentual), item.produto.quantidade)) }}</h5>
                          <h5 v-else-if="item.produto.UnidadeDesconto && !item.produto.percentual">{{ formatMoney(multiply(desconto(item.produto.valorVenda, item.produto.percentual), item.produto.quantidade)) }}</h5>
                          <h5 v-else>{{ formatMoney(multiply(desconto(item.produto.valorVenda, item.produto.percentual), item.produto.quantidade)) }}</h5>
                        </div>
                      </template>
                    </template>
                  </template>
                  <template v-else>
                    <p>{{ getTotalItemAmount(item.selecao) }}x</p>
                    <!-- <p>desconto: {{ item.produto.percentual }}%</p> -->
                    <div v-if="item.produto.percentual && parseFloat(item.produto.percentual) > 0 || item.produto.allowDesconto && item.produto.UnidadeDesconto">
                      <p class="sizeDetail offPrice"><del>{{ formatMoney(getTotalItemValue(item.selecao)) }}</del></p>
                      <h5 v-if="item.produto.UnidadeDesconto && item.produto.percentual">{{ formatMoney(desconto(desconto(getTotalItemValue(item.selecao),item.produto.UnidadeDesconto), item.produto.percentual)) }}</h5>
                      <h5 v-else-if="item.produto.UnidadeDesconto && !item.produto.percentual">{{ formatMoney(desconto(getTotalItemValue(item.selecao), item.produto.percentual)) }}</h5>
                      <h5 v-else>{{ formatMoney(desconto(getTotalItemValue(item.selecao), item.produto.percentual)) }}</h5>
                    </div>
                    <h5 v-else>{{ formatMoney(getTotalItemValue(item.selecao)) }}</h5>
                  </template>
                </div>
                <span></span>
              </div>
              <template v-if="item.selecao?.length > 1">
                <div class="subItem" v-for="(variacao, iv) in item.selecao" :key="variacao">
                  <a href="#" @click.prevent="confirmRemove(i, iv)" class="removeItem">
                    <fa :icon="['fas', 'times']"></fa>
                  </a>
                  <div>
                    <p>{{ variacao.composicao.descricaoGrade }}</p>
                    <p>{{ variacao.quantidade }}x</p>
                    <template v-if="item.produto.percentual && parseFloat(item.produto.percentual) > 0 || item.produto.allowDesconto && item.produto.UnidadeDesconto">
                      <h6 v-if="item.produto.UnidadeDesconto && item.produto.percentual">
                        {{ formatMoney(multiply(desconto(desconto(variacao.composicao.valorVendaGrade, item.produto.percentual),item.produto.UnidadeDesconto), variacao.quantidade))}}
                      </h6>
                      <h6 v-else-if="item.produto.UnidadeDesconto && !item.produto.percentual">
                        {{ formatMoney(multiply(desconto(variacao.composicao.valorVendaGrade, item.produto.UnidadeDesconto), variacao.quantidade))}}
                      </h6>
                      <h6 v-else>
                        {{ formatMoney(multiply(desconto(variacao.composicao.valorVendaGrade, item.produto.percentual), variacao.quantidade))}}
                      </h6>
                    </template>
                    <h6 v-else>
                      {{ formatMoney(multiply(variacao.composicao.valorVendaGrade, variacao.quantidade)) }}
                      <!-- {{ formatMoney(multiply(variacao.composicao.valorVendaGrade, variacao.quantidade)) }} -->
                    </h6>
                    
                  </div>
                  <span></span>
                </div>
              </template>
            </li>
          </ul>
          <div class="cartFooter" v-if="Object.keys(products).length > 0" >
            <div class="cartTotals">
              <p>Total:</p>
              <h6 v-if="getTotalValueFromCart() != getTotalValueFromCart()">
                <del>{{ formatMoney(getTotalValueFromCart()) }}</del>
              </h6>
              <h3>{{ formatMoney(getTotalValueFromCart(true)) }}</h3>
            </div>
            <div>
              <a :href="getCheckoutRoute()" class="btn btn-primary btn-large">Finalizar Compra</a>
            </div>
          </div>
          <div v-else class="emptyMessage">
            <fa :icon="['fal', 'face-frown' ]" class="icon"></fa>
            <p>Ainda não existem itens no carrinho</p>
          </div>
      </perfect-scrollbar>
    </div>
    <span class="cardFix"></span>
    <span class="backdrop" @click="toggleCart('close')"></span>
  </section>
</template>

<script>
import LojaService from '@/services/loja.service'
import { formatMoney, multiply } from '@/services/utils.service'
// import CreateList from '../utilities/cart/CreateList'

export default {
  components: {
    // CreateList
  },
  props: {
    cart: {
      type: Object,
      default: null
    }
  },
  watch : {
    cart ( data ) {
      if ( data ) {
        this.products = data.produtos
        const total = this.getTotalItensFromCart()
        const suffix = total == 1 ? ' item' : ' itens'
        this.amountLabel = total + suffix
        
        this.processImages()
        console.log("carrinho: ", this.products)
      }
    }
  },
  name: 'CartController',
  data () {
    return {
      on: false,
      amountLabel: '0 itens',
      products: [],
      nomeCart: '',
      saveList: false,
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
      toaster: false
    }
  },
  mounted () {
    window.cart.on('open', () => {
      this.toggleCart('open')
    })
  },
  methods: {
    processImages () {
      this.products.map( (k, i) => {
        this.setImageRoute(k.produto?.imgproduto, i)
      })
    },
    toggleCart ( state ) {
      if ( state ) {
        this.on = state == 'open'

        if (this.on) window.pagescroll.emit('lock')
        else window.pagescroll.emit('unlock')
      }
    },
    setImageRoute ( value, i ) {
      // console.log("setImageRoute:", value)
      if ( value ) {
        LojaService.setImageRoute(value).then(
          response => {
            this.products[i].produto.thumb = response.data
          }
        )
      } else return '/img/thumb_nopic.jpg'
    },
    formatMoney ( value ) {
      return formatMoney(value)
    },
    multiply ( value, times ) {
      return multiply(value,times)
    },
    getTotalItensFromCart () {

      const total = LojaService.getTotalItensFromCart()
      
      this.$emit('total', total)
      return total
    },
    getTotalItemAmount ( items ) {

      let total = 0
      
      items?.map(k => {
        total += k.quantidade
      })

      return total
    },
    getTotalItemValue ( items ) {
      return LojaService.getTotalItemValue(items)
    },
    getTotalValueFromCart ( desconto ) {
      return LojaService.getTotalValueFromCart( desconto )
    },
    confirmClear () {
      this.$refs.confirm.run({
        message: "Tem certeza que deseja limpar sua sacola de compras?",
        confirmButton: "Sim",
        denyButton: "Não",
        callback: this.clear,
      })
    },
    clear ( response ) {
      // console.log('response:', response)

      if ( response ) {
        this.products = []
        this.amountLabel = '0 itens'
        this.$emit('clear')
      }
     
    },
    saveCart () {
      this.saveList = true
    },
    saveCartSubmit () {
      const self = this;

      let nome = this.nomeCart;
      LojaService.saveCart(nome).then(() => {

        self.showModal = false;
        this.toggleCart('close');
        this.clear();
      });
    },
    confirmRemove ( item, sub ) {
      this.$refs.confirm.run({
        message: "Tem certeza que deseja remover este item?",
        confirmButton: "Sim",
        denyButton: "Não",
        callback: this.removeItem,
        data: {
          item, sub
        }
      })
    },
    removeItem ( e , data ) {
      console.log("removeItem:", data)
      if ( e ) {
        const removeOptions = data
        const products = LojaService.updateCart('remove', removeOptions, this.currentUser.idPessoa)


        this.$emit('remove', products.carrinho)
      }
     
    },
    desconto ( value, discount ) {
      return LojaService.calcDesconto (value, discount)
    },
    getCheckoutRoute () {
      if ( this.currentUser ) {
        return '/loja/checkout/detalhes'
      } else {
        return '/loja/checkout/?goback=/loja/checkout/detalhes'
      }
    },
    toggleToaster ( state ) {
      this.toaster = state

      setTimeout(() => {
        this.toaster = false
      }, 5000)
    }
  } 
}
</script>

<style lang="scss">
  .cartController {
    position: fixed; top: 0; left: 0;
    width: 100%; height: 100%;
    z-index: 2147483650; pointer-events: none;
    @extend %transition_4o;

    .cartControllerBody {
      position: absolute; top: 0; right: 0;
      width: 30%; min-width: 440px; height: 100%; z-index: 100;
      background-color: $color-light;
      border-top-left-radius: 30px; border-bottom-left-radius: 30px;
      transform: translateX(110%);
      transform-origin: center right;
      @extend %shadow-high-left;
      @extend %transition_4e;

      @media screen and (max-width: 1024px) {
        max-width: 50%; width: 50%; 
      }

      @media screen and (max-width: 640px) {
        border-top-left-radius: 0; border-bottom-left-radius: 0;
        width: 100%; max-width: 100%; min-width: 100%;
      }

      >.ps {
        padding: $hmg $hmg 120px $hmg;
        min-height: 100%; max-height: 100%;
      }

      .emptyMessage {
        width: 100%;
        text-align: center;
        padding: $mg 0;

        .icon {
          font-size: 64px;
        }

        p {
          padding: 0; margin: $hmg 0 0 0;
        }
      }
    }


    .confirm {
      z-index: 101;
    }

    .backdrop {
      display: block;
      width: 100%; height: 100%;
      background-color: #000; opacity: 0;
      @extend %transition_4o;
      pointer-events: none;
    }

    .cardFix {
      position: absolute; top: 0; right: 0;
      z-index: 99; transform: translateX(110%);
      width: 100px; height: 100%;
      background-color: $color-secondary;
      opacity: 0;
    }

    &.on {
       pointer-events: all;
       
      > div {
        transform: translateX(0);
      }

      .cardFix { 
        opacity: 1; transform: translateX(0);
      }

      .backdrop {
        opacity: .5;
        pointer-events: all;
      }
    }

    .cartHeader {
      padding: $hmg_small $hmg;
      position: relative;

      .btFecharCarrinhoMobile {
        font-size: 18px; display: none !important;
        padding: $hmg_mid 0;

        @media screen and ( max-width: 640px ) {
          display: initial !important;
        }
      }

      .btn {
        position: absolute; right: 0; top: $hmg_small;
      }

      @media screen and (max-width: 1024px) {
        padding: 0;
      }

      a:not(.btn){
        display: inline-flex; align-content: center; align-items: center;
        .icon {margin-left: 3px;}
        &:hover {
          color: $color-primary;
        }
      }
    }

    .cartProducts {
      list-style: none; padding: 0;
      padding: 0 $hmg_small;

      @media screen and (max-width: 1024px) {
        padding: 0;
      }
      
      >li {
        display: block; padding: 0; width: 100%;
        
        .sizeDetail {
          font-size: 11px; line-height: 1; font-weight: bold;
          display: block;
          margin-top: $hmg_mini;

          &.offPrice {
            white-space: nowrap;
            margin-top: 0;
          }
        }
        
        > div {
          display: block; padding: 0; width: 100%;
          position: relative; height: 100px;
          padding: 0 $hmg_small 0 $hmg_small;
          margin-bottom: $mg_mini;

          &.subItem {
            height: 50px;
            margin-left: $hmg;
            width: calc(100% - 25px);
            &:last-of-type {margin-bottom: $mg;}
          }

          >div {
            display: flex; align-content: center; align-items: center;
            justify-content: space-between;
            position: relative; z-index: 111; height: 100%;

            .thumb {
              width: 75px; height: 75px;
              background-size: cover; background-repeat: no-repeat; background-position: center;
              border-radius: $border-radius-small;
            }

            p {
              padding: 0; margin: 0; margin-left: $hmg_mini;
              max-width: 33%; line-height: 1.1;
            }
            
            h5, h6 {
              padding: 0; line-height: 1; margin: 0; margin-left: $hmg_mini;
              color: $color-text;
            }
          }

          >span {
            display: block;
            width: 100%; height: 100%; top: 50%; 
            transform: translateY(-50%); left: 0; right: 0;
            margin: auto;
            position: absolute; z-index: 110;
            border-radius: $border-radius;
            background-color: $color-secondary;
            @extend %transition_4e;
          }

          .removeItem {
            display: flex; width: 24px; height: 24px;
            border-radius: 100%; border: 2px solid $color-secondary;
            background-color: $color-light;
            align-items: center; align-items: center; justify-content: center;
            font-size: 12px;
            position: absolute; right: 0; top: 0;
            transform: translate(40%, -40%);
            z-index: 120;
            @extend %transition_3e;

            &:hover {
              transform: translate(40%, -40%) scale(1.2);
            }
          }

          &:hover {
            >span {
              transform: translateY(-50%) scale(1.02,1.05);
            }
          }
        }
        
      }
    }

    .cartTotals {
      h6 {
        margin: 0; color: $color-text;
      }
    }

    .cartFooter {
      display: flex; align-items: center; align-content: center;
      justify-content: space-between;
      position: fixed; bottom: 0; right: 0;
      height: auto; min-height: 100px; width: 100%;
      z-index: 200;
      padding: $mg_mid;
      border-top: 1px solid $color-secondary;
      background-color: $color-light;
      border-bottom-left-radius: 30px;

      >div:first-child {
        padding-right: $hmg;
      }
      p {
        margin: 0;
      }
      h3 {
        color: $color-text;
        white-space: nowrap;
      }
      @media screen and (max-width: 640px) {
        padding: 0 $hmg_small;
      }
    }

    .btCloseCart {
      position: absolute; top: 45px;
      left: 0; transform: translateX(-35%);
      z-index: 200;
      display: block; width: 40px; height: 40px;

      @media screen and ( max-width: 640px ) {
        display: none;
      }
      >span {
        position: relative; width: 100%; height: 100%;
        border-radius: 100%; background-color: $color-light;
        z-index: 200; display: block;
        @extend %transition_4e;
      }

      .icon {
        position: absolute; top: 50%;
        left: 0; right: 0; margin: auto;
        transform: translateY(-50%);
        z-index: 201;
      }

      &:hover {
        >span {
          transform: scale(1.1);
        }
      }
    }
  }

  .cartToaster {
    width: 100%;
    padding: 0 $mg_small;
    margin-bottom: $hmg;
    @extend %transition_4e;

    >div {
      display: flex; 
      width: 100%;
      padding: $hmg_small $hmg_mid;

      align-content: center; align-items: center;
      justify-content: space-between;
      border-radius: $border-radius;
      background-color: $color-success;

      p {
        padding: 0; margin: 0;
        color: $color-secondary;
      }
    
      .icon {
        margin: 0;
        color: $color-secondary;
      }
    }
    
  }
</style>