<template>
  <section class="formWrap">
    <template v-if="ready">
      <header>
        <h4>Filtrar</h4>
      </header>
      <form class="frame fixed" v-if="ready" @keyup.enter="runFilter()">
        <div class="row nop nom">
          <div class="col-md-3 form-group">
            <select-control :options="options.unidades" label="Unidade" v-model="filter.idUnidade" placeholder="Selecione"/>
          </div>
          <div class="col-md-3 form-group">
            <select-control 
              :options="options.departamentos"
              label="Departamento"
              v-model="filter.idDepartamento"
              placeholder="Selecione"
              @change="changeDepartamento"
            />
          </div>
          <div class="col-md-3 form-group">
            <select-control 
              :options="options.setores" 
              label="Setor" 
              v-model="filter.idSetor" 
              placeholder="Selecione"
              @change="changeSetor"
            />
          </div>
          <div class="col-md-3 form-group">
            <select-control :options="options.funcoes" label="Função" v-model="filter.idFuncao" placeholder="Selecione"/>
          </div>
        </div>
        <div class="row nop nom">
          <div class="col-md-4 form-group">
            <select-control :options="options.situacoes" label="Situacao" v-model="filter.idSituacao" placeholder="Selecione"/>
          </div>
          <div class="col-md-8 form-group">
            <input-control label="Nome" v-model="filter.nome" placeholder="Ex. João da Silva"/>
          </div>
        </div>
        <footer>
          <a href="#" class="btn btn-primary" @click.prevent="runFilter()">
            Filtrar
          </a>
          <a href="#" class="btn btn-secondary" @click.prevent="clear()">
            Limpar
          </a>
        </footer>
      </form>
    </template>
    <form-spinner v-else/>
  </section>
</template>

<script>
import UserService from '@/services/user.service.js'
import AdmService from '@/services/adm.service.js'
import { createSelectOptions } from '@/services/utils.service.js'
import FormSpinner from '@/components/utilities/FormSpinner.vue'

export default {
  components: {
    FormSpinner
  },
  data () {
    return {
      ready: false,
      options: {
        unidades: [],
        departamentos: [],
        setores: [],
        funcoes: [],
        situacoes: []
      },
      optionsScope: {
        unidades: [],
        departamentos: [],
        setores: [],
        funcoes: [],
        situacoes: []
      },
      wait: {
        unidades: false,
        departamentos: false,
        setores: false,
        funcoes: false,
        situacoes: false
      },
      filter: {
        idUnidade: null,
        idDepartamento: null,
        idFuncao: null,
        idSetor: null,
        idSituacao: null,
        nome: null
      }
    }
  },
  mounted () {
    this.getUnidades()
    this.getDepartamentos()
    this.getSetores()
    this.getFuncoes()
    this.getSituacoes()
  },
  methods: {
    getUnidades () {
      UserService.getUnidades().then(
        response => {
          console.log("getUnidades:", response.data)
          // this.wait.unidades = true
          this.options.unidades = createSelectOptions(response.data, 'idUnidade' ,'descricao')
          this.optionsScope.unidades = createSelectOptions(response.data, 'idUnidade' ,'descricao')

          this.wait.unidades = true
          this.finishLoad()
        },
        error => {
          console.error(error)
        }
      )
    },
    getDepartamentos () {
      AdmService.getDepartamentos().then(
        response => {
          console.log("getDepartamentos:", response.data)
          // this.wait.unidades = true
          this.options.departamentos = createSelectOptions(response.data, 'idDepartamento' ,'nome')
          this.optionsScope.departamentos = createSelectOptions(response.data, 'idDepartamento' ,'nome')
  
          this.wait.departamentos = true
          this.finishLoad()
        },
        error => {
          console.error(error)
        }
      )
    },
    getSetores () {
      AdmService.getSetores().then(
        response => {
          console.log("getSetores:", response.data)
          // this.wait.unidades = true
          this.options.setores = createSelectOptions(response.data, 'idSetor' ,'descricao')
          this.optionsScope.setores = createSelectOptions(response.data, 'idSetor' ,'descricao')
          
          this.wait.setores = true
          this.finishLoad()
        },
        error => {
          console.error(error)
        }
      )
    },
    getFuncoes () {
      AdmService.getListFuncoes().then(
        response => {
          console.log("getFuncoes:", response.data)
          // this.wait.unidades = true
          this.options.funcoes = createSelectOptions(response.data, 'idFuncao' ,'nome')
          this.optionsScope.funcoes = createSelectOptions(response.data, 'idFuncao' ,'nome')
  
          this.wait.funcoes = true
          this.finishLoad()
        },
        error => {
          console.error(error)
        }
      )
    },
    getSituacoes () {
      AdmService.getSituacoes().then(
        response => {
          console.log("getSituacoes:", response.data)
          // this.wait.unidades = true
          this.options.situacoes = createSelectOptions(response.data, 'idSituacao' ,'descricao')
          this.optionsScope.situacoes = createSelectOptions(response.data, 'idSituacao' ,'descricao')
  
          this.wait.situacoes = true
          this.finishLoad()
        },
        error => {
          console.error(error)
        }
      )
    },
    finishLoad () {
      const done = !Object.values(this.wait).some( a => !a)
      console.log("done:", done)
      if ( done ) {
        this.ready = true
      }
    },
    changeDepartamento () {
      const setores = this.optionsScope.setores.filter(a => a.idDepartamento == this.filter.idDepartamento)
      this.options.setores = setores

      const funcoes = this.optionsScope.funcoes.filter(a => a.idDepartamento == this.filter.idDepartamento)
      this.options.funcoes = funcoes
    },
    changeSetor () {
      const funcoes = this.optionsScope.funcoes.filter(a => a.idSetor == this.filter.idSetor)
      this.options.funcoes = funcoes
    },
    clear () {
      this.filter = {
        idUnidade: null,
        idDepartamento: null,
        idFuncao: null,
        idSetor: null,
        idSituacao: null,
        nome: null
      }
    },
    validate () {
      const valid = Object.values(this.filter).some( a => !a)

      return valid
    },
    runFilter () {
      if ( this.validate() ) {
        this.$emit('onFilter', { wait: true })
        AdmService.getColaboradores( this.filter ).then(
          response => {
            console.log("getColaboradores:", response.data)
            this.$emit('onFilter', { wait: false, data: response.data })
          },
          () => {
            this.$emit('onFilter', { wait: false })
            window.toaster.emit('open', {style: "error", message: "Não foi possível buscar colaboradores no momento. Se o erro persistir procure nosso time de suporte.", floater: true})
          }
        )
      } else {
        window.toaster.emit('open', {style: "error", message: "Preencha ao menos um campo para filtrar.", floater: true})
      }
      
    }
  }
}
</script>

<style lang="scss" scoped>
  .formWrap {
    border-bottom: $border-component;

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $hmg_mid;
    }

    form {
      padding: $mg_mini $mg_mini $hmg_mid $mg_mini;

      footer {
        padding: $mg_mini;
        display: flex;
        align-items: center;
        gap: $mg_mini
      }
    }
  }
  
</style>