<template>
  <controller :goToTopButton="true">
    <div class="page-title">
      <h3 class="text-capitalize">Restauração de Base</h3>
    </div>
    <div class="page-inner frame mid fixed">
      <div class="lastCall" v-if="!loading">
        <div v-if="lastRestore">
          <label>Última Restauração</label>
          <h4 class="color-text">
            {{ formatDateTime(lastRestore) }}
          </h4>
        </div>
        <a href="#" :disabled="!allowRestore" class="btn btn-primary" @click.prevent="confirmRestore()">
          Restaurar Base
        </a>
      </div>
      <div v-if="restauracoes.length > 0">
        <ol class="cardList">
          <li class="">
            <div class="plItemInner">
              <span>ID</span>
              <span>Início</span>
              <span>Fim</span>
              <span>Status</span>
            </div>
          </li>
          <li v-for="restauracao in restauracoes" :key="restauracao">
            <card spacing="small" :alert="!restauracao.status">
              <div class="plItemInner">
                <span>
                  {{ restauracao.idHomRestore }}
                </span>
                <span>
                  {{ formatDateTime(restauracao.createdAt) }}
                </span>
                <span>
                  {{ restauracao.updatedAt ? formatDateTime(restauracao.updatedAt) : ' - ' }}
                </span>
                <span>
                  <template v-if="restauracao.status">Ok</template>
                  <span v-else class="tag">Restaurando</span>
                </span>
              </div>
            </card>
          </li>
        </ol>
      </div>
    </div>
    <confirm 
      :backdrop="false"
      class="confirm"
      ref="confirm"
    ></confirm>
  </controller>
</template>

<script>
import FerramentasService from '@/services/ferramentas.service'
import { formatDateTime } from '@/services/utils.service'

export default {
  data () {
    return {
      allowRestore: false,
      loading: false,
      lastRestore: null,
      restauracoes: []
    }
  },
  mounted () {
    this.getRestauracaoBase()
    // this.evaluateRestore()
  },  
  methods: {
    getRestauracaoBase () {
      window.spinner.emit("open")
      FerramentasService.getRestauracaoBase().then(
        response => {
          this.restauracoes = response.data
          console.log("response:", response)
          window.spinner.emit("close")

          this.evaluateRestore()
        },
        error => {
          console.log("error:", error)
          window.spinner.emit("close")
        }
      )
    },
    evaluateRestore () {
      const restoring = this.restauracoes.some( k => !k.status )
      this.allowRestore = !restoring
      console.log( 'evaluateRestore:', restoring )
      
      this.lastRestore = this.restauracoes[0].updatedAt ? this.restauracoes[0].updatedAt : this.restauracoes[1].updatedAt
    },
    confirmRestore () {
      this.$refs.confirm.run({
        message: "Tem certeza que deseja restaurar a base? Esta ação não pode ser desfeita!",
        confirmButton: "Sim",
        denyButton: "Não",
        callback: this.restore,
      })
    },
    restore (state) {
      if ( state ) {
        FerramentasService.restaurarBase().then(
          response => {
            console.log("response:", response)
            window.spinner.emit("close")

            if ( response.status == 200 ) {
              window.toaster.emit('open', {style: "success", message: "Restauração iniciada com sucesso. Aguarde o processamento."})
              
              setTimeout(() => {
                this.getRestauracaoBase()
              },1000)
              
            }
          },
          error => {
            console.log("error:", error)
            window.spinner.emit("close")
            window.toaster.emit('error', {style: "error", message: "Ops! Algo aconteceu. Não foi possível restaurar a base neste momento."})
          }
        )
      }
    },
    formatDateTime ( date ) {
      return formatDateTime(date, true)
    }
  }
}
</script>

<style lang="scss" scoped>
  .lastCall {
    display: flex;
    align-content: center; align-items: center;
    justify-content: space-between;
    background-color: $color-secondary;
    border-radius: $border-radius;
    padding: $hmg_small;
    margin-bottom: $mg;

    label {
      margin: 0;
      padding: 0;
      font-size: $font-size-mini;
      text-transform: uppercase;
    }

    h4 {
      margin: 0;
      padding: 0;
    }
  }

  .plItemInner {
    grid-template-columns: .4fr 4fr 4fr 1fr;
  }

  .tag {
    background-color: $color-secondary;
    border-radius: $border-radius-small;
    padding: $hmg_mini;
    font-size: $font-size-mini !important;
    text-transform: uppercase;
    font-weight: bold !important;
  }
</style>