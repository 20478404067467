<template>
  <section>
    <header>
      <h4>Histórico de {{ type ? type.label : 'Atendimentos' }}</h4>
      <template v-if="type && atividade">
        <a href="#" class="btToggle" @click.prevent="toggleView('atendimentosRelacionados')" v-if="active == 'historicoAtendimentos'">
          <fa :icon="['far', 'timeline']" />
          <span>Exibir apenas atendimentos relacionados</span>
        </a>
        <a href="#" class="btToggle" @click.prevent="toggleView('historicoAtendimentos')" v-else>
          <fa :icon="['far', 'timer']" />
          <span>Exibir histórico de atendimentos</span>
        </a>
      </template>
    </header>
    <card
      hover boxed 
      overflow="visible" 
      spacing="mini"
      v-for="(item,i) in historico"
      :key="item"
      :style="{ zIndex: historico.length - i }"
      @click.prevent="goToAtividade(item)"
      :class="['histCardWrap', { lastItem: i === historico.length - 1 }]"
    >
      <div class="histCard">
        <div class="timeline-wrap">
          <div class="marker">
            <span></span>
          </div>
        </div>
        <div class="content-wrap">
          <p>{{ formatDate(item.created_at) }}</p>
          <h5>{{ item.nomeCategoriaAtendimento }}</h5>
          <span>
            <fa :icon="['far','user-headset']"></fa>
            <p>{{ item.userNome ?? 'Não atribuído' }}</p>
          </span>
        </div>
      </div>
    </card>
  </section>
</template>

<script>

import { formatDate } from '@/services/utils.service.js'
import AtividadesService from '@/services/atividades.service.js'

export default {
  props: {
    id: {
      type: [Number, String]
    },
    type: {
      type: [Object]
    },
    target: {
      type: String
    },
    atividade: {
      type: Object
    }
  },
  data () {
    return {
      historico: null,
      active: 'historicoAtendimentos'
    }
  },
  mounted () {
    console.log("historico:", this.atividade)
    
    if ( this.atividade ) {
      this.historico = this.atividade[this.active]
    } else {
      this.getAtividadesAluno()
    }
    
  },
  methods: {
    formatDate ( date ) {
      return formatDate(date)
    },
    goToAtividade ( atividade ) {
      const currentSection = this.atividade ? this.$router.currentRoute._value.params.section : this.type.slug 
      console.log("currentSection:", currentSection)
      console.log("atividade:", atividade)
      this.$router.push({ name: 'Painel Atividades', params: { section: currentSection, action: 'ver', id: atividade.idAtividade }})
    },
    toggleView ( active ) {
      this.active = active
      this.historico = this.atividade[active]
    },
    getAtividadesAluno () {
      console.log('getAtividadesAluno', this.id)
      AtividadesService.getHistoricoAtendimentosAluno( this.id ).then(
        response => {
          console.log('response', response)
          this.historico = response.data
        },
        () => {

        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
  header {
    margin-bottom: $mg_mini;
    padding-left: $mg_mini;

    .btToggle {
      display: flex;
      align-items: center;
      color: $color-primary;
      margin-top: $hmg_mini;
      gap: $hmg_mini;
    }
  }

  .histCardWrap{
    cursor: pointer;

    .histCard {
      display: flex;
      align-items: flex-start;
      padding-top: 2px;
      
      .timeline-wrap {
        width: $mg_mid;
        position: relative;

        > div {
          display: flex; flex-direction: column;
          align-items: center; justify-content: center;
          width: 15px; height: 15px;
          border: $border-component;
          background-color: $color-secondary;
          border-radius: 100%;
          position: relative;
          

          span {
            width: 9px; height: 9px;
            background-color: $color-component;
            border-radius: 100%;
            position: relative;
            z-index: 100;
          }

          &:after {
            content: "";
            display: block;
            width: 1px; height: 100px;
            background-color: $color-component;
            position: absolute;
            bottom: 0; left: 0; right: 0;
            transform: translateY(100%);
            margin: auto;
          }
        }
      }

      .content-wrap {
        p { margin: 0; padding: 0 }
        span {
          display: flex;
          align-items: center;
          align-content: center;
          gap: $hmg_mini;
          line-height: 1
        }
      }
    }

    &.lastItem {
      .histCard {
        .timeline-wrap {
          > div {
            &:after {
              display: none;
            }
          }
        }
      }
    }
  }

  
</style>