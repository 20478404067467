<template>
  <controller :goToTopButton="true">
    <div class="page-title">
      <h3 class="text-capitalize">Propostas Rematrícula</h3>
      <transition name="slideRightToLeft">
        <a href="#" @click.prevent="toggleAction(true)" class="btn btn-primary" v-if="!add && !filterType">
          <span>Gerar Propostas</span>&nbsp;
          <fa :icon="['fas', 'screen-users']"></fa>
        </a>
      </transition>
      <transition name="slideRightToLeft">
        <a href="#" @click.prevent="toggleAction(false)" class="btn btn-primary" v-if="!remove && !filterType">
          <span>Remover Propostas</span>&nbsp;
          <fa :icon="['fas', 'trash-can']"></fa>
        </a>
      </transition>
    </div>
    <div class="page-inner">
      <transition name="slideup-small">
        <filter-controller ref="filter" @onClearFilter="clearFilter" @onFilter="previewPropostas" @onFilterType="filterType = $event" :state="state"></filter-controller>
      </transition>
      <preview v-if="preview.length > 0" :scope="preview" :state="state" @onConfirm="toggleConfirm"></preview>
    </div>
  </controller>
</template>

<script>
import Preview from '@/components/matriculas/propostasrema/form/Preview.vue'
import FilterController from '@/components/matriculas/propostasrema/utilities/Filter.vue'
import MatriculaService from '@/services/matricula.service.js'

export default {
  components: {
    FilterController, Preview
  },
  props: {
    action: {
      type: String
    }
  },
  data () {
    return {
      preview: [],
      propostas: null,
      add: true,
      remove: false,
      state: 'add',
      filterType: null
    }
  },
  mounted () {

  },
  methods: {
    toggleAction ( state ) {
      if ( state ) {
        this.add = true
        this.remove = false
        this.state = 'add'
      } else {
        this.remove = true
        this.add = false
        this.state = 'remove'
      }

      this.$refs.filter.clearFilter( false, false )
    },
    seriesList ( series ) {
      const drop = []

      series.map( k => {
        drop.push( k.idSerie )
      })

      return drop
    },
    previewPropostas ( options ) {
      const unidade = options.data.unidade && ( options.type == 'periodoLetivoUnidade' || options.type == 'serie' ) ? options.data.unidade : null
      const perCodigo = options.data.perCodigo && ( options.type == 'periodoLetivoUnidade' || options.type == 'periodoLetivo' || options.type == 'serie' ) ? options.data.perCodigo : null
      const turmas = options.data.turmas && options.type == 'turma' ? options.data.turmas : []
      const series = options.data.series && options.type == 'serie' ? this.seriesList(options.data.series) : []
      
      const data = {
        "idUnidade": unidade,
        "perCodigo": perCodigo,
        "turmas" : turmas,
        "series" : series,
        "matriculas" : []
      }

      this.propostas = data
      // console.log("data:", data)
      this.preview = []
      window.spinner.emit("open")
      if ( this.add ) {
        // console.log("preview add")
        MatriculaService.previewPropostasRema( data ).then(
          response => {
            console.log("response:", response)
            if ( response.data[0].length > 0 && response.data[0] != 'Preview propostas de rematricula...' ) {
              this.preview = response.data[0]
            } else {
              window.toaster.emit('open', {style: "error", message: "Nenhuma propostas de rematrícula encontrada com os dados informados.", floater: true})
            }
            window.spinner.emit("close")
          },
          () => {
            window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível buscar propostas de rematrícula com os dados informados.", floater: true})
            window.spinner.emit("close")
          }
        )
      }
      if ( this.remove ) {
        // console.log("preview remove")
        MatriculaService.previewRemovePropostasRema( data ).then(
          response => {
            console.log("response:", response)
            if ( response.data[0].length > 0 && response.data[0] != 'Preview propostas de rematricula...' ) {
              this.preview = response.data[0]
            } else {
              window.toaster.emit('open', {style: "error", message: "Nenhuma propostas de rematrícula encontrada com os dados informados.", floater: true})
            }
            window.spinner.emit("close")
          },
          () => {
            window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível buscar propostas de rematrícula com os dados informados.", floater: true})
            window.spinner.emit("close")
          }
        )
      }
    },
    clearFilter ( resetState ) {
      this.preview = []
      this.propostas = null
      this.filterType = null

      if ( resetState ) {
        this.state = 'add'
        this.add = true
        this.remove = false
      }
    },
    toggleConfirm ( propostas ) {
      console.log("state", this.state)
      if ( this.state == 'add' ) this.createPropostas()
      else if ( this.state == 'remove' ) this.removePropostas(propostas) 
      // state == 'add' ? createPropostas : removePropostas
    },
    createPropostas () {
      console.log("createPropostas")
      window.spinner.emit("open")

      MatriculaService.createPropostasRema( this.propostas ).then(
        response => {
          if ( response.status == 200 || response.status == 201 ) {
            window.toaster.emit('open', {style: "success", message: "Tudo certo. Propostas criadas com sucesso!", floater: true})

            setTimeout(() => {
              this.clearFilter(true)
            }, 1000)
          }
          window.spinner.emit("close")
        },
        () => {
          window.spinner.emit("close")
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível buscar propostas de rematrícula com os dados informados.", floater: true})
        }
      )
    },
    removePropostas ( propostas ) {
      console.log("remove:", propostas)

      const data = {
        "idUnidade": null,
        "perCodigo": null,
        "turmas" : [],    
        "series" : [],
        "matriculas" : []
      }

      propostas.map( k => {
        data.matriculas.push(k.idMatricula)
      })

      // console.log("remove data:", data)
      window.spinner.emit("open")
      MatriculaService.removePropostasRema( data ).then(
        response => {
          if ( response.status == 200 || response.status == 201 ) {
            window.toaster.emit('open', {style: "success", message: "Tudo certo. Propostas removidas com sucesso!", floater: true})

            setTimeout(() => {
              this.clearFilter(true)
            }, 1000)
          }
          window.spinner.emit("close")
        },
        () => {
          window.spinner.emit("close")
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível remover as propostas de rematrícula com os dados informados.", floater: true})
        }
      )
    }
  }
}
</script>

<style>

</style>