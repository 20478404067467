<template>
  <section>
    <header>
      <h4>Resultado de Busca</h4>
      <p v-if="colaboradores">Sua busca retornou {{ colaboradores.length }} {{ colaboradores.length == 1 ? 'colaborador' : 'colaboradores'  }}</p>
    </header>
    <div>
      <table class="content-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nome</th>
            <th>Função</th>
            <th>Área</th>
            <th>Departamento</th>
            <th>Unidade</th>
            <th>Situação</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="colaborador in colaboradores" :key="colaborador">
            <td>{{ colaborador.idColaborador }}</td>
            <td>{{ colaborador.nome_pessoa }}</td>
            <td>{{ colaborador.nome_funcao }}</td>
            <td>{{ colaborador.nome_setor }}</td>
            <td>{{ colaborador.departamento }}</td>
            <td>{{ colaborador.nome_unidade }}</td>
            <td>{{ colaborador.nome_situacao }}</td>
            <td>
              
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    scope: {
      type: Array
    }
  },
  watch: {
    scope ( value ) {
      if ( value ) this.colaboradores = Object.assign([], value)
    }
  },
  data () {
    return {
      colaboradores: null
    }
  },
  mounted () {
    console.log("mounted - colaboradores:", this.scope)
    this.colaboradores = Object.assign([], this.scope)
  }
}
</script>

<style lang="scss" scopes>

</style>