<template>
  <header>
    <div>
      <div>
        <div class="logo-wrap">
          <a href="/">
            <logo :color="env == 'PROD' ? '#ED1C24' : '#444'" size="30px"/>
          </a>
        </div>
        <favorites v-if="!isMobile"></favorites>
      </div>
      <alert></alert>
    </div>
    <div class="search-wrap" v-if="!isMobile">
      <header-search />
    </div>
    <div v-else>
      <a href="#" :class="['mobile-menu-toggle', { close: mobileMenu }]" @click.prevent="toggleMobileMenu()">
        <span></span>
        <span></span>
        <span></span>
      </a>
    </div>
    <div class="mobile-menu" v-if="isMobile && mobileMenu">
      <header-search></header-search>
      <favorites mobile></favorites>
    </div>
  </header>
</template>

<script>
import Logo from '@/components/template/logo.vue'
import Favorites from '@/components/navigation/Favorites.vue'
import Alert from '@/components/utilities/notifications/Alert.vue'
import HeaderSearch from '@/components/utilities/HeaderSearch.vue'

export default {
  name: "Header",
  components: {
    Logo, Favorites, Alert, HeaderSearch
  },
  props: {
    env: {
      type: String
    }
  },
  data () {
    return {
      isMobile: false,
      resizeTimeout: null,
      mobileMenu: false
    }
  },
  mounted () {
    this.trackMobile()
    window.addEventListener('resize', this.trackMobile)
  },
  methods: {
    trackMobile () {
      if ( this.resizeTimeout ) clearTimeout(this.resizeTimeout)

      this.resizeTimeout = setTimeout(() => {
        this.isMobile = window.innerWidth < 1024
        console.log("idMobile:", this.isMobile)
      },200)
    },
    toggleMobileMenu ( state ) {
      if ( state ) {
        this.mobileMenu = state
      } else {
        this.mobileMenu = !this.mobileMenu
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  header{
    display: flex; align-content: center;
    justify-content: space-between;
    width: 100%; height: 60px;
    //border-bottom: $border-component;
    background-color: $color-secondary;
    // position: fixed; top: 0; left: 0;

    &.HOM{
      background-color: $color-dev;
    }

    >div {
      flex: 1;  
      display: flex; align-items: center;
      align-items: center; justify-content: space-between;

      &:last-child, &:nth-child(2) {
        flex: 0;
      }

      @media screen and (max-width: 1024px) {
        &:last-child:not(.mobile-menu), &:nth-child(2):not(.mobile-menu) {
          min-width: 60px; max-width: 60px;
        }
      }

      >div {
        flex: 1;
        display: flex; align-items: center;
        align-items: center;
      }
      .logo-wrap {
        display: flex; align-items: center; align-items: center;
        flex-direction: column; justify-content: center;
        width: 60px; height: 60px;
        
        a {
          @extend %transition_4e;

          &:hover {
            transform: scale(1.1);
          }
        }

        p {
          font-size: $font-size-small;
          font-weight: bold;
          margin: 0; padding: 0;
        }
      }
    }
    .search-wrap {
      max-width: 354px; min-width: 300px;
      border-left: $border-component;
    }
  }

  .mobile-menu-toggle {
    display: flex; flex-direction: column;
    align-items: center; justify-content: space-between;
    width: 40px; height: 32px;
    padding: $mg_mini; margin: auto;
    
    span {
      display: block; margin: auto;
      width: 24px; height: 1px;
      background-color: $color-primary;
      @extend %transition_4e;
    }

    &.close {
      span {
        &:nth-child(1) {
          transform: rotate(-45deg) translate(-18%);
          color: $color-text
        }
        &:nth-child(2) {
          display: none
        }
        &:nth-child(3) {
          transform: rotate(45deg) translate(-18%);
          color: $color-text
        }
      }
    }
  }

  .mobile-menu {
    display: block; width: calc(100vw - 60px);
    height: calc(100vh - 60px);
    background-color: $color-bg-transparent;
    backdrop-filter: blur(10px);
    position: fixed; right: 0; top: 60px;
    z-index: 500;
    border-top-left-radius: 6px;
    border-top: $border-component; border-left: $border-component;
    overflow: hidden;
  }
</style>