<template>
  <card v-if="item" class="produto" :listed="false" :fixed="false">
    <h4 class="productName" @click="goToProduto(item)">{{ item.descricaovenda }}</h4>
    <transition name="flipin">
      <product-config
        v-if="config == 'grade'"
        :produto="item"
        class="configSelector"
        type="grade"
        @select="setSize"
        :selected="item.selectedSize"
         @goback="goBack('grade')"
      ></product-config>
    </transition>
    <transition name="flipin">
      <product-config
        v-if="config == 'aluno'"
        :produto="item"
        :selected="item.selectedAluno"
        type="aluno"
        class="configSelector"
        @select="setAluno"
        @goback="closeConfig()"
      ></product-config>
    </transition>
    <transition name="flipin">
      <product-config
        v-if="config == 'turma'"
        :produto="item"
        class="configSelector"
        type="turma"
        @select="setTurma"
        :selected="item.selectedTurma"
         @goback="goBack('turma')"
      ></product-config>
    </transition>
    <transition name="flipin">
      <amount-selector
        v-if="amount"
        class="configSelector"
        @goback="goBack('quantidade')"
        @updateamount="updateAmount($event)"
        @addtocart="addToCart()"
      ></amount-selector>
    </transition>
    <div
      v-if="thumb"
      :class="['productThumb', {off: amount || config || aluno, wbg: item.idcategorialoja == 5 || !item.imgproduto }]"
      :style="{backgroundImage: `url('${ thumb }')`}"
      @click="goToProduto(item)"
    >
    </div>
    <div class="productThumb thumbSpinner" v-else>
      <div>
        <input-spinner />
      </div>
    </div>
    <div class="productDetails">
      <div>
        <template v-if="item.percentual && parseFloat(item.percentual) > 0 || item.allowDesconto && item.UnidadeDesconto">
          <span>
            <h6><del>{{
              item.servicoextra === 1 || item.precoitem === 0 ? formatMoney(item.PrecoVenda) : formatMoney(item.valorVenda) 
            }}</del></h6>
            <h5 v-if="item.UnidadeDesconto && item.percentual">{{
              item.servicoextra === 1 || item.precoitem === 0 ? formatMoney(desconto(desconto(item.PrecoVenda, item.percentual), item.UnidadeDesconto)) : formatMoney(desconto(desconto(item.valorVenda, item.percentual), item.UnidadeDesconto)) 
            }}</h5>
            <h5 v-else-if="item.UnidadeDesconto && !item.percentual">{{
              item.servicoextra === 1 || item.precoitem === 0 ? formatMoney(desconto(item.PrecoVenda, item.UnidadeDesconto)) : formatMoney(desconto(item.valorVenda, item.UnidadeDesconto)) 
            }}</h5>
            <h5 v-else>{{
              item.servicoextra === 1 || item.precoitem === 0 ? formatMoney(desconto(item.PrecoVenda, item.percentual)) : formatMoney(desconto(item.valorVenda, item.percentual)) 
            }}</h5>
          </span>
          <h3>{{ getParcelamento(item, true) }}</h3>
          <h6 class="color-text">{{ getParcelamento(item, true, true) }}</h6>
        </template>
        <template v-else>
          <h5>{{
            item.servicoextra === 1 || item.precoitem == 0 ? formatMoney(item.PrecoVenda) : formatMoney(item.valorVenda) 
          }}</h5>
          <h3>{{ getParcelamento(item, false) }}</h3>
          <h6 class="color-text">{{ getParcelamento(item, true, true) }}</h6>
        </template>
      </div>
      <div>
        <div class="addToCartWrap">
          <add-to-cart
            :product="item"
            @getConfig="getConfig"
            @closeConfig="closeConfig()"
            :amount="amountValue"
            ref="addToCart"
            v-if="canBuy(item).can"
          ></add-to-cart>
          <div class="noBuyAlert" :title="canBuy(item).message" v-else>
            <fa :icon="['fas', 'user-lock']" :class="['icon', { on: canBuy(item).motive != 'comprado' }]"></fa>
            <fa :icon="['fas', 'box-check']" :class="['icon', { on: canBuy(item).motive == 'comprado' }]" ></fa>
          </div>
        </div>
        <div class="addToFavWrap">
          <!-- <add-to-favorites 
            :product="item"
          ></add-to-favorites> -->
        </div>
      </div>
    </div>
  </card>
</template>

<script>
import AddToCart from '../utilities/AddToCart'
// import AddToFavorites from '../utilities/AddToFavorites'
import ProductConfig from '../utilities/cart/ProductConfig'
import AmountSelector from '../utilities/cart/AmountSelector'
import LojaService from '../../../services/loja.service'
import { formatMoney, divide } from '../../../services/utils.service'
import InputSpinner from '@/components/utilities/InputSpinner.vue'

export default {
  name: 'LojaProductCard',
  components: {
    AddToCart, ProductConfig, AmountSelector, InputSpinner
    // AddToFavorites
  },
  props: {
    produto: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: 0
    }
  },
  watch: {
    produto ( value ) {
      if ( value ) {
        // console.log("produto card:", value)
        this.item = value
      }
    }
  },
  data () {
    return {
      amount: false,
      amountValue: null,
      config: null,
      item: null,
      hasAluno: false,
      aluno: null,
      alunos: [],
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
      thumb: null
    }
  },
  mounted () {
    if ( this.produto ) this.item = this.produto
    // console.log("produto card:", this.item)
    this.hasAluno = this.currentUser?.alunos?.length
    this.setImageRoute(this.item.imgproduto)
  },
  methods: {
    defineOptions () {
      if ( window.innerWidth < 1220 ) return this.mobileOptions
      else return this.options
    },
    formatMoney (value) {
      return formatMoney(value)
    },
    divide (value, times) {
      return divide(value,times)
    },
    setImageRoute (route) {
      // console.log("setImageRoute:", route)

      if ( route ) {
        LojaService.setImageRoute(route).then(
          response => {
            this.thumb = response.data
          }
        )
        
      } else return '/img/thumb_nopic.jpg'
    },
    goToProduto (item) {
      // console.log("goToProduto:", item)
      // const item = item.servicoextra == 1 ? item.idtipomaterial : item.iditemmaterial
      const tipoMaterial = item.idtipomaterial ? item.idtipomaterial : item.id_tipomaterial
      const iditemmaterial = !item.iditemmaterial ? 'null' : item.iditemmaterial
      // console.log("tipoMaterial:", tipoMaterial)
      // console.log("iditemmaterial:", item.iditemmaterial)
      // console.log("servicoextra:", item.servicoextra)

      this.$router.push({name: 'Produto', params: {item: iditemmaterial, tipo: tipoMaterial, extra: parseFloat(item.servicoextra) }})
    },
    closeConfig () {
      this.config = null
      this.amount = false
      
      this.$refs.addToCart.resetCartButton()

      setTimeout(() => {
        this.item.selectedAluno = []
        this.item.selectedTurma = []
      },1000)
    },
    setSize ( size ) {
      // console.log("setSize:", size)
      this.item.selectedSize = size

      if ( this.item.comprasMultiplas == 1 ) {
        this.config = null
        this.amount = true
      } else {
        this.addToCart()
      }
    },
    setAluno ( aluno ) {
      // console.log("setAluno:", aluno)
      // console.log('selectedAluno:', this.item.selectedAluno)

      const exist = this.item.selectedAluno ? this.item.selectedAluno.filter( k => {
        return aluno.idAluno == k.idAluno
      }).length > 0 : null

      // console.log("this.item:", this.item)

      if ( !exist ) {
        this.item.selectedAluno.push(aluno)
        // console.log("caiu aqui...")
      }
      
      if ( this.item.servicoextra == 1 ) {
        if ( this.item.categoriaLivre == 1 && this.item.comprasMultiplas == 1 && this.item.numeroturmas != 1 ) {
          this.config = null
          this.amount = true
        } else if ( this.item.comprasMultiplas == 0 && this.item.numeroturmas != 1 ) {
          this.addToCart()
        } else {
          this.getConfig('turma')
        }
        
      } else if ( this.item.gradeProduto == 1 ) {
        this.getConfig('grade')
      } else if ( this.item.numeroturmas == 0 ) {
        this.addToCart()
      }
      
      // console.log("produto after add aluno:", this.item)
    },
    setTurma ( turma ) {
      // console.log("setTurma:", turma)
      this.item.selectedTurma.push(turma)
      
      if ( this.item.comprasMultiplas == 1 && this.item.servicoextra != 1 ) {
        this.config = null
        this.amount = true
      } else {
        this.addToCart()
      }
    },
    updateAmount (amount) {
      this.item.quantidade = amount
      this.amountValue = amount
    },
    addToCart () {
      // console.log("addToCart - item:", this.item)
      this.$refs.addToCart.addToCart(this.item)

      setTimeout(() => {
        this.closeConfig()
      },500)
      
    },
    getConfig ( config ) {
      this.config = config
    },
    goBack ( source ) {
      if ( source === 'grade' || source === 'turma') {
        this.item.selectedAluno = []
        this.config = 'aluno'
      }
      if ( source === 'quantidade' ) {
        this.amount = false
        this.config = 'grade'
      }
    },
    desconto ( value, discount ) {
      return LojaService.calcDesconto (value, discount)
    },
    canBuy ( produto ) {
      console.log("canBuy - produto:", produto)
      console.log("currentUser:", this.currentUser)

      const hasLogin = this.currentUser != null
      this.hasLogin = hasLogin

      const hasAluno = this.currentUser?.alunosProdutos != null
      this.hasAluno = hasAluno

      const allowed = produto.podeComprar
      const bloqueioCompra = produto.bloqueioCompra

      console.log("hasLogin:",hasLogin)
      console.log("bloqueioCompra:",bloqueioCompra)
      console.log("allowed:",allowed)

      var message = "Para comprar este produto você precisa estar logado"
      let can = false
      let motive = ""

      if ( produto.categoriaLivre == 1 && !bloqueioCompra ) {
        can = true
        message = "Compra Liberada"
        motive = "livre"
      } else {
        if ( bloqueioCompra ) {
          can = false
          message = "Ops. Infelizmente você não pode comprar este produto. Quer saber mais? Procure nosso time de atendimento."
          motive = "bloqueio-financeiro"
        } else if ( hasLogin && hasAluno && allowed && produto.comprado != 1 ) { 
          can = true
          motive = 'allowed'
        } else if ( produto.comprado == 1 ){
          can = false
          message = "Você já comprou este produto"
          motive = "comprado"
        } else if ( !allowed ) {
          can = false
          message = "Você não pode comprar este produto pois ele é restrito a uma turma específica."
          motive = "seguimento"
        }
      }

      const drop = {
        can, message, motive
      }
      
      console.log("drop:", drop)
      return drop
    },
    getParcelamento ( produto, desconto, label ) {
      const fp = produto.formasPagamento
      var parcelas, sfp

      if ( fp ) {
        const ordered = produto.formasPagamento.sort((a,b) => b.parcelas - a.parcelas)
        parcelas = ordered[0].parcelas

        sfp = ordered[0].cartao == 1 ? 'no cartão' : 'no boleto'

      }
      
      if ( desconto ) {
        var value
        if ( produto.servicoextra === 1 || produto.precoitem === 0 ) {
          if ( produto.UnidadeDesconto && produto.percentual ) {
            value = formatMoney(divide(this.desconto(this.desconto(produto.PrecoVenda, produto.percentual), produto.UnidadeDesconto), parcelas))
          } else if ( produto.UnidadeDesconto && !produto.percentual ) {
            value = formatMoney(divide(this.desconto(produto.PrecoVenda, produto.UnidadeDesconto), parcelas))
          } else {
            value = formatMoney(divide(this.desconto(produto.PrecoVenda, produto.percentual), parcelas))
          }
        } else {
          if ( produto.UnidadeDesconto && produto.percentual ) {
            value = formatMoney(divide(this.desconto(this.desconto(produto.valorVenda, produto.percentual), produto.UnidadeDesconto), parcelas))
          } else if ( produto.UnidadeDesconto && !produto.percentual ) {
            value = formatMoney(divide(this.desconto(produto.valorVenda, produto.UnidadeDesconto)), parcelas)
          } else {
            value = formatMoney(divide(this.desconto(produto.valorVenda, produto.percentual), parcelas))
          }
          
        }
        // const 
        return label ? sfp : ( 'até ' + parcelas + 'x ' + value )
      } else {
        const value = produto.servicoextra === 1 || produto.precoitem === 0 ? formatMoney(divide(produto.PrecoVenda, parcelas)) : formatMoney(divide(produto.valorVenda, parcelas))
        return label ? sfp : ( 'até ' + parcelas + 'x ' + value )
      }
      
    }
  }
}
</script>

<style lang="scss" >
.addToCartWrap, .addToFavWrap {
    display: flex; justify-content: flex-end;
  }
  .produto {
    display: flex; flex-direction: column;
    align-content: stretch; align-items: stretch;
    height: 600px; width: 100%;
    padding: $hmg_small;

    // @media screen and (max-width: 1366px) {
    //   // padding: $hmg_small;
    //   height: 580px;
    // }

    // @media screen and (max-width: 1220px) {
    //   max-width: 33% !important;
    // }

    // @media screen and (max-width: 720px) {
    //   max-width: 50% !important;
    // }

    // @media screen and (max-width: 440px) {
    //   max-width: 100% !important;
    // }

    h1, h2, h3, h4, h5, h6 {
      font-family: $font-family;
      color: $color-text;
    }

    .card-controller {
      height: 100%; width: 100%; max-width: 400px ;
      padding: $hmg; min-width: 100%;
    }

    .productName {
      text-align: center;
      cursor: pointer;
      // min-height: 80px;
    }

    .productThumb {
      width: 100%; flex: 1;
      background-position: center; background-repeat: no-repeat; background-size: cover;
      cursor: pointer; margin: $hmg 0;
      position: relative; z-index: 200;
      @extend %transition_4e;
      border-radius: $border-radius;
      background-color: $color-bg;

      &.wbg {
        background-size: contain;
      }

      &.off {
        opacity: 0;
      }
      &:hover {
        transform: scale(1.1);
      }

      &.thumbSpinner {
        position: relative;
        display: flex; align-items: center;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        background-color: $color-bg;
        
        >div {
          display: block;
          position: absolute;
          top: 50%; left: 0; right: 0;
          margin: auto;
          transform: translateY(-50%);
          display: flex; align-items: center;
          justify-content: center;
        }
      }
    }

    .productDetails {
      display: flex; align-content: stretch; align-items: stretch;

      > div {
        > span {
          display: flex; align-items: flex-end; align-content: flex-start;

          h6 { padding: 0 $hmg_mini 1px 0;}
        }
        
        &:first-child {
          width: 65%;
          text-align: left;

          >* {
            text-align: left;
          }
        }
        &:last-child {
          width: 35%;
        }
      }
    }
  }

  .addToCartWrap, .addToFavWrap {
    display: flex; justify-content: flex-end;
  }
  .item {
    height: 560px; padding: $hmg_mid $hmg_mid $mg $hmg_mid;
    width: 22%; min-width: 330px; max-width: 400px;

    @media screen and (max-width: 1220px) {
      width: 30%; min-width: 330px; max-width: 400px;
    }
    @media screen and (max-width: 540px) {
      width: 90%; min-width: 330px; max-width: 400px;
    }
    > * {
      height: 100%;

      .card-controller {
        height: 100%; width: 100%;
        display: flex; flex-direction: column;
        align-content: stretch; align-items: stretch;
        padding: $hmg;
      }
    }
    
    h1, h2, h3, h4, h5 {
      font-family: $font-family;
      color: $color-text;
    }

    &.card-controller {
      height: 100%;
      padding: $hmg;
      max-width: 400px;
    }

    .productName {
      text-align: center;
      cursor: pointer;
    }

    
  }
  
  .discount {
    display: flex; align-items: flex-end;

    >h6 {
      padding-right: $hmg_mini;
    }
  }

  .configSelector {
    position: absolute;
    top: 50%; left: 0; right: 0;
    margin: auto;
    z-index: 310;
    transform: translateY(-50%);
    transform-style: preserve-3d;
    transform-origin: center center;
    transition: transform .5s ease-out;
  }

  .noBuyAlert {
    font-size: 20px;

    .icon {
      opacity: 0; pointer-events: none;
      position: absolute;

      &.on {
        opacity: 1; pointer-events: all;
        position: relative;
      }
    }
  }
  .flipin-enter-active, .flipin-leave-active {
    opacity: 1; transform: translateY(-50%) rotateY(0);
  }

  .flipin-enter-from, .flipin-leave-to {
    transform: translateY(-50%) rotateY(-180deg);
    opacity: 0;
  }
</style>