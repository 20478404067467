<template>
  <section>
    <header>
      <h3>Filtrar</h3>
    </header>
    <form @keyup.enter="runFilter" v-if="ready" :class="['frame fixed mid',{ locked: processing}]">
      <div class="row nop nom">
        <div class="form-group col-md-2">
          <select-control :options="options.status" placeholder="Selecione" label="Status" v-model="filter.status" />
        </div>
        <div class="form-group col-md-2">
          <select-control :options="options.etapas" placeholder="Selecione" label="Etapa" v-model="filter.idFormLeadEtapa" />
        </div>
        <div class="form-group col-md-4">
          <select-control :options="options.unidades" placeholder="Selecione" label="Unidade" v-model="filter.idUnidade" />
        </div>
        <div class="form-group col-md-4">
          <select-control :options="options.midias" placeholder="Selecione" label="Midia" v-model="filter.idMidia" />
        </div>
      </div>
      <div class="row nop nom">
        <div class="form-group col-md-4">
          <input-control placeholder="pessoa@email.com" label="Email" v-model="filter.email" />
        </div>
        <div class="form-group col-md-4">
          <input-control placeholder="" label="Nome" v-model="filter.nome" />
        </div>
        <div class="form-group col-md-2">
          <input-control placeholder="" label="Pessoa Pipedrive" v-model="filter.pessoaPipe" />
        </div>
        <div class="form-group col-md-2">
          <input-control placeholder="" label="Negócio Pipedrive" v-model="filter.negocioPipe" />
        </div>
      </div>
      <footer>
        <a href="#" class="btn btn-primary" @click.prevent="runFilter()">
          Filtrar
        </a>
        <a href="#" class="btn btn-secondary" @click.prevent="clear()">
          Limpar
        </a>
      </footer>
    </form>
  </section>
</template>

<script>
import UserService from '@/services/user.service'
import CaptacaoService from '@/services/captacao.service.js'
import { createSelectOptions } from '@/services/utils.service.js'

export default {
  data () {
    return {
      wait: {
        etapas: false,
        unidades: false,
        midias: false
      },
      options: {
        unidades: [],
        midias: [],
        etapas: [],
        status: [
          { value: 'open', label: 'Em Aberto' },
          { value: 'won', label: 'Ganho' },
          { value: 'lost', label: 'Perdido' }
        ]
      },
      ready: false,
      processing: false,
      filter: {
        status: null,
        idFormLeadEtapa: null,
        idUnidade: null,
        idMidia: null,
        idPessoa: null,
        integrado: null,
        email: null,
        nome: null,
        pessoaPipe: null,
        negocioPipe: null
      },
      scope: null
    }
  },
  mounted () {
    this.getEtapas()
    this.getUnidades()
    this.getMidias()

    this.scope = Object.assign({},this.filter)
  },
  methods: {
    getEtapas () {
      CaptacaoService.getFormEtapas().then(
        response => {
          console.log("etapas:", response.data)
          this.options.etapas = createSelectOptions(response.data, 'idFormLeadEtapa' ,'nome')
          this.wait.etapas = true
          this.finishLoad()
        },
        error => {
          console.error(error)
        }
      )
    },
    getUnidades () {
      UserService.getUnidades().then(
        response => {
          this.options.unidades = createSelectOptions(response.data, 'idUnidade' ,'descricao')
          this.wait.unidades = true
          this.finishLoad()
        },
        error => {
          console.error(error)
        }
      )
    },
    getMidias () {
      CaptacaoService.getFunilMidias().then(
        response => {
          console.log("midias:", response.data)
          this.options.midias = createSelectOptions(response.data, 'idMidia' ,'nome')
          this.wait.midias = true
          this.finishLoad()
        },
        error => {
          console.error(error)
        }
      )
    },
    finishLoad () {
      const finished = !Object.values(this.wait).some( a => !a)

      if ( finished ) {
        this.ready = true
      }
    },
    runFilter () {
      this.processing = true
      this.$emit('onProcessing', true)
      CaptacaoService.filtrarLeads(this.filter).then(
        response => {
          console.log("filtrarLeads:", response)
          this.$emit('onFilter', response.data)

          setTimeout(() => {
            this.processing = false
            this.$emit('onProcessing', false)
          },1000)
          
        },
        () => {
          this.processing = false
          this.$emit('onProcessing', false)
        }
      )
    },
    clear () {
      this.filter = Object.assign({}, this.scope)
    }
  }
}
</script>

<style lang="scss" scoped>
  section {
    padding: $mg_mini $mg_mini $mg_mid $mg_mini;
    border-bottom: $border-component;

    form {
      &.locked {
        opacity: .8; pointer-events: none;
      }
    }
    >header {
      padding: $hmg_mid;
    }

    footer {
      display: flex; align-items: center;
      gap: $mg_mini;
      padding: 0 $mg_small;
    }
  }
</style>