<template>
  <div>
    <upload-imagens></upload-imagens>
  </div>
</template>

<script>
import UploadImagens from '@/components/ferramentas/uploadImagens/form/UploadImagens'

export default {
  components: {
    UploadImagens
  },
  data () {
    return {
      
    }
  }
}
</script>

<style>

</style>