<template>
  <section>
    <h3>Itinerários Formativos</h3>
    <p>Selecione os itinerários desejados</p>
    <div v-if="loading" class="loaderWrap">
      <input-spinner></input-spinner>
    </div>
    <ul v-else-if="available.length > 0">
      <li v-for="itinerario in available" :key="itinerario">
        <label class="check-control large">
          <input type="radio" id="itinerario" class="check" v-model="selectedItinerario" :value="itinerario.idTurmaOptativa" @change="checkIti()">
          <span>{{ itinerario.detalhes }}</span>
        </label>
      </li>
    </ul>
    <div v-else class="empty">
      <fa class="icon" :icon="['fal', 'face-meh']"></fa>
      <h4>Ops. Não existem turmas disponíveis para este aluno</h4>
    </div>
    <footer>
      <a href="#" @click.prevent="matricular()" class="btn btn-primary" v-if="available.length > 0">
        Matricular
      </a>
      <a href="#" @click.prevent="close()" class="btn btn-primary" v-else>
        Sair
      </a>
    </footer>
  </section>
</template>

<script>
import MatriculaService from '@/services/matricula.service'
import InputSpinner from '@/components/utilities/InputSpinner'

export default {
  components: {
    InputSpinner
  },
  props: {
    matricula: {
      type: Number
    },
    itinerarios: {
      type: Array
    },
    matriculas: {
      type: Array
    }
  },
  data () {
    return {
      selectedItinerario: null,
      available: [],
      loading: false
    }
  },
  mounted () {
    this.checkAvailability()
  },
  methods: {
    checkAvailability () {
      this.available = this.itinerarios.filter(o => !this.matriculas.some(i => i.idTurma == o.idTurma))
    },
    matricular () {
      if ( this.selectedItinerario ) {
        this.loading = true
        MatriculaService.matricularItinerarios(this.matricula,[this.selectedItinerario]).then(
          response => {
            console.log("response:", response)
            if ( response.status == 200 || response.status == 201 ) {
              if ( response.data[0].success ) {
                window.toaster.emit('open', {style: "success", message: "Tudo certo, matricula no(s) itinerário(s) selecionado(s) efetuada(s) com sucesso.", floater: true})
                this.$emit('save')
              } else {
                window.toaster.emit('open', {style: "error", message: "Ops. Algo deu errado. Não conseguimos matricula o aluno no(s) itinerário(s) selecionado(s).", floater: true})
              }
              
            }

            this.loading = false
          },
          error => {
            console.log("error:", error)
            const vagaMsg = "Não existem mais vagas para essa turma, favor procurar a secretaria."
            var errorMsg = "Ops. Algo deu errado. Não conseguimos matricula o aluno no(s) itinerário(s) selecionado(s)."
            if ( error.response.data == vagaMsg ) {
              errorMsg = vagaMsg
            }
            window.toaster.emit('open', {style: "error", message: errorMsg, floater: true})
            this.loading = false
          }
        )
      } else {
        window.toaster.emit('open', {style: "error", message: "Selecione ao menos um itinerário.", floater: true})
      }
      
      // /matricula/inscricaoItinerarios/{idMatricula}
    },
    close () {
      this.$emit('close')
    },
    checkIti() {
      console.log("selectedItinerario:", this.selectedItinerario)
    }
  }
}
</script>

<style lang="scss" scoped>
  ul {
    display: block;
    width: 100%; height: auto;
    padding: 0; margin: $mg 0 0 0;

    li {
      list-style: none;
    }
  }

  footer {
    padding-top: $hmg;
    text-align: right;
  }

  .empty {
    text-align: center;
    padding: $hmg;
    
    .icon {
      font-size: 44px;
      margin-bottom: $hmg;
    }
  }

  .loaderWrap {
    padding: $hmg;
    display: flex; flex-direction: column;
    align-content: center; align-items: center;
    justify-content: center;
  }
</style>