<template>
  <controller>
    <page-header title="Colaboradores">
      <template v-slot:side>
        <transition name="slideRightToLeft">
          <a href="#" @click.prevent="togglePlanilhas()" class="btn btn-primary" v-if="!planilha">
            <span>Enviar itens em lote</span>&nbsp;
            <fa :icon="['fas', 'file-spreadsheet']"></fa>
          </a>
        </transition>
        <transition name="slideRightToLeft">
          <a href="#" @click.prevent="togglePlanilhas()" class="btn btn-primary" v-if="planilha">
            <span>Filtrar</span>&nbsp;
            <fa :icon="['fas', 'user-tie']"></fa>
          </a>
        </transition>
        <a href="#" class="btn btn-primary">
          Novo Colaborador
        </a>
      </template>
    </page-header>
    <main>
      <planilhas-controller v-if="planilha" tipo="colaboradores" />
      <template v-else>
        <filter-controller @onFilter="runFilter" />
        <form-spinner v-if="loading" />
        <colaboradores v-if="!loading && colaboradores" :scope="colaboradores"/>
        <colaborador v-if="id"/>
      </template>
    </main>
  </controller>
</template>

<script>
import PlanilhasController from '@/components/planilhas/template/PlanilhasController.vue'
import FilterController from '@/components/administrativo/colaboradores/utilities/Filter.vue'
import Colaborador from '@/components/administrativo/colaboradores/form/Colaborador.vue'
import Colaboradores from '@/components/administrativo/colaboradores/template/Colaboradores.vue'
import FormSpinner from '@/components/utilities/FormSpinner.vue'

export default {
  components: {
    PlanilhasController, FilterController, FormSpinner, Colaborador, Colaboradores
  },
  props: {
    action: {
      type: String
    },
    id: {
      type: [ Number, String ]
    }
  },
  watch: {
    action ( value ) {
      console.log("watch action:", value)
    }
  },
  data () {
    return {
      planilha: false,
      loading: false,
      colaboradores: null,
    }
  },
  methods: {
    togglePlanilhas () {
      this.planilha = !this.planilha
    },
    runFilter ( options ) {
      this.loading = options.wait

      if ( options.data ) {
        this.colaboradores = options.data
        console.log("options:", options)
      }
    }
  }
}
</script>

<style>

</style>