<template>
  <controller :lockScroll="lockScroll">
    <page-header title="Formulários">
      <template v-slot:side><a href="#" class="btn btn-primary" @click.prevent="newForm()">Adicionar novo Formulário</a></template>
    </page-header>
    <formulario v-if="action" :id="id"></formulario>
    <formularios @lockScroll="lockScroll = $event" ref="forms"></formularios>
  </controller>
</template>

<script>
import PageHeader from "@/components/template/PageHeader.vue"
import Formularios from "@/components/ferramentas/formularios/form/Formularios.vue"
import Formulario from "@/components/ferramentas/formularios/form/Formulario.vue"

export default {
  components: {
    PageHeader, Formularios, Formulario
  },
  props: {
    action :{
      type: String
    },
    id: {
      type: [String, Number]
    }
  },
  watch: {
    id ( value ) {
      if ( !value ) {
        this.lockScroll = false
      }
    }
  },
  mounted () {
    console.log("action:", this.action)
    console.log("id:", this.id)
    if ( this.action ) {
      this.lockScroll = true
    } else {
      this.lockScroll = false
    }
  },
  data () {
    return {
      lockScroll: false
    }
  },
  methods: {
    newForm () {
      window.scroll({
        top: 0,
        behavior: 'instant'
      })

      this.$nextTick(() => {
        this.$emit("lockScroll", true)
        this.$router.push({ name: "Formulários", params:{ action: 'novo' }})
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  
</style>