<template>
  <!-- <card>
    <div class="sorteioResult">
      <ol>
        <li>Alexandre</li>
        <li>Edson</li>
        <li>Fernando</li>
        <li>Jean</li>
        <li>João</li>
        <li>Johnny</li>
        <li>Thiago</li>
        <li>Vinicius</li>
      </ol>
      <h1>Resultado: {{resultado}}</h1>
    </div>
    
  </card> -->
  <section>
    <div class="calendar-form">
      <div>
        <div>
          <div class="input-wrap">
            <i class="fa-regular fa-calendar icon"></i>
            <input type="text" placeholder="Data Retirada" class="data-retirada"/>
          </div>
          <div class="select-wrap">
            <i class="fa-regular fa-clock icon"></i>
            <select class="hora-retirada">
              <option value="" selected disabled>Hora Retirada</option>
              <option value="1">Opcao 1</option>
            </select>
          </div>
          <div class="calendar"></div>
        </div>
        <div>
          <div class="input-wrap">
            <i class="fa-regular fa-calendar icon"></i>
            <input type="text" placeholder="Data Devolucao" class="data-devolucao"/>
          </div>
          <div class="select-wrap">
            <i class="fa-regular fa-clock icon"></i>
            <select class="hora-devolucao">
              <option value="" selected disabled>Hora Devolução</option>
              <option value="1">Opcao 1</option>
            </select>
          </div>
          <div class="calendar"></div>
        </div>
        <a class="btnStartLoc" href="#">Continuar</a>
      </div>
      
    </div>
  </section>
</template>

<script>
import { pessoas } from './pessoas.js'

export default {
  data () {
    return {
      resultado: null,

    }
  },
  mounted () {
    this.getResult()
  },
  methods: {
    getResult () {
      // console.log("pessoas:", pessoas)

      this.resultado =  pessoas[Math.floor(Math.random() * pessoas.length)];
    }
  }
}
</script>

<style lang="scss" scoped>
  .sorteioResult {
    padding: $mg;
  }

  section {
    max-width: 540px;
  }
  .calendar-form {
    display: flex; flex-direction: column;

    > div {
      position: relative; 
      display: flex; flex-direction: column;
     
      &:last-child {
        margin: 0;
      }

      > div {
        position: relative;
        display: flex; align-content: center;
        margin-bottom: 1em;
        width: 100%;

        .input-wrap {
          position: relative;
          flex: 1;
          margin-right: 1em;

          .icon {
            // background-color: #ff9900;
            position: absolute;
            top: 50%; right: 10px;
            transform: translateY(-50%)
          }

          input {
            background-color: #f2f2f2;
            width: 100%; height: 50px;
            border: 0; padding: 0 1em;
            margin: 0;
            border-radius: .5em;
            box-shadow: none;
          }
        }
        .select-wrap {
          position: relative;
          flex: 1;
          
          .icon {
            width: 20px; height: 20px;
            // background-color: #ff9900;
            position: absolute;
            top: 50%; right: 10px;
            transform: translateY(-50%);
            z-index: 10;
          }

          select {
            appearance: none !important;
            border: 0; padding: 0 1em;
            width: 100%; height: 50px;
            margin: 0;
            border-radius: .5em; 
            background-color: #f2f2f2;
            box-shadow: none;
            background-image: none;

            &:after, &:before {
              display: none;
            }
          }
        }
      }
    }
  }
</style>