<template>
  <card spacing="mini" boxed>
    <div>
      <p>Categoria</p>
      <h4>{{ atividade[target].nomeCategoriaAtendimento }}</h4>
    </div>
    <div>
      <p>{{ filterPessoa(atividade[target].idPerfilPessoa) }}</p>
      <h4>{{ atividade[target].pessoaNome }}</h4>
    </div>
    <div v-if="atividade[target].alunoNome">
      <p>Aluno</p>
      <h4>{{ atividade[target].alunoNome }}</h4>
    </div>
  </card>
</template>

<script>
export default {
  props: {
    id: {
      type: [Number, String]
    },
    type: {
      type: [Object]
    },
    target: {
      type: String
    },
    atividade: {
      type: Object
    }
  },
  data () {
    return {
      
    }
  },
  mounted () {

  },
  methods: {
    filterPessoa ( idTipo, slug ) {
      var pessoa = null

      if ( idTipo == 1 ) {
        pessoa = slug ? 'alunos' : 'Aluno'
      } else if ( idTipo == 2 ) {
        pessoa = slug ? 'colaboradores' : 'Colaborador'
      } else if ( idTipo == 3 ) {
        pessoa = slug ? 'responsaveis' : 'Responsável'
      }

      return pessoa
    },
  }
}
</script>

<style lang="scss" scoped>
  h4, p {
    margin: 0; padding: 0
  }

  div {
    margin-bottom: $hmg_mid; 

    &:last-child {
      margin-bottom: 0
    }
  }
</style>