<template>
  <section>
    <header>
      <h5>
        Filtrar
      </h5>
    </header>
    <div class="row no-padd" ref="produtoFilterForm">
      <div class="col-md-6 form-group no-padd-left">
        <label>Descrição</label>
        <input-control
          v-model="filter.descricao" 
          placeholder="Ex. Camiseta Babylook 100% Algodão Gola Simples" 
          name="descricao"
          type="text"
        />
      </div>
    </div>
    <div class="row no-padd" ref="produtoFilterForm">
      <div class="col-md-4 form-group no-padd-left">
        <label>Categorias</label>
        <select-control
          v-model="filter.idcategorialoja" 
          placeholder="Selecione" 
          name="idcategorialoja"
          :options="options.categorias"
          :disabled="loading"
          @change="getSubcategorias(filter.idcategorialoja)"
        />
      </div>
      <div class="col-md-4 form-group no-padd-left">
        <label>Subcategorias</label>
        <select-control
          v-model="filter.idsubcategoria" 
          placeholder="Selecione" 
          name="idsubcategoria"
          :options="options.subcategorias"
          :disabled="loading"
        />
      </div>
    </div>
    <div class="row no-padd" ref="produtoFilterForm">
      <div class="col-md-3 form-group no-padd-left">
        <label>Início venda</label>
        <input-control
          v-model="filter.iniciovenda" 
          placeholder="DD/MM/AAAA" 
          name="iniciovenda"
          type="date"
          :disabled="loading"
        /> 
      </div>
      <div class="col-md-3 form-group no-padd-left">
        <label>Término venda</label>
        <input-control
          v-model="filter.terminovenda" 
          placeholder="DD/MM/AAAA" 
          name="terminovenda"
          type="date"
          :disabled="loading"
        /> 
      </div>
    </div>
    <div class="row no-padd" ref="produtoFilterForm">
      <div class="col-md-3 form-group no-padd-left">
        <label>Matrícula inicial</label>
        <input-control
          v-model="filter.matriculainicial" 
          placeholder="DD/MM/AAAA" 
          name="matriculainicial"
          type="date"
          :disabled="loading"
        /> 
      </div>
      <div class="col-md-3 form-group no-padd-left">
        <label>Matrícula Final</label>
        <input-control
          v-model="filter.matriculafinal" 
          placeholder="DD/MM/AAAA" 
          name="matriculafinal"
          type="date"
          :disabled="loading"
        /> 
      </div>
    </div>
    <div class="row no-padd" ref="produtoFilterForm">
      <div class="col-md-2 form-group no-padd-left ">
        <label>Serviço Extra</label>
        <div class="switchWrap">
          <switch-control v-model:checked="filter.servicoextra" labelOn="Sim" labelOff="Não" :invert="true"></switch-control>
        </div>
      </div>
      <div class="col-md-2 form-group no-padd-left">
        <label>Precifica por item</label>
        <div class="switchWrap">
          <switch-control v-model:checked="filter.precoitem" labelOn="Sim" labelOff="Não" :invert="true"></switch-control>
        </div>
      </div>
      <div class="col-md-2 form-group no-padd-left">
        <label>Status</label>
        <div class="switchWrap">
          <switch-control v-model:checked="filter.ativo" labelOn="Ativo" labelOff="Inativo" :invert="true"></switch-control>
        </div>
      </div>
    </div>
    <div class="col-md-12 form-actions form-group">
      <a href="#" @click.prevent="runFilter()" class="btn btn-primary">
        Buscar
      </a>
      <a href="#" @click.prevent="clearFilter()" class="btn btn-secondary">
        Limpar
      </a>
    </div>
  </section>
</template>

<script>
import MarketplaceService from '@/services/marketplace.service'
import { createSelectOptions } from '@/services/utils.service'

export default {
  data () {
    return {
      filter: {
        decricao: null,
        idcategorialoja: null,
        idsubcategoria: null,
        matriculainicial: null,
        matriculafinal: null,
        servicoextra: 0,
        precoitem: 0,
        ativo: 0
      },
      wait: {
        categorias: false,
        subcategorias: false
      },
      options: {
        categorias: [],
        subcategorias: []
      },
      loading: false
    }
  },
  mounted () {
   this.preloadCategorias()
  },
  methods: {
    getCategorias () {
      MarketplaceService.getCategorias().then(
        response => {
          console.log("getCategorias:", response.data)

          if ( response.status == 200 ) {
            this.options.categorias = createSelectOptions(response.data,'idcategorialoja', 'descricao')
            this.options.categorias.push({value:0, label: 'Nenhuma'})
            console.log("categorias:", this.options.categorias)
            this.wait.categorias = true
            this.finishLoad()
          } 
        },
        error => {
          console.log("error:", error)
        }
      )
    },
    getSubcategorias ( categoria ) {
      console.log('getSubcategorias - categoria:', categoria)

      if ( categoria == 0 ) {
        this.preloadCategorias()
        this.filter.idcategorialoja = null
      } else {
        this.loading = true
        MarketplaceService.getSubcategorias( categoria ).then(
          response => {
            console.log("getSubcategorias:", response.data)

            if ( response.status == 200 ) {
              this.options.subcategorias = createSelectOptions(response.data,'idsubcategorialoja', 'descricao')
              console.log("subcategorias:", this.options.subcategorias)
              this.wait.subcategorias = true
              this.finishLoad()
            } 
          },
          error => {
            console.log("error:", error)
          }
        )
      }
      
    },
    preloadCategorias () {
      window.spinner.emit("open")
      this.getCategorias()
      this.getSubcategorias()
    },
    finishLoad () {
      const finished = !Object.values(this.wait).some( a => !a)

      if ( finished ) {
        window.spinner.emit("close")
        this.loading = false
      }
      // console.log("finished:", finished)

    },
    runFilter () {
      this.loading = true
      window.spinner.emit("open")
      MarketplaceService.filterProdutos( this.filter ).then(
        response => {
          console.log("runFilter:", response)
          if ( response.data.length > 0 ) {
            this.$emit('onLoad', response.data)
          } else {
            window.toaster.emit('open', {style: "warning", message: "Não encontramos produtos com os filtros informados.", floater: true})
          }
          this.loading = false
          window.spinner.emit("close")
        },
        error => {
          console.log("error:", error)
          window.toaster.emit('open', {style: "warning", message: "Ops! Algo aconteceu. Não conseguimos processar sua solicitação. Tente novamente. Se o erro persistir, procure o suporte. Err: " + error.response.data.message, floater: true})
          this.loading = false
          window.spinner.emit("close")
        }
      )
    },
    clearFilter () {
      this.filter = {
        decricao: null,
        idcategorialoja: null,
        idsubcategoria: null,
        matriculainicial: null,
        matriculafinal: null,
        servicoextra: 0,
        precoitem: 0,
        ativo: 0
      }

      this.$emit('clear')
    }
  }
}
</script>

<style scoped lang="scss">
  header {
    margin: $hmg $hmg_small;

    h5 {
      margin: 0 0 $hmg_mini 0; padding: 0;
    }
    p {
      margin: 0; padding: 0;
    }
  }

  .form-actions {
    margin-top: $hmg !important
  }
</style>