<template>
  <main>
    <header>
      <h3>Editar Gatilhos</h3>
      <p v-if="gatilhos">{{ gatilhos.descricao }}</p>
    </header>
    <div>
      <p>Abaixo, estão listadas as perguntas disponíveis para adição de gatilhos</p>
    </div>
    <ul v-if="ready">
      <li v-for="(item, i) in gatilhos.perguntas" :key="item" class="questionWrap">
        <div>
          <h4>{{ item.descricao }}</h4>
          <ul>
            <li v-for="(opcao, o) in item.opcoes" :key="opcao" >
              <div>
                <card :boxed="false" :listed="false" :fixed="false" hover>
                  <div class="altBox" @click="toggleGatilhos(i,o)">
                    <h5>{{ opcao.alternativa }}</h5>
                    <fa :icon="['fal', 'arrow-right-long']" />
                  </div>
                </card>
              </div>
              <div>
                <template v-if="opcao.edit">
                  <div class="gatWrap" v-for="(gatilho, g) in opcao.gatilho" :key="gatilho">
                    <card :boxed="false" :listed="false" overflow="visible">
                      <div class="gatBox">
                        <header>
                          <div>
                            <a href="#" @click.prevent="toggleGatilho(i,o,g)" class="gatilhoToggle">
                              <fa :icon="['fal','chevron-down']"></fa>
                            </a>
                            <h4>{{ gatilho.nomeGatilho ? gatilho.nomeGatilho : "Gatilho" }}</h4>
                          </div>
                        </header>
                        <div v-if="gatilho.wait" class="spinnerWrap">
                          <span class="loader"></span>
                        </div>
                        <div v-else>
                          <div class="row nop nom">
                            <div class="form-group col-md-4">
                              <select-control 
                                :options="options.tipos"
                                placeholder="Selecione" 
                                name="tipo"
                                v-model="gatilho.tipo" 
                                label="Tipo"
                                @change="changeTipo(i, o, g)"
                              ></select-control>
                            </div>
                          </div>
                          <div class="row nop nom">
                            <div class="form-group col-md-12">
                              <input-control v-model="gatilho.nomeGatilho" label="Nome Gatilho" name="nomeGatilho" />
                            </div>
                            <template v-if="gatilho.tipo == 'novaAtividade'">
                              <div class="form-group col-md-6">
                                <select-control 
                                  v-model="gatilho.idCategoriaAtividadeDestino" 
                                  :options="options.categorias"
                                  name="idAtividadeCategoria"
                                  label="Categoria"
                                  placeholder="Selecione"
                                  required
                                ></select-control>
                              </div>
                              <div class="form-group col-md-6">
                                <select-control 
                                  v-model="gatilho.idAtendimentoCanal" 
                                  :options="options.canais"
                                  name="idAtendimentoCanal"
                                  label="Canal"
                                  placeholder="Selecione"
                                  required
                                ></select-control>
                              </div>
                              <div class="form-group col-md-12">
                                <div class="searchWrap">
                                  <input-spinner v-if="gatilho.operador.loading" class="searchSpinner"></input-spinner>
                                  <input-control 
                                    label="Operador"
                                    v-model="gatilho.operador.query"
                                    placeholder="Digite para buscar.."
                                    name="idUserAtribuido"
                                    :valid="valid.idUserAtribuido"
                                    @keyup="searchPessoa(true, i, o, g)"
                                    @blur="trackClearSearch(true, i, o, g)"
                                  ></input-control>
                                  <input type="hidden" v-model="gatilho.idUserAtribuido">
                                  <!-- <a href="#" class="remove" @click.prevent="remove(i)">
                                    <fa :icon="['fas','trash-can']"></fa>
                                  </a> -->
                                  <div class="listWrap" v-if="gatilho.operador.list && gatilho.operador.list.length > 0">
                                    <div class="list">
                                      <div>
                                        <table>
                                          <tr v-for="li in gatilho.operador.list" :key="li" @click="selectOperador(true,li, i, o, g)">
                                            <td>
                                              {{ li.nome }}
                                            </td>
                                            <td>
                                              {{ li.email }}
                                            </td>
                                            <td>
                                              {{ li.nomeUnidade }}
                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group col-md-12">
                                <input-control type="text" v-model="gatilho.linkExterno" placeholder="https://linkexterno.com.br" label="Link Externo"/>
                              </div>
                              <div class="form-group col-md-12">
                                <textarea-control type="text" v-model="gatilho.observacoes" placeholder="" label="Observações"/>
                              </div>
                            </template>
                            <template v-if="gatilho.tipo == 'notificaHangouts'">
                              <div class="form-group col-md-12">
                                <div class="searchWrap">
                                  <input-spinner v-if="gatilho.operador.loading" class="searchSpinner"></input-spinner>
                                  <input-control 
                                    label="Operador"
                                    v-model="gatilho.operador.query" 
                                    placeholder="Digite para buscar.."
                                    name="idUserAtribuido"
                                    :valid="valid.idUserAtribuido"
                                    @keyup="searchPessoa(false, i, o, g)"
                                    @blur="trackClearSearch(false, i, o, g)"
                                  ></input-control>
                                  <input type="hidden" v-model="gatilho.idUserAtribuido">
                                  <input type="hidden" v-model="gatilho.emailNotificacao">
                                  <!-- <a href="#" class="remove" @click.prevent="remove(i)">
                                    <fa :icon="['fas','trash-can']"></fa>
                                  </a> -->
                                  <div class="listWrap" v-if="gatilho.operador.list && gatilho.operador.list.length > 0">
                                    <div class="list">
                                      <div>
                                        <table>
                                          <tr v-for="li in gatilho.operador.list" :key="li" @click="selectOperador(false,li, i, o, g)">
                                            <td>
                                              {{ li.nome }}
                                            </td>
                                            <td>
                                              {{ li.email }}
                                            </td>
                                            <td>
                                              {{ li.nomeUnidade }}
                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="form-group col-md-12">
                                <textarea-control type="text" v-model="gatilho.textoNotificacao" placeholder="" label="Mensagem"/>
                              </div>
                            </template>
                          </div>
                        </div>
                        <footer>
                          <span>
                            <switch-control v-model:checked="gatilho.status" labelOn="Ativo" labelOff="Inativo" labelPos="esq" />
                          </span>
                          <a href="#" class="btn btn-primary" @click.prevent="saveGatilho(gatilho, i, o, g)">
                            Salvar
                          </a>
                        </footer>
                      </div>
                    </card>
                    <footer>
                      <a href="#" class="btnAddGatilho btn btn-primary" @click.prevent="addGatilho(i,o)">
                        <span>Adicionar Gatilho</span>
                        &nbsp;<fa :icon="['fas','plus']"/>
                      </a>
                      <a href="#" class="btnRemoveGatilho btn btn-secondary" @click.prevent="toggleRemoveGatilho(i,o,g)">
                        <span>Remover Gatilho</span>
                        &nbsp;<fa :icon="['fas','trash-can']"/>
                      </a>
                    </footer>
                  </div>
                </template>
              </div>
            </li>
          </ul>
        </div>
      </li>
    </ul>
    <footer>
      <a href="#" class="btn btn-secondary" @click.prevent="sair()">
        Sair
      </a>
    </footer>
    <confirm 
      :backdrop="false"
      class="confirm"
      ref="confirm"
    ></confirm>
  </main>
</template>

<script>
import FerramentaService from '@/services/ferramentas.service.js'
import AtendimentoService from '@/services/atendimento.service.js'
import inputSpinner from '@/components/utilities/InputSpinner'
import { createSelectOptions } from '@/services/utils.service.js'

export default {
  components: {
    inputSpinner
  },
  props: {
    categoria: {
      type: Object
    },
    idCategoria: {
      type: [String, Number]
    },
    idFormulario: {
      type: [String, Number]
    },
    perfilPessoa: {
      type: [String, Number]
    }
  },
  data () {
    return {
      ready: false,
      gatilhos: null,
      options: {
        tipos: [
          { value: 'novaAtividade', label: "Nova Atividade" },
          { value: 'notificaHangouts', label: "Notificação (Hangouts)" }
        ],
        categorias: [],
        canais: []
      },
      wait: {
        gatilhos: false,
        categorias: false,
        canais: false
      },
      valid: {
        idPessoa: true
      },
      gatilho: {
        nomeGatilho: "",
        linkExterno: "",
        idCategoriaAtividade: "",
        idCategoriaAtividadeDestino: "",
        idFormulario: "",
        idFormularioPergunta: "",
        idFormularioAlternativa: "",
        tipo: "",
        idFuncao: "",
        idUserAtribuido: "",
        emailNotificacao: "",
        textoNotificacao: "",
        status: "",
        idAtendimentoCanal: "",
        observacoes: "",
        edit: false,
        wait: false,
        operador: {
          query: null,
          loading: false,
          list: []
        },
      },
      operador: {
        query: null,
        loading: false,
        list: []
      },
      waitSearch: null,
      scope: null
    }
  },
  watch: {
    perfilPessoa ( value ) {
      if ( value ) {
        this.getCategoriasAtendimento()
      }
    }
  },
  mounted () {
    // console.log("categoria", this.categoria)
    if ( this.idCategoria && this.idFormulario ) {
      this.gatilho.idFormulario = this.idFormulario
      this.gatilho.idCategoriaAtividade = this.idCategoria

      this.scope = Object.assign({}, this.gatilho)

      this.getFormulario()      
      this.getCanaisAtendimento()

      if ( this.perfilPessoa ) this.getCategoriasAtendimento()
    }
  },
  methods: {
    getFormulario () {
      FerramentaService.getEditGatilhos(this.idCategoria, this.idFormulario).then(
        response => {
          this.gatilhos = response.data

          this.gatilhos.perguntas.map( k => {
            k.opcoes.map( (o, i) => {
              o.edit = i == 0
              
              if ( !o.gatilho || o.gatilho.length == 0 ) {
                const scope = Object.assign({}, this.gatilho)
                scope.idFormularioAlternativa = o.idFormularioAlternativa
                scope.idFormularioPergunta = o.idFormularioPergunta

                o.gatilho.push(scope)
                
              } else {
                // const operador = 
                o.gatilho.map( g => {
                  g.operador = Object.assign({}, this.operador)
                  g.operador.query = g.nome ?? g.nomeEmail
                })
              }
            })
          })
          console.log("getEditGatilhos:", response.data)
          this.wait.gatilhos = true
          this.finishLoad()
        },
        () => {

        }
      )
    },
    getCategoriasAtendimento () {
      console.log("perfilPessoa:", this.perfilPessoa)
      AtendimentoService.getCategoriasAtendimentoPorTipoPessoa(this.perfilPessoa).then(
        response => {
          console.log("categorias:", response.data)
          this.options.categorias = createSelectOptions(response.data,'idAtividadeCategoria','nome')

          this.wait.categorias = true
          this.finishLoad()
        },
        () => {

        }
      )
    },
    getCanaisAtendimento () {
      AtendimentoService.getCanaisAtendimento().then(
        response => {
          console.log("canais:", response.data)
          this.options.canais = createSelectOptions(response.data,'idAtendimentoCanal','nome')
          
          this.wait.canais = true
          this.finishLoad()
        },
        () => {

        }
      )
    },
    finishLoad () {
      const finished = !Object.values(this.wait).some( a => !a)

      if ( finished ) {
        this.ready = true
      }
      // console.log("finished:", finished)
    },
    toggleGatilhos ( pergunta, opcao ) {
      const gatilho = this.gatilhos.perguntas[pergunta].opcoes[opcao]
      console.log("gatilho:", gatilho)

      gatilho.edit = !gatilho.edit
      
    },
    toggleGatilho ( pergunta, opcao, gatilho ) {
      console.log("pergunta:", pergunta)
      console.log("opcao:", opcao)
      console.log("gatilho:", gatilho)
    },
    addGatilho ( pergunta, opcao ) {
      const scope = Object.assign({}, this.gatilho)
      const target = this.gatilhos.perguntas[pergunta].opcoes[opcao]

      scope.idFormularioPergunta = target.idFormularioPergunta
      scope.idFormularioAlternativa = target.idFormularioAlternativa

      target.gatilho.push(scope)
    },
    changeTipo ( pergunta, opcao, gatilho ) {
      const target = this.gatilhos.perguntas[pergunta].opcoes[opcao].gatilho[gatilho]
      const tipo = gatilho.tipo
      
      this.operador.query = null
      target.idUserAtribuido = null

      if ( tipo == 'novaAtividade' ) {
        target.textoNotificacao = null
        target.emailNotificacao = null
      }
      if ( tipo == 'notificaHangouts' ) {
        target.linkExterno = null
        target.observacoes = null
        target.idCategoriaAtividadeDestino = null
      }
    },
    searchPessoa ( operador, pergunta, opcao, gatilho ) {
      if ( this.waitSearch ) clearInterval(this.waitSearch)

      const g = gatilho ?? 0
      const target = this.gatilhos.perguntas[pergunta].opcoes[opcao].gatilho[g]
      console.log("gatilho:", gatilho)
      this.waitSearch = setTimeout ( () => {
        const nome = target.operador.query
        const pessoa = 'colaboradores'

        if ( nome.length == 0 ) {
          this.clearSearch( operador, pergunta, opcao, g )
          
          target.operador.loading = false
          
        } else {
          target.operador.loading = true

          AtendimentoService.getPessoaByNome(pessoa, nome).then(
            response => {
              console.log("getPessoaByNome:", response.data)
              if ( response.data.length > 0 ) {
                target.operador.list = response.data
                
              } else {
                target.operador.valid = false

                this.clearSearch(operador, pergunta, opcao, g)
              }

              target.operador.loading = false
            },
            () => {
              target.operador.loading = false
            }
          )
        }
        
      }, 500)
    },
    clearSearch ( operador, pergunta, opcao, gatilho ) {
      const target = this.gatilhos.perguntas[pergunta].opcoes[opcao].gatilho[gatilho]

      target.operador.query = ''
      target.operador.list = []
      target.operador.loading = false

      if ( operador ) {
        target.idUserAtribuido = null
      } else {
        target.emailNotificacao = null
      }
      
    },
    trackClearSearch ( operador, pergunta, opcao, gatilho ) {
      setTimeout(() => {
        const g = gatilho ?? 0
        const target = this.gatilhos.perguntas[pergunta].opcoes[opcao].gatilho[g]

        const hasValue = operador ? target.idUserAtribuido : target.emailNotificacao

        if ( !hasValue ) this.clearSearch(operador, pergunta, opcao, g)
      },500)
    },
    selectOperador ( operador, pessoa, pergunta, opcao, gatilho ) {
      console.log("operador: ", operador)
      console.log("pessoa: ", pessoa)
      console.log("pergunta: ", pergunta)
      console.log("opcao: ", opcao)
      console.log("gatilho: ", gatilho)

      // const g = gatilho ?? 0
      const target = this.gatilhos.perguntas[pergunta].opcoes[opcao].gatilho[gatilho]
      
      if ( operador ) {
        target.idUserAtribuido = pessoa.idPessoa
      } else {
        target.emailNotificacao = pessoa.email
      }
      
      target.operador.query = pessoa.nome
      target.operador.list = []
      target.operador.loading = false
    },
    saveGatilho ( data, pergunta, opcao, gatilho ) {
      const g = gatilho ?? 0
      const target = this.gatilhos.perguntas[pergunta].opcoes[opcao].gatilho[g]
      // console.log("target", target)
      target.wait = true
      
      FerramentaService.saveGatilhoAtividade( data ).then(
        response => {
          console.log("response", response.data)
          // console.log("this.gatilho:", this.gatilho)
          this.gatilho = Object.assign({}, this.scope)
          this.gatilho.operador = Object.assign({}, this.operador)
          window.toaster.emit('open', {style: "success", message: "Tudo certo! Gatilho salvo com sucesso.", floater: true})

          this.getFormulario()
          target.wait = false
        },
        () => {
          target.wait = false
        }
      )
    },
    sair () {
      this.$router.push({name: 'Gatilhos Atividades'})
    },
    toggleRemoveGatilho ( pergunta, opcao, gatilho ) {
      this.$refs.confirm.run({
        message: "Tem certeza que deseja remover este gatilho?",
        confirmButton: "Sim",
        denyButton: "Não",
        data: { pergunta, opcao, gatilho },
        callback: this.removeGatilho,
      })
    },
    removeGatilho ( response, data ) {
      const { pergunta, opcao, gatilho } = data
      // console.log("gatilho:", gatilho)

      if ( response ) {
        const target = this.gatilhos.perguntas[pergunta].opcoes[opcao].gatilho[gatilho]
        if ( target.idGatilhoAtividade ) {
          FerramentaService.removeGatilhoAtividade(target.idGatilhoAtividade).then(
            response => {
              if ( response.status == 200 ) {
                
                this.gatilhos.perguntas[pergunta].opcoes[opcao].gatilho.splice(gatilho, 1)

                this.$nextTick(() => {
                  if ( this.gatilhos.perguntas[pergunta].opcoes[opcao].gatilho.length == 0 ) {
                    const empty = Object.assign({}, this.gatilho)
                    this.gatilhos.perguntas[pergunta].opcoes[opcao].gatilho.push(empty)
                  }
                })
              }
            }
          )
        } else {
          this.gatilhos.perguntas[pergunta].opcoes[opcao].gatilho.splice(gatilho, 1)
        }
        console.log("removeGatilho:", response)
        console.log("data:", data)
      }
      
    }
  }
}
</script>

<style lang="scss" scoped>
  main {
    
    padding: $mg;

    @media screen and ( max-width: 1366px ) {
      padding: $hmg_mid
    }
    
    >header {
      width: 100%;
      margin-bottom: $hmg;

      h3 {
        margin: 0; padding: 0;  
      }

    }
    
    >div {
      margin-bottom: $hmg_mid;
    }

    >ul {
      padding: 0; margin: 0;

      > li {
        list-style: none;
        padding: $hmg_mid 0; margin: 0;
        border-top: $border-component;

        &:last-child {
          border-bottom: $border-component;
        }
      }

      .questionWrap {
        h4 { 
          padding: 0; margin: 0 0 $mg_mini $mg_mini;
        }

        ul {
          padding: 0; margin: 0;

          li {
            padding: 0; margin: 0;
            list-style: none;
            margin-bottom: $mg_mini;
            display: flex;
            align-items: stretch; align-content: flex-start;
            gap: $mg;
            
            > div {
              flex: 1;

              &:first-child {
                max-width: 40%
              }
            }
          }
        }
      }

      .altBox {
        display: flex; align-content: center;
        align-items: center;
        justify-content: space-between;
        padding: $hmg_mid;

        h5 {
          margin: 0; padding: 0;
        }
      }

      .gatWrap {
        margin-bottom: $hmg;

        .gatBox {
          header {
            padding: $hmg_mid;
            border-bottom: $border-component;
            display: flex; align-items: center;
            justify-content: space-between;
            position: relative;

            >div {
              display: flex; align-items: center;
              justify-content: flex-start;
              gap: $mg_mini;

              .gatilhoToggle {
                font-size: 18px;

                &:hover {
                  color: $color-primary
                }
              }
            }

            &:before {
              content: "";
              display: block;
              height: 65px; width: 1px;
              border-left: $border-component;
              left: 15px; top: 0;
              transform: translateY(-100%);
              position: absolute;
            }

            h4 {
              margin: 0; padding: 0;
            }
          }
          
          > div:not(.spinnerWrap) {
            padding: $hmg_mid $mg_mini;
          }

          > footer {
            border-top: $border-component;
            padding: $mg_mini $hmg_mid;
            display: flex;
            align-items: center; justify-content: space-between;
          }
        }

        &:first-child {
          .gatBox {
            header {
              &:before {
                content: "";
                display: block;
                width: 52px; height: 1px;
                border-top: $border-component;
                left: 0; top: 50%;
                transform: translate(-100%,-50%);
                position: absolute;
              }
            }
          }
        }

        footer {
          display: flex; 
          align-items: center; align-content: center;
          justify-content: space-between;
          padding: 0 $mg_mid
        }
      }
      
    }

    >footer {
      border-top: $border-component;
      padding: $mg_mini $hmg_mid;
      display: flex;
      align-items: center; justify-content: flex-end;
    }
  }

  .searchWrap {
    position: relative;

    .remove {
      position: absolute;
      right: $hmg_small; top: 50%;
      transform: translateY(-50%);

      >* {
        @extend %transition_3o;
      }
      
      &:hover {
        color: $color-primary
      }
    }
  }

  .listWrap {
    position: relative;
    width: 100%;

    .list {
      position: absolute; 
      width: 100%; max-height: 200px;
      left: $hmg_mini; top: -$hmg_mini;
      overflow: hidden;
      z-index: 100;
      padding: $hmg_small;
      background-color: $color-secondary;
      border-radius: $border-radius;
      @extend %shadow-high;
      border: $border-component;

      > div {
        padding: 0 $mg_mini 0 0; margin: 0; 
        max-height: calc(200px - $hmg_mid);
        overflow-y: auto;
        @extend %scrollbar_card;

        table {
          border-collapse: collapse;
          width: 100%;
          
          tr {
            @extend %transition_3o;
            cursor: default;
            vertical-align: middle;

            &:hover {
              background-color: $color-bg;
            }
            td {
              padding: $mg_mini;
              cursor: default; line-height: 1;

              &:first-child {
                border-top-left-radius: $border-radius-small;
                border-bottom-left-radius: $border-radius-small;
              }
              &:last-child {
                border-top-right-radius: $border-radius-small;
                border-bottom-right-radius: $border-radius-small;
              }
            }
          }
        }
      }

      ul {
        padding: 0 $mg_mini 0 0; margin: 0; max-height: 100%;
        overflow-y: auto;
        @extend %scrollbar_card;
        
        li {
          display: flex;
          align-content: center; align-items: center;
          justify-content: space-between;
          list-style: none;
          padding: $hmg_mini;
          margin: 0;
          @extend %transition_3o;
          cursor: pointer;

          &:hover {
            background-color: $color-bg;
            border-radius: $border-radius-small;
          }
          span {
            flex: 1;
            padding: 0 $mg_mini;

            &:first-child,
            &:last-child {
              padding: 0
            }

            &:last-child {
              flex: 0;
              text-align: right;
            }

            &:nth-child(1) {
              width: 30%;
            }
          }
        }
      }
    }
  } 

  .searchSpinner { 
    position: absolute; top: 16px;
    right: $hmg_mid;
    z-index: 100;
  }

  .spinnerWrap {
    width: 100%; padding-top: $mg;

    .loader {
      width: 100%;
      height: 6px;
      display: block;
      position: relative;
      overflow: hidden;
      background-color: $color-bg;
    }
    .loader::after {
      content: '';  
      width: 96px; height: 6px;
      background-color: $color-primary;
      position: absolute; top: 0; left: 0;
      box-sizing: border-box;
      animation: hit 0.6s ease-in-out infinite alternate;
    }

    @keyframes hit {
      0% {
        left: 0;
        transform: translateX(-1%);
      }
      100% {
        left: 100%;
        transform: translateX(-99%);
      }
    }
  }

  .btnAddGatilho {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -1px
  }
  .btnRemoveGatilho {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -1px
  }
</style>