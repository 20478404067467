<template>
  <section>
    <div class="loginBody frame nano">
      <transition name="slidedown-small">
        <Logo class="loginLogo" v-if="!loading"/>
      </transition>
      <div class="loginBodyContent" v-if="recuperaSenha && !loading">
        <header>
          <h3>Recuperacão de Senha</h3>
          <p v-if="!senhaRecuperada">Para recuperar sua senha, informe os dados abaixo.</p>
        </header>
        <div class="successMessage" v-if="senhaRecuperada">
          <h4 class="color-text">Ok. Tudo certo.</h4>
          <p>Enviamos sua nova senha para seu e-mail cadastrado em nosso sistema!</p>
        </div>
        <form v-else method="POST">
          <div class="form-group">
            <label>CPF</label>
            <input-control cssClass="invert" type="text" v-model="user.cpf" name="cpf" placeholder="CPF" required :disabled="waiting" masking="cpf"/>
          </div>
          <div class="form-group">
            <label>Data de Nascimento</label>
            <input-control cssClass="invert" type="date" v-model="user.dataNascimento" name="dataNascimento" placeholder="Data de Nascimento" required :disabled="waiting"/>
          </div>
        </form>
        <footer>
          <a href="#" @click.prevent="toggleRecuperaSenha()" class="btn btn-secondary">
          <fa :icon="['far', 'arrow-left-long']" />&nbsp; Voltar</a>
          <a href="#" @click.prevent="recuperarSenha()" class="btn btn-primary" v-if="!senhaRecuperada">Recuperar</a>
        </footer>
      </div>
      <div class="loginBodyContent" v-if="!recuperaSenha && !loading">
        <header>
          <h3>Seja bem-vindo</h3>
          <h2>à Rede Decisão</h2>
          <p>Para entrar, utilize seu e-mail e senha.</p>
        </header>
        <form method="POST">
          <div class="form-group">
            <input-control cssClass="invert" type="text" v-model="login.email" name="email" placeholder="Email" required :disabled="waiting"/>
          </div>
          <div class="form-group">
            <input-control cssClass="invert" type="password" v-model="login.password" name="password" placeholder="Senha" required :disabled="waiting"/>
          </div>
        </form>
        <footer>
          <a href="#" @click.prevent="toggleRecuperaSenha()" class="btn btn-secondary">
            <fa :icon="['fas', 'lock']" />&nbsp; Esqueci a senha
          </a>
          <a href="#" @click.prevent="handleLogin()" class="btn btn-primary">Entrar</a>
        </footer>
      </div>
    </div>
  </section>
</template>

<script>
import Logo from '@/components/template/logo.vue'
import AuthService from '@/services/auth.service'
import { formatDate, clearMask } from '@/services/utils.service'

export default {
  components: {
    Logo
  },
  props: {
    section: {
      type: String
    }
  },
  data () {
    return {
      waiting: false,
      passType: "password",
      login: {
        email: null,
        password: null,
        type: 'resp'
      },
      user: {
        cpf: null,
        dataNascimento: null
      },
      recuperaSenha: false,
      loading: false,
      senhaRecuperada: false
    }
  },
  mounted () {
    // console.log("this.section:", this.section)

    if ( this.section === 'recupera-senha' ) {
      this.toggleRecuperaSenha()
    }
  },
  methods: {
    toggleRecuperaSenha () {
      this.recuperaSenha = !this.recuperaSenha

      if ( !this.recuperaSenha ) {
        this.senhaRecuperada = false
      }

      this.$emit('recuperasenha')
    },
    handleLogin() {
      if (this.login.email && this.login.password) {
        window.spinner.emit("open")
        this.loading = true
        this.$store.dispatch('auth/login', this.login).then(() => {
            const url = new URL(window.location.href)
            const goto = url.searchParams.get("return")

            if ( goto ) {
              this.$router.push(goto)
              
            } else {
              // this.$router.go()
              this.$router.push({name: "Loja"})
              window.spinner.emit("close")
            }
            
          },
          error => {
            this.loading = false
            this.message = ( error.response && error.response.data && error.response.data.message ) || error.message || error.toString()
            console.log("message:", this.message)
            window.spinner.emit("close")
            this.loading = false
            window.toaster.emit('open', {style: "error", message: "Erro ao tentar acessar sua conta. Valide os dados e tente novamente.", floater: true})
          }
        )
      }
    },
    recuperarSenha () {
      
      this.loading = true
      window.spinner.emit("open")

      const drop = {
        cpf: clearMask('cpf',this.user.cpf),
        dataNascimento: formatDate(this.user.dataNascimento)
      }

      AuthService.reperarSenha( drop ).then(
        response => {
          this.loading = false
          this.senhaRecuperada = true
          window.spinner.emit("close")
          console.log("response:", response)
        },
        error => {
          this.loading = false
          window.spinner.emit("close")
          console.log("error:", error.response.data.error)
          const err = error.response.data.error
          var message = err == 'não foi encontrado esses dados na base' ? 'Ops. Não econtramos nenhum cadastro com os dados informados. Revise os dados e tente novamente.' : err

          window.toaster.emit('open', {style: "warning", message: message, floater: true})
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
  .loginBody {
    position: fixed; top: 45%; left: 0; right: 0;
    transform: translateY(-50%);
    @extend %transition_4e;

    .loginLogo {
      margin: 0 auto $mg;
    }

    header {
      margin-bottom: $hmg;
      padding: 0 $hmg_small;
      line-height: 1 !important;
      @extend %transition_4e;

      h2, h3 {
        line-height: 1;
      }
    }

    .loginBodyContent {
      width: 100%; height: auto;
      background-color: $color-secondary;
      border-radius: $border-radius-larger;
      padding: $mg;
      @extend %transition_4e;

      .successMessage {
        padding: 0 $hmg_small $hmg_small;
      }
    }
    
    footer {
      display: flex; align-content: center; align-items: center;
      justify-content: space-between;
      @extend %transition_4e;
    }
  }
</style>