<template>
  <div class="loginController" v-if="!loading">
    <Logo class="loginPageLogin"/>
    <div class="loginIntro">
      <h3>Juntos aprendemos mais</h3>
      <p>Para entrar, utilize seu e-mail e senha</p>
    </div>
    <transition name="slideup-small">
      <!-- <a class="btn btn-secondary btn-large" href="#" @click.prevent="login" v-if="!emailLogin">
        Entrar com Google
      </a> -->
      <div class="googleButton" >
        <GoogleLogin :callback="googleSignIn"/>
      </div>
    </transition>
    <transition name="slideup-small">
      <form name="form" @submit.prevent="handleLogin" class="loginForm" v-if="!loading && emailLogin">
        <div class="form-group">
          <input-control 
            v-model="email"
            type="email"
            name="email"
            cssClass="text-center"
            @change="updateUser"
            placeholder="Email"
            :autocomplete="true"
          />
        </div>
        <div class="form-group">
          <input-control
            v-model="password"
            type="password"
            name="password"
            cssClass="text-center"
            @change="updatePass"
            :autocomplete="true"
            placeholder="Senha"
          />
        </div>
        <!-- <div class="deprecateAlert">
          <fa :icon="['fas','triangle-exclamation']" class="icon"></fa>
          <div>
            <p><b>Atenção!</b> O login com e-mail e senha será desabilitado, permanentemente, no dia <b>12 de abril</b>. A partir desta data, só será possível acessar o Apollo utilizando seu login Google.</p>
            <p>Caso tenha alguma dificuldade com seu acesso, procure o time de suporte.</p>
          </div>
        </div> -->
        <div class="form-group">
          <button class="btn btn-icon">
            <fa :icon="['far', 'arrow-right-long']" ></fa>
          </button>
        </div>
      </form>
    </transition>
    <a :class="['btn', 'btn-icon', { loaner: !emailLogin }]" href="#" @click.prevent="emailLogin ? googleSign() : toggleLogin()">
      <template v-if="emailLogin">
        <label>Entra com</label>
        <fa :icon="['fab', 'google']" class="icon"></fa>
      </template>
      <template v-else>
        <label>Entra com login e senha</label>
        <fa :icon="['fal', 'user-lock']" class="icon"></fa>
      </template>
    </a>
  </div>
</template>

<script>
import User from '@/models/user'
import Logo from '@/components/template/logo.vue'
import AuthService from '@/services/auth.service.js'
import UserService from '@/services/user.service.js'
import { googleOneTap, decodeCredential } from "vue3-google-login"


export default {
  name: 'Login',
  components: {
    Logo
  },  
  data() {
    return {
      user: new User('', ''),
      email: null,
      password: null,
      loading: false,
      message: '',
      emailLogin: false,
      logginIn: false
    };
  },
  beforeCreate () {
    if (localStorage.getItem('token')) {
      this.$router.push('/')
    }
  },
  computed: {
    loggedIn() {
      return localStorage.getItem('token')
    }
  },
  mounted () {
    console.log("this.loggedIn:", this.loggedIn)
    console.log("this.logginIn:", this.logginIn)
    if ( !this.loggedIn && !this.logginIn ) {
      this.logginIn = true
      
      googleOneTap({ autoLogin: true, cancelOnTapOutside: false })
      .then((response) => {
        this.googleSignIn(response)
      })
      .catch((error) => {
        console.log("Handle the error", error)
      })
    }
  },
  methods: {
    googleSignIn ( e ) {
      console.log("e:", e)
      const userData = decodeCredential(e.credential)
      console.log("userData:", userData)

      window.spinner.emit("open")
      this.loading = true
      const email = userData.email
      const credential = e.credential

      UserService.storePicture(userData)

      AuthService.loginGoogle(email, credential).then(
        response => {
          localStorage.setItem('token', response.data.token)
          localStorage.setItem('profile', btoa(JSON.stringify(response.data.profile)))
          
          const url = new URL(window.location.href)
          const goback = url.searchParams.get("goback")
          const route = goback ? goback : '/'

          this.$router.push(route)

          console.log("login google:", response)

          window.spinner.emit("close")
          this.loading = false
          // return response.data
        },
        error => {
          console.log("error:", error)
          window.toaster.emit('open', {style: "error", message: "Erro ao tentar acessar sua conta. Valide os dados e tente novamente.", floater: true})
          window.spinner.emit('close')
          this.loading = false
        }
      )
    },
    handleLogin() {
      if (this.user.email && this.user.password) {
        window.spinner.emit("open")
        this.loading = true
        this.$store.dispatch('auth/login', this.user).then(() => {
            const url = new URL(window.location.href)
            const goback = url.searchParams.get("goback")

            console.log("goback aqui...:", goback)

            if ( goback ) {
              this.$router.push(goback)
              
            } else {
              this.$router.go()
              window.spinner.emit("close")
            }
            
          },
          error => {
            this.loading = false
            this.message = ( error.response && error.response.data && error.response.data.message ) || error.message || error.toString()
            console.log("message:", this.message)
            window.spinner.emit("close")
            this.loading = false
            window.toaster.emit('open', {style: "error", message: "Erro ao tentar acessar sua conta. Valide os dados e tente novamente.", floater: true})
          }
        )
      }
    },
    updateUser (data) {
      const target = data.target
      if ( typeof target !== 'undefined' ) {
        this.user.email = data.target.value
      }
    },
    updatePass (data) {
      const target = data.target
      if ( typeof target !== 'undefined' ) {
        this.user.password = data.target.value
      }
    },
    toggleLogin () {
      this.emailLogin = !this.emailLogin
    }
  }
}
</script>

<style lang="scss" scoped>
.loginController {
    width: 100%; max-width: 440px;
    text-align: center;
    padding: 0 $mg;
  }
  .loginPageLogin {
    margin: auto;
    // margin: 0 auto 3em;
  }

  .loginForm {
    @extend %transition_4o;

    &.loading {
      opacity: 0;
    }
    
  }

  .loginIntro {
    max-width: 100%;
    padding: $mg 0;

    > * {
      color: #FFF;
      margin: 0; padding: 0;
    }
  }

  .btn {
    margin: auto;

    &.loaner {
      margin: $mg auto 0;
    }
  }

  .deprecateAlert {
    display: flex;
    padding: 2em; background-color: $color-alert;
    border-radius: $border-radius;
    overflow: visible; position: relative;

    .icon {
      font-size: 34px;
      color: $color-secondary;
      margin-right: $hmg_mid;
    }

    p {
      color: $color-secondary;
      text-align: left;
      line-height: 1.1;

      &:last-of-type {
        margin-bottom: 0 !important;
      }

      &.b {
        color: $color-primary;
      }
    }

    &::before {
      content: "";
      display: block; width: 20px; height: 20px;
      border-top-left-radius: 5px; transform: translateY(-45%) rotate(45deg);
      position: absolute; top: 0; left: 0; right: 0;
      margin: auto; background-color: $color-alert;
      z-index: 10;
    }
  }

  .googleButton {
    display: flex;
    flex-direction: column; align-content: center; align-items: center;
    justify-content: center;
    border-radius: 2em;
    background-color: rgba(0,0,0,0.1);
    padding: $mg_mid;
  }
</style>