<template>
  <div class="switch-control">
    <label :class="{active: checked, invert: invert, disabled: disabled, small: small}">
      <template v-if="labelPos == 'esq'">
        <p :class="['esq', { off: !checked }]" v-if="labelOn && labelOff">{{ checked ? labelOn : labelOff }}</p>
      </template>
      <div>
        <span></span>
      </div>
      <input 
        type="checkbox" 
        :checked="checked"
        v-bind="$attrs"
        @change="change"
      >
      <template v-if="labelPos == 'dir'">
        <p :class="['dir', { off: !checked }]" v-if="labelOn && labelOff">{{ checked ? labelOn : labelOff }}</p>
      </template>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    checked: {
      // type: [Boolean, Number],
      required: true,
    },
    invert: {
      type: Boolean,
      default: false,
    },
    labelOn: {
      type: String,
      default: null,
    },
    labelOff: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    labelPos: {
      type: String,
      default: "dir" //dir e esq
    }
  },
  data () {
    return {
      // active: false
    }
  },
  mounted () {

  },
  methods: {
    change ( e ) {
      console.log("change..")
      this.$emit('update:checked', e.target.checked)
    }
  }
}
</script>

<style lang="scss" >
  .switch-control {
    display: inline-flex !important;
    align-items: center;
    
    > label {
      display: inline-flex; justify-content: flex-start;
      align-content: center; align-items: center;
      padding: 0; margin: 0;
      
      &.disabled {
        opacity: .5; pointer-events: none;
      }
      
      > div {
        width: 30px; height: 22px; margin: 0;
        position: relative; z-index: 100;
        border-radius: 2em;
        background-color: $color-bg; border: 2px solid $color-bg;
        @extend %transition_3o;

        > span {
          display: block; width: 16px; height: 16px;
          border-radius: 100%;
          box-shadow: 1px 2px 6px rgba(0,0,0,0.1);
          background-color: $color-secondary;
          position: absolute; top: 50%; transform: translateY(-50%);
          box-sizing: border-box;
          left: 1px;
          @extend %transition_4e;
        }
      }

      p {
        padding: 0;
        line-height: 1; white-space: nowrap;

        &.dir {
          margin: 0 0 0 $hmg_mini;
          text-align: left;
        }
        &.esq {
          margin: 0 $hmg_mini 0 0;
          text-align: right;
        }

        &.off {
          opacity: .7;
        }
        // font-size: 15px;
      }
      input {
        opacity: 0;
        position: absolute;
        z-index: -1;
        appearance: none;
      }

      &.active {
        > div {
          background-color: $color-success; 
          border: 2px solid $color-success;

          > span {
            left: 9px;
          }
        }
      }

      &:hover {
        // background-color: $color-secondary;
        // border: 2px solid $color-success;

        >div {
          >span {
            // background-color: $color-success;
            transform: translateY(-50%) scale(1.1);
            box-shadow: 1px 3px 12px rgba(0,0,0,0.15);
          }
        }
      }

      &.invert {
        >div {
          background-color: $color-fade;
          border: 2px solid $color-fade;
        }
        
        &.active {
          >div {
            background-color: $color-success; 
            border: 2px solid $color-success;
          }
        }

        &.small {
          p {
            font-size: $font-size;
            font-weight: normal;
          }
        }
      }
    }
  }
</style>