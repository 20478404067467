<template>
  <div class="successIcon">
    <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
      <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
    </svg>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
  .successIcon {
    display: flex;
    justify-content: center;
    align-items: center;

    .checkmark__circle {
      stroke-dasharray: 166;
      stroke-dashoffset: 166;
      stroke-width: 2;
      stroke-miterlimit: 10;
      stroke: $color-success-2;
      fill: none;
      animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
      // animation-delay: 1s
    }

    .checkmark {
      width: 56px;
      height: 56px;
      border-radius: 50%;
      display: block;
      stroke-width: 2;
      stroke: #fff;
      stroke-miterlimit: 10;
      margin: auto;
      box-shadow: inset 0px 0px 0px $color-success-2;
      animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
      // animation-delay: 1s
    }

    .checkmark__check {
      transform-origin: 50% 50%;
      stroke-dasharray: 48;
      stroke-dashoffset: 48;
      animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
      // animation-delay: 1s
      
    }

  }

  
  @keyframes stroke {
    100% {
        stroke-dashoffset: 0
    }
  }

  @keyframes scale {

    0%,
    100% {
        transform: none
    }

    50% {
        transform: scale3d(1.1, 1.1, 1)
    }
  }

  @keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px $color-success-2
    }
  }
</style>