<template>
<section class="loginPage">
  <toaster />
  <spinner color="#FFF"/>
  <login-resp v-if="user == 'responsavel'" :section="section" @recuperasenha="toggleRecuperaSenha"></login-resp>
  <login-user v-else></login-user>
</section>
  
</template>

<script>

import LoginUser from '@/components/auth/LoginUser'
import LoginResp from '@/components/auth/LoginResp'

export default {
  name: 'Login',
  components: {
    LoginUser, LoginResp
  },
  props: {
    user: {
      type: String
    },
    section: {
      type: String
    }
  },
  data() {
    return {
      recuperaSenha: false
    };
  },
  computed: {
    
  },
  mounted () {
    if ( !this.user ) {
      this.$router.push({name: 'Login', params: { user: 'colab'} })
    }
  },
  methods: {
    toggleRecuperaSenha () {
      this.recuperaSenha = !this.recuperaSenha
      const section = this.recuperaSenha ? 'recupera-senha' : ''

      this.$router.push({name: 'Login', params: { user: this.user, section: section} })
    }
  }
}

</script>

<style lang="scss" scoped>

.loginPage {
  width: 100vw; min-height: 100vh;
  overflow: hidden;
  display: flex; align-content: center; align-items: center;
  justify-content: center; flex-direction: column;
  background-color: $color-primary;
}

</style>