<template>
  <controller :lockScroll="true">
    <form-spinner v-if="loading"/>
    <template v-else>
      <header-controller @onSelect="toggleSelect" @onEdit="toggleEdit" @onCreate="toggleCreate" :assistants="assistants" :active="active"/>
      <section class="assistant-controller">
        <div :class="['sidebar',{ on: sidebar }]">
          <edit-controller v-if="edit || create" :scope="active" @close="close"/>
        </div>
        <div class="assistant-body">
          <chat v-if="chat" :active="active" :funcaoAssistant="funcaoAssistant"/>
        </div>
      </section>
    </template>
  </controller>
</template>

<script>
import HeaderController from '@/components/ferramentas/assistentes/template/Header.vue'
import EditController from '@/components/ferramentas/assistentes/form/Edit.vue'
import Chat from '@/components/ferramentas/redechat/views/RedeChatView.vue'
import EstudAIService from '@/services/estudai.service.js'
import FormSpinner from '@/components/utilities/FormSpinner.vue'

export default {
  components: {
    HeaderController, EditController, Chat, FormSpinner
  },
  props: {
    id: {
      type: [String, Number]
    }
  },
  data () {
    return {
      sidebar: false,
      edit: null,
      create: false,
      assistants: [],
      active: null,
      chat: false,
      loading: false,
      saving: false,
      scope: {
        name: "",
        instructions: "",
        description: "",
        fileSearch: 1,
        idsFuncoes: []
      },
      funcaoAssistant: false
      // active: 'asst_w91ek3ZhKTa0EeOVJhpgnCWI'
    }
  },
  mounted () {
    this.getAssitants()
  },
  methods: {
    toggleSelect ( assistant ) {
      this.chat = false

      this.$nextTick(() => {
        this.active = assistant
        this.$router.push({ name: 'Assistentes', params: { id: assistant.id } })
        this.chat = true
        this.trackAssitantFuncao()
      })
      
    },
    toggleEdit ( assistant ) {
      console.log("edit:", assistant)
      this.active = assistant
      this.edit = true
      this.sidebar = true

      this.trackAssitantFuncao()
    },
    toggleCreate () {
      this.createAssistant()
    },
    getAssitants () {
      this.loading = true
      EstudAIService.getAssistants().then(
        response => {
          if ( response.data.length > 0 ) {
            this.assistants = response.data
            this.toggleSelect(this.assistants[0])
            console.log("getAssistants:", this.assistants)
          }
          this.loading = false
        },
        error => {
          console.error(error)
          this.loading = false
        }
      )
    },
    createAssistant () {
			this.loading = true
      
			EstudAIService.createAssistant(this.scope).then(
				response => {
					console.log('createAssistant', response.data)
          this.active = response.data
          this.assistants.push(this.active)
					this.loading = false

          this.create = true
          this.sidebar = true
				},
				error => {
					console.error(error)
					this.loading = false
				}
			)
		},
    close () {
      this.edit = false
      this.create = false
      this.sidebar = false
    },
    trackAssitantFuncao () {
      console.log("assistant:", this.active)
      this.funcaoAssistant = this.active.idsFuncoes.length > 0

      console.log("funcaoAssistant:", this.funcaoAssistant)
    }
  }
}
</script>

<style lang="scss" scoped>
  .assistant-controller {
    display: flex; align-items: stretch;
    height: calc(100vh - 60px - 62px);

    .sidebar {
      @extend %transition_4o;

      &.on {
        width: 310px;
      }
    }

    .assistant-body {
      width: 100%; height: auto; 
      overflow-y: auto;
    }
  }
</style>