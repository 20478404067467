<template>
  <controller :lockScroll="lockScroll">
    <page-header title="Usuários Online"></page-header>
    <list-controller></list-controller>
  </controller>
</template>

<script>
import ListController from '@/components/ferramentas/onlineUsers/List.vue'

export default {
  components: {
    ListController
  },
  data() {
    return {
      categoria: null
    }
  },
  mounted () {

  },
  methods: {
  }
}
</script>

<style>

</style>