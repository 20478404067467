<template>
  <controller>
    <span ref="form"></span>
    <page-header title="Funções"></page-header>
    <section class="page-content">
      <transition name="fade">
        <criarFuncoes v-if="criar" :criarID="criarID" @close="close" @updateParentList="updateParentList"></criarFuncoes>
      </transition>
      <main>
        <datatable
          :headers="headers" 
          :data="content"
          :actions="actions"
          :keys="keys"
          @onAdd="novo"
        ></datatable>
      </main>
      <confirm 
        @callback="onConfirm" 
        ref="confirm"
      ></confirm>
    </section>
  </controller>
</template>
<script>
import axios from 'axios'
import authHeader from '@/services/auth-header'
import criarFuncoes from './Criar'

export default {
  name: "Funções",
  components: {
    criarFuncoes
  },
  data () {
    return {
      focused: false,
      aluno: null,
      responsavel: null,
      filiacao: null,
      unidade: null,
      api: null,
      headers: ['Nome', 'Setor', ''],
      keys: ['nome', 'descricao'],
      content: [],
      criar: false,
      criarID: null,
      actions: [
        {
          icon: ['far', 'pencil'],
          label: "Editar",
          showLabel: false,
          callback: (item) => {
            this.criar = true
            this.scrollUp()
            setTimeout(() => {
              this.criarID = item
            }, 100)
          }
        },
        {
          icon: ["far", "trash-can"],
          label: "Remover",
          showLabel: false,
          callback: (item) => {
             this.$refs.confirm.run({
               message: "Tem certeza que deseja remover este item?",
               confirmButton: "Sim",
               denyButton: "Não",
               callback: this.onConfirm,
               data: item
             })
          }
        }
      ]
    }
  },
  mounted () {
    this.listFuncoes()
  },
  methods: {
    listFuncoes () {
      window.spinner.emit('open')
      axios.get(process.env.VUE_APP_API_URL + 'funcoes',{ headers: authHeader() })
      .then( response => {
          this.content = response.data
      })
      .catch( error => {
        console.error("error:", error)
      }).then(() =>{
        window.spinner.emit('close')
      })
    },
    close () {
      this.criar = false
      this.criarID = null
    },
    delete (id) {
      window.spinner.emit('open')
      axios.delete(process.env.VUE_APP_API_URL + 'funcao/' + id, { headers: authHeader() })
      .then(response => {
        window.toaster.emit('open', {style: "success", message: "Função removida com sucesso."})
        this.listFuncoes()
        return response.data
      }).then(() =>{
        window.spinner.emit('close')
      })
    },
    updateParentList (){
      this.listFuncoes()
    },
    onConfirm (data,item) {
      this.delete(item.idFuncao)
    },
    novo () {
      // console.log("adicionar novo item...")
      this.criar = true
      this.scrollUp()  
    },
    scrollUp () {
      this.$refs.form.scrollIntoView({ behavior: "smooth", block: 'start' })
    }
  }
}
</script>
<style lang="scss" scoped>
  main {
    padding: $hmg_mid
  }
</style>