<template>
  <section class="section">
    <div class="planilhasListWrap" v-if="documentos">        
      <ol class="cardList">
        <li>
          <div class="plItemInner">
            <span>
              Documento
            </span>
            <span>
              Documento do Responsável
            </span>
            <span>
              É Contrato
            </span>
            <span>
              Anexa ao contrato
            </span>
            <span>
              Vinculado a Matrícula
            </span>
            <span>
              Validade
            </span>
            <span></span>
          </div>
        </li>
        <li v-for="documento in documentos" :key="documento">
          <card spacing="small">
            <div class="plItemInner">
              <span class="fixed">
                {{ documento.descricao }}
              </span>
              <span>
                {{ documento.documentoresponsavel ? 'Sim' : 'Não' }}
              </span>
              <span>
                {{ documento.contrato ? 'Sim' : 'Não' }}
              </span>
              <span>
                {{ documento.anexoContrato ? 'Sim' : 'Não' }}
              </span>
              <span>
                {{ documento.vinculoMatricula ? 'Sim' : 'Não' }}
              </span>
              <span>
                {{ documento.validade ? documento.validade : 'ND' }}
              </span>
              <span class="actionWrap">
                <a href="#" @click.prevent="selectDocumento(documento)">
                  <fa :icon="['far','magnifying-glass']"></fa>
                </a>
              </span>
            </div>
          </card>
        </li>
      </ol>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    scope: {
      type: Array
    }
  },
  data () {
    return {
      documentos: null
    }
  },
  mounted () {
    this.documentos = this.scope
  },
  methods: {
    selectDocumento ( documento ) {
      this.$emit('onSelect', documento)
    }
  }
}
</script>

<style lang="scss" scoped>
  .section {
    padding: $hmg $hmg_small $mg_extra 
  }
  .plItemInner {
    grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr .3fr;
  }
  
  .action-wrap {
    display: flex; align-content: center; align-items: center;
    justify-content: flex-end;
    padding: $hmg $mg;

    a {
      margin-left: $hmg_small
    }

    label{
      margin-right: $hmg
    }
  }
</style>