<template>
  <controller>
    <datatable :data="content" :keys="keys"></datatable>
  </controller>
</template>
<script>
import axios from 'axios'
import authHeader from '../../../services/auth-header'

export default {
  name: "Docs",
  data () {
    return {
      content: [],
      keys: [],
      max: 1000,
      count: 0
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    fetchData () {
      axios.get("comprovante_endereco_2.json",{ headers: authHeader() })
      .then( response => {
        this.processData(response.data)
          console.log("response:",response.data)
      })
      .catch( error => {
        console.error("error:", error)
      }).then(() =>{
        window.spinner.emit('close')
      })
    },
    processData (data) {
      this.content = []
      data.map( (k , i) => {
        const src = k.palavrasRecuperadas
        const row = src.substring(1, src.length-1)
        const words = row.split(", ")

        words.map( (w, wi) => {
          words[wi] = w.toLowerCase()
        })

        data[i].words = words
      })
      
      this.removeRepeated(data)
      // this.findData(data)
      // console.log("content:", data)
    },
    findData (data) {
      const result = []
      const repeated = []
      const ids = []
      // const conditions = ["nascimento", "certidão"]
      // let counter = 0

      data.map( k => {

        // ARQUIVOS UNICOS --------
        // const pc = k.palavrasRecuperadas
        // const exists = result.filter(e => {
        //   return pc === e.palavrasRecuperadas
        // }).length > 0

        // if (!exists) {
        //   result.push(k)
        //   ids.push(k.idStagingDocumentosS3)
        // } else {
        //   repeated.push(k.idStagingDocumentosS3)
        // }

        if ( 
          // CERTIDÃO DE NASCIMENTO
          k.words.includes("certidão") &&
          !k.words.includes("gás") && !k.words.includes("habilitação") && !k.words.includes("curriculares") &&
          !k.words.includes("histórico") && !k.words.includes("inscrição") && !k.words.includes("Fiscal") && !k.words.includes("adimplência") && !k.words.includes("declaração") && !k.words.includes("expedição")||
          k.words.includes("nascimento") &&
          !k.words.includes("gás") && !k.words.includes("habilitação") && !k.words.includes("curriculares") &&
          !k.words.includes("histórico")  && !k.words.includes("inscrição") && !k.words.includes("Fiscal") && !k.words.includes("adimplência") && !k.words.includes("declaração") && !k.words.includes("expedição")
          //----------

          // CONTRATO
          // k.words.includes("contrato") && 
          // k.words.includes("obrigações") &&
          // !k.words.includes("consulta") &&
          // !k.words.includes("vivo") &&
          // !k.words.includes("comgas") &&
          // !k.words.includes("conta")
          //----------

          // REDECHECK
          // k.words.includes("consulta") &&
          // !k.words.includes("nascimento") &&
          // !k.words.includes("nextel") &&
          // !k.words.includes("fatura")
          //----------

          // DECLARAÇÃO ESCOLARIDADE
          // k.words.includes("declaração") &&
          // k.words.includes("escolaridade")
          //----------

          // COMPROVANTE DE RESIDÊNCIA
          // k.words.includes("conta") &&
          // !k.words.includes("nascimento") && !k.words.includes("maternos") && !k.words.includes("identificação") && !k.words.includes("vacinação") ||
          // k.words.includes("instalação") &&
          // !k.words.includes("nascimento") && !k.words.includes("maternos") && !k.words.includes("identificação") && !k.words.includes("vacinação") ||
          // k.words.includes("vivo") &&
          // !k.words.includes("nascimento") && !k.words.includes("maternos") && !k.words.includes("identificação") && !k.words.includes("vacinação") ||
          // k.words.includes("tim") &&
          // !k.words.includes("nascimento") && !k.words.includes("maternos") && !k.words.includes("identificação") && !k.words.includes("vacinação") ||
          // k.words.includes("eletropaulo") &&
          // !k.words.includes("nascimento") && !k.words.includes("maternos") && !k.words.includes("identificação") && !k.words.includes("vacinação") ||
          // k.words.includes("efetricidade") &&
          // !k.words.includes("nascimento") && !k.words.includes("maternos") && !k.words.includes("identificação") && !k.words.includes("vacinação") ||
          // k.words.includes("água") &&
          // !k.words.includes("nascimento") && !k.words.includes("maternos") && !k.words.includes("identificação") && !k.words.includes("vacinação") ||
          // k.words.includes("aes") &&
          // !k.words.includes("nascimento") && !k.words.includes("maternos") && !k.words.includes("identificação") && !k.words.includes("vacinação") ||
          // k.words.includes("eegotos") &&
          // !k.words.includes("nascimento") && !k.words.includes("maternos") && !k.words.includes("identificação") && !k.words.includes("vacinação") ||
          // k.words.includes("esgotos") &&
          // !k.words.includes("nascimento") && !k.words.includes("maternos") && !k.words.includes("identificação") && !k.words.includes("vacinação") ||
          // k.words.includes("tarifa") &&
          // !k.words.includes("nascimento") && !k.words.includes("maternos") && !k.words.includes("identificação") && !k.words.includes("vacinação")
          //----------

          // CARTEIRA DE VACINAÇÃO
          // k.words.includes("vacinação") && 
          // !k.words.includes("conta") && !k.words.includes("aes") && !k.words.includes("banco") ||
          // k.words.includes("vacina") && 
          // !k.words.includes("conta") && !k.words.includes("aes") && !k.words.includes("banco") ||
          // k.words.includes("doses") && 
          // !k.words.includes("conta") && !k.words.includes("aes") && !k.words.includes("banco") ||
          // k.words.includes("vida") && 
          // !k.words.includes("conta") && !k.words.includes("aes") && !k.words.includes("banco") ||
          // k.words.includes("obrioatorias") && 
          // !k.words.includes("conta") && !k.words.includes("aes") && !k.words.includes("banco") ||
          // k.words.includes("tríplice") && 
          // !k.words.includes("conta") && !k.words.includes("aes") && !k.words.includes("banco")
          //----------

          // HISTÓRICO ESCOLAR
          // k.words.includes("colégio") &&
          // !k.words.includes("transferência") && !k.words.includes("adimplência") && !k.words.includes("quitou") && 
          // !k.words.includes("quitação") && !k.words.includes("quitacao") ||
          // k.words.includes("histórico") &&
          // !k.words.includes("transferência") && !k.words.includes("adimplência") && !k.words.includes("quitou") && 
          // !k.words.includes("quitação") && !k.words.includes("quitacao") ||
          // k.words.includes("escolaridade") &&
          // !k.words.includes("transferência") && !k.words.includes("adimplência") && !k.words.includes("quitou") && 
          // !k.words.includes("quitação") && !k.words.includes("quitacao")

          // COMPROVANTE DE QUITAÇÃO
          // k.words.includes("quitaçao") && !k.words.includes("iptu") ||
          // k.words.includes("quitação") && !k.words.includes("iptu") ||
          // k.words.includes("quitado") && !k.words.includes("iptu") ||
          // k.words.includes("instrumento") && !k.words.includes("iptu")
          //----------
        ) {
          const pc = k.palavrasRecuperadas

          const exists = result.filter(e => {
            return pc === e.palavrasRecuperadas
          }).length > 0
          
          // console.log("exists:", exists)

          if (!exists) {
            result.push(k)
            ids.push(k.idStagingDocumentosS3)
            // counter++
          } else {
            repeated.push(k.idStagingDocumentosS3)
          }
          // console.log("pc:", pc)
          // console.log("pc1:", pc1)
          // console.log("result:", result)
          
        }
      })

      // this.findElements(result)
      console.log("ids:", JSON.stringify(ids))
      // console.log("ids:", ids)
      console.log("repeated:", repeated)
      // console.log("repeated:", JSON.stringify(repeated))
      console.log("result:", result)
    },
    removeRepeated (data) {
      const cleared = []
      const repeated = []
      const ids = []
      // const total = data.length
      // console.log("total:", total)

      data.map( k => {
        const pr = k.palavrasRecuperadas
        const exists = cleared.filter(e => {
          return pr === e.palavrasRecuperadas
        }).length > 0

        if ( !exists ) {
          cleared.push(k)
          ids.push(k.idStagingDocumentosS3)
        } else repeated.push(k.idStagingDocumentosS3)
      })

      console.log("cleared:", cleared)
      console.log("ids:", JSON.stringify(ids))

      this.processCertidao(cleared)
    },
    processCertidao (data) {
      const found = []
      
      data.map( k => {
        // console.log("k:", k)
        const track = []

        k.words.map( (w , i) => {
          // console.log("w:", w)
          if ( w === 'cep:' ) {
            
            if ( !track.includes(i) ) {
              track.push(i)
              const obj = {
                '0': k.words[i],
                '1': k.words[i + 1],
                '2': k.words[i + 2],
                '3': k.words[i + 3],
              }

              found.push(obj)
            }
          }
        })
      })

      console.log("found:", found)
    }
  }
}
</script>