<template>
  <section>
    <transition name="fadein">
      <div class="frame sm fixed" v-if="ready && !nova">
        <header>
          <h4>Filtrar Matrículas</h4>
          <p>Encontre as matrículas que você procura.</p>
        </header>
        <form @keyup.enter="runFilter" @submit.prevent="">
          <div class="row no-padd">
            <div class="col-md-5 form-group">
              <select-control v-model="filter.unidade" name="unidade" :options="options.unidades" placeholder="Selecione" @change="getPeriodosLetivos()" :disabled="loadingOpts" label="Unidade"/>
            </div>
            <div class="col-md-3 form-group">
              <select-control v-model="filter.periodoLetivo" :options="options.periodosLetivos" placeholder="Selecione" @change="getSeries()" :disabled="loadingOpts" label="Período Letivo"/>
            </div>
            <div class="col-md-4 form-group">
              <select-control v-model="filter.tipoCurso" name="tipoCurso" :options="options.tipoCurso" placeholder="Selecione"  @change="getSeries()" :disabled="loadingOpts" label="Tipo de Curso"/>
            </div>
            <div class="col-md-4 form-group">
              <select-control v-model="filter.serie" :options="options.series" placeholder="Selecione" @change="getTurmas()" :disabled="loadingOpts" label="Série"/>
            </div>
            <div class="col-md-4 form-group">
              <select-control v-model="filter.turma" :options="options.turmas" placeholder="Selecione" :disabled="loadingOpts" label="Turma"/>
            </div>
            <div class="col-md-4 form-group">
              <select-control v-model="filter.documentos" name="name" :options="options.documentos" placeholder="Selecione" :disabled="loadingOpts" label="Documentos"/>
            </div>
            <div class="col-md-4 form-group">
              <select-control v-model="filter.tipoNome" name="tipoNome" :options="options.tipoNome" placeholder="Selecione" :disabled="loadingOpts" label="Tipo"/>
            </div>
            <div class="col-md-8 form-group">
              <input-control v-model="filter.nome" name="nome" placeholder="Nome" :autocomplete="true" label="Nome"/>
            </div>
          </div>
          <footer>
            <a href="#" class="btn btn-primary" @click.prevent="runFilter()" :disabled="loadingOpts">
              Filtrar
            </a>
            <a href="#" class="btn btn-secondary" @click.prevent="clearFilter()" :disabled="loadingOpts">
              Limpar
            </a>
          </footer>
        </form>
      </div>
    </transition>
    <transition name="fadein">
      <div class="frame sm fixed" v-if="ready && nova">
        <header>
          <h4>Iniciar Matrícula</h4>
          <p>Escolha as informações iniciais para a nova matrícula</p>
        </header>
        <form @keyup.enter="runNovaFilter" @submit.prevent="">
          <div class="row no-padd">
            <div class="col-md-8 form-group">
              <select-control v-model="filter.unidade" name="unidade" :options="options.unidades" placeholder="Selecione" @change="getPeriodosLetivos()" :disabled="loadingOpts" label="Unidade"/>
            </div>
            <div class="col-md-4 form-group">
              <select-control v-model="filter.periodoLetivo" :options="options.periodosLetivos" placeholder="Selecione" @change="getSeries()" :disabled="loadingOpts" label="Período Letivo"/>
            </div>
            <div class="col-md-4 form-group">
              <select-control v-model="filter.cursos" :options="options.cursos" placeholder="Selecione" :disabled="loadingOpts" @change="selectCurso()" label="Curso"/>
            </div>
            <div class="col-md-8 form-group">
              <select-control v-model="filter.serie" :options="options.series" placeholder="Selecione" :disabled="loadingOpts" label="Série"/>
            </div>
          </div>
          <div class="col-md-12 form-actions form-group">
            <a href="#" class="btn btn-primary" @click.prevent="runNovaFilter()" :disabled="loadingOpts">
              Filtrar
            </a>
            <a href="#" class="btn btn-secondary" @click.prevent="clearFilter()" :disabled="loadingOpts">
              Limpar
            </a>
          </div>
        </form> 
      </div>
    </transition>
    <form-spinner v-if="loading" />
  </section>
</template>

<script>
import UserService from '@/services/user.service.js'
import AcademicoService from '@/services/academico.service.js'
import FormSpinner from '@/components/utilities/FormSpinner.vue'
import { createSelectOptions } from '@/services/utils.service.js'

export default {
  components: {
    FormSpinner
  },
  props: {
    nova: {
      type: Boolean
    }
  },
  data () {
    return {
      loading: false,
      ready: false,
      filter: {
        nome: null,
        unidade: null,
        tipoCurso: null,
        periodoLetivo: null,
        serie: null,
        turma: null,
        documentos: '0',
        tipoNome: '1',
      },
      options: {
        unidades: [],
        tipoCurso: [],
        periodosLetivos: [],
        series: [],
        turmas: [],
        cursos: [],
        documentos: [
          { label: 'Documentos', value: '0' },
          { label: 'Pendentes de entrega', value: 'P' },
          { label: 'Pendente validação', value: 'N' },
          { label: 'Validado', value: 'V' },
          { label: 'Inválido', value: 'I' }
        ],
        tipoNome: [
          { label: 'Todos', value: '0' },
          { label: 'Aluno', value: '1' },
          { label: 'Financeiro', value: '2' },
          { label: 'Pedagógico', value: '3' },
          { label: 'Filiação 1', value: '4' },
          { label: 'Filiação 2', value: '5' }
        ]
      },
      loadingOpts: false,
      wait: {
        unidades: false,
        tipoCurso: false,
        cursos: false
      },
      matriculas: [],
      cursos: [],
      series: [],
      turmas: null
    }
  },
  mounted () {
    this.getUnidades()
    this.getTipoCurso()
    this.getCursos()
  },
  methods: {
    getUnidades () {
      UserService.getMinhasUnidades().then(
        response => {
          console.log("getMinhasUnidades:", response.data)
          this.options.series = []
          this.options.periodosLetivos = []
          
          if ( response.data.length > 0 ) {
            this.options.unidades = createSelectOptions(response.data, 'idUnidade' ,'descricao')
            this.wait.unidades = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhuma unidade encontrada. Verifique sua conexão e tente novamente.", floater: true})
          }
          
          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma unidade encontrada. Verifique sua conexão e tente novamente.", floater: true})
        }
      )
    },
    getTipoCurso () {
      AcademicoService.getTipoCurso().then(
        response => {
          console.log("getTipoCurso:", response.data)
          if ( response.data.length > 0 ) {
            this.options.tipoCurso = createSelectOptions(response.data, 'idTipoCurso' ,'descricao')
            this.wait.tipoCurso = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum tipo de curso encontrado. Verifique sua conexão e tente novamente.", floater: true})
          }
          
          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum tipo de curso encontrado. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    getCursos () {
      AcademicoService.getCursos().then(
        response => {
          console.log("getCursos:", response.data)
          if ( response.data.length > 0 ) {
            this.cursos = response.data
            this.options.cursos = createSelectOptions(response.data, 'idCurso' ,'descricao')
            this.wait.cursos = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum curso encontrado. Isto parece ser um problema de parametrização.", floater: true})
          }

          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum curso encontrado. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    getPeriodosLetivos () {
      this.loadingOpts = true
      AcademicoService.getPeriodosLetivos(this.filter.unidade).then(
        response => {
          console.log("getPeriodosLetivos:", response.data)
          
          if ( response.data.length > 0 ) {
            this.options.periodosLetivos = createSelectOptions(response.data, 'idPeriodoLetivo' ,'descricao').reverse()
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum período letivo encontrado para esta unidade. Isto parece ser um problema de parametrização.", floater: true})
          }
          this.loadingOpts = false
        },
        error => {
          console.error(error)
          this.loadingOpts = false
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum período letivo encontrado para esta unidade. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    getSeries () {
      if ( !this.nova && this.filter.periodoLetivo && this.filter.tipoCurso || this.nova && this.filter.periodoLetivo ) {
        this.loadingOpts = true
        const curso = this.filter.tipoCurso ?? 0

        AcademicoService.getSeries(this.filter.periodoLetivo, curso).then(
          response => {
            // console.log("getSeries:", response.data)
            this.options.series = []

            if ( response.data.length > 0 ) {
              this.series = response.data
              response.data.map( k => {
                k.label = k.descricao + ' ' + k.descricaoTurno
              })
              this.options.series = createSelectOptions(response.data, 'idSerie' ,'label')
              
            } else {
              window.toaster.emit('open', {style: "error", message: "Nenhuma série encontrada para este período letivo. Isto parece ser um problema de parametrização.", floater: true})
            }

            this.loadingOpts = false
          },
          error => {
            console.error(error)
            this.loadingOpts = false
            window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma série encontrada para este período letivo. Isto parece ser um problema de parametrização.", floater: true})
          }
        )
      }
    },
    getTurmas () {
      this.loadingOpts = true
      AcademicoService.getTurmas(this.filter.serie).then(
        response => {
          // console.log("getTurmas:", response.data)
          if ( response.data.length > 0 ) {
            this.options.turmas = createSelectOptions(response.data, 'idTurma' ,'descricao')
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhuma turma encontrada para esta série. Isto parece ser um problema de parametrização.", floater: true})
          }
          this.loadingOpts = false
        },
        error => {
          console.error(error)
          this.loadingOpts = false
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma turma encontrada para esta série. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    finishLoad () {
      const finished = !Object.values(this.wait).some( a => !a)

      if ( finished ) {
        this.ready = true
        this.trackParams()
      }
    },
    runFilter () {
      const empty = Object.values(this.filter).every(e => !e)

      if ( empty ) {
        window.toaster.emit('open', {style: "warning", message: "Preencha ao menos um campo, para filtrar.", floater: true})
      } else {
        const options = {
          idUnidade: this.filter.unidade,
          idTipoCurso: this.filter.tipoCurso,
          idPeriodoLetivo: this.filter.periodoLetivo,
          idSerie: this.filter.serie,
          idTurma: this.filter.turma,
          validado: this.filter.documentos,
          tipoNome: this.filter.tipoNome,
          nome: this.filter.nome,
          idTipoSituacao: 0
        }

        this.loading =  true
        AcademicoService.filtrarMatriculas( options ).then(
          response => {
            console.log("filtrarMatriculas:", response)
            if ( response.data.length > 0 ) {
              this.matriculas = response.data
              this.$emit("onFilter", { matriculas: this.matriculas, filter: this.filter })
            } else {
              window.toaster.emit('open', {style: "warning", message: "Não foi possível encontrar nenhuma matrícula com as informações enviadas.", floater: true})
            }
            
            this.loading = false
          },
          () => {
            this.loading =  false
            window.toaster.emit('open', {style: "warning", message: "Ops. Algo aconteceu. Não conseguimos processar sua requisição. Confira os dados e tente novamente.", floater: true})
          }
        )
      }
    },
    runNovaFilter () {
      const serie = this.filter.serie ?? 0
      this.loading = true
      AcademicoService.getTurmas( serie ).then(
          response => {
            // console.log("filterTurmas:", response)
            if ( response.data.length > 0 ) {
              this.turmas = response.data
              this.$emit('onFilter', this.turmas)
              console.log("turmas:", this.turmas)
            } else {
              window.toaster.emit('open', {style: "warning", message: "Não foi possível encontrar nenhuma turma com as informações enviadas.", floater: true})
            }
            this.loading = false
          },
          () => {
            this.loading =  false
          }
        )
    },
    selectCurso () {
      const selected = this.filter.cursos

      const result = this.series.filter( k => {
        return k.idCurso == selected
      })

      console.log("result:", result)
      if ( result.length > 0 ) {
        this.options.series = createSelectOptions(result, 'idSerie' ,'label')
      } else {
        this.options.series = []
      }
    },
    clearFilter ( clearoptions ) {
      // this.cursos = []
      // this.series = []
      this.filter = {
        nome: null,
        unidade: null,
        tipoCurso: null,
        periodoLetivo: null,
        serie: null,
        turma: null,
        documentos: '0',
        tipoNome: '1',
      }

      if ( clearoptions ) {
        this.options = {
          unidades: [],
          tipoCurso: [],
          periodosLetivos: [],
          series: [],
          turmas: [],
          cursos: [],
          documentos: [
            { label: 'Documentos', value: '0' },
            { label: 'Pendentes de entrega', value: 'P' },
            { label: 'Pendente validaçã', value: 'N' },
            { label: 'Validado', value: 'V' },
            { label: 'Inválido', value: 'I' }
          ],
          tipoNome: [
            { label: 'Todos', value: '0' },
            { label: 'Aluno', value: '1' },
            { label: 'Financeiro', value: '2' },
            { label: 'Pedagógico', value: '3' },
            { label: 'Filiação 1', value: '4' },
            { label: 'Filiação 2', value: '5' }
          ]
        }
      }
      
      this.$emit('onEmpty')
    },
    trackParams(){
      const currentRoute = this.$router.currentRoute._value
      const query = currentRoute.query

      if ( Object.values(query).length > 0 ) {
        console.log("tem query...")
        Object.keys(this.filter).map( k => {
          console.log("k", k)
          this.filter[k] = query[k]
        })
        this.runFilter()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .frame {
    header {
      padding: $hmg;

      p {
        margin: 0; padding: 0;
      }
    }
  }

  form {
    padding: $mg_mini
  }
  section {
    border-bottom: $border-component
  }
  footer {
    display: flex; align-items: center;
    gap: $mg_mini;
    padding: $hmg_mid
  }
</style>