<template>
  <section class="slider-control">
    <div class="frame sliderTitle">
      <h1>{{ title }}</h1>
      <a :href=" '/loja/categoria/' + id + '/' + encodeURIComponent(title) " class="btn btn-primary btAll">Ver tudo</a>
    </div>
    <div class="slider-controller" v-if="list">
      <div class="viewport">
        <ul class="slider" ref="slider" :style="{ transform: 'translate( ' + left + 'px , 0 )' }">
          <li class="slide" v-for="( item, i ) in list" :key="item" :ref=" 'item_' + i " :style="{ left: itemPos(i, item), width: itemWidth(i, item) }">
            <loja-product-card :produto="item"></loja-product-card>
          </li>
        </ul>
        <div class="buttons">
          <a href="#" @click="move('left')" :class="['direction-button', { disabled: !allowLeft }]">
            <fa :icon="['fas', 'chevron-left']" class="icon" />
          </a>
          <a href="#" @click="move('right')" :class="['direction-button', { disabled: !allowRight }]">
            <fa :icon="['fas', 'chevron-right']" class="icon" />
          </a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import LojaProductCard from '@/components/loja/template/ProductCard'
import { gsap } from "gsap"

export default {
  name: 'Slider',
  components: {
    LojaProductCard
  },
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    id: {
      type: Number,
      default: null
    },
    title: {
      type: String,
      default: ""
    },
    slug: {
      type: String,
      default: ""
    }
  },
  watch: {
    data ( value ) {
      if ( value ) {
        // console.log("list:", value)
        this.list = value
      }
    }
  },
  data () {
    return {
      list: [],
      left: window.innerWidth < 1220 ? 10 : (window.innerWidth - 1220) / 2 + 20,
      allowLeft: false,
      allowRight: true,
      resizeLoop: null
    }
  },
  mounted () {
    this.left = window.innerWidth < 1220 ? 10 : (window.innerWidth - 1220) / 2 + 20

    document.addEventListener('resize', this.resize)
  },
  methods: {
    calcW ( ) {
      const w = window.innerWidth / 4
      const mg = 10
      const min = window.innerWidth < 1024 ? ( window.innerWidth - ( mg * 2 ) ) : 380
      const max = window.innerWidth < 1024 ? ( window.innerWidth - ( mg * 2 ) ) : 400
      const width = w < min ? min : ( w > max ? max : w)

      return width
    },
    itemPos ( i ) {
      return ( this.calcW() * i ) + 'px'
    },
    itemWidth () {
      return this.calcW() + 'px'
    },
    move ( direction ) {
      // console.log("direction:", direction)
      const refs = this.$refs
      const slideW = refs.item_1[0].clientWidth
      const currentPos = this.$refs.slider.getBoundingClientRect().left

      console.log("slideW:", slideW)
      console.log("refs:", refs)
      console.log("currentPos:", currentPos)

      if ( direction === 'left' ) {
        let pos = currentPos + slideW
        gsap.to(this.$refs.slider, {x: pos, duration: .5, ease: "back.out(1.7)"})
        this.trackAllowMove(pos)
      }

      if ( direction === 'right' ) {
        let pos = currentPos - slideW
        gsap.to(this.$refs.slider, {x: pos, duration: .5, ease: "back.out(1.7)"})
        this.trackAllowMove(pos)
      }
      
    },
    trackAllowMove ( pos ) {
      // const offset = 30 * ( this.list.length - 2 )
      const slideW = parseFloat (this.calcW() * ( this.list.length - 1 ) )
      const viewport = window.innerWidth < 1220 ? window.innerWidth : 1220
      const end = -( slideW - viewport )

      console.log('left:', this.left)
      console.log('pos:', pos)
      console.log('end:', end)

      if ( pos < this.left ) {
        console.log("pos < left")
        this.allowLeft = true
      }
      if ( pos == this.left ) {
        console.log("pos = left")
        this.allowLeft = false
      } else if ( pos <= end ) {
        console.log("pos <= end")
        this.allowRight = false
        // this.allowLeft = true
      } else {
        console.log("other")
        this.allowRight = true
      }
    },
    resize () {
      if ( !this.resizeLoop ) {
        this.resizeLoop = setTimeout (() => {
          clearTimeout(this.resizeLoop)
          this.left = window.innerWidth < 1220 ? 10 : (window.innerWidth - 1220) / 2 + 20
        },200)
      } else {
        this.resizeLoop = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .slider-control {
    display: block;
    width: 100%; height: auto;
    margin-top: $mg;

    .sliderTitle {
      padding: 0 $mg; margin-bottom: $hmg;
      display: flex; align-items: center; align-content: center;

      .btn {
        margin-left: $hmg;
        white-space: nowrap;
      }

      
    }

    .slider-controller {
      display: block; width: 100%; height: 640px;
      
      .viewport {
        width: 100%; height: 100%;
        overflow: hidden; position: relative;

        .slider{
          position: absolute; top: 0; left: 0;
          z-index: 100;
          width: 100%; height: 100%;
          // display: flex; align-content: flex-start; align-items: center;

          .slide {
            width: 340px; position: absolute; top: 0;
            list-style: none; height: 100%;
          }
        }
        .buttons {
          position: absolute;
          z-index: 101; padding: 0 $mg;
          width: 100%; top: 50%; transform: translateY(-50%);
          display: flex; align-items: center; align-content: center; justify-content: space-between;
          pointer-events: none;

          @media screen and ( max-width: 1024px ) {
            padding: 0;
          }

          a {
            pointer-events: all;
            width: 80px; height: 80px;
            font-size: 50px; color: $color-primary;
            display: flex; align-items: center; align-content: center; justify-content: center;
            border-radius: 100%;
            
            @media screen and ( min-width: 1024px ) {
              &:hover {
                background-color: #FFF;
              }
            }
            .icon {
              @extend %transition_3o;
              transform: scale(1.1);
            }

            &:first-child {
              .icon {margin-right: 4px;}
            }

            &:last-child {
              .icon {margin-left: 4px;}
            }

            &.disabled {
              opacity: .1;
              pointer-events: none;
            }

            @media screen and ( max-width: 1024px ) {
              width: 50px; height: 50px;
              font-size: 40px;

              &:first-child {
                .icon {margin-right: 0;}
              }

              &:last-child {
                .icon {margin-left: 0;}
              }
            }

          }
        }
      }
    }
  }
</style>