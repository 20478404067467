import axios from 'axios'
import authHeader from './auth-header'
// import moment from 'moment'

class FormulariosService {
  getFormularios (ativo) {

    const status = ativo ?? 0

    return axios.get(process.env.VUE_APP_API_URL + 'parametro/formularios/0/' + status, { headers: authHeader() })
  }

  getFormulario ( id ) {
    return axios.get(process.env.VUE_APP_API_URL + 'parametro/formulario/' + id, { headers: authHeader() })
  }

  updateFormularios ( data ) {
    const id = data.idFormulario
    return axios.put(process.env.VUE_APP_API_URL + 'parametro/formulario/' + id, data, { headers: authHeader() })
  }

  getPerguntas ( id ) {
    return axios.get(process.env.VUE_APP_API_URL + `parametro/formularioPerguntas/${id}/0/0`, { headers: authHeader() })
  }

  getAlternativas ( id ) {
    return axios.get(process.env.VUE_APP_API_URL + 'parametro/formularioAlternativas/' + id, { headers: authHeader() })
  }

  saveAlternativa ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'parametro/formularioAlternativa', data, { headers: authHeader() })
  }

  updateAlternativa ( id, data ) {
    return axios.put(process.env.VUE_APP_API_URL + 'parametro/formularioAlternativa/' + id, data, { headers: authHeader() })
  }

  deleteAlternativa ( id ) {
    return axios.delete(process.env.VUE_APP_API_URL + 'parametro/formularioAlternativa/' + id, { headers: authHeader() })
  }

  savePergunta ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'parametro/formularioPergunta', data, { headers: authHeader() })
  }

  deletePergunta ( id ) {
    return axios.delete(process.env.VUE_APP_API_URL + 'parametro/formularioPergunta/' + id, { headers: authHeader() })
  }

  updatePergunta ( id, data ) {
    return axios.put(process.env.VUE_APP_API_URL + 'parametro/formularioPergunta/' + id, data, { headers: authHeader() })
  }

  saveFormulario ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'parametro/formulario', data, { headers: authHeader() })
  }

  updateFormulario ( id, data ) {
    return axios.put(process.env.VUE_APP_API_URL + 'parametro/formulario/' + id, data, { headers: authHeader() })
  }
  
  getCondicao ( id ) {
    return axios.get(process.env.VUE_APP_API_URL + 'parametro/formularioPerguntaCondicao/' + id, { headers: authHeader() })
  }

  saveCondicao ( data ) {
    console.log("saveCondicao service:", data)
    const id = data.idFormularioPerguntaCondicao
    console.log("saveCondicao service - id:", id)
    if ( id ) {
      console.log("condicao PUT:", id)
      return axios.put(process.env.VUE_APP_API_URL + 'parametro/formularioPerguntaCondicao/' + id, data, { headers: authHeader() })
    } else {
      return axios.post(process.env.VUE_APP_API_URL + 'parametro/formularioPerguntaCondicao', data, { headers: authHeader() })
    }
    
  }
}

export default new FormulariosService();
