<template>
  <section>
    <template v-if="!empty && list">
      <div class="print-page" v-for="turma in Object.keys(list)" :key="turma">
        <h3 v-if="title">{{ title }}</h3>
        <header>
          <div>
            <div>
              <label>Unidade</label>
              <p>{{ list[turma][0].nomeUnidade }}</p>
            </div>
            <div>
              <label>Periodo Letivo</label>
              <p>{{ list[turma][0].nomePeridoletivo }}</p>
            </div>
            <div>
              <label>Curso</label>
              <p>{{ list[turma][0].nomeTipoCurso }}</p>
            </div>
            <div>
              <label>Série</label>
              <p>{{ list[turma][0].nomeSerie }}</p>
            </div>
            <div>
              <label>Turma</label>
              <p>{{ list[turma][0].nomeTurma }}</p>
            </div>
          </div>
          <div>
            <logo color="#817F7B" size="40px"/>
          </div>
        </header>
        <table class="print-table">
          <thead>
            <tr>
              <th class="idWrap">
                RM
              </th>
              <th>
                Aluno
              </th>
              <th v-if="!filter.active">
                Status
              </th> 
              <th>
                Data Matrícula
              </th>
              <th v-if="filter.active">
                Assinatura
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in list[turma]" :key="item">
              <td>
                {{ item.rm }}
              </td>
              <td class="name">
                {{ item.nomeAluno.toLowerCase() }}
              </td>              
              <td v-if="!filter.active">
                {{ item.nomeSituacao }}
              </td>
              <td>
                {{ formatDate(item.dataSituacao) }}
              </td>
              <td v-if="filter.active" class="sign"></td>
            </tr>
          </tbody>
        </table>
        <footer>{{ now() }}</footer>
      </div>
    </template>
    <div v-else>
      <h4>Não existem itens para imprimir</h4>
    </div>
  </section>
</template>

<script>
import Logo from '@/components/template/logo.vue'
import moment from 'moment'
import { formatDate } from '@/services/utils.service.js'

export default {
  components: {
    Logo
  },
  data () {
    return {
      list: null,
      filter: null,
      title: null,
      empty: false
    } 
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      const list = localStorage.getItem('matList')

      if ( list ) {
        const parsed = JSON.parse(list)
        console.log("parsed:", parsed)
        this.list = parsed.list
        this.filter = parsed.filter
        this.title = parsed.title

        console.log("list:", this.list)
        console.log("filter:", this.filter)
        
        this.$nextTick(() => {
          setTimeout(() => {
            window.print()
          },2000)
        })
        
      } else {
        this.empty = true
      }
    },
    now () {
      return moment().format('DD/MM/YYYY - HH:mm:ss')
    },
    formatDate ( date ) {
      return formatDate(date)
    }
  }
}
</script>

<style lang="scss" scoped>
  .print-page {
    page-break-after: always;
    width: 100%;
    padding: $hmg;

    h3 {
      margin-bottom: $hmg_mid;
    }

    header {
      display: flex;
      width: 100%;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      margin-bottom: $hmg_mid;

      >div {
        display: flex;
        align-items: center;
        align-content: center;
        gap: $mg;
        justify-content: flex-start;
        line-height: 1;
        label {
          font-size: 12px; font-weight: bold;
          margin-bottom: $hmg_mini;
        }
        p {
          font-size: 16px;
        }
      }
      
    }

    table {
      border: 0;
      border-collapse: collapse;
      width: 100%;
      font-size: 15px;

      tr {
        th {
          padding: $hmg_mini;
          font-weight: bold;
          border-bottom: $border-component-double;
          font-size: 13px;

          &:last-child {
            text-align: right
          }
        }
        td {
          padding: $hmg_mini;
          border-bottom: $border-component;
          // border: $border-component;

          &:last-child {
            text-align: right
          }
        }

        .idWrap {
          width: 150px
        }
      }
    }
  }

  footer {
    font-size: 13px;
    width: 100%;
    text-align: right;
    margin-top: $hmg;

    @media print {
      position: fixed;
      bottom: 1em;
      right: 1em;
      margin: 0;
    }
    
  }

  .sign {
    width: 30%
  }

  .name {
    text-transform: capitalize
  }
</style>