<template>
  <div class="sidebar">
    <template v-if="menuReady">
      <div>
        <div :class="['tooltipster', { active: tooltip.label }]" :style="{ top: tooltip.pos + 'px'}">
          <span></span>
          <p>{{ tooltip.label }}</p>
        </div>
        <nav v-if="menuReady">
          <a 
            v-for="nav in menu"
            href="#"
            :key="nav"
            @click.prevent="toggleMenu(nav)"
            @mouseenter="addTooltip($event, nav.name)"
            @mouseleave="clearTooltip()"
            :class="{ active: nav.id == active }"
          >
            <fa :icon="parseIcon(nav.icon)"></fa>
          </a>
        </nav>
      </div>
      <!-- <a href="#" @click.prevent="logOut()">sair</a> -->
    </template>
    <div class="sidebar-loader" v-else>
      <span></span>
    </div>
    <div class="userMenuWrap">
      <user-menu></user-menu>
    </div>
  </div>
</template>

<script>
import UserService from '@/services/user.service.js'
import { parseIcon } from '@/services/utils.service.js'
import UserMenu from '@/components/utilities/UserMenu.vue'

export default {
  components: {
    UserMenu
  },
  props: {
    active: {
      type: [String,Number],
      default: null
    },
    env: {
      type: String
    }
  },
  data () {
    return {
      menu: null,
      menuReady: false,
      tooltipDelay: null,
      tooltipLabelDelay: null,
      tooltip: {
        pos: -120,
        label: null
      },
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null
    }
  },
  watch: {
    active ( value ) {
      console.log("active:", value)
    }
  },
  mounted () {
    this.getMenu()
  },
  methods: {
    getMenu() {
      UserService.getMenuPortal().then(
        response => {
          this.assembleMenu(response.data)
        }, 
        error => {
          console.log('error', error)
        }
      )
    },
    logOut() {
      this.$store.dispatch('auth/logout')
      this.$router.go()
    },
    parseIcon ( icon ) {
      return parseIcon(icon)
    },
    addTooltip ( e, name ) {
      clearTimeout(this.tooltipDelay)
      this.tooltip.pos = e.target.offsetTop

      this.tooltipLabelDelay = setTimeout(() => {
        this.tooltip.label = name
      }, 600)
    },
    clearTooltip () {
      this.tooltip.label = ""
      clearTimeout(this.tooltipLabelDelay)
      this.tooltipDelay = setTimeout(() => {
        this.tooltip.pos = -120
      }, 200)
    },
    assembleMenu ( data ) {
      data.map( k => {
        k.menu = k.permissions.filter( a => a.tipo == 'menu' )
        console.log("k.menu:", k.menu)

        k.menu.sort(function(a,b) {
          var x = a.name.toLowerCase();
          var y = b.name.toLowerCase();

          return x.localeCompare(y, 'pt', { sensitivity: 'base' })
        })
      })

      data.map( k => {
        k.menu.map( km => {
          km.menu = k.permissions.filter( a => a.tipo == 'submenu' && a.idMenu == km.id)
          // console.log("km.menu:", km.menu)
          km.menu.sort(function(a,b) {
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();

            return x.localeCompare(y, 'pt', { sensitivity: 'base' })
          })
        })
      })

      this.menu = data
      this.menu.unshift({icon: 'fal house', loadChildren: '/', name: 'Início', id: 0})
      this.menu.push({icon: 'fal person-to-door', loadChildren: 'leave', name: 'Sair', id: 999})

      setTimeout(() => {
        this.menuReady = true
      },500)

      console.log("menu:", this.menu)
    },
    toggleMenu ( nav ) {
      if ( nav.loadChildren ) {
        if ( nav.loadChildren == 'leave' ) {
          this.logOut()
        } else {
          this.$router.push(nav.loadChildren)
          this.saveRotaFavorita(nav)
          this.$emit("onSuperMenu", null)
        }
      } else {
        this.$emit("onSuperMenu", nav)
      }

      this.$emit("setActive", nav.id)
    },
    saveRotaFavorita ( route ) {
      console.log("route:", route)
      const idPessoa = this.currentUser.idPessoa
      const data = {
        idPessoa: idPessoa,
        idRole: route.id,
        idPermission: null
      }
      console.log("saveRotaFavorita:", data)

      UserService.saveRotaFavorita(data).then(
        response => {
          console.log("saveRotaFavorita:", response.data)
        }
      )
    },
  }
}
</script>

<style lang="scss" scoped>
  .sidebar {
    width: 60px; height: 100vh;
    // position: fixed; top: 0; left: 0;
    // z-index: 501;
    // border-right: $border-component;
    background-color: $color-secondary;
    padding: 0;

    >div {
      position: relative
    }

    nav {
      display: flex; align-items: center; flex-direction: column;
      padding-top: $mg_mini;
      position: relative; z-index: 101;

      a {
        display: flex; justify-content: center; align-items: center; flex-direction: column;
        width: 40px; height: 40px;
        margin-top: $mg_mini;
        font-size: 16px; line-height: 1;

        @media screen and ( max-height: 800px ) {
          margin-top: 0
        }

        &:hover {
          color: $color-primary
        }

        &.active {
          color: $color-primary
        }
      }
    }

    .tooltipster {
      display: flex; flex-direction: column;
      align-items: center; justify-content: center;
      position: absolute; z-index: 502;
      top: -120px; right: 4px;
      width: auto; min-width: 3px; height: 40px;
      background-color: $color-bg;
      @extend %transition_4e;
      transform: translateX(100%);
      border: 0;

      p {
        margin: 0; padding: 0;
        white-space: nowrap;
        @extend %transition_4e;
      }

      &.active {
        background-color: $color-secondary;
        // box-shadow: 1px 1px 8px rgba(0,0,0,0.1)
        border: $border-component;
        border-radius: $border-radius-small;
          
        p {
          padding: 0 $mg_mini;
        }
      }
    }

    .sidebar-loader {
      position: absolute; right: 0;
      top: 0; height: 100vh;
      width: 100px;
      
      > span {
        display: block; height: 100px; width: 100%;
        background-color: $color-bg;
        animation: spin 1s linear infinite;
        position: absolute;
        transform-origin: center;
      }

      @keyframes spin {
        0% {
          transform: scale(1,0) translateY(-100%);
          top: 0;
        }
        50% {
          transform: scale(1,3) translateY(-50%);
          top: 50%
        }
        100% {
          transform: scale(1,0) translateY(0);
          top: 100%
        }
      }
    }
  }

  .userMenuWrap {
    position: absolute !important;
    bottom: $mg;
    left: $mg_mini;
    z-index: 102;

    @media screen and ( max-height: 800px ) {
      bottom: $mg_mini;
    }
  }
</style>