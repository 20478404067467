import axios from 'axios'
import authHeader from './auth-header'
// import moment from 'moment'

class CaptacaoService {
  getRDForms () {
    return axios.get(process.env.VUE_APP_API_URL + 'get/campanhas/rd', { headers: authHeader() })
  }

  saveLead ( lead ) {
    return axios.post(process.env.VUE_APP_API_URL + 'prospects', lead, { headers: authHeader() })
  }

  getGatilhosPipedrive () {
    return axios.get(process.env.VUE_APP_API_URL + 'pipedrive/gatilhos/list', { headers: authHeader() })
  }

  saveGatilhoPipedrive ( gatilho ) {
    return axios.post(process.env.VUE_APP_API_URL + 'pipedrive/gatilhos/create', gatilho, { headers: authHeader() })
  }

  editGatilhoPipedrive ( data ) {
    const gatilho = data.idGatilhopipedrive
    return axios.put(process.env.VUE_APP_API_URL + 'pipedrive/gatilhos/update/' + gatilho, data, { headers: authHeader() })
  }

  deleteGatilhoPipedrive ( data ) {
    const gatilho = data.idGatilhopipedrive
    return axios.delete(process.env.VUE_APP_API_URL + 'pipedrive/gatilhos/delete/' + gatilho, { headers: authHeader() })
  }

  getFormEtapas () {
    return axios.get(process.env.VUE_APP_API_URL + 'formularioprospects', { headers: authHeader() })
  }

  getFunilMidias () {
    return axios.get(process.env.VUE_APP_API_URL + 'midias', { headers: authHeader() })
  }

  filtrarLeads ( options ) {
    const status = options.status ?? 0
    const idUnidade = options.idUnidade ?? 0
    const idMidia = options.idMidia ?? 0
    const idPessoa = options.idPessoa ?? 0
    const integrado = options.integrado ?? 0
    const idFormLeadEtapa = options.idFormLeadEtapa ?? 0
    const nome = options.nome ?? 0
    const email = options.email ?? 0
    const pessoaPipe = options.pessoaPipe ?? 0
    const negocioPipe = options.negocioPipe ?? 0

    return axios.get(process.env.VUE_APP_API_URL + `prospects/${status}/${idUnidade}/${idMidia}/${idPessoa}/${integrado}/${idFormLeadEtapa}/${nome}/${email}/${pessoaPipe}/${negocioPipe}`, { headers: authHeader() })
  }

  getInteresses ( options ) {
    const idProspect = options.idProspect ?? 0
    const idCurso = options.idCurso ?? 0
    const idSerie = options.idSerie ?? 0
    const idTurno = options.idTurno ?? 0
    const idProductDeal = options.idProductDeal ?? 0

    return axios.get(process.env.VUE_APP_API_URL + `interesses/${idProspect}/${idCurso}/${idSerie}/${idTurno}/${idProductDeal}`, { headers: authHeader() })
  }

  getProspect ( idProspect ) {
    return axios.get(process.env.VUE_APP_API_URL + `prospects/${idProspect}`, { headers: authHeader() })
  }

  saveProspect ( prospect ) {
    if ( prospect.idProspect ) {
      return axios.put(process.env.VUE_APP_API_URL + `prospects/${ prospect.idProspect }`, prospect, { headers: authHeader() })
    } else {
      return axios.post(process.env.VUE_APP_API_URL + `prospects`, prospect, { headers: authHeader() })
    }
  }

  saveInteresse ( interesse ) {
    if ( interesse.idInteresse ) {
      return axios.put(process.env.VUE_APP_API_URL + `interesses/${ interesse.idInteresse }`, interesse, { headers: authHeader() })
    } else {
      return axios.post(process.env.VUE_APP_API_URL + `interesses`, interesse, { headers: authHeader() })
    }
  }

  deleteInteresse ( interesse ) {
    return axios.delete(process.env.VUE_APP_API_URL + `interesses/${ interesse }`, { headers: authHeader() })
  }

  deleteProspect ( prospect ) {
    return axios.delete(process.env.VUE_APP_API_URL + `prospects/${ prospect }`, { headers: authHeader() })
  }

  ganharLead ( prospects ) {
    return axios.put(process.env.VUE_APP_API_URL + `prospects/status/${ prospects }`, { status: 'won' }, { headers: authHeader() })
  }
}
export default new CaptacaoService()