<template>
  <div class="headerCart">
    <a href="#" @click.prevent="$refs.cartController.toggleCart('open')" class="cartToggle">
      <fa :icon="['fas', 'bag-shopping']" class="icon"></fa>
      <p>{{ total }}</p>
    </a>
    <cart-controller 
      ref="cartController" 
      :cart="cart" 
      @clear="clear" 
      @remove="remove" 
      @total="total = $event"
    ></cart-controller>
  </div>
</template>

<script>
import cartController from '@/components/loja/template/Cart'
import LojaService from '@/services/loja.service'

export default {
  name: 'Cart',
  components: {
    cartController
  },
  data () {
    return {
      cart: null,
      total: 0
    }
  },
  mounted () {
    this.fetchCartData()

    window.cart.on('update', ( add ) => {
      this.fetchCartData()

      if ( add && this.$refs.cartController ) {
        setTimeout(() => {
          this.$refs.cartController.toggleToaster(true)
        }, 1000)
      }
      
    })
  },
  methods: {
    closeCart() {
      this.$refs.cartController.toggleCart('close')
    },
    fetchCartData () {
      let cart = LojaService.getCart()
      
      if ( cart ) {
        // cart = JSON.parse(cart)
        this.cart = cart
        console.log("cart:", cart)
      }
    },
    clear () {
      let cart = LojaService.getCart()

      if ( cart ) {
        LojaService.clearCart()
        this.cart = null
        this.total = 0
      }
    },
    remove ( produtos ) {
      localStorage.setItem('carrinho', JSON.stringify(produtos))
      this.cart = produtos
    }
  }
}
</script>

<style lang="scss">
  .headerCart {
    .cartToggle {
      position: relative;
      display: flex; align-content: center; align-items: center; justify-content: flex-end;
      width: 65px;
      overflow: visible;
      background-color: $color-secondary;
      border-radius: 20px;
      padding: 2px 2px 2px 30px;
      @extend %transition_3e;

      .icon {
        font-size: 30px;
        color: $color-primary;
        position: absolute; 
        top: 50%; transform: translateY(-50%);
        left: 6px;
        @extend %transition_3e;
      }

      p {
        margin: 0; padding: 0 10px 0 0;
        text-align: right; font-weight: bold;
      }

      &:hover {

        .icon { 
          transform: translateY(-50%) scale(1.1);
        }
      }
    }

    @media screen and ( max-width: 1220px ) {
      margin-left: $hmg_small;
    }
  }
</style>