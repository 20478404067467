<template>
  <div class="autocomplete-control">
    <input-spinner v-if="loading" class="ac-spinner"/>
    <input-control v-model="query" :placeholder="placeholder" @keyup="search" :disabled="loading"></input-control>
    <div class="autocomplete-control-body" v-if="release.length > 0 && listVisible">
      <perfect-scrollbar>
        <ul class="result">
          <li v-for="item in release" :key="item">
            <span class="thumb" v-if="item[result.thumb]" :style="{backgroundImage: 'url(' + item[result.thumb] + ')'}"></span>
            <checkbox-control :justified="true" :value="item[result.id]" @change="select">{{ item[result.value] }}</checkbox-control>
          </li>
        </ul>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
// import LojaService from '../../services/loja.service'
import InputSpinner from '../../components/utilities/InputSpinner'
export default {
  name: "Autocomplete",
  components: {
    InputSpinner
  },
  props: {
    source: {
      type: Object,
      default: () => {
        return {
          type: 'route', //route or service
          target: null, //route to endpoint or service name
          method: null  //for service only
        }
      }
    },
    result: {
      type: Object,
      default: () => {
        return {
          id: '', //route or service
          value: '', //route to endpoint or service name
          thumb: '',
        }
      }
    },
    minLenght: {
      type: Number,
      default: 3
    },
    stall: {
      type: Number,
      default: 500 //time in milliseconds
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Encontre'
    }
  },
  data () {
    return {
      query: null,
      wait: null,
      service: null,
      release: [],
      disabled: true,
      loading: false,
      listVisible: false
    }
  },
  mounted () {
    if ( this.source.target ) {
      // console.log("this.dataSource.target :", this.dataSource.target )

      import('../../services/' + this.source.target ).then( service => {
        // console.log("LojaService:", service.default.testLojaService)
        this.service = service.default
        this.disabled = false
      })
    }
  },
  methods: {
    search () {
      if ( this.query.length >= this.minLenght && !this.disabled) {
        if ( this.wait ) clearInterval(this.wait)

        this.wait = setTimeout ( () => {
          this.loading = true
          this.service[this.source.method]( this.query ).then( response => {
            this.release = response.data
            this.loading = false
            // console.log("response:", response)
          })
        }, this.stall )
      }
    },
    select ( data ) {
      console.log("select:", data)
      if ( data.target.value ) {
        this.listVisible = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .autocomplete-control {
    position: relative;
    overflow: visible;
    
    .ac-spinner {
      position: absolute; top: 0;
      right: $hmg_small; z-index: 100;
    }

    > .autocomplete-control-body {
      max-height: 440px;
      margin: 0;
      border-radius: $border-radius;
      background-color: #FFF;
      margin-top: $hmg_small;

      .ps {
        height: auto; max-height: 440px;
        padding: $hmg_small;
      }
    }

    .result {
      padding: 0; margin: 0;

      .thumb {
        width: 30px; height: 30px;
        background-size: contain; background-repeat: no-repeat;
        background-position: center;
        margin-right: $hmg_small;
      }

      li {
        list-style: none;
        width: 100%;
        padding: $hmg_mini $hmg_small $hmg_mini 0;
        display: flex; align-content: center; align-items: center;
        justify-content: space-between;
        
        margin-top: $hmg_mini;
      }
    }
  }

</style>