<template>
  <section class="toolbar">
    <h5>Links e Alertas</h5>
    <div class="links">
      <ul v-if="respFin">
        <li v-for="item in links" :key="item">
          <a href="#" @click.prevent="trackRoute(item)">
            <div>
              <fa :icon="item.icon" class="icon"/>
              <p>{{ item.label }}</p>
            </div>
          </a>
        </li>
        <li v-for="item in alertas" :key="item">
          <a href="#" @click.prevent="trackRoute(item)">
            <div>
              <fa :icon="item.icon" class="icon"/>
              <p>{{ item.label }}</p>
            </div>
            <span>
              {{ item.value }}
            </span>
            <span class="negocie" v-if="item.slug == 'pagamentos' && item.value > 0">
              Negocie Já!
            </span>
          </a>
        </li>
      </ul>
    </div>
    <div v-if="aluno">
      <historico-atendimento :id="aluno.Aluno.idAluno" :type="histType"/>
    </div>
    <confirm 
      :backdrop="false"
      class="confirm"
      ref="confirm"
    ></confirm>
  </section>
</template>

<script>
import MatriculaService from '@/services/matricula.service.js'
import AuthService from '@/services/auth.service.js'
import FinanceiroService from '@/services/financeiro.service.js'
import HistoricoAtendimento from '@/components/atividades/utilities/atendimentos/Historico.vue'

export default {
  components: {
    HistoricoAtendimento
  },
  props: {
    aluno: {
      type: Object
    }
  },
  data () {
    return {
      histType: {
        label: "Atendimentos",
        slug: "atendimentos",
        single: "Atendimento"
      },
      links: [
        { 
          label: 'Redefinir Senha do Email', 
          icon: ['fal','lock-keyhole-open'], 
          slug: 'redefinir-senha-aluno',
          type: 'action'
        },
        { 
          label: 'Frequências',
          icon: ['fal','calendar-circle-user'], 
          slug: 'frequencias',
          type: 'painel'
        },
        { 
          label: 'Avaliações', 
          icon: ['fal','file-check'], 
          slug: 'avaliacoes',
          type: 'painel'
        },
        { 
          label: 'Notificações Escolares', 
          icon: ['fal','file-circle-exclamation'], 
          slug: 'notificacoesescolares',
          type: 'painel'
        },
        { 
          label: 'Orientações de Estudo', 
          icon: ['fal','book-open-reader'], 
          slug: 'orientacoesestudo',
          type: 'painel' 
        },
        { 
          label: 'Shop Decisão', 
          icon: ['fal','store'], 
          slug: 'shop',
          type: 'link'
        },
        { 
          label: 'Boletos', 
          icon: ['fal','barcode-read'], 
          slug: 'boletos',
          type: 'modal' 
        }
      ],
      alertas: [
        { 
          label: 'Negociações Ativas', 
          icon: ['fal','money-check-dollar-pen'], 
          slug: 'negociacoes',
          type: 'navigation', 
          value: 0
        },
        { 
          label: 'Bloqueios', 
          icon: ['fal','user-lock'], 
          slug: 'bloqueios',
          type: 'navigation', 
          value: 0 
        },
        { 
          label: 'Pagamentos Atrasados', 
          icon: ['fal','envelope-open-dollar'], 
          slug: 'pagamentos',
          type: 'navigation',
          value: 0
        }
      ],
      respFin: null,
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null
    }
  },
  mounted () {
    console.log("this.aluno.......", this.aluno)
    if ( this.aluno.Aluno ) this.getAlertas()
    if ( this.aluno.Aluno.responsavelFinanceiro ) this.getRespFin()
  },
  methods: {
    getAlertas () {
      MatriculaService.getAlertasCentral( this.aluno.Aluno.idAluno ).then(
        response => {
          console.log("getAlertasCentral:", response.data)
          this.alertas[0].value = response.data.negociacoes
          this.alertas[1].value = response.data.bloqueios
          this.alertas[2].value = response.data.debitos_matriculas
        },
        () => {

        }
      )
    },
    getDebitos (respFin) {
      FinanceiroService.getNegociacoesDebitos( respFin ).then(
        response => {
          console.log("getNegociacoesDebitos:", response.data)
          const drop = response.data[0]
          const totalLoja = drop.loja ? drop.loja?.matriculas.length : 0
          const totalMens = drop.perseus ? drop.perseus?.matriculas.length : 0
          const total = ( totalLoja ? totalLoja : 0 ) + ( totalMens ? totalMens : 0 )
          this.alertas[2].value = total
        },
        () => {

        }
      )
    },
    getRespFin () {
      MatriculaService.getPessoaByIdPessoaOuCpf(this.aluno.Aluno.responsavelFinanceiro.idPessoa,0).then(
        response => {
          this.respFin = response.data.dados
          this.getDebitos(response.data.dados.Basicos.idPessoa)
        },
        () => {

        }
      )
    },
    trackRoute ( item ) {
      if ( item.type == 'painel' ) {
        this.$emit("onAddPanel", item.slug)
      }

      if ( item.type == 'action' ) {
        this.trackAction(item.slug)
      }

      if ( item.type == 'link' ) {
        this.trackLink(item.slug)
      }

      if ( item.type == 'modal' ) {
        const options = {
          matricula: {
            idMatricula: this.aluno.Aluno.idMatricula
          },
          target: item.slug,
          size: 'full'
        }
        this.$emit("onModal", options)
      }

      if ( item.type == 'navigation' ) {
        if ( item.slug == 'pagamentos' && item.value > 0 ) {
          const params = '?idPessoaRespFin=' + this.respFin.Basicos.idPessoa + '&idMatricula=0&idAluno=' + this.aluno.Aluno.idAluno
          const path = 'renegociacoes/listaDebitos/' + params

          console.log("negocie path:", path)
          
          this.$router.push({ 
            name: 'LinkExterno', 
            params: { 
              id: 41,
              isMetabase: 0,
              isUrl: 0,
              token: process.env.VUE_APP_LARAVEL_API_LOGIN_TOKEN,
              email: this.currentUser.email
            },
            query: {
              numero_dashboard: 0,
              path: path,
            }
          })
        } else if ( item.slug != 'pagamentos' ) {
          this.$emit('onNavigation', item.slug)
        }
      }

    },
    trackAction ( action ) {
      if ( action  == "redefinir-senha-aluno" ) {
        this.confirmResetGooglePassword()
      }
    },
    trackLink ( link ) {
      if ( link == 'shop' ) {
        console.log("this.respFin",  this.respFin)
        const url = "https://lojadecisao.com.br/loja?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjY4ODAsImlzcyI6Imh0dHA6Ly9sb2NhbGhvc3Q6ODAwMC9hcGkvYXV0aC9sb2dpbiIsImlhdCI6MTU2MzQ3NzY4NywiZXhwIjoxNTYzNDgxMjg3LCJuYmYiOjE1NjM0Nzc2ODcsImp0aSI6ImRtRjZsYUMwWUswVTdzSVIifQ.ynkU2CQ2Kr3yt-cxA3o21y2wkg2HRj-zckdBYDy2MNE&email=" + this.respFin.Basicos.email
        window.open(url)
      } 
    },
    confirmResetGooglePassword () {
      this.$refs.confirm.run({
        message: "Tem certeza que deseja redefinir a senha deste aluno?",
        confirmButton: "Sim",
        denyButton: "Não",
        callback: this.resetGooglePassword,
      })
    },
    resetGooglePassword ( state ) {
      // console.log("aluno", this.aluno)
      const email = this.aluno.Aluno.emailAcademico
      if ( email ) {
        if ( state ) {
          window.spinner.emit('open')
          AuthService.resetGooglePassword( email ).then(
            response => {
              if ( response.data ) {
                // this.newPass = response.data.novaSenha
                this.$refs.confirm.run({
                  message: "Senha redefinida com sucesso!",
                  obs: "A nova senha foi encaminhada para o e-mail do responsável financeiro.<br><br><b>A nova senha é:</b> <br/><b style='font-size: 28px;' class='font-code'>" + response.data.novaSenha + "</b>",
                  confirmButton: "Copiar senha",
                  denyButton: "Fechar",
                  data: response.data.novaSenha,
                  size: 'mid',
                  callback: this.copy,
                  preventCloseOnTrue: true
                })
              } else {
                //
              }
              console.log("response:", response)
              window.spinner.emit('close')
            },
            error => {
              console.log("error:", error)
              window.spinner.emit('close')
            }
          )
        }
      } else {
        this.$refs.confirm.run({
          message: "Ops. Este aluno não tem email acadêmico cadastrado.",
          confirmButton: "Ok"
        })
      }
    },
    copy ( state, newPass ) {
      if ( state ) {
        navigator.clipboard.writeText(newPass)
        
        setTimeout(() => {
          window.toaster.emit('open', {style: "success", message: "Senha copiada para a área de transferência.", floater: true})
        },500)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .toolbar {
    padding: $hmg_mid;
    position: sticky; top: 60px;

    .links {
      margin-bottom: $mg;

      ul {
        padding: 0; margin: 0;

        li {
          list-style: none;
          margin: 0 0 $mg_mini 0; padding: 0;

          a {
            display: flex; align-items: center;
            justify-content: space-between;
            border-radius: $border-radius;
            border: $border-component;
            background-color: $color-secondary;
            height: 44px; padding: 0 $mg_mini 0 $hmg_small;
            @extend %transition_3o;
            position: relative;

            >div {
              display: flex; align-items: center;
              gap: $mg_mini;
              line-height: 1;
              position: relative;

              p {
                margin: 0; padding: 0;
              }
            }

            .icon {
              font-size: 20px
            }
            span:not(.negocie) {
              width: 25px; height: 25px;
              border-radius: $border-radius-small;
              border: $border-component;
              line-height: 25px; text-align: center;
              font-weight: bold;
              background-color: $color-bg
            }

            .negocie {
              display: inline-block;
              width: auto; white-space: nowrap;
              border-radius: 2em;
              padding: $hmg_mini $mg_mini;
              background-color: $color-primary;
              color: $color-secondary;
              text-align: center;
              position: absolute; right: 0; bottom: 0;
              transform: translateY(80%);

              &:before {
                content: '';
                display: block; width: 10px; height: 10px;
                border-radius: 2px; z-index: 0;
                position: absolute; top: 3px; left: 50%; right: 0;
                margin: auto;
                transform: rotate(45deg) translate(-50%, -50%);
                background-color: $color-primary;
              }
            }

            &:hover {
              box-shadow: 1px 3px 14px rgba(0,0,0,0.1)
            }
          }
        }
      }
    }
  }
  
</style>