import axios from 'axios'
import authHeader from './auth-header'

class AdmService {

  getFuncoes () {
    return axios.get(process.env.VUE_APP_API_URL + 'funcoes', { headers: authHeader() })
  }
  
  getListFuncoes () {
    return axios.get(process.env.VUE_APP_API_URL + 'auth/getlistafuncoes', { headers: authHeader() })
  }

  getDepartamentos () {
    return axios.get(process.env.VUE_APP_API_URL + 'auth/getlistadepartamentos', { headers: authHeader() })
  }

  getSetores () {
    return axios.get(process.env.VUE_APP_API_URL + 'auth/getlistasetores', { headers: authHeader() })
  }

  getSituacoes () {
    return axios.get(process.env.VUE_APP_API_URL + 'auth/getlistasituacao', { headers: authHeader() })
  }

  getColaboradores ( options ) {
    const idUnidade = options.idUnidade ?? 0
    const idDepartamento = options.idDepartamento ?? 0
    const idFuncao = options.idFuncao ?? 0
    const idSetor = options.idSetor ?? 0
    const idSituacao = options.idSituacao ?? 0
    const nome = options.nome ?? 0

    return axios.get(process.env.VUE_APP_API_URL + `colaborador/getallcolaboradores/${idUnidade}/${idDepartamento}/${idFuncao}/${idSetor}/${nome}/${idSituacao}`, { headers: authHeader() })
  }
  
}

export default new AdmService()