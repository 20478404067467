<template>
  <main>
    <div class="frame micro">
      <fa :icon="['fal','face-meh']" class="icon"></fa>
      <h3>Ops. Algo deu errado</h3>
      <p>Não conseguimos efetuar o login com o email:</p>
      <span>{{ email }}</span>
      <p>Se o erro persistir, procure nosso suporte.</p>
    </div>
  </main>
</template>

<script>
  export default {
  name: "Erro de Login",
  props: {
    email: {
      type: String
    }
  },
  data () {
    return {

    }
  },
  mounted () {
    console.log("email na pagina de erro", this.email)
  }
}
</script>

<style scoped lang="scss">
  main {
    display: block;
    width: 100%; height: 100vh;
    position: absolute;
    top: 0; left: 0;

    >div {
      position: absolute; top: 50%; left: 0; right: 0;
      margin: auto;
      transform: translateY(-50%);
      text-align: center;

      .icon {
        font-size: 74px;
        margin-bottom: $hmg
      }

      span {
        display: inline-block;
        padding: $hmg_mini $hmg_small;
        background-color: $color-bg;
        border-radius: 2em;
        margin-bottom: $hmg_small;
        font-weight: bold; font-size: 1.2rem;
        text-align: center;
      }
    }
  }
</style>