<template>
  <controller>
    <page-header title="Cancelamento e Estorno">
      <template v-slot:side>
        <transition name="slideLeftToRightSmall" v-if= "section != 'atendimento' && section != 'financeiro' && section != 'devolucao' ">
          <a href="#" class="btn btn-primary" @click.prevent="goBack()"><fa :icon="['far', 'arrow-left-long']" />&nbsp; Voltar</a>
        </transition>
      </template>
    </page-header>
    <section class="main">
      <template v-if="section == 'atendimento' || section == 'financeiro' || section == 'devolucao' || section == 'filtro' ">
        <filter-controller @onFilter="runFilter"/>
        <painel :section="section" :filter="filter"/>
      </template>
      <detail-page :detail="section" v-else></detail-page>
    </section>
  </controller>
</template>

<script>
import FilterController from '@/components/marketplace/cancelamento/utilities/Filter.vue'
import Painel from '@/components/marketplace/cancelamento/template/Painel.vue'
import DetailPage from '@/components/marketplace/cancelamento/template/Details.vue'

export default {
  props: {
    section: {
      type: [String, Number]
    }
  },
  components: {
    FilterController, Painel, DetailPage
  },
  data () {
    return {
      filter: null,
      ready: false, 
    }
  },
  mounted () {
    if ( !this.section || ( this.section == 'filtro' && !this.filter )) {
      this.$router.push({name: 'Cancelamento e Estorno', params: { section: 'atendimento' }})

      this.$nextTick(() => {
        this.ready = true
      })
      
    }
  },
  methods: {
    goBack () {
      this.$router.push({name: 'Cancelamento e Estorno', params: { section: 'atendimento' }})
    },
    runFilter ( data ) {
      this.$router.push({name: 'Cancelamento e Estorno', params: { section: 'filtro' }})
      console.log("runFilter - data:", data)
      this.filter = Object.assign({}, data)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>