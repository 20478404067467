<template>
  <controller :goToTopButton="true">
    <div class="page-title">
      <h4 class="text-capitalize">Esteira de Projetos de Tecnologia</h4>
      <template>
        <!-- <a 
          href="#" 
          :class="['btn', { 'btn-primary': !creatingDesconto , 'btn-secondary': creatingDesconto }]" @click.prevent="toggleCreateDesconto(true)">Novo Desconto</a>
        <a href="#" :class="['btn', { 'btn-primary': !creatingVoucher , 'btn-secondary': creatingVoucher }]" @click.prevent="toggleCreateVoucher(true)">Novo Voucher</a> -->
      </template>
    </div>
    <div class="page-inner dash-controller">
      <!-- <div v-for="(item, i, key) in items" :key="item">
        <h5 class="color-text">{{ i + ' - ' + key }}</h5>
        <ol class="dash-list">
          <li>
            <div>{{ item.value }}</div>
          </li>
        </ol>
      </div> -->
      <ul>
        <li>
          <div>asdasd</div>
          <div>asdasdddasd</div>
          <div>g adfadfsdfadf</div>
          <div>sdfaadfadfasd</div>
          <div>sdfaadfadfasd</div>
        </li>
      </ul>

    </div>
  </controller>
</template>

<script>
import TecService from '@/services/tec.services'
import moment from 'moment'

export default {
  
  data () {
    return {
      raw: null,
      items: {},
      areas: [],
      projetos: [],
      blip: [
            {
                "intervalStart": "2022-11-01T03:00:00.000Z",
                "intervalEnd": "2022-11-02T03:00:00.000Z",
                "count": 1450
            },
            {
                "intervalStart": "2022-11-02T03:00:00.000Z",
                "intervalEnd": "2022-11-03T03:00:00.000Z",
                "count": 105
            },
            {
                "intervalStart": "2022-11-03T03:00:00.000Z",
                "intervalEnd": "2022-11-04T03:00:00.000Z",
                "count": 1408
            },
            {
                "intervalStart": "2022-11-04T03:00:00.000Z",
                "intervalEnd": "2022-11-05T03:00:00.000Z",
                "count": 1013
            },
            {
                "intervalStart": "2022-11-05T03:00:00.000Z",
                "intervalEnd": "2022-11-06T03:00:00.000Z",
                "count": 119
            },
            {
                "intervalStart": "2022-11-06T03:00:00.000Z",
                "intervalEnd": "2022-11-07T03:00:00.000Z",
                "count": 42
            },
            {
                "intervalStart": "2022-11-07T03:00:00.000Z",
                "intervalEnd": "2022-11-08T03:00:00.000Z",
                "count": 1442
            },
            {
                "intervalStart": "2022-11-08T03:00:00.000Z",
                "intervalEnd": "2022-11-09T03:00:00.000Z",
                "count": 1030
            },
            {
                "intervalStart": "2022-11-09T03:00:00.000Z",
                "intervalEnd": "2022-11-10T03:00:00.000Z",
                "count": 1143
            },
            {
                "intervalStart": "2022-11-10T03:00:00.000Z",
                "intervalEnd": "2022-11-11T03:00:00.000Z",
                "count": 815
            },
            {
                "intervalStart": "2022-11-11T03:00:00.000Z",
                "intervalEnd": "2022-11-12T03:00:00.000Z",
                "count": 1176
            },
            {
                "intervalStart": "2022-11-12T03:00:00.000Z",
                "intervalEnd": "2022-11-13T03:00:00.000Z",
                "count": 95
            },
            {
                "intervalStart": "2022-11-13T03:00:00.000Z",
                "intervalEnd": "2022-11-14T03:00:00.000Z",
                "count": 25
            },
            {
                "intervalStart": "2022-11-14T03:00:00.000Z",
                "intervalEnd": "2022-11-15T03:00:00.000Z",
                "count": 231
            },
            {
                "intervalStart": "2022-11-15T03:00:00.000Z",
                "intervalEnd": "2022-11-16T03:00:00.000Z",
                "count": 80
            },
            {
                "intervalStart": "2022-11-16T03:00:00.000Z",
                "intervalEnd": "2022-11-17T03:00:00.000Z",
                "count": 1162
            },
            {
                "intervalStart": "2022-11-17T03:00:00.000Z",
                "intervalEnd": "2022-11-18T03:00:00.000Z",
                "count": 917
            },
            {
                "intervalStart": "2022-11-18T03:00:00.000Z",
                "intervalEnd": "2022-11-19T03:00:00.000Z",
                "count": 1196
            },
            {
                "intervalStart": "2022-11-19T03:00:00.000Z",
                "intervalEnd": "2022-11-20T03:00:00.000Z",
                "count": 676
            },
            {
                "intervalStart": "2022-11-20T03:00:00.000Z",
                "intervalEnd": "2022-11-21T03:00:00.000Z",
                "count": 40
            },
            {
                "intervalStart": "2022-11-21T03:00:00.000Z",
                "intervalEnd": "2022-11-22T03:00:00.000Z",
                "count": 1009
            },
            {
                "intervalStart": "2022-11-22T03:00:00.000Z",
                "intervalEnd": "2022-11-23T03:00:00.000Z",
                "count": 1133
            },
            {
                "intervalStart": "2022-11-23T03:00:00.000Z",
                "intervalEnd": "2022-11-24T03:00:00.000Z",
                "count": 951
            },
            {
                "intervalStart": "2022-11-24T03:00:00.000Z",
                "intervalEnd": "2022-11-25T03:00:00.000Z",
                "count": 0
            },
            {
                "intervalStart": "2022-11-25T03:00:00.000Z",
                "intervalEnd": "2022-11-26T03:00:00.000Z",
                "count": 0
            },
            {
                "intervalStart": "2022-11-26T03:00:00.000Z",
                "intervalEnd": "2022-11-27T03:00:00.000Z",
                "count": 0
            },
            {
                "intervalStart": "2022-11-27T03:00:00.000Z",
                "intervalEnd": "2022-11-28T03:00:00.000Z",
                "count": 0
            },
            {
                "intervalStart": "2022-11-28T03:00:00.000Z",
                "intervalEnd": "2022-11-29T03:00:00.000Z",
                "count": 0
            },
            {
                "intervalStart": "2022-11-29T03:00:00.000Z",
                "intervalEnd": "2022-11-30T03:00:00.000Z",
                "count": 0
            },
            {
                "intervalStart": "2022-11-30T03:00:00.000Z",
                "intervalEnd": "2022-12-01T03:00:00.000Z",
                "count": 0
            }
        ],
        totalBlip: 0
    }
  },
  mounted () {
    this.getSheets()


    this.blip.map( k => {
      this.totalBlip += k.count
    })

    console.log('total blip:', this.totalBlip)
  },
  methods: {
    getSheets () {
      TecService.getProjectData().then(
        response => {
          // console.log("response:",response)
          this.raw = response.data.values
          this.parseData()
        },
        error => {
          console.log("error:", error.response)
        }
      )
    },
    parseData () {
      // const data = []
      // console.log("raw:",this.raw)
      
      this.raw.map( (k , i) => {
        if ( i >= 2 ) {
          const area = k[0]
          if ( !this.areas.includes(area) && area != "" ) {
            this.areas.push(area)
          }
          const projeto = k[1]
          if ( !this.projetos.includes(projeto) && projeto != "" ) {
            this.projetos.push(projeto)
            this.items[projeto] = {}
          }
        }
      })
      // console.log("this.items:", this.items)

      this.raw.map( (k,i) => {
        // console.log("k:", k)
        if ( i >= 2 ) {
          const projeto = k[1]
          const pa = k[5]
          // console.log("projeto:", projeto)
          if ( projeto && pa ) {
            this.items[projeto][pa] = []
            // console.log("projeto:", projeto)
            // console.log("item:", item)
            // this.items[projeto].push(item)
          }
        }
      })

      this.raw.map( (k,i) => {
        // console.log("k:", k)
        if ( i >= 2 ) {
          const item = {
            area: {
              label: this.raw[1][0],
              value: k[0]
            },
            projeto: {
              label: this.raw[1][1],
              value: k[1]
            },
            indice: {
              label: this.raw[1][2],
              value: parseFloat(k[2])
            },
            label: {
              label: this.raw[1][3],
              value: k[3]
            },
            pa: {
              label: this.raw[1][5],
              value: k[5]
            },
            etapa: {
              label: this.raw[1][6],
              value: k[6]
            },
            forms: {
              label: this.raw[1][7],
              value: k[7]
            },
            descricao: {
              label: this.raw[1][10],
              value: k[10]
            },
            dataEnvio: {
              label: this.raw[1][12],
              value: moment(k[12],'YYYY-MM-DD')
            },
            dataPrazo: {
              label: this.raw[1][13],
              value: moment(k[13],'YYYY-MM-DD')
            },
            diretoria: {
              label: this.raw[1][14],
              value: k[14]
            },
            po: {
              label: this.raw[1][15],
              value: k[15]
            },
            enviado: {
              label: this.raw[1][17],
              value: k[17] == 'Sim'
            },
            retorno: {
              label: this.raw[1][18],
              value: k[18]
            },
            status: {
              label: this.raw[1][19],
              value: k[19]
            }
          }
          const projeto = k[1]
          const pa = k[5]
          // console.log("projeto:", projeto)
          if ( projeto && pa ) {
            
            // console.log("projeto:", projeto)
            // console.log("item:", item)
            this.items[projeto][pa].push(item)
          }
        }
      })
      const sortAlphaNum = (a, b) => a.localeCompare(b, 'en', { numeric: true })
      this.areas.sort()
      this.projetos.sort(sortAlphaNum)
      console.log("items:", this.items)
      // console.log("areas:", this.areas)
      // console.log("projetos:", this.projetos)
      
      // this.items = data

     
    }
  }
}
</script>

<style lang="scss" scoped>
  .dash-controller {
    position: relative;

    .dash-list {
      display: block; width: auto;
      position: absolute;

      li {
        display: flex; align-items: center;
      }
    }


  }
  
  ul {
    display: block;
    width: 100%;
    padding: 0; margin: 0;

    li {
      width: 100%;
      display: grid;
      background-color: $color-secondary;
      border-radius: $border-radius;
      grid-template-columns: 20%;

      div {
        // display: block;
        &:nth-child(1) {          
          grid-column-start: 1;
          grid-column-end: 1;
        }
      }
    }
  }
</style>