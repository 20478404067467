<template>
  <section ref="serieForm">
    <div v-if="serie" class="frame fixed">
      <div class="row no-padd" >
        <div class="col-md-4 form-group">
          <label>Unidade</label>
          <select-control 
            v-model="serie.idUnidade"
            name="idUnidade"
            :options="options.unidades"
            placeholder="Selecione"
            @change="!serie.idSerie ? getPeriodosLetivos() : null"
            :disabled="loading"
            :valid="valid.idUnidade"
            @keyup="valid.idUnidade = true"
            required
          />
        </div>
        <div class="col-md-4 form-group">
          <label>Turno</label>
          <select-control
            v-model="serie.idTurno"
            :options="options.turnos"
            placeholder="Selecione"
            name="idTurno"
            :disabled="loading"
            :valid="valid.idTurno"
            @keyup="valid.idTurno = true"
            required
          />
        </div>
        <div class="col-md-4 form-group">
          <label>Tipo de Curso</label>
          <select-control
            v-model="serie.idTipoCurso"
            name="tipoCurso"
            :options="options.tipoCurso"
            placeholder="Selecione"
            :disabled="loading"
          />
        </div>
        <div class="col-md-4 form-group">
          <label>Período Letivo</label>
          <select-control
            v-model="serie.idPeriodoLetivo"
            :options="options.periodosLetivos"
            placeholder="Selecione"
            name="idPeriodoLetivo"
            @change="!serie.idSerie ? getGestorSeriesInfo() : null"
            :disabled="loading"
            :valid="valid.idPeriodoLetivo" 
            @keyup="valid.idPeriodoLetivo = true"
            required
          />
        </div>
        <div class="col-md-4 form-group">
          <label>Curso</label>
          <select-control 
            v-model="serie.idCurso" 
            :options="options.cursos" 
            placeholder="Selecione" 
            name="idCurso"
            :disabled="loading" 
            @change="valid.idCurso = true"
            :valid="valid.idCurso"
            required
          />
        </div>
      </div>
      <div class="row no-padd">
        <div class="col-md-4 form-group">
          <label>Descrição</label>
          <input-control
            v-model="serie.descricao" 
            placeholder="" 
            name="descricao"
            type="text"
            maxlength="3"
            :valid="valid.descricao" 
            @keyup="valid.descricao = true"
            @change="setNomeExibicao()"
            required
          />
          <input type="hidden" v-model="serie.descricaoAntigo" />
        </div>
        <div class="col-md-4 form-group">
          <label>Nome Exibição</label>
          <input-control
            v-model="serie.nomeExibicao"
          ></input-control>
        </div>
        <div class="col-md-2 form-group">
          <label>Status</label>
          <div class="switchWrap">
            <switch-control v-model:checked="serie.ativa" labelOn="Ativo" labelOff="Inativo" invert></switch-control>
          </div>
        </div>
        <div class="col-md-2 form-group">
          <label>Formulário de Leads</label>
          <div class="switchWrap">
            <switch-control v-model:checked="serie.apresentaFormLead" labelOn="Mostra" labelOff="Não mostra" invert></switch-control>
          </div>
        </div>
      </div>
      <div class="row no-padd">
        <div class="col-md-4 form-group">
          <label>Sistema de Avaliação</label>
          <select-control
            v-model="serie.savCodigo"
            :options="options.sistemas_avaliacao"
            placeholder="Selecione"
            name="savCodigo"
            :disabled="loading"
            :valid="valid.savCodigo" 
            @change="valid.savCodigo = true; setGestorLabel('sistemas_avaliacao','savCodigo', 'savNome')"
            required
          />
          <input type="hidden" v-model="serie.savNome">
        </div>
        <div class="col-md-4 form-group">
          <label>Centro de Custo</label>
          <select-control
            v-model="serie.cC1Codigo"
            :options="options.centros_custo"
            placeholder="Selecione"
            name="cC1Codigo"
            :disabled="loading"
            :valid="valid.cC1Codigo" 
            @change="valid.cC1Codigo = true; setGestorLabel('centros_custo','cC1Codigo', 'cC1Nome')"
            required
          />
          <input type="hidden" v-model="serie.cC1Nome">
        </div>
        <div class="col-md-4 form-group">
          <label>Subcentro de custo</label>
          <select-control
            v-model="serie.cC2Codigo"
            :options="options.sub_centros_custo"
            placeholder="Selecione"
            name="cC2Codigo"
            :disabled="loading"
            :valid="valid.cC2Codigo" 
            @change="valid.cC2Codigo = true; setGestorLabel('sub_centros_custo','cC2Codigo','cC2Nome')"
            required
          />
          <input type="hidden" v-model="serie.cC2Nome">
        </div>
      </div>
    </div>
    <div class="section" v-if="serie">
      <header>
        <h4 class="color-text">Documentos</h4>
      </header>
      <div class="row no-padd">
        <div class="col-md-5 form-group">
          <label>Selecione os documentos requeridos</label>
          <div>
            <select-multiple 
              :options="options.documentosRequeridos" 
              v-model="serie.documentosRequeridos" 
              :disabled="loading" 
              model="iddocumentosrequeridos"
            />
          </div>
        </div>
        <div class="col-md-7 form-group" v-if="serie.documentosRequeridos">
          <label>Configure os documentos selecionados</label>
          <table class="transparent-table">
            <thead>
              <tr>
                <th>Documento</th>
                <th>Obrigatório</th>
                <th>Primeira Matrícula</th>
                <th>Requerido Apollo</th>
                <th>Requerido App Rema</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="doc in serie.documentosRequeridos" :key="doc">
                <td>{{ doc.descricao }}</td>
                <td>
                  <label class="check-control">
                    <input type="checkbox" class="check invert" v-model="doc.obrigatorio" />
                  </label>
                </td>
                <td>
                  <label class="check-control">
                    <input type="checkbox" class="check invert" v-model="doc.primeiraMatricula" />
                  </label>
                </td>
                <td>
                  <label class="check-control">
                    <input type="checkbox" class="check invert" v-model="doc.requeridoGestao" />
                  </label>
                </td>
                <td>
                  <label class="check-control">
                    <input type="checkbox" class="check invert" v-model="doc.requeridoPortal" />
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <div class="row no-padd" ref="serieForm">
        <div class="col-md-4 form-group">
          <label>Valor Anuidade</label>
          <input-control
            v-model="serie.valorAnuidade" 
            placeholder="" 
            name="valorAnuidade"
            type="number"
            :valid="valid.valorAnuidade" 
            @keyup="valid.valorAnuidade = true"
            required
          />
        </div>
      </div> -->
      <div class="row no-padd">
        <div class="col-md-12 form-actions form-group">
          <a href="#" @click.prevent="save()" class="btn btn-primary">
            Salvar Série
          </a>
          <a href="#" @click.prevent="cancel()" class="btn btn-secondary">
            Cancelar
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import UserService from '@/services/user.service.js'
import AcademicoService from '@/services/academico.service.js'
import { createSelectOptions } from '@/services/utils.service.js'
import FormService from '@/services/form.service.js'

export default {
  props: {
    scope: {
      type: Object
    }
  },
  data () {
    return {
      serie: null,
      valid: {
        descricao: true,
        idUnidade: true,
        idTurno: true,
        idPeriodoLetivo: true,
        idCurso: true,
        idSerie: true
      },
      wait: {
        unidades: false,
        turnos: false,
        cursos: false,
        tipoCurso: false,
        documentos: false
      },
      options: {
        unidades: [],
        turnos: [],
        tipoCurso: [],
        periodosLetivos: [],
        cursos: [],
        centros_custo: [],
        sistemas_avaliacao: [],
        sub_centros_custo: [],
        documentosRequeridos: []
      },
      tipoCurso: null,
      series: null,
      loading: false,
      documentosRequeridos: []
    }
  },
  mounted () {
    this.serie = this.scope
    this.serie.descricaoAntigo = this.serie.descricao
    // console.log("this.serie", this.serie)

    if ( this.serie.documentosRequeridos ) {
      if ( this.serie.documentosRequeridos.length > 0 ) {
        this.serie.documentosRequeridos.map( k => {
          console.log("k:", k)
          k.obrigatorio = k.obrigatorio == 1
          k.primeiraMatricula =  k.primeiraMatricula == 1
          k.requeridoGestao =  k.requeridoGestao == 1
          k.requeridoPortal = k.requeridoPortal == 1
        })
      }
    }
    

    // this.getUnidades()
    this.getTipoCurso()
    this.getCursos()
    this.getTurnos()
    this.getDocumentosRequeridos()

    if ( this.serie.idSerie ) {
      this.getUnidades(true)
      this.getPeriodosLetivos(true)
    } else {
      this.getUnidades()
    }
  },
  methods: {
    getUnidades ( force ) {
      UserService.getMinhasUnidades().then(
        response => {
          console.log("getMinhasUnidades:", response.data)
          // this.options.series = []
          // this.options.periodosLetivos = []
          
          if ( response.data.length > 0 ) {
            this.options.unidades = createSelectOptions(response.data, 'idUnidade' ,'descricao')
            this.wait.unidades = true

            if ( force ) {
              this.$nextTick(() => {
                this.waitToGetGestorSeries()
              })
            }
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhuma unidade encontrada. Verifique sua conexão e tente novamente.", floater: true})
          }
          
          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma unidade encontrada. Verifique sua conexão e tente novamente.", floater: true})
        }
      )
    },
    getTurnos () {
      AcademicoService.getTurnos().then(
        response => {
          console.log("getTurnos:", response.data)
          if ( response.data.length > 0 ) {
            this.options.turnos = createSelectOptions(response.data, 'idTurno' ,'descricao')
            this.wait.turnos = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum tipo de curso encontrado. Verifique sua conexão e tente novamente.", floater: true})
          }
          
          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum tipo de curso encontrado. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    getCursos () {
      AcademicoService.getCursos().then(
        response => {
          console.log("getCursos:", response.data)
          if ( response.data.length > 0 ) {
            this.cursos = response.data
            this.options.cursos = createSelectOptions(response.data, 'idCurso' ,'descricao')
            this.wait.cursos = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum curso encontrado. Isto parece ser um problema de parametrização.", floater: true})
          }

         
          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum curso encontrado. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    getTipoCurso () {
      AcademicoService.getTipoCurso().then(
        response => {
          console.log("getTipoCurso:", response.data)
          if ( response.data.length > 0 ) {
            this.options.tipoCurso = createSelectOptions(response.data, 'idTipoCurso' ,'descricao')
            this.wait.tipoCurso = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum tipo de curso encontrado. Verifique sua conexão e tente novamente.", floater: true})
          }
          
          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum tipo de curso encontrado. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    getPeriodosLetivos (force) {
      if ( !this.loading || force ) {
        this.loading = true
        AcademicoService.getPeriodosLetivos(this.serie.idUnidade).then(
          response => {
            console.log("getPeriodosLetivos:", response.data)
            
            if ( response.data.length > 0 ) {
              this.options.periodosLetivos = createSelectOptions(response.data, 'idPeriodoLetivo' ,'descricao').reverse()

              if ( force ) {
                this.$nextTick(() => {
                  this.waitToGetGestorSeries()
                })
                
              }
            } else {
              window.toaster.emit('open', {style: "error", message: "Nenhum período letivo encontrado para esta unidade. Isto parece ser um problema de parametrização.", floater: true})
            }
            this.loading = false
          },
          error => {
            console.error(error)
            this.loading = false
            window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum período letivo encontrado para esta unidade. Isto parece ser um problema de parametrização.", floater: true})
          }
        )
      }
    },
    getDocumentosRequeridos () {
      AcademicoService.getDocumentosRequeridos().then(
        response => {
          console.log("getDocumentosRequeridos:", response.data)
          
          if ( response.data.length > 0 ) {
            response.data.map( k => {
              k['obrigatorio'] = false
              k['primeiraMatricula'] = false
              k['requeridoGestao'] = false
              k['requeridoPortal'] = false
            })
            this.options.documentosRequeridos = createSelectOptions(response.data, 'iddocumentosrequeridos' ,'descricao')
            this.wait.documentos = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum documento requerido encontrado. Isto parece ser um problema de parametrização.", floater: true})
          }
          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum documento requerido encontrado. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    finishLoad () {
      const finished = !Object.values(this.wait).some( a => !a)

      if ( finished ) {
        window.spinner.emit("close")
        this.loading = false
      }
      // console.log("finished:", finished)
    },
    waitToGetGestorSeries () {
      const periodosLetivos = this.options.periodosLetivos.length > 0
      const unidades =  this.options.unidades.length > 0

      // console.log("waitToGetGestorSeries - periodosLetivos:", periodosLetivos)
      // console.log("waitToGetGestorSeries - unidades:", unidades)

      if ( periodosLetivos && unidades ) {
        this.getGestorSeriesInfo()
      }
      
    },
    getGestorSeriesInfo () {
      const perCodigo = this.options.periodosLetivos.filter( a => a.idPeriodoLetivo == this.serie.idPeriodoLetivo)[0].descricao
      const sedCodigo = this.options.unidades.filter( a => a.idUnidade == this.serie.idUnidade)[0].idintegracao

      if ( !this.loading ) {
        this.loading = true
        AcademicoService.getGestorSeriesInfo(perCodigo, sedCodigo).then(
          response => {
            // console.log("response:", response)
            this.createGestorSelects(response.data)
            this.loading = false
          },
          () => {
            this.loading = false
          }
        )
      }
    },
    createGestorSelects ( data ) {
      Object.keys(data).map( k => {
        console.log("k:", k)
        data[k].map( kd => {
          var item, cItem
          if ( k == 'sistemas_avaliacao' ) {
            item = {
              value: kd.colunas.savCodigo,
              label: kd.colunas.savNome
            }

            this.options[k].push(item)
          }

          if ( k == 'sub_centros_custo' ) {
            cItem = {
              value: kd.colunas.cC2Codigo,
              label: kd.colunas.cC2Nome
            }
            item = {
              value: kd.colunas.cC1Codigo,
              label: kd.colunas.cC1Nome
            }
            
            const existS = this.options.sub_centros_custo.some( a => a.value == cItem.value)
            const existC = this.options.centros_custo.some( a => a.value == item.value)
            
            if ( !existS ) this.options.sub_centros_custo.push(cItem)
            if ( !existC ) this.options.centros_custo.push(item)
          }
        })
      })
    },
    setGestorLabel( src, target, drop ) {
      const name = this.options[src].filter( k => k.value == this.serie[target] )[0].label
      this.serie[drop] = name
    },
    setNomeExibicao () {
      const nomeExibicao = this.serie.descricao

      const turno = this.options.turnos.filter( k => k.value == this.serie.idTurno)[0].label
      
      this.serie.nomeExibicao = nomeExibicao + '/' + turno
    },
    save () {
      const valid = FormService.validate(this.$refs.serieForm, this.valid)
      
      if ( valid.response ) {
        this.serie.savCodigo = parseFloat(this.serie.savCodigo)

        if ( this.serie.documentosRequeridos && this.serie.documentosRequeridos.length > 0 ) {
          this.serie.documentosRequeridos.map( k => {
            k.obrigatorio = k.obrigatorio ? 1 : 0
            k.primeiraMatricula =  k.primeiraMatricula ? 1 : 0
            k.requeridoGestao =  k.requeridoGestao ? 1 : 0
            k.requeridoPortal = k.requeridoPortal ? 1 : 0
          })
        } else {
          this.serie.documentosRequeridos = []
        }

        window.spinner.emit('open')
        AcademicoService.saveSerie( this.serie ).then(
          response => {
            console.log("response", response)
            if ( response.status == 200 ) {
              window.toaster.emit('open', {style: "success", message: "Tudo certo! Série criada com sucesso.", floater: true})

              setTimeout(() => {
                this.$emit('onCancel', true)
              },500)
            }
            window.spinner.emit('close')
          },
          () => {
            window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível salvar a série informada.", floater: true})
            window.spinner.emit('close')
          }
        )
      }
    },
    cancel () {
      this.$emit('onCancel', false)
    }
  }
}
</script>

<style lang="scss" scoped>
  // .frame {
  //   padding: 0 $hmg_small
  // }
  
  .section {
    margin-top: $hmg_small
  }
  .form-actions {
    margin-top: $hmg !important
  }

  header {
    padding: $hmg_small $hmg_mid
  }
</style>