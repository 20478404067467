<template>
  <section>
    <card boxed>
      <p>Agente</p>
      <h4>{{ atividade[target].userNome ? atividade[target].userNome : "Não atribuído" }}</h4>
      <div class="cardActions">
        <div v-if="active && atividade[target].emAndamento && atividade[target].status == 0" class="timer">
          <p><b>Em andamento</b></p>
          <fa :icon="['fas','clock']" />
          <p>{{ fromNow(atividade[target].dataInicioAndamento) }}</p>
        </div>
        <div v-else-if="atividade[target].status == 1" class="timer">
          <p><b>Finalizado</b></p>
          <fa :icon="['fas','circle-check']" />
          <p>{{ formatDate(atividade[target].dataFimAtividade) }}</p>
        </div>
        <a href="#" class="btn btn-primary" @click.prevent="start()" :disabled="disabled" v-if="type.slug != 'tarefas'">
          Iniciar Atendimento
        </a>
        <a href="#" @click.prevent="toggleTransfer()" v-if="atividade[target].realizado != 1 && atividade[target].status != 1 && !disabled">
          <fa :icon="['far', 'arrow-right-arrow-left']" />
          &nbsp;Transferir Atendimento
        </a>
      </div>
    </card>
    <modal ref="modal" wrapped>
      <div class="modalWrap">
        <div class="">
          <div class="searchWrap">
            <input-spinner v-if="operador.loading" class="searchSpinner"></input-spinner>
            <input-control 
              v-model="operador.query" 
              placeholder="Buscar operador.."
              name="idUserAtribuido"
              :valid="valid.idUserAtribuido"
              @keyup="searchPessoa()"
              @blur="trackClearSearch()"
            ></input-control>
            <input type="hidden" v-model="operador.idUserAtribuido">
            <!-- <a href="#" class="remove" @click.prevent="remove(i)">
              <fa :icon="['fas','trash-can']"></fa>
            </a> -->
            <div class="listWrap" v-if="operador.list && operador.list.length > 0">
              <div class="list">
                <div>
                  <table>
                    <tr v-for="li in operador.list" :key="li" @click="selectOperador(li)">
                      <td>
                        {{ li.nome }}
                      </td>
                      <td>
                        {{ li.email }}
                      </td>
                      <td>
                        {{ li.nomeUnidade }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer>
          <a href="#" class="btn btn-secondary" @click.prevent="cancelTransfer">
            Cancelar
          </a>
          <a href="#" class="btn btn-primary" @click.prevent="transfer">
            Salvar
          </a>
        </footer>
      </div>
    </modal>
  </section>
</template>

<script>
import AtividadesService from '@/services/atividades.service.js'
// import AtendimentoService from '@/services/atendimento.service.js'
import inputSpinner from '@/components/utilities/InputSpinner'
import { fromNow, formatDate } from '@/services/utils.service.js'
import Modal from "@/components/utilities/Modal"

export default {
  components: {
    Modal, inputSpinner
  },
  props: {
    id: {
      type: [Number, String]
    },
    type: {
      type: [Object]
    },
    target: {
      type: String
    },
    atividade: {
      type: Object
    },
    disabled: {
      type: Boolean
    }
  },
  data () {
    return {
      active: false,
      operador: {
        query: null,
        loading: false,
        idUserAtribuido: null,
        list: []
      },
      valid: {
        idUserAtribuido: true
      },
      waitSearch: null
    }
  },
  mounted () {
    console.log("atividade:", this.atividade)
    console.log("type:", this.type)
    this.active = this.atividade[this.target].emAndamento
  },
  methods: {
    start () {
      AtividadesService.startAtividade(this.id).then(
        () => {
          // console.log("startAtividade", response)
          const current = this.$router.currentRoute._value.params
                current.action = 'executar'
                current.id = parseFloat(current.id)
          // console.log("current", current)

          this.$router.push({ name: 'Painel Atividades', params: current})
          // this.section = current._value.params

          window.toaster.emit('open', {style: "success", message: "Atividade iniciada. O tempo de execução está sendo contabilizado a partir de agora.", floater: true})
        },
        () => {

        }
      )
    },
    fromNow( dateTime ) {
      return fromNow(dateTime)
    },
    formatDate( date ) {
      return formatDate(date)
    },
    toggleTransfer () {
      window.pagescroll.emit("lock")

      const options = {
        title: 'Transferir atendimento',
        titleAlign: "center",
        message: "Escolha um novo operador para este atendimento", //não obrigatório
        size: "mid", //small, mid, normal, large, full
        backdrop: true,
        backdropColor: "transparent",  //se o backdrop for false, não precisa
        backdropClose: true,
        // onClose: this.closeEdit
      }

      console.log("toggleTransfer:", options)
      this.$refs.modal.open(options)
    },
    cancelTransfer () {
      this.$refs.modal.close()
    },
    transfer () {
      const drop = {
        idUserAntigo: this.atividade.informacoesAtendimento.idUserAtribuido,
        idUserNovo: this.operador.idUserAtribuido
      }
      
      AtividadesService.transferirAtividade(this.id, drop).then(
        response => {
          if ( response.status == 200 ) {
            window.toaster.emit('open', {style: "success", message: "Atividade transferida com sucesso.", floater: true}) 
          }

          setTimeout(() => {
            this.$refs.modal.close()
            this.$emit('onUpdate')
          }, 1000)
        },
        () => {
          this.$refs.modal.close()
        } 
      )
    },
    searchPessoa () {
      if ( this.waitSearch ) clearInterval(this.waitSearch)
        console.log("search:", this.operador.query)

        this.waitSearch = setTimeout ( () => {
          const nome = this.operador.query
          // const pessoa = 'colaboradores'

          if ( nome.length == 0 ) {
            this.clearSearch()
            this.operador.loading = false

          } else {
            this.operador.loading = true

            AtividadesService.getOperadorPermitido(this.id, nome).then(
              response => {
                console.log("getPessoaByNome:", response.data)
                if ( response.data.length > 0 ) {
                  this.operador.list = response.data
                  
                } else {
                  this.valid.operador = false
                  this.clearSearch()
                }

                this.operador.loading = false
              },
              () => {
                this.valid.operador = false
                this.operador.loading = false
              }
            )
          }
          
        }, 500)
    },
    clearSearch () {
      this.operador.query = ''
      this.operador.list = []
      this.operador.loading = false
      this.operador.idUserAtribuido = null
      
    },
    trackClearSearch () {
      setTimeout(() => {
        if ( !this.operador.idUserAtribuido ) this.clearSearch()
      },500)
    },
    selectOperador ( pessoa ) {
      this.operador.idUserAtribuido = pessoa.id
      this.operador.query = pessoa.nome
      this.operador.list = []
      this.operador.loading = false
    },
  }
}
</script>

<style lang="scss" scoped>
  p, h4 { 
    margin: 0; padding: 0;
  }
  .cardActions {
    margin-top: $hmg_mid;
    display: flex; flex-direction: column;
    align-items: flex-start;
    gap: $hmg_mid;

    a:not(.btn) {
      &:hover {
        color: $color-primary
      }
    }
  }
  .timer {
    display: flex; align-content: center;
    gap: $hmg_mini;
    padding: $mg_mini;
    border-radius: 2em;
    background-color: $color-bg;
    line-height: 1;

    >* {
      margin: 0; padding: 0
    }
  }

  .modalWrap {
    padding-top: $hmg_mid;
    
    footer {
      display: flex;
      align-content: center;
      justify-content: space-between;
      margin-top: $hmg
    }
  }

  .searchWrap {
    position: relative;

    .remove {
      position: absolute;
      right: $hmg_small; top: 50%;
      transform: translateY(-50%);

      >* {
        @extend %transition_3o;
      }
      
      &:hover {
        color: $color-primary
      }
    }
  }

  .listWrap {
    position: relative;
    width: 100%;

    .list {
      position: absolute; 
      width: 100%; max-height: 200px;
      left: $hmg_mini; top: -$hmg_mini;
      overflow: hidden;
      z-index: 100;
      padding: $hmg_small;
      background-color: $color-secondary;
      border-radius: $border-radius;
      @extend %shadow-high;
      border: $border-component;

      > div {
        padding: 0 $mg_mini 0 0; margin: 0; 
        max-height: calc(200px - $hmg_mid);
        overflow-y: auto;
        @extend %scrollbar_card;

        table {
          border-collapse: collapse;
          width: 100%;
          
          tr {
            @extend %transition_3o;
            cursor: default;
            vertical-align: middle;

            &:hover {
              background-color: $color-bg;
            }
            td {
              padding: $mg_mini;
              cursor: default; line-height: 1;

              &:first-child {
                border-top-left-radius: $border-radius-small;
                border-bottom-left-radius: $border-radius-small;
              }
              &:last-child {
                border-top-right-radius: $border-radius-small;
                border-bottom-right-radius: $border-radius-small;
              }
            }
          }
        }
      }

      ul {
        padding: 0 $mg_mini 0 0; margin: 0; max-height: 100%;
        overflow-y: auto;
        @extend %scrollbar_card;
        
        li {
          display: flex;
          align-content: center; align-items: center;
          justify-content: space-between;
          list-style: none;
          padding: $hmg_mini;
          margin: 0;
          @extend %transition_3o;
          cursor: pointer;

          &:hover {
            background-color: $color-bg;
            border-radius: $border-radius-small;
          }
          span {
            flex: 1;
            padding: 0 $mg_mini;

            &:first-child,
            &:last-child {
              padding: 0
            }

            &:last-child {
              flex: 0;
              text-align: right;
            }

            &:nth-child(1) {
              width: 30%;
            }
          }
        }
      }
    }
  } 

  .searchSpinner { 
    position: absolute; top: -3px;
    right: $hmg_mini;
    z-index: 100;
  }

  .spinnerWrap {
    width: 100%; padding-top: $mg;

    .loader {
      width: 100%;
      height: 6px;
      display: block;
      position: relative;
      overflow: hidden;
      background-color: $color-bg;
    }
    .loader::after {
      content: '';  
      width: 96px; height: 6px;
      background-color: $color-primary;
      position: absolute; top: 0; left: 0;
      box-sizing: border-box;
      animation: hit 0.6s ease-in-out infinite alternate;
    }

    @keyframes hit {
      0% {
        left: 0;
        transform: translateX(-1%);
      }
      100% {
        left: 100%;
        transform: translateX(-99%);
      }
    }
  }
</style>