<template>
  <ol class="productMgmtList" v-if="list.length > 0">
		<li>
			<div class="productMgmtHeader">
				<span>ID Checkout</span>
				<span>Data</span>
				<span>Responsável</span>
				<span>Valor</span>
				<span></span>
			</div>
		</li>
		<li v-for="item in list" :key="item">
			<card>
				<div class="productItem">
					<div>
						{{ item.idcheckoutMarketplace }}
					</div>
					<div>
						{{ formatDate(item.created_at) }}
					</div>
					<div>
						{{ item.nome }}
					</div>
					<div>
						{{ formatMoney(item.valorvenda) }}
					</div>
					<div class="recompra-actions">
						<a href="#" class="cardAction" @click.prevent="getDetails(item.idcheckoutMarketplace)">
							<fa :icon="['far', 'pencil']" class="icon"></fa>
						</a>
					</div>
				</div>
			</card>
		</li>
	</ol>
</template>

<script>
import LojaService from '@/services/loja.service'
import { formatMoney, formatDate, convertValue } from '@/services/utils.service'

export default {
	props: {
		list: {
			type: Array,
			default: () => {
				return []
			}
		},
		desconto: {
			type: Object,
			default: () => {
				return {}
			}
		}
	},
	watch: {
		list ( value ) {
			console.log("watch list:", value)
		},
		desconto ( value ) {
			console.log("watch desconto:", value)
		}
	},
	data () {
		return {

		}
	},
	methods: {
		formatMoney ( value ) {
      return formatMoney(value)
    },
    formatDate ( value ) {
      return formatDate(value)
    },
    convertValue ( value ) {
			console.log("value:", value)
			if ( value ) {
				return convertValue(value)
			}
    },
    calcDesconto ( valor, desconto ) {
      // console.log("desconto - valor:", valor)
      // console.log("desconto - desconto:", desconto)

      return LojaService.calcDesconto(valor, desconto) 
    },
		getDetails ( id ) {
			this.$emit('getDetails', id)
		}
	}
}
</script>

<style lang="scss" scoped>
.productMgmtList {
    display: block; width: 100%; height: auto;
    padding: 0; margin: $mg 0 0 0;

    li {
      list-style: none;
      padding: 0; margin: 0;

      .productItem {
        padding: $hmg_mid;
        display: flex; align-items: stretch; align-content: stretch;

        @media screen and (max-width: 1024px) {
          flex-wrap: wrap;
        }
        
        > div {
          display: flex; align-content: center; align-items: center;
          width: 13%; flex: 1;
          padding: 0 $hmg_mid;
					&:last-child {
						text-align: right;
						padding-right: 0;
					}
          // &:nth-child(1) { width: 20% ;}
          // &:nth-child(2),
          // &:nth-child(3),
          // &:nth-child(4) { width: 18% ;}
        }
      }

      .productMgmtHeader {
        display: flex; align-items: stretch; align-content: stretch;
        margin-bottom: $hmg_mid; padding: 0 $hmg_mid;
        font-size: 12px; font-weight: bold;
        
        @media screen and (max-width: 1024px) {
          flex-wrap: wrap;
        }

        > span {
          display: block;
          width: 13%; padding: 0 $hmg_mid;
					flex: 1;
					&:last-child {
						text-align: right;
						padding-right: 0;
					}
          // &:nth-child(1) { width: 20% ;}
          // &:nth-child(2),
          // &:nth-child(3),
          // &:nth-child(4) { width: 18% ;}
        }
      }
    }
  }

	.newValue {
    font-weight: bold;
    .icon {
      margin: 0 4px;
      padding-top: 2px;
    }
  }

  .oldValue {
    color: $color-alert;
  }

	.cardAction {
		font-size: 16px; color: $color-primary;
	}

	.recompra-actions {
		display: flex; align-items: center; align-content: center; justify-content: flex-end;
	}
</style>