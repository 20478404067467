<template>
  <div class="logoLoja">
    <svg version="1.1" baseProfile="tiny" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 955 162" overflow="visible" xml:space="preserve">
      <g>
        <g>
          <path :fill="fill" d="M266.1,61.7c-0.2,1.2-0.4,2.7-1.2,4.6H247c0.2-0.7,0.4-1.3,0.5-2c0.7-4-2.4-6.6-8.4-6.6
            c-7.3,0-12,2.4-12.7,6.5c-1.8,10.9,36,3.8,32.2,26.7c-2.4,14.7-13.6,27.4-39.3,27.4c-14.8,0-26.4-5.7-24.2-19.3
            c0.3-2.1,0.9-4.2,1.7-6.1h18c-0.5,0.7-0.9,2-1,2.9c-0.7,4.4,2,7.4,9.4,7.4c9.7,0,13.6-3,14.3-7.2c1.8-10.8-35.7-3.3-31.8-27.4
            c2.7-16.9,18.2-25.3,37.4-25.3C257.7,43.3,268.2,48.9,266.1,61.7z"/>
          <path :fill="fill" d="M293.4,72.3h21.4l8.8-27.9h19.4l-22.9,72.9H301l9.2-29.4h-21.4l-9.1,29.4h-19.3l22.5-72.9h19.4L293.4,72.3
            z"/>
          <path :fill="fill" d="M360.3,118.2c-15.5,0-25.2-11-22.4-27c0.5-2.7,2.7-9.3,4.6-15.7c6-19.3,22.9-32.2,42.9-32.2h3.4
            c15.6,0,25.3,11,22.4,27.1c-0.5,2.5-2.2,8.3-4.6,15.7c-6.4,19.5-23,32.1-42.8,32.1H360.3z M385.1,89.6c4.3-13.4,5.8-17.4,6.3-20.3
            c1.2-6.9-2.7-11.3-9.6-11.3c-9.1,0-15,5.9-17.9,15.2c-4.3,13.4-5.3,16.4-5.8,19c-1.2,6.8,2.7,11.3,9.4,11.3
            C375.7,103.5,382.2,98.6,385.1,89.6z"/>
          <path :fill="fill" d="M482.4,63.2c-2.7,14.7-14.9,26.9-30.8,26.9H434l-8.8,27.1H406l23.5-72.9h37
            C477.8,44.3,484.5,52.4,482.4,63.2z M444,59.1l-5.4,16.6h12.5c6.1,0,10-4.9,11.1-9.9c0.6-3.5-1.2-6.7-5.7-6.7H444z"/>
        </g>
        <g>
          <path :fill="fill" d="M568,77l-1.5,7.4c-4.2,20.1-18.6,32.7-37,32.7h-25.3L519,44.3h25.4C562.9,44.3,572.1,57,568,77z
            M516.5,107.7h14.9c12.4,0,21.4-7.8,24.6-23.3l1.5-7.4c3.1-15-2-23.3-15-23.3h-14.9L516.5,107.7z"/>
          <path :fill="fill" d="M639.2,44.3l-3.4,9.5h-36.2l-4.5,21.5h32.7l-2,9.5h-32.7l-4.8,23h36.7l-0.5,9.5h-48.5l15.2-72.9H639.2z"/>
          <path :fill="fill" d="M703.6,72.3h-10.4c2.7-12.2-1.9-19.5-13.6-19.5c-12.7,0-21.4,8-24.6,23.3l-2.1,9.6
            c-3.2,15.1,1.6,23.3,14.4,23.3c12.1,0,20-7.3,22.6-19.3h10c-3.8,17.5-17.3,28.8-33.7,28.8H665c-18.3,0-26.9-12.1-22.5-32.7l2-9.6
            c4.3-20.2,18.3-32.7,36.4-32.7h1.2C698.7,43.3,707.5,54.2,703.6,72.3z"/>
          <path :fill="fill" d="M746.6,53.8H738l-11.9,54h8.5l-2.1,9.5h-27.4l2.1-9.5h8.5l11.9-54H719l2.1-9.5h27.6L746.6,53.8z"/>
          <path :fill="fill" d="M809.3,63.7l-0.3,1.3h-10.5l0.4-1.6c1.6-6.9-3.9-10.6-12.8-10.6c-9.9,0-16.8,3.4-18.2,9.7
            c-3.7,16.7,41.9,7.1,36,33c-3.7,16.5-16.2,22.8-32.9,22.8c-17.2,0-26.1-7.3-22.9-21.8c0.2-0.7,0.3-1.2,0.6-2.1h10.4
            c-0.3,1-0.4,1.5-0.6,2.2c-1.9,8.4,4.1,12.2,14.7,12.2c10.6,0,18.2-4.1,19.7-10.9c4.2-18.7-41.6-8-35.9-33.9
            c3.3-14.8,15.9-20.7,30.9-20.7C803.9,43.3,812.3,50.5,809.3,63.7z"/>
          <path :fill="fill" d="M859,101.4h-34.9l-9.7,15.8h-10.1l45.2-72.9H861l11.7,72.9h-11.3L859,101.4z M865,37.5
            c-7.7,0-10.7-3.6-17.2-3.6c-3.2,0-5.4,1.2-6.7,3.6h-5.3c1.3-5.5,6.7-11,14.4-11c6.1,0,12.4,3.7,17.3,3.7c3.1,0,5.4-1.3,6.7-3.7
            h5.3C878,32.7,872.1,37.5,865,37.5z M852.2,56.9h-0.6L830,91.9h27.6L852.2,56.9z"/>
          <path :fill="fill" d="M887,85.5l2.2-9.6c4.8-20.5,19.9-32.7,37.3-32.7h1.1c17.6,0,27,12.3,22.1,32.7l-2.3,9.6
            c-4.8,20.4-20,32.7-37.3,32.7h-1.1C891.6,118.2,882.2,106,887,85.5z M937.1,85.5l2.3-9.6c3.6-15.1-1.3-23.3-14.4-23.3
            c-12.4,0-21.7,7.9-25.3,23.3l-2.2,9.6c-3.5,15.1,1.4,23.3,14.3,23.3C923.9,108.8,933.4,101.1,937.1,85.5z"/>
        </g>
        <g>
          <g>
            <path :fill="fill" d="M61,126.4c-9.4,0-17,7.6-17,17c0,9.4,7.6,17,17,17c9.4,0,17-7.6,17-17C78,134.1,70.4,126.4,61,126.4z
              M61,149.1c-3.1,0-5.7-2.5-5.7-5.7c0-3.1,2.6-5.7,5.7-5.7s5.7,2.6,5.7,5.7C66.6,146.6,64.1,149.1,61,149.1z"/>
          </g>
          <g>
            <path :fill="fill" d="M129.1,126.4c-9.4,0-17,7.6-17,17c0,9.4,7.6,17,17,17c9.4,0,17-7.6,17-17
              C146.1,134.1,138.5,126.4,129.1,126.4z M129.1,149.1c-3.1,0-5.7-2.5-5.7-5.7c0-3.1,2.6-5.7,5.7-5.7c3.1,0,5.7,2.6,5.7,5.7
              C134.8,146.6,132.2,149.1,129.1,149.1z"/>
          </g>
          <g>
            <path :fill="fill" d="M62.9,5.5c-2.2-2.5-5.3-4-8.6-4H38.2v11.4l16.1,0c0,0,0,0.1,0,0.1l-3.1,19.3l-1.4,9.1l-0.3,2.1l11.2,1.8
              l0.6-4l1.8-11.4l2.4-15.2l0-0.1C66,11.2,65.1,7.9,62.9,5.5z"/>
          </g>
          <g>
            <polygon :fill="fill" points="62.5,34 61.3,41.2 60.7,45.3 59.8,50.8 48.3,50.8 49.5,43.5 49.8,41.4 51.2,32.3 51.3,32.3 
              53.2,32.6 			"/>
          </g>
          <g>
            <path :fill="fill" d="M53.2,29.9v11.4H167l-18.3,64.1c-0.7,2.4-2.9,4.1-5.4,4.1l-92.7,0c0,0,0-0.1,0-0.1l1.8-11.7H40.9l-1.6,10
              l0,0.1c-0.5,3.3,0.5,6.6,2.6,9.1c2.2,2.5,5.3,4,8.6,4h92.7c7.6,0,14.3-5.1,16.4-12.3l22.5-78.5H53.2z"/>
          </g>
          <g>
            <path :fill="fill" d="M94.1,70.3H26.9c-3.1,0-5.7-2.5-5.7-5.7c0-3.1,2.5-5.7,5.7-5.7h67.2c3.1,0,5.7,2.5,5.7,5.7
              C99.8,67.7,97.2,70.3,94.1,70.3z"/>
          </g>
          <g>
            <path :fill="fill" d="M73.3,89.4H6.1c-3.1,0-5.7-2.5-5.7-5.7c0-3.1,2.5-5.7,5.7-5.7h67.2c3.1,0,5.7,2.5,5.7,5.7
              C78.9,86.9,76.4,89.4,73.3,89.4z"/>
          </g>
        </g>
        <g id="XMLID_00000172415258085545353880000015119568254899921086_">
          <path :fill="fill" d="M147.3,70.4c0,0.3,0,0.6-0.1,0.9c-0.3,1.3-0.8,2.5-1.7,3.4c-0.7,0.8-1.6,1.3-2.6,1.7
            c-1.1,0.4-2.2,0.7-3.3,1.1c-1.4,0.4-2.7,0.9-4.1,1.3c-0.5,0.1-0.5,0.2-0.3,0.6c0.9,2,1.9,3.9,3,5.7c0.3,0.6,0.6,1.2,0.6,2
            c0,2-0.8,3.7-2.6,5c-0.7,0.6-1.5,1-2.4,1.3c-1.9,0.6-4.1,0.1-5.1-1.7c-0.9-1.6-1.7-3.1-2.6-4.7c-0.1-0.2-0.2-0.3-0.3-0.5
            c-0.3-0.5-1-0.5-1.4,0c-0.3,0.3-0.6,0.6-0.8,0.9c-1.3,1.4-2.6,2.9-3.8,4.3c-0.9,1-1.9,1.6-3.1,1.8c-1.4,0.3-2.7,0.1-3.7-0.7
            c-0.6-0.4-1.1-0.9-1.4-1.5c-1-1.8-0.7-4.3,1-6.2c1.7-1.9,3.4-3.8,5-5.8c0.1-0.1,0.1-0.2,0.2-0.2c0.1-0.2,0.1-0.2-0.1-0.3
            c-0.1,0-0.1-0.1-0.2-0.1c-1.9-0.7-3.8-1.3-5.7-2c-0.5-0.2-1-0.4-1.5-0.7c-1.4-0.9-2.1-2.2-2-4c0.1-2.7,2.2-5.1,4.7-5.7
            c1-0.3,1.9-0.2,2.8,0c1.3,0.3,2.6,0.7,3.9,1c0.7,0.2,1.5,0.4,2.2,0.6c0.1,0,0.3,0,0.5,0c0.4-0.1,0.6-0.4,0.7-0.8
            c0.1-0.3,0.1-0.6,0.2-0.9c0.3-1.4,0.5-2.8,0.8-4.2c0.1-0.7,0.2-1.3,0.4-2c0.5-2,1.8-3.1,2.4-3.5c0.3-0.2,1.5-1.1,3.5-1.1
            c1.5,0,2.4,0.4,3.1,1.1c1,1,1.5,2.2,1.2,3.8c-0.3,1.8-0.5,3.5-0.8,5.3c-0.1,0.5-0.1,1-0.2,1.5c-0.1,0.6,0.3,0.9,0.9,0.8
            c1.2-0.3,2.4-0.5,3.6-0.8c1.2-0.3,2.3-0.6,3.5-0.8C144.8,65.6,147.3,67.5,147.3,70.4z"/>
        </g>
      </g>
      </svg>

  </div>
</template>

<script>
export default {
  props: {
    fill: {
      type: String,
      default: "#ED1C24"
    }
  }
}
</script>

<style lang="scss" scoped>
  .logoLoja {
    width: 165px; height: auto;

    path {
      // fill: $color-primary;
    }
  }
</style>