<template>
  <section>
    <form-spinner v-if="!ready"></form-spinner>
    <template v-else>
      <form v-if="prospect">
        <div class="row nop nom">
          <div class="form-group col-md-7">
            <input-control v-model="prospect.nomeResponsavel" label="Nome" :disabled="prospect.status == 'won'"/>
          </div>
          <div class="form-group col-md-5">
            <input-control v-model="prospect.cpfResponsavel" label="CPF" masking="cpf" :disabled="prospect.status == 'won'"/>
          </div>
          <div class="form-group col-md-3">
            <input-control type="date" v-model="prospect.dataNascimento" label="Data Nascimento" :disabled="prospect.status == 'won'"/>
          </div>
          <div class="form-group col-md-4">
            <input-control v-model="prospect.numeroCelular" label="Celular" masking="celular" :disabled="prospect.status == 'won'"/>
          </div>
          <div class="form-group col-md-5">
            <input-control v-model="prospect.email" label="Email" :disabled="prospect.status == 'won'"/>
          </div>
          <div class="col-md-7 form-group">
              <select-control 
                label="Unidade"
                v-model="prospect.idUnidade" 
                name="unidade" 
                placeholder="Selecione" 
                :options="options.unidades" 
                @change="getPeriodosLetivos()" 
                required
                :disabled="prospect.status == 'won'"
                />
            </div>
            <div class="col-md-5 form-group">
              <select-control
                label="Período Letivo"
                v-model="prospect.idPeriodoLetivo" 
                name="periodoLetivo" 
                placeholder="Selecione"
                @change="getSeries()"
                :options="options.periodosLetivos"
                :disabled="loadingFilterData || prospect.status == 'won'"
                required
              />
            </div>
        </div>
      </form>
      <form v-if="prospect">
        <header>
          <h4>Interesses/Alunos</h4>
          <a href="#" @click.prevent="toggleAddInteresse()" class="btn btn-primary" v-if="prospect.status != 'won'">
            Novo Interesse
          </a>
        </header>      
        <ul v-if="prospect.interesses && prospect.interesses.length > 0" class="interesses">
          <li v-for="(interesse, i) in prospect.interesses" :key="interesse">
            <card :boxed="false">
              <div class="interesseCard">
                <div class="row nop nom">
                  <div class="form-group col-md-8">
                    <input-control v-model="interesse.nomeAluno" label="Nome" :disabled="prospect.status == 'won'"/>
                  </div>
                  <div class="form-group col-md-4">
                    <input-control v-model="interesse.dataNascimento" label="Data Nascimento" type="date" :disabled="prospect.status == 'won'"/>
                  </div>
                  <div class="col-md-6 form-group">
                    <select-control
                      label="Série"
                      v-model="interesse.idSerie" 
                      name="serie"
                      placeholder="Selecione"
                      :options="options.series"
                      :disabled="loadingFilterData || prospect.status == 'won'"
                      @change="getTurmas(null,interesse.idInteresse)"
                    />
                  </div>
                  <div class="col-md-6 form-group">
                    <select-control
                      label="Turma"
                      v-model="interesse.idTurma" 
                      name="serie"
                      placeholder="Selecione"
                      :options="options.turmas[interesse.idInteresse]"
                      :disabled="loadingFilterData || prospect.status == 'won'"
                    />
                  </div>
                </div>
              </div>
              <footer>
                <a href="#" class="btn btn-primary" @click.prevent="gotoMat(interesse)" v-if="prospect.status == 'won' && interesse.idMatricula">Ir para a Proposta</a>
                <a href="#" @click.prevent="toggleDeleteInteresse(interesse.idInteresse, i)" v-else>
                  <fa :icon="['fal','trash-can']"/>
                </a>
              </footer>
            </card>      
          </li>
        </ul>
      </form>
    </template>
    <footer>
      <a href="#" class="btn btn-secondary" @click.prevent="voltar()" :disabled="!ready">Voltar</a>
      <a href="#" class="btn btn-primary" @click.prevent="save()" :disabled="!ready">Salvar</a>
    </footer>
    <confirm 
      :backdrop="false"
      class="confirm"
      ref="confirm"
    ></confirm>
  </section>
</template>

<script>
import UserService from '@/services/user.service'
import AcademicoService from '@/services/academico.service.js'
import CaptacaoService from '@/services/captacao.service.js'
import { createSelectOptions, formatDate, cleanCpf, clearMask } from "@/services/utils.service.js"
import FormSpinner from '@/components/utilities/FormSpinner.vue'

export default {
  components: {
    FormSpinner
  },
  props: {
    scope: {
      type: Object
    }
  },
  data () {
    return {
      prospect: null,
      ready: false,
      loadingFilterData: false,
      options: {
        unidades: [],
        periodosLetivos: [],
        series: [],
        turmas: {}
      },
      wait: {
        unidades: false,
      },
      leadScope: {
        idProspect: null,
        idPeriodoLetivo: null,
        idSerie: null,
        idTurma: null,
        nomeAluno: null,
        dataNascimento: null,
        idDealProduct: null
      },
      waiter: {}
    }
  },
  mounted () {
    this.prospect = this.scope
    this.getUnidades()

    if ( this.prospect.idUnidade ) {
      this.getPeriodosLetivos()
    }
    if ( this.prospect.idPeriodoLetivo ) {
      this.getSeries()
    }

    if ( this.prospect.interesses.length > 0 ) {
      this.prospect.interesses.map( k => {
        console.log("k:", k)
        if ( k.idSerie ) {
          this.options.turmas[k.idInteresse] = []
          this.getTurmas(k.idSerie, k.idInteresse)
        }
      })
      console.log('options:', this.options)
    }
  },
  methods: {
    getUnidades () {
      UserService.getUnidades().then(
        response => {
          // console.log("getMinhasUnidades:", response.data)
          // this.wait.unidades = true
          this.options.unidades = createSelectOptions(response.data, 'idUnidade' ,'descricao')
          this.wait.unidades = true
          this.finishLoad()
        },
        error => {
          console.error(error)
        }
      )
    },
    getPeriodosLetivos () {
      this.loadingFilterData = true
      AcademicoService.getPeriodosLetivos( this.prospect.idUnidade ).then(
        response => {
          this.loadingFilterData = false
          // console.log("getPeriodosLetivos:", response.data)
          this.options.periodosLetivos = createSelectOptions(response.data, 'idPeriodoLetivo' ,'descricao').reverse()
        },
        error => {
          console.error(error)
          this.loadingFilterData = false
        }
      )
    },
    getSeries () {
      this.loadingFilterData = true
      AcademicoService.getSeries(this.prospect.idPeriodoLetivo, 0).then(
        response => {
          this.loadingFilterData = false
          console.log("getSeries:", response.data)
          this.options.series = createSelectOptions(response.data, 'idSerie' ,'nomeExibicao')
        },
        error => {
          console.error(error)
          this.loadingFilterData = false
        }
      )
    },
    getTurmas ( idSerie, target ) {
      this.loadingFilterData = true
      const serie = idSerie ?? this.prospect.interesses.filter( a => a.idInteresse == target)[0].idSerie

      AcademicoService.getTurmas( serie ).then(
        response => {
          this.loadingFilterData = false
          // console.log("getSeries:", response.data)
          this.options.turmas[target] = createSelectOptions(response.data, 'idTurma' ,'descricao')
        },
        error => {
          console.error(error)
          this.loadingFilterData = false
        }
      )
    },
    finishLoad () {
      const done = !Object.values(this.wait).some( a => !a)
      // console.log("done:", done)
      if ( done ) {
        this.ready = true
      }
    },
    voltar () {
      this.$router.push({ name: "Leads" })
    },
    toggleAddInteresse () {
      const interesse = Object.assign({},this.leadScope)
      interesse.idProspect = this.prospect.idProspect
      // interesse.nrFilho = Number(this.prospect.interesses.length) + 1
      interesse.idPeriodoLetivo = this.prospect.idPeriodoLetivo
      console.log("interesse:", interesse)

      this.prospect.interesses.unshift(interesse)

      console.log("interesses:", this.prospect.interesses)
    },
    save () {
      this.ready = false
      this.saveInteresses()
      this.saveProspect()
    },
    saveInteresses () {
      this.waiter.prospect = false
      this.prospect.interesses.map( (k, i) => {
        this.waiter['interesse_' + i] = false
      })
      
      console.log("waiter:", this.waiter)
      
      this.prospect.interesses.map( (k,i) => {
        this.saveInteresse(k,i)
      })
    },
    saveInteresse ( interesse, index ) {

      const drop = Object.assign({}, interesse)
      
      drop.dataNascimento = formatDate(drop.dataNascimento)

      CaptacaoService.saveInteresse( drop ).then(
        response => {
          if ( response.status == 200 || response.status == 201 ) {
            this.waiter['interesse_' + index] = true
            this.finishSave()
          }
        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops! Algo aconteceu. Não foi possível salvar o interesse.", floater: true})
          this.ready = true
        }
      )
    },
    saveProspect () {
      const prospect = Object.assign({},this.prospect)

      if ( !prospect.dataNascimento ) {
        delete prospect.dataNascimento
      } else {
        prospect.dataNascimento = formatDate(prospect.dataNascimento)
      }

      prospect.cpfResponsavel = prospect.cpfResponsavel && cleanCpf(prospect.cpfResponsavel)
      prospect.numeroCelular = prospect.numeroCelular && clearMask('phonecc', prospect.numeroCelular)

      delete prospect.interesses

      CaptacaoService.saveProspect( prospect ).then(
        response => {
          if ( response.status == 200 || response.status == 201 ) {
            this.waiter.prospect = true
            this.finishSave()
          }
        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops! Algo aconteceu. Não foi possível salvar o prospect.", floater: true})
          this.ready = true
        }
      )
    },
    finishSave () {
      const done = !Object.values(this.waiter).some( a => !a)
      console.log("done:", done)
      if ( done ) {
        this.ready = true
      }
    },
    toggleDeleteInteresse ( interesse, index ) {
      this.$refs.confirm.run({
        message: "Tem certeza que deseja remover este interesse?",
        confirmButton: "Sim",
        denyButton: "Não",
        data: {
          interesse, index
        },
        callback: this.deleteInteresse,
      })
    },
    deleteInteresse ( response, data ) {
      if ( response ) {
        if ( data.interesse ) {
          CaptacaoService.deleteInteresse( data.interesse ).then(
            response => {
              if ( response.status == 200 ) {
                window.toaster.emit('open', {style: "success", message: "Tudo certo! Interesse removido com sucesso.", floater: true})
                this.prospect.interesses.splice( data.index, 1)
              }
            },
            error => {
              console.error( error )
              window.toaster.emit('open', {style: "error", message: "Ops! Algo acontece. Não foi possível remover este interesse. Por favor, tente novamente mais tarde. Se o problema persistir, procure nosso time de suporte.", floater: true})
            }
          )
        } else {
          this.prospect.interesses.splice( data.index, 1)
        }
       
      }
      
    },
    gotoMat ( interesse ) {
      // console.log("gotoCA:", interesse)
      this.$router.push({ name: 'Nova Matrícula', params: { action: 'editar', step: 'unidade-curso', idMatricula: interesse.idMatricula }})
    }
  }
}
</script>

<style lang="scss" scoped>
  section {
    form {
      padding: $hmg_mid $mg_mini;
      border-bottom: $border-component;

      >header {
        display: flex; align-items: center;
        justify-content: space-between;
        padding: $mg_mini $hmg_mid;
        line-height: 1;
        margin-bottom: $hmg_mid;

        h4  {
          padding: 0; margin: 0;
        }
      }
    }
  }

  .interesses {
    padding: 0; margin: 0;

    li {
      padding: 0 $mg_mini; margin: 0 0 $mg_mini; list-style: none;
      
      .interesseCard {
        padding: $hmg_mid $mg_mini $mg_mini
      }
      footer {
        border-top: $border-component;
        padding: $mg_mini $hmg_mid; 
        display: flex; align-items: center;
        justify-content: flex-end;

        a:not(.btn) {
          font-size: 16px;

          &:hover {
            color: $color-primary
          }
        }
      }
    }
  }

  footer {
    display: flex; align-items: center;
    justify-content: space-between;
    padding: $mg_mini $hmg_mid;
  }
</style>