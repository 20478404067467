<template>
  <section class="over-page">
    <main class="frame">
      <div class="form-wrap">
        <card :listed="false">
          <header>
            <div class="titleWrap">
              <label v-if="id">Editar formulário {{ id }}</label>
              <input-control cssClass="transparent large" v-model="form.descricao" ref="descricao"/>
              <div class="userInfo" v-if="form.nome">
                <fa :icon="['fas', 'user-tie']" class="icon"/>
                <p>{{ form.nome }}</p>
              </div>
            </div>
            <switch-control v-model:checked="form.ativo" labelOn="Ativo" labelOff="Inativo" labelPos="esq" invert></switch-control>
          </header>
          <div class="questions">
            <ul>
              <li v-for="(pergunta, i) in perguntas" :key="pergunta" draggable @dragstart="startDrag($event, pergunta)">
                <span class="drag"></span>
                <pergunta 
                  :allowCondition="allowCondition" 
                  :conditions="conditionQuestions" 
                  :scope="pergunta" 
                  :index="i" 
                  :ref="'pergunta_' + i" 
                  @onRemove="removePergunta(i)" 
                  @onSave="getPerguntas(id)"
                ></pergunta>
              </li>
            </ul>
            <a href="#" class="btAddQuestion" @click.prevent="addQuestion()" v-if="id">
              <span>Adicionar Pergunta</span>
              <fa :icon="['fal','circle-question']" class="icon" />
            </a>
            <p v-else>
              Para adicionar perguntas é necessário salvar o formulário
            </p>
          </div>
          <footer>
            <div>
              <a href="#" class="btn btn-secondary" @click.prevent="goBack()">Voltar</a>
            </div>
            <div>
              <a href="#" class="btn btn-primary" @click.prevent="saveForm()">Salvar Formulário</a>
            </div>
          </footer>
        </card>
      </div>
    </main>
  </section>
</template>

<script>
import FormulariosService from "@/services/formularios.service.js"
import Pergunta from "@/components/ferramentas/formularios/form/Pergunta.vue"

export default {
  components: {
    Pergunta
  },
  props: {
    id: {
      type: [String, Number]
    }
  },
  data () {
    return {
      form: {
        descricao: "Formulário sem nome",
        ativo: false
      },
      perguntas: [],
      wait: {
        form: false,
        perguntas: false
      },
      allowCondition: false,
      conditionQuestions: null,
      model: {
        descricao: null,
        obrigatorio: false,
        tipoResposta: 1,
        ordem: null,
        idFormularioPerguntaCondicao: null,
        idFormulario: null,
        idFormularioAlternativaCondicao: null,
        active: false
      }
    }
  },
  mounted () {
    if ( this.id ) {
      window.spinner.emit("open")
      this.getForm(this.id)
      this.getPerguntas(this.id)
    }

  },
  methods: {
    getForm ( id ) {
      FormulariosService.getFormulario(id).then(
        response => {
          this.form = response.data
          this.wait.form = true

          this.finishLoad()
        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops! Algo aconteceu. Não foi possível recuperar o formulário solicitado.", floater: true})
        }
      )
    },
    getPerguntas ( id )  {
      FormulariosService.getPerguntas(id).then(
        response => {
          this.perguntas = response.data
          console.log("perguntas:", this.perguntas)
          this.wait.perguntas = true
          this.trackAllowCondition()
          this.finishLoad()


        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops! Algo aconteceu. Não foi possível recuperar as perguntas solicitadas.", floater: true})
        }
      )
    },
    finishLoad() {
      const finished = !Object.values(this.wait).some( a => !a)

      if ( finished ) {
        window.spinner.emit("close")
        // this.loading = false
      }
    },
    goBack () {
      this.$router.push({ name: "Formulários" })
    },
    trackAllowCondition () {
      const allow = this.perguntas.some( a => a.tipoResposta == 2 || a.tipoResposta == 3 )
      console.log("trackAllowCondition:", allow)
      this.allowCondition = allow

      if ( allow ) {
        const perguntas = this.perguntas.filter( a => a.tipoResposta == 2 || a.tipoResposta == 3 )
        this.conditionQuestions = perguntas
      }
    },
    addQuestion () {
      var newQ = Object.assign({}, this.model)

      const index = this.perguntas.length

      newQ.idFormulario = parseFloat(this.id)
      newQ.ordem = Number(index) + 1
      newQ.active = true

      this.perguntas.push(newQ)

      this.$nextTick(() => {
        console.log("newq-ref:", this.$refs['pergunta_' + index] )
        this.$refs['pergunta_' + index][0].togglePergunta()
        this.$refs.descricao.focus()
      })
      
    },
    saveForm () {
      const drop = {
        descricao : this.form.descricao,
        ativo: this.form.ativo ? 1 : 0
      }

      // console.log("id:", this.id)
      // console.log("drop:", drop)
      
      if ( this.id ) {
        FormulariosService.updateFormulario( this.id, drop ).then(
          response => {
            if ( response.data ) {
              window.toaster.emit('open', {style: "success", message: "Tudo certo! Formulário salvo com sucesso.", floater: true})
              this.getForm( this.id )
            }
          },
          () => {
            window.toaster.emit('open', {style: "error", message: "Ops! Algo aconteceu. Não foi possível salvar este formulário.", floater: true})
          }
        )
      } else {
        FormulariosService.saveFormulario( drop ).then(
          response => {
            if ( response.data ) {
              console.log("response.data:", response.data)
              window.toaster.emit('open', {style: "success", message: "Tudo certo! Formulário salvo com sucesso.", floater: true})

              this.$nextTick(() => {
                this.$emit("lockScroll", true)
                this.$router.push({ name: "Formulários", params:{ action: 'editar', id: response.data.idFormulario }})
              })
            }
          },
          () => {
            window.toaster.emit('open', {style: "error", message: "Ops! Algo aconteceu. Não foi possível salvar este formulário.", floater: true})
          }
        )
      }
    },
    removePergunta ( i ) {
      this.perguntas.splice(i,1)
    },
    startDrag (e, item) {
      console.log("e", e)
      console.log("item", item)
    }
  }
}
</script>

<style lang="scss" scoped>
  main {
    display: flex;
    align-items: stretch; align-content: center;
    padding: $mg;
    gap: $hmg_mid;

    > div {
      // background-color: $color-success;
      flex: 1;

      &.siderbar {
        max-width: 320px;
      }

      .form-wrap {
        header {
          display: flex;
          align-content: center; align-items: flex-start;
          justify-content: space-between;
          padding: $hmg_mid;

          label {
            font-size: $font-size-small;
            color: $color-primary;
            font-weight: bold
          }
          .titleWrap {
            flex: 1; padding-right: $mg
          }

          .userInfo {
            display: flex; align-content: center;
            align-items: center;
            gap: $hmg_mini; margin-top: $hmg_mid;

            p {
              padding: 0; margin: 0
            }
          }
        }

        .questions {
          border-top: $border-component;
          padding: $hmg_mid;

          ul {
            padding: 0; margin: 0;

            li {
              list-style: none; 
              padding: 0; margin: 0 0 $mg_mini 0;
              position: relative;

              .drag {
                display: block;
                position: absolute;
                left: 5px; top: 50%;
                width: 8px; height: 20px;
                border-radius: 4px; 
                background-color: $color-component;
                transform: translateY(-50%);
                z-index: 101;
              }
            }
          }

          .btAddQuestion {
            display: flex; align-items: center; align-content: center;
            justify-content: space-between; 
            width: 100%; 
            border-radius: $border-radius; border: $border-component;
            padding: $hmg_small $hmg_mid; margin-top: $hmg_mid;
            background-color: $color-bg;
            @extend %transition_3o;
            font-size: $font-size-large; font-weight: bold;
            .icon {
              font-size: 18px;
            }

            &:hover {
              // transform: scale(1.05,1.05);
              border: $border-component;
              @extend %shadow-low-secondary;
            }
            &:hover, &:active, &:focus, &:visited {
              background-color: $color-bg !important;
            }
          }
        }

        footer {
          display: flex; width: 100%;
          align-content: center; align-items: center;
          justify-content: space-between;
          border-top: $border-component;
          padding: $mg_mini $hmg_mid;

          >div {
            display: flex;
            align-content: center; align-items: center;

            &:last-child {
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }
</style>