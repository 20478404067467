<template>
  <div class="documentos" ref="docs">
    <header>
      <div class="toggleResendContract" v-if="!this.auto && reenvioContrato && ( matricula?.idSituacao == 1 || matricula?.idSituacao == 14 ) && !loading">
        <a href="#" @click.prevent="confirmResendContract()" class="btn btn-primary btn-small">
          Reenviar Contrato
        </a>
      </div>
    </header>
    <form v-if="documentos && !loading && ( documentos?.docAluno.length > 0 || documentos?.docResponsavel.length > 0 )" enctype="multipart/form-data">
      <div v-for="list in lists" :key="list">
        <h6 class="color-text" v-if="list == 'docAluno'">Documentos do Aluno</h6>
        <h6 class="color-text" v-if="list == 'docResponsavel'">Documentos do Responsável</h6>
        <ul>
          <li v-for="(documento,i) in documentos[list]" :key="documento" :class="{ invert: model === 'invert' }">
            <div class="info">
              <div>
                <span v-if="documento.documentoEntregue" class="statusMarker enviado">
                  <fa :icon="['fas','check']" class="icon"></fa>
                </span>
                <span class="statusMarker pendente" v-else>
                  <fa :icon="['fas','xmark']" class="icon"></fa>
                </span>
                <div>
                  <p>{{ documento.descricao }} <fa :icon="['fas', 'asterisk']" class="requiredIcon" v-if="documento.obrigatorio" /></p>
                  <label v-if="documento.documentoEntregue?.nomeUser && !this.auto">
                    <template v-if="documento.documentoEntregue?.validado == 'V'">
                      <fa :icon="['fas','thumbs-up']" class="color-success"></fa>
                      <b> Aprovado </b></template>
                    <template v-if="documento.documentoEntregue?.validado == 'I'">
                      <fa :icon="['fas','thumbs-down']" class="color-primary"></fa>
                      <b> Reprovado </b>
                    </template>
                    por {{ documento.documentoEntregue.nomeUser  }} em {{ formatDate(documento.documentoEntregue.datahora ) }}
                  </label>
                  <label v-if="documento.documentoEntregue?.idClickSign && documento.documentoEntregue?.nomeAssinatura">
                    Contrato Assinado digitalmente por: {{ documento.documentoEntregue?.nomeAssinatura }} em: {{ formatDate(documento.documentoEntregue?.dataentrega) }}
                  </label>
                </div>
              </div>
              <div>
                <template v-if="documento?.documentoEntregue?.src && !documento.documentoEntregue?.fake && !documento.upload.read || documento.documentoEntregue?.idClickSign">
                  <a :href="
                      documento.documentoEntregue?.idClickSign ? documento?.documentoEntregue?.src : 'data:image/png;base64,'+ documento?.documentoEntregue?.src
                    "
                    :download="docFileName(documento)"
                    :target="documento.documentoEntregue?.idClickSign && '_blank' "
                    class="fileDownloadButton"
                  >
                    <fa :icon="['fas','cloud-arrow-down']" class="icon"></fa>
                    <span></span>
                  </a>
                </template>
                <p class="image-src-label" v-if="documento.upload.ready && !documento.upload.uploaded">
                  <fa :icon="['fas','cloud-arrow-up']" v-if="documento.upload?.ready" class="icon"></fa>
                  <fa :icon="['fas','cloud-check']" v-else-if="documento.upload?.uploaded" class="icon"></fa>
                  <fa :icon="['fas','cloud-exclamation']" v-else-if="documento.upload?.uploadError" class="icon"></fa>
                  <span>{{ documento.upload?.name }}</span>
                </p>
                <label class="file-button" v-if="!uploading && !documento.loading && isValidado(documento) && !documento.documentoEntregue?.idClickSign && action !='ver'">
                  <p>{{ documento.documentoEntregue ? 'Atualizar' : 'Enviar' }}</p>
                  <span></span>
                  <input type="file" @change="setUploadImage($event, list ,i)" accept=".jpeg,.jpg,.png,.pdf"/>
                </label>
              </div>
            </div>
            <div 
              :class="['thumb', { opened: documento.opened }]" 
              @click.prevent="documento.documentoEntregue?.src && !documento.opened && !preventThumbToggle && !documento.documentoEntregue?.idClickSign ? toggleThumb(list,i,true) : null"
              :style="{ height: documento.opened ? ( vh + 'px' ) : '', width: documento.opened ? ( vw + 'px' ) : '' }"
            >
              <header v-if="documento.opened">
                <div>
                  <p class="color-text" v-if="list == 'docAluno'">Documento do Aluno</p>
                  <p class="color-text" v-if="list == 'docResponsavel'">Documento do Responsável</p>
                  <h4>{{ documento.descricao }}</h4>
                </div>
                <a href="" @click.prevent="toggleThumb(list,i,false)" @mouseenter="preventThumbToggle = true" @mouseleave="preventThumbToggle = false">
                  <fa :icon="['fal','xmark-large']" class="icon"></fa>
                </a>
              </header>
              <div v-if="documento.loading">
                <image-spinner></image-spinner>
              </div>
              <div class="pic" v-else-if="documento.documentoEntregue?.src">
                <img v-if="documento.documentoEntregue?.fake" :src="documento.documentoEntregue.src">
                <fa v-else-if="documento.documentoEntregue?.idClickSign" :icon="['fas','file-signature']" />
                <img v-else :src="'data:image/png;base64,' + documento.documentoEntregue.src">
              </div>
              <div v-else-if="documento.error" title="Imagem não encontrada">
                <fa :icon="['fas', 'hexagon-exclamation']" class="color-alert"></fa>
              </div>
              <div v-else title="Imagem não enviada">
                <fa :icon="['fas', 'image']" :class="model === 'invert' ? 'color-secondary' : 'color-bg' "></fa>
              </div>
            </div>
            <div 
              class="validation"
              v-if="
                ( documento.documentoEntregue && 
                  !documento.documentoEntregue?.fake && 
                  !documento.loading &&
                  validation 
                ) || ( 
                  !validation && 
                  documento.documentoEntregue?.validado &&
                  documento.documentoEntregue?.validado != 'N'
                )
              " 
              :class="{
                approved: documento.documentoEntregue.validado == 'V', 
                rejected: documento.documentoEntregue.validado == 'I'
              }">
              <a href="#" :class="['approved',{ disabled: documento.validating || action == 'ver' }]" @click.prevent="validateDoc(list, i, 'approve')">
                <fa :icon="['fas','thumbs-up']" class="icon"></fa>
              </a>
              <a href="#" :class="['rejected',{ disabled: documento.validating || action == 'ver' }]" @click.prevent="validateDoc(list, i, 'reject')">
                <fa :icon="['fas','thumbs-down']" class="icon"></fa>
              </a>
            </div>
          </li>
        </ul>
      </div>
      <div class="form-actions actions-right" v-if="action != 'ver'">
        <a href="#" @click.prevent="submit()" class="btn btn-primary" :disabled="!allow">Salvar</a>
        <a href="#" @click.prevent="cancel()" class="btn btn-secondary" v-if="!auto">Cancelar</a>
      </div>
    </form>
    <div v-else-if="loading" class="content-spinner" >
      <input-spinner></input-spinner>
    </div>
    <div v-else class="empty frame micro">
      <fa :icon="['fal','face-meh']" class="icon"></fa>
      <h4 class="color-text">Ops. Parece que esta matrícula não tem documentos requeridos.</h4>
    </div>
    <confirm 
      :backdrop="false"
      class="confirm"
      ref="confirm"
    ></confirm>
  </div>
</template>

<script>
import AcademicoService from '@/services/academico.service'
import MatriculaService from '@/services/matricula.service'
import { getImagesFromBucket, toBase64, formatDate } from '@/services/utils.service'
import InputSpinner from '@/components/utilities/InputSpinner'
import ImageSpinner from '@/components/utilities/ImageSpinner'
import moment from 'moment'

export default {
  components: {
    ImageSpinner, InputSpinner
  },
  props: {
    id: {
      type: [Number, String]
    },
    model: {
      type: String,
      default: 'regular'
    },
    cancancel: {
      type: Boolean,
      default: false
    },
    modalmode: {
      type: Boolean,
      default: false
    },
    validation: {
      type: Boolean,
      default: false
    },
    reenvioContrato: {
      type: Boolean,
      default: false
    },
    auto: {
      type: Boolean,
      default: false
    },
    matricula: {
      type: Object,
      default: () => {
        return {}
      }
    },
    action: {
      type: String
    }
  },
  watch: {
    id ( value ) {
      if ( value ) {
        this.getDocumentosByIdMatricula()
      }
    },
    matricula ( value ) {
      if ( value ) {
        console.log("matricula on watch:", value)
      }
    }
  },
  data () {
    return {
      documentos: null,
      vh: null,
      vw: null,
      lists: null,
      allow: false,
      uploading: false,
      preventThumbToggle: false,
      loading: false
    }
  },
  mounted () {
    if ( this.id ) this.getDocumentosByIdMatricula()

    // console.log("validation inside component:", this.validation)

    setTimeout(() => {
      this.triggerTip('documentos','documentos-obrigatorios')
       setTimeout(() => {
        this.triggerTip('documentos','documentos-nao-obrigatorios')
      },2000)
    },1000)

    // console.log("matricula:", this.matricula)
  },
  methods: {
    getDocumentosByIdMatricula () {
      this.loading = true

      AcademicoService.getDocumentosByIdMatricula( this.id ).then(
        response => {
          console.log('getDocumentos:', response)
          this.lists = Object.keys(response.data)

          var filteredResp, filteredAluno

          if ( this.auto ) {
            filteredResp = response.data.docResponsavel.filter( k => {
              return k.requeridoPortal == 1
            })
            filteredAluno = response.data.docAluno.filter( k => {
              return k.requeridoPortal == 1
            })
          } else {
            filteredResp = response.data.docResponsavel.filter( k => {
              return k.requeridoGestao == 1
            })
            filteredAluno = response.data.docAluno.filter( k => {
              return k.requeridoGestao == 1
            })
          }

          filteredResp.map( (k,i) => {
            if ( k.documentoEntregue?.idClickSign ) {
              this.getStatusContrato(k,i,'src')
              this.getStatusContrato(k,i,'name')
              this.getStatusContrato(k,i,'created_at')
            }
          })

          this.documentos = {
            docAluno: filteredAluno,
            docResponsavel: filteredResp
          }

          // this.documentos = response.data
          this.getImages()
          this.loading = false
          setTimeout(() => {
            this.trackViewportSize()
          }, 500)
        },
        error => {
          console.error(error)
          this.loading = false
        }
      )
    },
    getImages () {
      if ( Object.keys(this.documentos).length > 0 ){
        Object.keys(this.documentos).map( k => {
           this.documentos[k].map( kd => {
            kd.loading = true
            kd.upload = {
              file: null,
              ext: null,
              size: null,
              name: null,
              ready: false,
              index: null,
              type: k,
              uploaded: false,
              uploadError: false,
              idMatricula: this.id,
              iddocumentosrequeridos: kd.iddocumentosrequeridos,
              idPessoa: this.id,
              dataentrega: null,
              validadedocumento: null,
              urldocumento: null,
              validado: 'I'
            }

            if ( kd.documentoEntregue && !kd.documentoEntregue.idClickSign ) {
              let documento = kd.documentoEntregue.urldocumento.split("/")[1]
              console.log('kd.documentoEntregue.urldocumento:', kd.documentoEntregue.urldocumento)
              
              kd.validado = kd.documentoEntregue.validado
              getImagesFromBucket(kd.idPessoa, documento).then(
                response => {
                  kd.documentoEntregue.src = response.data.file
                  kd.loading = false
                  console.log("getImagesFromBucket:", response)
                },
                error => {
                  console.error(error)
                  kd.loading = false
                  kd.error = true
                }
              )
            } else {
              kd.loading = false
            }
           })
        })
      }
    },
    setUploadImage ( e, type, i ) {
      const file = e.target.files[0]
      const name = file.name
      const ext = file.type
      const size = file.size

      this.documentos[type][i].upload.file = file
      this.documentos[type][i].upload.name = name
      this.documentos[type][i].upload.ext = ext
      this.documentos[type][i].upload.size = size
      this.documentos[type][i].upload.ready = true
      this.documentos[type][i].upload.index = null
      this.documentos[type][i]['documentoEntregue'] = {
        src: null,
        fake: true
      }
      this.documentos[type][i].documentoEntregue.src = URL.createObjectURL(file)
      // console.log("setUploadImage:", e)
      // console.log("type:", type)
      // console.log("i:", i)
      // console.log("documentoEntregue:", this.documentos[type][i].documentoEntregue)

      this.allowSubmit()
      // this.convertTo64Bits(e.target.files[0])

    },
    toggleThumb (type, i, state) {
      // console.log("toggleThumb - type:", type)
      // console.log("toggleThumb - i:", i)
      // console.log("toggleThumb - state:", state)
      this.documentos[type][i].opened = state

      setTimeout(() => {
        this.preventThumbToggle = false
      },500)
      console.log("documentos:", this.documentos)
    },
    trackViewportSize () {
      const offset = this.modalmode ? 75 : 0
      console.log("offset:", offset)
      this.vh = this.$refs.docs.clientHeight - offset
      this.vw = this.$refs.docs.clientWidth
    },
    cancel () {
      this.$emit('oncancel')
    },
    createUploadList () {
      const upload = []
      
      if ( Object.keys(this.documentos).length > 0 ){
        Object.keys(this.documentos).map( k => {
          // console.log("k:", k)
          this.documentos[k].map( ( kd , i ) => {
            // console.log("kd:", kd)
            if ( kd.upload?.file ) {
              kd.upload.index = i
              upload.push(kd)
            }
          })
        })
      }

      return upload
    },
    allowSubmit () {
      const allow = this.createUploadList().length > 0
      // console.log("allowSubmit:", allow)
      this.allow = allow
    },
    submit () {
      const list = this.createUploadList()
      // console.log("final list:", list)
      this.uploading = true
      var count = 0

      list.map( k => {
        // console.log("list - k:", k)
        const file = k.upload.file
        const type = k.upload.type
        const index = k.upload.index
        const item = this.documentos[type][index]
        
        item.loading = true
        // console.log("item before:", item)
        toBase64(file).then(
          response => {
            console.log('response:', response)
            const today = moment().subtract(1,'days').format('DD/MM/YYYY')
            k.upload.dataentrega = today
            k.upload.validadedocumento = today
            k.upload.urldocumento = response

            // console.log('k.upload:', k.upload)
            this.uploading = false
            // item.loading = false
            var data 
            if ( k.documentoEntregue ) {
              data = {
                idMatricula: k.upload.idMatricula,
                iddocumentosrequeridos: k.iddocumentosrequeridos,
                idPessoa: k.idPessoa,
                validadedocumento: null,
                validado: k.upload.validado,
                urldocumento: k.upload.urldocumento
              }
              // console.log("data :", data)
            } else {
              data = {
                idMatricula: k.upload.idMatricula,
                iddocumentosrequeridos: k.iddocumentosrequeridos,
                idPessoa: k.idPessoa,
                dataentrega: k.upload.dataentrega,
                validadedocumento: k.upload.validadedocumento,
                urldocumento: k.upload.urldocumento
              }
              // console.log("data:", data)
            }
            // item.upload = null

            AcademicoService.updateDocumento( data.idMatricula, [data] ).then(
              response => {
                console.log('updateDocumento:', response)
                count++
                
                item.documentoEntregue = response.data.documento[0]
                item.documentoEntregue.vinculoMatricula = item.vinculoMatricula
                item.upload.uploaded = true
                this.allow = false

                let documento = item.documentoEntregue.urldocumento.split("/")[1]
                getImagesFromBucket(item.idPessoa, documento).then(
                  response => {
                    item.documentoEntregue.src = response.data.file
                    item.loading = false
                    item.upload.ready = false
                  },
                  error => {
                    console.error(error)
                    item.loading = false
                    item.upload.error = true
                  }
                )
                if ( count == list.length - 1) {
                  // this.getDocumentosByIdMatricula()
                }
                // this.lists = Object.keys(response.data)
                // this.documentos = response.data
                // 
                
              },
              error => {
                console.error(error)
              }
            )
            // return response
          },
          error => {
            console.error(error)
          }
        )
        console.log("file:", file)
      })
    },
    validateDoc (list, index, valid) {
      // console.log("validate - list:", list)
      // console.log("validate - index:", index)
      // console.log("validate - valid:", valid)
      const documento = this.documentos[list][index].documentoEntregue
      const validado = valid === 'approve' ? 'V' : valid === 'reject' ? 'I' : null

      if ( validado ) {
        // console.log("documento:", documento)
        const data = {
          idMatricula: this.id,
          iddocumentosrequeridos: documento.iddocumentosrequeridos,
          iddocumentoEntregue: documento.iddocumentoEntregue,
          idPessoa: documento.idPessoa,
          validado: validado,
          vinculoMatricula: documento.iddocumentosrequeridos == 16 ? 0 : documento.vinculoMatricula //eu não queria esse hard code.. juro
        }
        // console.log("data:", data)

        this.documentos[list][index].validating = true
        AcademicoService.updateDocumento( this.id, [data] ).then(
          response => {
            if ( response.status == 200 ) {
              this.documentos[list][index].validating = false
              documento.validado = valid === 'approve' ? 'V' : 'I'
            }
          },
          error => {
            console.error(error)
            this.documentos[list][index].validating = false
          }
        )
      }  
    },
    docFileName ( file ) {
      console.log("file:", file)
      var parsed
      if ( file.documentoEntregue?.urldocumento ) {
        parsed = file.documentoEntregue.urldocumento.split('/')[1]
      } else {
        parsed = 'contrato_assinado'
      }
      
      // const ext = parsed.split(".")[1]
      // const file = parsed.split(".")[0]
      return parsed
    },
    formatDate ( date ) {
      return formatDate(date)
    },
    isValidado ( documento ) {
      const validado = documento.documentoEntregue?.validado == 'V'
      
      if ( this.auto ) {
        return true
      } else {
        return !validado
      }
      
    },
    isRequired ( documento ) {
      console.log("documento:", documento)

      if ( this.auto ) {
        //
      } else {
        //
      }
    },
    validate () {
      console.log("documentos:", this.documentos)

      const validateAluno = this.documentos.docAluno.filter( k => {
        return !k.documentoEntregue?.iddocumentoEntregue && k.obrigatorio == 1
      })

      const validateResp = this.documentos.docResponsavel.filter( k => {
        return !k.documentoEntregue?.iddocumentoEntregue && k.obrigatorio == 1
      })

      const response = validateResp.length == 0 && validateAluno.length == 0

      if ( !response ) {
        window.toaster.emit('open', {style: "error", message: "Ops. Para continuar você precisa enviar todos os documentos obrigatórios.</b>", floater: true})
      }

      return { response: response, valids: this.documentos }

    },
    getStatusContrato ( documento, i, data ) {
      const idClickSign = documento.documentoEntregue.idClickSign
      console.log("idClickSign:", idClickSign)

      MatriculaService.getContratoClickSign( idClickSign ).then( 
        response => {
          console.log("getContratoClickSign:", response)
          // console.log("data:", data)

          if ( data == 'src' ) {
            this.documentos.docResponsavel[i].documentoEntregue.src = response.data.document.downloads.signed_file_url
          }

          const signer = response.data.document.signers[0]
          console.log("signer:", signer)
          const drop = signer[data]


          if ( data == 'name') {
            console.log("name - drop:", drop)
            this.documentos.docResponsavel[i].documentoEntregue['nomeAssinatura'] = drop
          }
          if ( data == 'created_at' ) {
            console.log("created_at - drop:", drop)
            this.documentos.docResponsavel[i].documentoEntregue['created_at'] = moment(drop).format("DD/MM/YYYY")
          }
          
        },
        error => {
          console.log("error:", error)
        }
      )
    },
    triggerTip ( branch, field ) {
      const tip = {
        branch: branch,
        field: field
      }
      window.mattip.emit("add", tip)
    },
    confirmResendContract () {
      this.$refs.confirm.run({
        message: "Tem certeza que deseja reenviar o contrato?",
        obs: "Esta ação excluirá o contrato atual, mesmo que ele já esteja assinado.",
        confirmButton: "Sim",
        denyButton: "Não",
        callback: this.resendContract,
      })
    },
    resendContract ( response ) {
      console.log("resendContract:", response)

      if ( response ) {
        window.spinner.emit('open')
        MatriculaService.reenviaContratoClickSign( this.id ).then(
          response => {
            window.toaster.emit('open', {style: "success", message: "Contrato reenviado com sucesso.", floater: true})
            setTimeout( () => {
              this.getDocumentosByIdMatricula()
            },1000 ) 
            console.log("response:", response.data)
            window.spinner.emit('close')
          },
          error => {
            window.toaster.emit('open', {style: "error", message: "Ops. Não foi possível reenviar este contrato.", floater: true})
            console.log("error:", error)
            window.spinner.emit('close')
          }
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .documentos {
    header {
      display: block;
      width: 100%;
      padding: 0;
    }

    form {
      position: relative;
      >div {
        margin: $hmg 0;

        ul {
          display: block; width: 100%;
          padding: 0; margin: 0;

          li {
            list-style: none;
            display: flex; height: 80px; width: 100%;
            align-content: center; align-items: center;
            margin-top: $mg_mini;
            @extend %transition_4e;

            >div{
              @extend %transition_4e;

              &.info {
                flex: 1; height: 100%;
                display: flex; align-content: center; align-items: center;
                justify-content: space-between;
                border-radius: $border-radius; background-color: $color-secondary;
                margin-right: $mg_mini;
                padding: 0 $hmg;
                position: relative;
                
                @media screen and ( max-width: 640px ) {
                  padding: 0 $mg_mini;
                }

                .statusMarker {
                  display: flex; width: 22px; height: 22px; min-width: 22px; min-height: 22px; max-width: 22px; max-height: 22px;
                  border-radius: 100%; flex-direction: column; align-content: center; align-items: center; justify-content: center;
                  text-align: center;
                  @extend %transition_4e;

                  &.enviado {
                    background-color: $color-success-2;
                  }
                  &.pendente {
                    background-color: $color-primary;
                  }

                  .icon {
                    font-size: 12px;
                    line-height: 1;
                    color: $color-secondary
                  }
                  
                  @media screen and ( max-width: 640px ) {
                    margin-left: -21px
                  }
                }

                >div {
                  display: flex; align-content: flex-start; align-items: center;
                  @extend %transition_4e;
                  position: relative;

                  >div {
                    margin: 0 0 0 $hmg;

                    p {
                      padding: 0; margin: 0;
                      font-size: 16px;
                      line-height: 1;
                    }
                    label {
                      padding: 0; margin: 0;
                      font-size: 11px;
                      line-height: 1;
                    }
                  }

                  @media screen and ( max-width: 640px ) {
                    >div {
                      margin: 0 0 0 $hmg_small;
                      p {
                        font-size: 13px; padding-right: $hmg_mini;
                      }
                    }
                  }
                }

                .image-src-label {
                  margin: 0 $mg_mini 0 0;
                  font-size: 13px; text-align: right;
                  display: flex; align-content: center; align-items: center;
                  height: 40px; border-radius: 2em;
                  background-color: $color-secondary;
                  padding: 0 $hmg_small;
                  @extend %transition_4e;

                  > .icon {
                    margin-right: $hmg_mini;
                    font-size: 16px;
                  }
                }

                .file-button {
                  display: block; width: 100px; height: 40px;
                  position: relative; margin: 0; padding: 0;
                  @extend %transition_4e;

                  span {
                    display: block; width: 100%; height: 100%;
                    position: relative;
                    border-radius: 2em; background-color: $color-primary;
                    z-index: 10;
                    @extend %transition_4e;
                  }

                  p {
                    font-size: 16px; text-align: center;
                    position: absolute; left: 0; right: 0; top: 50%;
                    transform: translateY(-50%);
                    margin: 0 auto; padding: 0;
                    color: $color-bg;
                    z-index: 11;
                  }

                  input {
                    appearance: none; display: none;
                  }

                  &:hover {
                    span {
                      transform: scale(1.05);
                    }
                  }

                  @media screen and ( max-width: 640px ) {
                    width: 75px; height: 30px;

                    p {
                      font-size: 13px
                    }
                  }
                }
              }

              &.thumb {
                flex: 0; width: 80px; height: 80px; max-width: 80px; min-width: 80px;
                border-radius: $border-radius; background-color: $color-secondary;
                @extend %transition_4e;

                >div {
                  width: 100%; height: 100%;
                  display: flex; flex-direction: column;
                  align-content: center; align-items: center; justify-content: center;
                  font-size: 34px;

                  &:not(.pic) {

                  }
                  &.pic {
                    padding: $mg_mini;

                    img {
                      display: block; width: auto; height: auto;
                      max-width: 100%; max-height: 100%;
                    }
                  }
                }

                header {
                  width: 100%;
                  display: flex; align-content: center; align-items: flex-start;
                  justify-content: space-between;
                  position: absolute; top: $hmg; left: 0;
                  padding: 0 $hmg;

                  p{
                    margin: 0;
                  }
                  a {
                    font-size: 28px;

                    .icon {
                      @extend %transition_4e;
                      transform-origin: center;
                    }

                    &:hover{
                      transform: scale(1.1);
                      color: $color-primary;
                    }
                  }
                }
                
                &.opened {
                  position: absolute;
                  width: 100%; min-width: 100%;
                  left: 0; right: 0; top: 0; 
                  margin: auto; padding: 80px $hmg $hmg $hmg;
                  z-index: 1000;
                  // background-color: $color-secondary !important;
                  // border-radius: $border-radius-larger;
                  @extend %shadow-mid;
                }
              }

              &.validation {
                width: auto; height: 80px; margin-left: $mg_mini;
                border-radius: $border-radius; background-color: $color-secondary;
                display: flex; align-items: stretch; align-content: stretch;
                overflow: hidden;

                a {
                  flex: 1; width: 70px; height: 100%;
                  display: flex; align-items: center; align-content: center;
                  justify-content: center; flex-direction: column;
                  font-size: 28px;

                  &:hover {
                    color: $color-secondary;

                    &.approved {
                      background-color: $color-success-2;
                    }
                    &.rejected {
                      background-color: $color-primary;
                    }
                  }

                  &.disabled {
                    opacity: .5; pointer-events: none;
                  }
                }
                &.approved {
                  a {
                    &.approved {
                      pointer-events: none;
                      background-color: $color-success-2;
                      color: $color-secondary
                    }
                    &.rejected {
                      display: none;
                    }
                  }   
                }
                &.rejected {
                  a {
                    &.rejected {
                      pointer-events: none;
                      background-color: $color-primary;
                      color: $color-secondary
                    }
                    &.approved {
                      display: none;
                    }
                  }   
                }
              }
            }

            .fileDownloadButton {
              display: block; width: 40px; height: 40px;
              position: relative; text-align: center;
              margin-right: $hmg_small;
              @extend %transition_4e;

              .icon {
                position: absolute; left: 0; right: 0;
                top: 50%; transform: translateY(-50%);
                margin: auto; text-align: center;
                font-size: 16px; z-index: 11;
              }

              span {
                display: block; width: 100%; height: 100%;
                border-radius: 100%; background-color: $color-bg;
                @extend %transition_4e; z-index: 10;
                position: relative;

                @media screen and ( max-width: 640px ) {
                  display: block;
                  overflow: hidden;
                  max-width: 50%;
                  text-overflow: ellipsis;
                }
              }

              &:hover {
                span {
                  transform: scale(1.1);
                }
              }
            }

            &.invert {
              .fileDownloadButton {
                span {
                  background-color: $color-secondary;
                }
              }
              >div{
                &.info {
                  background-color: $color-bg;

                  .image-src-label {
                    background-color: $color-secondary;
                    >span {
                      @media screen and ( max-width: 640px ) {
                        display: block;
                        overflow: hidden;
                        max-width: 50%;
                        text-overflow: ellipsis;
                      }
                    }
                  }
                }
                &.thumb {
                  background-color: $color-bg;
                }
                &.validation {
                  background-color: $color-bg;
                }
              }
            }
          }
        }
      }
    }

    .content-spinner {
      padding: $hmg; margin: auto;
      text-align: center;

      >div {
        margin: auto;
      }
    }
  }

  .empty {
    text-align: center;

    .icon {
      font-size: 64px;
      margin-bottom: $hmg;
    }
  }

  .requiredIcon {
    color: $color-primary;
  }

  .toggleResendContract {
    // position: absolute; right: -$hmg; top: 50%;
    // transform: translate(100%, -50%);

  }
</style>