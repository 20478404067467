
import axios from 'axios'
import authHeader from './auth-header'

class SearchService {
  ApolloSearch ( query ) {
    return axios.post(process.env.VUE_APP_API_URL + 'busca/full/text/' + query, {}, { headers: authHeader() })
  }
}

export default new SearchService()