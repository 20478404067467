<template>
  <section class="mat-main" v-if="matricula">
    <header>
      <p>Etapa {{ step.index + 1 }} de {{ totalSteps }}</p>
      <h4>Dados de Filiação</h4>
      <p 
        v-if="
          this.matricula.Filiacao.mae.Responsavel.financeiro || 
          this.matricula.Filiacao.mae.Responsavel.pedagogico ||
          this.matricula.Filiacao.pai.Responsavel.financeiro || 
          this.matricula.Filiacao.pai.Responsavel.pedagogico
        ">
        Confirme se os dados dos responsáveis financeiro e pedagógico estão corretos. Caso algum dado esteja incorreto, por favor, atualize.</p>
    </header>
    <section class="form-wrap">
      <h5 class="color-text">{{ step.index + 1 }}.1. Informações Pessoais da Filiação 1</h5>
      <pessoas branch="Filiacao" child="mae" @onload="clearLoading('pessoa1')" ref="pessoa1" @populateEndereco="populateEndereco($event,'endereco1Data')" v-if="loaded" :trackResp="!this.auto" :action="action" :auto="auto" :highrequire="auto"></pessoas>
    </section>
    <section class="form-wrap">
      <h5 class="color-text">{{ step.index + 1 }}.2. Endereço da Filiação 1</h5>
      <enderecos branch="Filiacao" child="mae" @onload="clearLoading('endereco1')" ref="endereco1" :autoPopulate="endereco1Data" v-if="loaded" :action="action"></enderecos>
    </section>
    <section class="form-wrap" v-if="action != 'ver'">
      <label class="check-control">
        <input type="checkbox" v-model="matricula.Filiacao.semPai" class="check"/>
        <span>Não quero declarar segunda filiação estando de acordo com as responsabilidades financeiras e pedagógicas envolvidas.</span>
      </label>
    </section>
    <section class="form-wrap" v-if="!matricula.Filiacao.semPai">
      <h5 class="color-text">{{ step.index + 1 }}.3. Informações Pessoais da Filiação 2</h5>
      <pessoas branch="Filiacao" child="pai" @onload="clearLoading('pessoa2')" ref="pessoa2" @populateEndereco="populateEndereco($event,'endereco2Data')" v-if="loaded" :trackResp="!this.auto" :action="action" :auto="auto" :highrequire="auto"></pessoas>
    </section>
    <section class="form-wrap" v-if="!matricula.Filiacao.semPai">
      <h5 class="color-text">{{ step.index + 1 }}.4. Endereço da Filiação 2</h5>
      <enderecos branch="Filiacao" child="pai" @onload="clearLoading('endereco2')" ref="endereco2" :autoPopulate="endereco2Data" v-if="loaded" :action="action"></enderecos>
    </section>
  </section>
</template>

<script>
import Pessoas from '@/components/matriculas/form/Pessoas'
import Enderecos from '@/components/matriculas/form/Enderecos'
import MatriculaService from '@/services/matricula.service'
import { unformatDate } from '@/services/utils.service'

export default {
  components: {
    Pessoas, Enderecos
  },
  props: {
    ready: {
      type: Boolean,
      default: false
    },
    idMatricula: {
      type: [String, Number]
    },
    auto: {
      type: Boolean,
      default: false
    },
    action: {
      type: String
    },
    step: {
      type: Object
    },
    totalSteps: {
      type: Number
    }
  },
  data () {
    return {
      noFiliacao2: false,
      matricula: null,
      wait: {
        pessoa1: false,
        endereco1: false,
        pessoa2: false,
        endereco2: false
      },
      endereco1Data: null,
      endereco2Data: null,
      loaded: false
    }
  },
  mounted () {
    this.$store.dispatch('matricula/get').then(response => {
      this.matricula = response
      
      console.log("sem pai:", this.matricula.Filiacao.semPai)
      if ( this.matricula.Filiacao.semPai ) {
        this.wait.pessoa2 = true
        this.wait.endereco2 = true
      }
    })
  },
  methods: {
    validate () {
      var response, validPessoa1, validEndereco1, validEndereco2, validPessoa2

      validPessoa1 = this.$refs.pessoa1.validate()
      validEndereco1 = this.$refs.endereco1.validate()

      if ( !this.matricula.Filiacao.semPai ) {
        validEndereco2 = this.$refs.endereco2.validate()
        validPessoa2 = this.$refs.pessoa2.validate()

        response = validPessoa1.response && validPessoa2.response && validEndereco1.response && validEndereco2.response
      } else {
        response = validPessoa1.response && validEndereco1.response
      }
      
      var invalids = []
      var hasError = false

      if ( !validPessoa1.response || !validEndereco1.response ) {
        Object.keys(validPessoa1.valids).map(k => {
          if ( !validPessoa1.valids[k] ) {
            invalids.push(k)
          }
        })

        Object.keys(validEndereco1.valids).map(k => {
          if ( !validEndereco1.valids[k] ) {
            invalids.push(k)
          }
        })

        hasError = true
      }
      
      if ( !this.matricula.Filiacao.semPai ) {
        if ( !validPessoa2.response || !validEndereco2.response ) {
          Object.keys(validPessoa2.valids).map(k => {
            if ( !validEndereco1.valids[k] ) {
              invalids.push(k)
            }
          })

          Object.keys(validEndereco2.valids).map(k => {
            if ( !validEndereco2.valids[k] ) {
              invalids.push(k)
            }
          })

          hasError = true
        }
      }

      if ( hasError ) {
        var msg = invalids.join(', ')
        window.toaster.emit('open', {style: "error", message: "Existem campos não preenchidos: <b>" + msg + "</b>", floater: true})
      }

      return { response: response }
    },
    clearLoading ( target ) {
      this.wait[target] = true

      this.stopSpinner()
    },
    stopSpinner () {
      const done = !Object.values(this.wait).includes(false)
      if ( done )  {
        window.spinner.emit("close")
        this.$emit('ready')
      }
    },
    populateEndereco ( data, target ) {
      this[target] = data
    },
    get () {
      this.$store.dispatch('matricula/get').then(response => {
        this.matricula = response
        // console.log("get filiacao - matricula response:", response)
        // console.log("idMatricula:", this.idMatricula)
        if ( this.idMatricula ) {
          MatriculaService.getFiliacao( this.idMatricula ).then(
            response => {
              console.log("getFiliacao:", response.data)
              if ( response.data && Object.keys(response.data).length > 0 ) {
                if ( response.data.pessoa.mae ) {
                  this.matricula.Filiacao.mae.Basicos = response.data.pessoa.mae.Basicos ? response.data.pessoa.mae.Basicos : this.matricula.Filiacao.mae.Basicos
                  this.matricula.Filiacao.mae.Documento = response.data.pessoa.mae.Documento ? response.data.pessoa.mae.Documento : this.matricula.Filiacao.mae.Documento
                  this.matricula.Filiacao.mae.Endereco = response.data.pessoa.mae.Endereco ? response.data.pessoa.mae.Endereco : this.matricula.Filiacao.mae.Endereco
                  this.matricula.Filiacao.mae.Responsavel = response.data.pessoa.mae.Responsavel ? response.data.pessoa.mae.Responsavel : this.matricula.Filiacao.mae.Responsavel

                  this.matricula.Filiacao.mae.Basicos.dataNascimento = response.data.pessoa.mae.Basicos.dataNascimento ? unformatDate(response.data.pessoa.mae.Basicos.dataNascimento) : this.matricula.Filiacao.mae.Basicos.dataNascimento
                  
                  if ( !this.matricula.Filiacao.mae.Basicos.nomeDocumento ) {
                    this.matricula.Filiacao.mae.Basicos.nomeDocumento = this.matricula.Filiacao.mae.Basicos.nome
                  }

                  if ( this.matricula.Filiacao.mae.Responsavel ) {
                    this.matricula.Filiacao.mae.Responsavel.financeiro = this.matricula.Filiacao.mae.Responsavel.financeiro == 1 
                    this.matricula.Filiacao.mae.Responsavel.pedagogico = this.matricula.Filiacao.mae.Responsavel.pedagogico == 1 
                  }
                }
                if ( response.data.pessoa.pai ) {
                  this.matricula.Filiacao.pai.Basicos = response.data.pessoa.pai.Basicos ? response.data.pessoa.pai.Basicos : this.matricula.Filiacao.pai.Basicos
                  this.matricula.Filiacao.pai.Documento = response.data.pessoa.pai.Documento ? response.data.pessoa.pai.Documento : this.matricula.Filiacao.pai.Documento
                  this.matricula.Filiacao.pai.Endereco = response.data.pessoa.pai.Endereco ? response.data.pessoa.pai.Endereco : this.matricula.Filiacao.pai.Endereco
                  this.matricula.Filiacao.pai.Responsavel = response.data.pessoa.pai.Responsavel ? response.data.pessoa.pai.Responsavel : this.matricula.Filiacao.pai.Responsavel

                  this.matricula.Filiacao.pai.Basicos.dataNascimento = response.data.pessoa.pai.Basicos.dataNascimento ? unformatDate(response.data.pessoa.pai.Basicos.dataNascimento) : this.matricula.Filiacao.pai.Basicos.dataNascimento

                  if ( !this.matricula.Filiacao.pai.Basicos.nomeDocumento ) {
                    this.matricula.Filiacao.pai.Basicos.nomeDocumento = this.matricula.Filiacao.pai.Basicos.nome
                  }

                  if ( this.matricula.Filiacao.pai.Responsavel ) {
                    this.matricula.Filiacao.pai.Responsavel.financeiro = this.matricula.Filiacao.pai.Responsavel.financeiro == 1
                    this.matricula.Filiacao.pai.Responsavel.pedagogico = this.matricula.Filiacao.pai.Responsavel.pedagogico == 1
                  }

                } else {
                  this.matricula.Filiacao.semPai = true
                  this.wait.pessoa2 = true
                  this.wait.endereco2 = true
                }
              } else {
                this.$nextTick(() => {
                  this.$emit('ready')
                  this.loaded = true
                })
              }
              // this.matricula.Aluno.Aluno = response.data.aluno.Aluno
              // this.matricula.Aluno.Basicos = response.data.aluno.Basicos
              // this.matricula.Aluno.Basicos.dataNascimento = unformatDate(this.matricula.Aluno.Basicos.dataNascimento)
              // this.matricula.matricula = response.data.matricula

              // if ( !this.matricula.Aluno.Basicos.nomeDocumento ) {
              //   this.matricula.Aluno.Basicos.nomeDocumento = this.matricula.Aluno.Basicos.nome
              // }
              
              
              // this.ready = true
              this.$store.dispatch('matricula/save').then(response => {
                console.log(response)
                
                this.$nextTick(() => {
                  this.$emit('ready')
                  this.loaded = true
                })
                
              })
              
            },
            error => {
              console.error(error)
            }
          )
        
        } else {
          this.loaded = true
          const done = !Object.values(this.wait).includes(false)
          if ( done )  {
            this.$emit('ready')
          }
        }
        
      })
      
    },
  }
}
</script>

<style lang="scss" scoped>

</style>