<template>
  <section class="super-chat">
    <header>
      <header>
        <h4>
          Edição de Conteúdo
        </h4>
        <a href="#" @click.prevent="voltar()" class="btn btn-bg">
          <fa :icon="['far', 'arrow-left-long']" />&nbsp;
          Voltar
        </a>
      </header>
      <div class="pre">
        <label>
          Origem da Informação
        </label>
        <p v-if="conteudo">
          {{ conteudo.descricao }}
        </p>
      </div>
      <div class="content-tabs">
        <nav>
          <a href="#" @click.prevent="setActive('conteudo')" :class="{ active: active == 'conteudo' }">
            Teoria (Eu)
          </a>
          <a href="#" @click.prevent="setActive('dicasAula')" :class="{ active: active == 'dicasAula' }">
            Prática (Nós)
          </a>
          <a href="#" @click.prevent="setActive('questoes')" :class="{ active: active == 'questoes' }">
            Questões (Eles)
          </a>
        </nav>
      </div>
    </header>
    <div class="content">
      <textarea v-model="content" :class="{ disabled: loading }"></textarea>
    </div>
    <footer>
      <div class="prompts">
        <select-control 
          :options="options.prompts" 
          name="prompts"
          v-model="prompt"
          placeholder="Selecione"
          cssClass="invert"
          @change="emitPrompt"
          :disabled="fineTunning.length > 0"
        />
      </div>
      <textarea 
        placeholder="Quer refinar o conteúdo? Digite aqui as diretrizes ou apenas clique em regerar e deixe que nós cuidamos disso..." 
        v-model="fineTunning" 
        :class="{ active: fineTunning.length > 0 }"
        :disabled="prompt && prompt != 'null'"
      ></textarea>
      <div>
        <a href="#" @click.prevent="refine()" class="btn btn-bg btn-large">
          Regerar
        </a>
        <a href="#" @click.prevent="save()" class="btn btn-primary btn-large">
          Salvar
        </a>
      </div>
    </footer>
  </section>
</template>

<script>
import EstudAIService from '@/services/estudai.service.js'
import { createSelectOptions } from '@/services/utils.service.js'

export default {
  props: {
    scope: {
      type: Object
    }
  },
  data () {
    return {
      conteudo: null,
      content: null,
      active: null,
      fineTunning: "",
      loading: false,
      prompt: null,
      options: {
        prompts: []
      }
    }
  },
  mounted () {
    this.conteudo = Object.assign({}, this.scope)
    console.log("conteudo:", this.conteudo)
    this.setActive('conteudo')
    this.getPrompts()
  },
  methods: {
    setActive ( activate ) {
      console.log("activate:", activate)

      this.active = activate
      this.content = this.conteudo[activate]
    },
    voltar () {
      this.$emit("onReturn")
    },
    refine () {
      const data = {
        idConteudo: this.conteudo.idConteudo,
        tipo: this.active,
        texto: this.fineTunning,
        prompt: this.prompt
      }

      window.spinner.emit("open")
      this.loading = true
      EstudAIService.refineConteudo(data).then(
        response => {
          console.log("response", response)
          
          if ( response.data ) this.content = response.data[0][this.active]
          window.spinner.emit("close")
          this.loading = false
        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops. Algo deu errado. Não foi possível acessar a API EstudAI", floater: true})
          window.spinner.emit("close")
          this.loading = false
        }
      )
    },
    save () {
      const data = {
        idConteudo: this.conteudo.idConteudo,
        tipo: this.active,
        texto: this.content
      }

      window.spinner.emit("open")
      this.loading = true
      EstudAIService.saveConteudo(data).then(
        response => {
          console.log("response", response)
          if ( response.data ) this.content = response.data[0][this.active]
          window.spinner.emit("close")
          this.loading = false
        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops. Algo deu errado. Não foi possível acessar a API EstudAI", floater: true})
          window.spinner.emit("close")
          this.loading = false
        }
      )
    },
    getPrompts () {
      window.spinner.emit("open")
      EstudAIService.getPrompts().then(
        response => {
          console.log("getPrompts:", response.data)
          const empty = {
            descricao: 'Nenhum',
            idPromptConteudos: 'null'
          }
          const drop = response.data
          drop.push(empty)

          this.options.prompts = createSelectOptions(drop, 'idPromptConteudos' ,'descricao')
          window.spinner.emit("close")
        },
        () => {
          window.spinner.emit("close")
        }
      )
    },
    emitPrompt() {
      console.log("prompt:", this.prompt)
    }
  }
}
</script>

<style lang="scss" scoped>
  .super-chat {
    display: flex; height: 100vh;
    width: 100%;
    position: absolute; top: 0; left: 0;
    overflow: hidden;
    flex-direction: column;
    align-content: stretch; align-items: stretch;

    >header {
      display: flex; flex-direction: column;
      justify-content: space-between;
      padding: $hmg $hmg 0;
      background-color: $color-secondary;
      min-height: 180px; max-height: 25%;
      
      .pre {
        margin-top: $hmg_small;
        border-left: 4px solid $color-bg;
        padding-left: $mg_mini;

        label {
          margin: 0;
          font-size: 12px;
          font-weight: bold;
        }
        p {
          margin: 0;
          padding: 0;
          font-size: 1.2rem
        }
      }

      header {
        display: flex;
        align-items: center;
        align-content: center;

        h4 {
          margin: 0
        }
        .btn {
          margin-left: $hmg
        }
      }
      .content-tabs {
        margin-top: $mg_mini;

        nav {
          display: flex;
          align-items: flex-end;
          height: 50px;

          a {
            padding: $mg_mini $hmg_small;
            line-height: 1;
            border-top-left-radius: $border-radius-small;
            border-top-right-radius: $border-radius-small;
            margin-right: $hmg_mini;
            background-color: $color-primary;
            @extend %transition_4e;
            color: $color-secondary;
            transform-origin: bottom center;

            &:hover {
              padding: $mg_mini $hmg_small $hmg_small;
            }
            &.active {
              padding: $mg_mini $hmg_small $hmg_small;
              font-weight: bold;
            }
          }
        }
      }
    }
    .content {
      padding: 0;
      flex: 1;
      background-color: $color-bg;
      
      textarea {
        width: 100%; height: 100%;
        margin: auto;
        font-size: 15px;
        color: $color-text;
        background-color: $color-bg;
        border: 0; outline: 0;
        @extend %scrollbar;
        @extend %transition_4o;
        padding: $mg 10rem;
        box-shadow: none;
        resize: none;

        &:focus {
          border: 0; outline: 0;
          background-color: $color-fade
        }
        &.disabled {
          opacity: .7;
          background-color: $color-fade;
        }
      }
    }

    footer {
      display: flex;
      align-items: center;
      height: 100px; width: calc(100% - $mg_large);
      padding: $hmg_mini;
      background-color: $color-secondary;
      border-top-right-radius: $border-radius-larger;
      @extend %transition_4e;

      textarea {
        width: 100%; height: 50px;
        border: 0; outline: 0;
        padding: $hmg_small $hmg;
        background-color: transparent;
        color: $color-text;
        font-size: 15px;
        @extend %scrollbar;
        @extend %transition_4e;
        transform-origin: center;
        resize: none;
        overflow: hidden;
        text-overflow: ellipsis;

        &::placeholder {
          opacity: .5
        }

        &:focus, &.active {
          overflow: auto;
          height: 100%;
          text-overflow: none;

          &::placeholder {
            opacity: .7
          }
        }

        &:disabled {
          &::placeholder {
            opacity: .3
          }
        }
      }

      div:not(.prompts) {
        display: flex;
        align-content: center; align-items: center;
        padding: $hmg_small $hmg;
        .btn {
          margin-left: $hmg_small
        }
      }
    }
  }
  .prompts {
    min-width: 240px
  }
</style>