<template>
  <div class="emptyList">
    <fa :icon="['fal', 'face-smile-relaxed']" class="icon"></fa>
    <h4>Não existem itens com este status</h4>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  .emptyList {
    padding: $hmg;
    text-align: center;
    .icon {
      font-size: 64px;
    }
    h4 {
      color: $color-text;
      text-align: center;
      margin-top: $hmg
    }
  }
</style>