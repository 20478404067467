<template>
  <div class="row no-padd" ref="cursoForm">
    <template v-if="matricula">
      <div class="col-md-8 form-group no-padd-left" >
        <label>Unidade</label>
        <select-control
          v-model="matricula.matricula.idUnidade" 
          name="unidade" placeholder="Selecione" 
          :options="options.unidades" 
          :disabled="loadingFilterData || auto || ( action == 'nova' && idMatricula && !editTurmaRemat ) || ( action == 'editar' && !editTurmaRemat )" :valid="valid.unidade" 
          @change="getPeriodosLetivos(null,true)" 
          @keyup="valid.unidade = true"
          required
        />
      </div>
      <div class="col-md-4 form-group no-padd-left">
        <label>Período Letivo</label>
        <select-control
          v-model="matricula.matricula.idPeriodoLetivo" 
          name="periodoLetivo" placeholder="Selecione"
          :options="options.periodosLetivos"
          :disabled="loadingFilterData || auto || ( action == 'nova' && idMatricula && !editTurmaRemat ) || ( action == 'editar' && !editTurmaRemat )" :valid="valid.periodoLetivo"          
          @change="getSeries(null, null, true)"
          @keyup="valid.periodoLetivo = true"
          required
        />
      </div>
      <div class="col-md-8 form-group no-padd-left">
        <label>Série</label>
        <select-control 
          v-model="matricula.matricula.idSerie" 
          name="serie" placeholder="Selecione"
          :options="options.series"
          :disabled="loadingFilterData || auto || ( action == 'nova' && idMatricula && !editTurmaRemat ) || ( action == 'editar' && !editTurmaRemat )" :valid="valid.serie"
          @change="getTurmas()" 
          @keyup="valid.serie = true"
          required
        />
      </div>
      <div class="col-md-4 form-group no-padd-left">
        <label>Turmas</label>
        <select-control 
          v-model="matricula.matricula.idTurma" 
          name="turma" placeholder="Selecione"
          :options="options.turmas"
          :disabled="loadingFilterData || auto || ( action == 'nova' && idMatricula && !editTurmaRemat ) || ( action == 'editar' && !editTurmaRemat )" :valid="valid.turma"
          @keyup="valid.turma = true"
          required
        />
      </div>
      <div class="col-md-4 form-group no-padd-left">
        <label>Forma de Ingresso</label>
        <select-control
          v-model="matricula.matricula.primeiraMatricula" 
          name="primeiraMatricula"
          :options="options.primeiraMatricula"
          :disabled="loadingFilterData || auto || ( action == 'nova' && idMatricula && !editTurmaRemat ) || ( action == 'editar' && !editTurmaRemat )" :valid="valid.primeiraMatricula"
          @keyup="valid.primeiraMatricula = true"
        />
      </div>
    </template>
  </div>
</template>

<script>
import UserService from '@/services/user.service'
import AcademicoService from '@/services/academico.service'
import FormService from '@/services/form.service'
import { createSelectOptions } from '@/services/utils.service'

export default {
  props: {
    auto: {
      type: Boolean,
      default: false
    },
    action: {
      type: String
    },
    idMatricula: {
      type: [String, Number]
    }
  },
  data () {
    return {
      options:{
        unidades: [],
        periodosLetivos: [],
        series: [],
        turmas: [],
        primeiraMatricula: [
          {
            label: 'Matrícula',
            value: "1"
          },
          {
            label: 'Rematrícula',
            value: "0"
          }
        ]
      },
      wait: {
        unidades: false,
        periodosLetivos: false,
        series: false,
        turmas: false
      },
      valid: {
        unidade: true,
        periodosLetivo: true,
        serie: true,
        turma: true,
        primeiraMatricula: true
      },
      unidade: null,
      periodoLetivo: null,
      serie: null,
      turma: null,
      loading: false,
      loadingFilterData: false,
      matricula: null
    }
  },
  mounted () {
    window.spinner.emit("open")

    this.editTurmaRemat = UserService.filterPermissions('editTurmaRemat')


    this.$store.dispatch('matricula/get').then(response => {
      this.matricula = response

      this.getUnidades(false)
      this.getPeriodosLetivos(this.matricula.matricula.idUnidade, false)
      this.getSeries(this.matricula.matricula.idPeriodoLetivo, 0, false)
      this.getTurmas(this.matricula.matricula.idSerie)
      
    })
  },
  methods: {
    getUnidades (clear) {
      UserService.getUnidades().then(
        response => {
          console.log("getMinhasUnidades:", response.data)
          this.wait.unidades = true
          this.options.unidades = createSelectOptions(response.data, 'idUnidade' ,'descricao')

          if ( clear ) {
            this.clear('idPeriodoLetivo')
            this.clear('idSerie')
            this.clear('idTurma')
          }
          
          this.stopSpinner()
        },
        error => {
          console.error(error)
        }
      )
    },
    getPeriodosLetivos ( unidade, clear ) {
      unidade = unidade ? unidade : this.matricula.matricula.idUnidade
      this.loadingFilterData = true
      AcademicoService.getPeriodosLetivos( unidade ).then(
        response => {
          console.log("getPeriodosLetivos:", response.data)
          this.options.periodosLetivos = createSelectOptions(response.data, 'idPeriodoLetivo' ,'descricao').reverse()
          this.wait.periodosLetivos = true
          this.loadingFilterData = false
          if ( clear ) {
            this.clear('idSerie')
            this.clear('idTurma')
          }
          this.stopSpinner()
        },
        error => {
          console.error(error)
          this.loadingFilterData = false
        }
      )
    },
    getSeries ( periodoLetivo, tipoCurso, clear ) {
      this.loadingFilterData = true
      // const tipoCurso = 
      periodoLetivo = periodoLetivo ? periodoLetivo : this.matricula.matricula.idPeriodoLetivo
      tipoCurso = tipoCurso ? tipoCurso : 0

      AcademicoService.getSeries(periodoLetivo, tipoCurso).then(
        response => {
          console.log("getSeries:", response.data)

          if ( response.data.length > 0 ) {
            this.series = response.data
            response.data.map( k => {
              k.label = k.descricao + ' ' + k.descricaoTurno
            })
            this.options.series = createSelectOptions(response.data, 'idSerie' ,'label')

            this.wait.series = true
            this.loadingFilterData = false
            if ( clear ) {
              this.clear('idTurma')
            }
            this.stopSpinner()
          }
        },
        error => {
          console.error(error)
          this.loadingFilterData = false
        }
      )
    },
    getTurmas ( serie ) {
      this.loadingFilterData = true
      serie = serie ? serie : this.matricula.matricula.idSerie
      
      AcademicoService.getTurmas(serie).then(
        response => {
          // console.log("getTurmas:", response.data)
          this.options.turmas = createSelectOptions(response.data, 'idTurma' ,'descricao')

          this.wait.turmas = true
          this.loadingFilterData = false

          this.stopSpinner()
        },
        error => {
          console.error(error)
          this.loadingFilterData = false
        }
      )
    },
    stopSpinner () {
      const done = !Object.values(this.wait).includes(false)
      console.log("done:", done)
      if ( done ) {
        window.spinner.emit("close")
        this.loading = false
      }
    },
    validate () {
      const valid = FormService.validate(this.$refs.cursoForm, this.valid)
      return valid
    },
    clear ( target ) {
      console.log("clear:", target)
      this.matricula.matricula[target] = null
    }
  }
}
</script>

<style lang="scss" scoped>

</style>