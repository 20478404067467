<template>
  <section class="filter">
    <form-spinner v-if="loading"/>
    <header>
      <h4>
        Filtrar
      </h4> 
    </header>
    <div class="frame fixed">
      <div class="row no-padd" ref="solcitacoesForm">
        <div class="col-md-8 form-group">
          <label>Aluno ou Responsável</label>
          <input-control v-model="filter.nomePessoa" name="nomePessoa" placeholder="Nome" :disabled="loading" @keyup.enter="dropFilter"/>
        </div>
        <div class="col-md-8 form-group">
          <label>Unidade</label>
          <select-control v-model="filter.unidade" name="unidade" :options="options.unidades" placeholder="Selecione" @change="getPeriodosLetivos()" :disabled="loading"/>
        </div>
        <div class="col-md-4 form-group">
          <label>Período Letivo</label>
          <select-control v-model="filter.periodoLetivo" :options="options.periodosLetivos" placeholder="Selecione" :disabled="loading"/>
        </div>
        <div class="col-md-4 form-group">
          <label>ID Cancelamento</label>
          <input-control v-model="filter.idCancelamento" name="id" placeholder="Ex. 00239482" :disabled="loading" @keyup.enter="dropFilter"/>
        </div>
        <div class="col-md-4 form-group">
          <label>Data Início</label>
          <input-control v-model="filter.datainicio" name="datainicio" placeholder="" type="date" :disabled="loading"/>
        </div>
        <div class="col-md-4 form-group">
          <label>Data Fim</label>
          <input-control v-model="filter.datafim" name="datafim" placeholder="" type="date" :disabled="loading"/>
        </div>
        <div class="col-md-12 form-group">
          <label>Status</label>
          <div class="row no-padd status-box">
            <div class="col-md-2 form-group">
              <label class="check-control">
                <input type="checkbox" class="check" value="pendente" name="pendente" v-model="filter.pendente"/>
                <span>Pendentes</span>
              </label>
            </div>
            <div class="col-md-2 form-group">
              <label class="check-control">
                <input type="checkbox" class="check" value="aprovado" name="aprovado" v-model="filter.aprovado"/>
                <span>Aprovadas</span>
              </label>
            </div>
            <div class="col-md-2 form-group">
              <label class="check-control">
                <input type="checkbox" class="check" value="reprovado" name="reprovado" v-model="filter.reprovado"/>
                <span>Reprovadas</span>
              </label>
            </div>
            <div class="col-md-3 form-group">
              <label class="check-control">
                <input type="checkbox" class="check" value="aguardandoEntrega" name="aguardandoEntrega" v-model="filter.aguardandoEntrega"/>
                <span>Aguardando Entrega</span>
              </label>
            </div>
            <div class="col-md-2 form-group">
              <label class="check-control">
                <input type="checkbox" class="check" value="finalizado" name="finalizado" v-model="filter.finalizado"/>
                <span>Finalizadas</span>
              </label>
            </div>
          </div>
        </div>
        
      </div>
      <div class="col-md-12 form-actions form-group">
        <a href="#" @click.prevent="dropFilter()" class="btn btn-primary">
          Buscar
        </a>
        <a href="#" @click.prevent="clearFilter()" class="btn btn-secondary">
          Limpar
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import UserService from '@/services/user.service.js'
import AcademicoService from '@/services/academico.service.js'
import { createSelectOptions } from '@/services/utils.service.js'
import FormSpinner from '@/components/utilities/FormSpinner.vue'

export default {
  components: {
    FormSpinner
  },
  data () {
    return {
      filter: {
        nomePessoa: null,
        unidade: null,
        periodoLetivo: null,
        idCancelamento: null,
        datainicio: null,
        datafim: null,
        pendente: null,
        aprovado: null,
        reprovado: null,
        aguardandoEntrega: null,
        finalizado: null
      },
      wait: {
        unidades: false
      },
      options: {
        unidades: [],
        tipoCurso: [],
        periodosLetivos: [],
        series: [],
        turmas: [],
        cursos: [],
      },
      series: [],
      drop: [],
      loading: true
    }
  },
  mounted () {
    this.getUnidades()

    // window.spinner.emit("open")
  },
  methods: {
    getUnidades () {
      this.loading = true
      UserService.getMinhasUnidades().then(
        response => {
          console.log("getMinhasUnidades:", response.data)          
          if ( response.data.length > 0 ) {
            this.options.unidades = createSelectOptions(response.data, 'idUnidade' ,'descricao')
            this.wait.unidades = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhuma unidade encontrada. Verifique sua conexão e tente novamente.", floater: true})
          }

          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma unidade encontrada. Verifique sua conexão e tente novamente.", floater: true})
        }
      )
    },
    getPeriodosLetivos () {
      this.loading = true
      AcademicoService.getPeriodosLetivos(this.filter.unidade).then(
        response => {
          console.log("getPeriodosLetivos:", response.data)
          
          if ( response.data.length > 0 ) {
            this.options.periodosLetivos = createSelectOptions(response.data, 'idPeriodoLetivo' ,'descricao').reverse()
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum período letivo encontrado para esta unidade. Isto parece ser um problema de parametrização.", floater: true})
          }
          this.loading = false
        },
        error => {
          console.error(error)
          this.loading = false
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum período letivo encontrado para esta unidade. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    dropFilter () {
      this.$emit('onFilter', this.filter)
    },
    finishLoad () {
      const finished = !Object.values(this.wait).some( a => !a)

      if ( finished ) {
        // window.spinner.emit("close")
        this.loading = false
      }
      // console.log("finished:", finished)

    },
    clearFilter () {
      this.filter = {
        nomePessoa: null,
        unidade: null,
        periodoLetivo: null,
        idCancelamento: null,
        datainicio: null,
        datafim: null,
        pendente: null,
        aprovado: null,
        reprovado: null,
        aguardandoEntrega: null,
        finalizado: null
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .filter {
    padding-bottom: $hmg_small;
    border-bottom: $border-component;
  }
  header {
    padding: $hmg_mid;

    h5 {
      margin: 0; padding: 0;
    }
    p {
      margin: 0; padding: 0;
    }
  }

  .frame {
    padding: 0 $hmg_small;
  }

  .form-actions {
    margin-top: $hmg !important
  }

  .status-box {
    background-color: $color-secondary;
    border-radius: $border-radius;

    .form-group {
      margin: 0;
      display: flex; flex-direction: column;
      align-content: flex-start; justify-content: center;
      padding-top: $mg_mini; padding-bottom: $mg_mini;
    }
  }
</style>