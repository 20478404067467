import { reactive } from 'vue'
import io from 'socket.io-client'

export const state = reactive({
    id: '',
    assistant_id:  '',
    connected: false,
    messageEvents: [],
    messageTrigger: [],
    connectTrigger: [],
    systemTrigger: [],
    firstConnect: true
})

// const url = `127.0.0.1:5020`
// const url = `redechat.hom.rededecisao.com:5020`

export const socket = io(process.env.VUE_APP_REDECHAT_SOCKET, {autoConnect: false })

socket.on('connect', () => {
	state.id = socket.id
	state.connected = true
	state.messageEvents = []
	state.connectTrigger[0] = Date.now()
})

socket.on('welcome', () => {
	console.log('messageEvents:', state.messageEvents)
	// state.systemTrigger[0] = { type: 'welcome', iat: Date.now() }
})

socket.on('message-list', (data) => {
    state.messageEvents.push(...data)
    state.messageTrigger[0] = Date.now()
})

socket.on('join', (data) => {
    state.systemTrigger[0] = { type: 'join', data, iat: Date.now() }
})

socket.on('leave', (data) => {
    state.systemTrigger[0] = { type: 'leave', data, iat: Date.now() }
})

socket.on('disconnect', () => {
    state.connected = false
    state.systemTrigger[0] = { type: 'disconnect', iat: Date.now() }
})

socket.on('message', (data) => {
    state.messageEvents.push(data)
    state.messageTrigger[0] = Date.now()
})

socket.on('ai-start', () => {
    state.systemTrigger[0] = { type: 'ai-start', iat: Date.now() }
})

socket.on('ai-end', () => {
    state.systemTrigger[0] = { type: 'ai-end', iat: Date.now() }
})