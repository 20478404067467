<template>
  <controller :lockScroll="true">
    <section class="external">
      <form-spinner v-if="ready"/>
      <div class="">
        <iframe
          :src="src"
          id="iframe"
          frameborder="0"
          allowtransparency="true"
          style="width: 0; min-width: 100% !important;"
          :height="iframeHeight"
          :load="ready == true"
        ></iframe>
      </div>
    </section>
  </controller>
</template>
<script>
import moment from 'moment'
import FormSpinner from '@/components/utilities/FormSpinner.vue'

export default {
  name: "LinkExterno",
  components: {
    FormSpinner
  },
  computed: {
    iframeOffsetWidth() {      
      return this.$refs['chartiframe'].offsetWidth;
    },
  },
  props: {
    id: {
      type: [String, Number]
    }, 
    isMetabase: {
      type: [String, Number]
    }, 
    isUrl: {
      type: [String, Number]
    }
  },
  watch: { 
    $route(to, from) {
      console.log("to:", to)
      console.log("from:", from)
      window.spinner.emit("open")
      this.handleSrc()
      this.loading = true
      // if(to !== from)
      // { 
      //   this.handleSrc()
      // } 
    } 
  },
  data () {
    return {
      focused: false,
      aluno: null,
      // dashId: this.path ? this.path : null,
      responsavel: null,
      filiacao: null,
      unidade: null,
      api: null,
      criar: false,
      criarID: null,
      src:  null,
      iframeHeight: '0px',
      tokenLaravel: process.env.VUE_APP_LARAVEL_API_LOGIN_TOKEN,
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
      loading: false,
      numero_dashboard: 0,
      path: null,
      ready: false
    }
  },
  mounted () {

    this.iframeHeight = `{{
      "100":481,
      "200":427,
      "300":400,
      "400":400,
      "500":400,
      "700":400,
      "800":400,
      "900":400,
      "1000":400,
    }[Math.min(1e3,Math.max(100*Math.floor(this.iframeOffsetWidth/100),100))]}px`;
    window.addEventListener('message', this.handleIframeResize);

    this.handleSrc()
  },
  beforeUnmount() {
    window.removeEventListener('message', this.handleIframeResize);
    // console.log("unmount...")
  },
  methods: {
    handleIframeResize(e) {
      for(var b in e.data['datawrapper-height']) {
        if ('8dfPN' === b) {
          this.iframeHeight = `${e.data['datawrapper-height'][b]}px`;
        }
      }
    },
    getQueryStringParams(query) {      
      var array = query.split('&')
      var result = {}

      console.log("getQueryStringParams:", array)
      // var common = false


      array.map( k => {
        if ( k.includes('%') ) {
          if(k == 'unidade=%unidade') {
            result['unidade'] = this.currentUser.colaborador.sigla
            // result.push({"unidade": this.currentUser.colaborador.sigla})
          }
          if(k == 'email=%email') {
            result['email'] = this.currentUser.colaborador.email
            // result.push({"email": this.currentUser.colaborador.email})
          }
          if(k == 'nome_pessoa=%nome_pessoa') {
            result['nome_pessoa'] = this.currentUser.colaborador.nome
            // result.push({"nome_pessoa": this.currentUser.colaborador.nome})
          }
          if(k == 'data_atual=%data_atual') {
            result['data_atual'] = moment().format('YYYY-MM-DD')
            // result.push({"data_atual": moment().format('YYYY-MM-DD')})
          }

          // common = true
        } else if ( k != "" ) {
          var data = k.split(";")
          data.map( k => {
            const item = k.split("=")

            result[item[0]] = item[1]
          })

          // result = drop
        }

        // console.log("result:", result)
        // console.log("common:", common)

      })

      return result
    },
    handleSrc () {
      var sign = require('jwt-encode')

      this.numero_dashboard = this.$route.query.numero_dashboard
      this.path = this.$route.query.path
      
      console.log("numero_dashboard:", this.numero_dashboard)
      console.log("path:", this.path)

      if ( this.numero_dashboard ) {
        if(this.numero_dashboard != 0 && this.numero_dashboard.split('/')[0] === 'dashboard') {
          var payload = {
            resource: { dashboard: Number(this.numero_dashboard.split('/')[1]) },
            params: this.path ? this.getQueryStringParams(this.path) : {},
            exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
          }
        }
        
        if(this.numero_dashboard != 0 && this.numero_dashboard.split('/')[0] === 'question') {
          payload = {
            resource: { question: Number(this.numero_dashboard.split('/')[1]) },
            params: this.path ? this.getQueryStringParams(this.path) : {},
            exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
          }
        }
      }
      

      console.log('payload:', payload)
      console.log('params:', this.$route.params)
      
      setTimeout(() => { 
        
        // if(this.$route.params.isMetabase === "0" && this.$route.params.isUrl === "0" && numero_dashboard == "0") {
        //    this.src = process.env.VUE_APP_ANGULAR_URL + '' + this.$route.params.path + '/?token=' + this.tokenLaravel + '&email=' + this.currentUser.email
        // }
        

        if(this.$route.params.isMetabase === "0" && this.$route.params.isUrl === "0" ) {
          this.src = process.env.VUE_APP_ANGULAR_URL + '' + this.path + '/?token=' + this.tokenLaravel + '&email=' + this.currentUser.email
        }

        if(this.$route.params.isMetabase === "1" && this.$route.params.isUrl === "0") {
           this.src = process.env.VUE_APP_METABASE_SITE_URL + "/embed/" + this.numero_dashboard.split('/')[0] + '/' + sign(payload, process.env.VUE_APP_METABASE_SECRET_KEY) + "#bordered=false&titled=true"
        }
        
        if(this.$route.params.isMetabase === "0" && this.$route.params.isUrl === "1") {
          
           this.src = this.$route.params.path
        }
        console.log('this.src', this.src)
        
        const path = this.$route.fullPath
        console.log('path', path)

        this.$gtag.pageview({
          page_path: '/' + path,
        })

      }, 500)

    }
  } 
}
</script>
<style lang="scss" scoped>
  .external {
    
  }

  iframe {
    height:calc(100vh - 60px);
    width:calc(100vw - 4px);
    box-sizing: border-box;
  }

.off {
  opacity: 0; pointer-events: none
}

.page-inner > span {
  @extend %transition_4o;
}
</style>