<template>
  <header>
    <topbar :devmode="devmode" :hommode="hommode"></topbar>
    <div class="headerMain">
      <div class="frame">
        <a href="/loja/" class="logoRedeShop">
          <logo />
        </a>
        <div class="loginCall">
          <a href="#" @click.prevent="goToLogin()" v-if="!hasUser">
            <b>Olá, faça seu login</b><br>
            e personalize sua visualização<br> 
            de produtos e categorias.
          </a>
          <div v-else>
            <b>Olá {{ firstName(currentUser.cadastro.Basicos.nome) }}</b>, seja bem-vindo!
            <!-- <b>Olá {{ currentUser.cadastro.Basicos.nome }}</b>,<br> seja bem-vindo! -->
            <router-link class="btMinhaConta" to="/minhaconta">Minha Conta</router-link>
            <!-- <a href="#" @click.prevent="logOut()"><b>Sair</b></a> -->
          </div>
        </div>
        <div class="searchWrap" v-if="!isMobile">
          <searchbar :categories="categories"></searchbar>
        </div>
        <!-- <div>
          <favoritos></favoritos>
        </div> -->
        <div>
          <cart></cart>
        </div>
        <div v-if="isMobile">
          <a href="#" @click.prevent="toggleMobileMenu()" class="mobileMenuToggle">
            <span></span>
            <span></span>
            <span></span>
          </a>
        </div>
        <div class="searchWrap" v-if="isMobile">
          <searchbar :categories="categories"></searchbar>
        </div>
      </div>
    </div>
    <menu-controller :toggle="mobileMenuToggle" @close="mobileMenuToggle = false"></menu-controller>
  </header>
</template>
<script>
import topbar from '../utilities/TopBar.vue'
import searchbar from '../utilities/SearchBar.vue'
import menuController from '../navigation/Menu.vue'
// import favoritos from '../utilities/favoritos/Favoritos.vue'
import cart from '../utilities/cart/Cart.vue'
import LojaService from '../../../services/loja.service'
import Logo from '@/components/loja/template/Logo'

export default {
  name: "header-controller",
  components: {
    topbar, menuController, searchbar, cart, Logo
    // favoritos
  },
  props: {
    devmode: {
      type: Boolean,
      default: false
    },
    hommode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      categories: [],
      isMobile: false,
      hasUser: false,
      mobileMenuToggle: false
      // currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null
    }
  },
  computed: {
    currentUser() {
      return localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null
    }
  },
  watch: {
    currentUser ( value ) {
      // console.log("currentUser:", value)
      if ( value ) {
        this.hasUser = true
      } else {
        this.hasUser = false
      }
    }
  },
  mounted () {
    this.fetchCategories()
    this.hasUser = this.currentUser != null
    // console.log("currentUser:", this.currentUser)
    window.addEventListener('resize', this.checkMobile)
    this.checkMobile()
  },
  methods: {
    fetchCategories () {
      LojaService.getMenuCategoriasLoja().then(
        response => {
          this.categories = response.data
        },
        error => {
          console.log('error', error)
        }
      )
    },
    checkMobile () {
      const ww = window.innerWidth
      this.isMobile = ww <= 1024
    },
    goToLogin () {
      const currentRoute = this.$router.currentRoute.value.href
      console.log("currentRoute:", currentRoute)
      
      this.$router.push({name: 'Login', params:{ user: 'responsavel' }, query: { return: currentRoute }})
    },
    logOut() {
      this.$store.dispatch('auth/logout')
      this.$router.go()
    },
    firstName ( nome ) {
      return nome.split(" ")[0]
    },
    toggleMobileMenu () {
      this.mobileMenuToggle = !this.mobileMenuToggle
    }
  }
}
</script>
<style lang="scss" scoped>
  header {
    width: 100%;
    background-color: $color-bg;

    .headerMain {
      width: 100%; height: 112px;
      padding: $hmg;

      > div {
         display: flex; align-items: center; align-content: stretch;
        // justify-content: space-between;
        >* {
          margin: 0 .7em
        }
        .logoRedeShop {
          flex: 0;
          width: auto;

          // > img {
          //   display: block;
          //   width: 165px; height: auto;
          // }
          
        }

        .loginCall {
          height: 75px; width: 220px;
          // font-size: 12px;
          border-radius: $border-radius;
          border: 1px solid #FFF;
          color: $color-primary;
          position: relative;
          max-width: 50%;

          @media screen and ( max-width: 1024px ) {
            height: 85px; width: 160px;
          }

          > * {
            display: block; width: 100%;
            position: absolute; top: 50%;
            left: 0; right: 0; margin: auto;
            padding: $mg_mini; line-height: 1.4;
            transform: translateY(-50%);
            text-align: center;
          }

          .btMinhaConta {
            display: block; width: 100%;
            padding: 4px 1em; margin-top: $hmg_mini;
            background-color: $color-primary; color: $color-secondary;
            border-radius: 4px;
            text-align: center;
            @extend %transition_3o;

            &:hover {
              border-radius: 6px;
              @extend %shadow-low-primary;
            }
          }
        }

        .searchWrap {
          flex: 1; width: 100%;
        }

        .spacer {
          display: none;
          width: 100%; flex: 1;
          height: 75px;

        }
      }
      
      @media screen and (max-width: 1024px) {
        height: auto; padding: $hmg_mid;

        > div {
          display: flex; align-items: center; align-content: stretch;
          flex-wrap: wrap;
          justify-content: space-between;
          >* {
            margin: 0
          }

          .spacer {
            display: block;
          }
          .logoRedeShop {
            display: block;
            flex: 1; height: 40px;
            width: 32px; max-width: 32px; min-width: 32px;
            margin-right: $hmg_mid;
            overflow: hidden;
            position: relative;

            > img {
              display: block;
              width: 165px; height: auto;
            }

            @media screen and (max-width: 1024px) {
              margin-right: $hmg_small;
            }
            
          }
          .loginCall {
            // display: none;
          }
          .searchWrap {
            min-width: 100%;
            margin-top: $hmg
          }
        }
      }
    }
    
    .mobileMenuToggle {
      display: block; width: 24px; height: 16px;
      position: relative; margin-left: $hmg_mid;

      > span {
        display: block; width: 24px; height: 3px;
        border-radius: 2px;
        background-color: $color-primary;
        position: absolute;

        &:nth-child(1) {
          top: 0;
        }
        &:nth-child(2) {
          top: 50%; transform: translateY(-50%);
        }
        &:nth-child(3) {
          bottom: 0;
        }
      }
    }
  }
  
</style>