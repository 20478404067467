<template>
  <section>
    <header>
      <p>Etapa {{ step.index + 1 }} de {{ totalSteps }}</p>
      <h4>Ficha de Saúde</h4>
      <p>Não se esqueça de atualizar a ficha de saúde da criança, ter essas informações atuailizadas é muito importante.</p>
    </header>
    <section class="form-wrap">
      <h5 class="color-text">{{ step.index + 1 }}.1. Informações do Aluno</h5>
      <saude ref="saude" v-if="loaded" :action="action"></saude>
    </section>
  </section>
</template>

<script>
import Saude from '@/components/matriculas/form/Saude'
import MatriculaService from '@/services/matricula.service'

export default {
  components: {
    Saude
  },
  props: {
    ready: {
      type: Boolean,
      default: false
    },
    idMatricula: {
      type: [String, Number]
    },
    action: {
      type: String
    },
    step: {
      type: Object
    },
    totalSteps: {
      type: Number
    }
  },
  data () {
    return {
      matricula: null,
      loaded: false
    }
  },
  mounted () {
    
  },
  methods: {
    validate () {
      const valid = this.$refs.saude.validate()

      if ( !valid.response  ) {
        var invalids = []
        Object.keys(valid.valids).map(k => {
          if ( !valid.valids[k] ) {
            invalids.push(k)
          }
        })
        var msg = invalids.join(', ')
        window.toaster.emit('open', {style: "error", message: "Existem campos não preenchidos: <b>" + msg + "</b>", floater: true})
      }

      return valid
    },
    get () {
      this.$store.dispatch('matricula/get').then(response => {
        this.matricula = response
        // console.log("get alunos - matricula response:", response)
        // console.log("idMatricula:", this.idMatricula)
        if ( this.idMatricula ) {
          MatriculaService.getFichaSaude( this.idMatricula ).then(
            response => {
              console.log("getFichaSaude:", response.data)

              if ( response.data.fichaSaude && Object.keys(response.data.fichaSaude).length > 0 ) {

                Object.keys(response.data.fichaSaude).map( k => {
                  this.matricula.fichaSaude[k] = !response.data.fichaSaude[k] ? null : ( response.data.fichaSaude[k] == 1 ? true : response.data.fichaSaude[k] )
                })
                
                this.matricula.fichaSaude['idMatricula'] = this.idMatricula
              }
              this.$store.dispatch('matricula/save').then(() => {
                this.$emit('ready')
                this.$nextTick(() => {
                  this.loaded = true
                })
              })
              
            },
            error => {
              console.error(error.response.data.message)
            }
          )
        } else {
          this.loaded = true
          this.$emit('ready')
        }      
      })      
    },
  }
}
</script>

<style lang="scss" scoped>
  header {
    padding: 0 $hmg_small $hmg;
  }
  .form-wrap {
    h5 {
      padding: 0 0 $hmg_small $hmg_small;
    }
  }
</style>