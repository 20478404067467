<template>
  <controller>
    <page-header title="Matrículas">
      <template v-slot:side>
        <transition name="slideRightToLeft">
          <a href="#" @click.prevent="toggleNew(false)" class="btn btn-primary" v-if="nova">
            <span>Filtrar Matrículas</span>&nbsp;
            <fa :icon="['fas', 'magnifying-glass-plus']"></fa>
          </a>
        </transition>
        <transition name="slideRightToLeft">
          <a href="#" @click.prevent="toggleNew(true)" class="btn btn-primary" v-if="!nova">
            <span>Nova Matrícula</span>&nbsp;
            <fa :icon="['fas', 'graduation-cap']"></fa>
          </a>
        </transition>
      </template>
    </page-header>
    <main>
      <filter-controller :nova="nova" @onEmpty="emptyResultList()" @onFilter="dropFilter"/>
      <result-controller :scope="matriculas" :filter="filter" v-if="matriculas.length > 0" />
      <turmas-controller :scope="turmas" v-if="turmas.length > 0" />
      <!-- LIST TURMAS -->
    </main>
  </controller>
</template>
<script>

import FilterController from '@/components/matriculas/utilities/Filter.vue'
import ResultController from '@/components/matriculas/template/FilterResult.vue'
import TurmasController from '@/components/matriculas/template/NewMatResult.vue'

export default {
  name: "Matrículas",
  components: {
    FilterController, ResultController, TurmasController
  },
  props: {
    action: {
      type: String
    }
  },
  data () {
    return {
      loading: false,
      nova: false,
      matricula: null,
      matriculas: [],
      turmas: [],
      filter: null
    }
  },
  mounted () {

    if ( this.action == 'nova' ) {
      this.toggleNew(true)
    }

    this.$store.dispatch('matricula/get').then(response => {
      this.matricula = response
      console.log("matricula:", response)
    })
  },
  methods: {
    dropFilter ( data ) {
      console.log("data: ", data)

      if ( this.nova ) {
        this.turmas = data
      } else {
        this.matriculas = data.matriculas
        this.filter = data.filter
      }
    },
    toggleNew ( state ) {
      this.emptyResultList()

      if ( state ) {
        this.$router.push({name: 'Pesquisa Matriculas', params: { action: 'nova' }})
      } else {
        this.$router.push({name: 'Pesquisa Matriculas', params: { action: null }})
      }
      this.nova = state
      // this.filter.tipoCurso = 0
    },
    emptyResultList () {
      this.matriculas = []
    },
    
  }
}
</script>

<style lang="scss" scoped>
  .search-filter {
    header {
      margin: $hmg;

      h4 {
        margin: 0 0 $hmg_mini 0; padding: 0;
      }
      p {
        margin: 0; padding: 0;
      }
    }
  }
  
  main {
    // padding: $hmg_mid
  }
</style>