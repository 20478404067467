<template>
  <section class="funcoesWrap">
    <h3>Selecione as funções que terão acesso a este assistente</h3>
    <div>
      <form-spinner v-if="loading" />
      <header>
        <div>
          <input-control v-model="query" @keyup="search" placeholder="O que você procura?" search preventSearchButton/>
        </div>
        <div>
          <label class="check-control ">
            <span>Todos</span>
            <input type="checkbox" class="check" @change="selectAll" v-model="all" />
          </label>
        </div>
      </header>
      <div>
        <div class="funcoesList">
          <ul>
            <li v-for="(funcao, i) in funcoes" :key="funcao" :ref="`funcao-${i}`" :class="{ highlight: funcao.highlight }">
              <label class="check-control justified">
                <span>{{ funcao.nome }}</span>
                <input type="checkbox" v-model="selectedFuncoes" @change="trackFuncoes()" :value="funcao" class="check" />
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <footer>
      <a href="#" class="btn btn-primary" @click.prevent="saveFuncoes()">Salvar</a>
    </footer>
  </section>
</template>

<script>
import AdmService from '@/services/adm.service.js'
import FormSpinner from '@/components/utilities/FormSpinner.vue'

export default {
  components: {
    FormSpinner
  },
  props: {
    scope: {
      type: Array
    }
  },
  data () {
    return {
      loading: false,
      funcoes: null,
      funcoesBkp: null,
      query: null,
      selectedFuncoes: [],
      waitSearch: null,
      all: false
    }
  },
  mounted () {
    this.getFuncoes()
  },
  methods: {
    getFuncoes () {
      this.loading = true

      AdmService.getFuncoes().then(
        response => {
          this.funcoes = response.data.map( k => ({
            ...k,
            highlight: false
          }))

          this.funcoes.map( k => {
            if ( this.scope ) {
              if ( this.scope.includes(k.idFuncao) ) {
                this.selectedFuncoes.push(k)
              }
            }
          })

          this.funcoesBkp = Object.assign([], response.data)
          this.loading = false
          console.log('funcoes:', this.funcoes)
        },
        error => {
          console.error(error)
          this.loading = false
        }
      )
    },
    search () {
      if ( this.waitSearch ) clearTimeout(this.waitSearch)

      if ( this.query && this.query.length >= 1 ) {

        this.waitSearch = setTimeout(() => {
          this.funcoes.map( a => {
            const nome = a.nome.toLowerCase()
            
            const existNome = nome.includes(this.query)
            a.highlight = existNome
          })

          const targetIndex = this.funcoes.findIndex(a => a.highlight)
          if ( targetIndex != -1 ) {
            const targetElement = this.$refs[`funcao-${targetIndex}`][0]
            targetElement.scrollIntoView({ behavior: "smooth", block: "center" })
          }

        },200)
      }
    },
    trackFuncoes() {
      this.$emit('onCountTotal', this.selectedFuncoes.length)
    },
    saveFuncoes () {
      this.$emit('onSave', this.selectedFuncoes )
    },
    selectAll () {
      console.log("selectAll:", this.all)
      if ( this.all ) {
        this.selectedFuncoes = Object.assign([], this.funcoes)
      } else {
        this.selectedFuncoes = []
      }
      console.log("selectedFuncoes:", this.selectedFuncoes)
    }
  }
}
</script>

<style lang="scss" scoped>
  .funcoesWrap {
    h3 {
      text-align: center;
      padding: $mg_mid $mg_mid 0;
    }

    header {
      display: flex; align-items: flex-end;
      justify-content: space-between;
      gap: $hmg_mid;
      padding: $hmg_mid $hmg_mid 0 0;

      >div {
        padding: 0 17px 0 $hmg_mid;

        &:first-child {
          flex: 1
        }
      }
    }

    .funcoesList {
      max-height: 450px;
      overflow: auto;
      @extend %scrollbar_card_small;
      padding: 0 $hmg_mid $hmg_mid; margin: $hmg_mid 0 0 0;

      ul {
        padding: 0; margin: 0;
        width: 100%;
        
        li {
          padding: $mg_mini $mg_mini $mg_mini $hmg_mini; margin: 0;
          list-style: none;
          width: 100%;
          border-top: $border-component;

          label {
            width: 100%
          }

          &.highlight {
            background-color: $color-neutro
          }
        }
      }
    }
    footer {
      width: 100%;
      padding: $hmg_mid;
      border-top: $border-component;
      display: flex; align-items: center;
      justify-content: flex-end
    }
  }
</style>