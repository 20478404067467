<template>
  <section :class="['over-page',{ locked: locked }]">
    <main v-if="atividade">
      <div class="sidebar">
        <h3>Registro de<br>atendimento</h3>
        <detalhes :target="target" :atividade="atividade" :id="id" :type="type" ></detalhes>
        <operador :target="target" :atividade="atividade" :id="id" :type="type" @onUpdate="update" :disabled="disabled"></operador>
        <historico :target="target" :atividade="atividade" :id="id" :type="type" v-if="!isMobile"></historico>
        <relevantes :atividade="atividade" :id="id" v-else></relevantes>
      </div>
      <div class="content">
        <card :listed="false" overflow="visible">
          <header-atividade :target="target" :atividade="atividade" :id="id" :type="type"></header-atividade>
          <registro :target="target" :atividade="atividade" :id="id" :type="type" @onChange="setDetails" :action="action" :disabled="disabled"></registro>
          <section class="atividade-form" v-if="atividade.formulario.perguntas.length > 0">
            <formulario :form="atividade.formulario" ref="form" @onSave="save" :action="action" :disabled="disabled" :asw="atividade.informacoesAtendimento.status == 1"></formulario>
          </section>
          <footer>
            <a href="#" class="btn btn-secondary" @click.prevent="cancel()">{{ disabled ? 'Sair' : 'Cancelar' }}</a>
            <a href="#" class="btn btn-primary" @click.prevent="toggleSave()" v-if="!disabled">Salvar</a>
          </footer>
        </card>
      </div>
      <div class="sidebar">
        <relevantes v-if="!isMobile" :atividade="atividade" :id="id"></relevantes>
        <historico :target="target" :atividade="atividade" :id="id" :type="type" v-else></historico>
      </div>
    </main>
    <confirm 
      :backdrop="false"
      class="confirm"
      ref="confirm"
    ></confirm>
  </section>
</template>

<script>

import AtendimentoService from '@/services/atendimento.service.js'
import AtividadesService from '@/services/atividades.service.js'
import Detalhes from '@/components/atividades/template/atendimentos/Detalhes.vue'
import HeaderAtividade from '@/components/atividades/template/atendimentos/Header.vue'
import Historico from '@/components/atividades/utilities/atendimentos/Historico.vue'
import Operador from '@/components/atividades/utilities/atendimentos/Operador.vue'
import Relevantes from '@/components/atividades/utilities/atendimentos/Relevantes.vue'
import Registro from '@/components/atividades/form/atendimentos/Registro.vue'
import Formulario from  '@/components/ferramentas/formularios/form/Responder.vue'

export default {
  components: {
    Detalhes, Historico, HeaderAtividade, Operador, Registro, Formulario, Relevantes
  },
  props: {
    id: {
      type: [Number, String]
    },
    type: {
      type: Object
    },
    action: {
      type: String
    },
    isMobile: {
      type: Boolean
    }
  },
  watch: {
    id ( value ) {
      if ( value ) {
        this.getAtividade(value)
      }
    },
    action ( value ) {
      if ( value == 'executar' ) {
        this.getAtividade(this.id)
      } 
    }
  },
  data () {
    return {
      atividade: null,
      target: null,
      disabled: false,
      locked: false,
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
    }
  },
  mounted () {
    if (this.id) {
      this.getAtividade(this.id)
    }
  },
  methods: {
    getAtividade ( id ) {
      // console.log("getAtividade:", id)
      AtendimentoService.getAtividadeAtendimento( id ).then(
        response => {
          console.log("response", response.data)
          this.atividade = response.data
          this.target = this.trackTarget()
          this.disabled = this.atividade.informacoesAtendimento.emAndamento == 0 || this.atividade.informacoesAtendimento.status ? true : false
          this.trackPermission()
        },
        () => {

        } 
      )
    },
    trackPermission () {
      const view = this.atividade.funcoes.view.includes(this.currentUser.colaborador.idFuncao)
      // const edit = this.atividade.funcoes.edit.includes(this.currentUser.colaborador.idFuncao)
      const execute = this.atividade.funcoes.execute.includes(this.currentUser.colaborador.idFuncao)

      this.disabled = !execute
    
      if ( !view  && !execute ) {
        this.confirmRowBack()
      }
    },
    confirmRowBack () {
      this.$refs.confirm.run({
        message: "Ops. Você não tem permissão para acessar este atendimento.",
        confirmButton: "Ok. Voltar",
        denyButton: "Ir para o painel",
        callback: this.rowBack,
        backdropColor: 'blur'
      })
    },
    rowBack( response ) {
      if ( response ) {
        this.$router.go(-1)
      } else {
        this.$emit("onCancel")
      }
    },
    setDetails ( options ) {
      console.log("setDetails:", options)
      
      this.atividade.informacoesAtendimento[options.target] = options.data
    },
    trackTarget () {
      var target

      if ( this.type.slug == 'atendimentos' ) target = 'informacoesAtendimento'
      if ( this.type.slug == 'tickets' ) target = 'informacoesTickets'
      if ( this.type.slug == 'tarefas' ) target = 'informacoesAtendimento'
      if ( this.type.slug == 'solicitacoes' ) target = 'informacoesSolicitacoes'
      if ( this.type.slug == 'prospects' ) target = 'informacoesProspects'

      return target
    },
    toggleSave () {
      this.$refs.form.save()
    },
    save ( data ) {
      const atividade = {
        idAtividade: this.id,
        registroAtendimento: this.atividade.informacoesAtendimento.registroAtendimento,
        anexo: this.atividade.informacoesAtendimento.anexo,
        idFormulario: this.atividade.formulario.idFormulario,
        formulario: data
      }

      console.log("atvidade:", atividade)

      AtividadesService.saveAtividade(atividade.idAtividade, atividade).then(
        response => {
          console.log("saveAtividade:", response.data)
          this.$emit("onSave")
        },
        () => {

        }
      )
      console.log("save:", atividade)
    },
    cancel () {
      this.$emit("onCancel")
    },
    update () {
      this.getAtividade(this.id)
      this.$emit("onCancel")
    },
  }
}
</script>

<style lang="scss" scoped>
  main {
    display: flex; align-items: flex-start; align-content: center;
    gap: $hmg_mid;
    padding: $mg;

    .sidebar {
      width: 320px;
      
      &:last-child {
        top: $hmg;
        position: sticky;
      }

      h3 {
        padding: $hmg_mid $hmg_mid $mg_mini $hmg_mid
      }

      p {
        margin: 0; padding: 0;
      }
    }
    
    .content {
      flex: 1;

      footer {
        display: flex;
        align-content: center;
        justify-content: space-between;
        padding: $mg_mini $hmg_mid;
        border-top: $border-component
      }
    }

    @media screen and ( max-width: 849px ) {
      flex-direction: column;
      padding: $hmg_mid;
      .sidebar {
        width: 100%;
      }
    }
  }


  .atividade-form {
    padding: $hmg_mid;
  }

  
</style> 