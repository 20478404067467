<template>
  <body class="printPage" ref="printPage">
    <header>
      <h1>Lista de Entrega</h1>
      <logo fill="#444"></logo>
    </header>
    <div>
      <table v-for="list in lists" :key="list">
        <thead>
          <tr>
            <th></th>
            <th>Código</th>
            <th>Data</th>
            <th>Unidade</th>
            <th>Turma</th>
            <th>Aluno</th>
            <th>Responsável</th>
            <th></th>
            <th class="signWrap">Assinatura</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item,i) in list" :key="item">
            <td>{{ i + 1 }}</td>
            <td>{{ item.codigo }}</td>
            <td>{{ item.datapedido }}</td>
            <!-- <td>Nome do lote...</td> -->
            <!-- <td>{{ item.nomeLote ? (item.nomeLote.split(" - ")[item.nomeLote.split(" - ").length - 1] ) : '-' }}</td> -->
            <td>{{ item.sigla }}</td>
            <td>{{ item.nomeTurma }}</td>
            <td>{{ item.aluno }}</td>
            <td>{{ item.nome }}</td>
            <td>
              <!-- <fa :icon="['fas', 'circle-dollar-to-slot']" :class="['icon','error']" title="Inadimplente - Débitos Pedido" v-if="item.debitos_pedido"/> -->
              <fa :icon="['fas', 'money-check-dollar']" :class="['icon','error']" title="Inadimplente - Débitos Mensalidade" v-if="item.bloqueiaPedido == 1"/>
            </td>
            <td class="signWrap"><span class="signature"></span></td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th colspan="6">{{ now() }}</th>
          </tr>
        </tfoot>
      </table>
    </div>
  </body>
</template>

<script>
import moment from 'moment'
import Logo from '@/components/loja/template/Logo'

export default {
  name: "Lista Entrega",
  components: {
    Logo
  },
  data () {
    return {
      lists: [],
      src: [],
      printCount: 0
    }
  },
  mounted () {
    this.src = JSON.parse(localStorage.getItem('PrintList'))

    console.log("this.src:", this.src )
    
    document.getElementsByTagName("html")[0].style = 'height: auto !important; min-height: 100%; overflow: auto !important; background-color: #FFF !important;'
    // console.log("html:", html)
          // html.classList.add("scrollable")

    setTimeout(() => {
      if ( this.printCount === 0 ) this.print()
      this.printCount++
    },1000)
  },
  methods: {
    now () {
      const date = moment().format('DD/MM/YYYY - HH:mm:ss')
      // console.log('date:', date)
      return date
    },
    chunckList ( items, size ) {
      const chunks = []
      items = [].concat(...items)

      while (items.length) {
        chunks.push(
          items.splice(0, size)
        )
      }

      return chunks
    },
    removeDuplicates ( data ) {
      const list =  data.filter((obj, index) => {
        return index === data.findIndex(o => obj.aluno === o.aluno)
      })

      return list
    },
    print () {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop)
      })

      let compact = eval(params.compact)

      // console.log("compact:", compact)
      this.lists = compact ? this.chunckList(this.removeDuplicates(this.src), 22) : this.chunckList(this.src, 22)

      // console.log("lists:", this.lists)

      const newBody = this.$refs.printPage
      // console.log('newBody:', newBody)
      document.html = newBody
      // console.log(this.$refs.printPage)
      setTimeout(() => {
        print()
      },500)
    }
  }
}
</script>

<style lang="scss">
  .scrollable {
    height: auto !important; min-height: 100%; overflow: auto !important;
    background-color: #FFF !important;
  }
  // .body {
  //   background-color: #FFF !important;
  // }
  .printPage {
    width: 100%; height: auto;
    background-color: $color-secondary;
    color: #444; padding-bottom: 50px;

    header {
      display: flex; max-width: 1024px; margin: auto;
      align-content: center; align-items: center; justify-content: space-between;
      padding: 50px 25px;

      @media print {
        padding: 20px 25px 50px;
      }

      h1 {
        color: #444;

      }
    }
    

    > div {
      // border: 1px solid #444;
      max-width: 1024px; margin: auto;
    }
    table {
      width: 100%; 
      border-collapse: collapse;
      border: 0; border-left: 1px solid #444; border-right: 1px solid #444;
      page-break-inside: auto;
      page-break-after: always;

      thead {
        display: table-header-group;

        tr {
          border-top: 1px solid #444;
          border-bottom: 1px solid #444;
          page-break-inside: avoid;
          page-break-after: auto;

          th {
            padding: .8em;
            font-size: 12px; color: #444;
          }
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid #444;
          page-break-inside: avoid;
          page-break-after: auto;

          td {
            padding: .8em;
            font-size: 13px;
            color: #444;
            line-height: 1.2;
          }
        }
      }

      tfoot {
        tr {
          vertical-align: middle;
          line-height: 1;
          border-bottom: 1px solid #444;

          th {
            padding: .8em;
            font-size: 11px;
            font-weight: normal;
            color: #444;
          }
        }
      }

      .signWrap {
        border-left: 1px solid #444;
      }
    }

    .signature {
      display: block;
      padding: 10px; min-width: 250px; width: 100%;
      // border-bottom: 1px solid #333;
    }

    .icon {
      font-size: 18px;
      margin-right: 10px
    }
  }

  
</style>