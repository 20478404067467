<template>
  <section>
    <div>
      <h5>Selecione a categoria de atividade</h5>
      <div v-if="categorias" class="row nop">
        <div class="col-md-4 from-group">
          <select-control v-model="categoria" :options="categorias" placeholder="Selecione" @change="runFilter"/>
        </div>
      </div>
      
    </div>
  </section>
</template>

<script>
import AtendimentoService from '@/services/atendimento.service.js'
import { createSelectOptions } from '@/services/utils.service.js'

export default {
  data () {
    return {
      categorias: [],
      categoria: null
    }
  },
  mounted() {
    this.getCategorias()
  },
  methods: {
    getCategorias () {
      AtendimentoService.getCategoriasAtendimento().then(
        response => {
          // console.log("getCategoriasAtendimento: ", response.data)
          this.categorias = createSelectOptions(response.data,'idAtividadeCategoria','nome')
        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops. Algo Aconteceu! Não foi possível carregar as categorias de atendimento.", floater: true})
        }
      )
    },
    runFilter () {
      const categoria = this.categorias.filter( a => a.idAtividadeCategoria == this.categoria )[0]
      this.$emit("onFilter", categoria)
    }
  }
}
</script>

<style lang="scss" scoped>
  section {
    padding: $mg;

    h5 {
      margin-bottom: $hmg_mid;
      padding: 0 $hmg_mid;
    }
  }
</style>