<template>
  <main>
    <section class="collapseList">
      <header>
        <h4>{{ list.length }} usuário(s) ativo(s)</h4>
      </header>
      <template v-for="(grupo, funcao) in groupedUsers" :key="funcao">
        <h2>{{ funcao }}</h2>
        <ul>
          <li v-for="(item) in grupo" :key="item">
            <card :boxed="true">
              <div>
                <header>
                  <img v-if="item.urlPic" :src="item.urlPic" alt="Thumbnail">
                  <div v-else class="initials">{{ getInitials(item.nomeDocumento) }}</div>
                  <span>
                    {{ item.nomeDocumento }}
                  </span>
                  <span>
                    {{ item.email }}
                  </span>
                  <span>
                    {{ item.nomeFuncao }}
                  </span>
                  <span>
                    {{ item.sigla }}
                  </span>
                  <button @click="openModal(item)">Enviar Notificação</button>
                </header>
              </div>
            </card>
          </li>
        </ul>
      </template>
    </section>
    <div v-if="showModal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <p>Usuário: {{ selectedUser.nomeDocumento }}</p>
        <form @submit.prevent="sendNotification">
          <p>Título: <input type="text" v-model="notificationMessage.title"> </p>
          <textarea id="message" v-model="notificationMessage.message"></textarea>
          <button type="submit">Enviar</button>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
import FerramentasService from '@/services/ferramentas.service.js'

export default {
  data () {
    return {
      list: [],
      filterNomeFuncao: '',
      showModal: false,
      notificationMessage: {
        "type": "apollo",
        "title": null,
        "message": null
      },
      selectedUser: null
    }
  },
  mounted () {
    window.Echo.join('OnlineUsers').here((users) => {
      users.forEach(user => {
        FerramentasService.getMeObject(user.id).then(
          response => {
            console.log("response" + JSON.stringify(response))
            this.list.push(response.data)
          },
          () => {}
        )
      })
      console.log("this.list" + JSON.stringify(this.list))
    }).joining((user) => {
      FerramentasService.getMeObject(user.id).then(
        response => {
          this.list.push(response.data)
        },
        () => {}
      )
    }).leaving((user) => {
      this.list = this.list.filter(item => item.id !== user.id);
    })
  },
  computed: {
    getInitials() {
      return (nome) => {
        if (nome) {
          const words = nome.split(' ');
          const lastWord = words[words.length - 1];
          if (words.length > 1) {
            return `${words[0].charAt(0)}${lastWord.charAt(0)}`;
          }
          return `${words[0].charAt(0)}`;
        }
        return '';
      };
    },
    uniqueNomeFuncoes() {
      return [...new Set(this.list.map(item => item.nomeFuncao))];
    },
    groupedUsers() {
      return this.list.reduce((acc, item) => {
        if (!acc[item.nomeFuncao]) {
          acc[item.nomeFuncao] = [];
        }
        acc[item.nomeFuncao].push(item);
        return acc;
      }, {});
    }
  },
  methods: {
    openModal(user) {
      this.selectedUser = user;

      setTimeout(() => this.showModal = true, 350);
      
    },
    closeModal() {
      this.showModal = false;
      this.selectedUser = null;
    },
    sendNotification() {
      let data = {
        "userId": this.selectedUser.id,
        "type": "apollo",
        "title": this.notificationMessage.title,
        "msg": this.notificationMessage.message,
        "forAll": false,
        "idFuncao": null,
        "OnlineOnly": null
      };

      FerramentasService.sendNotification( data ).then(
          response => {
            console.log("response:", response)
          }
        )

      this.closeModal();
    }
  }
}
</script>


<style lang="scss" scoped>
  main {
    padding: $mg;
  }

  .count {
    display: flex; 
    flex-direction: column;
    justify-content:  center; 
    align-items: center;
    width: 24px; 
    height: 24px;
    min-width: 24px; 
    min-height: 24px;
    max-width: 24px; 
    max-height: 24px;
    border-radius: 100%;
  }

  header img {
    width: 50px; 
    height: 50px;
    border-radius: 50%; 
    object-fit: cover; 
    margin-right: 10px; 
    border: $border-component
  }

  .initials {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px; 
    height: 50px;
    border-radius: 50%; 
    background-color: lightgray; 
    color: white; 
    font-weight: bold; 
    font-size: 20px; 
    margin-right: 10px; 
  }
  .modal {
    display: none;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 600px; /* Limitar a largura máxima do modal */
    border-radius: 5px;
  }

  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .modal-content h2 {
    margin-top: 0;
  }

  .modal-content label {
    display: block;
    margin-bottom: 5px;
  }

  .modal-content textarea {
    width: 100%;
    height: 100px;
    resize: vertical; /* Permitir redimensionamento vertical */
    margin-bottom: 10px;
  }

  .modal-content button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
  }

  .modal-content button:hover {
    background-color: #45a049;
  }
</style>
