<template>
  <div class="input-control">
    <template v-if="thumb || fake">
      <a class="thumb" :href="thumb ?? fake" download v-if="isPdf(thumb ?? fake)" target="_blank">
        <fa :icon="['far', 'magnifying-glass-plus']" class="icon" />
      </a>
      <div class="thumb" :style="{ 'background-image': fake ? ( `url('${fake}')` ) : `url('${thumb}')`}" @click.prevent="toggleImage" v-else>
        <fa :icon="['far', 'magnifying-glass-plus']" class="icon" />
      </div>
    </template>
    <fa :icon="['fas', 'asterisk']" :class="['requiredIcon','color-primary',{ hasLabel: label != '' }]" v-if="required" />
    <label :class="['form-control', { disabled: disabled, hasThumb: thumb || fake }]">
      <p v-if="file.name"><b>{{ parseName(file.name) }}</b></p>
      <p v-else>{{ placeholder ? placeholder : 'Selecione um arquivo' }}</p>
      <div>
        <span v-if="file.ext">{{ parseExt(file.ext) }}</span>
        <fa :icon="['fal', 'files']" class="fileIcon"/>
      </div>
      
      <input 
        type="file"
        :class="[
          'form-control',
          { error: !valid },
          cssClass
        ]"
        :name="name"
        @focus="focus"
        @blur="blur"
        @change="change"
        @input="input"
        v-bind="$attrs"
        v-bind:value="modelValue"
        ref="input"
        :required="required"
        :disabled="disabled"
        :accept="accept"
      >
    </label>
    <modal ref="modal" wrapped>
      <img :src="fake ? fake : thumb" alt="" class="large-image">
    </modal>
  </div>
</template>

<script>
import { toBase64 } from '@/services/utils.service.js'
import Modal from '@/components/utilities/Modal'

export default {
  components: {
    Modal
  },
  props: {
    type: {
      type: String,
      default: "text"
    },
    class: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    valid: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ""
    },
    modelValue: {
      default: ""
    },
    cssClass: {
      type: String,
      default: ""
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean
    },
    thumb: {
      type: String
    },
    responseType: {
      type: String,
      default: 'base64' //base64 ou file
    },
    accept: {
      type: String,
      default: ".jpeg,.jpg,.png,.pdf,.gif"
    }
  },
  watch: {
    thumb ( value ) {
      console.log("watch thumb..", value)
    }
  },
  data () {
    return {
      file: {
        file: null,
        name: null,
        ext: null,
        size: null,
        src: null,
        error: null
      },
      fake: null
    }
  },
  mounted () {
    console.log("file src:", this.thumb)
  },
  methods: {
    focus (data) {
      this.$emit("focus", data)
    },
    blur (data) {
      this.$emit("blur", data)
    },
    change (data) {
      const file = data.target.files[0]
      const name = file.name
      const ext = file.type
      const size = file.size
      const src = URL.createObjectURL(file)

      this.file.file = file
      this.file.name = name
      this.file.ext = ext
      this.file.size = size
      this.file.src = src
      this.file.error = null
      // this.documentos[type][i].documentoEntregue.src = URL.createObjectURL(file)

      if ( this.responseType == 'base64' ) {
        toBase64(file).then(
          response => {
            this.fake = response
            console.log("toBase64", response)
            this.$emit("change", response)
          }
        )
      } else {
        this.$emit("change", this.file)
      }
    },
    parseName ( name ) {
      const parsedName = name.split(".")[0]
      return parsedName
    },
    parseExt ( ext ) {
      const parsedExt = ext.split("/")[1]
      return parsedExt
    },
    toggleImage ( item ) {
      this.imagem = item.imagemEnviada
      
      const options = {
        // title: item.nomeProduto,
        titleAlign: "center",
        // message: "Se quiser uma mensagem...", //não obrigatório
        size: "large", //small, mid, large, full
        backdrop: true,
        backdropColor: "transparent",  //se o backdrop for false, não precisa
        backdropClose: true, //se o backdrop for false, não precisa
        fullHeight: false,
        onClose: this.closeModal
      }
      
      // document.getElementByClassName
      this.$refs.modal.open(options)
    },
    closeModal () {
      this.imagem = null
    },
    isPdf ( file ) {
      console.log("isPdf:", file)
      const regex = /pdf/i
      // console.log("test:", regex.test(file))

      return regex.test(file)
    }
  }
}
</script>

<style lang="scss" scoped>
  .input-control {
    position: relative;
    width: 100%; overflow: visible;

    .thumb {
        display: flex; flex-direction: column;
        justify-content: center; align-items: center;
        width: 40px; height: 40px;
        border-radius: $border-radius-mid;
        background-color: $color-bg;
        background-size: contain; 
        background-position: center;
        background-repeat: no-repeat;
        border: $border-component;
        position: absolute;
        bottom: $hmg_mini; left: $hmg_mini;
        text-align: center;
        cursor: pointer;
        
        .icon {
          font-size: 24px;
          color: $color-secondary;
          opacity: .8;
          transform: scale(.9);
          filter: drop-shadow(2px 2px 2px #000);
          @extend %transition_3o;
        }

        &:hover {
          .icon {
            opacity: 1;
            transform: scale(1);
          }
        }
      }

    .form-control {
      display: flex; align-items: center; align-content: center;
      justify-content: space-between;
      width: 100%; height: 50px;
      border: $border-component; border-radius: $border-radius;
      box-shadow: none; outline: none;
      padding: 0 $hmg_small;
      color: $color-text;
      @extend %transition_3o;

      &.hasThumb {
        padding: 0 $hmg_small 0 $mg
      }

      &.invert {
        background-color: $color-bg;
      }
      
      input {
        opacity: 0;
        z-index: -1;
        position: absolute;
      }
      
      p {
        padding: 0; margin: 0
      }

      

      > div:not(.thumb) {
        display: flex; align-items: center; align-content: center;
        gap: $mg_mini;

        span {
          padding: $hmg_mini $mg_mini;
          border-radius: 2em;
          background-color: $color-bg;
          text-transform: uppercase;
        }
      }

      &:disabled,
      &[disabled='true'], 
      &[readonly='true'],
      &.disabled,
      &.readonly {
        background-color: $color-fade;
        pointer-events: none;
      }

      &.error {
        border: 2px solid $color-alert !important;
      }

      &:hover {
        @extend %shadow-mid;
        outline: none;
      }
    }

    .fileIcon {
      // position: absolute;
      // top: 50%; right: $hmg_mid;
      // transform: translateY(-50%);
      font-size: 16px;
    }
  }

  .large-image {
    display: block;
    width: 100%;
  }
</style>