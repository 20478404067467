<template>
  <div class="funil">
    <h3>Filtrar produtos</h3>
    <div class="funil-body">
      <div>
        <div class="funilFilterBody">
          <div class="funilSearch">
            <input-control placeholder="Busque por categorias..." cssClass="small small-radius"/>
            <div class="funilFilterList">
              <!-- <checkbox-control @change="toggleAll($event, 0)">{{ getToggleAllLabel(0) }}</checkbox-control> -->
            </div>
          </div>
          <div class="filterListWrap">
            <input-spinner v-if="loading[0]" class="listSpinner"></input-spinner>
            <perfect-scrollbar class="ps">
              <ul class="filterList" v-for="list in data[0]" :key="list">
                <li v-for="item in list" :key="item">
                  <checkbox-control v-model="item.selected" :justified="true" :value="item.idcategorialoja" @change="select(0, item, $event, item.categoria)">
                    {{ item.categoria }}
                  </checkbox-control>
                </li>
              </ul>
            </perfect-scrollbar>
          </div>
        </div>
      </div>
      <div>
        <div class="funilFilterBody">
          <div class="funilSearch">
            <input-control placeholder="Busque por subcategorias..." cssClass="small small-radius"/>
            <div class="funilFilterList">
              <!-- <checkbox-control @change="toggleAll($event, 1)">{{ getToggleAllLabel(1) }}</checkbox-control> -->
            </div>
          </div>
          <div class="filterListWrap">
            <input-spinner v-if="loading[1]" class="listSpinner"></input-spinner>
            <perfect-scrollbar class="ps">
              <ul class="filterList" v-for="list in data[1]" :key="list">
                <li v-for="item in list" :key="item" :class="{nohover: item.title}">
                  <p v-if="item.title">
                    {{ item.title }}
                  </p>
                  <checkbox-control
                    v-model="item.selected"
                    :justified="true"
                    :value="item.idcategorialoja"
                    @change="select(1, item, $event, item.descricao)"
                    v-else
                  >
                    {{ item.descricao }}
                  </checkbox-control>
                </li>
              </ul>
            </perfect-scrollbar>
          </div>
        </div>
      </div>
      <div>
        <div class="funilFilterBody">
          <div class="funilSearch">
            <input-control placeholder="Busque por produtos..." cssClass="small small-radius"/>
            <div class="funilFilterList">
              <checkbox-control @change="toggleAll($event, 2)">{{ getToggleAllLabel(2) }}</checkbox-control>
            </div>
          </div>
          <div class="filterListWrap">
            <input-spinner v-if="loading[2]" class="listSpinner"></input-spinner>
            <perfect-scrollbar class="ps">
              <ul class="filterList" v-for="list in data[2]" :key="list">
                <li v-for="item in list" :key="item" :class="{nohover: item.title}">
                  <p v-if="item.title">
                    {{ item.title }}
                  </p>
                  <checkbox-control
                    v-model="item.selected"
                    :justified="true"
                    :value="item.idsubcategorialoja"
                    @change="select(2, item, $event, item.descricao)"
                    v-else
                  >
                    {{ item.nomeProduto }}
                  </checkbox-control>
                </li>
              </ul>
            </perfect-scrollbar>
          </div>
        </div>
      </div>
    </div>
    <div class="funil-footer">
      <a href="#" @click.prevent="cancel()" class="btn btn-secondary" v-if="canCancel">Cancelar</a>
      <transition name="slideup">
        <a href="#" @click.prevent="finalize()" class="btn btn-primary" v-if="allowSelect">Selecionar</a>
      </transition>
    </div>
  </div>
</template>

<script>
import LojaService from '@/services/loja.service'
import InputSpinner from '@/components/utilities/InputSpinner'

export default {
  name: "Funil",
  props: {
    canCancel: {
      type: Boolean,
      default: true
    }
  },
  components: {
    InputSpinner
  },
  data () {
    return {
      data: [[],[],[]],
      loading: [false,false,false],
      allowSelect: false
    }
  },
  mounted () {
    this.fetchData(0)
  },
  methods: {
    fetchData ( level, data, title ) {
      this.loading[level] = true

      if ( level === 0 ) {
        LojaService.getMenuCategoriasLoja().then(
          response => {
            if ( response.data ) {
              const finalData = this.addSelectedTag(response.data)
              this.data[level] = [finalData]
              this.loading[level] = false
              // console.log("data 0:", this.data)
            }
          },
          error => {
            console.log('error', error)
            this.loading[level] = false
          }
        )
      }

      if ( level === 1 ) {
        LojaService.getSubcategoriaLoja(data).then(
          response => {
            if ( response.data ) {
              const finalData = this.addSelectedTag(response.data)
              console.log("finalData 1:", finalData)
              if ( finalData.length > 0 ) {
                const obj = []
                finalData.map( k => {
                  obj.push(k)
                })

                if ( title ) {
                  const titleItem = {
                    title: title
                  }

                  obj.unshift(titleItem)
                  
                  // console.log("obj:", obj)

                  this.data[level].unshift(obj)
                }
              }
              // console.log("data 1:", this.data)
              this.loading[level] = false
            }
          },
          error => {
            console.log('error', error)
            this.loading[level] = false
          }
        )
      }

      if ( level === 2) {
        const drop = {
          categoriasIds: [],
          subCategoriasIds: [data],
          text: "",
          inativos: true
        }
        
        LojaService.buscaProdutosLojaSemSegmento(drop).then(
          response => {
            if ( response.data ) {
              console.log("buscaProdutosLoja-response:", response.data)
              const finalData = this.addSelectedTag(response.data)
              if ( finalData.length > 0 ) {
                const obj = []
                finalData.map( k => {
                  obj.push(k)
                })

                if ( title ) {
                  const titleItem = {
                    title: title
                  }

                  obj.unshift(titleItem)
                  
                  // console.log("obj:", obj)

                  this.data[level].unshift(obj)
                }
              }
              this.loading[level] = false
            }
          },
          error => {
            console.log('error', error)
            this.loading[level] = false
          }
        )
      }
    },
    unloadData ( level, item ) {
      const tag = level == 2 ? 'descricao' : 'categoria'
      const newData = this.data[level].filter(k => {
        return k[0].title != item[tag]
      })

      this.data[level] = newData

      if ( level == 2 ) {
        this.allowSelect = false
      }

    },
    addSelectedTag ( obj ) {
      let newObj = []
      obj.map( k => {
        k.selected = false
        newObj.push(k)
      })

      return newObj
    },
    select ( level, item, event, title ) {
      const isSelected = event.target.checked
      item.selected = isSelected

      if ( isSelected ) {
        if ( level === 0 ) {
          this.fetchData(1, item.idcategorialoja, title)
          // console.log("data:", this.data[level])
        }
        if ( level === 1 ) {
          console.log("item:", item)
          this.fetchData(2, item.idsubcategorialoja, title)
          // console.log("data:", this.data1)
        }
        if ( level === 2 ) {
          this.allowSelect = true
        }
      } else {
        const nextLevel = level + Number(1)
        this.unloadData(nextLevel, item)
      }
      
    },
    getToggleAllLabel ( level ) {
      const data = this.data[level]
      const total = data.length

      const selected = data.filter(k => {
        return k.selected
      })

      return selected.length == total ? 'Nenhum' : 'Todos'
    },
    toggleAll ( event, level ) {
      const isSelected = event.target.checked

      if ( isSelected ) {
        this.data[level].map( k => {
          k.map( ki => {
            if ( !ki.title ) ki.selected = true

            let id

            if ( level !== 2 ) {
              if ( level === 0 ) id = 'idcategorialoja'
              if ( level === 1 ) id = 'idsubcategorialoja'

              this.fetchData(1, ki[id], ki.categoria)
            } else {
              //
            }
            
          })
        })

        this.allowSelect = true
      } else {
        this.data[level].map( k => {
          k.map( ki => {
            ki.selected = false
          })
        })
        this.allowSelect = false
      }
    },
    search () {

    },
    finalize () {
      console.log("this.data:", this.data)

      const payload = {
        categorias: [],
        subcategorias: [],
        produtos: []
      }

      // loops para pegar os itens selecionados.

      // categorias
      this.data[0].map( kc => {
        console.log("kc:", kc)
        if ( kc ) {
          kc.map ( kck => {
            if ( kck.selected ) {
              payload.categorias.push(kck)
            }
          })
        }
      })
      
      // subcategorias
      this.data[1].map( ks => {
        console.log("ks:", ks)

        ks.map ( ksk => {
          if ( ksk.selected ) {
            payload.subcategorias.push(ksk)
          }
        })
      })

      // produtos
      this.data[2].map( kp => {
        console.log("kp:", kp)

        kp.map ( kpk => {
          if ( kpk.selected ) {
            payload.produtos.push(kpk)
          }
        })
      })


      console.log("payload:", payload)

      this.$emit('filter', payload)
    },
    cancel () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
  .funil {
    display: flex; flex-direction: column;
    align-content: stretch; align-items: stretch;
    width: 100%; height: 60vh; min-height: 500px;
    background-color: $color-secondary;
    padding: $hmg; border-radius: $border-radius-larger;
    margin-bottom: $mg;
    
    .funil-body {
      display: flex; align-items: stretch; align-content: stretch;
      justify-content: stretch;
      width: 100%; height: 100%;

      > div {
        flex: 1; height: 100%;
        overflow: hidden;
        margin-right: $hmg_small;
        border-radius: $border-radius;
        @extend %transition_4e;

        &:last-child {
          margin-right: 0
        }

        .ps { 
          height: 100%; width: 100%;
          padding:  0 $hmg_small $hmg_small;
          position: absolute;
          max-height: 100%;
        }
      }
    }

    .funilFilterBody {
      display: flex; flex-direction: column;
      align-content: stretch; align-items: stretch;
      height: 100%;      
      background-color: $color-light;

      .funilSearch {
        padding: $hmg_small $hmg_small 0 $hmg_small;
      }
      .filterListWrap {
        width: 100%; flex: 1;
        position: relative;
      }
      .funilFilterList {
        display: flex; align-items: center; align-self: center;
        justify-content: flex-end;
        padding: $mg_mini;

        >*{
          width: auto;
        }
      }
    }

    .filterList {
      padding: 0;

      li {
        list-style: none;
        padding: $hmg_mini $mg_mini;
        border-radius: $border-radius-small;
        @extend %transition_4o;
        
        &:hover:not(.nohover) {
          background-color: $color-secondary;
        }

        &.nohover {
          padding: 0 $mg_mini;
          font-size: 11px; font-weight: bold;

          p {
            margin-bottom: $hmg_mini;
          }
          
        }
      }
    }

    .funil-footer {
      display: flex; align-content: center; align-items: center;
      justify-content: flex-end; margin-top: $hmg_mid;

      a {
        margin-left: $hmg_mid
      }
    }

    .listSpinner {
      margin: auto;
      left: 0; right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
</style>