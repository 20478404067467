<template>
  <section>
    <header>
      <h4>Categorias</h4>
    </header>
    <div>
      
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style>

</style>