<template>
  <section class="mat-main" v-if="matricula">
    <header>
      <p>Etapa {{ step.index + 1 }} de {{ totalSteps }}</p>
      <h4>Dados do Aluno</h4>
      <p>Confirme se os dados do aluno estão corretos. Caso algum dado esteja incorreto, por favor, atualize.</p>
    </header>
    <section class="form-wrap">
      <h5 class="color-text">{{ step.index + 1 }}.1. Informações Pessoais</h5>
      <pessoas branch="Aluno" ref="pessoa" v-if="loaded" @populateEndereco="populateEndereco" :auto="auto" @onload="clearLoading('endereco')" :action="action" lowrequire></pessoas>
    </section>
    <section class="form-wrap">
      <h5 class="color-text">{{ step.index + 1 }}.2. Endereço</h5>
      <enderecos branch="Aluno" ref="endereco" v-if="loaded" :autoPopulate="enderecoData" @onload="clearLoading('pessoa')" :action="action"></enderecos>
    </section>
  </section>
</template>

<script>
import Pessoas from '@/components/matriculas/form/Pessoas'
import Enderecos from '@/components/matriculas/form/Enderecos'
import MatriculaService from '@/services/matricula.service'
import { formatDate, clearMask, unformatDate } from '@/services/utils.service'

export default {
  components: {
    Pessoas, Enderecos
  },
  props: {
    idMatricula: {
      type: [String, Number]
    },
    auto: {
      type: Boolean,
      default: false
    },
    action: {
      type: String
    },
    step: {
      type: Object
    },
    totalSteps: {
      type: Number
    }
  },
  data () {
    return {
      matricula: null,
      enderecoData: null,
      wait: {
        pessoa: false,
        endereco: false
      },
      loaded: false
    }
  },
  mounted () {
    this.$store.dispatch('matricula/get').then(response => {
        console.log("response get matricula on aluno", response)
        if ( response.Aluno.Basicos.idPessoa ) {
          this.matricula = response
          this.loaded = true
        } else {
          this.get()
        }
    })
  },
  methods: {
    validate () {
      const pessoaValid = this.$refs.pessoa.validate()
      const enderecoValid = this.$refs.endereco.validate()

      if ( !pessoaValid.response || !enderecoValid.response ) {
        var invalids = []
        Object.keys(pessoaValid.valids).map(k => {
          if ( !pessoaValid.valids[k] ) {
            invalids.push(k)
          }
        })
        Object.keys(enderecoValid.valids).map(k => {
          if ( !enderecoValid.valids[k] ) {
            invalids.push(k)
          }
        })
        var msg = invalids.join(', ')
        window.toaster.emit('open', {style: "error", message: "Existem campos não preenchidos: <b>" + msg + "</b>", floater: true})
      }

      return { response: pessoaValid.response && enderecoValid.response }
    },
    populateEndereco ( data ) {
      this.enderecoData = data
    },
    clearLoading ( target ) {
      this.wait[target] = true
      this.stopSpinner()
    },
    stopSpinner () {
      const done = !Object.values(this.wait).includes(false)
      if ( done ) {
        window.spinner.emit("close")
        this.$emit('ready')
      }
    },
    save ( step ) {
      this.$store.dispatch('matricula/get').then(response => {
        this.matricula = response

        window.spinner.emit('open')
        // this.matricula.Aluno.Basicos.dataNascimento = unformatDate(this.matricula.Aluno.Basicos.dataNascimento)
        const aluno = {}
        const basicos = {}
        const enderecos = {}
        const documentos = {}
        const matricula = {}

        Object.keys(this.matricula.Aluno.Basicos).map((k,i) => {
          if ( Object.values(this.matricula.Aluno.Basicos)[i] ) {
            basicos[k] = Object.values(this.matricula.Aluno.Basicos)[i]
          }
        })
        Object.keys(this.matricula.Aluno.Documento).map((k,i) => {
          if ( Object.values(this.matricula.Aluno.Documento)[i] ) {
            documentos[k] = Object.values(this.matricula.Aluno.Documento)[i]
          }
        })
        Object.keys(this.matricula.Aluno.Endereco).map((k,i) => {
          if ( Object.values(this.matricula.Aluno.Endereco)[i] ) {
            enderecos[k] = Object.values(this.matricula.Aluno.Endereco)[i]
          }
        })
        Object.keys(this.matricula.Aluno.Aluno).map((k,i) => {
          if ( Object.values(this.matricula.Aluno.Aluno)[i] ) {
            aluno[k] = Object.values(this.matricula.Aluno.Aluno)[i]
          }
        })

        Object.keys(this.matricula.matricula).map((k,i) => {
          if ( Object.values(this.matricula.matricula)[i] ) {  
            matricula[k] = Object.values(this.matricula.matricula)[i]
          }
        })
        
        const data = {
          aluno: {
            Aluno: JSON.parse(JSON.stringify(aluno)),
            Basicos: JSON.parse(JSON.stringify(basicos)),
            Documento: JSON.parse(JSON.stringify(documentos)),
            Endereco: JSON.parse(JSON.stringify(enderecos))
          },
          matricula: JSON.parse(JSON.stringify(matricula)),
          contato: {}
        }
        
        if ( !data.aluno.Basicos.nome ) {
          data.aluno.Basicos.nome = data.aluno.Basicos.nomeDocumento
        }
        // console.log("data raw", data)
        if ( data.aluno.Basicos.telefonecelular ) {
          data.aluno.Basicos.telefonecelular = clearMask('phone', data.aluno.Basicos.telefonecelular)
        }
        if ( data.aluno.Basicos.telefonefixo ) {
          data.aluno.Basicos.telefonefixo = clearMask('phone', data.aluno.Basicos.telefonefixo)
        }
        data.aluno.Basicos.dataNascimento = formatDate(data.aluno.Basicos.dataNascimento)
        data.aluno.Endereco.cep = clearMask('cep', data.aluno.Endereco.cep)
        if ( data.aluno.Documento.cpf ) {
          data.aluno.Documento.cpf = clearMask('cpf', data.aluno.Documento.cpf)
        }
        if ( data.aluno.Documento.rg ) {
          data.aluno.Documento.rg = clearMask('rg', data.aluno.Documento.rg)
        }

        delete data.aluno.Endereco.nomeUf
        delete data.aluno.Endereco.uf
        delete data.aluno.Endereco.nomeCidade
        delete data.aluno.Endereco.cidade
        delete data.aluno.Documento.nomeOrgaoEmissor
        delete data.aluno.Documento.orgaoEmissor
        delete data.aluno.Documento.rgDataExpedicao
        delete data.aluno.Basicos.nacionalidade
        
        // console.log("data", data)

        MatriculaService.saveAluno( data, this.idMatricula ).then(
          response => {
            console.log('saveAluno:', response)

            this.nextAfterValidation(step)
        
            this.$store.dispatch('matricula/save', this.steps).then(response => {
              console.log('save matricula:', response)
              this.steps[this.findStepBySlug('unidade-curso').index].saved = true
              this.steps[step.index].saved = true

              window.spinner.emit('close')
            })
            
          },
          error => {
            console.error(error)
            window.spinner.emit('close')
          }
        )
      })
    },
    get () {
      this.$store.dispatch('matricula/get').then(response => {
        this.matricula = response
        if ( this.idMatricula ) {
          MatriculaService.getAlunoByIdMatricula( this.idMatricula ).then(
            response => {
              console.log("getAuno:", response.data)
              this.matricula.Aluno.Aluno = response.data.aluno.Aluno ? response.data.aluno.Aluno : this.matricula.Aluno.Aluno
              this.matricula.Aluno.Basicos = response.data.aluno.Basicos ? response.data.aluno.Basicos : this.matricula.Aluno.Basicos
              this.matricula.Aluno.Basicos.dataNascimento = this.matricula.Aluno.Basicos.dataNascimento ? unformatDate(this.matricula.Aluno.Basicos.dataNascimento) : this.matricula.Aluno.Basicos.dataNascimento
              this.matricula.Aluno.Documento = response.data.aluno.Documento ? response.data.aluno.Documento : this.matricula.Aluno.Documento
              this.matricula.Aluno.Endereco = response.data.aluno.Endereco ? response.data.aluno.Endereco : this.matricula.Aluno.Endereco
              
              this.matricula.matricula = response.data.matricula ? response.data.matricula : this.matricula.matricula

              if ( !this.matricula.Aluno.Basicos.nomeDocumento ) {
                this.matricula.Aluno.Basicos.nomeDocumento = this.matricula.Aluno.Basicos.nome
              }
              
              if ( !this.matricula.Aluno.Basicos.nomeDocumento ) {
                this.matricula.Aluno.Basicos.nomeDocumento = this.matricula.Aluno.Basicos.nome
              }
              
              this.$store.dispatch('matricula/save', this.steps).then(() => {
                this.loaded = true
              })
              
            },
            error => {
              console.error(error.response.data.message)
            }
          )
        } else {
          this.loaded = true
          this.$emit('ready')
        }
      })      
    },
  }
}
</script>

<style lang="scss" scoped>
  
</style>