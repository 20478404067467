<template>
  <controller :goToTopButton="true">
    <div class="page-title">
      <h3 class="text-capitalize">Gatilhos Pipedrive + Blip</h3>
      <transition name="slideRightToLeft">
        <a href="#" @click.prevent="toggleNew()" class="btn btn-primary" v-if="nova">
          <span>Cancelar Criação</span>&nbsp;
          <fa :icon="['fas', 'x-mark']"></fa>
        </a>
      </transition>
      <transition name="slideRightToLeft">
        <a href="#" @click.prevent="toggleEdit()" class="btn btn-primary" v-if="editar">
          <span>Cancelar Edição</span>&nbsp;
          <fa :icon="['fas', 'x-mark']"></fa>
        </a>
      </transition>
      <transition name="slideRightToLeft">
        <a href="#" @click.prevent="toggleNew()" class="btn btn-primary" v-if="!nova && !editar">
          <span>Novo Gatilho</span>&nbsp;
          <fa :icon="['far', 'pencil']"></fa>
        </a>
      </transition>
      
    </div>
    <div class="page-inner">
      <section class="frame fixed sm" v-if="nova || editar">
        <form @keyup.enter="runFilter">
          <div class="row no-padd">
            <div class="col-md-12 form-group">
              <label>Descrição</label>
              <input-control v-model="gatilho.descricao" placeholder="Descreva este gatilho..." :autocomplete="true"/>
            </div>
            <div class="col-md-6 form-group">
              <label>Etapa do funil</label>
              <select-control v-model="gatilho.etapaAtual" :options="options.etapas" placeholder="Selecione" />
            </div>
            <div class="col-md-6 form-group">
              <label>NPS</label>
              <div class="switchWrap">
                <switch-control v-model:checked="gatilho.nps" :invert="true" labelOn="Sim" labelOff="Não"></switch-control>
              </div>
            </div>
          </div>
          <div class="row no-padd" v-if="!gatilho.nps">
            <div class="col-md-12 form-group">
              <h5>Informações do Blip</h5>
            </div>
            <div class="col-md-6 form-group">
              <label>Template</label>
              <input-control v-model="gatilho.templateBlip" placeholder="ex: pos_atendimento_pipedrive" :autocomplete="true"/>
            </div>
            <div class="col-md-6 form-group">
              <label>Namespace</label>
              <input-control v-model="gatilho.namespace" placeholder="ex: 2f01d140_67f6_4d3a_a576_eec2548a2d89" :autocomplete="true"/>
            </div>
          </div>
          <div class="row no-padd" v-if="!gatilho.nps">
            <div class="col-md-12 form-group">
              <h5>Teste</h5>
            </div>
            <div class="col-md-6 form-group">
              <label>É teste?</label>
              <div class="switchWrap">
                <switch-control v-model:checked="gatilho.teste" :invert="true" labelOn="Sim" labelOff="Não"></switch-control>
              </div>
            </div>
            <div class="col-md-6 form-group">
              <label>Número de Telefone para o teste</label>
              <input-control v-model="gatilho.numeroTeste" placeholder="ex: 5511999990000"/>
            </div>
          </div>
          <div class="row no-padd">
            <div class="col-md-12 form-actions form-group">
              <a href="#" class="btn btn-primary" @click.prevent="save()">
                Salvar
              </a>
              <a href="#" class="btn btn-secondary" @click.prevent="clear()">
                Limpar
              </a>
            </div>
          </div>
        </form>
      </section>
      <section :class="['lista',{ spaced: nova }]" v-if="gatilhos.length > 0">
        <header v-if="nova || editar">
          <h4>Gatilhos</h4>
        </header>
        <card :boxed="true">
          <table class="content-table bordered-list">
            <thead>
              <tr>
                <th>Ativo</th>
                <th>Descrição</th>
                <th>Etapa</th>
                <th>NPS</th>
                <th>Namespace</th>
                <th>Template Blip</th>
                <th>Teste</th>
                <th>Número Teste</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="gatilho in gatilhos" :key="gatilho">
                <td>
                  <input-spinner v-if="gatilho.loading"></input-spinner>
                  <switch-control v-model:checked="gatilho.ativo" @change="toggleGatilhoAtivo($event, gatilho)" v-else></switch-control>
                </td>
                <td>{{ gatilho.descricao }}</td>
                <td>{{ gatilho.etapaAtual }}</td>
                <td>{{ gatilho.nps ? 'Sim' : 'Não' }}</td>
                <td>{{ gatilho.namespace }}</td>
                <td>{{ gatilho.templateBlip }}</td>
                <td>{{ gatilho.teste ? 'Sim' : 'Não' }}</td>
                <td>{{ gatilho.numeroTeste }}</td>
                <td class="act">
                  <div class="actions">
                    <a href="#" @click.prevent="toggleEdit(gatilho)" title="Editar">
                      <fa :icon="['far', 'pencil']"></fa>
                    </a>
                    <a href="#" @click.prevent="confirmRemove(gatilho)" title="Remover">
                      <fa :icon="['far', 'trash-can']"></fa>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </card>
      </section>
    </div>
    <confirm 
      :backdrop="false"
      class="confirm"
      ref="confirm"
    ></confirm>
  </controller>
</template>

<script>
import CaptacaoService from '@/services/captacao.service.js'
import InputSpinner from '@/components/utilities/InputSpinner'

export default {
  components: {
    InputSpinner
  },
  data () {
    return {
      options: {
        etapas: [
          { label: '1-Primeiro Contato', value: '1-Primeiro Contato' },
          { label: '2-Pós Atendimento', value: '2-Pós Atendimento' },
          { label: '3-Visita não comparecida', value: '3-Visita não comparecida' },
          { label: '4-Visita agendada', value: '4-Visita agendada' },
          { label: '5-Pós Visita', value: '5-Pós Visita' },
          { label: '6-Lista de espera', value: '6-Lista de espera' },
          { label: '7-Documentação', value: '7-Documentação' }
        ]
      },
      gatilho: {
        templateBlip: null,
        etapaAtual: null,
        namespace: null,
        descricao: null,
        loading: false,
        ativo: 0,
        teste: false,
        numeroTeste: null,
        nps: null
      },
      nova: false,
      editar: false,
      gatilhos: []
    }
  },
  mounted () {
    this.getGatilhos()
  },
  methods: {
    getGatilhos () {
      window.spinner.emit("open")
      this.gatilhos = []
      CaptacaoService.getGatilhosPipedrive().then(
        response => {
          console.log("getGatilhoPipedrive:", response.data)

          response.data.map( k => {
            const ativo = k.ativo
            const nps = k.nps

            k.ativo = ativo == 1
            k.nps = nps == 1
            k.loading = false
          })

          console.log("response.data:", response.data)
          this.gatilhos = response.data
          window.spinner.emit("close")
        },
        error => {
          window.spinner.emit("close")
          const msg = error.response.data.error
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. <b>" + msg + "</b>", floater: true})
        }
      )
    },
    save () {
      window.spinner.emit("open")

      if ( this.editar ) {
        this.edit()
      } else {
        CaptacaoService.saveGatilhoPipedrive( this.gatilho ).then(
          response => {
            console.log("saveGatilhoPipedrive:", response.data)
            window.spinner.emit("close")
            window.toaster.emit('open', {style: "success", message: "Gatilho criado com sucesso!", floater: true})
            this.refresh()
          },
          error => {
            window.spinner.emit("close")
            const msg = error.response.data.error
            window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível salvar este gatilho. <b>" + msg + "</b>", floater: true})
          }
        )
      }
      
    },
    edit ( gatilho ) {
      const drop = gatilho ? gatilho : this.gatilho

      CaptacaoService.editGatilhoPipedrive( drop ).then(
        response => {
          console.log("editGatilhoPipedrive:", response.data)
          window.spinner.emit("close")
          window.toaster.emit('open', {style: "success", message: "Gatilho editado com sucesso!", floater: true})

          setTimeout(() => {
            this.refresh()
          }, 1000)
        },
        error => {
          window.spinner.emit("close")
          const msg = error.response.data.error
          this.clearLoading()
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível editar este gatilho.<b>" + msg + "</b>", floater: true})
        }
      )
    },
    confirmRemove ( gatilho ) {
      this.$refs.confirm.run({
        message: "Tem certeza que deseja remover este item?",
        confirmButton: "Sim",
        denyButton: "Não",
        data: gatilho,
        callback: this.remove,
      })
    },
    remove ( state, gatilho ) {
      if ( state ) {
        window.spinner.emit("open")
        CaptacaoService.deleteGatilhoPipedrive( gatilho ).then(
          response => {
            console.log("deleteGatilhoPipedrive:", response.data)
            window.spinner.emit("close")
            window.toaster.emit('open', {style: "success", message: "Gatilho removido com sucesso!", floater: true})
            this.refresh()
          },
          error => {
            window.spinner.emit("close")
            const msg = error.response.data.error
            window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível remover este gatilho. <b>" + msg + "</b>", floater: true})
          }
        )
      }
    },
    clear () {
      this.gatilho = {
        templateBlip: null,
        etapaAtual: null,
        namespace: null,
        descricao: null,
        loading: false,
        ativo: 0,
        teste: false,
        numeroTeste: null,
        nps: null
      }
    },
    toggleNew () {
      this.nova = !this.nova
    },
    refresh () {
      this.clear()
      
      setTimeout(() => {
        this.getGatilhos()
      },500)
      
    },
    toggleEdit ( gatilho ) {
      this.editar = !this.editar

      if ( this.editar ) {
        this.gatilho = gatilho
      } else {
        this.clear()
      }
    },
    toggleGatilhoAtivo ( e, gatilho ) {
      gatilho.loading = true
      this.edit(gatilho)
      
      // console.log("toggleGatilhoAtivo:", e.target.checked)
      // console.log("gatilho:", gatilho)
    },
    clearLoading () {
      this.gatilhos.map(k => {
        k.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  header {
    h4 {
      padding-left: $hmg_small
    }
  }

  h5 {
    padding-left: $hmg_small;
  }

  .lista {
    &.spaced {
      margin-top: $mg
    }
  }

  
</style>