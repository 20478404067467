<template>
   <div class="">
      <header>
        <h4>{{ title }} Permissão</h4>
      </header>
      <form method="POST">
        <div class="row nop nom">
          <div class="form-group col-md-4">
            <input-control type="text" placeholder="Nome" v-model="nome" />
          </div>
          <div class="form-group col-md-4">
            <select-control type="text" placeholder="Tipo" v-model="tipo" :options="tipos" />
          </div>
          <div class="form-group col-md-4">
            <select-control type="text" placeholder="Externo ?" v-model="tipo_link" :options="tipos_link" />
          </div>
          <div class="form-group col-md-4" v-if="tipo_link === 'normal'">
            <select-control type="text" placeholder="Tipo de URL" v-model="tipo_url" :options="urls" />
          </div>
          <div class="form-group col-md-4">
            <select-control type="text" placeholder="Módulo Menu" v-model="modulo" :options="modulos" />
          </div>
          <div class="form-group col-md-4" v-if="tipo === 'submenu'">
            <select-control type="text" placeholder="Item Menu" v-model="itemMenu" :options="item_menu" />
          </div>
          <div class="form-group col-md-4">
            <select-control type="text" placeholder="Módulo Sistema" v-model="modulo_sistema" :options="modulos_sistema" />
          </div>
          <div class="form-group col-md-4" v-if="tipo_link === 'metabase'">
            <input-control type="text" placeholder="Número Dashboard" v-model="numero_dashboard" />
          </div>
          <div class="form-group col-md-4">
            <input-control type="text" placeholder="Router Link" v-model="router_link" />
          </div>
          <div class="form-group col-md-4">
            <input-control type="text" placeholder="Ícone" v-model="icone" />
          </div>
        </div>
        <footer>
          <a href="#" class="btn btn-primary" @click.prevent="criarID ? editPermissao() : savePermissao()" >
            Salvar
          </a>
          <!-- <a href="#" class="btn btn-primary" @click.prevent="editPermissao" v-else>
            Salvar
          </a> -->
          <a href="#" class="btn btn-primary" @click.prevent="close">
            Cancelar
          </a>
        </footer>
      </form>
    </div>
</template>
<script>
import axios from 'axios'
import authHeader from '@/services/auth-header'

export default {
  name: "criarModulos",
  props: {
    criarID: {
      type: [Number, String, Object],
      default: null
    }
  },
  watch: {
    criarID (newVal) {
      this.id = newVal.id
      this.nome = newVal.name
      this.tipo = newVal.tipo
      this.numero_dashboard = newVal.numeroDashboard ? newVal.numeroDashboard : null
      this.tipo_link = (newVal.externo === 1 && newVal.angular === 1) ? 'angular' : (newVal.externo === 1 && newVal.angular === 0) ? 'metabase' : 'normal'
      this.modulo = newVal.role_id
      this.itemMenu = Number(newVal.idMenu)
      this.modulo_sistema = newVal.moduloName
      this.tipo_url = newVal.tipo_url
      this.router_link = newVal.router_link
      this.icone = newVal.icon
    }
  },
  data () {
    return {
      title: null,
      id: null,
      nome: null,
      tipo: null,
      tipo_link: 'normal',
      modulo: null,
      numero_dashboard: null,
      modulo_sistema: null,
      router_link: null,
      icone: null,
      itemMenu: 0,
      tipos: [
        { label: 'Menu', value: 'menu' },
        { label: 'SubMenu', value: 'submenu' }
      ],
      item_menu: [],
      tipos_link: [
        { label: 'Angular', value: 'angular' },
        { label: 'Metabase', value: 'metabase' },
        { label: 'PowerBI', value: 'powerbi' },
        { label: 'Url', value: 'normal' }
      ],
      urls: [
        { label: 'Nova aba', value: 'blank' },
        { label: 'Embedado', value: 'self' }
      ],
      modulos: [],
      modulos_sistema: [],
      tipo_url: null
    }
  },
  mounted () {
    setTimeout(() => {
      this.getPermissionsSistema()
      this.getModulos()
      this.getModulosSistema()
      this.title = this.criarID === null ? "Criar" : "Editar"
    },100)
  },
  methods: {
    close () {
      this.$emit('close','')
    },
    getModulos () {
      axios.get(process.env.VUE_APP_API_URL + 'auth/getallroles',{ headers: authHeader() })
      .then( response => {
        for (let index = 0; index < response.data.length; index++) {
          this.modulos.push({label: response.data[index]['name'], value: response.data[index]['id']})
        }
      })
      .catch( error => {
        console.error("error:", error)
      })
    },
    getModulosSistema () {
      axios.get(process.env.VUE_APP_API_URL + 'auth/getlistangularmodulos',{ headers: authHeader() })
      .then( response => {
        for (let index = 0; index < response.data.length; index++) {
          this.modulos_sistema.push({label: response.data[index]['angularModule'], value: response.data[index]['angularModule']})
        }
      })
      .catch( error => {
        console.error("error:", error)
      })
    },
    getPermissionsSistema () {
      axios.get(process.env.VUE_APP_API_URL + 'auth/getallpermissions',{ headers: authHeader() })
      .then( response => {
        for (let index = 0; index < response.data.length; index++) {
          this.item_menu.push({label: response.data[index]['name'], value: response.data[index]['id']})
        }
      })
      .catch( error => {
        console.error("error:", error)
      })
    },
    editPermissao () {
      window.spinner.emit('open')
      axios.put(process.env.VUE_APP_API_URL + 'auth/editpermission/' + this.id, {permission: {
          name: this.nome,
          tipo: this.tipo,
          numero_dashboard: this.numero_dashboard,
          tipo_link: this.tipo_link,
          nome_modulo: this.modulo_sistema,
          role_id: this.modulo,
          tipo_url: this.tipo_url,
          router_link: this.router_link,
          icon: this.icone,
          itemMenu: Number(this.itemMenu),
          loadChildren: null
        }
      },{ headers: authHeader() })
      .then(response => {
        this.close()
        window.toaster.emit('open', {style: "success", message: "Permissão editada com sucesso."})
        this.$emit('updateParentList','')
        return response.data;
      }).then(() =>{
        window.spinner.emit('close')
      })
    },
    savePermissao () {
      window.spinner.emit('open')
      axios.post(process.env.VUE_APP_API_URL + 'auth/createpermission', {permission: {
          name: this.nome,
          tipo: this.tipo,
          numero_dashboard: this.numero_dashboard,
          tipo_link: this.tipo_link,
          nome_modulo: this.modulo_sistema,
          role_id: this.modulo,
          tipo_url: this.tipo_url,
          router_link: this.router_link,
          icon: this.icone,
          itemMenu: Number(this.itemMenu),
          loadChildren: null
        }
      },{ headers: authHeader() })
      .then(response => {
        this.close()
        window.toaster.emit('open', {style: "success", message: "Permissão criada com sucesso."})
        this.$emit('updateParentList','')
        return response.data;
      }).then(() =>{
        window.spinner.emit('close')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  form {
    padding: 0 $mg_mini
  }
  header {
    padding: $hmg_mid
  }
  footer {
    padding: 0 $hmg_mid $hmg_mid $hmg_mid;
    display: flex; align-items: center;
    gap: $mg_mini
  }
</style>