<template>
  <div class="ficha-saida">
    <header>
      <fa :icon="['far', 'door-open']" class="icon" />
      <h5>Ficha de Saída</h5>
    </header>
    <div v-if="aluno && ficha">
      <span class="saida-status" :style="{ backgroundColor: ficha.autorizacaoSaida.corHexa }">
        {{ ficha.autorizacaoSaida.corAutorizacao }} - {{ ficha.autorizacaoSaida.descricao }}
      </span>
      <div>
        <h5>Quem pode retirar</h5>
        <p>{{ quemPode() }}</p>
      </div>
      <div class="quemMaisWrap">
        <h5>Quem mais?</h5>
        <ul>
          <li v-for="auth in ficha.autorizacaoRetirarAluno" :key="auth">
            <card :boxed="false" :listed="false">
              <div class="quemMaisCard">
                <header>
                  <h5>{{ auth.parentesco }}</h5>
                  <h5>{{ auth.nome }}</h5>
                </header>
                <div>
                  <ul>
                    <li class="labeledDetail">
                      <label>Telefone</label>
                      <p>{{ auth.telefoneCelular }}</p>
                    </li>
                    <li class="labeledDetail">
                      <label>CPF</label>
                      <p>{{ auth.cpf }}</p>
                    </li>
                  </ul>
                </div>
              </div>

            </card>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import MatriculaService from '@/services/matricula.service.js'

export default {
  props: {
    aluno: {
      type: Object
    }
  },
  data () {
    return {
      ficha: null
    }
  },
  mounted () {
    console.log('aluno:', this.aluno)
    if ( this.aluno.Aluno ) this.getFichaSaida()
  },
  methods: {
    getFichaSaida () {
      MatriculaService.getFichaSaida( this.aluno.Aluno.idMatricula ).then(
        response => {
          if ( Object.keys(response.data).length > 0 ) {
            this.ficha = response.data
          }
          
          console.log('response:', response)
        },
        () => {

        }
      )
    },
    quemPode () {
      const autorizacaoSaida = this.ficha.autorizacaoSaida
      const quemPode = []
      
       if ( autorizacaoSaida.mae == 1 ) {
          quemPode.push("Filiação 1")
        }
        if ( autorizacaoSaida.pai == 1 ) {
          quemPode.push("Filiação 2")
        }
        if ( autorizacaoSaida.responsavelFinanceiro == 1 ) {
          quemPode.push("Responsável Financeiro")
        }
        if ( autorizacaoSaida.responsavelPedagógico == 1 ) {
          quemPode.push("Responsável Pedagógico")
        }

        console.log("quemPode:", quemPode)
        
        return quemPode.length > 0 ? quemPode.join(", ") : '...'
    }
  }
}
</script>

<style lang="scss" scoped>
  .ficha-saida {
    header {
      display: flex; align-items: center;
      line-height: 1;
      gap: $hmg_mini;

      h5 { 
        margin: 0; padding: 0;
      }

      .icon {
        font-size: 16px;
      }
    }
    
    .saida-status {
      display: block;
      border-radius: $border-radius;
      color: $color-secondary;
      padding: $mg_mini;
      margin: $hmg_mid 0;
    }
  }

  .quemMaisWrap {
    margin-top: $hmg_mid;

    h5 {
      margin: 0; padding: 0;
    }

    ul {
      padding: 0; margin: 0;

      li {
        list-style: none;
        padding: 0; margin: $mg_mini 0 0 0;
      }
    }

    .quemMaisCard {
      header {
        padding: $mg_mini;
        border-bottom: $border-component;
        display: flex; align-items: center;
        justify-content: space-between;

        h5 {
          &:first-child {
            color: $color-primary
          }
        }
      }

      ul {
        padding: $mg_mini; margin: 0;
        display: flex; align-items: center;
        justify-content: space-between;

        li {
          list-style: none;
          padding: 0; margin: 0;

          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }

  .labeledDetail {
    label {
      font-size: $font-size-small;
      font-weight: bold;
      padding: 0; margin: 0 0 $hmg_mini 0
    }
    p {
      margin: 0; padding: 0;
    }
  }
</style>