
<template>
  <section class="negociacoes">
    <header>
      <h4>Negociações</h4>
      <a href="#" class="btn btn-secondary" @click.prevent="goBack()">Voltar</a>
    </header>
    <card v-for="negociacao in negociacoes" :key="negociacao" :boxed="true">
      <div class="statusTag">
        <p>{{ formatDate(negociacao.created_at) }} </p>
      </div>
      <h5>
        {{negociacao.nomeCampanha}}
      </h5>
      <p>{{negociacao.nomeAluno}}</p>
      <ul class="cardDetails">
        <li>
          <label>Responsável</label>
          <p>{{ negociacao.nomeResponsavel }}</p>
        </li>
        <li>
          <label>Forma de Pagamento</label>
          <p>{{ negociacao.nomeFormaPagamento }}</p>
        </li>
      </ul>
      <ul class="cardDetails">
        <li>
          <label>Valor Original</label>
          <p>{{ formatMoney(negociacao.valorOriginal) }}</p>
        </li>
        <li>
          <label>Valor Corrigido</label>
          <p>{{ formatMoney(negociacao.valorCorrigido) }}</p>
        </li>
      </ul>
      <ul class="cardDetails">
        <li>
          <label>Valor Negociação</label>
          <p>{{ formatMoney(negociacao.valorRenegociacao)}}</p>
        </li>
        <li>
          <label>Valor Entrada</label>
          <p>{{ formatMoney(negociacao.valorEntrada) }}</p>
        </li>
      </ul>
    </card>
    <empty v-if="negociacoes.length == 0 && !loading"/>
  </section>
</template>
<script>
import axios from 'axios'
import authHeader from '@/services/auth-header'
import { formatMoney, formatDate } from '@/services/utils.service'

export default {
  name: "negociacoes",
  components: {
    
  },
  props: {
    aluno: {
      type: Object
    },
    idAluno: {
      type: [String, Number]
    }
  },
  data () {
    return {
      negociacoes: [],
      loading: true,
      negociacoesTotalMessage: "0 itens"
    }
  },
  mounted () {
    console.log("negociacoes 1:", this.aluno)
    console.log("negociacoes 2:", this.idAluno)

    this.listNegociacoes()
  },
  computed: {

  },
  methods: {
    listNegociacoes () {
      window.spinner.emit('open')
      axios.get(process.env.VUE_APP_API_URL + 'renegociacao/efetivadas/' +  this.aluno.Basicos.nome + '/0/0/0/0/0' ,{ headers: authHeader() })
      .then( response => {
          if (typeof response.data != 'undefined' && response.data.length > 0) {
            const total = response.data.length
            const suffix = total > 1 ? " itens" : " item"
            this.negociacoesTotalMessage = total + suffix
            this.negociacoes = response.data
          }          
      })
      .catch( error => {
        console.error("error:", error)
      }).then(() =>{
        window.spinner.emit('close')
        this.loading = false
      })
    },
    formatDate (date) {
      return formatDate(date)
    },
    formatMoney (value) {
      return formatMoney(value)
    },
    goBack() {
      this.$emit('goBack')
    }
  }
}
</script>
<style lang="scss" scoped>
  .negociacoes {
    padding: $hmg_mid $hmg_mid $hmg_mid $mg;

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $hmg_mid;

      h4 {
        padding: 0; margin: 0;
      }
    }
  }

  .cardDetails {      
    display: flex;
    align-items: stretch; align-content: center;
    justify-content: space-between;
    list-style: none; margin: 0; padding: 1em 0 0 0; 
    border-top: 1px solid $color-light;
    
    >li {
      padding: 0;

      label {
        margin: 0;
        flex: 0;
        font-weight: bold;
        font-size: $font-size-small
      }
      p {
        flex: 1;
        // text-align: right;
      }

      &:last-child:not(:first-child) {
        text-align: right;

        label { text-align: right; }
        p { text-align: right; }
      }
    }

    &.full {
      width: 100%;

      >li {
        width: 100%;
      }
    }

    &:last-child {
      li {
        p { margin-bottom: 0; }
      }
    }
  }
</style>