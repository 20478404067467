<template>
  <section>
    <div v-if="fornecedor">
      <div class="row no-padd" ref="fornecForm">
        <div class="col-md-6 form-group no-padd-left">
          <label>Nome</label>
          <input-control
            v-model="fornecedor.nome" 
            placeholder="Ex. Rede Decisão" 
            name="nome"
            type="text"
            :valid="valid.nome" 
            @keyup="valid.nome = true"
            required
          />
        </div>
        <div class="col-md-4 form-group no-padd-left">
          <label>CNPJ</label>
          <input-control
            v-model="fornecedor.cnpj" 
            placeholder="00.000.000/0001-00" 
            name="cnpj"
            type="text"
            masking="cnpj"
          />
        </div>
      </div>
      <div class="row no-padd" ref="fornecForm">
        <div class="col-md-4 form-group no-padd-left">
          <label>Contato</label>
          <input-control
            v-model="fornecedor.contato" 
            placeholder="Ex. João"
            name="contato"
            type="text"
          />
        </div>
        <div class="col-md-3 form-group no-padd-left">
          <label>Telefone</label>
          <input-control
            v-model="fornecedor.fone" 
            placeholder="+5511987654321"
            name="fone"
            type="text"
            masking="telefone"
            @keydown="addCountryCode('fone', $event)"
          />
        </div>
        <div class="col-md-4 form-group no-padd-left">
          <label>Email</label>
          <input-control
            v-model="fornecedor.email" 
            placeholder="joao@email.com"
            name="email"
            type="email"
          />
        </div>
      </div>
      <div class="row no-padd" ref="fornecForm">
        <div class="col-md-4 form-group no-padd-left">
          <label>Chave produto nota fiscal</label>
          <input-control
            v-model="fornecedor.chaveProdutoNotaFiscal" 
            placeholder=""
            name="chaveProdutoNotaFiscal"
            type="text"
          />
        </div>
        <div class="col-md-4 form-group no-padd-left">
          <label>Chave produto nota fiscal</label>
          <input-control
            v-model="fornecedor.classeImposto" 
            placeholder="REF1037838"
            name="classeImposto"
            type="text"
          />
        </div>
      </div>
      <div class="row no-padd" ref="fornecForm">
        <div class="col-md-12 form-group no-padd-left titleWrap">
          <h5>Endereço</h5>
        </div>
        <div class="col-md-4 form-group no-padd-left">
          <label>CEP</label>
          <input-control 
            v-model="fornecedor.cep" 
            placeholder="00000-000" 
            name="cep" 
            type="text" 
            :disabled="loading" 
            @keyup="buscaCep()"
            masking="cep"
            required
          />
        </div>
        <div class="col-md-8 form-group no-padd-left">
          <label>Logradouro</label>
          <input-control 
            v-model="fornecedor.endereco" 
            placeholder="Avenida Paulista" 
            name="endereco" 
            type="text" 
            :disabled="loading" 
            readonly
          />
        </div>
        <div class="col-md-2 form-group no-padd-left">
          <label>Número</label>
          <input-control 
            v-model="fornecedor.numero" 
            placeholder="Avenida Paulista" 
            name="logradouro" 
            type="text" 
            :disabled="loading" 
            ref="number"
          />
        </div>
        <div class="col-md-4 form-group no-padd-left">
          <label>Complemento</label>
          <input-control 
            v-model="fornecedor.complemento" 
            placeholder="Casa, Apto, etc." 
            name="complemento" 
            type="text" 
            :disabled="loading" 
          />
        </div>
        <div class="col-md-6 form-group no-padd-left">
          <label>Bairro</label>
          <input-control 
            v-model="fornecedor.bairro" 
            placeholder="Jardins" 
            name="bairro"
            type="text" 
            :disabled="loading" 
            readonly
          />
        </div>
        <div class="col-md-6 form-group no-padd-left">
          <label>Cidade</label>
          <select-control 
            v-model="fornecedor.idcidade" 
            placeholder="São Paulo" 
            :options="options.cidades" 
            name="idcidade" 
            type="text" 
            :disabled="loading" 
            readonly
          />
        </div>
        <div class="col-md-2 form-group no-padd-left">
          <label>UF</label>
          <select-control 
            v-model="fornecedor.idUf" 
            placeholder="SP" 
            :options="options.ufs" 
            name="idUf" 
            type="text" 
            :disabled="loading" 
            readonly
          />
        </div>
      </div>
      <div class="row no-padd" ref="fornecForm">
        <div class="col-md-12 form-group no-padd-left titleWrap">
          <h5>Regras de negócio</h5>
        </div>
        <div class="col-md-2 form-group no-padd-left">
          <label>Emitir nota no pagamento</label>
          <div class="switchWrapMini">
            <switch-control v-model:checked="fornecedor.emitirNota" labelOn="Emite" labelOff="Não emite" invert></switch-control>
          </div>
        </div>
        <div class="col-md-2 form-group no-padd-left">
          <label>Gerar Pedido no pagamento</label>
          <div class="switchWrapMini">
            <switch-control v-model:checked="fornecedor.gerarPedido" labelOn="Gerar" labelOff="Não gerar" invert></switch-control>
          </div>
        </div>
        <div class="col-md-2 form-group no-padd-left">
          <label>Gerar Pedido na venda</label>
          <div class="switchWrapMini">
            <switch-control v-model:checked="fornecedor.gerarPedidoVenda" labelOn="Gerar" labelOff="Não gerar" invert></switch-control>
          </div>
        </div>
        <div class="col-md-2 form-group no-padd-left">
          <label>Gerar nota na entrega</label>
          <div class="switchWrapMini">
            <switch-control v-model:checked="fornecedor.gerarNotaEntrega" labelOn="Gera nota na entrega" labelOff="Não gera nota na entrega" invert></switch-control>
          </div>
        </div>
      </div>
      <div class="row no-padd" ref="fornecForm">
        <div class="col-md-12 form-group no-padd-left titleWrap">
          <h5>Webmania</h5>
        </div>
        <div class="col-md-6 form-group no-padd-left">
          <label>Access Token</label>
          <input-control 
            v-model="fornecedor.webmaniaAccessToken" 
            placeholder="123-j0yTYITHhSmmj5bXQXEb1imGabK5agZFMsbzXxe19hnCZA8K" 
            name="webmaniaAccessToken" 
            type="text"
          />
        </div>
        <div class="col-md-6 form-group no-padd-left">
          <label>Access Token</label>
          <input-control
            v-model="fornecedor.webmaniaAccessToken" 
            placeholder="123-j0yTYITHhSmmj5bXQXEb1imGabK5agZFMsbzXxe19hnCZA8K" 
            name="webmaniaAccessToken" 
            type="text"
          />
        </div>
        <div class="col-md-6 form-group no-padd-left">
          <label>Consumer Key</label>
          <input-control 
            v-model="fornecedor.webmaniaConsumerKey" 
            placeholder="ATL2erJRDNm6QWcjpDwLnqxJSNt3iAJf" 
            name="webmaniaConsumerKey" 
            type="text"
          />
        </div>
        <div class="col-md-6 form-group no-padd-left">
          <label>Consumer Secret</label>
          <input-control 
            v-model="fornecedor.webmaniaConsumerSecret" 
            placeholder="HEJNDpjxZGbacbQzJJDtYLcJL5WWoLCIAuHsug5s3ysUhg3a" 
            name="webmaniaConsumerSecret" 
            type="text"
          />
        </div>
      </div>
      <footer class="action-wrap">
        <a href="#" class="btn btn-primary" @click.prevent="save()">
          Salvar
        </a>
        <a href="#" class="btn btn-secondary" @click.prevent="cancel()">
          Cancelar
        </a>
      </footer>
    </div>
  </section>
</template>

<script>
import { createSelectOptions, getCEP, clearMask } from '@/services/utils.service.js'
import UserService from '@/services/user.service.js'
import FormService from '@/services/form.service.js'
import MarketplaceService from '@/services/marketplace.service.js'

export default {
  props: {
    scope: {
      type: Object
    }
  },
  data () {
    return {
      fornecedor: null,
      loading: false,
      options: {
        ufs: [],
        cidades: []
      },
      wait: {
        ufs: false,
        cidades: false
      },
      valid: {
        nome: true
      }
    }
  },
  mounted () {
    this.fornecedor = this.scope
    window.spinner.emit('open')

    this.getUfs()
    this.getCidades()
  },
  methods: {
    getUfs () {
      UserService.ufs().then(
        response => {
          if ( response.data.length > 0 ) {
            console.log('ufs:', response.data)
            this.options.ufs = createSelectOptions(response.data,'idUf', 'uf')
            this.wait.ufs = true
            this.stopSpinner()
          } else {
            //
          }
        },
        error => {
          console.error(error)
          window.spinner.emit("close")
          this.loading = false
          this.loading = false
        }
      )
    },
    getCidades () {
      UserService.cidades().then(
        response => {
          if ( response.data.length > 0 ) {
            console.log('cidades:', response.data)
            this.wait.cidades = true
            this.options.cidades = createSelectOptions(response.data,'idCidade', 'nome')
            this.stopSpinner()
          } else {
            //
          }
        },
        error => {
          console.error(error)
          window.spinner.emit("close")
          this.loading = false
          this.loading = false
        }
      )
    },
    addCountryCode ( src, e ) {
      var target = this.fornecedor[src]
      const iniCode = '+55'
      // console.log("key:", e.key)
      const escape = ['Enter', 'Backspace', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Tab', 'Escape']
      const allowAddCode = !escape.includes(e.key)


      // console.log("target.length:", target.length)
      
      if ( !target && allowAddCode ) {
        const newValue = iniCode + target
        // console.log("newValue:", newValue)

        this.fornecedor[src] = newValue
      }
    },
    buscaCep () {
      const cep = this.fornecedor.cep
      // console.log('cep:', cep)
      
      if ( cep && cep.length == 9) {
        if ( this.searchCepLoop ) clearTimeout(this.searchCepLoop)

        this.searchCepLoop = setTimeout (() => {
          window.spinner.emit("open")
          this.loading = true
          getCEP(cep).then(
            response => {
              console.log("getCEP:", response)
              if ( response.data.erro == 'true' ) {
                //
              } else {
                this.populateEnderecoByCep(response.data)
              }
              this.loading = false
              window.spinner.emit("close")
            },
            error => {
              console.error(error)
              this.loading = false
              window.spinner.emit("close")
            }
          )
        },300)
        
      }
    },
    populateEnderecoByCep ( data ) {
      this.fornecedor.endereco = data.logradouro
      this.fornecedor.bairro = data.bairro
      this.fornecedor.cidade = data.localidade
      this.fornecedor.nomeUf = data.uf
      
      const searchCidades = this.options.cidades.filter( k => {
        return k.label.toLowerCase() == data.localidade.toLowerCase()
      })

      const idCidade = searchCidades[0].value
      this.fornecedor.idCidade = idCidade

      const searchUf = this.options.ufs.filter( k => {
        return k.label.toLowerCase() == data.uf.toLowerCase()
      })

      const idUf = searchUf[0].value
      this.fornecedor.idUf = idUf

      console.log(this.$refs.number)
      this.$refs.number.focus()
    },
    stopSpinner () {
      const done = !Object.values(this.wait).some( a => !a)
      if ( done ) {
        window.spinner.emit('close')
        this.loading = false
      }
    },
    save () {
      const valid = FormService.validate(this.$refs.fornecForm, this.valid)

      if ( valid.response ) {
        this.fornecedor.cnpj = clearMask('cpf', this.fornecedor.cnpj)
        delete this.fornecedor.cidade
        delete this.fornecedor.nomeUf
        delete this.fornecedor.idUf

        window.spinner.emit("open")
        MarketplaceService.saveFornecedor( this.fornecedor ).then(
          response => {
            console.log("response:", response)
            if ( response.status == 200 ) {
              const action = this.fornecedor.idFornecedor ? 'atualizado' : 'salvo'

              window.toaster.emit('open', {style: "success", message: "Fornecedor " + action + " com sucesso", floater: true})
              
            }
            window.spinner.emit("close")

            setTimeout(() => {
              this.cancel()
            }, 500)
          },
          error => {
            console.log("error:", error)
            window.spinner.emit("close")
            window.toaster.emit('open', {style: "warning", message: "Ops! Algo aconteceu. Não foi possível salvar este fornecedor. Tente novamente, se o problema persistir, informe o suporte.", floater: true})
          }
        )

      }
    },
    cancel () {
      this.$emit("onClose")
    }
  }
}
</script>

<style lang="scss" scoped>
  .titleWrap {
    padding: $hmg $hmg_small 0 $hmg_small !important
  }

  footer {
    display: flex;
    align-items: center; align-content: center;
    justify-content: flex-end;

    a {
      margin-left: $hmg_small
    }
  }
</style>