<template>
  <div class="turmasSelect" v-if="turmas">
    <card>
      <div class="turmas">
        <header>
          <div>
            <h4>Clique para selecionar a turma desejada</h4>
            <p>{{ turmas[0].siglaUnidade + " - " + turmas[0].descricaoUnidade }}</p>
            <p><b>{{ turmas[0].descricaoCurso + " - " + turmas[0].descricaoSerie + " - " + turmas[0].descricaoTurno }}</b></p>
          </div>
        <router-link class="btn btn-primary" v-if="turmas && currentUser" :to="{ name: 'Simulador de Preços', params: { idSerie: turmas[0].idSerie, idFuncao: currentUser.funcao.idFuncao, tipoMatricula: 2} }">
          Simulador de Preços&nbsp;
          <fa :icon="['far','calculator']" />
        </router-link>
        </header>
        <table class="content-table bordered-list">
          <thead>
            <tr>
              <th>
                Turma
              </th>
              <th>
                Capacidade
              </th>
              <th>
                Inscritos
              </th>
              <th>
                Vagas
              </th>
              <th>

              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="turma in turmas" :key="turma" @click="startNova(turma)">
              <td>
                {{ turma.descricao }}
              </td>
              <td>
                {{ turma.capacidade }}
              </td>
              <td>
                {{ turma.inscritos }}
              </td>
              <td>
                {{ turma.vagas }}
              </td>
              <td>
                <fa :icon="['far','face-smile-plus']"></fa>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </card>
  </div>
</template>

<script>
export default {
  props: {
    scope: {
      type: Array
    }
  },
  data () {
    return {
      turmas: null,
      matricula: null,
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
    }
  },
  watch: {
    scope ( value ) {
      if ( value ) {
        this.turmas = this.scope
      }
    }
  },
  mounted () {
    this.turmas = this.scope
    console.log(this.currentUser)
    this.$store.dispatch('matricula/get').then(response => {
      this.matricula = response
      console.log("matricula:", response)
    })
  },
  methods: {
    startNova ( turma ) {
      // console.log("turma:", turma)
      this.matricula.matricula.idPeriodoLetivo = turma.idPeriodoLetivo
      this.matricula.matricula.idCurso = turma.idCurso
      this.matricula.matricula.idSerie = turma.idSerie
      this.matricula.matricula.idTurma = turma.idTurma
      this.matricula.matricula.idUnidade = turma.idUnidade
      
      this.$store.dispatch('matricula/save').then(response => {
        console.log('save matricula:', response)
        this.$router.push({name: 'Nova Matrícula', params: { action: 'nova' } })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .turmasSelect {
    padding: $hmg_mid;

    .turmas {
      padding: $hmg;
      
      header {
        display: flex; align-items: flex-start;
        justify-content: space-between;
        padding: 0 $mg_mini $hmg;
        
        h4 {
          margin-bottom: $hmg_small;
        }
        p, h5 {
          margin: 0; padding: 0;
        }
      }
    }
  }
</style>