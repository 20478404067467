<template>
  <div :class="['selectMultiple',{ active: list.length > 0, disabled: disabled }]">
    <div class="selectMultipleInner">
      <p class="placeholder" v-if="list.length == 0">Selecione</p>
      <ul v-else>
        <li v-for="item in list" :key="item">
          <label class="check-control large justified">
            <span>{{ item.label }}</span>
            <input type="checkbox" class="check" :value="item.value" v-model="selected" @change="trackSelected">
          </label>  
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array
    },
    disabled: {
      type: Boolean
    },
    modelValue: {
      type: [Array, Object]
    },
    model: {
      type: String
    }
  },
  data () {
    return {
      list: [],
      selected: []
    }
  },
  watch: {
    options ( value ) {
      console.log("value:", value)
      if ( !value || value.length == 0 ) {
        this.list = []
      } else {
        this.list = value
        if( this.modelValue ) this.preset()
      }
    }
  },
  mounted () {
    this.list = this.options

    if ( this.modelValue ) this.preset()
  },
  methods: {
    preset () {
      if (this.modelValue.length > 0 && this.model && this.list.length > 0) {
          this.modelValue.map( k => {
          this.selected.push( k[this.model] )
        })
      }
    },
    trackSelected () {
      const selected = []

      this.selected.map( k => {
        this.options.map ( ko => {
          if ( k == ko[this.model] ) selected.push( ko )
        })
      })

      this.$emit("update:modelValue", selected)
    },
    clear () {
      this.selected = []
    }
  }
}
</script>

<style lang="scss" scoped>
  .selectMultiple {
    height: 50px; width: 100%;
    border-radius: $border-radius;
    background-color: $color-secondary;
    border: $border-component;
    @extend %transition_4e;

    &.active {
      height: 300px;
    }
    &.disabled {
      background-color: $color-fade;
    }

    .selectMultipleInner {
      height: 100%;
      padding: $hmg_small;
      position: relative;
      .placeholder {
        @extend %transition_4e;
        position: absolute;
        top: 50%; left: $hmg_small;
        transform: translateY(-50%);
        opacity: .3;
      }

      > ul {
        @extend %scrollbar_card;
        height: 100%; overflow-y: auto;
        padding: 0; margin: 0;

        li {
          list-style: none;
          line-height: 1;
          padding: 0; margin: 0;
        }
      }
      
    }
  }
</style>