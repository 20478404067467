<template>
  <section>
    <header>
      <h4>Segmentação</h4>
    </header>
    <div>
      <section class="cross-control">
        <div>
          <div>
            <autocomplete-box src="tipoMaterial" :single="true" reference="idtipomaterial" @select="selectItem('produto',$event)" @changeSelected="resetLists()" ref="tipoMaterialFilter" target="produto"></autocomplete-box>
          </div>
        </div>
        <transition name="slideup-small">
          <div class="loadingList" v-if="loadingSegmentacao">
            <p>Aguarde. Estamos carregando os segmentos deste produto.</p>
          </div>
        </transition>
        <transition name="slideup-small">
          <section v-if="!loadingSegmentacao && produto" class="newItemForm">
            <h4>Adicionar novo segmento</h4>
            <div class="row no-padd" ref="produtoFilterForm">
              <div class="col-md-6 form-group" >
                <label>Unidade</label>
                <select-control v-model="filter.unidade" name="unidade" :options="options.unidades" placeholder="Selecione" @change="getPeriodosLetivos()" :disabled="loading"/>
              </div>
              <div class="col-md-4 form-group">
                <label>Tipo de Curso</label>
                <select-control v-model="filter.tipoCurso" name="tipoCurso" :options="options.tipoCurso" placeholder="Selecione" :disabled="loading" @change="clearFilter('tipoCurso')"/>
              </div>
              <div class="col-md-4 form-group">
                <label>Período Letivo</label>
                <select-control 
                  v-model="filter.periodoLetivo" 
                  :options="options.periodosLetivos" 
                  placeholder="Selecione" 
                  @change="
                    getSeries(); 
                    clearFilter('periodoLetivo');
                    addPerCodigo('periodosLetivos')
                  "
                  :disabled="loading"
                />
                <input type="hidden" v-model="filter.perCodigo">
              </div>
              <div class="col-md-4 form-group">
                <label>Curso</label>
                <select-control v-model="filter.cursos" :options="options.cursos" placeholder="Selecione" :disabled="loading" @change="clearFilter('curso'); selectCurso(); "/>
              </div>
              <div class="col-md-6 form-group">
                <label>Séries</label>
                <div>
                  <select-multiple :options="options.series" v-model="filter.series" model="idSerie" @change="trackSerieChange" :disabled="loading" ref="selectSeries"/>
                </div>
              </div>
              <div class="col-md-12 form-actions">
                <a href="#" class="btn btn-primary" @click.prevent="addItems()">Adicionar items</a>
              </div>
            </div>
          </section>
        </transition>
        <section class="itemsListWrap" v-if="!loadingSegmentacao && segmentos.length > 0" >
          <h4>Segmentação Atual</h4>
          <div>
            <ol class="itemsList">
              <li>
                <div class="itemsListInner has-action">
                  <span>ID</span>
                  <span>Unidade</span>
                  <span>Segmento</span>
                  <span>Turmas Disponíveis</span>
                  <span></span>
                </div>
              </li>
              <transition-group name="slideLeftToRightSmall">
                <li v-for="(item, index) in segmentos" :key="item" :class="{ done: item.done, error: item.error }">
                  <card 
                    spacing="small" 
                    :alert="item.error" 
                    :subcard="true" 
                    :subcardVisible="item.subcard"
                    subcardPos="-26px"
                    @closeSub="toggleMaterialTurma(index, false)"
                  >
                    <div class="itemsListInner has-action">
                      <span>
                        {{ item.idMaterialSeguimento ? item.idMaterialSeguimento : "-" }}
                      </span>
                      <span>
                        {{ item.sigla }}
                      </span>
                      <span>
                        {{ item.nomeExibicao }}
                        <!-- <select-control :options="options.calendarios" v-model="item.idcalendarioentrega" placeholder="Selecione" cssClass="invert" :disabled="item.loading" required></select-control> -->
                      </span>
                      <span>
                        <a 
                          href="#" @click.prevent="toggleMaterialTurma(index, true, item.idMaterialSeguimento)" 
                          class="btn btn-primary" 
                          :disabled="item.subcard"
                          v-if="item.idMaterialSeguimento"
                        >Adicionar turmas</a>
                        <span class="tag" v-else>Pendente</span>
                      </span>
                      <span class="itemsListActions">
                        <input-spinner v-if="item.loading"></input-spinner>
                        <template v-else>
                          <a href="#" @click.prevent="confirmDelete(index)">
                            <fa :icon="['far','trash-can']"></fa>
                          </a>
                        </template>
                      </span>
                    </div>
                    <template v-slot:subcard>
                      <div class="">
                        <div class="searchWrap">
                          <input-spinner v-if="item.loading" class="searchSpinner"></input-spinner>
                          <input-control 
                            v-model="item.query" 
                            placeholder="Buscar Turma.." 
                            :disabled="item.loading" 
                            :valid="item.valid"
                            @keyup="search($event,index)" 
                            cssClass="invert"
                          ></input-control>
                          <input type="hidden" v-model="item.idSelectedTurma">
                          <!-- <a href="#" class="remove" @click.prevent="remove(i)">
                            <fa :icon="['fas','trash-can']"></fa>
                          </a> -->
                          <div class="listWrap" v-if="item.list && item.list.length > 0">
                            <div class="list">
                              <ul>
                                <li v-for="li in item.list" :key="li" @click="selectTurma(li,index)">
                                  {{ li.descricao }}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <template v-if="item.turmas.length > 0">
                        <div class="turmaList">
                          <transition-group name="fadein" tag="ul">
                              <li v-for="(turma, it) in item.turmas" :key="turma">
                                <span>
                                  {{ turma.descricao }} - {{ turma.detalhes }}
                                </span>
                                <a href="#" @click.prevent="confirmRemoveTurma(turma, it, index)">
                                  <fa :icon="['far','trash-can']"></fa>
                                </a>
                              </li>
                            </transition-group>
                        </div>
                        <div class="turmaAction">
                          <a href="#" @click.prevent="saveTurmas(index)" class="btn btn-primary">Salvar Turmas</a>
                        </div>
                      </template>
                    </template>
                  </card>
                </li>
              </transition-group>
            </ol>
          </div>
        </section>
      </section>
      <footer>
        <a href="#" class="btn btn-primary" @click.prevent="saveList()" v-if="allowSave">
          <span>
            Salvar Segmentação
          </span>
        </a>
      </footer>
    </div>
    <confirm 
      :backdrop="false"
      class="confirm"
      ref="confirm"
    ></confirm>
  </section>
</template>

<script>
import AutocompleteBox from '@/components/marketplace/parametrizacao/utilities/AutocompleteBox'
import MarketplaceService from '@/services/marketplace.service'
import inputSpinner from '@/components/utilities/InputSpinner'
import { 
  createSelectOptions, 
  formatMoney
} from "@/services/utils.service"
import UserService from '@/services/user.service.js'
import AcademicoService from '@/services/academico.service.js'

export default {
  components: {
    AutocompleteBox, inputSpinner
  },
  data () {
    return {
      segmentos: [],
      produto: null,
      segmentacoes: [],
      calendarios: [],
      loadingSegmentacao: false,
      itemToDelete: null,
      done: {
        calendarios: false,
        composicao: false,
        itemMaterial: false
      },
      filter: {
        unidade: null,
        periodoLetivo: null,
        perCodigo: null,
        tipoCurso: null,
        cursos: null,
        series: null,
        turmas: null
      },
      wait: {
        unidades: false,
        tipoCurso: false,
        cursos: false
      },
      options: {
        unidades: [],
        tipoCurso: [],
        periodosLetivos: [],
        series: [],
        turmas: [],
        cursos: [],
        todosPeriodosLetivos: []
      },
      total: null,
      loading: false,
      query: null,
      waitSearch: null,
      allowSave: false
    }
  },
  mounted () {
    // this.getCalendarios()
    this.getUnidades()
    this.getTipoCurso()
    this.getCursos()
  },
  methods: {
    selectItem ( src, value ) {
      // console.log("src", src)
      // console.log("value", value)

      if ( src === 'produto' ) {
        this.produto = value[0]
        console.log("produto", this.produto)
        if ( this.produto ) {
          this.getSegmentosPermitidos(this.produto.idtipomaterial)
        }
      } else {
        this.kits = value
      }
      
      console.log("selectItem:", value)
    },
    getSegmentosPermitidos ( id ) {
      this.loadingSegmentacao = true
      console.log("getSegmentosPermitidos:", id)
      MarketplaceService.getSegmentosPermitidos( id ).then(
        response => {
          console.log("getSegmentos:", response.data)
          if ( response.data.length > 0 ) {
            // this.$refs.itemMaterialFilter.restore()
            const segmentos = response.data
            segmentos.map( k => {
              k.loading = false
              k.list = null
              k.query = null
              k.valid = true
              k.idSelectedTurma = null
              k.subcard = false
              k.turmas = []
            })

            this.$nextTick(() => {
              this.segmentos = segmentos
            })
            
            console.log("response composicaomaterial:", response.data)
          } else {
            this.segmentacoes = []
            window.toaster.emit('open', {style: "warning", message: "Não encontramos segmentos.", floater: true})
          }
          
          this.loadingSegmentacao = false
          // this.getTotal()
        },
        error => {
          console.log("error:", error)
          window.toaster.emit('open', {style: "warning", message: "Não foi possível encontrar segmentos neste momento.", floater: true})
          window.spinner.emit("close")
        }
      )
    },
    getSegmentoTurmas ( id, i ) {
      // this.loadingSegmentacao = true
      console.log("getSegmentoTurmas:", id)
      this.segmentos[i].turmas = []

      MarketplaceService.getSegmentoTurmas( id ).then(
        response => {
          console.log("getSegmentoTurmas:", response.data)
          if ( response.data.length > 0 ) {
            this.segmentos[i].turmas = response.data
            console.log("response getSegmentoTurmas:", response.data)
          } else {
            // this.segmentacoes = []
            window.toaster.emit('open', {style: "warning", message: "Não existem turmas para este segmento.", floater: true})
          }
          
          // this.loadingSegmentacao = false
          // this.getTotal()
        },
        error => {
          console.log("error:", error)
          window.toaster.emit('open', {style: "warning", message: "Ops. Algo aconteceu. Não foi possível encontrar turmas para este segmento neste momento.", floater: true})
          window.spinner.emit("close")
        }
      )
    },
    getUnidades () {
      UserService.getMinhasUnidades().then(
        response => {
          console.log("getMinhasUnidades:", response.data)
          this.options.series = []
          this.options.periodosLetivos = []
          
          if ( response.data.length > 0 ) {
            this.options.unidades = createSelectOptions(response.data, 'idUnidade' ,'descricao')
            this.wait.unidades = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhuma unidade encontrada. Verifique sua conexão e tente novamente.", floater: true})
          }
          
          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma unidade encontrada. Verifique sua conexão e tente novamente.", floater: true})
        }
      )
    },
    getTipoCurso () {
      AcademicoService.getTipoCurso().then(
        response => {
          console.log("getTipoCurso:", response.data)
          if ( response.data.length > 0 ) {
            this.options.tipoCurso = createSelectOptions(response.data, 'idTipoCurso' ,'descricao')
            this.wait.tipoCurso = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum tipo de curso encontrado. Verifique sua conexão e tente novamente.", floater: true})
          }
          
          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum tipo de curso encontrado. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    getCursos () {
      AcademicoService.getCursos().then(
        response => {
          console.log("getCursos:", response.data)
          if ( response.data.length > 0 ) {
            this.cursos = response.data
            this.options.cursos = createSelectOptions(response.data, 'idCurso' ,'descricao')
            this.wait.cursos = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum curso encontrado. Isto parece ser um problema de parametrização.", floater: true})
          }

         
          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum curso encontrado. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    getPeriodosLetivos () {
      this.loading = true
      AcademicoService.getPeriodosLetivos(this.filter.unidade).then(
        response => {
          console.log("getPeriodosLetivos:", response.data)
          
          if ( response.data.length > 0 ) {
            this.options.periodosLetivos = createSelectOptions(response.data, 'idPeriodoLetivo' ,'descricao').reverse()
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum período letivo encontrado para esta unidade. Isto parece ser um problema de parametrização.", floater: true})
          }
          this.loading = false
        },
        error => {
          console.error(error)
          this.loading = false
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum período letivo encontrado para esta unidade. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    getSeries () {
      if ( this.filter.tipoCurso ) {
        this.loading = true
        AcademicoService.getSeries(this.filter.periodoLetivo, this.filter.tipoCurso).then(
          response => {
            console.log("getSeries:", response.data)
            this.options.series = []

            if ( response.data.length > 0 ) {
              this.series = response.data
              response.data.map( k => {
                k.label = k.descricao + ' ' + k.descricaoTurno
              })
              this.options.series = createSelectOptions(response.data, 'idSerie' ,'label')
              
            } else {
              window.toaster.emit('open', {style: "error", message: "Nenhuma série encontrada para este período letivo. Isto parece ser um problema de parametrização.", floater: true})
            }

            this.loading = false
          },
          error => {
            console.error(error)
            this.loading = false
            window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma série encontrada para este período letivo. Isto parece ser um problema de parametrização.", floater: true})
          }
        )
      }
    },
    clearFilter ( part, resetState ) {
      this.$emit('onClearFilter', resetState)

      if ( !part ) {
        this.filter = {
          unidade: null,
          periodoLetivo: null,
          tipoCurso: null,
          cursos: null,
          series: null
        }
        this.series = null
        this.options.series = []
        this.options.turmas = []
        this.$emit('clear')
      } else if ( part === 'tipoCurso' ) {
        this.filter.cursos = null
        this.filter.periodoLetivo = null
        this.filter.series = null
        this.options.series = this.series
      } else if ( part === 'periodoLetivo' ) {
        this.filter.cursos = null
        this.filter.series = null
        this.options.series = this.series
        this.$refs.selectSeries.clear()
      } else if ( part === 'curso' ) {
        this.filter.series = null
        this.options.series = this.series
        this.$refs.selectSeries.clear()
      }
      
    },
    addPerCodigo ( src ) {
      const perCodigo = this.options[src].filter( k => k.value == this.filter.periodoLetivo )[0].label
      this.filter.perCodigo = perCodigo
    },
    trackSerieChange () {
      console.log("trackSerieChange:", this.filter.series)
      
    },
    selectCurso () {
      const selected = this.filter.cursos
      this.loading = true
      
      const result = this.series.filter( k => {
        return k.idCurso == selected
      })

      if ( result.length > 0 ) {
        this.options.series = createSelectOptions(result, 'idSerie' ,'label')
      } else {
        this.options.series = []
      }
      this.loading = false
    },
    saveList () {
      const drop = this.segmentos.filter( k => !k.idMaterialSeguimento )

      console.log("produto:", this.produto)
      const total = drop.length

      
      if ( drop.length > 0 ) {
        drop.map( (k, i) => {
          console.log("k:", k)
          
          const data = {
            idtipomaterial: this.produto.idtipomaterial,
            idSerie: k.idSerie,
            recebedorpadrao: 0
          }
          
          console.log("data:", data)

          k['loading'] = true
          this.allowSave = false

          MarketplaceService.saveMaterialSegmento(data).then(
            response => {
              console.log("saveMaterialSegmento: ", response)
              k['loading'] = false

              if ( i == ( total - 1 ) ) {
                this.allowSave = true

                this.getSegmentosPermitidos(this.produto.idtipomaterial)
              }
            },
            error => {
              console.log("error:", error)
              k['loading'] = false
              k['error'] = true
              // this.loadingItems = false
            }
          )
          
        })
      }
      
    },
    validate ( object ) {

      const response = Object.values(object).filter( k => {
        return !k && k != 0
      })
      
      return response.length == 0
    },
    confirmDelete ( item ) {
      this.$refs.confirm.run({
        message: "Tem certeza que deseja remover este item?",
        confirmButton: "Sim",
        denyButton: "Não",
        data: item,
        callback: this.delete,
      })
    },
    delete ( response, data ) {
      if ( response ) {
        const target = this.segmentos[data]

        if ( target.idMaterialSeguimento ) {
          MarketplaceService.deleteMaterialSegmento(target.idMaterialSeguimento).then(
            response => {
              console.log("deleteMaterialSegmento: ", response)
              if ( response.data ) {
                // this.$refs.itemMaterialFilter.stateItem(false, target)
                this.$nextTick(() => {
                  this.segmentos.splice(data, 1)
                })
                
              } else {
                target['error'] = true
              }
              
              target['loading'] = false
            },
            error => {
              console.log("error:", error)
              target['loading'] = false
              // this.loadingItems = false
            }
          )
        } else {
          this.segmentos.splice(data, 1)
        }
        
      }      
    },
    exclude () {
      const waitToExclude = setInterval( () => {
        if ( this.segmentos.length > 0 ) {
          clearInterval(waitToExclude)

          this.segmentos.map( k => {
            console.log("exclude - segmentos - k:", k)
            this.$refs.itemMaterialFilter.stateItem(true, k)
          })
        }
      },100)
    },
    formatMoney ( value ) {
      return formatMoney(value)
    },
    search ( e, i ) {
      console.log("i:", i)
      console.log("segmentos:", this.segmentos)
      const query = this.segmentos[i].query
      
      console.log("query:", query)

      if ( query && query.length >= 2 ) {
        if ( this.waitSearch ) clearInterval(this.waitSearch)
        console.log("search:", query)
      
        this.waitSearch = setTimeout ( () => {
          this.segmentos[i].loading =  true
          const idUnidade = this.segmentos[i].idUnidade
          const idPeriodoLetivo = this.segmentos[i].idPeriodoLetivo

          console.log("idUnidade:", idUnidade)
          console.log("idPeriodoLetivo:", idPeriodoLetivo)
          console.log("query:", query)

          AcademicoService.searchTurmas(idUnidade, idPeriodoLetivo, query).then(
            response => {
              // console.log("response:", response.data)
              if ( response.data.length > 0 ) {
                this.segmentos[i].list = response.data
              } else {
                this.segmentos[i].valid = false
              }
              this.segmentos[i].loading = false
            },
            () => {
              this.segmentos[i].loading = false
            }
          )
        }, 500)
      } else{
        this.segmentos[i].valid = true
      }

    },
    resetLists () {
      this.segmentos = []
      // this.getTotal()
    },
    toggleMaterialTurma ( i, state, idSegmento ) {
      this.segmentos[i].subcard = state

      if ( state ) {
        this.getSegmentoTurmas(idSegmento, i)
      }
      
    },
    finishLoad () {
      const finished = !Object.values(this.wait).some( a => !a)

      if ( finished ) {
        window.spinner.emit("close")
        this.loading = false
      }
      // console.log("finished:", finished)
    },
    addItems () {
      const idUnidade = this.filter.unidade
      const unidade = this.options.unidades.filter( k => k.idUnidade == idUnidade )[0]
      const segmentos = this.filter.series

      segmentos.map( (k, i) => {
        const item = {
          idUnidade: unidade,
          sigla: k.siglaUnidade,
          nomeExibicao: k.nomeExibicao,
          idSerie: k.idSerie,
          loading: false,
          list: null,
          query: null,
          valid: true,
          idSelectedTurma: null,
          subcard: false,
          turmas: []
        }

        const exists = this.segmentos.length > 0 ? this.segmentos.some( a => a.idSerie == item.idSerie) : false

        console.log("exists:", exists)

        if ( !exists ) {
          setTimeout (() => {
            this.allowSave = true
            this.segmentos.unshift(item)
          }, 400 * i)
        }
        else  window.toaster.emit('open', {style: "warning", message: `A série ${ item.nomeExibicao } já existe.`, floater: true})
      })

      console.log("this.segmentos:", this.segmentos)
    },
    selectTurma ( item, index ) {
      console.log("item:", item)
      console.log("index:", index)

      const target = this.segmentos[index]

      target.list = null

      const exists = target.turmas.length > 0 ? target.turmas.some( a => a.idTurma == item.idTurma) : false

      if ( !exists ) target.turmas.push(item)
      else window.toaster.emit('open', {style: "warning", message: `A turma ${ item.descricao } já existe.`, floater: true})
    },
    confirmRemoveTurma ( item, index, parent ) {
      console.log("item:", item)

      this.$refs.confirm.run({
        message: "Tem certeza que deseja remover esta turma?",
        confirmButton: "Sim",
        denyButton: "Não",
        data: { item, index, parent },
        callback: this.removeTurma,
      })
    },
    removeTurma ( response, data ) {
      console.log("response:", response)
      console.log("data:", data)
      if ( response ) {
        if ( data.item.idtipomaterialturmas ) {
          window.spinner.emit("open")
          MarketplaceService.deleteMaterialTurmas( data.item.idtipomaterialturmas ).then(
            response => {
              console. log("response:", response)
              this.segmentos[data.parent].turmas.splice(data.index,1)
              window.spinner.emit("close")
            },
            () => {
              window.toaster.emit('open', {style: "warning", message: `Não foi possível salvar a turma ${ data.item.descricao }.`, floater: true}) 
              window.spinner.emit("close")   
            }
          )
        } else {
          this.segmentos[data.parent].turmas.splice(data.index,1)
        }
      }
    },
    saveTurmas ( index ) {
      const drop = this.segmentos[index].turmas.filter( k => !k.idtipomaterialturmas )
      console.log("drop:", drop)

      const total = drop.length
      
      if ( total > 0 ) {
        window.spinner.emit("open")

        drop.map( (k,i) => {
          const idMaterialSegmento = this.segmentos[index].idMaterialSeguimento
          const data = {
            idMaterialSeguimento: idMaterialSegmento,
            idTurma: k.idTurma
          }
          MarketplaceService.saveMaterialTurmas( data ).then(
            response => {
              console. log("response:", response)

              if ( i == ( total - 1 )) {
                window.spinner.emit("close")
                this.getSegmentoTurmas(idMaterialSegmento, index)
              }
            },
            () => {
              window.toaster.emit('open', {style: "warning", message: `Não foi possível salvar a turma ${ k.descricao }.`, floater: true})
              window.spinner.emit("close")  
            }
          )
        })
      } else {
        window.toaster.emit('open', {style: "warning", message: `Não existem itens para serem salvos.`, floater: true})
      }
      
       
    }
  }
}
</script>

<style lang="scss" scoped>
  header {
    padding: 0 0 $hmg_small 0;
  }

  footer {
    display: flex; justify-content: flex-end;
    padding: $hmg_mid;

    .total {
      display: flex; align-content: center; align-items: center;
      justify-content: space-between;
      width: 30%;
      padding: $hmg_mid;
      background-color: $color-secondary;
      border-radius: $border-radius;

      >* {
        margin: 0; padding: 0;
      }

      &:first-child {
        width: 20%;
        margin-right: $hmg_mid;
      }
    }
  }

  .cross-control {
    display: flex; flex-direction: column;

    >div {
      display: flex; width: calc(100% + $mg_mini); height: auto;
      align-content: stretch; align-items: stretch;
      margin-left: -$hmg_mid;

      > div {
        width: 100%; max-width: 50%;
        margin-right: $mg;

        &:last-child {
          margin-right: 0;
        }
      }

      .waiting {
        position: relative;
        width: 100%; height: 100%;
        border: 3px dashed $color-secondary;
        border-radius: $border-radius-larger;

        > div {
          position: absolute;
          margin: auto; top: 50%; transform: translateY(-50%);
          text-align: center;
          left: 0; right: 0;

          .icon {
            font-size: 100px;
            margin-bottom: $hmg_small
          }

        }
      }
    }
    
    .itemsListWrap {
      margin-top: $mg;

      h4 { 
        padding: 0 $hmg_mid;
      }
      > div {
        margin-top: $hmg_small
      }
    }

    .itemsList {
      display: block;
      width: 100%;
      padding: 0; margin: 0;

      li {
        list-style: none;
        width: 100%; 
        padding: 0; margin: 0;
        @extend %transition_4o;

        .itemsListInner {
          display: grid; width: 100%;
          padding: $mg_mini $hmg_small;
          align-items: center; align-content: center;
          grid-template-columns: 3fr 2fr 1fr 1fr;

          &.has-action {
            grid-template-columns: 2fr 2fr 2fr 1fr .2fr;
          }
          

          > span {
            padding: 0 $hmg_mini;

            &.actionWrap {
              display: flex; align-items: center;
              justify-content: flex-end;
            }

            .syncIcon {
              display: flex; flex-direction: column;
              align-content: center; align-items: center; justify-content: center;
              width: 28px; height: 28px;
              background-color: $color-success-2;
              padding: 0; margin: 0;
              line-height: 1;
              color: $color-secondary;
              border-radius: 100%;
              text-align: center;
            }

            .fixed {
              white-space: nowrap;
            }

            a:not(.btn) {
              color: $color-primary;

              &:hover {
                color: $color-dev
              }
            }
          }
        }

        .plItemDetails {
          padding: $hmg;
        }

        .plTableFooter {
          padding-top: $hmg;
          display: flex;
          align-content: center; align-items: center;
          justify-content: flex-end;

          >.btn {
            margin-left: $mg_small;
          }
        }
      }
    }
  }

  .itemsListActions {
    display: flex; align-items: center; align-content: center;
    justify-content: flex-end;
  }

  .newItemForm {
    margin-top: $mg;

    h4 {
      margin-bottom: $hmg;
      padding-left: $hmg
    }
  }

  .searchWrap {
    position: relative;
    width: 60%; min-width: 280px; max-width: 540px;
    .remove {
      position: absolute;
      right: $hmg_small; top: 50%;
      transform: translateY(-50%);

      >* {
        @extend %transition_3o;
      }
      
      &:hover {
        color: $color-primary
      }
    }
  }

  .listWrap {
    position: relative;
    width: 100%;

    .list {
      position: absolute; 
      width: 100%; height: 180px;
      left: $hmg_mini; top: -$hmg_mini;
      overflow: hidden;
      z-index: 100;
      padding: $hmg_small;
      background-color: $color-secondary;
      border-radius: $border-radius;
      @extend %shadow-high;

      ul {
        padding: 0; margin: 0; max-height: 100%;
        overflow-y: auto;
        @extend %scrollbar;

        li {
          list-style: none;
          padding: $hmg_mini;
          margin: 0;
          @extend %transition_3o;
          cursor: pointer;

          &:hover {
            background-color: $color-bg;
            border-radius: $border-radius-small;
          }
        }
      }
    }
  } 

  .searchSpinner { 
    position: absolute; top: 50%;
    right: $hmg;
    transform: translateY(-55%);
    z-index: 100;
  }

  .tag {
    padding: $hmg_mini $mg_mini;
    background-color: $color-alert;
    color: $color-secondary;
    text-align: center;
    border-radius: 2em;
  }

  .turmaList {
    width: 100%;
    margin-top: $hmg;
    @extend %transition_4o;

    ul {
      padding: 0; margin: 0;

      li {
        display: flex;
        align-items: center; justify-content: space-between;
        list-style: none; padding: $mg_mini !important;
        border-bottom: 1px solid $color-bg;
        @extend %transition_3o;

        a {
          &:hover {
            color: $color-primary
          }
        }
        &:hover {
          background-color: $color-bg;
          border-radius: $border-radius-small
        }
      }
    }
  }

  .turmaAction {
    margin-top: $hmg
  }
</style>