<template>
  <section>
    <ul class="planos" v-if="planos">
      <li v-for="( plano, i ) in planos" :key="plano" :class="{ selected: matricula.planoPag.idprecificacao == plano.idprecificacao || plano.selected }">
        <div>
          <span v-if="plano.interno" class="internoTag">Interno</span>
          <p>Opção {{ i + 1 }}</p>
          <h2 class="color-primary">{{ plano.descricao }}</h2>
          <div class="valorTotal">
            <p>De: <del>{{ formatMoney(parseFloat(plano.valorTotal)) }}</del></p>
            <p><b>Por: {{ formatMoney(parseFloat(plano.valorTotalDesconto)) }} <span class="color-success">com {{ plano.desconto }}% de desconto</span></b></p>
          </div>
          <div class="discountMessage">
            <fa :icon="['fas','badge-dollar']" class="icon"></fa>
            <span>Você economiza {{ formatMoney(calcDiscountDif(plano)) }}</span>
          </div>
        </div>
        <div>
          <div class="planoBox" v-if="plano.fixa">
            <header>
              Entrada
            </header>
            <div>
              <div class="months">
                <p><b>{{ plano.fixa.numeroParcelas }} {{ plano.fixa.numeroParcelas == 1 ? 'Parcela' : 'Parcelas' }}</b></p>
                <div>
                  <span></span>
                </div>
                <p>{{ dropDate(plano.fixa.mesInicio) }}</p>
              </div>
              <div class="price">
                <div>
                  <p>De</p>
                  <p><del>{{ formatMoney(plano.fixa.valorTotal) }}</del></p>
                </div>
                <div>
                  <p>Por</p>
                  <p><b><span>{{ formatMoney(plano.fixa.valorTotalDesconto) }}</span></b></p>
                </div>
              </div>
            </div>
          </div>
          <div class="planoBox" v-if="plano.entrada">
            <header>
              Primeira Mensalidade
            </header>
            <div>
              <div class="months">
                <p>{{ dropDate(plano.entrada.mesInicio) }}</p>
                <div>
                  <span></span>
                  <p>{{ plano.entrada.numeroParcelas }} Parcelas</p>
                </div>
                <p>{{ dropDate(plano.entrada.mesFinal) }}</p>
              </div>
              <div class="price">
                <div>
                  <p>De</p>
                  <p>{{ plano.entrada.numeroParcelas }}x de <b><del>{{ formatMoney(plano.entrada.valorParcela) }}</del></b></p>
                  <p><del>{{ formatMoney(plano.entrada.valorTotal) }}</del></p>
                </div>
                <div>
                  <p>Por</p>
                  <p>{{ plano.entrada.numeroParcelas }}x de <b><span>{{ formatMoney(plano.entrada.valorParcelaDesconto) }}</span></b></p>
                  <p>{{ formatMoney(plano.entrada.valorTotalDesconto) }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="planoBox" v-if="plano.mensalidades">
            <header>
              {{ plano.entrada && 'Demais' }} Mensalidades
            </header>
            <div>
              <div class="months">
                <p>{{ dropDate(plano.mensalidades.mesInicio) }}</p>
                <div>
                  <span></span>
                  <p>{{ plano.mensalidades.numeroParcelas }} Parcelas</p>
                </div>
                <p>{{ dropDate(plano.mensalidades.mesFinal) }}</p>
              </div>
              <div class="price">
                <div>
                  <p>De</p>
                  <p>{{ plano.mensalidades.numeroParcelas }}x de <b><del>{{ formatMoney(plano.mensalidades.valorParcela) }}</del></b></p>
                  <p><del>{{ formatMoney(plano.mensalidades.valorTotal) }}</del></p>
                </div>
                <div>
                  <p>Por</p>
                  <p>{{ plano.mensalidades.numeroParcelas }}x de <b><span>{{ formatMoney(plano.mensalidades.valorParcelaDesconto) }}</span></b></p>
                  <p>{{ formatMoney(plano.mensalidades.valorTotalDesconto) }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="planoBox" v-if="plano.anuidade">
            <header>
              Anuidade
            </header>
            <div>
              <div class="months">
                <p><b>1 Parcela</b></p>
                <div>
                  <span></span>
                </div>
                <p>{{ dropDate(plano.anuidade.mesFinal) }}</p>
              </div>
              <div class="price">
                <div>
                  <p>De</p>
                  <p><del>{{ formatMoney(plano.anuidade.valorTotal) }}</del></p>
                </div>
                <div>
                  <p>Por</p>
                  <p><b><span>{{ formatMoney(plano.anuidade.valorTotalDesconto) }}</span></b></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a href="#" @click.prevent="selectPlano(plano, i)">
          {{ plano.selected ? 'Selecionado' : 'Matricule-se já!' }}
        </a>
      </li>
    </ul>
  </section>
</template>

<script>
// import UserService from '@/services/user.service'
import AcademicoService from '@/services/academico.service'
import MatriculaService from '@/services/matricula.service'
// import FormService from '@/services/form.service'
import { formatMoney, formatDate, unformatDate } from '@/services/utils.service'

export default {
  components: {

  },
  props: {
    auto: {
      type: Boolean,
      default: false
    },
    idMatricula: {
      type: [ Number, String ]
    },
    action: {
      type: String
    }
  },
  data () {
    return {
      options:{
        turmas: null,
        vencimentos: [
          { label: '5', value: 5 },
          { label: '10', value: 10 }
        ],
        meses: {
          1: 'jan',
          2: 'fev',
          3: 'mar',
          4: 'abr',
          5: 'mai',
          6: 'jun',
          7: 'jul',
          8: 'ago',
          9: 'set',
          10: 'out',
          11: 'nov',
          12: 'dez'
        },
        arrecadacoes: {
          'ENT': 'entrada',
          'MEN': 'mensalidades',
          'ANU': 'anuidade'
        }
      },
      wait: {
        turmas:  false,
        planosPagamento: false
      },
      matricula: null,
      planos: null,
      planoModel: null,
      selectedPlano: null
    }
  },
  mounted () {
    window.spinner.emit("open")

    this.$store.dispatch('matricula/get').then(response => {
      // console.log("matricula:", response)
      this.matricula = response
      
      if ( this.matricula.matricula.idMatricula ) {
        this.getTurmas(this.matricula.matricula.idSerie)
        this.getPlanosPagamento(this.matricula.matricula.idMatricula)
      } else {
        // this.getTurmas(this.matricula.matricula.idSerie)
        this.getAluno()
        this.getPlanosPagamento(this.idMatricula)
      }

      // console.log('idprecificacao:', this.matricula.planoPag.idprecificacao)
      this.planoModel = Object.assign({},this.matricula.planoPag)
      
      console.log('auto:', this.auto)
    })
  },
  methods: {
    getTurmas ( serie ) {
      this.loadingFilterData = true
      AcademicoService.getTurmas(serie).then(
        response => {
          console.log("getTurmas:", response.data)
          this.options.turmas = response.data

          this.wait.turmas = true
          this.loadingFilterData = false

          this.stopSpinner()
        },
        error => {
          console.error(error)
          this.loadingFilterData = false
        }
      )
    },
    getPlanosPagamento ( idMatricula ) {
      idMatricula = idMatricula ? idMatricula : this.matricula.matricula.idMatricula
      
      MatriculaService.getPlanosPagamento(idMatricula).then(
        response => {
          //console.log("getPlanosPagamento", response)
          this.$emit('finalDate', response.data[0].dataFinal)
          if ( response.data.length > 0 ) {
            var planos
            if ( this.auto ) {
              planos = response.data.filter( k => {
                return k.interno == 0 && k.planosPagamento
              })
            } else {
              planos = response.data.filter( k => {
                return k.planosPagamento
              })
            }
            this.planos = this.processPlanos(planos)
            
            console.log("this.planos:", this.planos)

            this.trackSelectedPlan()
          } else {
            window.toaster.emit('open', {style: "error", message: "Não encontramos planos de pagamento disponível para esta matrícula.", floater: true})
          }
          
          this.wait.planosPagamento = true

          this.stopSpinner()
        },
        error => {
          console.error(error)
        }
      )
    },
    processPlanos ( planos ) {
      const newPlanos = []
      planos.map( k => {
        // console.log("k:", k)
        const fixa = k.planosPagamento.filter( a => a.idTipoArrecadacao == 16)

        if ( fixa.length > 0 ) {
          // console.log("has fixa:", fixa[0])
          k['fixa'] = fixa[0]
        }

        const p = {
          ...k
        }
        const total = this.sumValuesByDate(k.planosPagamento)
        // console.log('total:', total)

        total.map( t => {
          // console.log('t', t)

          const target = this.options.arrecadacoes[t.tipo]
          p[target] = {
            ...t
          }
        })
        
        newPlanos.push(p)
      })
      // console.log("planos:", newPlanos)

      return newPlanos
    },
    sumValuesByDate(data) {
      return Object.values(data.reduce((acc, object) => {
        // console.log('object:', object)
        const type = object.siglaFront

        acc[type] = acc[type] || { 
          dataFinal: object.dataFinal,
          dataInicio: object.dataInicio,
          mesInicio: object.mesInicio,
          valorTotal: 0,
          valorTotalDesconto: 0,
          valorParcela: 0,
          valorParcelaDesconto: 0,
          mesFinal: null,
          numeroParcelas: object.numeroParcelas,
          tipo: object.siglaFront
        }
        acc[type].valorTotal += Number(object.valorTotal)
        acc[type].valorTotalDesconto += Number(object.valorTotalDesconto)
        acc[type].valorParcela += Number(object.valorParcela)
        acc[type].valorParcelaDesconto += Number(object.valorParcelaDesconto)

        const parseMesInicio = object.mesInicio.split("/")
        const mesInicio = parseFloat(parseMesInicio[0])
        const mesFinal = mesInicio + ( object.numeroParcelas - 1 )
        acc[type].mesFinal = mesFinal + '/' + parseMesInicio[1]

        return acc
      }, {}))
    },
    dropDate ( date ) {
      const parsed = date.split('/')
      const year = parsed[1].slice(-2)
      return this.options.meses[parseFloat(parsed[0])] + '/' + year
    },
    getAluno () {
      this.$store.dispatch('matricula/get').then(response => {
        this.matricula = response
        if ( this.idMatricula ) {
          MatriculaService.getAlunoByIdMatricula( this.idMatricula ).then(
            response => {
              // console.log("getAuno:", response.data)
              this.matricula.Aluno.Aluno = response.data.aluno.Aluno ? response.data.aluno.Aluno : this.matricula.Aluno.Aluno
              this.matricula.Aluno.Basicos = response.data.aluno.Basicos ? response.data.aluno.Basicos : this.matricula.Aluno.Basicos
              this.matricula.Aluno.Basicos.dataNascimento = this.matricula.Aluno.Basicos.dataNascimento ? unformatDate(this.matricula.Aluno.Basicos.dataNascimento) : this.matricula.Aluno.Basicos.dataNascimento
              this.matricula.Aluno.Documento = response.data.aluno.Documento ? response.data.aluno.Documento : this.matricula.Aluno.Documento
              this.matricula.Aluno.Endereco = response.data.aluno.Endereco ? response.data.aluno.Endereco : this.matricula.Aluno.Endereco
              
              this.matricula.matricula = response.data.matricula ? response.data.matricula : this.matricula.matricula

              if ( !this.matricula.Aluno.Basicos.nomeDocumento ) {
                this.matricula.Aluno.Basicos.nomeDocumento = this.matricula.Aluno.Basicos.nome
              }
              
              if ( !this.matricula.Aluno.Basicos.nomeDocumento ) {
                this.matricula.Aluno.Basicos.nomeDocumento = this.matricula.Aluno.Basicos.nome
              }
              
              this.$store.dispatch('matricula/save', this.steps).then(() => {
                 this.getTurmas(this.matricula.matricula.idSerie)
              })
              
            },
            error => {
              console.error(error.response.data.message)
            }
          )
        } else {
          this.loaded = true
          this.$emit('ready')
        }
      })      
    },
    stopSpinner () {
      const done = !Object.values(this.wait).includes(false)
      if ( done ) {
        setTimeout(() => {
          window.spinner.emit("close")
          this.$emit('ready')
        },1000)
      }
    },
    validate () {
      const valid = this.matricula.planoPag.idprecificacao ? true : false

      if ( !valid ) {
        window.toaster.emit('open', {style: "error", message: "Para continuar, escolha o plano de pagamento.", floater: true})
      }

      return { response: valid }
      // return valid
    },
    parseFirstName( name ) {
      const nome = name.split(" ")[0]
      return nome
    },
    filterTurma ( idTurma,src ) {
      // console.log('filter turma:', idTurma)

      const turma = this.options.turmas.filter( k => {
        return k.idTurma == idTurma
      })[0]

      // console.log('turma:', turma)

      const drop = turma[src]

      // console.log('drop:', drop)

      return drop
    },
    clearSelection () {
      this.planos.map( k => {
        k.selected = false
      })
    },
    selectPlano ( plano, i ) {
      if ( this.selectedPlano ) {
        this.selectedPlano = null
        this.matricula.planoPag = this.planoModel
        this.planos[i].selected = false
      } else {
        this.clearSelection()

        this.$nextTick(() => {
          this.selectedPlano = plano
          this.matricula.planoPag = plano
          this.planos[i].selected = true
        })        
      }
      console.log("select plano:", this.planos)
    },
    formatMoney ( value ) {
      return formatMoney(value)
    },
    formatDate ( value ) {
      return formatDate(value)
    },
    calcDiscountDif ( plano ) {
      const valorTotal = parseFloat(plano.valorTotal)
      const valorTotalDesconto = parseFloat(plano.valorTotalDesconto)

      const dif = valorTotal - valorTotalDesconto
      return dif
    },
    filterEntrada ( plano ) {
      const filterEntrada = plano.planosPagamento.filter(k => {
        return k.tipoArrecadacao == 'Entrada'
      })
      // console.log("filterEntrada:", filterEntrada)
      return filterEntrada
    },
    filterMensalidade ( plano ) {
      const filterMensalidade = plano.planosPagamento.filter(k => {
        return k.tipoArrecadacao == 'Mensalidade'
      })
      // console.log("filterMensalidade:", filterMensalidade)
      return filterMensalidade
    },
    filterAnuidade ( plano ) {
      const filterAnuidade = plano.planosPagamento.filter(k => {
        return k.tipoArrecadacao == 'Anuidade'
      })
      console.log("filterAnuidade:", filterAnuidade)
      return filterAnuidade
    },
    trackSelectedPlan () {
      this.planos.map( k => {
        console.log('k',k)
        if ( k.selected ) this.matricula.planoPag = k
      })
    },
    toggleDescontos () {
      this.$emit('toggleDescontos')
    }
  }
}
</script>

<style lang="scss" scoped>
  // header {
  //   display: flex; align-items: center; align-content: center;

  //   >div {
  //     margin-right: $mg; margin-top: $hmg;

  //     @media screen and (max-width: 1366px) {
  //       margin-right: $hmg;
  //     }

  //     p {
  //       margin: 0; font-weight: bold;
  //     }
  //   }

  //   @media screen and (max-width: 640px) {
  //     margin-bottom: $mg;
  //     align-items: flex-start;
       
  //     >div {
  //       margin-top: $hmg;
  //     }
  //   }
  // }

  .planos {
    display: flex; flex-wrap: wrap;
    align-content: stretch; align-items: flex-start;
    gap: $hmg_mid;
    padding: 0; margin: $mg 0 0 0;
    
    li {
      display: flex; align-items: stretch;
      flex-direction: column;
      border-radius: $border-radius-large;
      border: $border-component; 
      background-color: $color-secondary;
      list-style: none; margin: 0;
      width: 35%;  position: relative;
      
      @media screen and (max-width: 1024px) {
        width: 100%;
      }

      >div {
        &:first-child {
          padding: $hmg;
        }
        padding: 0 $hmg;

        >h2 { 
          max-width: 70%;
          @media screen and (max-width: 1024px) {
            max-width: 100%;
          }
        }

        .valorTotal {
          p {
            margin: 0; padding: 0;

            &:last-child {
              font-size: $font-size-larger
            }
          }
        }
        
        .discountMessage {
          display: flex; align-items: center;
          font-weight: bold; gap: $hmg_mini;
          margin: $hmg_mid 0 0 0;

          .icon {
            font-size: 20px;
            color: $color-success-2;
          }
        }
      }

      a:not(.toggleCalc) {
        display: flex;
        width: 100%;
        height: 50px;
        align-items: center;
        align-content: center;
        justify-content: center;
        background-color: $color-primary;
        margin-top: $hmg_mid;
        font-size: 18px; text-transform: uppercase; font-weight: bold;
        color: $color-secondary;
        border-bottom-left-radius: $border-radius-large;
        border-bottom-right-radius: $border-radius-large;
        @extend %transition_4e;

        &:hover {
          height: 60px;
        }
      }

      &.selected {
        border: 1px solid $color-success-2 !important;

        a:not(.toggleCalc) {
          background-color: $color-success-2;
        }
      }

      .planoBox {
        border-radius: $border-radius;
        border: $border-component;
        overflow: hidden;
        margin-bottom: $hmg_mid;

        &:last-child {
          margin-bottom: 0;
        }

        header {
          padding: $hmg_small;
          border-bottom: $border-component;
          font-size: 20px; font-weight: bold;
          text-align: center !important;
          background-color: $color-bg
        }
        > div {
          padding: $hmg_mid;
        }
      }
      // display: flex; flex-direction: column; align-content: center; align-items: flex-start;
      // list-style: none; position: relative;
      // border-right: 1px solid $color-fade;
      // padding: $hmg_small $hmg; margin: $mg 0 0 0;
      // width: 33.333333%; min-width: 240px; max-width: 360px !important;

      // @media screen and ( max-width: 640px ) {
      //   border-right: 0; border-bottom: 1px solid $color-fade;
      //   margin: 0 0 $mg 0; width: 100%;
      //   padding: $hmg $hmg $mg;
      // }
      
      

      .btn-check {
        width: calc(100% + $mg_mid);
        margin-top: $hmg; margin-left: -$hmg_small;
      }

      h2 {
        margin-bottom: $hmg;
      }

      .planosContent {
        display: flex; align-content: center; align-items: center;
        justify-content: space-between;
        width: 100%;

        >span {
          &:last-child {
            text-align: right;
          }
          &:first-child {
            text-align: left !important;
          }
          p {
            font-size: $font-size-larger; margin: 0;
          }

          &.large {
            p {
              font-size: 16px;
            }
          }
        }
      }

      .planoCardHeader {
        margin-bottom: $hmg_mini;

        h5 {
          margin: 0;
        }
      }

      .planoCard {
        width: calc( 100% + $mg_mid );
        margin-left: -$hmg_small;
        background-color: $color-secondary;
        border-radius: $border-radius;
        padding: $hmg_small;
        position: relative;

        footer {
          height: 37px; width: 100%;
          position: absolute; bottom: 0; left: 0;
          background-color: $color-success-2;
          display: flex; align-content: center; align-items: center; justify-content: space-between;
          font-weight: bold;
          padding: 0 $hmg_small;
          border-bottom-left-radius: $border-radius; border-bottom-right-radius: $border-radius;

          p {margin: 0; white-space: nowrap;}
        }

        &.hasFooter {
          padding-bottom: calc($hmg_small + 37px);
        }
      }

      

      .btDiscountDetails {
        font-size: 20px;

        .icon {
          @extend %transition_4e;
        }

        &:hover {
          .icon {
            transform: scale(1.1);
          }
        }
      }

      .planoDetails {
        width: 100%; margin-bottom: $hmg_mid;
      }

      .due {
        font-size: $font-size-larger;
        margin-top: $mg;

        @media screen and ( max-width: 640px ) {
          margin-top: $hmg;
        }

        p {
          margin: 0
        }
      }
    }
  }

  .dueDateSelect {
    display: flex; align-items: center; align-content: center;
    background-color: $color-secondary;
    border-radius: $border-radius;
    padding: $mg_mini $hmg_small;

    label {
      margin: 0; padding: 0;
    }
    select {
      appearance: none;
      padding: 0; margin: 0;
      line-height: 1;
      outline: 2px solid $color-primary;
      border: 0; margin-left: $hmg_small;
      color: $color-text; font-weight: bold;
      width: 26px; height: 26px; border-radius: 100%;
      background-color: $color-bg;
      text-align: center;

      option {
        padding: 0; margin: 0;
        line-height: 1;
      }
    }
  }

  .counterTag {
    // position: absolute; top: 0; left: 0;
    padding: $hmg_mini $hmg_small;
    border-radius: $border-radius;
    transform: translateY(-100%);
    margin-left: -$hmg_small;
    background-color: $color-secondary;
    @extend %shadow-low-light;
  }

  .months {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $mg_mini;
    
    >p{
      margin: 0; padding: 0;
    }
    
    >div {
      flex: 1;
      position: relative;

      span {
        width: 100%; height: 1px;
        background-color: $color-bg;
        display: block;
      }
      p {
        margin: 0;
        padding: 0;
        position: absolute;
        left: 0; right: 0;
        margin: auto;
        transform: translateY(10px);
        font-weight: bold;
        text-align: center;
      }
    }
  }

  .price {
    display: flex; width: 100%;
    margin-top: $hmg;
    align-items: flex-start; align-content: flex-start;
    justify-content: space-between;
    // gap: $hmg_mid;

    > div {
      // flex: 1;

      &:first-child {
        p {
          color: $color-mid-grey;
          padding-right: $hmg_mini
        }
      }

      &:last-child{
        text-align: right;

        p {
          padding-left: $hmg_mini
        }
      }

      p {
        display: block;
        margin: 0; padding: 0;  

        &:nth-child(1) {
          font-weight: bold;
          margin-bottom: 3px;
        }
        &:nth-child(2) {
          font-size: $font-size-larger;
          border-bottom: $border-component;

          span {
            color: $color-success-2
          }
        }
        &:nth-child(3) {
          margin-top: 3px;
        }
        &:last-child {
          border-bottom: 0 !important;

          span {
            color: $color-success-2
          }
        }
      }
    }
  }

  .internoTag {
    display:  inline-flex; align-content: center; justify-content: center;
    margin-bottom: $hmg_mid;
    padding: $hmg_mini $mg_mini;
    border-radius: 2em;
    background-color:  $color-primary;
    color: $color-secondary
  }

  .toggleCalc {
    display: flex; align-content: center; align-items: center;
    justify-content: center; 
    line-height: 1; font-size: 16px;
    width: 40px; height: 40px;
    position: absolute; right: $hmg_mid; top: $hmg_mid;
    border-radius: 100%;
    @extend %transition_3o;

    &:hover {
      background-color: $color-bg
    }
  }
</style>