<template>
  <section ref="docForm">
    <header>
      <h5>{{ state }} Documento</h5>
    </header>
    <div v-if="documento" class="frame fixed">
      <div class="row no-padd" >
        <div class="col-md-6 form-group">
          <label>Descrição</label>
          <input-control
            v-model="documento.descricao" 
            placeholder="Nome do documento" 
            name="descricao"
            type="text"
            :valid="valid.descricao" 
            @keyup="valid.descricao = true"
            required
          />
        </div>
        <div class="col-md-3 form-group">
          <label>Validade</label>
          <input-control
            v-model="documento.validade" 
            placeholder="DD/MM/YYYY" 
            name="validade"
            type="date"
          />
        </div>
      </div>
      <div class="row no-padd" >
        <div class="col-md-2 form-group">
          <label>É contrato</label>
          <div class="switchWrapMini">
            <switch-control v-model:checked="documento.contrato" labelOn="Sim" labelOff="Não" invert></switch-control>
          </div>
        </div>
        <div class="col-md-2 form-group">
          <label>Anexa ao contrato</label>
          <div class="switchWrapMini">
            <switch-control v-model:checked="documento.anexoContrato" labelOn="Sim" labelOff="Não" invert></switch-control>
          </div>
        </div>
        <div class="col-md-2 form-group">
          <label>Documento do Responsável</label>
          <div class="switchWrapMini">
            <switch-control v-model:checked="documento.documentoresponsavel" labelOn="Sim" labelOff="Não" invert></switch-control>
          </div>
        </div>
        <div class="col-md-2 form-group">
          <label>Vínculado a Matrícula</label>
          <div class="switchWrapMini">
            <switch-control v-model:checked="documento.vinculoMatricula" labelOn="Sim" labelOff="Não" invert></switch-control>
          </div>
        </div>
      </div>
      <div class="row no-padd">
        <div class="col-md-12 form-actions form-group">
          <a href="#" @click.prevent="save()" class="btn btn-primary">
            Salvar Documento
          </a>
          <a href="#" @click.prevent="cancel()" class="btn btn-secondary">
            Cancelar
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import FormService from '@/services/form.service.js'
import AcademicoService from '@/services/academico.service.js'

export default {
  props: {
    scope: {
      type: Object
    },
    state: {
      type: String
    }
  },
  watch: {
    scope ( value ) {
      this.documento = value
    }
  },
  data () {
    return {
      documento: null,
      valid: {
        descricao: true
      }
    }
  },
  mounted () {
    this.documento = this.scope
  },
  methods: {
    save () {
      const valid = FormService.validate(this.$refs.docForm, this.valid)
      console.log("valid:", valid)

      if ( valid.response ) {
        window.spinner.emit('open')
        AcademicoService.saveDocumentosRequeridos( this.documento ).then(
          response => {
            console.log("response", response)
            if ( response.status == 200 ) {
              const action = this.step == 'Editar' ? 'editado' : 'salvo'
              window.toaster.emit('open', {style: "success", message: "Tudo certo! Documento " + action + " com sucesso.", floater: true})
              this.documento = null
              setTimeout(() => {
                this.$emit('onSave')
              }, 1000)
            }
            window.spinner.emit('close')
          },
          () => {
            window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível " + this.step + " o documento informado.", floater: true})
            window.spinner.emit('close')
          }
        )
      }
    },
    cancel () {
      this.$emit('onCancel')
    }
  }
}
</script>

<style lang="scss" scoped>
  header {
    padding: $hmg_small $hmg;
  }
</style>