<template>
  <section class="table-wrap">
    <header>
      <div>
        <h4>Precificações</h4>
        <p v-if="precificacoes">{{ precificacoes.length + ( precificacoes.length == 1 ? ' item encontrado' : ' itens encontrados' ) }}</p>
      </div>
      <transition name="slideRightToLeft">
        <a href="#" @click.prevent="voltar()" class="btn btn-secondary">
          <fa :icon="['far','arrow-left-long']"/>&nbsp;
          Voltar
        </a>
      </transition>
    </header>
    <table v-if="precificacoes" class="transparent-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Descrição</th>
          <th>Série</th>
          <th>Unidade</th>
          <th>Data Início</th>
          <th>Data Final</th>
          <th>Contrato</th>
          <th>Interno</th>
          <th>Status</th>
          <th>Aprovado</th>
          <th></th>
          <th class="text-right">
            <label class="check-control">
              <span>Todos</span>
              <input type="checkbox" class="check" v-model="all" @change="toggleAll()">
            </label>
          </th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(precificacao,i) in precificacoes" :key="precificacao">
          <tr :class="{ active: precificacao.planos }">
            <td>
              {{ precificacao.idprecificacao }}
            </td>
            <td>
              {{ precificacao.descricao }}
            </td>
            <td>
              {{ precificacao.descricaoSerie }}
            </td>
            <td>
              {{ precificacao.sigla }}
            </td>
            <td>
              <input-control v-model="precificacao.dataInicio" type="date" placeholder="DD/MM/YYYY" cssClass="small invert" @change="toggleChange(i)"/>
            </td>
            <td>
              <input-control v-model="precificacao.dataFinal" type="date" placeholder="DD/MM/YYYY" cssClass="small invert" @change="toggleChange(i)"/>
            </td> 
            <td>
              <!-- {{ plano.numeroParcelas }} -->
              <select-control v-model="precificacao.template_contrato" :options="options.contratos" placeholder="Selecione" cssClass="small" />
            </td>
            <td class="form-group switch-wrap">
              <div class="switchWrapMiniLoose">
                <switch-control v-model:checked="precificacao.interno" labelOn="Interno" labelOff="Aberto" @change="toggleChange(i)" small invert></switch-control>
              </div>
            </td>
            <td class="form-group switch-wrap">
              <div class="switchWrapMiniLoose">
                <switch-control v-model:checked="precificacao.ativo" labelOn="Ativo" labelOff="Inativo" @change="toggleChange(i)" small invert></switch-control>
              </div>
            </td>
            <td class="statusWrap">
              <template v-if="precificacao.aprovado">
                <fa :icon="['fas','check']" class="color-success" />
                Aprovada
              </template>
              <template v-else>
                <fa :icon="['fas','triangle-exclamation']" class="color-alert" />
                Pendente
              </template>
            </td>
            <td class="actions">
              <a href="#" @click.prevent="confirmRemove(precificacao, i)" class="action">
                <fa :icon="['fas','trash-can']" class="icon"></fa>
              </a>
              <a href="#" @click.prevent="closePlanos(i)" class="action" v-if="precificacao.planos">
                <fa :icon="['fas','magnifying-glass-minus']" class="icon"></fa>
              </a>
              <a href="#" @click.prevent="getPlanos(precificacao, i)" class="action" v-else>
                <fa :icon="['fas','magnifying-glass-plus']" class="icon"></fa>
              </a>
            </td>
            <td class="text-right">
              <label class="check-control">
                <input type="checkbox" class="check" v-model="precificacao.selected">
              </label>
            </td>
          </tr>
          <tr v-if="precificacao.planos" class="collapsed-row">
            <td colspan="12">
              <header>
                <div>
                  <h4>Planos de Pagamento</h4>
                  <p v-if="precificacao.planos">{{ precificacao.planos.length }} {{ precificacao.planos.length == 1 ? ' item encontrado' : ' itens encontrados' }}</p>
                </div>
                <a href="#" class="collapse-close" @click.prevent="closePlanos(i)">
                  <fa :icon="['fas', 'chevron-up']" class="icon"></fa>
                </a>
              </header>
              <table class="transparent-table invert">
                <thead>
                  <tr>
                    <th>
                      ID
                    </th>
                    <th>
                      Arrecadação
                    </th>
                    <th>
                      Mês Início
                    </th>
                    <th>
                      Vencimento
                    </th>
                    <th>
                      Data Início
                    </th>
                    <th>
                      Data Final
                    </th>
                    <th>
                      Competência Inicial
                    </th>
                    <th>
                      Competência Final
                    </th>
                    <th>
                      Valor Parcela
                    </th>
                    <th>
                      Parcelas
                    </th>
                    <th>
                      Status
                    </th>
                    <th></th>
                    <th class="text-right">
                      <label class="check-control">
                        <span>Todos</span>
                        <input type="checkbox" class="check" v-model="innerAll" @change="toggleAll(i,'planos')">
                      </label>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(plano, ii) in precificacao.planos" :key="plano">
                    <td>
                      {{ plano.idPlanoPagamento }}
                    </td>
                    <td>
                      {{ plano.descricaoArrecadacao }}
                    </td>
                    <td>
                      <input-control v-model="plano.mesInicio" placeholder="MM/YYYY" cssClass="small" @change="toggleChange(i,'planos', ii)" masking="mes_ano"/>
                      <!-- {{ plano.mesInicio }} -->
                    </td>
                    <td>
                      <input-control v-model="plano.diaVencimento1" type="number" placeholder="Ex. 5" cssClass="small" @change="toggleChange(i,'planos', ii); trackMax('dias', 'diaVencimento1', i)"  />
                      <!-- {{ plano.diaVencimento1 }} -->
                    </td>
                    <td>
                      <input-control v-model="plano.dataInicio" type="date" placeholder="DD/MM/YYYY" cssClass="small" @change="toggleChange(i,'planos', ii)"/>
                    </td>
                    <td>
                      <input-control v-model="plano.dataFinal" type="date" placeholder="DD/MM/YYYY" cssClass="small" @change="toggleChange(i,'planos', ii)"/>
                    </td>
                    <td>
                      <!-- {{ plano.competenciaFinal }} -->
                      <input-control v-model="plano.competenciaInicial" placeholder="MM/YYYY" cssClass="small" @change="toggleChange(i,'planos', ii)" masking="mes_ano"/>
                    </td>
                    <td>
                      <!-- {{ plano.competenciaInicial }} -->
                      <input-control v-model="plano.competenciaFinal" placeholder="MM/YYYY" cssClass="small" @change="toggleChange(i,'planos', ii)" masking="mes_ano"/>
                    </td>
                    <td>
                      <!-- {{ plano.valorParcela }} -->
                      <input-control v-model="plano.valorParcela" type="number" placeholder="Ex. 1992" cssClass="small" @change="toggleChange(i,'planos', ii)"/>
                    </td>
                    <td>
                      <!-- {{ plano.numeroParcelas }} -->
                      <input-control v-model="plano.numeroParcelas" type="number" placeholder="Ex. 12" cssClass="small" @change="toggleChange(i,'planos', ii); trackMax('meses','numeroParcelas', i)"/>
                    </td>
                    <td class="form-group switch-wrap">
                      <div class="switchWrapMiniLoose">
                        <switch-control v-model:checked="plano.ativo" labelOn="Ativo" labelOff="Inativo" @change="toggleChange(i,'planos', ii)" small invert></switch-control>
                      </div>
                    </td>
                    <td class="actions">
                      <a href="#" @click.prevent="editPlano(plano, i)" class="action">
                        <fa :icon="['fas','pencil']" class="icon"></fa>
                      </a>
                    </td>
                    <td class="text-right">
                      <label class="check-control">
                        <input type="checkbox" class="check" v-model="plano.selected">
                      </label>
                    </td>
                  </tr>
                </tbody>
              </table>
              <footer class="action-wrap">
                <a href="#" class="btn btn-primary" @click.prevent="confirmSave('planos',i)">
                  Salvar Planos
                </a>
              </footer>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <footer class="action-wrap">
      <a href="#" class="btn btn-primary" @click.prevent="confirmSave('precificacoes')">
        Salvar Precificações
      </a>
      <a href="#" class="btn btn-primary" @click.prevent="confirmApprove()" v-if="allowApprove">
        Aprovar Precificações
      </a>
    </footer>
    <modal ref="modal">
      <plano-pagamento v-if="edit" :scope="edit" @onCancel="closeEdit(true)" @onSave="refreshList()"></plano-pagamento>
    </modal>
    <confirm
      :backdrop="false"
      class="confirm"
      ref="confirm"
    ></confirm>
  </section>
</template>

<script>
import FinanceiroService from '@/services/financeiro.service.js'
import UserService from '@/services/user.service.js'
import { formatDate } from '@/services/utils.service.js'
import PlanoPagamento from '@/components/financeiro/planospagamento/form/PlanoPagamento.vue'
import Modal from "@/components/utilities/Modal"

export default {
  components: {
    PlanoPagamento, Modal
  },
  props: {
    scope: {
      type: Array
    }
  },
  data () {
    return {
      precificacoes: null,
      all: false,
      innerAll: false,
      allowApprove: false,
      edit: null,
      editIndex: null,
      precificacao: null,
      options: {
        contratos: [
          { label: "Padrão 2023", value: "contratoGestao" },
          { label: "Material Integrado 2024", value: "contrato_2024_regular_material" },
          { label: "Padrão 2025", value: "contrato_2025_regular" },
          { label: "Material Integrado 2025", value: "contrato_2025_regular_material" }
        ]
      }
    }
  },
  mounted () {
    if ( this.scope ) {
      this.precificacoes = Object.assign([], this.scope)
    }
    this.addSelector(null, true)
    this.allowApprove = UserService.filterPermissions('aprovarAlcadasDesconto')
  },
  methods: {
    addSelector ( target, off ) {
      if ( target ) {
        target.map( k => {
          k.selected = off ? false : true
        })
      } else {
        this.precificacoes.map( k => {
          k.selected = off ? false : true
        })
      }
    },
    toggleChange ( index, target, innerIndex ) {
      if ( target ) {
        this.precificacoes[index][target][innerIndex].selected = true
      } else {
        this.precificacoes[index].selected = true
      }
    }, 
    toggleAll ( index, target ) {
      console.log("all:", this.all)
      if ( this.all ) {
        if ( target ) {
          this.addSelector(this.precificacoes[index][target])
        } else {
          this.addSelector()
        }
        
      } else {
        if ( target ) {
          this.addSelector(this.precificacoes[index][target], true)
        } else {
          this.addSelector(null, true)
        }
        
      }
      // console.log("this.selected", this.selected)
    },
    voltar () {
      this.$emit("onReturn")
    },
    getPlanos ( precificacao, i ) {
      const idPrecificacao = precificacao.idprecificacao
      this.precificacao = precificacao
      
      window.spinner.emit("open")
      FinanceiroService.getPlanosByPrecificacao(idPrecificacao).then(
        response => {
          console.log("getPlanosByPrecificacao:", response.data)
          if ( response.data.length > 0 ) {
            this.precificacoes[i].planos = response.data
            this.addSelector(this.precificacoes[i].planos, true)
          } else {
            window.toaster.emit('open', {style: "error", message: "Não foi possível encontrar planos de pagamento para esta precificação", floater: true})
          }
          window.spinner.emit("close")
        },
        () => {
          window.spinner.emit("close")
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não conseguimos processar sua solicitação.", floater: true})
        }
      )
    },
    closePlanos ( i ) {
      this.precificacoes[i].planos = null
    },
    formatDate ( date ) {
      return formatDate(date)
    },
    trackMax ( src, target, index ) {
      var min, max

      if ( src == 'meses' ) {
        min = 1
        max = 12
      }
      if ( src == 'dias' ) {
        min = 1
        max = 31
      }
      if ( this.precificacoes[index].planos[target] < min ) this.precificacoes[index].planos[target] = min
      if ( this.precificacoes[index].planos[target] > max ) this.precificacoes[index].planos[target] = max
    },
    editPlano ( plano, i ) {
      const options = {
        // title: 'Olá, família Decisão!',
        // titleAlign: "center",
        // message: "Se quiser uma mensagem...", //não obrigatório
        size: "large", //small, mid, normal, large, full
        backdrop: true,
        backdropColor: "transparent",  //se o backdrop for false, não precisa
        backdropClose: true,
        // fullHeight: true,
        onClose: this.closeEdit
      }
      this.edit = plano
      this.editIndex = i
      this.$refs.modal.open(options)
      window.pagescroll.emit("lock")
    },
    closeEdit ( closeModal ) {
      this.edit = null
      this.editIndex = null
      this.precificacao = null

      window.pagescroll.emit("unlock")
      console.log('closeEdit:', event)

      if ( closeModal ) this.$refs.modal.close()
    },
    confirmSave ( target, i ) {
      const msg = target == 'precificacoes' ? 'as precificações' : 'os planos'
      const next = target == 'precificacoes' ? this.savePrecificacoes : this.savePlanos

      this.$refs.confirm.run({
        message: `Tem certeza que deseja salvar ${msg} selecionadas?`,
        confirmButton: "Sim",
        denyButton: "Não",
        data: i,
        callback: next
      })
    },
    savePrecificacoes ( response, data ) {
      console.log("save", this.precificacoes)
      console.log("response", response)
      console.log("data", data)

      var drop = []

      if ( response ) {
        if ( data ) {
          drop = data
        } else  {
          
          this.precificacoes.map( k => {
            if ( k.selected ) {
              k.aprovado = 0
              drop.push(k)
            }
          })
        }
        console.log("drop", drop)
        
        if ( drop.length > 0 ) {
          FinanceiroService.savePrecificacao( drop ).then(
            response => {
              if ( response.status == 200 ) {
                window.toaster.emit('open', {style: "success", message: "Tudo certo! Precificações salvas com sucesso.", floater: true})
                this.$emit("onSave")
              }
            },
            () => {
              window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível salvar esta(s) precificações(s).", floater: true})
            }
          )
        } else {
          window.toaster.emit('open', {style: "error", message: "Selecione as precificações que você quer salvar.", floater: true})
        }
      }
    },
    savePlanos ( response, index ) {
      console.log("save", this.precificacoes[index].planos)

      if ( response ) {
        const drop = []
        this.precificacoes[index].planos.map( k => {
          if ( k.selected ) {
            drop.push(k)
          }
        })

        if ( drop.length > 0 ) {
          FinanceiroService.savePlanosPagamento( drop ).then(
            response => {
              if ( response.status == 200 ) {
                window.toaster.emit('open', {style: "success", message: "Tudo certo! Precificações salvas com sucesso.", floater: true})
              }
            },
            () => {
              window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível salvar esta(s) precificações(s).", floater: true})
            }
          )
        } else {
          window.toaster.emit('open', {style: "error", message: "Selecione as precificações que você quer salvar.", floater: true})
        }
      }
    },
    confirmApprove () {
      this.$refs.confirm.run({
        message: "Tem certeza que deseja aprovar as alçadas selecionadas?",
        confirmButton: "Sim",
        denyButton: "Não",
        // data: alcada,
        callback: this.approve,
      })
    },
    approve ( response ) {
      if ( response ) {
        const drop = []
        this.precificacoes.map( k => {
          if ( k.selected ) {
            k.aprovado = 1
            drop.push(k)
          }
        })

        this.savePrecificacoes( response, drop)
      }
    },
    refreshList() {
      this.getPlanos(this.edit, this.editIndex)
    }
  }
}
</script>

<style lang="scss" scoped>
  header {
    display: flex;
    align-items: center; align-content: center;
    margin-bottom: $hmg_small;

    p {
      margin: 0; padding: 0;
    }

    .btn {
      margin-left: $hmg
    }
  }
  .table-wrap {
    padding: $hmg $hmg $mg_extra;

    .transparent-table{
      th, td {
        &:last-child {
          max-width: 120px
        }
      }
     }
  }
  .actions {
    display: flex; align-content: center; align-items: center;
    justify-content: flex-end;
    font-size: 12px;

    >a {
      display: flex; flex-direction: column;
      align-items: center; align-content: center; justify-content: center;
      width: 30px; height: 30px;
      border-radius: 100%; 
      @extend %transition_3o;

      .icon {
        font-size: 14px !important
      }
      
      &:hover {
        background-color: $color-bg
      }
    }
  }

  .action-wrap {
    display: flex; align-content: center; align-items: center;
    justify-content: flex-end;
    padding: $hmg 0 0 $hmg_small;

    a {
      margin-left: $hmg_small
    }

    label{
      margin-right: $hmg
    }
  }

  .switch-wrap { min-width: 120px;}

  .collapsed-row {
    header {

      width: 100%;
      justify-content: space-between;
    }
  }

  .statusWrap {
    white-space: nowrap
  }
</style>