<template>
  <section class="checkoutController">
    <div class="checkoutHeader">
      <logo color="#ED1C24" size="40px" />
      <h2>Finalizar Compra</h2>
      <div>
        <a href="#" @click.prevent="goBack()" class="btn btn-secondary" v-if="!finalized && !waiting">
          <fa :icon="['far', 'arrow-left-long']" class="icon"></fa>
          <span>Voltar para a loja</span>
        </a>
      </div>
    </div>
    <spinner></spinner>
    <toaster></toaster>
    <section v-if="finalized">
      <confirmacao 
        :waiting="waiting"
        :transacao="transaction.cod"
        :message="transaction.message"
        :status="transaction.status"
        :pix="transaction.pix"
        @goback="manageError()"
      ></confirmacao>
    </section>
    <section class="checkoutBody frame" v-else>
      <div class="checkoutForm">
        <informacoes ref="informacoes"></informacoes>
      </div>
      <div class="checkoutSidebar">
        <checkout-sidebar v-if="cart" @onFinish="finalizar" ref="sidebar"></checkout-sidebar>
      </div>
    </section>
    
    <!-- <perfect-scrollbar class="checkoutControllerScroll">
      <h1>Finalizar Compra</h1>
      <timeline 
        :step="step"
        :done="done"
        v-if="!finalized && !waiting"
      ></timeline>
      <wizard
        :step="step" 
        :done="done"
        @updatedata="updateData"
        @next="togglePrevNext('next', $event)"
        ref="wizard"
        v-if="!finalized && !waiting"
      ></wizard>
      <transition name="slidein">
        <confirmacao
          v-if="finalized"
          :waiting="waiting"
          :transacao="transaction.cod"
          :message="transaction.message"
          :status="transaction.status"
          :pix="transaction.pix"
          @goback="manageError()"
        ></confirmacao>
      </transition>
      <transition name="slidein">
        <checkout-bar
          v-if="!finalized && !waiting && informacoes && !smallScreen"
          :step="step"
          @next="togglePrevNext('next', $event)"
          @prev="togglePrevNext('prev', $event)"
          @confirm="confirm"
          @finalize="finalizar"
        ></checkout-bar>
      </transition>
    </perfect-scrollbar> -->
  </section>
</template>

<script>

import Informacoes from '@/views/loja/checkout/Informacoes'
import Logo from '@/components/template/logo'
import CheckoutSidebar from '@/components/loja/utilities/checkout/CheckoutSidebar.vue'
import Confirmacao from '@/views/loja/checkout/Confirmacao'
import LojaService from '@/services/loja.service.js'
import CheckoutLoja from '@/services/checkoutLoja.service.js'
// import { formatMoney } from '@/services/utils.service'

export default {
  components: {
    Logo,
    Informacoes, CheckoutSidebar, Confirmacao
  },
  props: {
    stepLabel: {
      type: String,
      default: null
    }
  },
  watch: {
    stepLabel ( value ) {
      console.log("value:", value)
    }
  },
  data () {
    return {
      step: 1,
      done: [],
      finalized: false,
      waiting: false,
      transaction: {
        cod: null,
        status: null,
        message: null,
      },
      valid: {
        informacoes: false,
        entrega: false,
        pagamento: false
      },
      informacoes: null,
      labels: [
        'detalhes', 'retirada', 'cupom', 'pagamento'
      ],
      viewTarget: [
        'viewConfirmaDados', 'viewUnidadeRetirada', 'viewCupom', 'viewPagamento'
      ],
      smallScreen: false,
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
      cart: null
    }
  },
  mounted () {
    window.spinner.emit("open")

    // this.addOcta()

    this.$store.dispatch('checkout/get').then(response => {
      this.informacoes = response

      const carrinho = LojaService.getCart()
      console.log('carrinho:', carrinho)
      if ( carrinho ) {
        this.informacoes.produtos = carrinho.produtos
        // this.$router.push({name: 'Checkout', params: { stepLabel: this.labels[this.step - 1] } })

        LojaService.saveCart(this.currentUser.idPessoa, this.viewTarget[this.step - 1])
        // begin_checkout
        const path = this.$route.fullPath
        console.log('path', path)
        
        const eventData = {
          'event_category': 'start_checkout',
          'event_label': this.currentUser.email,
          'shopping_cart': carrinho
        }

        console.log("eventData:",eventData)
        this.$gtag.event('start_checkout', eventData)

        this.cart = carrinho
        
      } else {
        this.$router.push({name: 'Loja'})
      }
      window.spinner.emit("close")
    })

    // window.addEventListener('resize', () => {
    //   // console.log("window.innerHeight < 500:",  window.innerHeight < 500)
    //   this.smallScreen = window.innerHeight < 500
    // })
  },
  methods: {
    togglePrevNext ( direction ) {
      if ( direction === 'next' ) {
        // const validationTarget = ['pessoais', 'endereco']
        if ( this.step == 1 ) {
          this.$store.dispatch('checkout/validate','informacoes').then(response => {
            //validation here...
            const contentValid = this.$refs.wizard.$refs.informacoes.validate()
            console.log("contentValid", contentValid)
            console.log("checkout/validate - informacoes", response)
            if ( contentValid ) this.next()
            
          })
        } else if ( this.step == 2 ) {
          const contentValid = this.$refs.wizard.$refs.retirada.validate()

          if ( contentValid ) this.next()
        } else {
          this.next()
        }
        
      } else if ( direction === 'prev' ) {
        this.prev()
      }
    },
    next () {
      if ( this.step < 4 ) {
        this.step ++
      }
      this.$router.push({name: 'Checkout', params: { stepLabel: this.labels[this.step - 1] } })
      console.log("next:", this.step)

      LojaService.saveCart(this.currentUser.idPessoa, this.viewTarget[this.step - 1])
    },
    prev () {
      if ( this.step > 1 ) {
        this.step --
      }
      this.$router.push({name: 'Checkout', params: { stepLabel: this.labels[this.step - 1] } })
      console.log("prev:", this.step)
    },
    confirm ( e ) {
      console.log("confirm: ", e)
    },
    updateData ( data ) {
      console.log("updateData:", data)
    },
    goBack () {
      this.$router.push({name: 'Loja'})
      // this.$router.go(-1)
    },
    finalizar (data) {
      // data.pagamento.valorPago = formatMoney(LojaService.getTotalValueFromCart(true))
      console.log("data:", data)

      this.$store.dispatch('checkout/validate','informacoes').then(() => {
        //validation here...
        const infoValid = this.$refs.informacoes.validate()
        const contentValid = this.$refs.sidebar.validate()
        
        console.log("contentValid:", contentValid)
        console.log("infoValid:", infoValid)

        if ( infoValid && contentValid ) {
          this.$store.dispatch('checkout/validate','pagamento').then(response => {
          //validation here...
          let valor = response.pagamento.valorPago.replace("R$ ","")
              valor = parseFloat(valor.replace(".","").replace(",",""))
          // console.log("valor", valor)
          if ( data.pagamento ) {
            data.pagamento.valorPago = valor
          }
          
          window.spinner.emit('open')
          this.waiting = true
          
          if ( response ) {
            CheckoutLoja.checkoutMarketPlace(data).then(
              success => {
                const eventData = {
                  'event_category': 'end_checkout',
                  'event_label': this.currentUser.email,
                  'shopping_cart': data
                }

                this.$gtag.event('end_checkout', eventData)
                
                this.$router.push({name: 'Checkout', params: { stepLabel: "confirmacao" } })
                // console.log("success:", success)
                window.spinner.emit('close')
                
                this.finalized = true
                this.waiting = false

                var retorno = success.data.transacao?.retornoPagarme
                console.log("retorno:", retorno)
                
                if ( retorno ) {
                  // retorno = JSON.parse(retorno)
                  // console.log(" parsed:",  parsed)
                  this.transaction.status = 'pix'
                  this.transaction.message = 'Sua compra foi efetivada com sucesso.<br>Agora, basta pagar com pix, utilizando o QR Code ou o código informados abaixo:'
                  this.transaction.pix = {
                    pixcode: retorno.pix_qr_code,
                    pixexp: retorno.pix_expiration_date
                  }
                  console.log(" this.transaction:",  this.transaction.pix)

                } else {
                  this.transaction.status = 'success'
                  this.transaction.message = 'Aguarde a confirmação de pagamento para que possamos processar seu pedido. <br></br><b>Acompanhe o status da sua compra através do seu email ou acessando <a href="/minhaconta/minhascompras"></a>"Minhas compras"</a> na sua conta no Shop Decisão.</b>'
                }
                
                setTimeout(() => {
                  LojaService.clearCart()
                },500)
              },
              error => {
                console.log("error:", error)
                window.spinner.emit('close')

                const msg = Object.values(error.response.data)
                var errorMsg

                if ( msg && msg.length > 0 ) {
                  errorMsg = msg[0]
                  errorMsg = errorMsg == 'O formado do campo basicos.telefonecelular deve ser :regex' ? 'O formato do telefone celular não está correto.</br>Valide a informação e tente novamente.' : errorMsg
                } else {
                  errorMsg = decodeURIComponent(JSON.parse(error.request.response).error)
                }

                this.finalized = true
                this.waiting = false
                this.transaction.status = 'error'
                this.transaction.message = errorMsg
              }
            )
          }
        })
      
        }
      })
      
      
    },
    manageError () {
      this.waiting = false
      this.finalized = false 
    },
    // addOcta () {
    //   // console.log("add octa now...")
    //   this.octaScript = document.createElement('script')
    //   this.octaScript.setAttribute('src', '/octa.js')
    //   // console.log("externalScript:", externalScript)
      
    //   document.head.appendChild(this.octaScript)

    //   const octa = document.getElementById('octadesk-octachat-appchat')
    //   if ( octa ) octa.removeAttribute('class', 'hidden')
    // },
    fetchCartData () {
      let cart = LojaService.getCart()
      
      if ( cart ) {
        this.cart = cart
      }
    },
  }
}
</script>

<style lang="scss">
  .checkoutController{
    overflow-y: auto;
    background-color: $color-light; height: 100%; 
    @extend %scrollbar;
    padding: $mg_large 0;

    .checkoutHeader {
      width: calc(100% - 10px); position: fixed; top: 0; left: 0;
      display: flex; align-items: center; align-content: center; justify-content: space-between;
      padding: $hmg;
      z-index: 1000;
      background-color: $color-bg;
    }

    h1 {
      margin: auto; text-align: center;
    }
  }

  .checkoutBody {
    display: flex;

    > div {
      flex: 1
    }

    @media screen and ( max-width: 1024px ) {
      flex-direction: column;
    }
  }
  .checkoutSidebar {
    width: 50%;
    max-width: 640px; min-width: 320px;
    padding: 0 $hmg $hmg;

    @media screen and ( max-width: 1024px ) {
      width: 100%; max-width: 100%;
      padding: $hmg 0
    }
  }

  .checkoutForm {
    @media screen and ( max-width: 1024px ) {
      padding: 0 $hmg_small
    }
  }

  .slidein-enter-active, .slidein-leave-active {
    // transition: .4s ease;
    opacity: 1; transform: translateY(0) !important;
  }
  .slidein-enter, .slidein-leave-to{ 
    opacity: 0; transform: translateY(100%) !important;
  }

  .octadesk-octachat-app--bottomRight.octa-widget-v2{
    bottom: 2.2rem; right: $hmg;

    @media screen and (max-width: 480px) {
      // bottom: 1.25rem !important; right: 1.25rem !important;
    }
  }
</style>




