<template>
  <section class="table-wrap">
    <header>
      <h4>Resultado da Busca</h4>
      <p v-if="conteudosProgramaticos">{{ conteudosProgramaticos.length }} {{ conteudosProgramaticos.length == 1 ? 'item' : 'itens' }} encontrados</p>
    </header>
    <table v-if="conteudosProgramaticos" class="transparent-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Descrição</th>
          <th>Matéria</th>
          <th>Período Letivo</th>
          <th>Criado Em</th>
          <th>Última Atualização</th>
          <th></th>
          <!-- <th class="text-right">
            <label class="check-control">
              <span>Selecionar Todos</span>
              <input type="checkbox" class="check" v-model="all" @change="toggleAll()">
            </label>
          </th> -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(conteudoProgramatico, i) in conteudosProgramaticos" :key="conteudoProgramatico">
          <td>
            {{ conteudoProgramatico.idConteudoProgramatico }}
          </td>
          <td>
            {{ conteudoProgramatico.descricao }}
          </td>
          <td>
            {{ conteudoProgramatico.nomeMateria }}
          </td>
          <td>
            {{ conteudoProgramatico.ano }}
          </td>
          <td>
            {{ formatDateTime(conteudoProgramatico.created_at) }}
          </td>
          <td>
            {{ formatDateTime(conteudoProgramatico.updated_at) }}
          </td>
          <td class="text-right">
            <a href="#" @click.prevent="getConteudo(conteudoProgramatico,i)">
              <fa :icon="['fas','magnifying-glass']"></fa>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <footer class="action-wrap">
      <a href="#" class="btn btn-primary" @click.prevent="confirmSync()">
        Salvar
      </a>
    </footer> -->
  </section>
</template>

<script>
import { formatDateTime } from '@/services/utils.service.js'

export default {
  props: {
    scope: {
      type: Array
    }
  },
  watch: {
    scope ( value ) {
      if ( value ) this.conteudosProgramaticos = value
    }
  },
  data () {
    return {
      conteudosProgramaticos: null
    }
  },
  mounted () {
    this.conteudosProgramaticos = Object.assign([], this.scope)
  },
  methods: {
    formatDateTime ( date ) {
      return formatDateTime(date)
    },
    getConteudo ( conteudo ) {
      console.log("getConteudo:", conteudo)

      this.$emit('onSelect', conteudo.idConteudoProgramatico)
    }
  }
}
</script>

<style lang="scss" scoped>
  .table-wrap {
    padding: $hmg $hmg_small $mg_extra;

    header {
      padding: 0 $hmg_small
    }
  }
</style>