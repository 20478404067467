<template>
  <div class="ficha-saude">
    <header>
      <fa :icon="['far', 'file-waveform']" class="icon" />
      <h5>Ficha de Saúde</h5>
    </header>
    <div v-if="ficha">
      <div class="fichaIntro">
        <div class="blood">
          <fa :icon="['fal', 'droplet']" class="icon"/>
          <h4>{{ ficha.tipoSanguineo }}</h4>
        </div>
        <div class="labeledDetail">
          <label>Plano de Saúde</label>
          <p>{{ ficha.possuiPlanoSaude ? ficha.nomePlanoSaude : 'Não Possui' }}</p>
        </div>
      </div>
      <div class="ficha-details">
        <ul>
          <li v-if="ficha.possuiNecessidadeEspecial">
            <h6>É portador de necessidade especiais</h6>
            <p v-if="ficha.nomeNecessidadeEspecial">{{ ficha.nomeNecessidadeEspecial }}</p>
          </li>
          <li v-if="ficha.possuiAlergiaMedicamento">
            <h6>É alergico a medicamentos</h6>
            <p v-if="ficha.nomeAlergiaMedicamento">{{ ficha.nomeAlergiaMedicamento }}</p>
          </li>
          <li v-if="ficha.realizaTratamentoMedico">
            <h6>Está realizando tratamento médico no momento?</h6>
            <p v-if="ficha.nomeTratamentoMedico">{{ ficha.nomeTratamentoMedico }}</p>
          </li>
          <li v-if="ficha.possuiMedicacaoRegular">
            <h6>Faz uso de medicação regular</h6>
            <p v-if="ficha.nomeMedicacaoRegular">{{ ficha.nomeMedicacaoRegular }}</p>
          </li>
        </ul>
        <div v-if="ficha.informacaoAdicional" class="adicionais">
          <h5>Informações Adicionais</h5>
          <p>{{ ficha.informacaoAdicional }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MatriculaService from '@/services/matricula.service.js'

export default {
  props: {
    aluno: {
      type: Object
    }
  },
  data () {
    return {
      ficha: null
    }
  },
  mounted () {
    if ( this.aluno.Aluno ) this.getFichaSaude()
  },
  methods: {
    getFichaSaude () {
      MatriculaService.getFichaSaude(this.aluno.Aluno.idMatricula).then(
        response => {
          console.log("getFichaSaude:", response.data)
          this.ficha = response.data.fichaSaude
        },
        () => {

        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
  .ficha-saude {
    header {
      display: flex; align-items: center;
      line-height: 1;
      gap: $hmg_mini;

      h5 { 
        margin: 0; padding: 0;
      }

      .icon {
        font-size: 16px;
      }
    }
  }

  .fichaIntro {
    display: flex;
    align-content: center;
    align-items: center;
    gap: $hmg_mid;
    margin: $hmg_mid 0;

    .blood {
      display: flex; flex-direction: column;
      align-items: center; justify-content: center;
      width: 60px; height: 60px;
      text-align: center;
      border-radius: $border-radius;
      background-color: $color-bg;

      .icon {
        margin: 0 auto;
        font-size: 18px;
      }
      h4 {
        margin: $hmg_mini 0 0 0; padding: 0;
        line-height: 1;
      }
    }
  }

  .labeledDetail {
    label {
      font-size: $font-size-small;
      font-weight: bold;
      padding: 0; margin: 0 0 $hmg_mini 0
    }
    p {
      margin: 0; padding: 0;
    }
  }

  .ficha-details {
    ul {
      padding: 0; margin: 0 0 $hmg_mid 0;

      li {
        padding: $mg_mini 0; margin: 0;
        list-style: none;
        border-top: 1px solid $color-bg;

        &:last-child {
          border-bottom: 1px solid $color-bg;
        }

        h6 {
          margin: 0; padding: 0;
        }

        p {
          margin: 0; padding: 3px 0 0 0
        }
      }
    }
  }

  .adicionais {}
</style>