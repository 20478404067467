<template>
  <controller>
    <page-header title="Matrícula"></page-header>
    <matricula-rapida v-if="action == 'pre'" :idMatricula="idMatricula" :gestao="true"></matricula-rapida>
    <matricula-controller :action="action" :step="step" :idMatricula="idMatricula" @dropIdMatricula="pushIdMatricula" v-else></matricula-controller>
  </controller>
</template>

<script>
import MatriculaController from '@/components/matriculas/template/MatriculaController.vue'
import MatriculaRapida from '@/components/matriculas/form/MatriculaRapida.vue'

export default {
  name: 'Matrícula',
  components: {
    MatriculaController, MatriculaRapida
  },
  props: {
    action: {
      type: String
    },
    step: {
      type: String
    },
    idMatricula: {
      type: [String, Number]
    }
  },
  data () {
    return {

    }
  },
  mounted () {
    console.log("action:", this.action)
    // window.pagescroll.emit("lock")
  },
  methods: {
    pushIdMatricula(e) {
      // console.log("pushIdMatricula:", e)
      const currentRoute = this.$router.currentRoute._value.name
      // console.log("currentRoute:", currentRoute)
      this.$router.push({ name: currentRoute, params: { idMatricula: e } })
    }
  }
}
</script>

<style lang="scss" scoped>
  
</style>