import axios from 'axios'
import authHeader from './auth-header'
// import moment from 'moment'

class AtividadesService {
  getAtividades ( type, filter ) {
    const view = filter.view ? 1 : 0
    const edit = filter.edit ? 1 : 0
    const execute = filter.execute ? 1 : 0

    return axios.get(process.env.VUE_APP_API_URL + `atendimento/busca/atendimentosna/${ type }/${ view }/${ edit }/${ execute }`, { headers: authHeader() })
  }

  getAtividadesPendentesHome ( quantidade, type ) {
    return axios.get(process.env.VUE_APP_API_URL + `atendimento/get/pendentes/home/${ quantidade }/${ type }`, { headers: authHeader() })
  }

  getAcompanhamentoAtividades ( type, filter ) {
    const view = filter.view ? 1 : 0
    const edit = filter.edit ? 1 : 0
    const execute = filter.execute ? 1 : 0
    return axios.get(process.env.VUE_APP_API_URL + `atendimento/busca/statusCategorias/novoapollo/0/${ view }/${ edit }/${ execute }/${ type }`, { headers: authHeader() })
  }

  saveAtividade (id, data) {
    return axios.post(process.env.VUE_APP_API_URL + 'atendimento/responder/na/' + id, data, { headers: authHeader() })
  }

  getHomeDash () {
    return axios.get(process.env.VUE_APP_API_URL + 'atendimento/dashboard/home', { headers: authHeader() })
  }

  startAtividade ( id ) {
    return axios.post(process.env.VUE_APP_API_URL + 'atendimento/iniciar/atendimento/na/' + id, {}, { headers: authHeader() })
  }

  getOperadorPermitido ( id, query ) {
    return axios.get(process.env.VUE_APP_API_URL + `atendimento/users/transferir/${id}/${query}`, { headers: authHeader() })
  }

  transferirAtividade (id, data) {
    return axios.post(process.env.VUE_APP_API_URL + 'atendimento/transferir/user/' + id, data, { headers: authHeader() })
  }
  
  getCategoriasAtividades (full) {
    return axios.get(process.env.VUE_APP_API_URL + `atendimento/categorias/na${full ? '/full' : ''}`, { headers: authHeader() })
  }
    
  getFuncoes () {
    return axios.get(process.env.VUE_APP_API_URL + `funcoes`, { headers: authHeader() })
  }
      
  getFuncoesCategoriaAtividade (idAtividadeCategoria) {
    return axios.get(process.env.VUE_APP_API_URL + `atendimento/categorias/atividades/funcoes/` + idAtividadeCategoria, { headers: authHeader() })
  }    

  getClassificacaoAtividade () {
    return axios.get(process.env.VUE_APP_API_URL + `atendimento/classificacao/atividades`, { headers: authHeader() })
  }

  getHistoricoAtendimentosAluno ( idAluno ) {
    return axios.get(process.env.VUE_APP_API_URL + 'atendimento/ultimos/registros/na/ca/' + idAluno, { headers: authHeader() })
  }

  filterAtividades ( options, page ) {
    page = page ?? 1
    return axios.post(process.env.VUE_APP_API_URL + 'atendimento/filtrar/atendimentosna?page=' + page, options, { headers: authHeader() })
  }
}

export default new AtividadesService()