<template>
  <main>
    <section class="collapseList" v-if="ready">
      <ul>
        <li v-for="(item,i) in list" :key="item" @click="!preventParentClick ? toggleCollapse(true, i, item) : null" :class="{ active: item.collapse }">
          <card :boxed="true">
            <div>
              <header class="headerWrap">
                <div>
                  <span :class="{active: item.collapse}" @mouseenter="preventParentClick = true" @mouseleave="preventParentClick = false" @click="toggleCollapse(false, i, item)"></span>
                </div>
                <span>
                  <h5>{{ item.nomeCategoria }}</h5>
                </span>
                <span>
                  <fa :icon="['fas','list-check']" /> 
                  {{ item.nomeFormulario }}
                </span>
                <span>
                  <span class="count">{{ item.qntGatilhos }}</span>
                </span>
                <div class="alerts">
                  <a href="#" class="" @click.prevent="editGatilho(item)" @mouseenter="preventParentClick = true" @mouseleave="preventParentClick = false">
                    <fa :icon="['fal','pen-to-square']" />
                  </a>
                </div>
              </header>
              <transition name="slidedown-small">
                <div class="contentWrap" v-if="item.collapse">
                  <table class="content-table bordered-list">
                    <thead>
                      <tr>
                        <th>id</th>
                        <th>Gatilho</th>
                        <th>Tipo</th>
                        <th>Pergunta</th>
                        <th>Resposta</th>
                        <th>Criado por</th>
                        <th>Criado em</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="gatilho in item.details" :key="gatilho">
                        <td>{{ gatilho.idGatilhoAtividade }}</td>
                        <td>{{ gatilho.nomeGatilho }}</td>
                        <td>{{ tipos[gatilho.tipo] }}</td>
                        <td>{{ gatilho.descricao }}</td>
                        <td>{{ gatilho.alternativa }}</td>
                        <td>{{ gatilho.nome }}</td>
                        <td>{{ formatDate(gatilho.createdAt) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </transition>
            </div>
          </card>
        </li>
      </ul>
    </section>
  </main>
</template>

<script>
import FerramentaService from  '@/services/ferramentas.service.js'
import { formatDate } from '@/services/utils.service.js'

export default {
  props: {
    idCategoria: {
      type: [String, Number]
    },
    idFormulario: {
      type: [String, Number]
    },
    perfilPessoa: {
      type: [String, Number]
    }
  },
  data () {
    return {
      preventParentClick: false,
      ready: false,
      list: [],
      tipos: {
        novaAtividade: "Nova Atividade",
        notificaHangouts: "Notificação (Hangouts)"
      } 
    }
  },
  mounted () {
    this.getGatilhos()
  },
  methods: {
    getGatilhos () {
      FerramentaService.getGatilhosAtividades().then(
        response => {
          response.data.map( k => {
            k.collapse = false
            k.loadingDetails = false
            k.details = {}
            this.list.push(k)
          })
          this.ready = true
          console.log("getGatilhosAtividades:", this.list)
          // this.list = response.data
        }
      )
    },
    toggleCollapse ( collapse, index, item, force ) {
      const target = this.list[index]

      if ( !target.collapse && collapse || force ) {
        // this.reload = {
        //   collapse: collapse,
        //   index: index,
        //   checkout: checkout
        // }
        // console.log("force:", force)
        
        target.loadingDetails = true
        FerramentaService.getGatilhosDetails(item.idAtividadeCategoria,item.idFormulario).then(
          response => {
            target.loadingDetails = false
            target.details = response.data
            target.collapse = collapse
            console.log("getGatilhosDetails:", response.data)
          },
          error => {
            console.error('error:', error.response)
            target.loadingDetails = false
          }
        )
      } else {
        target.collapse = collapse
      }
    },
    formatDate ( date ) {
      return formatDate(date)
    },
    editGatilho ( gatilho ) {
      console.log("editGatilho:", gatilho)
      this.$router.push({ name: 'Gatilhos Atividades', params: { idCategoria: gatilho.idAtividadeCategoria, idFormulario: gatilho.idFormulario, perfilPessoa: gatilho.idPerfilPessoa }})
    }
  }
}
</script>

<style lang="scss" scoped>
  main {
    border-top: $border-component;
    padding: $mg;

    @media screen and ( max-width: 1366px ) {
      padding: $hmg_mid
    }
  }

  .count {
    display: flex; flex-direction: column;
    justify-content:  center; align-items: center;
    width: 24px; height: 24px;
    min-width: 24px; min-height: 24px;
    max-width: 24px; max-height: 24px;
    border-radius: 100%;
    background-color: $color-success;
    line-height: 1;
    color: $color-secondary
  }

  .alerts {
    a {
      font-size: 16px;

      &:hover {
        color: $color-primary !important;
      }
    }
  }

  .headerWrap {
    h5 {
      margin: 0; padding: 0;
    }
  }

  .contentWrap {
    border-top: $border-component;
    padding: $hmg_mid;
    margin-top: $hmg
  }
</style>