<template>
  <controller :goToTopButton="true">
    <div class="page-title">
      <h3 class="text-capitalize">Prompts Conteúdos</h3>
      <transition name="slideRightToLeft">
        <a href="#" @click.prevent="createPrompt()" class="btn btn-primary" v-if="!create && !edit">
          <span>Criar Prompt</span>&nbsp;
          <fa :icon="['fas', 'rectangle-code']"></fa>
        </a>
      </transition>
      <transition name="slideRightToLeft">
        <a href="#" @click.prevent="voltar()" v-if="create || edit" class="btn btn-secondary">
          <fa :icon="['far', 'arrow-left-long']" />&nbsp;
          Voltar
        </a>
      </transition> 
    </div>
    <div class="page-inner">
      <transition name="slideup-small">
        <prompt :scope="prompt" v-if="create || edit" @onCancel="voltar" @onSave="getPrompts"></prompt>
      </transition>
      <transition name="slideup-small">
        <prompts :scope="prompts" v-if="prompts && !create && !edit" @onEdit="editPrompt"></prompts>
      </transition>
    </div>
  </controller>
</template>

<script>
import Prompts from '@/components/solucoesescolares/prompts/form/Prompts.vue'
import Prompt from '@/components/solucoesescolares/prompts/form/Prompt.vue'
import EstudAIService from '@/services/estudai.service.js'

export default {
  components: {
    Prompts, Prompt
  },
  data () {
    return {
      prompts: null,
      prompt: null,
      create: false,
      edit: false,
      model: {
        descricao: null,
        promptTeoria: null,
        promptPratica: null,
        promptOrientacaoEstudos: null
      }
    }
  },
  mounted () {
    this.getPrompts()
  },
  methods: {
    getPrompts () {
      window.spinner.emit("open")
      EstudAIService.getPrompts().then(
        response => {
          console.log("getPrompts:", response.data)

          this.prompts = response.data
          window.spinner.emit("close")
        },
        () => {
          window.spinner.emit("close")
        }
      )
    },
    createPrompt () {
      this.create = true
      this.prompt = this.model
    },
    editPrompt ( prompt ) {
      this.edit = true
      this.prompt = prompt
    },
    voltar () {
      this.create = false
      this.edit = false
      this.prompt = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>