<template>
  <section>
    <p>Olá, {{ parseNome() }}. Seja bem-vindo a sua conta do Shop Decisão.</p>
    <p>Aqui, por enquanto, você pode acompanhar seus pedidos <br>e ver seus boletos, mas em breve poderá fazer muito mais.</p>
    <p>Volte, sempre temos novidades para você!</p>
  </section>
</template>

<script>
export default {
  computed: {
    currentUser() {
      return localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null
    },
  },
  data () {
    return {

    }
  },
  mounted () {

  },
  methods: {
    parseNome () {
      console.log("this.currentUser:", this.currentUser)
      if ( this.currentUser.nome ) {
        return this.currentUser.nome.split(" ")[0]
      } else {
        return "Nome..."
      }
      
    }
  }
}
</script>

<style lang="scss" scoped>

</style>