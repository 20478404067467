<template>
  <section ref="saudeForm">
    <div class="row no-padd" v-if="matricula">
      <div class="col-md-4 form-group no-padd-left">
        <label>Tipo Sanguínio</label>
        <select-control 
          v-model="matricula.fichaSaude.tipoSanguineo" 
          name="tipoSanguineo" placeholder="Selecione" 
          :options="options.tiposSaguineos"
          :valid="valid.tipoSanguineo"
          @change="valid.tipoSanguineo = true"
          :disabled="action == 'ver'"
          required
        />
      </div>
    </div>
    <div class="row no-padd" v-if="matricula">
      <div class="col-md-12 form-group no-padd-left">
        <ul class="saude-about">
          <li :class="{ toggle: matricula.fichaSaude.possuiPlanoSaude }">
            <label class="check-control">
              <input type="checkbox" class="check" v-model="matricula.fichaSaude.possuiPlanoSaude" @change="trackChange(matricula.fichaSaude.possuiPlanoSaude)" :disabled="action == 'ver'"/>
              <span>Possui plano de saúde?</span>
            </label>
            <div v-if="matricula.fichaSaude.possuiPlanoSaude">
              <input-control v-model="matricula.fichaSaude.nomePlanoSaude" placeholder="Qual?" type="text" name="nomePlanoSaude" cssClass="invert" :disabled="action == 'ver'"/>
            </div>
          </li>
          <li :class="{ toggle: matricula.fichaSaude.possuiNecessidadeEspecial }">
            <label class="check-control">
              <input type="checkbox" class="check" v-model="matricula.fichaSaude.possuiNecessidadeEspecial" :disabled="action == 'ver'"/>
              <span>É portador de necessidade especiais?</span>
            </label>
            <div v-if="matricula.fichaSaude.possuiNecessidadeEspecial">
              <textarea-control v-model="matricula.fichaSaude.nomeNecessidadeEspecial" name="nomeNecessidadeEspecial" placeholder="Mais detalhes" cssClass="invert" :disabled="action == 'ver'"/>
            </div>
          </li>
          <li :class="{ toggle: matricula.fichaSaude.possuiAlergiaMedicamento }">
            <label class="check-control">
              <input type="checkbox" class="check" v-model="matricula.fichaSaude.possuiAlergiaMedicamento" :disabled="action == 'ver'"/>
              <span>É alergico a medicamentos?</span>
            </label>
            <div v-if="matricula.fichaSaude.possuiAlergiaMedicamento">
              <textarea-control v-model="matricula.fichaSaude.nomeAlergiaMedicamento" name="possuiAlergiaMedicamento" placeholder="Mais detalhes" cssClass="invert" :disabled="action == 'ver'"/>
            </div>
          </li>
          <li :class="{ toggle: matricula.fichaSaude.realizaTratamentoMedico }">
            <label class="check-control">
              <input type="checkbox" class="check" v-model="matricula.fichaSaude.realizaTratamentoMedico" :disabled="action == 'ver'"/>
              <span>Está realizando tratamento médico no momento?</span>
            </label>
            <div v-if="matricula.fichaSaude.realizaTratamentoMedico">
              <textarea-control v-model="matricula.fichaSaude.nomeTratamentoMedico" name="nomeTratamentoMedico" placeholder="Mais detalhes" cssClass="invert" :disabled="action == 'ver'"/>
            </div>
          </li>
          <li :class="{ toggle: matricula.fichaSaude.possuiMedicacaoRegular }">
            <label class="check-control">
              <input type="checkbox" class="check" v-model="matricula.fichaSaude.possuiMedicacaoRegular" :disabled="action == 'ver'"/>
              <span>Faz uso de medicação regular?</span>
            </label>
            <div v-if="matricula.fichaSaude.possuiMedicacaoRegular">
              <textarea-control v-model="matricula.fichaSaude.nomeMedicacaoRegular" name="nomeMedicacaoRegular" placeholder="Mais detalhes" cssClass="invert" :disabled="action == 'ver'"/>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="row no-padd" v-if="matricula">
      <div class="col-md-12 form-group no-padd-left">
        <label>Existe alguma informação relevante quanto às condições de saúde do aluno que o colégio deva conhecer?</label>
        <textarea-control v-model="matricula.fichaSaude.nomeNecessidadeEspecial" name="informacaoAdicional" placeholder="Explique" :disabled="action == 'ver'"/>
      </div>
    </div>
  </section>
</template>

<script>
// import UserService from '@/services/user.service'
// import { createSelectOptions } from '@/services/utils.service'
import FormService from '@/services/form.service'

export default {
  props: {
    action: {
      type: String
    }
  },
  data () {
    return {
      matricula: null,
      options: {
        tiposSaguineos: [
          {
            label: 'A+',
            value: 'A+'
          },
          {
            label: 'A-',
            value: 'A-'
          },
          {
            label: 'B+',
            value: 'B+'
          },
          {
            label: 'B-',
            value: 'B-'
          },
          {
            label: 'AB+',
            value: 'AB+'
          },
          {
            label: 'AB-',
            value: 'AB-'
          },
          {
            label: 'O+',
            value: 'O+'
          },
          {
            label: 'O-',
            value: 'O-'
          },
          {
            label: 'Não Informado',
            value: 'NI'
          }
        ],
      },
      valid: {
        tipoSanguineo: true
      }
    }
  },
  mounted () {
    // this.getUfs()
    // this.getCidades()

    this.$store.dispatch('matricula/get').then(response => {
      console.log("matricula-get:", response)
      this.matricula = response
      window.spinner.emit('close')
    })
  },
  methods: {
    validate () {
      const valid = FormService.validate(this.$refs.saudeForm, this.valid)
      return valid
    },
    trackChange ( target ) {
      console.log("trackChange:", target)
    }
  }
}
</script>

<style lang="scss" scoped>
  .saude-about {
    padding: $hmg 0;
    margin: 0;

    li {
      display: flex;
      list-style: none;
      padding: $mg_mini; margin-bottom: $mg_mini;
      border-radius: $border-radius;
      background-color: $color-secondary;
      @extend %transition_4e; 

      > div {
        margin-top: $mg_mini
        // position: absolute; top: 50px;
        // width: 100%;
        // transform: translateY(100%);
      }

      &.toggle {
        display: block;
        border-radius: $border-radius-larger;
        padding: $hmg_small;
        // padding-bottom: $hmg;
        // margin-bottom: 100px;
      }
    }
  }
</style>