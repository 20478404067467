<template>
  <div class="row no-padd" ref="enderecoForm" v-if="matricula">
    <div class="col-md-4 form-group no-padd-left">
      <label>CEP</label>
      <input-control 
        v-if="child"
        v-model="matricula[branch][child].Endereco.cep" 
        placeholder="00000-000" 
        name="cep" 
        type="text" 
        :valid="valid.cep" 
        :disabled="loadingInputContent" 
        @keyup="valid.cep = true; buscaCep()"
        @focus="triggerTip(branch,'cep')"
        masking="cep"
        required
      />
      <input-control 
        v-else
        v-model="matricula[branch].Endereco.cep" 
        placeholder="00000-000" 
        name="cep" 
        type="text" 
        :valid="valid.cep" 
        :disabled="loadingInputContent" 
        @keyup="valid.cep = true; buscaCep()"
        @focus="triggerTip(branch,'cep')"
        masking="cep"
        required
      />
    </div>
    <div class="col-md-8 form-group no-padd-left">
      <label>Logradouro</label>
      <input-control 
        v-if="child"
        v-model="matricula[branch][child].Endereco.logradouro" 
        placeholder="Avenida Paulista" 
        name="logradouro" 
        type="text" 
        :valid="valid.logradouro"
        :disabled="loadingInputContent || action == 'ver'" 
        @keyup="valid.logradouro = true" 
        @focus="triggerTip(branch,'logradouro')"
        required
      />
      <input-control 
        v-else
        v-model="matricula[branch].Endereco.logradouro" 
        placeholder="Avenida Paulista" 
        name="logradouro" 
        type="text" 
        :valid="valid.logradouro"
        :disabled="loadingInputContent || action == 'ver'" 
        @keyup="valid.logradouro = true" 
        @focus="triggerTip(branch,'logradouro')"
        required
      />
    </div>
    <div class="col-md-2 form-group no-padd-left">
      <label>Número</label>
      <input-control 
        v-if="child"
        v-model="matricula[branch][child].Endereco.numero" 
        placeholder="Avenida Paulista" 
        name="logradouro" 
        type="text" 
        :valid="valid.logradouro"
        :disabled="loadingInputContent || action == 'ver'" 
        @keyup="valid.logradouro = true" 
        @focus="triggerTip(branch,'numero')"
        ref="number"
        required
      />
      <input-control 
        v-else
        v-model="matricula[branch].Endereco.numero" 
        placeholder="Avenida Paulista" 
        name="logradouro" 
        type="text" 
        :valid="valid.logradouro"
        :disabled="loadingInputContent || action == 'ver'" 
        @keyup="valid.logradouro = true" 
        @focus="triggerTip(branch,'numero')"
        ref="number"
        required
      />
    </div>
    <div class="col-md-4 form-group no-padd-left">
      <label>Complemento</label>
      <input-control 
        v-if="child"
        v-model="matricula[branch][child].Endereco.complemento" 
        placeholder="Casa, Apto, etc." 
        name="complemento" 
        type="text" 
        :disabled="loadingInputContent || action == 'ver'" 
        @focus="triggerTip(branch,'complemento')"
      />
      <input-control 
        v-else
        v-model="matricula[branch].Endereco.complemento" 
        placeholder="Casa, Apto, etc." 
        name="complemento" 
        type="text" 
        :disabled="loadingInputContent || action == 'ver'" 
        @focus="triggerTip(branch,'complemento')"
      />
    </div>
    <div class="col-md-6 form-group no-padd-left">
      <label>Bairro</label>
      <input-control 
        v-if="child"
        v-model="matricula[branch][child].Endereco.bairro" 
        placeholder="Jardins" 
        name="bairro"
        type="text" 
        :valid="valid.bairro" 
        :disabled="loadingInputContent || action == 'ver'" 
        @keyup="valid.bairro = true" 
        @focus="triggerTip(branch,'bairro')"
        required
      />
      <input-control 
        v-else
        v-model="matricula[branch].Endereco.bairro" 
        placeholder="Jardins" 
        name="bairro"
        type="text" 
        :valid="valid.bairro" 
        :disabled="loadingInputContent || action == 'ver'" 
        @keyup="valid.bairro = true" 
        @focus="triggerTip(branch,'bairro')"
        required
      />
    </div>
    <div class="col-md-6 form-group no-padd-left">
      <label>Cidade</label>
      <template v-if="child">
        <select-control 
          v-model="matricula[branch][child].Endereco.idCidade" 
          placeholder="São Paulo" 
          :options="options.cidades" 
          name="idCidade" 
          type="text" 
          :valid="valid.idCidade"
          :disabled="loadingInputContent || action == 'ver'" 
          @change="valid.idCidade = true; fillNome('cidades', matricula[branch][child].Endereco.idCidade)" 
          @focus="triggerTip(branch,'idCidade')"
          required
        />
        <input type="hidden" name="nomeCidade" v-model="matricula[branch][child].Endereco.nomeCidade" />
      </template>
      <template v-else>
        <select-control 
          v-model="matricula[branch].Endereco.idCidade" 
          placeholder="São Paulo" 
          :options="options.cidades" 
          name="idCidade" 
          type="text" 
          :valid="valid.idCidade"
          :disabled="loadingInputContent || action == 'ver'" 
          @change="valid.idCidade = true; fillNome('cidades', matricula[branch].Endereco.idCidade)" 
          @focus="triggerTip(branch,'idCidade')"
          required
        />
        <input type="hidden" name="nomeCidade" v-model="matricula[branch].Endereco.nomeCidade" />
      </template>
    </div>
    <div class="col-md-2 form-group no-padd-left">
      <label>UF</label>
      <template v-if="child">
        <select-control 
          v-model="matricula[branch][child].Endereco.idUf" 
          placeholder="SP" 
          :options="options.ufs" 
          name="idUf" 
          type="text" 
          :valid="valid.idUf" 
          :disabled="loadingInputContent || action == 'ver'" 
          @change="valid.idUf = true; fillNome('ufs', matricula[branch][child].Endereco.idUf)" 
          @focus="triggerTip(branch,'idUf')"
          required
        />
        <input type="hidden" name="nomeUf" v-model="matricula[branch][child].Endereco.nomeUf" />
      </template>
      <template v-else>
        <select-control 
          v-model="matricula[branch].Endereco.idUf" 
          placeholder="SP" 
          :options="options.ufs" 
          name="idUf" 
          type="text" 
          :valid="valid.idUf" 
          :disabled="loadingInputContent || action == 'ver'" 
          @change="valid.idUf = true; fillNome('ufs', matricula[branch].Endereco.idUf)" 
          @focus="triggerTip(branch,'idUf')"
          required
        />
        <input type="hidden" name="nomeUf" v-model="matricula[branch].Endereco.nomeUf" />
      </template>
    </div>
    <div class="col-md-3 form-group checkbox-only" v-if="hasEndereco">
      <!-- <label class="check-control">
        <input type="checkbox" class="check" name="addForAll" v-model="addForAll" @change="addEnderecoParaTodos"/>
        <span>
          Utilizar o mesmo endereço para todas as pessoas desta matrícula 
        </span>
      </label> -->
    </div>
  </div>
</template>

<script>
import UserService from '@/services/user.service'
import FormService from '@/services/form.service'
import { createSelectOptions, getCEP } from '@/services/utils.service'

export default {
  props: {
    autoPopulate: {
      type: Object
    },
    branch: {
      type: String
    },
    child: {
      type: String
    },
    action: {
      type: String
    },
  },
  watch: {
    autoPopulate ( value ) {
      if ( value ) {
        // console.log("autoPopulate:", value)
        if ( this.child ) {
          this.matricula[this.branch][this.child].Endereco = value
        } else {
          this.matricula[this.branch].Endereco = value
        }
        
        this.hasEndereco = true
      }
    }
  },
  data () {
    return {
      matricula: null,
      valid: {
        cep: true,
        logradouro: true,
        numero: true,
        bairro: true,
        idUf: true,
        idCidade: true
      },
      options: {
        ufs: [],
        cidades: []
      },
      wait: {
        ufs: false,
        cidades: false
      },
      loading: false,
      loadingInputContent: false,
      searchCepLoop: null,
      hasEndereco: false,
      addForAll: false
    }
  },
  mounted () {
    this.$store.dispatch('matricula/get').then(response => {
      // console.log("matricula:", response)
      this.matricula = response
      window.spinner.emit("open")
      this.loading = true
      this.loadingInputContent = true

      this.getUfs()
      this.getCidades()
    })
    
  },
  methods: {
    getUfs () {
      UserService.ufs().then(
        response => {
          if ( response.data.length > 0 ) {
            console.log('ufs:', response.data)
            this.options.ufs = createSelectOptions(response.data,'idUf', 'uf')
            this.wait.ufs = true
            this.stopSpinner()
          } else {
            //
          }
        },
        error => {
          console.error(error)
          window.spinner.emit("close")
          this.loading = false
          this.loadingInputContent = false
        }
      )
    },
    getCidades () {
      UserService.cidades().then(
        response => {
          if ( response.data.length > 0 ) {
            console.log('cidades:', response.data)
            this.wait.cidades = true
            this.options.cidades = createSelectOptions(response.data,'idCidade', 'nome')
            this.stopSpinner()
          } else {
            //
          }
        },
        error => {
          console.error(error)
          window.spinner.emit("close")
          this.loading = false
          this.loadingInputContent = false
        }
      )
    },
    stopSpinner () {
      const done = !Object.values(this.wait).includes(false)
      console.log("done:", done)
      if ( done ) {
        this.loading = false
        this.loadingInputContent = false
        this.$emit('onload')
      }
    },
    fillNome ( target, data ) {
      console.log("fillNome:", target)
      console.log("data:", data)
    },
    buscaCep () {
      const cep = this.child ? this.matricula[this.branch][this.child].Endereco.cep : this.matricula[this.branch].Endereco.cep
      // console.log('cep:', cep)
      
      if ( cep && cep.length == 9) {
        if ( this.searchCepLoop ) clearTimeout(this.searchCepLoop)

        this.searchCepLoop = setTimeout (() => {
          window.spinner.emit("open")
          this.loadingInputContent = true
          getCEP(cep).then(
            response => {
              console.log("getCEP:", response)

              if ( response.data.erro ) {
                window.toaster.emit('open', {style: "warning", message: "CEP não econtrado..", floater: true})
              } else {
                this.populateEnderecoByCep(response.data)
              }
              this.loadingInputContent = false
              window.spinner.emit("close")
            },
            error => {
              console.error(error)
              this.loadingInputContent = false
              window.spinner.emit("close")
            }
          )
        },300)
        
      }
    },
    populateEnderecoByCep ( data ) {
      if ( this.child ) {
        this.matricula[this.branch][this.child].Endereco.logradouro = data.logradouro
        this.matricula[this.branch][this.child].Endereco.bairro = data.bairro
        this.matricula[this.branch][this.child].Endereco.nomeCidade = data.localidade
        this.matricula[this.branch][this.child].Endereco.nomeUf = data.uf
      } else {
        this.matricula[this.branch].Endereco.logradouro = data.logradouro
        this.matricula[this.branch].Endereco.bairro = data.bairro
        this.matricula[this.branch].Endereco.nomeCidade = data.localidade
        this.matricula[this.branch].Endereco.nomeUf = data.uf
      }
      

      const searchCidades = this.options.cidades.filter( k => {
        return k.label.toLowerCase() == data.localidade.toLowerCase()
      })

      const idCidade = searchCidades[0].value
      if ( this.child ) this.matricula[this.branch][this.child].Endereco.idCidade = idCidade
      else this.matricula[this.branch].Endereco.idCidade = idCidade

      const searchUf = this.options.ufs.filter( k => {
        return k.label.toLowerCase() == data.uf.toLowerCase()
      })

      const idUf = searchUf[0].value
      if ( this.child ) this.matricula[this.branch][this.child].Endereco.idUf = idUf
      else this.matricula[this.branch].Endereco.idUf = idUf

      console.log(this.$refs.number)
      this.$refs.number.$refs.input.focus()

      this.hasEndereco = true
    },
    addEnderecoParaTodos () {
      console.log('addEnderecoParaTodos:', this.matricula)
    },
    validate () {
      const valid = FormService.validate(this.$refs.enderecoForm, this.valid)
      console.log("validate:", valid)
      return valid
    },
    triggerTip ( branch, field ) {
      const tip = {
        branch: branch,
        field: field
      }
      window.mattip.emit("add", tip)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>