<template>
  <div class="planos">
    <header>
      <h4>Selecione o plano que deseja simular</h4>
    </header>
    <ul v-if="planos">
      <li v-for="( plano, i ) in planos" :key="plano" @click="selectPlano(plano)">
        <div>
          <span v-if="plano.interno" class="internoTag">Interno</span>
          <p>Opção {{ i + 1 }}</p>
          <h2 class="color-primary">{{ plano.descricao }}</h2>
          <div class="valorTotal">
            <p>De: <del>{{ formatMoney(parseFloat(plano.valorTotal)) }}</del></p>
            <p>
                <b>Por: {{ formatMoney(parseFloat(plano.valorTotalDesconto)) }} 
                <!-- <span class="color-success">com {{ plano.descontPlano }}% de desconto</span> -->
            </b></p>
          </div>
          <div class="discountMessage">
            <fa :icon="['fas','badge-dollar']" class="icon"></fa>
            <span>Economia de {{ formatMoney(calcDiscountDif(plano)) }}</span>
          </div>
        </div>
        <div>
          <div class="planoBox" v-if="plano.entrada">
            <header>
              Primeira Mensalidade
            </header>
            <div>
              <div class="months">
                <p>{{ dropDate(plano.entrada.mesInicio) }}</p>
                <div>
                  <span></span>
                  <p>{{ plano.entrada.numeroParcelas }} Parcelas</p>
                </div>
                <p>{{ dropDate(plano.entrada.mesFinal) }}</p>
              </div>
              <div class="price">
                <div>
                  <p>De</p>
                  <p>{{ plano.entrada.numeroParcelas }}x de <b><del>{{ formatMoney(plano.entrada.valorParcela) }}</del></b></p>
                  <p><del>{{ formatMoney(plano.entrada.valorTotal) }}</del></p>
                </div>
                <div>
                  <p>Por</p>
                  <p>{{ plano.entrada.numeroParcelas }}x de <b><span>{{ formatMoney(plano.entrada.valorParcelaDesconto) }}</span></b></p>
                  <p>{{ formatMoney(plano.entrada.valorTotalDesconto) }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="planoBox" v-if="plano.mensalidades">
            <header>
              {{ plano.entrada && 'Demais' }} Mensalidades
            </header>
            <div>
              <div class="months">
                <p>{{ dropDate(plano.mensalidades.mesInicio) }}</p>
                <div>
                  <span></span>
                  <p>{{ plano.mensalidades.numeroParcelas }} Parcelas</p>
                </div>
                <p>{{ dropDate(plano.mensalidades.mesFinal) }}</p>
              </div>
              <div class="price">
                <div>
                  <p>De</p>
                  <p>{{ plano.mensalidades.numeroParcelas }}x de <b><del>{{ formatMoney(plano.mensalidades.valorParcela) }}</del></b></p>
                  <p><del>{{ formatMoney(plano.mensalidades.valorTotal) }}</del></p>
                </div>
                <div>
                  <p>Por</p>
                  <p>{{ plano.mensalidades.numeroParcelas }}x de <b><span>{{ formatMoney(plano.mensalidades.valorParcelaDesconto) }}</span></b></p>
                  <p>{{ formatMoney(plano.mensalidades.valorTotalDesconto) }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="planoBox" v-if="plano.anuidade">
            <header>
              Anuidade
            </header>
            <div>
              <div class="months">
                <p><b>1 Parcela</b></p>
                <div>
                  <span></span>
                  <!-- <p>{{ plano.anuidade.numeroParcelas }} Parcelas</p> -->
                </div>
                <p>{{ dropDate(plano.anuidade.mesFinal) }}</p>
              </div>
              <div class="price">
                <div>
                  <p>De</p>
                  <!-- <p>{{ plano.anuidade.numeroParcelas }}x de <b><del>{{ formatMoney(plano.anuidade.valorParcela) }}</del></b></p> -->
                  <p><del>{{ formatMoney(plano.anuidade.valorTotal) }}</del></p>
                </div>
                <div>
                  <p>Por</p>
                  <!-- <p>{{ plano.anuidade.numeroParcelas }}x de <b><span>{{ formatMoney(plano.anuidade.valorParcelaDesconto) }}</span></b></p> -->
                  <p><b><span>{{ formatMoney(plano.anuidade.valorTotalDesconto) }}</span></b></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { formatMoney } from '@/services/utils.service'

export default {
  props: {
    scope: {
      type: Array
    }
  },
  data () {
    return {
      planos: null,
      options: {
        meses: {
          1: 'jan',
          2: 'fev',
          3: 'mar',
          4: 'abr',
          5: 'mai',
          6: 'jun',
          7: 'jul',
          8: 'ago',
          9: 'set',
          10: 'out',
          11: 'nov',
          12: 'dez'
        },
      }
    }
  },
  mounted () {
    this.planos = Object.assign([], this.scope)
  },
  methods: {
    calcDiscountDif ( plano ) {
      const valorTotal = parseFloat(plano.valorTotal)
      const valorTotalDesconto = parseFloat(plano.valorTotalDesconto)

      const dif = valorTotal - valorTotalDesconto
      return dif
    },
    formatMoney ( value ) {
      return formatMoney(value)
    },
    selectPlano ( plano ) {
      console.log("selectPlano:", plano)
      
      this.$emit('onSelect', plano)
    },
    dropDate ( date ) {
      console.log("dropDate:", date)

      const parsed = date.split('/')
      const year = parsed[1].slice(-2)
      return this.options.meses[parseFloat(parsed[0])] + '/' + year
    },
  }
}
</script>

<style lang="scss" scoped>
  .planos {
    padding: $hmg_mid;

    header {
      margin-bottom: $hmg_mid
    }

    ul {
      display: flex; flex-wrap: wrap;
      align-content: stretch; align-items: flex-start;
      gap: $hmg_mid; width: 100%;
      padding: 0; margin: 0;
      
      li {
        display: flex; align-items: stretch; flex-direction: column;
        width: 32%; padding-bottom: $hmg;
        border-radius: $border-radius-large;
        border: $border-component;
        background-color: $color-secondary;
        list-style: none; margin: 0;
        position: relative;
        cursor: pointer;

        @extend %transition_3o;
        
        &:hover {
          @extend %shadow-mid;
          border-color: $color-primary !important
        }

        @media screen and (max-width: 1024px) {
          width: 100%;
        }

        >div {
          &:first-child {
            padding: $hmg;
          }
          padding: 0 $hmg;

          >h2 { 
            max-width: 70%;
            @media screen and (max-width: 1024px) {
              max-width: 100%;
            }
          }

          .valorTotal {
            p {
              margin: 0; padding: 0;

              &:last-child {
                font-size: $font-size-larger
              }
            }
          }
          
          .discountMessage {
            display: flex; align-items: center;
            font-weight: bold; gap: $hmg_mini;
            margin: $hmg_mid 0 0 0;

            .icon {
              font-size: 20px;
              color: $color-success-2;
            }
          }
        }

        a:not(.toggleCalc) {
          display: flex;
          width: 100%;
          height: 50px;
          align-items: center;
          align-content: center;
          justify-content: center;
          background-color: $color-primary;
          margin-top: $hmg_mid;
          font-size: 18px; text-transform: uppercase; font-weight: bold;
          color: $color-secondary;
          border-bottom-left-radius: $border-radius-large;
          border-bottom-right-radius: $border-radius-large;
          @extend %transition_4e;

          &:hover {
            height: 60px;
          }
        }

        &.selected {
          border: 1px solid $color-success-2 !important;

          a:not(.toggleCalc) {
            background-color: $color-success-2;
          }
        }

        .planoBox {
          border-radius: $border-radius;
          border: $border-component;
          overflow: hidden;
          margin-bottom: $hmg_mid;

          &:last-child {
            margin-bottom: 0;
          }

          header {
            padding: $hmg_small;
            border-bottom: $border-component;
            font-size: 20px; font-weight: bold;
            text-align: center !important;
            background-color: $color-bg
          }
          > div {
            padding: $hmg_mid;
          }
        }        

        .btn-check {
          width: calc(100% + $mg_mid);
          margin-top: $hmg; margin-left: -$hmg_small;
        }

        h2 {
          margin-bottom: $hmg;
        }

        .planosContent {
          display: flex; align-content: center; align-items: center;
          justify-content: space-between;
          width: 100%;

          >span {
            &:last-child {
              text-align: right;
            }
            &:first-child {
              text-align: left !important;
            }
            p {
              font-size: $font-size-larger; margin: 0;
            }

            &.large {
              p {
                font-size: 16px;
              }
            }
          }
        }

        .planoCardHeader {
          margin-bottom: $hmg_mini;

          h5 {
            margin: 0;
          }
        }

        .planoCard {
          width: calc( 100% + $mg_mid );
          margin-left: -$hmg_small;
          background-color: $color-secondary;
          border-radius: $border-radius;
          padding: $hmg_small;
          position: relative;

          footer {
            height: 37px; width: 100%;
            position: absolute; bottom: 0; left: 0;
            background-color: $color-success-2;
            display: flex; align-content: center; align-items: center; justify-content: space-between;
            font-weight: bold;
            padding: 0 $hmg_small;
            border-bottom-left-radius: $border-radius; border-bottom-right-radius: $border-radius;

            p {margin: 0; white-space: nowrap;}
          }

          &.hasFooter {
            padding-bottom: calc($hmg_small + 37px);
          }
        }

        

        .btDiscountDetails {
          font-size: 20px;

          .icon {
            @extend %transition_4e;
          }

          &:hover {
            .icon {
              transform: scale(1.1);
            }
          }
        }

        .planoDetails {
          width: 100%; margin-bottom: $hmg_mid;
        }

        .due {
          font-size: $font-size-larger;
          margin-top: $mg;

          @media screen and ( max-width: 640px ) {
            margin-top: $hmg;
          }

          p {
            margin: 0
          }
        }
      }
    }
    
  }

  .dueDateSelect {
    display: flex; align-items: center; align-content: center;
    background-color: $color-secondary;
    border-radius: $border-radius;
    padding: $mg_mini $hmg_small;

    label {
      margin: 0; padding: 0;
    }
    select {
      appearance: none;
      padding: 0; margin: 0;
      line-height: 1;
      outline: 2px solid $color-primary;
      border: 0; margin-left: $hmg_small;
      color: $color-text; font-weight: bold;
      width: 26px; height: 26px; border-radius: 100%;
      background-color: $color-bg;
      text-align: center;

      option {
        padding: 0; margin: 0;
        line-height: 1;
      }
    }
  }

  .counterTag {
    // position: absolute; top: 0; left: 0;
    padding: $hmg_mini $hmg_small;
    border-radius: $border-radius;
    transform: translateY(-100%);
    margin-left: -$hmg_small;
    background-color: $color-secondary;
    @extend %shadow-low-light;
  }

  .months {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $mg_mini;
    
    >p{
      margin: 0; padding: 0;
    }
    
    >div {
      flex: 1;
      position: relative;

      span {
        width: 100%; height: 1px;
        background-color: $color-bg;
        display: block;
      }
      p {
        margin: 0;
        padding: 0;
        position: absolute;
        left: 0; right: 0;
        margin: auto;
        transform: translateY(10px);
        font-weight: bold;
        text-align: center;
      }
    }
  }

  .price {
    display: flex; width: 100%;
    margin-top: $hmg;
    align-items: flex-start; align-content: flex-start;
    justify-content: space-between;
    // gap: $hmg_mid;

    > div {
      // flex: 1;

      &:first-child {
        p {
          color: $color-mid-grey;
          padding-right: $hmg_mini
        }
      }

      &:last-child{
        text-align: right;

        p {
          padding-left: $hmg_mini
        }
      }

      p {
        display: block;
        margin: 0; padding: 0;  

        &:nth-child(1) {
          font-weight: bold;
          margin-bottom: 3px;
        }
        &:nth-child(2) {
          font-size: $font-size-larger;
          border-bottom: $border-component;

          span {
            color: $color-success-2
          }
        }
        &:nth-child(3) {
          margin-top: 3px;
        }
        &:last-child {
          border-bottom: 0 !important;

          span {
            color: $color-success-2
          }
        }
      }
    }
  }

  .internoTag {
    display:  inline-flex; align-content: center; justify-content: center;
    margin-bottom: $hmg_mid;
    padding: $hmg_mini $mg_mini;
    border-radius: 2em;
    background-color:  $color-primary;
    color: $color-secondary
  }
</style>