<template>
  <div class="page-inner">
    <div class="row no-padd">
      <div class="col-md-4 form-group no-padd-left">
        <label>Escolha o tipo de planilha que você quer ver e/ou criar</label>
        <select-control 
          :options="options.tipoPlanilha"
          v-model="tipoPlanilha"  
          placeholder="Selecione" 
          name="tipoPlanilha" 
          type="text"
          @change="getPlanilhas()"
          :disabled="loadingListcontent"
        ></select-control>
      </div>
      <div class="col-md-2 form-group mainActionWrap" v-if="tipoPlanilha && !newPlanilha && newFormReady">
        <a href="#" class="btn btn-primary" @click.prevent="toggleNew(true)">
          <span>Adicionar nova planilha</span>&nbsp;
          <fa :icon="['fas','file-spreadsheet']"></fa>
        </a>
      </div>
    </div>
    <transition name="slideLeftToRightSmall">
      <div class="newPlanilha" v-if="newPlanilha" ref="newPlanilhaForm">
        <card>
          <div class="newPlanilhaInner">
            <header>
              <div>
                <h4>Adicionar nova planilha</h4>
                <p>Selecione as configurações iniciais da sua planilha</p>
              </div>
            </header>
            <div class="row no-padd">

            </div>
            <div class="row no-padd">
              <div class="col-md-3 form-group no-padd-left" v-for="form in newFormSchema" :key="form">
                <label>{{ newFormLabels[form] }}</label>
                <select-control 
                  :options="newFormOptions[form]"
                  v-model="newForm[form]"
                  placeholder="Selecione" 
                  :name="form" 
                  type="text"
                  :disabled="loadingListcontent"
                  cssClass="invert"
                  @change="createName(newForm[form], newFormOptions[form]); valid[form] = true"
                  :required="required[form]"
                  :valid="valid[form]"
                ></select-control>
              </div>
            </div>
            <div class="row no-padd">
              <div class="col-md-4 form-group no-padd-left">
                <label>Nome da Planilha</label>
                <input-control
                  v-model="nomePlanilha"  
                  placeholder="Nome da Planilha" 
                  :name="nomePlanilha" 
                  type="text"
                  :disabled="loadingListcontent"
                  cssClass="invert"
                ></input-control>
              </div>
              <div class="col-md-12 form-group no-padd-left action">
                <a href="#" class="btn btn-primary" @click.prevent="createPlanilha()">
                  <span>Criar Planilha</span>
                </a>
                <a href="#" class="btn btn-secondary" @click.prevent="toggleNew(false)">
                  <span>Cancelar Criação</span>
                </a>
              </div>
            </div>
          </div>
        </card>
      </div>
    </transition>
    <div class="planilhasListWrap" v-if="planilhas.length > 0 && planilhasLoaded">
      <h4>Planilhas</h4>
      <ol class="planilhasList">
        <li>
          <div class="plItemInner">
            <span>
                ID
            </span>
            <span>
              Nome
            </span>
            <span>
                Criada por
            </span>
            <span>
                Em
            </span>
            <span>
              Última atualização
            </span>
            <span>
              Planilha
            </span>
            <span>
                
            </span>
          </div>
        </li>
        <li v-for="(planilha, i) in planilhas" :key="planilha">
          <card>
            <div class="plItemInner">
              <span>
                {{ planilha.idPLanilha }}
              </span>
              <span>
                {{ planilha.nomePlanilha ? planilha.nomePlanilha : '-' }}
              </span>
              <span>
                {{ planilha.nome }}
              </span>
              <span>
                {{ formatDateTime(planilha.created_at) }}
              </span>
              <span>
                {{ formatDateTime(planilha.updated_at) }}
              </span>
              <span class="fixed">
                <a :href="'https://docs.google.com/spreadsheets/d/' + planilha.idGoogleSheets" target="_blank">{{ planilha.idGoogleSheets }}</a>
              </span>
              <span class="actionWrap">
                <input-spinner v-if="planilha.loading"></input-spinner>
                <template v-else-if="!planilha.sincronizado">
                  <a href="#" class="btn btn-primary" @click.prevent="getPlanilha(planilha, i)">
                    <span>Visualizar</span> &nbsp;
                    <fa :icon="['far','magnifying-glass-plus']"></fa>
                  </a>
                  <a href="#" @click.prevent="confirmSave(planilha.idPLanilha)" class="btn btn-primary">
                    <span>Salvar</span>
                  </a>
                </template>
                <span class="syncIcon" v-else>
                  <fa :icon="['fas','check']" :class="['icon',{ success: planilha.sincronizada }]"></fa>
                </span>
                <a href="#" @click.prevent="confirmRemove(planilha, i)" class="bt">
                  <fa :icon="['fas','trash-can']"></fa>
                </a>
              </span>
            </div>
            <div class="plItemDetails" v-if="planilha.details?.values && planilha.details.values?.length > 0">
              <div class="plItemDetailsTableWrap" >
                <table class="content-table bordered-list">
                  <thead>
                    <tr>
                      <th v-for="header in planilha.details.header" :key="header">
                        {{ header }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(values, vi ) in planilha.details.values" :key="values">
                      <tr v-if="vi < 200" :i="vi">
                        <td v-for="value in values" :key="value" >
                          <p v-if="value.length > 100" :title="value">{{value}}</p>
                          <template v-else>{{ value }}</template>
                        </td>
                      </tr>
                      <tr v-else-if="vi == 200">
                        <td :colspan="values.length">
                          <div class="over-limit">
                            <fa :icon="['fas','triangle-exclamation']"></fa>
                            <p>Esta planilha é muito grande para ser exibida por completo. Isto não é um problema. Para visualizar a planilha inteira, utilize o link do Google Sheets.</p>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <div class="plTableFooter">
                <a href="#" @click.prevent="cancelSync(i)" class="btn btn-secondary">
                  <span>Cancelar</span>
                </a>
                <a href="#" @click.prevent="confirmSave(planilha.idPLanilha)" class="btn btn-primary">
                  <span>Salvar</span>
                </a>
              </div>
            </div>
          </card>
        </li>
      </ol>
    </div>
    <div class="planilhasEmpty frame mini" v-else-if="planilhas.length == 0 && planilhasLoaded">
      <fa :icon="['fal', 'face-meh']" class="icon"></fa>
      <h3>Ops!</h3>
      <p>Não encontramos planilhas para esta opção.</p>
    </div>
    
    <confirm 
      :backdrop="false"
      class="confirm"
      ref="confirm"
    ></confirm>
  </div>
</template>

<script>
import PlanilasService from "@/services/planilhas.service"
import { createSelectOptions, formatDateTime } from "@/services/utils.service"
import InputSpinner from '@/components/utilities/InputSpinner'
import FormService from '@/services/form.service.js'
import UserService from '@/services/user.service.js'

export default {
  components: {
    InputSpinner
  },
  props: {
    tipo: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      options: {
        tipoPlanilha: []
      },
      tipoPlanilha: null,
      loadingListcontent: false,
      loadingPlanilhas: false,
      planilhasLoaded: false,
      planilhas: [],
      newPlanilha: false,
      newFormSchema: [],
      newFormOptions: {},
      newFormLabels: {},
      newForm: {},
      fks: {},
      nomePlanilha: "",
      newFormReady: false,
      creatingNome: false,
      required: {},
      valid: {}
    }
  },
  mounted () {
    this.getTipoPlanilhas()
    // this.permissions.financeiro.
  },
  methods: {
    getTipoPlanilhas () {
      this.loadingListcontent = true
      PlanilasService.getTipoPlanilhas( this.tipo ).then(
        response => {
          if ( response.status == 200 && response.data.length > 0) {
            console.log("tipo planilha:", this.tipo)
            console.log("planilhas:", response.data)
            var allowed

            if ( this.tipo === 'financeiro' ) {
              allowed = response.data.filter( k => {
                const tag = 'planilhasFinanceiro - ' + k.nome
                return UserService.filterPermissions(tag)
              })
            } else {
              allowed = response.data
            }
            
            // console.log("allowed:", allowed)

            allowed.map( k => {
              k.label = k.nome + ' - ' + k.descricao
            })

            this.options.tipoPlanilha = createSelectOptions(allowed, "idTipoPlanilha", "label")
          }
          // console.log("response:", response)
          this.loadingListcontent = false
        },
        error => {
          console.log("error:", error)
          this.loadingListcontent = false
        }
      )
    },
    getPlanilhas () {
      if ( !this.loadingPlanilhas ) {
        this.clearData()

        this.loadingPlanilhas = true
        this.planilhasLoaded = false
        this.planilhas = []
        const planilha = this.tipoPlanilha
        // console.log("planilha:", planilha)
        window.spinner.emit("open")
        PlanilasService.getPlanilhas( planilha ).then(
          response => {
            console.log("planilhas:", response.data)
            if ( response.status == 200 ) {
              if ( response.data[0].length > 0 ) {
                response.data[0].map( k => {
                  k.details = {
                    header: [],
                    values: []
                  }
                  k.loading = false
                })
                this.planilhas = response.data[0]
                console.log("planilhas:", this.planilhas)

              } else {
                this.newFormReady = true
              }

              if ( Object.keys(response.data.keys).length > 0 ) {
                const listData = {
                  keys: response.data.keys,
                  values: response.data.values,
                  labels: response.data.labels,
                  required: response.data.required,
                  lists: response.data.menus
                }

                this.createNewFormLists(listData)
              } else {
                this.newFormReady = true
              }
            }
            
            this.planilhasLoaded = true
            this.loadingPlanilhas = false
            window.spinner.emit("close")
            // console.log("planilhas:", this.planilhas)
          },
          error => {
            this.loadingPlanilhas = false
            console.log("error:", error)
            window.spinner.emit("close")
          }
        )
      }
    },
    getPlanilha ( planilha, index ) {
      const idPlanilha = planilha.idPLanilha
      this.loadingPlanilhas = true
      this.planilhas[index].loading = true

      PlanilasService.getPlanilha( idPlanilha ).then(
          response => {
            console.log("getPlanilha - response:", response.data)
            if ( response.status == 200 && response.data[0].values.length > 0) {
              this.planilhas[index].details = {
                                        header: [],
                                        values: []
                                      }
              // console.log("chegou aqui..", this.planilhas)
              response.data[0].values.map( (k,i) => {
                if ( i == 0 ) {
                  this.planilhas[index].details.header = k
                } else {
                  this.planilhas[index].details.values.push(k)
                }
              })
            }
            this.loadingPlanilhas = false
            this.planilhas[index].loading = false
            console.log("planilha:", this.planilhas[index])
          },
          error => {
            this.loadingPlanilhas = false
            this.planilhas[index].loading = false
            console.log("error:", error)
          }
        )
    },
    formatDateTime ( date ) {
      return formatDateTime(date)
    },
    confirmSave ( planilha ) {
      this.$refs.confirm.run({
        message: "Tem certeza que deseja salvar esta planilha? <span class='color-text'>Isto não pode ser desfeito.</span>",
        confirmButton: "Sim",
        denyButton: "Não",
        callback: this.syncPlanilha,
        data: {
          planilha
        }
      })
    },
    createNewFormLists ( data ) {
      console.log("createNewFormLists:", data)
      
      const keys = Object.keys(data.lists)
      this.newFormSchema = keys
      // console.log("keys:", keys)

      keys.map( k => {
        this.newFormOptions[k] = createSelectOptions(data.lists[k], data.values[k], data.keys[k])
        this.newFormLabels[k] = data.labels[k]
        this.newForm[k] = null
        this.newForm.nome = ""
        this.fks[k] = data.values[k]
      })

      if ( data.required ) {
        data.required.map( k => {
          this.required[k] = true
          this.valid[k] = true
        })
      }
      
      console.log("newFormOptions:", this.newFormOptions)
      // console.log("newForm:", this.newForm)
      // console.log("newFormLabels:", this.newFormLabels)

      this.newFormReady = true
    },
    toggleNew ( state ) {
      this.newPlanilha = state

      if ( !state ) this.clearNewForm()
    },
    createFks ( newForm ) {
      const map = Object.keys(newForm)
      const fks = {}
      map.map( k => {
        fks[this.fks[k]] = newForm[k]
        
      })
      // fks['idcliente'] = 2

      return fks
    },
    createPlanilha () {
      var valid = {}

      if ( Object.keys(this.valid).length == 0 ) {
        valid['response'] = true
      } else {
        valid = FormService.validate(this.$refs.newPlanilhaForm, this.valid)
      }

      if ( valid.response ) {
        const planilha = {
          idTipoPlanilha: this.tipoPlanilha,
          fks: this.createFks( this.newForm ),
          nome: this.nomePlanilha
        }
        
        window.spinner.emit("open")
        PlanilasService.createPlanilha( planilha ).then(
            response => {
              console.log("createPlanilha:", response.data)
              if ( response.status == 200 ) {
                this.toggleNew(false)
                this.getPlanilhas()
              }

              window.spinner.emit("close")
            },
            error => {
              console.log("error:", error)
              window.spinner.emit("close")
            }
        )
      }
      
    },
    createName ( value , src, ) {

      if ( !this.creatingNome ) {
        this.creatingNome = true

        const part = src.filter( k => k.value == value )[0]
        const nome = this.nomePlanilha != "" ? " - " + part.label : part.label
        this.nomePlanilha += nome

        setTimeout(() => {
          this.creatingNome = false
        }, 1000)
      }   
      
    },
    clearNewForm () {
      const keys = Object.keys(this.newForm)

      keys.map( k => {
        this.newForm[k] = null
      })
    },
    syncPlanilha ( state, data ) {
      if ( state ) {
        const planilha = data.planilha
        window.spinner.emit("open")
        PlanilasService.syncPlanilha( planilha ).then(
          response => {
            console.log("syncPlanilha - response:", response.data)
            if ( response.status == 200 ) {
              this.getPlanilhas()
            } else {
              window.toaster.emit('open', {style: "error", message: "Ops. Algo Aconteceu. Não foi possível salvar a planillha - Err: " + response.status, floater: true})
            }

            window.spinner.emit("close")
          },
          error => {
            // this.loadingPlanilhas = false
            var msg = error.response.data.msg
            msg = msg ? msg : 'Não foi possível salvar a planillha.'
            // console.log("msg:", msg)
            window.toaster.emit('open', {style: "error", message: "Ops. Algo Aconteceu. " + msg, floater: true})
            console.log("error:", error)
            window.spinner.emit("close")
          }
        )
      }

    },
    clearData () {
      this.newFormSchema = []
      this.newFormOptions = {}
      this.newFormLabels = {}
      this.newForm = {}
    },
    cancelSync ( index ) {
      this.planilhas[index].details = {
        header: [],
        values: []
      }
      this.clearData()
    },
    confirmRemove ( planilha ) {
      console.log("confirmRemove - planilha:", planilha)

      this.$refs.confirm.run({
        message: "Tem certeza que deseja remover esta planilha? <span class='color-text'>Isto não pode ser desfeito.</span>",
        confirmButton: "Sim",
        denyButton: "Não",
        callback: this.removePlanilha,
        data: planilha.idPLanilha
      })
    },
    removePlanilha ( response, idPlanilha ) {
      if ( response ) {
        window.spinner.emit("open")
        PlanilasService.removePlanilha( idPlanilha ).then(
          response => {
            if ( response.status == 200 ) {
              this.getPlanilhas()
              window.toaster.emit('open', {style: "success", message: "Tudo certo! Planilha excluída com sucesso.", floater: true})
            }
            
            window.spinner.emit("close")
          },
          () => {
            window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível excluir a planilha solicitada.", floater: true})
            window.spinner.emit("close")
          }
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .page-inner {
    padding-bottom: $mg_large;
  }
  .mainActionWrap {
    padding-top: $mg_mid;
    // display: flex; flex-direction: column;
    // justify-content: flex-end;
    // padding-bottom: $hmg_mini;

    .btn {
      // display: inline-flex;
      // width: auto;
    }
  }
  .planilhasList {
    display: block;
    width: 100%;
    padding: 0; margin: 0;

    li {
      list-style: none;
      width: 100%; 
      padding: 0; margin: 0;

      .plItemInner {
        display: grid; width: 100%;
        padding: $mg_mini $hmg_small;
        align-items: center; align-content: center;
        grid-template-columns: .3fr 1.5fr 1.5fr 1fr 1fr 2fr 2fr;

        > span {

          &.actionWrap {
            display: flex; align-items: center;
            justify-content: flex-end;

            a {
              margin-left: $hmg_small
            }
          }

          .syncIcon {
            display: flex; flex-direction: column;
            align-content: center; align-items: center; justify-content: center;
            width: 28px; height: 28px;
            background-color: $color-success-2;
            padding: 0; margin: 0;
            line-height: 1;
            color: $color-secondary;
            border-radius: 100%;
            text-align: center;
          }

          .fixed {
            white-space: nowrap;
          }

          a:not(.btn) {
            color: $color-primary;

            &:hover {
              color: $color-dev
            }
          }
        }
      }

      .plItemDetails {
        padding: $hmg;

        .plItemDetailsTableWrap {
          overflow-x: auto;
          @extend %scrollbar;
          padding: 0 0 $hmg_mid 0;

          td {
            p {
              min-width: 220px;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              word-break: break-all;
            }
          }
        }
        
      }

      .plTableFooter {
        padding-top: $hmg;
        display: flex;
        align-content: center; align-items: center;
        justify-content: flex-end;

        >.btn {
          margin-left: $mg_small;
        }
      }
    }
  }

  .newPlanilha {
    margin-top: $hmg;
    margin-left: -$hmg_small;
    width: calc(100% + $mg_mid);
    @extend %transition_4e;
    opacity: 1;

    header {
      display: flex;
      align-items: flex-start;
      // padding-left: $hmg_small;
      margin-bottom: $hmg_small;

      > div:first-child {
        margin-right: $mg;
      }
    }

    .newPlanilhaInner {
      padding: $hmg;
    }
  }

  .action {
    .btn {
      margin-right: $hmg_small;
    }
  }

  .planilhasListWrap {
    margin-top: $mg
  }

  .planilhasEmpty {
    text-align: center;
    padding-top: $mg_large;

    .icon {
      font-size: 80px;
      margin: 0 auto $hmg;
    }
  }

  .over-limit {
    padding: $hmg_small;
    display: flex;
    align-content: center; align-items: center;
    background-color: $color-alert;
    color: $color-secondary;
    text-align: left;
    border-radius: $border-radius-small;

    p {
      padding: 0; margin: 0 0 0 $hmg_small;
      text-align: left;
    }
  }
</style>