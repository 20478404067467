<template>
  <section class="">
    <card v-if="!empty">
      <div class="dataTableHeader">
        <!-- <search @onSearch="searchTable" :minLength="3" :data="content"></search> -->
        <div class="dataTableHeaderActions" v-if="hasNew">
          <a href="#" class="btn btn-primary" @click.prevent="add()">Novo</a>
        </div>
      </div>
      <table :class="['dataTable', {compact: compact}]">
        <thead>
          <tr>
            <td 
              v-for="(header, hi) in headers" 
              :key="header" 
              :style="{ textAlign: typeof align[hi] == 'undefined' ? 'left' : align[hi] }">
              {{ header }}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, ii) in content" :key="item">
            <template v-for="(cell, cr, ci) in item" :key="cell">
              <td 
                v-if="indexKeys.includes(cr)"
                :style="{ textAlign: typeof align[ci] == 'undefined' ? 'left' : align[ci] }"
              >
              {{ cell }}
            </td>
            </template>
            <td v-if="actions.length > 0" class="dataTableActions">
              <a 
                href="#"
                v-for="action in actions"
                :key="action"
                @click.prevent="action.callback(data[ii])"
                :title="action.label"
                class="dataTableAction"
              >
                <fa :icon="action.icon"></fa>
                <span v-if="action.showLabel">{{ action.label }}</span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </card>
  </section>
</template>
<script>
// import search from "./Search"

export default {
  name: "DataTable",
  components: {
    // search
  },
  props: {
    headers: {
      type: Array,
      default: () => {
        return []
      }
    },
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    actions: {
      type: Array,
      default: () => {
        return []
      }
    },
    align: {
      type: Array,
      default: () => {
        return []
      }
    },
    keys: {
      type: Array,
      default: () => {
        return []
      }
    },
    hasNew: {
      type: Boolean,
      default: true
    },
    startLoader: {
      type: Boolean,
      default: true
    },
    compact: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      empty: true,
      content: [],
      indexKeys : []
    }
  },
  watch: {
    data (to) {
      // console.log("from:", from)
      console.log("to:", to)

      this.empty = to.length === 0

      this.processData(to)
  
    }
  },
  mounted () {
    // if ( this.startLoader ) window.spinner.emit("open")
    // this.spin = true
    console.log("datatable data:", this.data)

    if ( this.data ) {
      this.processData(this.data)
    }
    this.empty = false
  },
  methods: {
    processData (data) {
      console.log("processData:", data)
      this.content = []

      data.map( dk => {
        const item = {}
        this.indexKeys = this.keys.length > 0 ? this.keys : Object.keys(data[0])

        this.indexKeys.map( kk => {
          item[kk] = dk[kk]
        })
        this.content.push(item)
      })
    },
    add () {
      this.$emit("onAdd")
    },
    searchTable (query) {
      console.log("query:", query)
    }
  }
}
</script>
<style lang="scss" scoped>

  .dataTableHeader {
    width: 100%; height: 60px;
    border-bottom: 1px solid $color-light;
    display: flex; align-content: center; align-items: center;

    .dataTableHeaderActions {
      padding: 0 15px;

      .btn {
        padding: .7em;
        border-radius: 1em;
      }
    }
  }

  .dataTable {
    width: 100%; height: auto;
    // table-layout: fixed;
    border: 0; padding: 0; margin: 0 auto 25px;

    &.fixed {
      table-layout: fixed;
    }
    thead {
      > tr {
        border-bottom: 2px solid $color-light;
        vertical-align: middle;

        > th, > td {
          padding: 0 25px;
          height: 40px;
          font-size: 12px; font-weight: 400;
        }
      }

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }

    tbody {
      > tr {
        border-bottom: 1px solid $color-light;
        vertical-align: top;

        @media screen and (max-width: 1024px) {
          display: flex; flex-direction: column;
          align-content: flex-start; align-items: flex-start;
        }

        td {
          padding: 10px 25px;
          min-height: 50px;
          font-weight: 400;
          text-transform: uppercase;

          &.dataTableActions {
            padding: 10px 15px 10px 25px;
          }
        }

        &:last-child {
          border-bottom: 0;
        }

        @media screen and (max-width: 1024px) {
          vertical-align: middle;
          
          td {
            min-height: 0; 
            padding: 5px 15px;

            &:first-child {
              padding-top: 15px;
            }
            &:last-child {
              padding-bottom: 15px;
            }
          }
        }
      }
    }
    
    &.compact {
      thead {
        > tr {
          > th, > td {
            padding: 0 10px;
            font-size: 12px; font-weight: 400;
          }
        }
      }
      tbody {
        > tr {
          td {
            padding: 10px;
          }
        }
      }
    }

    .dataTableActions {
      text-align: right;
      .dataTableAction {
        font-size: 18px;
        padding: 15px;
        color: $color-dark;

        &:hover {
          color: $color-primary;
        }
      }
      
      @media screen and (max-width: 1024px) {
        margin-top: 1em;
      }
    }
    
  }
</style>