<template>
  <transition name="slidedown">
    <div class="toaster" :class="{floater: floater}" v-if="on">
      <div class="toasterContent" :class="[style]">
        <div v-html="message"></div>
        <a href="#" @click.prevent="close()" class="btnClose"></a>
      </div>
    </div>
  </transition>
</template>
<script>
// var toaster = require('tiny-emitter/instance')

export default {
  name: "Toaster",
  data () {
    return {
      message: "Teste de Mensagem",
      style: "default",
      floater: true,
      on: false
    }
  },
  mounted () {
    window.toaster.on('open', (options) => {
      // console.log("options: ", options)
      this.on = true
      this.message = options.message
      this.style = options.style
      if (typeof options.floater != 'undefined') {
        this.floater = options.floater
      }
      
      setTimeout(() => {
        this.close()
      },10000)
    })
  },
  methods: {
    close () {
      this.on = false
      this.message = ""
      this.floater = false
      this.style = 'default'
    }
  }
}
</script>
<style lang="scss" scoped>
  .toaster {
    width: 100%; max-width: 1080px; min-height: 50px; height: auto;
    padding: 0;
    // position: absolute;
    @extend %transition_4e;
    overflow: hidden;
    // position: fixed; z-index: 1000;
    
    &.floater {
      position: fixed; top: 70px;
      left: 0; right: 0; margin: auto;
      z-index: 10000;
    }

    .toasterContent{
      display: flex; align-content: center; align-items: center;
      justify-content: space-between;
      width: 100%;
      border-radius: $border-radius;
      padding: $hmg_mid;

      &.default {background-color: $color-primary;}
      &.success {background-color: $color-success;}
      &.warning {background-color: $color-alert;}
      &.error {background-color: $color-alert;}

      div {
        margin: 0; 
        line-height: 1.2; 
        // font-size: 15px;
        color: $color-secondary;
      }
    }
  }

  @media screen and (max-width: 1366px) {
    .toaster {padding: 0 $mg_mid;}
  }
  @media screen and (max-width: 1024px) {
    .toaster {padding: 0 $mg_small;}
  }
</style>