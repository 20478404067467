<template>
  <section>
    <div ref="categoriaForm" class="form-wrap">
      <div class="row no-padd">
        <div class="form-group col-md-6">
          <input-control
            label="Descrição Categoria"
            v-model="categoria.nome"
            required
          ></input-control>
        </div>
      </div>
    </div>
    <footer>
      <div>
        <a href="#" class="btn btn-secondary" @click.prevent="cancel()">Cancelar</a>
      </div>
      <div>
        <a
          href="#"
          :class="['btn btn-primary', { disabled: running }]"
          @click.prevent="submit()"
          >{{ this.scope ? "Editar" : "Criar" }}</a
        >
      </div>
    </footer>
  </section>
</template>

<script>
import FormService from '@/services/form.service.js'
import AtendimentoService from '@/services/atendimento.service.js'

export default {
  components: {},
  props: {
    scope: {
      type: Object,
    },
  },
  watch: {
    scope(value) {
      if (value) {
        this.categoria = value;
      }
    },
  },
  data() {
    return {
      ready: false,
      waiting: true,
      running: false,
      categoria: {
        idAtendimentoCategoria: null,
        nome: null
      },
      valid: {
        dataOcorrencia: true,
        idAtividadeCategoria: true,
        idAtendimentoCanal: true,
        idPessoa: true,
      },
      category: null,
      waitSearch: null,
    };
  },
  mounted() {
    console.log("scope", this.scope)

    if(this.scope) {
      this.categoria = this.scope
    }

    console.log("this.categoria", this.categoria)

  },
  methods: {
    cancel () {
      this.$emit('onCancel')
    },
    submit (  ) {
      const valid = FormService.validate(this.$refs.categoriaForm, this.valid)

      if ( valid.response ) {
        this.running = true
        AtendimentoService.saveCategoriaAtendimento( this.categoria ).then(
          response => {
            if ( response.status == 200 ) {
              window.toaster.emit('open', {style: "success", message: "Tudo certo! Categoria de atendimento criada com sucesso.", floater: true})
            }
          },
          () => {
            this.running = false
            window.toaster.emit('open', {style: "error", message: "Não foi possível salvar este atendimento. Valide os dados e tente novamente. Se o problema persistir, procure nosso time de suporte.", floater: true})
          }
        )
        
      } else {
        this.running = false
            window.toaster.emit('open', {style: "error", message: "Todos os campos requeridos do formulário devem estar preenchidos.", floater: true})
      }
    }
  },
};
</script>

<style lang="scss" scoped>
section {
  border-top: $border-component;
}

.form-wrap {
  padding: $hmg_mid $mg_mini;
}

footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $hmg_mid;
  border-top: $border-component;

  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: $hmg_mid;
  }

  > div {
    display: flex;
    align-content: flex-end;
    gap: $hmg_mid;

    @media screen and (max-width: 1024px) {
      gap: $mg_mini;
      justify-content: space-between;
    }
  }
}

.searchWrap {
  position: relative;

  .remove {
    position: absolute;
    right: $hmg_small;
    top: 50%;
    transform: translateY(-50%);

    > * {
      @extend %transition_3o;
    }

    &:hover {
      color: $color-primary;
    }
  }
}

.listWrap {
  position: relative;
  width: 100%;

  .list {
    position: absolute;
    width: 100%;
    max-height: 200px;
    left: $hmg_mini;
    top: -$hmg_mini;
    overflow: hidden;
    z-index: 100;
    padding: $hmg_small;
    background-color: $color-secondary;
    border-radius: $border-radius;
    @extend %shadow-high;
    border: $border-component;

    > div {
      padding: 0 $mg_mini 0 0;
      margin: 0;
      max-height: calc(200px - $hmg_mid);
      overflow-y: auto;
      @extend %scrollbar_card;

      table {
        border-collapse: collapse;
        width: 100%;

        tr {
          @extend %transition_3o;
          cursor: default;
          vertical-align: middle;

          &:hover {
            background-color: $color-bg;
          }
          td {
            padding: $mg_mini;
            cursor: default;
            line-height: 1;

            &:first-child {
              border-top-left-radius: $border-radius-small;
              border-bottom-left-radius: $border-radius-small;
            }
            &:last-child {
              border-top-right-radius: $border-radius-small;
              border-bottom-right-radius: $border-radius-small;
            }
          }
        }
      }
    }

    ul {
      padding: 0 $mg_mini 0 0;
      margin: 0;
      max-height: 100%;
      overflow-y: auto;
      @extend %scrollbar_card;

      li {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: space-between;
        list-style: none;
        padding: $hmg_mini;
        margin: 0;
        @extend %transition_3o;
        cursor: pointer;

        &:hover {
          background-color: $color-bg;
          border-radius: $border-radius-small;
        }
        span {
          flex: 1;
          padding: 0 $mg_mini;

          &:first-child,
          &:last-child {
            padding: 0;
          }

          &:last-child {
            flex: 0;
            text-align: right;
          }

          &:nth-child(1) {
            width: 30%;
          }
        }
      }
    }
  }
}

.searchSpinner {
  position: absolute;
  top: 16px;
  right: $hmg_mid;
  z-index: 100;
}

.spinnerWrap {
  width: 100%;
  padding-top: $mg;

  .loader {
    width: 100%;
    height: 6px;
    display: block;
    position: relative;
    overflow: hidden;
    background-color: $color-bg;
  }
  .loader::after {
    content: "";
    width: 96px;
    height: 6px;
    background-color: $color-primary;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    animation: hit 0.6s ease-in-out infinite alternate;
  }

  @keyframes hit {
    0% {
      left: 0;
      transform: translateX(-1%);
    }
    100% {
      left: 100%;
      transform: translateX(-99%);
    }
  }
}
</style>
