<template>
  <section class="page-inner">
    <h4>Novo Lead</h4>
    <div class="frame mini fixed">
      <form @keyup.enter="saveLead()" ref="leadForm">
        <div class="row no-padd">
          <div class="col-md-12 form-group">
            <label>Nome</label>
            <input-control 
              v-model="lead.nomeResponsavel" 
              placeholder="Nome do Responsável"
              name="nomeResponsavel"
              :autocomplete="true" 
              :valid="valid.nomeResponsavel" 
              :disabled="loadingFilterData"
              @keyup="valid.nomeResponsavel = true"
              required
            />
          </div>
          <div class="col-md-7 form-group">
            <label>Email</label>
            <input-control 
              type="email"
              v-model="lead.email" 
              placeholder="responsavel@email.com"
              name="email"
              :autocomplete="true"
              :valid="valid.email" 
              :disabled="loadingFilterData"
              @keyup="valid.email = true"
              required
            />
          </div>
          <div class="col-md-5 form-group">
            <label>Celular</label>
            <input-control 
              v-model="lead.numeroCelular" 
              placeholder="+55 (11) 9 8765 4321"
              name="numeroCelular"
              :autocomplete="true"
              :valid="valid.numeroCelular" 
              :disabled="loadingFilterData"
              @keyup="valid.numeroCelular = true"
              masking="celular"
              @keydown="addCountryCode('telefonecelular', $event)"
              required
            />
          </div>
          <div class="col-md-7 form-group">
            <label>Unidade</label>
            <select-control 
              v-model="lead.idUnidade" 
              name="unidade" 
              placeholder="Selecione" 
              :options="options.unidades" 
              :disabled="loadingFilterData"
              @change="getPeriodosLetivos(); valid.unidade = true" 
              @keyup="valid.unidade = true"
              :valid="valid.unidade" 
              required
              />
          </div>
          <div class="col-md-5 form-group">
            <label>Período Letivo</label>
            <select-control
              v-model="lead.idPeriodoLetivo" 
              name="periodoLetivo" 
              placeholder="Selecione"
              :options="options.periodosLetivos"
              :disabled="loadingFilterData"
              :valid="valid.periodoLetivo" 
              @change="valid.periodoLetivo = true" 
              required
            />
          </div>
          <div class="col-md-12 form-group">
            <label>Campanha</label>
            <select-control
              v-model="lead.utm_campaign" 
              name="utm_campaign" 
              placeholder="Selecione"
              :options="options.forms"
              :disabled="loadingFilterData"
              @change="valid.utm_campaign = true"
              :valid="valid.utm_campaign" 
              required
            />
          </div>
          <div class="col-md-12 form-actions form-group">
            <a href="#" class="btn btn-primary" @click.prevent="saveLead()">
              Criar Lead
            </a>
            <a href="#" class="btn btn-secondary" @click.prevent="confirmClear()">
              Limpar
            </a>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import UserService from '@/services/user.service'
import AcademicoService from '@/services/academico.service'
import CaptacaoService from '@/services/captacao.service.js'
import FormService from '@/services/form.service'
import { createSelectOptions, clearMask } from '@/services/utils.service'

export default {
  data () {
    return {
      lead: {
        nomeResponsavel: null,
        email: null,
        numeroCelular: null,
        idUnidade: null,
        idPeriodoLetivo: null,
        idFormLeadEtapa: 1,
        utm_campaign: null
      },
      valid: {
        nomeResponsavel: true,
        email: true,
        numeroCelular: true,
        idUnidade: true,
        idPeriodoLetivo: true,
        utm_campaign: true
      },
      options: {
        unidades: [],
        periodosLetivos: [],
        forms: []
      },
      wait: {
        unidades: false,
        forms: false
      },
      loadingFilterData: true
    }
  },
  mounted () {
    window.spinner.emit("open")
    this.getUnidades()
    this.getRDForms()
  },
  methods: {
    getUnidades () {
      UserService.getUnidades().then(
        response => {
          console.log("getMinhasUnidades:", response.data)
          // this.wait.unidades = true
          this.options.unidades = createSelectOptions(response.data, 'idUnidade' ,'descricao')
          this.loadingFilterData = false
          this.wait.unidades = true
          this.stopSpinner()
        },
        error => {
          console.error(error)
        }
      )
    },
    getPeriodosLetivos () {
      this.loadingFilterData = true
      const unidade = this.lead.idUnidade
      AcademicoService.getPeriodosLetivos( unidade ).then(
        response => {
          console.log("getPeriodosLetivos:", response.data)
          this.options.periodosLetivos = createSelectOptions(response.data, 'idPeriodoLetivo' ,'descricao').reverse()
          this.loadingFilterData = false
        },
        error => {
          console.error(error)
          this.loadingFilterData = false
        }
      )
    },
    getRDForms () {
      this.loadingFilterData = true
      CaptacaoService.getRDForms().then(
        response => {
          console.log("getRDForms:", response.data)
          if ( response.data.message || response.data.error ) {
            this.options.forms.push ({
              label: "Orgânico",
              value: "organico"
            })
          } else {
            this.options.forms = createSelectOptions(response.data, 'conversion_identifier' ,'title')
          }
          
          this.loadingFilterData = false
          this.wait.forms = true
          this.stopSpinner()
        },
        error => {
          console.error(error)
          this.loadingFilterData = false
        }
      )
    },
    stopSpinner () {
      const done = !Object.values(this.wait).includes(false)
      console.log("done:", done)
      if ( done ) {
        window.spinner.emit("close")
        this.loading = false
      }
    },
    addCountryCode ( src, e ) {
      var target = this.lead.numeroCelular
      const iniCode = '+55'
      // console.log("key:", e.key)
      const escape = ['Enter', 'Backspace', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Tab', 'Escape']
      const allowAddCode = !escape.includes(e.key)

      if ( !target && allowAddCode ) {
        const newValue = iniCode + target
        console.log("newValue:", newValue)

        this.lead.numeroCelular = newValue
        // target = iniCode + target
      }
    },
    confirmClear () {
      this.$refs.confirm.run({
        message: "Tem certeza que deseja limpar o formulário?",
        confirmButton: "Sim",
        denyButton: "Não",
        callback: this.clear,
      })
    },
    clear () {
      this.lead = {
        nomeResponsavel: null,
        email: null,
        numeroCelular: null,
        idUnidade: null,
        idPeriodoLetivo: null,
        idFormLeadEtapa: 1
      }

      this.valid = {
        nomeResponsavel: true,
        email: true,
        numeroCelular: true,
        idUnidade: true,
        idPeriodoLetivo: true,
      }

    },
    saveLead () {
      const valid = this.validate().response

      if ( valid ) {
        window.spinner.emit("open")
         this.loadingFilterData = true
        const drop = JSON.parse(JSON.stringify(this.lead))

        drop.numeroCelular = clearMask('phone', drop.numeroCelular)

        CaptacaoService.saveLead( drop ).then(
          response => {
            console.log("response:", response)
            window.spinner.emit("close")
            window.toaster.emit('open', {style: "success", message: "Lead salvo com sucesso.", floater: true})
            
            setTimeout(() => {
              this.clear()
              this.loadingFilterData = false
            },1000)
          },
          error => {
            window.toaster.emit('open', {style: "error", message: "Ops! Algo aconteceu. Não foi possível salvar o lead.", floater: true})
            console.log("error:", error)
            window.spinner.emit("close")
            this.loadingFilterData = false
          }
        )
      } else {
        window.toaster.emit('open', {style: "error", message: "Existem campos incompletos. Revise o formulário e tente novamente.", floater: true})
      }
      
    },
    validate () {
      const valid = FormService.validate(this.$refs.leadForm, this.valid)
      return valid
    }
  }
}
</script>

<style>

</style>