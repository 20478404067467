<template>
  <loja-controller>
    <loja-page-header>Minha Conta</loja-page-header>
    <section class="page-inner frame">
      <div class="minhaconta">
        <div>
          <h1 class="color-text">{{ currentUser.cadastro.Basicos.nome }}</h1>
          <p>{{ currentUser.cadastro.Basicos.email }}</p>
          <div class="menuMinhaConta">
            <nav>
              <router-link to="/minhaconta/minhascompras">
                <span class="iconWrap">
                  <fa :icon="['fas','basket-shopping-simple']" class="icon"></fa>
                </span>
                <span>Minhas Compras</span>
              </router-link>
              <router-link to="/minhaconta/boletos" @click.prevent="" class="">
                <span class="iconWrap">
                  <fa :icon="['fas','rectangle-barcode']" class="icon"></fa>
                </span>
                <span>Boletos</span>
              </router-link>
              <router-link to="/minhaconta/vouchers">
                <span class="iconWrap">
                  <fa :icon="['fas','ticket']" class="icon"></fa>
                </span> 
                <span>Meus Cupons</span>
              </router-link>
              <!-- <router-link to="" class="soon">
                <span class="iconWrap">
                  <fa :icon="['fas','user']" class="icon"></fa>
                </span> 
                <span>Meu Perfil</span>
              </router-link>
              <router-link to="" class="soon">
                <span class="iconWrap">
                  <fa :icon="['fas','lock-keyhole']" class="icon"></fa>
                </span>
                <span>Alterar Senha</span>
              </router-link> -->
              <!-- <router-link to="" class="soon">
                <span class="iconWrap">
                  <fa :icon="['fas','credit-card']" class="icon"></fa>
                </span>
                <span>Formas de Pagamento</span>
              </router-link> -->
              <router-link to="" @click.prevent="logout()">
                <span class="iconWrap">
                  <fa :icon="['fas','person-to-door']" class="icon"></fa>
                </span>
                <span>Sair</span>
              </router-link>
            </nav>
          </div>
        </div>
        <div class="sectionWrap">
          <home v-if="section === '' "></home>
          <minhas-compras v-if="section === 'minhascompras' " :item="item" :detail="detail"></minhas-compras>
          <boletos v-if="section === 'boletos' " :item="item" :detail="detail"></boletos>
          <vouchers v-if="section === 'vouchers' "></vouchers>
        </div>
      </div>
    </section>
  </loja-controller>
</template>

<script>
import Home from '@/views/loja/minhaconta/Home'
import MinhasCompras from '@/views/loja/minhaconta/MinhasCompras'
import Boletos from '@/views/loja/minhaconta/Boletos'
import Vouchers from '@/views/loja/minhaconta/Vouchers'

export default {
  components: {
    Home, MinhasCompras, Boletos, Vouchers
  },
  props: {
    section: {
      type: String,
      default: ""
    },
    item: {
      type: [String, Number],
      default: ""
    },
    detail: {
      type: [String, Number],
      default: ""
    }
  },
  data () {
    return {

    }
  },
  computed: {
    currentUser() {
      return localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null
    }
  },
  mounted () {
    
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
      this.$router.push({name: 'Login', params:{ user: 'responsavel' }})
      // this.$router.push('/login/responsavel/')
    },
  }
}
</script>

<style lang="scss" scoped>
  .minhaconta {
    display: flex;
    align-items: flex-start; align-content: stretch;

    >div {
      flex: 1;
      padding-right: $hmg;

      &:first-child {
        max-width: 300px;
      }
    }

    .menuMinhaConta {
      display: block;
      margin-top: $hmg;

      nav {
        display: flex; flex-direction: column;
        align-content: flex-start; align-items: flex-start;

        > a { 
          display: flex; width: auto;
          padding: $mg_mini 0;
          align-content: center; align-items: center;
          @extend %transition_3o;
          flex: 0;
          white-space: nowrap;

          .iconWrap {
            width: 16px;
            margin-right: $hmg_small;
            text-align: center;
            .icon {
              display: block;
              @extend %transition_4e;
            }
          } 
          
          &.soon {
            pointer-events: none;

            > span {
              opacity: .5;
            }
            &:after {
              content: 'Em breve';
              display: table;
              padding: 4px 6px 2px; 
              border-radius: 2em;
              background-color: $color-success;
              color: $color-secondary;
              font-size: 9px; text-align: center;
              text-transform: uppercase;
              margin-left: $hmg_small;
              line-height: 1;
            }
          }

          &:hover {
            color: $color-primary;
            .iconWrap {
              .icon {
                transform: translateX(5px);
                color: $color-primary
              }
            }
          }

        }
      }
     
    }

    .sectionWrap {
      padding: $mg;
      border-radius: $border-radius-larger;
      background-color: $color-secondary;
      min-height: 400px;
      position: relative;
      // overflow: hidden;

      &::before {
        content: "";
        display: block;
        width: 24px; height: 24px;
        border-bottom-left-radius: 4px;
        transform: rotate(45deg) translateX(-50%);
        position: absolute;
        top: 100px; left: 0;
        background-color: $color-secondary;

        @media screen and ( max-width: 1024px ) {
          top: 0; left: 100px;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 0;
          transform: rotate(45deg) translateY(-50%);
        }
      }
    }

    @media screen and ( max-width: 1024px ) {
      flex-direction: column;

      .sectionWrap {
        width: 100%;
        padding: $hmg;
        margin-top: $hmg;
      }
    }
  }
  @media screen and ( max-width: 1024px ) {
    .page-inner {
      padding-top: 0 !important
    }
  }
</style>