import axios from 'axios'
import authHeader from './auth-header'
// import moment from 'moment'

class FinanceiroService {
  syncParams () {
    return axios.get(process.env.VUE_APP_API_URL + 'perseus/migra/parametros/new', { headers: authHeader() })
  }
  getArrecadacoes () {
    return axios.get(process.env.VUE_APP_API_URL + 'proximociclo/get/tipoarrecadacao', { headers: authHeader() })
  }
  saveArrecadacoes ( data ) {
    return axios.put(process.env.VUE_APP_API_URL + 'proximociclo/edit/tipoarrecadacao', data, { headers: authHeader() })
  }
  getDescontos () {
    return axios.get(process.env.VUE_APP_API_URL + 'proximociclo/get/tipodesconto', { headers: authHeader() })
  }
  saveDescontos ( data ) {
    return axios.put(process.env.VUE_APP_API_URL + 'proximociclo/edit/tipo/desconto', data, { headers: authHeader() })
  }
  // getAlcadas ( data ) {
  //   const idSerie = data.idSerie ? data.idSerie : 0
  //   const idUnidade = data.idUnidade ? data.idUnidade : 0
  //   const idPeriodoLetivo = data.idPeriodoLetivo ? data.idPeriodoLetivo : 0
  //   const idFuncao = data.idFuncao ? data.idFuncao : 0

  //   return axios.get(process.env.VUE_APP_API_URL + `proximociclo/get/alcada/descontos/${idSerie}/${idUnidade}/${idPeriodoLetivo}/${idFuncao}`, { headers: authHeader() })

  // }
  getAlcadas ( data ) {
    const tipoMatricula = data.tipoMatricula ? data.tipoMatricula : 0
    const idUnidade = data.idUnidade ? data.idUnidade : 0
    const idPeriodoLetivo = data.idPeriodoLetivo ? data.idPeriodoLetivo : 0
    const idFuncao = data.idFuncao ? data.idFuncao : 0
    const idTipoArrecadacao = data.idTipoArrecadacao ? data.idTipoArrecadacao : 0

    return axios.get(process.env.VUE_APP_API_URL + `proximociclo/get/alcada/create/edit/descontos/${idUnidade}/${idPeriodoLetivo}/${idFuncao}/${tipoMatricula}/${idTipoArrecadacao}`, { headers: authHeader() })

  }

  saveAlcada ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'proximociclo/create/alcada/descontos', data, { headers: authHeader() })
  }
  removeAlcada ( idlimiteDescontoFuncao ) {
    return axios.delete(process.env.VUE_APP_API_URL + `proximociclo/create/alcada/descontos/${idlimiteDescontoFuncao}`, { headers: authHeader() })
  }

  getPrecificacao ( data ) {
    const idUnidade = data.idUnidade ? data.idUnidade : 0
    const idPeriodoLetivo = data.idPeriodoLetivo ? data.idPeriodoLetivo : 0
    const idSerie = data.idSerie ? data.idSerie : 0
    const idCurso = data.idCurso ? data.idCurso : 0
    const tipoCurso = data.tipoCurso ? data.tipoCurso : 0
    const dataInicio = data.dataInicio ? data.dataInicio : 0
    const dataFinal = data.dataFinal ? data.dataFinal : 0
    const operadorInicio = data.operadorInicio ? data.operadorInicio : 0
    const operadorFinal = data.operadorFinal ? data.operadorFinal : 0
    const ativos = data.ativos ? data.ativos : 0

    return axios.get(process.env.VUE_APP_API_URL + `proximociclo/get/precificacao/${idUnidade}/${idPeriodoLetivo}/${idSerie}/${idCurso}/${tipoCurso}/${dataInicio}/${dataFinal}/${operadorInicio}/${operadorFinal}/${ativos}`, { headers: authHeader() })
  }

  getPlanosByPrecificacao ( idPrecificacao ) {
    return axios.get(process.env.VUE_APP_API_URL + `proximociclo/get/precificacao/planos/${idPrecificacao}`, { headers: authHeader() })
  }

  savePrecificacao ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + "proximociclo/get/precificacao/edit", data, { headers: authHeader() })
  }
  
  savePlanosPagamento ( data ) {
    return axios.post(process.env.VUE_APP_API_URL + "proximociclo/get/plano/pagamento/edit", data, { headers: authHeader() })
  }

  getNegociacoesDebitos ( respFin ) {
    return axios.get(process.env.VUE_APP_API_URL + 'renegociacao/debitos/' + respFin + '/0',{ headers: authHeader() })
  }
}

export default new FinanceiroService()