<template>
  <div class="filter">
    <div class="frame fixed">
      <header>
        <h4>Filtrar</h4>
      </header>
      <form v-if="ready" @keyup.enter="runFilter()">
        <div class="row nop nom">
          <div class="col-md-4 form-group">
            <select-control :options="options.status" v-model="filter.status" label="Status" placeholder="Selecione" />
          </div>
          <div class="col-md-2 form-group">
            <input-control v-model="filter.idAtividade" label="ID Atividade"/>
          </div>
        </div>
        <div class="row nop nom">
          <div class="col-md-5 form-group">
            <select-control :options="options.indicadores" v-model="filter.idIndicador" label="Indicador" placeholder="Selecione" />
          </div>
          <div class="col-md-3 form-group">
            <select-control :options="options.classificacoes" v-model="filter.idClassificacao" label="Classificação" placeholder="Selecione" />
          </div>
          <div class="col-md-4 form-group">
            <select-control :options="options.categorias" v-model="filter.idCategoriaAtividade" label="Categoria" placeholder="Selecione"/>
          </div>
        </div>
        <div class="row nop nom">
          <div class="col-md-2 form-group">
            <select-control :options="options.perfis" v-model="filter.idPerfilPessoa" label="Perfil" placeholder="Selecione" />
          </div>
          <div class="col-md-5 form-group">
            <input-control v-model="filter.usuarioAtendido" label="Atendido" placeholder="Nome da pessoa que foi atendida"/>
          </div>
          <div class="col-md-5 form-group">
            <input-control v-model="filter.operador" label="Operador" placeholder="Nome da pessoa que executou o atendimento"/>
          </div>
        </div>
        <div class="row nop nom">
          <div class="col-md-6 form-group">
            <select-control :options="options.unidades" v-model="filter.idUnidade" label="Unidade" placeholder="Selecione" />
          </div>
          <div class="col-md-6 form-group">
            <select-control :options="options.funcoes" v-model="filter.idFuncao" label="Função" placeholder="Selecione" />
          </div>
        </div>
        <div class="row nop nom">
          <div class="col-md-3 form-group">
            <select-control :options="options.tiposData" v-model="filter.tipoData" label="Tipo Data" placeholder="Selecione" required/>
          </div>
          <div class="col-md-2 form-group">
            <input-control v-model="filter.dataInicio" type="date" label="Data Início" required/>
          </div>
          <div class="col-md-2 form-group">
            <input-control v-model="filter.dataFinal" type="date" label="Data Final" required/>
          </div>
        </div>
        <footer>
          <a href="#" class="btn btn-primary" @click.prevent="runFilter()">
            Filtrar
          </a>
          <a href="#" class="btn btn-secondary" @click.prevent="clearFilter()">
            Limpar
          </a>
        </footer>
      </form>
    </div>
  </div>
</template>

<script>
import AtividadesService from '@/services/atividades.service.js'
import FerramentasService from '@/services/ferramentas.service.js'
import UserService from '@/services/user.service.js'
import AdmService from '@/services/adm.service.js'
import { createSelectOptions } from '@/services/utils.service.js'
import moment from 'moment'

export default {
  components: {
    
  },
  data () {
    return {
      ready: false,
      filter: {
        idCategoriaAtividade: null,
        status: null,
        idPerfilPessoa: null,
        idIndicador: null,
        idClassificacao: null,
        usuarioAtendido: null,
        operador: null,
        idAtividade: null,
        tipoData: null,
        dataInicio: null,
        dataFinal: null,
        idMatricula: null,
        idFuncao: null
      },
      options: {
        categorias: [],
        indicadores: [],
        classificacoes: [],
        status: [
          {
            label: 'Pendente',
            value: 0
          },
          {
            label: 'Em Andamento',
            value: 2
          },
          {
            label: 'Finalizado',
            value: 1
          }
        ],
        perfis: [
          {
            label: 'Aluno',
            value: 1
          },
          {
            label: 'Colaborador',
            value: 2
          },
          {
            label: 'Responsável',
            value: 3
          }
        ],
        tiposData: [
          {
            label: 'Data Ocorrência',
            value: 'dataOcorrencia'
          },
          {
            label: 'Prazo Execução',
            value: 'dataSLA'
          },
          {
            label: 'Prazo Expiração',
            value: 'dataExpiracao'
          },
          {
            label: 'Início Atendimento',
            value: 'dataInicioAtendimento'
          },
          {
            label: 'Fim Atendimento',
            value: 'dataFimAtendimento'
          }
        ],
        unidades: [],
        funcoes: []
      },
      wait: {
        categorias: false,
        indicadores: false,
        classificacoes: false,
        unidades: false,
        funcoes: false
      },
      model: null,
      processing: false
    }
  },
  mounted () {
    this.getCategorias()
    this.getIndicadores()
    this.getClassificacoes()
    this.getUnidades()
    this.getFuncoes()

    this.model = Object.assign({}, this.filtro)

    const weekStart = moment().startOf('week').format('YYYY-MM-DD')
    const weekEnd = moment().endOf('week').format('YYYY-MM-DD')
    
    this.filter.dataInicio = weekStart
    this.filter.dataFinal = weekEnd
    this.filter.tipoData = 'dataOcorrencia'

    console.log("weekStart:", weekStart)
    console.log("weekEnd:", weekEnd)
  },
  methods: {
    getCategorias () {
      AtividadesService.getCategoriasAtividades().then(
        response => {
          console.log("getCategoriasAtividades:", response.data)

          this.options.categorias = createSelectOptions(response.data, 'idAtividadeCategoria', 'descricaoCategoriaAtividade')
          this.wait.categorias = true
          this.finishLoad()
        },
        () => {

        }
      )
    },
    getIndicadores () {
      FerramentasService.getIndicadores().then(
        response => {
          console.log("getIndicadores:", response.data)
          this.options.indicadores = createSelectOptions(response.data, 'idIndicador', 'descricaoIndicador')
          console.log("indicadores:", this.options.indicadores)
          this.wait.indicadores = true
          this.finishLoad()
        },
        () => {

        }
      )
    },
    getClassificacoes () {
      AtividadesService.getClassificacaoAtividade().then(
        response => {
          console.log("getClassificacaoAtividade:", response.data)
          this.options.classificacoes = createSelectOptions(response.data, 'idClassificacaoAtividade', 'descricaoClassificacaoAtividade')
          console.log("classificacoes:", this.options.classificacoes)
          this.wait.classificacoes = true
          this.finishLoad()
        },
        () => {

        }
      )
    },
    getUnidades () {
      UserService.getUnidades().then(
        response => {
          console.log("getMinhasUnidades:", response.data)
          // this.wait.unidades = true
          this.options.unidades = createSelectOptions(response.data, 'idUnidade' ,'descricao')
          this.wait.unidades = true
          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma unidade encontrada. Verifique sua conexão e tente novamente.", floater: true})
        }
      )
    },
    getFuncoes () {
      AdmService.getFuncoes().then(
        response => {
          console.log("getFuncoes:", response.data)
          this.options.funcoes = createSelectOptions(response.data, 'idFuncao' ,'nome')

          this.wait.funcoes = true
          this.finishLoad()
        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma função encontrada. Verifique sua conexão e tente novamente.", floater: true})
        }
      )
    },
    finishLoad () {
      const done = !Object.values(this.wait).some( a => !a)

      if ( done ) {
        this.ready = true
      }
    },
    runFilter ( page ) {
      this.$emit('onProcessing', true)
      AtividadesService.filterAtividades( this.filter, page ).then(
        response => {
          console.log('runFilter: ', response.data)
          if ( response.data.data.length > 0 ) {
            this.$emit('onFilter', response.data)
          } else {
            window.toaster.emit('open', {style: "error", message: "Não encontramos atividades com os dados informados.", floater: true})
          }
          
          this.$emit('onProcessing', false)
        },
        () => {
          this.$emit('onProcessing', false)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo deu errado. Não foi possível executar o filtro.", floater: true})
        }
      )
    },
    clearFilter () {
      this.filter = Object.assign({}, this.model)
    },
    paginate ( page ) {
      this.runFilter(page)
    }
  }
}
</script>

<style lang="scss" scoped>
  .filter {
    border-bottom: $border-component;
    
    form {
      padding: 0 $mg_mini $hmg_mid $mg_mini;
    }

    header {
      padding: $hmg_mid;
    }

    footer {
      padding: $mg_mini;
      display: flex; align-items: center;
      gap: $mg_mini;
    }
  }
</style>