<template>
  <section class="mat-main" v-if="matricula">
    <header>
      <p>Etapa {{ step.index + 1 }} de {{ totalSteps }}</p>
      <h4>Unidade e Curso</h4>
      <p>Confirme se a unidade e série do aluno estão corretas.</p>
    </header>
    <section class="form-wrap" v-if="ready">
      <h5 class="color-text">{{ step.index + 1 }}.1. Unidade e Curso</h5>
      <cursos ref="curso" v-if="loaded" :auto="auto" :idMatricula="idMatricula" :action="action"></cursos>
    </section>
    <section class="form-wrap" v-if="ready && !emptyOpts">
      <h5 class="color-text">{{ step.index + 1 }}.2. Optativas</h5>
      <optativas @empty="emptyOpts = true" v-if="loaded" :action="action"></optativas>
    </section>
  </section>
</template>

<script>
import Cursos from '@/components/matriculas/form/Cursos'
import Optativas from '@/components/matriculas/form/Optativas'
import MatriculaService from '@/services/matricula.service'
import { unformatDate } from '@/services/utils.service'

export default {
  components: {
    Cursos, Optativas
  },
  props: {
    ready: {
      type: Boolean,
      default: false
    },
    idMatricula: {
      type: [String, Number]
    },
    auto: {
      type: Boolean,
      default: false
    },
    action: {
      type: String
    },
    step: {
      type: Object
    },
    totalSteps: {
      type: Number
    }
  },
  data () {
    return {
      matricula: null,
      emptyOpts: true,
      loaded: false
    }
  },
  mounted () {
    console.log("totalSteps:", this.totalSteps)
  },
  methods: {
    validate () {
      return this.$refs.curso.validate()
    },
    get () {
      this.$store.dispatch('matricula/get').then(responseMat => {
        this.matricula = responseMat
        const primeiraMatricula = this.matricula.matricula.primeiraMatricula

        if ( this.idMatricula ) {
          MatriculaService.getAlunoByIdMatricula( this.idMatricula ).then(
            response => {
              console.log("getAuno:", response.data)
              const savedUnidade = this.matricula.matricula.idUnidade
              const newUnidade = response.data.matricula.idUnidade
              const didNotChange = savedUnidade == newUnidade || !savedUnidade

              // console.log("savedUnidade:", savedUnidade)
              // console.log("newUnidade:", newUnidade)
              // console.log("didNotChange:", didNotChange)

              this.matricula.Aluno.Aluno = response.data.aluno.Aluno ? response.data.aluno.Aluno : this.matricula.Aluno.Aluno
              this.matricula.Aluno.Basicos = response.data.aluno.Basicos ? response.data.aluno.Basicos : this.matricula.Aluno.Basicos
              this.matricula.Aluno.Basicos.dataNascimento = this.matricula.Aluno.Basicos.dataNascimento ? unformatDate(this.matricula.Aluno.Basicos.dataNascimento) : this.matricula.Aluno.Basicos.dataNascimento
              this.matricula.Aluno.Documento = response.data.aluno.Documento ? response.data.aluno.Documento : this.matricula.Aluno.Documento
              this.matricula.Aluno.Endereco = response.data.aluno.Endereco ? response.data.aluno.Endereco : this.matricula.Aluno.Endereco
              
              if ( didNotChange ) {
                this.matricula.matricula = response.data.matricula ? response.data.matricula : this.matricula.matricula
              }
              
              if ( !this.matricula.Aluno.Basicos.nomeDocumento ) {
                this.matricula.Aluno.Basicos.nomeDocumento = this.matricula.Aluno.Basicos.nome
              }
              
              if ( !this.matricula.Aluno.Basicos.nomeDocumento ) {
                this.matricula.Aluno.Basicos.nomeDocumento = this.matricula.Aluno.Basicos.nome
              }

              if ( primeiraMatricula || primeiraMatricula == 0 ) {
                this.matricula.matricula.primeiraMatricula = primeiraMatricula
              }

              this.$store.dispatch('matricula/save', this.steps).then(() => {
                // console.log(response)
                this.$emit('ready')
                this.$nextTick(() => {
                  this.loaded = true
                })
              })
              
            },
            error => {
              console.error(error.response.data.message)
            }
          )
        } else {
          this.loaded = true
          this.$emit('ready')
        }
      })      
    },
  }
}
</script>

<style lang="scss" scoped>

</style>