<template>
  <section class="table-wrap frame fixed mid">
    <header>
      <h4>Resultado da Busca</h4>
      <p v-if="propostas">{{ propostas.length }} {{ propostas.length == 1 ? 'proposta' : 'propostas' }} encontradas</p>
    </header>
    <table v-if="propostas" class="transparent-table">
      <thead>
        <tr>
          <th>Curso</th>
          <th>Série</th>
          <th>Turma</th>
          <th>Aluno</th>
          <th class="text-right" v-if="state == 'remove'">
            <label class="check-control">
              <span>Todos</span>
              <input type="checkbox" class="check" v-model="all" @change="toggleAll">
            </label>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="proposta in propostas" :key="proposta">
          <td>
            {{ proposta.descricaoCurso }}
          </td>
          <td>
            {{ proposta.serieNomeExibicao }}
          </td>
          <td>
            {{ proposta.descricaoTurma }}
          </td>
          <td>
            {{ proposta.nome }}
          </td>
          <td class="text-right" v-if="state == 'remove'">
            <label class="check-control">
              <input type="checkbox" class="check invert" v-model="proposta.selected" >
            </label>
          </td>
        </tr>
      </tbody>
    </table>
    <footer class="action-wrap">
      <a href="#" class="btn btn-primary" @click.prevent="confirmAction()">
        {{ state == 'add' ? 'Gerar' : 'Remover' }} Propostas
      </a>
    </footer>
    <confirm 
      :backdrop="false"
      class="confirm"
      ref="confirm"
    ></confirm>
  </section>
</template>

<script>
export default {
  props: {
    scope: {
      type: Array
    },
    state: {
      type: String
    }
  },
  data () {
    return {
      propostas: null,
      all: true
    }
  },
  mounted () {
    console.log('this.scope:', this.scope)
    this.propostas = this.scope

    // if ( this.state == 'remove' ) this.toggleAll()
  },
  methods: {
    toggleAll () {
      this.propostas.map(k => {
        k.selected = this.all
      })
    },
    confirmAction () {
      const action = this.state == 'add' ? 'adicionar' : 'remover'
      this.$refs.confirm.run({
        message: `Tem certeza que deseja ${ action } as propostas de rematricula listadas?`,
        confirmButton: "Sim",
        denyButton: "Não",
        callback: this.action,
      })
    },
    action ( response ) {
      console.log("response:", response)
      if ( response ) {
        // console.log("response:", response)
        const selected = this.state == 'remove' ? this.propostas.filter( a => a.selected) : ''
        console.log("selected:", selected)
        this.$emit('onConfirm', selected)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .table-wrap {
    padding: $hmg $hmg_small $mg_extra;
  }
  
  .action-wrap {
    display: flex; align-content: center; align-items: center;
    justify-content: flex-end;
    padding: $hmg $hmg_small;

    a {
      margin-left: $hmg_small
    }

    label{
      margin-right: $hmg
    }
  }
</style>