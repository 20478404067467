<template>
  <section class="page-full">
    <spinner />
    <toaster />
    <header class="noControllerHeader frame mini">
      <logo class="logo" color="#ED1C24" size="50px"/>
    </header>
    <card class="frame mini mainFormBox" v-if="!loading && !done" :fixed="false">
      <div class="nps">
        <div class="question">
          <h3>{{ pergunta }}</h3>
          <p v-if="!askForEmail">Escolha de 0 a 10, qual o seu nível de satisfação.</p>
        </div>
        <template v-if="askForEmail">
          <div class="iniWrap">
            <p>Antes de começar, preencha seu e-mail cadastrado</p>
            <input-control v-model="email" type="email" placeholder="email@email.com.br" cssClass="text-center" :valid="validEmail" @keyup="validEmail = true" required/>
            <div class="emailErrMsg" v-if="!validEmail && emailErrMsg">
              <span>{{ emailErrMsg }}</span>
            </div>
            <footer>
              <a href="#" class="btn btn-primary" @click.prevent="saveEmail">Continuar</a>
            </footer>
          </div>
        </template>
        <template v-else>
          <ul class="evaluation">
            <li 
              v-for="item in evaluation" 
              :key="item"
              :class="{ active: item.value == selected }"
              @mouseenter="toggleMessage(item.message)" 
              @mouseleave="toggleMessage()"
              @click="select(item)"
            >
              <fa :icon="['fal', item.icon]" />
              <span>{{ item.value }}</span>
            </li>
          </ul>
          <div class="message">
            <transition name="slideup-small">
              <p v-if="activeMessage">{{ activeMessage }}</p>
            </transition>
          </div>
          <p class="badAlert" v-if="selected != null && selected < 7">Que pena que você se sente assim. Gostariamos muito de saber o porquê. Conta para a gente, para que possamos melhorar nossos serviços?</p>
          <textarea-control 
            v-model="explicacao" 
            cssClass="invert" 
            placeholder="Explique sua resposta" 
            @keyup="valid = true"
            :valid="valid"
            :required="selected < 7"
          ></textarea-control>        
          <div class="actionWrap">
            <a href="#" @click.prevent="rowBack()" :class="['btn btn-secondary']" v-if="hasErr">Voltar</a>
            <a href="#" @click.prevent="save()" :class="['btn btn-primary',{ disabled: !valid }]">Responder</a>
          </div>
        </template>
      </div>
    </card>
    <div v-if="!loading && done" class="succMsg">
      <success></success>
      <h1>Tudo certo!</h1>
      <p>Muito obrigado por sua resposta. Continuaremos sempre trabalhando para tornar nossos serviços melhores. <b>Você já pode fechar esta janela.</b></p>
    </div>
  </section>
</template>

<script>
import FormService from "@/services/form.service"
import Logo from '@/components/template/logo.vue'
import Success from '@/components/utilities/Success'

export default {
  components: {
    Logo, Success
  },
  data () {
    return {
      evaluation: [
        {
          value: 0,
          icon: "face-sad-tear",
          message: "Péssimo. Está longe de ser o que deveria."
        },
        {
          value: 1,
          icon: "face-pensive",
          message: "Foi ruim. Ainda tem muito o que melhorar."
        },
        {
          value: 2,
          icon: "face-worried",
          message: "Nada satisfatório. Ainda tem muito o que melhorar."
        },
        {
          value: 3,
          icon: "face-frown",
          message: "Não foi legal. Da pra melhorar."
        },
        {
          value: 4,
          icon: "face-frown-slight",
          message: "Não foi legal. Da pra melhorar."
        },
        {
          value: 5,
          icon: "face-meh",
          message: "Quase lá! Ainda tem alguns pontos que precisam de atenção."
        },
        {
          value: 6,
          icon: "face-meh-blank",
          message: "Nem bom nem ruim. Sempre tem espaço para melhorias."
        },
        {
          value: 7,
          icon: "face-smile",
          message: "Bom. Mas tem coisas que poderiam ser melhores."
        },
        {
          value: 8,
          icon: "face-smile-relaxed",
          message: "Foi muito bom. Mas ainda pode melhorar."
        },
        {
          value: 9,
          icon: "face-laugh",
          message: "Excelente. Além das minhas expectativas."
        },
        {
          value: 10,
          icon: "face-grin-stars",
          message: "Foi maravilhoso. Uma experiência única!"
        },
      ],
      pergunta: "Avalie sua experiência de compra no Shop Decisão",
      explicacao: null,
      activeMessage: null,
      fallBack: null,
      selected: null,
      valid: true,
      query: null,
      loading: false,
      done: false,
      askForEmail: false,
      email: null,
      validEmail: true,
      emailErrMsg: null,
      hasErr: false
    }
  },
  mounted () {
    this.run()
  },
  methods: {
    run () {
      const query = this.$route.query

      if ( query ) {
        console.log("query:", query)
        if ( query.idProspect &&  query.idProspect == 0 ) {
          this.askForEmail = true
        }

        if ( query.asw ) {
          this.selected = parseInt(query.asw)
          this.activeMessage = this.evaluation.filter( k => {
            return k.value == this.selected
          })[0].message

          this.fallBack = this.activeMessage
          console.log("activeMessage:", this.activeMessage)

          delete query.asw
        }
        window.spinner.emit('open')
        this.loading = true

        FormService.getNPS( query.campanha ).then(
          response => {
            console.log("getNPS ok:", response)
            window.spinner.emit('close')
            this.pergunta = response.data.pergunta
            this.loading = false
            // this.valid = this.validate()
          },
          error => {
            console.error("getNPS - error:", error.response)
            window.spinner.emit('close')
            this.loading = false
          }
        )
        
        this.query = query
      }
    },
    toggleMessage ( message ) {
      this.activeMessage = message ? message : null

      if ( !message && this.selected ) {
        this.activeMessage = this.fallBack
      }
    },
    select ( item ) {
      this.selected = item.value == this.selected ? null : item.value
      this.fallBack = this.activeMessage

      // console.log(this.$router.currentRoute)
      this.$router.replace({path: this.$router.currentRoute._value.path, query: { ...this.$router.currentRoute._value.query, asw: this.selected }})

      this.valid = this.validate()
    },
    validate () {
      var asw = true
      if ( this.selected < 7 ) {
        asw = this.explicacao ? true : false
      }

      const valid = this.selected != null && this.selected != false && asw || this.selected == 0 && asw
      console.log("valid:", valid)

      return valid
    },
    save () {
      this.valid = this.validate()

      // console.log("valid:", this.valid)
      if ( this.valid ) {
        var data

        if ( this.email ) {
          data = {
            avaliacao: this.selected,
            explicacao: this.explicacao,
            email: this.email,
            ...this.query
          }
        } else {
          data = {
            avaliacao: this.selected,
            explicacao: this.explicacao,
            ...this.query
          }
        }
        
        // console.log("data:", data)
        this.loading = true
        window.spinner.emit('open')
        FormService.saveNPS( this.query.campanha, data ).then(
          response => {
            console.log("save ok:", response)
            window.spinner.emit('close')
            this.loading = false
            this.done = true
          },
          error => {
            console.error("error:", error.response)
            window.toaster.emit('open', {style: "error", message: error.response.data.message, floater: true})
            window.spinner.emit('close')
            this.loading = false
            this.hasErr = true
          }
        )
      } else {
        this.valid = false
      }
    },
    saveEmail () {
      if ( this.email ) {
        if ( this.email.includes("@") && this.email.includes(".com") ) {
          this.validEmail = true
          this.emailErrMsg = null
          this.askForEmail = false
          this.hasErr = false
        } else {
          this.validEmail = false
          this.emailErrMsg = 'Ops. Isso não parece ser um email.'
        }
        
      } else {
        console.log("email not valid")
        this.emailErrMsg = null
        this.validEmail = false
      }

    },
    rowBack () {
      this.askForEmail = true
    }
  }
}
</script>

<style lang="scss" scoped>

.mainFormBox {
  margin-top: $hmg;
}

.nps {
  width: 100%; padding: $mg;

  @media screen and ( max-width: 1024px ) {
    padding: $hmg;
  }

  .question {
    max-width: 60%; margin: 0 auto $hmg_small;

    h3 {
      color: $color-text;
      text-align: center; 
    }
    p {
      text-align: center;
    }
  }

  .message {
    width: 100%; height: 40px; 
    // padding-top: $hmg_mini;
    text-align: center;

    p {
      margin: auto;
      @extend %transition_4e;
    }
  }

  .evaluation {
    display: flex;
    width: auto; margin: auto;
    align-content: center; align-items: center;
    justify-content: center;
    padding: $hmg; margin-bottom: $hmg_mid;

    > li {
      display: flex; flex-direction: column;
      justify-content: center; align-items: center; align-content: center;
      width: 44px; height: 44px; min-width: 44px; min-height: 44px;
      font-size: 34px;
      list-style: none;
      margin: 0; position: relative; z-index: 99;
      border-radius: 100%;
      @extend %transition_4e;
      cursor: pointer;

      > span {
        display: flex; flex-direction: column;
        align-content: center; align-items: center; justify-content: center;
        width: 20px; height: 20px; line-height: 1;
        position: absolute; bottom: -5px; left: 0; right: 0;
        margin: auto;
        border-radius: 100%; color: $color-secondary;
        transform: translateY(100%) rotateY(90deg) perspective(1000px);
        font-size: 12px; font-weight: bold;
        transition-delay: .4s;
        @extend %transition_4e;
      }

      &:hover, &.active {
        @extend %shadow-mid;
        transform: scale(1.1,1.1);

        > span {
          transform: translateY(100%) rotateY(0); opacity: 1;
          transition-delay: 0;
        }
      }

      &:hover {
        z-index: 101 !important;
      }

      &:nth-child(1), &:nth-child(2) {
        color: $color-awful;
        > span {
          background-color: $color-awful;
        }
        &:active, &.active {
          background-color: $color-awful;
          color: $color-secondary;
        }
      }
      &:nth-child(3), &:nth-child(4), &:nth-child(5) {
        color: $color-bad;
        > span {
          background-color: $color-bad;
        }
        &:active, &.active {
          background-color: $color-bad;
          color: $color-secondary;
        }
      }
      &:nth-child(6), &:nth-child(7) {
        color: $color-ok;
        > span {
          background-color: $color-ok;
        }
        &:active, &.active {
          background-color: $color-ok;
          color: $color-secondary;
        }
      }
      &:nth-child(8), &:nth-child(9) {
        color: $color-good;
        > span {
          background-color: $color-good;
        }
        &:active, &.active {
          background-color: $color-good;
          color: $color-secondary;
        }
      }
      &:nth-child(10), &:nth-child(11) {
        color: $color-awesome;
        > span {
          background-color: $color-awesome;
        }
        &:active, &.active {
          background-color: $color-awesome;
          color: $color-secondary;
        }
      }

    }

    @media screen and ( max-width: 640px ) {
      flex-wrap: wrap;

      li {
        margin: .4em;

        > span {
          transform: translateY(100%) rotateY(0); opacity: 1;
          transition-delay: 0; bottom: 0;
        }
      }
    }
  }
  .badAlert {
    padding: $hmg_small $hmg;
  }

  .actionWrap {
    display: flex;
    justify-content: center;
    gap: $hmg_mid;
    margin: $hmg auto 0;
    text-align: center;
  }
}
  
  .noControllerHeader {
    padding: $hmg;
    text-align: center;

    .logo {
      margin: auto !important;
    }
  }

  .succMsg {
    text-align: center;
    max-width: 320px;
    padding: $mg $hmg;
    margin: auto;

    h1 {
      color: $color-text;
      margin-top: $hmg;
      padding-bottom: $hmg_small;      
    }
  }

  .iniWrap {
    padding-top: $hmg_mid;

    p {
      text-align: center
    }

    footer {
      margin-top: $hmg_mid;
      text-align: center;
    }

    .emailErrMsg {
      display: flex;
      margin: $hmg_mini auto 0;
      text-align: center;
      align-items:center;
      justify-content: center;

      >span {
        line-height: 1;
        padding: $hmg_mini $mg_mini;
        border-radius: 2em;
        background-color: $color-alert;
        text-align: center;
        color: $color-secondary
      }
      
    }
  }
</style>