<template>
  <section>
    <h2>Meus Boletos</h2>
    <div v-if="loading" class="tableLoader">
      <input-spinner></input-spinner>
    </div>
    <table class="content-table" v-else>
      <thead>
        <template v-if="!isMobile">
          <tr>
            <th>ID</th>
            <th>Parcela</th>
            <th>Vencimento</th>
            <th>Status</th>
            <th>Tipo</th>
            <th class="text-right">Valor</th>
            <th></th>
          </tr>
        </template>
        <template v-else>
          <tr v-for="item in boletos" :key="item">
            <th>ID</th>
            <th>Parcela</th>
            <th>Vencimento</th>
            <th>Status</th>
            <th>Tipo</th>
            <th class="text-right">Valor</th>
            <th></th>
          </tr>
        </template>
      </thead>
      <tbody>
        <tr class="" v-for="item in boletos" :key="item" @click.prevent="openBoleto(item.boletourl)">
          <td>{{ item.idcheckoutMarketplace }}</td>
          <td>{{ item.parcela }}</td>
          <td>{{ formatDate(item.vencimentoboleto) }}</td>
          <td><span :class="['marker', getStatus(item)]">{{ getStatus(item, true) }}</span></td>
          <td>{{ item.idRenegociacaoEfetivada ? 'Renegociação' : 'Parcela' }}</td>
          <td class="text-right">{{ formatMoney(item.valortransacao) }}</td>
          <td>
            <fa :icon="['fas', 'rectangle-barcode']" class="icon"></fa>
          </td>
        </tr>
      </tbody>
    </table>
    <a href="/boletos" class="btn btn-primary btVerTodos">Ver todos os meus boletos</a>
  </section>
</template>

<script>
import MarketplaceService from "@/services/marketplace.service"
import { formatDate, formatMoney } from "@/services/utils.service"
import moment from "moment"
import InputSpinner from '@/components/utilities/InputSpinner'

export default {
  components: {
    InputSpinner
  },
  data () {
    return {
      boletos: [],
      slideLoop: null,
      isMobile: false,
      loading: false
    }
  },
  mounted () {
    this.fetchBoletos()
    window.addEventListener('resize', this.checkSize)

    this.checkSize()
  },
  methods: {
    fetchBoletos () {
      console.log("currentUser:", this.currentUser)
      // const idResponsavel = this.currentUser.idPessoa
      this.loading = true

      MarketplaceService.getMeusBoletos().then(
        response => {
          if ( response.data.length > 0 ) {
            this.boletos = response.data
            console.log("boletos:", this.boletos)
          }

          this.loading = false
          console.log("response - getMeusPedidos:", response)
        },
        error => {
          console.error("error:", error)
          this.loading = false
        }
      )
    },
    formatDate ( value ) {
      return formatDate(value)
    },
    formatMoney ( value ) {
      return formatMoney(value)
    },
    openBoleto ( url ) {
      window.open(url)
    },
    getStatus ( item, label ) {
      const now = moment()
      const vencimento = moment(item.vencimentoboleto, 'YYYY-MM-DD')
      const diff = now.diff(vencimento, 'days')

      console.log("diff:", diff)

      if ( item.pago == 1 ) {
        return label ? 'Pagamento Confirmado' : 'pago'
      } else if ( diff == 0 ) {
        return label ? 'Vence Hoje' : 'atual'
      } else if ( diff >= 1 ) {
        return label ? 'Vencido' : 'vencido'
      } else {
        return label ? 'Aguardando Pagamento' : 'pendente'
      } 
    },
    checkSize () {
      if ( this.slideLoop ) clearInterval(this.slideLoop)

      this.slideLoop = setTimeout(() => {
        this.isMobile = window.innerWidth <= 1024
      }, 200)
    },
  }
}
</script>

<style lang="scss" scoped>
  .btVerTodos {
    margin-top: $mg;
  }
</style>