<template>
  <section class="bloqueios">
    <header>
      <h4>Bloqueios</h4>
      <a href="#" class="btn btn-secondary" @click.prevent="goBack()">Voltar</a>
    </header>
    <section class="page-content">
      <empty v-if="bloqueios.length == 0 && !loading"/>
      <ol class="cardList" v-else>
        <li>
          <div class="plItemInner">
            <span>
              Motivo
            </span>
            <span>
              Status
            </span>
            <span>
              Data
            </span>
            <span class="text-center">
              Matricula
            </span>
            <span class="text-center">
              Forma de Pagamento
            </span>
            <span class="text-center">
              Vendas
            </span>
          </div>
        </li>
        <li v-for="bloqueio in bloqueios" :key="bloqueio">
          <card spacing="small">
            <div class="plItemInner">
              <span>
                {{ bloqueio.descricao }}
              </span>
              <span class="form-group">
                <switch-control v-model:checked="bloqueio.cancelado" labelOn="Ativo" labelOff="Cancelado" :invert="true" @change="updateBloqueio(bloqueio)" v-if="editBloqueiosFinanceiros"></switch-control>
                <template v-else>
                  {{ bloqueio.cancelado ? "Ativo" : "Inativo" }}
                </template>
              </span>
              <span>
                {{ formatDate(bloqueio.data) }}
              </span>
              <span class="text-center">
                <fa :icon="['fas','check']" class="icon color-success" v-if="bloqueio.bloquearMatricula" />
                <fa :icon="['fas','xmark']" class="icon color-bg" v-else />
              </span>
              <span class="text-center">
                <fa :icon="['fas','check']" class="icon color-success" v-if="bloqueio.bloquearFormaPagamento" />
                <fa :icon="['fas','xmark']" class="icon color-bg" v-else />
              </span>
              <span class="text-center">
                <fa :icon="['fas','check']" class="icon color-success" v-if="bloqueio.bloquearVenda" />
                <fa :icon="['fas','xmark']" class="icon color-bg" v-else />
              </span>
            </div>
          </card>
        </li>
      </ol>
    </section>
  </section>
</template>
<script>

import MatriculaService from '@/services/matricula.service.js'
import { formatDate } from '@/services/utils.service.js'
import UserService from '@/services/user.service.js'

export default {
  name: "Bloqueios",
  components: {
    
  },
  props: {
    idAluno: {
      type: [String, Number]
    },
  },
  data () {
    return {
      aluno: null,
      responsavel: null,
      filiacao: null,
      unidade: null,
      api: null,
      bloqueiosTotalMessage: "0 bloqueios",
      loading: true,
      bloqueios: [],
      editBloqueiosFinanceiros: false
    }
  },
  mounted () {
    this.listBloqueios()

    this.editBloqueiosFinanceiros = UserService.filterPermissions('editBloqueiosFinanceiros')
  },
  methods: {
    formatDate ( date ) {
      return formatDate(date)
    },
    listBloqueios () {
      window.spinner.emit('open')
      this.loading = true
      MatriculaService.getBloqueios( this.idAluno ).then( 
        response => {  
          console.log("bloqueios:", response.data)

          if ( response.data.length > 0 ){

            response.data.map( k => {
              k.cancelado = k.cancelado == 0
            })
            this.bloqueios = response.data
            this.bloqueiosTotalMessage = this.bloqueios.length + ( this.bloqueios.length == 1 ? ' bloqueio' : ' bloqueios')
          }

          this.loading = false
          window.spinner.emit('close')
        },
        () => {
          window.spinner.emit('close')
        }
      )
    },
    updateBloqueio( bloqueio ) {
      window.spinner.emit('open')
      bloqueio.cancelado = bloqueio.cancelado ? 1 : 0
      console.log("bloqueio:", bloqueio)
      if ( !this.loading ) { 
        this.loading = true
        MatriculaService.updateBloqueios(bloqueio).then(
          response => {
            console.log("response", response)
            this.listBloqueios()
            this.loading = false
            // window.spinner.emit('close')
          },
          () => {
            window.spinner.emit('close')
            this.loading = false
          }
        )
      }
    },
    goBack() {
      this.$emit('goBack')
    }
  }
}
</script>
<style lang="scss" scoped>
  .bloqueios {
    padding: $hmg_mid $hmg_mid $hmg_mid $mg;

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: $hmg_mid;

      h4 {
        padding: 0; margin: 0;
      }
    }
  }
  .plItemInner {
    grid-template-columns: 2fr 1fr 1fr 1.5fr 1.5fr 1.5fr;

    > span {
      .icon {
        font-size: 24px
      }
      &.form-group {
        margin: 0;
      }
    }
  }
</style>