<template>
  <nav class="fastMenu" v-if="fastMenu.length > 0">
      <template v-for="item in fastMenu" :key="item">
        <router-link
          v-if="item.router_link != 0 && item.externo === 1 && item.angular === 0" 
          :to="{ 
            name: 'LinkExterno', 
            params: { 
              id: item.id,
              path: item.router_link,          
              isMetabase: 1,
              isUrl: 0, 
              token: tokenLaravel, 
              email: currentUser.email
          },
          query: {
            numero_dashboard: item.numeroDashboard, 
          }
          }">
          <span>{{ item.name }}</span>
        </router-link>
        <router-link
          v-if="item.router_link != 0 && item.externo === 1 && item.angular === 1"
          :to="{ 
            name: 'LinkExterno', 
            params: { 
              id: item.id, 
              path: item.router_link, 
              isMetabase: 0, 
              isUrl: 0, 
              token: tokenLaravel, 
              email: currentUser.email
            },
            query: {
              numero_dashboard: 0, 
            }
            }">
          <span>{{ item.name }}</span>
        </router-link>
        <a
          :href="item.router_link"
          v-if="item.router_link != 0 && item.externo === 0 && item.angular === 0 && item.tipo_url === 'blank'"
          target="_blank"
        >
          <span>{{ item.name }}</span>
        </a>
        <router-link
          v-if="item.router_link != 0 && item.externo === 0 && item.angular === 0 && item.tipo_url === 'self'"
          :to="{ 
            name: 'LinkExterno', 
            params: { 
              id: item.id, 
              path: item.router_link, 
              isMetabase: 0, 
              isUrl: 1, 
              token: tokenLaravel, 
              email: currentUser.email
            },
            }">
          <span>{{ item.name }}</span>
        </router-link>
        <a
          :href="item.router_link"
          v-if="item.router_link != 0 && item.externo === 0 && item.angular === 0 && item.tipo_url !== 'self' && item.tipo_url !== 'blank'"
          target="_self"
        >
          <span>{{ item.name }}</span>
        </a>
      </template>
    </nav>
</template>
<script>
import UserService from '../../services/user.service';

export default {
  data () {
    return {
      fastMenu: [],
      tokenLaravel: process.env.VUE_APP_LARAVEL_API_LOGIN_TOKEN,
    }
  },
  computed: {
    currentUser() {
      return localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null
    }
  },
  mounted () {
    this.getMenuPortal()
  },
  methods: {
    getMenuPortal() {
      UserService.getMenuPortal().then(
        response => {
          // this.menu = response.data
          this.getMenu(response.data)
          console.log('getMenuPortal:', response.data)
        },
        error => {
          console.log('error', error)
        }
      )
    },
    getMenu(items) {
      const menu = []
      const track = []

      console.log("items:", items)

      items.map( i => {
        track.push(i)
        i.permissions.map(p => {
          if ( p.name.toLowerCase() == i.name.toLowerCase() ) {
            p.angular = 0
            p.externo = 0
            p.router_link = i.loadChildren
          }
          if(p.home === 1) menu.push(p)
        })
      })

      
      console.log("menu:", menu)
      if ( menu.length === 1 && menu[0].id !== 72 /*id 72 = base de conhecimento*/ ) {
        
        // console.log("menu has only one item...")
        let route

        if (menu[0].router_link != 0 && menu[0].externo === 1 && menu[0].angular === 0){
          route = { 
            name: 'LinkExterno', 
            params: {
              id: menu[0].id,
              path: menu[0].router_link, 
              isMetabase: 1, 
              isUrl: 0, 
              token: this.tokenLaravel, 
              email: this.currentUser.email
            },
            query: { numero_dashboard: menu[0].numeroDashboard, }
          }
        } else if (menu[0].router_link != 0 && menu[0].externo === 1 && menu[0].angular === 1) {
          route = { 
            name: 'LinkExterno', 
            params: { 
              id: menu[0].id, 
              path: menu[0].router_link, 
              isMetabase: 0, 
              isUrl: 0, 
              token: this.tokenLaravel, 
              email: this.currentUser.email 
            },
            query: { numero_dashboard: 0, }
          }
        } else {

          route = menu[0].router_link
        }

        console.log("route:", route)
        // this.$router.push(route)
      } else {

        menu.sort(function(a, b) {
          a = a.name.toUpperCase()
          b = b.name.toUpperCase()
          return ( a < b ) ? - 1 : ( a > b ) ? 1 : 0
        })

        this.fastMenu = menu

        this.$emit("onActive",true)
      }
      // console.log("fastMenu:", this.fastMenu)
    }
  }
}
</script>
<style lang="scss" scoped>
  .fastMenu {
    position: absolute; top: 50%; left: 0; right: 0;
    margin: auto; width: auto; height: auto;
    transform: translateY(-50%);
    display: flex; align-content: center; align-items: center;
    justify-content: center; flex-wrap: wrap;
    max-width: 60%;

    > a {
      display: flex; align-content: center; align-items: center;
      width: auto; height: 50px; max-width: 300px; min-width: 100px; 
      border-radius: 10px; padding: 0 25px; line-height: 50px;
      background-color: $color-primary;
      margin: 5px; color: #FFF;
      @extend %transition_4e;

      span {
        line-height: 1.1;
        white-space: normal;
      }

      &:hover{
        @extend %shadow-low-primary;
        transform: translate3d(-5px, -5px, 10px);
      }
    }
  }
</style>