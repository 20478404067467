<template>
  <controller :goToTopButton="true">
    <div class="page-title">
      <h3 class="text-capitalize">Parametrização Shop</h3>
      <transition name="slideRightToLeft">
        <a href="#" @click.prevent="togglePlanilhas(true)" class="btn btn-primary" v-if="!planilhas">
          <span>Enviar itens em lote</span>&nbsp;
          <fa :icon="['fas', 'file-spreadsheet']"></fa>
        </a>
      </transition>
      <transition name="slideRightToLeft">
        <a href="#" @click.prevent="togglePlanilhas(false)" class="btn btn-primary" v-if="planilhas">
          <span>Enviar itens individualmente</span>&nbsp;
          <fa :icon="['fas', 'gift']"></fa>
        </a>
      </transition>
    </div>
    <div class="page-inner" v-if="section">
      <planilhas-controller v-if="planilhas" tipo="marketplace"></planilhas-controller>
      <parametrizacao-controller v-else :section="section" :action="action" :mid="mid" :target="target"></parametrizacao-controller>
    </div>
  </controller>
</template>

<script>
import ParametrizacaoController from '@/components/marketplace/parametrizacao/template/ParametrizacaoController'
import PlanilhasController from '@/components/planilhas/template/PlanilhasController'

export default {
  components: {
    ParametrizacaoController,
    PlanilhasController
  },
  props: {
    section: {
      type: String
    },
    action: {
      type: String
    },
    mid: {
      type: String
    },
    target: {
      type: String
    }
  },
  data () {
    return {
      planilhas: false,
    }
  },
  beforeMount () {
    if ( !this.section ) {
      this.$router.push({name: 'Parametrização Shop', params: { section: 'parametros' }})
    }
  },
  mounted () {
    // console.log("")
    if ( this.section === 'planilhas' ) {
      this.planilhas = true
    }
  },
  methods: {
    togglePlanilhas ( state ) {
      this.planilhas = state
      console.log('togglePlanilhas:', state)

      this.$nextTick(() => {
        if ( state ) {
          this.$router.push({name: 'Parametrização Shop', params: { section: 'planilhas' }})
        } else {
          this.$router.push({name: 'Parametrização Shop', params: { section: 'parametros' }})
        }
      })
      
    }
  }
}
</script>

<style lang="scss" scoped>
  
</style>