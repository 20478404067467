<template>
  <external-controller title="Matrículas" :back="{ label: 'Sair', action: sairMatricula }">
    <toaster />
    <spinner />
    <matricula-rapida v-if="action == 'pre'" :idMatricula="idMatricula"></matricula-rapida>
    <matricula-controller v-else :action="action" :step="step" :auto="auto" :idMatricula="idMatricula"></matricula-controller>
  </external-controller>
</template>

<script>
import MatriculaController from '@/components/matriculas/template/MatriculaController'
import MatriculaRapida from '@/components/matriculas/form/MatriculaRapida.vue'

export default {
  name: 'Matrícula',
  components: {
    MatriculaController, MatriculaRapida
  },
  props: {
    action: {
      type: String
    },
    step: {
      type: String
    },
    idMatricula: {
      type: [String, Number]
    }
  },
  data () {
    return {
      auto: true
    }
  },
  mounted () {
    // this.addOcta()
  },
  methods: {
    sairMatricula ( e ) {
      console.log(e)
    },
    addOcta () {
      // console.log("add octa now...")
      this.octaScript = document.createElement('script')
      this.octaScript.setAttribute('src', '/octa.js')
      // console.log("externalScript:", externalScript)
      
      document.head.appendChild(this.octaScript)

      const octa = document.getElementById('octadesk-octachat-appchat')
      if ( octa ) octa.removeAttribute('class', 'hidden')
    },
  }
}
</script>

<style lang="scss" scoped>
  
</style>