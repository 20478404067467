<template>
  <section ref="wrap">
    <header>
      <input v-model="query" placeholder="O que você procura?" @keyup="search"/>
      <div>
        <fa :icon="['fal', 'magnifying-glass']" class="icon" v-if="!query || query.length < 3"/>
        <fa :icon="['fal', 'broom-wide']" class="icon" @click="closeSearch(true)" v-else/>
      </div>
    </header>
    <div class="resultWrap" :class="{ active: active }" :style="{ width: boxW + 'px'}">
      <span class="loader" v-if="working"></span>
      <span v-else-if="results.length == 0" class="emptyMessage">
        <p>Nenhum resultado encontrado</p>
      </span>
      <ol v-else>
        <li v-for="result in results" :key="result">
          <a href="#" @click.prevent="goToRoute(result.indexable)" v-if="parseType(result.indexable_type) == 'Roles' || parseType(result.indexable_type) == 'Permissions' ">
            <fa :icon="['far','square-poll-vertical']" class="icon painel" title="Painel" v-if="result.numeroDashboard"></fa>
            <fa :icon="['far','arrow-up-right-from-square']" class="icon externo" title="Link Externo" v-else-if="result.tipo_url == 'blank' || ( result.externo == 1 && !result.numeroDashboard )"></fa>
            <fa :icon="['far','link-simple']" class="icon apollo" title="Apollo" v-else></fa>
            <div>
              <h6>{{ result.indexable.name }}</h6>
            </div>
          </a>
          <a :href="createCentralRoute('aluno', result)" v-else-if="parseType(result.indexable_type) == 'Pessoa' && result.aluno">
            <span class="painel">
              <fa :icon="['far','user-graduate']" class="icon" title="Aluno" ></fa>
              <p>Aluno</p>
            </span>
            <div>
              <h6 class="name" v-if="result.indexable.nome">{{ result.indexable.nome.toLowerCase() }}</h6>
              <p>{{ result.aluno.sigla }}</p>
            </div>
          </a>
          <div v-else>
            <span v-if="parseType(result.indexable_type) == 'Colaborador'" class="apollo">
              <fa :icon="['far','user-tie-hair']" class="icon" title="Colaborador" ></fa>
              <p>Colaborador</p>
            </span>
            <span v-if="parseType(result.indexable_type) == 'Pessoa'" class="externo">
              <fa :icon="['far','user']" class="icon" title="Pessoa" ></fa>
              <p v-if="result.alunos && result.alunos.length > 0">Responsável</p>
              <p v-else>Pessoa</p>
            </span>
            <div>
              <template v-if="parseType(result.indexable_type) == 'Colaborador'" >
                <h6 class="name">{{ result.pessoa ? result.pessoa.nome.toLowerCase() : '...' }}</h6>
                <p>{{ result.funcao ? result.funcao.nome : query }}</p>
                <a v-if="result.pessoa" :href="'mailto:' + result.pessoa.email" class="emailLink">
                  <fa :icon="['fal','paper-plane']" class="icon"></fa>
                  {{ result.pessoa ? result.pessoa.email : '...' }}
                </a>
              </template>
              <template v-else>
                <a :href="createCentralRoute('responsavel', result)" class="pessoaLink">
                  <h6 class="name" v-if="result.indexable">{{ result.indexable.nome.toLowerCase() }}</h6>
                  <h6 class="name" v-else>{{ trackNameFromContent(result.indexed_title) }}</h6>
                </a>
              </template>
            </div>
            <div v-if="result.alunos && result.alunos.length > 0" class="subList">
              <ul>
                <li v-for="aluno in result.alunos" :key="aluno">
                  <a :href="createCentralRoute('responsavel-aluno', aluno)" class="name">
                    <span>{{ aluno.nome.toLowerCase() }}</span>
                    <fa :icon="['fal', 'arrow-right-long']"></fa>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <!-- <label>{{ parseType(result.indexable_type) }}</label> -->
        </li>
      </ol>
    </div>
    <div class="backdrop" @click="closeSearch(true)" v-if="active"></div>
  </section>
</template>

<script>
import SearchService from  '@/services/search.service.js'
import UserService from '@/services/user.service.js'

export default {
  data () {
    return {
      active: false,
      boxW: window.innerWidth < 1024 ? window.innerWidth - 60 : 354,
      query: null,
      results: [],
      searchWait: null,
      working: false,
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
      types: [
        {
          label: "Responsavel",
          index: "App\\Models\\Responsavel",
        },
        {
          label: "Pessoa",
          index: "App\\Models\\Pessoa",
        },
        {
          label: "Roles",
          index: "App\\Models\\Roles"
        },
        {
          label: "Permissions",
          index: "App\\Models\\Permissions"
        },
        {
          label: "Colaborador",
          index: "App\\Models\\Colaborador"
        },
      ]
    }
  },
  mounted () {

  },
  methods: {
    toggleSearch () {
      // const componentW = this.$refs.wrap
      // console.log("componentW:", componentW)

      this.active = true

    },
    search ( e ) {
      console.log("search:", e)
      if ( e.key == 'Escape' || e.keyCode == 27 || e.which == 27 ) {
        this.closeSearch(true)
      } else {
        if ( this.query && this.query.length >= 3 ) {
          this.toggleSearch()
          if ( this.searchWait ) clearTimeout(this.searchWait)
          this.searchWait = setTimeout(() => {
            this.working = true
            SearchService.ApolloSearch( this.query ).then(
              response => {
                console.log("search:", response.data)
                // this.parseResult(response.data)
                this.results = response.data
                this.working = false
              },
              () => {
                this.working = false
                // console.error('')
              }
            )

          },200)
        } else {
          this.closeSearch()
        }
      }
    },
    clearData () {
      
    },
    closeSearch ( clear ) {
      this.active = false
      this.results = []

      if ( clear ) this.query = ""
    },
    parseType ( index ) {
      // console.log("parseType:", index)
      // console.log("types:", this.types)
      // console.log("parseResult:", data)
      const filtered = this.types.filter( a => a.index == index )
      // console.log("filtered:", filtered)

      return filtered[0].label
    },
    goToRoute ( item ) {
      if ( item.router_link && item.tipo_url == 'blank' || item.externo == 1 && !item.numeroDashboard ) {
        window.open(item.router_link)
      } else if ( item.externo == 1 && item.numeroDashboard ) {
        // const query = this.getQueryStringParams(item.router_link)
        this.$router.push({name: 'Painel', params: { id: item.numeroDashboard }, query: {path: item.router_link} })
        this.saveRotaFavorita(item)
      } else {
        if ( item.loadChildren ) {
          this.$router.push(item.loadChildren)
        } else {
          this.$router.push(item.router_link)
        }

        this.saveRotaFavorita(item)
      }
    },
    createCentralRoute ( target, pessoa ) {
      if ( target == 'aluno' ) {
        const route = `/centraldoaluno/${pessoa.aluno.idAluno}/${pessoa.aluno.idPessoa}`
        return route
      }

      if ( target == 'responsavel' ) {
        // console.log("responsavel:", pessoa)
        const responsavel = pessoa.indexable ? encodeURIComponent(pessoa.indexable.nome) : this.trackNameFromContent(pessoa.indexed_title)
        var tipo = 0
        
        if ( pessoa.alunos && pessoa.alunos.length > 0 ) {
          if ( pessoa.alunos[0].financeiro == 1 ) {
            tipo = 2
          } else if ( pessoa.alunos[0].pedagogico == 1  ) {
            tipo = 3
          }
        }
        
        const route = `/matriculas/pesquisa/?nome=${responsavel}&tipoNome=${tipo}`
        return route
      }

      if ( target == 'responsavel-aluno' ) {
        // console.log("responsavel-aluno:", pessoa)
        // const aluno = encodeURIComponent(pessoa.nome)
        // const route = `/centraldoaluno/${aluno}/0/0`
        const route = `/centraldoaluno/${pessoa.idAluno}/${pessoa.idPessoa}`
        return route
      }
    },
    trackNameFromContent ( content ) {
      const parsed = content.split(" ")
      const cleared = parsed.splice(0,1)
      const drop = cleared.join(" ")

      return drop
    },
    saveRotaFavorita ( route ) {
      const idPessoa = this.currentUser.idPessoa
      const data = {
        idPessoa: idPessoa,
        idRole: null,
        idPermission: route.id
      }

      UserService.saveRotaFavorita(data).then(
        response => {
          console.log("saveRotaFavorita:", response.data)
        }
      )
    },
  }
}
</script>

<style lang="scss" scoped>
  section {
    width: 100%; height: 59px;

    p {
      margin: 0; padding: 0;
    }
  }
  .backdrop {
    position: fixed; top: 0; left: 0;
    width: 100vw; height: 100vh;
    z-index: 100;
  }

  header {
    width: 100%; height: 100%;
    position: relative;
    z-index: 200;
    padding: 0; margin: 0;

    >div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: $mg_mini;
      position: absolute;
      top: 50%; transform: translateY(-50%); right: $hmg_mid;
      font-size: 18px;
      color: $color-primary;
    }

    input {
      width: 100%; height: 100%;
      padding: 0 $hmg_mid;
      border: 0; outline: 0;
      box-shadow: none;
      vertical-align: middle;
      color: $color-text;
      background-color: transparent;

      &::placeholder {
        color: $color-text;
        opacity: .5;
      }
    }
  }

  .resultWrap{
    position: fixed;
    top: 60px; height: 0;
    right: 0; overflow-y: auto;
    @extend %transition_6e;
    @extend %scrollbar_light;
    background-color: $color-secondary;
    border-left: $border-component;
    z-index: 450;

    &.active {
      height: calc(100vh - 60px);
      border-top: $border-component;

      @media screen and ( max-width: 640px ) {
        height: calc(100vh - 120px);
      }
    }

    ol {
      padding: 0; margin: 0;

      >li {
        list-style: none;
        margin: 0;
        border-bottom: $border-component;

        
        > a,
        > div {
          display: block;
          padding: $hmg_mid;

          &:hover {
            @extend %transition_4o;
            background-color: $color-bg;
          }
          > div {
            margin-top: $mg_mini;
          }
          > p {
            margin: 0; padding: 0;
          }

          >span {
            display: flex; align-content: center;
            p {
              margin: 0 0 0 $hmg_mini; padding: 0;
              font-size: $font-size-small;
            }

            &.painel {
              color: $color-painel
            }
            &.externo {
              color: $color-externo
            }
            &.apollo {
              color: $color-apollo
            }
            .icon {
              font-size: 14px
            }
          }
        }
        
      }
    }
  }

  .loader {
    width: 100%;
    height: 6px;
    display: block;
    position: relative;
    overflow: hidden;
    background-color: $color-bg;
  }
  .loader::after {
    content: '';  
    width: 96px; height: 6px;
    background-color: $color-primary;
    position: absolute; top: 0; left: 0;
    box-sizing: border-box;
    animation: hit 0.6s ease-in-out infinite alternate;
  }

  @keyframes hit {
    0% {
      left: 0;
      transform: translateX(-1%);
    }
    100% {
      left: 100%;
      transform: translateX(-99%);
    }
  }

  .emptyMessage { 
    padding: $hmg_small
  }

  .name {
    text-transform: capitalize
  }
  .emailLink { 
    display: flex; align-content: center;
    margin-top: $mg_mini;
    .icon {
      margin-right: $hmg_mini
    }

    &:hover {
      color: $color-primary
    }
  }

  .subList {
    padding: 0; margin: 0;
    width: 100%;

    ul {
      padding: 0; margin: $mg_mini 0 0 0;

      li {  
        padding: 0; margin: 0;
        border-bottom: $border-component;
        list-style: none;

        a { 
          display: flex; align-items: center;
          justify-content: space-between;
          width: 100%;
          padding: $hmg_mini 0;
          @extend %transition_4e;

          &:hover {
            padding: $hmg_mini;
          }
        }
      }
    }
  }
</style>