<template>
  <section>
    <div>
      <h1>Ops! Estamos em manutenção.</h1>
      <p>Não esquenta, voltamos já já!</p>
    </div>
  </section>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  section {
    width: 100vw; height: 100vh;
    background-image: url('@/assets/img/fundo-manutencao.jpg');
    background-repeat: no-repeat; background-position: center right;
    background-size: cover;

    @media screen and ( max-width: 689px ) {
      background-image: url('@/assets/img/fundo-manutencao-m.jpg');
      background-position: bottom center;
    }

    > div {
      position: absolute; top: 100px; left: 100px;
      max-width: 540px; padding: $mg;

      h1 { 
        font-size: 6vmin;
        color: $color-secondary;
      }
      p {
        font-size: 3vmin;
        margin-top: $hmg_mid;
        color: $color-dev
      }

      @media screen and ( max-width: 689px ) {
        position: relative; top: initial; left: initial;

        h1 { 
          font-size: 8vmin;
        }
        p {
          font-size: 5vmin;
        }
      }
    }
  }
</style>