<template>
  <section>
    <a href="#" @click.prevent="toggleList()">
      <fa :icon="['fal', 'bell']" class="icone"/>
      <span v-if="count(notificacoes) > 0">{{ count(notificacoes) }}</span>
    </a>
    <notification-list 
      :config="config" 
      :scope="notificacoes" 
      :state="listOn" 
      @refreshList="get()" 
      @close="toggleList(false)"
      @get="get($event)"
    ></notification-list>
    <toasters :config="config" ref="toasters"></toasters>
  </section>
</template>

<script>
import Toasters from '@/components/utilities/notifications/Toasters.vue'
import NotificationList from '@/components/utilities/notifications/Notifications.vue'
import NotificacaoService from '@/services/notifications.service.js'

export default {
  components: {
    Toasters, NotificationList
  },
  data () {
    return {
      user: JSON.parse(atob(localStorage.getItem('profile'))),
      notificacoes: [],
      config: [
        {
          type: "apollo",
          title: "Notificações",
          icon: ['fas','bullhorn']
        },
        {
          type: "sincronismo",
          title: "Sincronismo",
          icon: ['fas','arrows-rotate']
        },
        {
          type: "atividades",
          title: "Atividades",
          icon: ['fas','clipboard-check']
        },
        {
          type: "relatorios",
          title: "Relatório de Atividades",
          icon: ['fas','file-chart-pie']
        },
        {
          type: "jarvis",
          title: "Alerta do Jarvis",
          icon: ['fas','bolt']
        }
      ],
      listOn: false
    }
  },
  mounted () {
    this.get()
    const self = this
    const user = 'App.User.' + this.user.colaborador.idUser
    
    if ( window.Echo ) {
      window.Echo.private(user).listen('NotificacaoApollo', function(e) {
        self.pushMessage(e)
        self.get()
      })

      window.Echo.join('OnlineUsers').here((users) => {
        console.log('Usuários: ' + JSON.stringify(users))
      }).joining((user) => {
        console.log('Entrou: ' + JSON.stringify(user))
      }).leaving((user) => {
        console.log('Saiu: ' + JSON.stringify(user))
      }).listenForWhisper('UsuariosOnlineApollo', (e) => {
        console.log('e: ' + JSON.stringify(e))
      })

      window.Echo.connector.socket.on( 'subscription_error', (channel, error) => {
      // console.log('error : ' + channel, error)

        if(error === 401) {
          // self.logOut()
          // console.log('Enviar para o login, expirou o token')
        }
      })
    }
    
  },
  methods: {
    save() {
      // NotificacaoService.saveNotificacoes(this.notificacoes)
    },
    get( lidas ) {
      const user = this.user.colaborador.idUser
      console.log("user:", user)
      const all = lidas ? 0 : 1
      this.notificacoes = []
      
      NotificacaoService.getNotificacoes(user, all).then(
        response => {
          console.log("getNotificacoes:", response.data)
          this.notificacoes = response.data
        },
        () => {

        }
      )
      // console.log("notificacoes:", notificacoes)
    },
    count ( notificacoes ) {
      const total = notificacoes.filter( a => a.read == 0 ).length
      return total
    },
    pushMessage ( data ) {
      console.log("pushMessage:", data)
      this.$refs.toasters.addNotificacoes(data)
    },
    toggleList ( state ) {
      if ( state ) this.listOn = state
      else this.listOn = !this.listOn
    },
    logOut() {
      this.$store.dispatch('auth/logout')
      this.$router.go()
    },
  }
}
</script>

<style lang="scss" scoped>
  section {
    position: relative
  }
  a {
    display: flex; flex-direction: column;
    align-items: center; justify-content: center;
    position: relative; text-align: center;
    width: 60px; height: 60px;
    font-size: 18px;

    > span {
      font-size: 10px;
      color: $color-primary;
      position: absolute;
      top: 25%; right: 25%;
      font-weight: bold;
    }
  }
</style>