<template>
  <section>
    <atendimento v-if="novo || editar" :id="id" @onCancel="closeNew()" @onSave="closeNew(true, $event)" :isMobile="isMobile" />
    <atividade v-if="executar" :id="id" :type="type" :action="action" @onCancel="closeExecute()" @onSave="closeExecute(true)" :isMobile="isMobile" />
    <transition name="slidedown-small">
      <header :class="['sessionHeader', type ? type.slug : '' ]">
        <h5>{{ type ? type.label : 'Todas' }}</h5>
        <a href="#" @click.prevent="addNew(true)">
          <p>Adicionar Novo</p>
          <span></span> 
        </a>
      </header>
    </transition>
    <transition name="slidedown-small">
      <acompanhamento :type="type" :filter="filter" :isMobile="isMobile" ref="acompanhamento" />
    </transition>
    <template v-if="loading">
      <form-spinner/>
      <div class="wait">
        <span>Aguarde. Estamos carregando suas atividades.</span>
      </div>
    </template>
    
    <kanban-tabs
      v-if="mode == 'list' && atividades"
      :config="config"
      :isMobile="isMobile"
      :data="atividades"
      @onToggle="active = $event"
    ></kanban-tabs>
    <div :class="['list-wrap', mode]" v-if="atividades">
      <div :class="['lists', { visible: ativ == active }]" v-for="(ativ,i) in Object.keys(atividades)" :key="ativ">
        <header :class="Object.keys(atividades)[i]" v-if="mode == 'kanban'">
          <h5>{{ config[ativ] }}</h5>
          <span>
            {{ atividades[ativ].length }}
          </span>
        </header>
        <ul>
          <li v-for="(item, ii) in atividades[ativ]" :key="item" :ref="`atividade_${ Object.keys(atividades)[i] }_${ ii }`">
            <card boxed :listed="false" :active="item.active">
              <div :class="['item-card', item.tiposlug]">
                <form-spinner v-if="item.processing"/>
                <header :class="{ hasSpinner: item.processing }">
                  <div>
                    <h6 :class="item.tiposlug">{{ item.tipoLabel }}</h6>
                    <!-- <span>{{ item.idAtendimento }}</span> -->
                    <span class="borderedTag">{{ item.idAtividade }}</span> 
                  </div>
                  <div>
                    <span class="timeframe">
                      {{ item.unidade }}
                    </span>
                    <span class="timeframe" v-if="Object.keys(atividades)[i] == 'finalizado' && item.dataFimAtividade">
                      {{ formatDate(item.dataFimAtividade) }}
                    </span>
                    <span :class="['timeframe', status(item)]" v-else>
                      <fa :icon="['fas','calendar-star']" v-if="status(item) == ''" class="icon"/>
                      <fa :icon="['fas','calendar-exclamation']" v-else class="icon"/>
                      {{ formatDate(item.dataOcorrencia) }}
                    </span>
                    <div class="permissionTags">
                      <span class="permissionTag view" v-if="item.view" title="Ver">
                        <fa :icon="['fas','eye']" class="icon"/>
                      </span>
                      <!-- <span class="permissionTag edit" v-if="item.edit" title="Editar">
                        <fa :icon="['fas','pen-to-square']" class="icon"/>
                      </span> -->
                      <span class="permissionTag execute" v-if="item.execute" title="Executar">
                        <fa :icon="['fas','pencil']" class="icon"/>
                      </span>
                    </div>
                  </div>
                </header>
                <div>
                  <div class="atribuicao">
                    <span>
                      <fa :icon="['fas', 'user-tie-hair']" />
                      <p>{{ item.idUserAtribuido ? item.nome : 'Não atribuído' }}</p>
                    </span>
                    <fa :icon="['fal','arrow-right-long']" />
                    <span>
                      <fa :icon="['fas', 'user-hair']" />
                      <p>{{ getAtendido(item) }}</p>
                    </span>
                  </div>
                  <div v-if="item.alunoNome" class="atribuicao">
                    <span>
                      <fa :icon="['fas', 'user-graduate']" />
                      <p>{{ item.alunoNome }}</p>
                    </span>
                  </div>
                  <h5>
                    {{ item.nomeCategoria }}
                  </h5>
                  <p>
                    {{ item.observacoes }}
                  </p>
                  <a v-if="item.linkExterno" :href="item.linkExterno" target="_blank">
                    {{ item.linkExterno }}
                  </a>
                </div>
                <footer :class="{ processing: item.processing }">
                  <template v-if="item.checklist && Object.keys(atividades)[i] == 'pendente' && item.execute">
                    <input-control placeholder="Observação (Opcional)" v-model="item.registroAtendimento" cssClass="mid" />
                    <a href="#" :class="['btn', 'btn-' + item.tiposlug]" @click.prevent="executeChecklist(item, ii, Object.keys(atividades)[i])" >
                      Feito
                    </a>
                  </template>
                  <router-link 
                    v-else-if="!item.checklist"
                    :class="['btn', 'btn-' + item.tiposlug]"
                    :to="{
                      name: 'Painel Atividades',
                      params: {
                        section: item.tiposlug, 
                        action: Object.keys(atividades)[i] == 'pendente' || Object.keys(atividades)[i] == 'finalizado' ? 'ver' : 'executar',
                        id: item.idAtividade
                      }
                    }">
                    {{ Object.keys(atividades)[i] == 'pendente' || Object.keys(atividades)[i] == 'finalizado' ? 'Ver' : 'Continuar' }} {{ item.tipoSLabel }}
                  </router-link>
                  <div v-if="item.idUser == currentUser.colaborador.idUser && Object.keys(atividades)[i] == 'pendente'">
                    <a href="#" 
                      @click.prevent="edit(item.idAtividade,true)" 
                      class="btnEdit" 
                    >
                      <fa :icon="['fal','pen-to-square']"></fa>
                    </a>
                  </div>
                </footer>
              </div>
            </card>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import { formatDate } from '@/services/utils.service.js'
import KanbanTabs from '@/components/atividades/navigation/KanbanTabs.vue'
import Acompanhamento from '@/components/atividades/template/Acompanhamento.vue'
import Atividade from '@/components/atividades/template/Atividade.vue'
import AtividadesService from '@/services/atividades.service.js'
import Atendimento from '@/components/atividades/template/Atendimento.vue'
import FormSpinner from '@/components/utilities/FormSpinner.vue'
import { gsap } from "gsap/dist/gsap"

export default {
  components: {
    KanbanTabs, Acompanhamento, Atendimento, Atividade, FormSpinner
  },
  props: {
    mode: {
      type: String,
      default: 'kanban'
    },
    type: {
      type: Object
    },
    section: {
      type: [String, Number]
    },
    action: {
      type: [String, Number]
    },
    id: {
      type: [String, Number]
    },
    isMobile: {
      type: Boolean
    }
  },
  watch: {
    mode ( value ) {
      if ( value == 'list' &&  this.atividades ) {
        this.active = Object.keys(this.atividades)[0]
      }
    },
    type ( value ) {
      // console.log("watch type:", value)
      if ( value ) {
        this.getAtividades(value.slug) 
      }
    },
    section ( value ) {
      // console.log("watch section ---- :", value)
      if ( !value ) {
        this.getAtividades(0)
      } 

    },
    action ( value ) {
      if ( value == 'executar' || value == 'ver' ) {
        this.execute(this.id, null, this.action)
      }
    },
    isMobile ( value ) {
      if ( value ) {
        this.active = 'pendente'
      }
    },
  },
  data () {
    return {
      active: null,
      config: {
        pendente: "Pendentes",
        andamento: "Em Andamento",
        finalizado: "Finalizados"
      },
      atividades: null,
      novo: false,
      editar: false,
      executar: false,
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
      resizeTimeout: null,
      loading: false,
      loadingAcompanhamento: false,
      filter: {
        view: true,
        edit: true,
        execute: true
      }
    }
  },
  mounted () {
    // console.log("type:", this.type)
    const slug = this.type ? this.type.slug : 0
    this.getAtividades(slug)

    if ( this.section ) {
      console.log("section:", this.section)
      // this.getAcompanhamentoAtividades(this.section)
    }
    
    if ( this.action == 'novo' ) {
      this.addNew() 
    }
    if ( this.action == 'editar' ) {
      this.edit(this.id)
    }
    if ( this.action == 'executar' || this.action == 'ver' ) {
      this.execute(this.id, null, this.action)
    }
    this.loadingAcompanhamento = true
    // this.$emit("lockScroll", false)
  },
  methods: {
    rerun ( filter ) {
      this.filter = filter
      const slug = this.type ? this.type.slug : 0

      this.$nextTick(() => {
        this.getAtividades(slug)
        // this.$refs.acompanhamento.getAcompanhamento(this.type, this.filter)
      })
      
    },
    getAtividades ( type ) {
      this.loading = true
      this.atividades = null
      
      AtividadesService.getAtividades(type, this.filter).then(
        response => {
          console.log("getAtividades:", response)
          response.data.pendente.map( k => {
            if ( k.checklist ) k['registroAtendimento'] = ''
          })
          this.atividades = response.data
          this.loading = false

          if ( this.$refs.acompanhamento ) {
            setTimeout(() => {
              this.$refs.acompanhamento.getAcompanhamento(this.type, this.filter)
            }, 500) 
          }
        },
        () => {
          this.loading = false
        }
      )
    },
    formatDate ( timestamp ) {
      return formatDate(timestamp)
    },
    // getAcompanhamentoAtividades ( type ) {
    //   console.log("getAcompanhamentoAtividades:", type)
      
    //   AtividadesService.getAcompanhamentoAtividades( type ).then(
    //     response => {
    //       console.log("type:", response)
    //       this.loadingAcompanhamento = false
    //     },
    //     error => {
    //       console.error("error:", error)
    //       this.loadingAcompanhamento = false
    //     }
    //   )
    // },
    addNew ( reroute ) {
      window.scroll({
        top: 0,
        behavior: 'instant'
      })

      this.$emit("lockScroll", true)
      this.novo = true
      
      if ( reroute ) {
        this.$router.push({ name: 'Painel Atividades', params: { section: this.section, action: 'novo' }})
      }
      
    },
    closeNew ( refresh, data ) {
      this.$emit("lockScroll", false)
      this.novo = false
      this.editar = false

      if ( data ) {
        this.execute(data, null, 'ver')
        this.$router.push({ name: 'Painel Atividades', params: { section: this.section, action: 'ver', id: data }})
      } else {
        this.$router.push({ name: 'Painel Atividades', params: { section: this.section }})

        if ( refresh ) {
          this.getAtividades(this.type.slug)
          // this.$refs.acompanhamento.getAcompanhamento(this.type, this.filter)
          // this.getAcompanhamentoAtividades('atendimentos')
        }
      }
    },
    closeExecute ( refresh ) {
      this.$emit("lockScroll", false)
      this.executar = false

      this.$router.push({ name: 'Painel Atividades', params: { section: this.section }})

      if ( refresh ) {
        this.getAtividades('atendimentos')
        // this.$refs.acompanhamento.getAcompanhamento(this.type, this.filter)
        // this.getAcompanhamentoAtividades('atendimentos')
      }
    },
    edit ( item, reroute ) {
      window.scroll({
        top: 0,
        behavior: 'instant'
      })

      this.$emit("lockScroll", true)
      this.editar = true

      if ( reroute ) {
        this.$router.push({ name: 'Painel Atividades', params: { section: this.section, action: 'editar', id: item }})
      }
      
    },
    execute ( item, reroute, action ) {
      // console.log("execute item:", item)
      window.scroll({
        top: 0,
        behavior: 'instant'
      })

      this.$emit("lockScroll", true)

      if ( reroute ) {
        this.$router.push({ name: 'Painel Atividades', params: { section: this.section, action: action, id: item }})
      }

      this.$nextTick(() => {
        this.executar = true
      })
    },
    status ( atividade ) {
      if ( atividade.statusSLA == 'SLA Não Vencido' ) return ''
      if ( atividade.statusSLA == 'SLA Vencido' ) return 'alert2'
    },
    getAtendido ( item ) {
      if ( item.idPerfilPessoa == 1  ) { //aluno
        return item.alunoNome
      }
      if ( item.idPerfilPessoa == 2 ) { //colaborador 
        return item.nomeColaborador
      }
      if ( item.idPerfilPessoa == 3  ) { //responsavel
        return item.responsavelNome
      }
      // console.log("getAtendido:", item)
    },
    executeChecklist ( data, i, origin) {
      const atividade = {
        idAtividade: data.idAtividade,
        registroAtendimento: data.registroAtendimento,
        idFormulario: null,
        formulario: []
      }
      this.atividades[origin][i]['processing'] = true
      this.atividades[origin][i]['active'] = true
      // console.log("atvidade:", atividade)

      AtividadesService.saveAtividade(atividade.idAtividade, atividade).then(
        response => {
          if ( response.status == 200 ) {
            // this.atividades[origin][i]['processing'] = false
            setTimeout(() => {
              // this.atividades[i]['done'] = true
              const item = this.$refs['atividade_' + origin + '_' + i]
              const tl = gsap.timeline()

              tl.to(item, {
                x: '100%',
                scale: 0.4,
                opacity: 0,
                duration: 0.3,
                easy: 'back.in(2)',
                onComplete: () => {
                  this.atividades[origin].splice(i,1)
                }
              })
            },1000)
          }
        },
        () => {
          
        }
      )
      console.log("save:", atividade)
    },
  }
}
</script>

<style lang="scss" scoped>
  .sessionHeader {
    display: flex; width: 100%; height: 40px;
    align-items: center; align-content: center;
    justify-content: space-between;
    position: sticky; top: 50px;
    z-index: 400;
    padding: 0 $hmg_mid;
    @extend %transition_3o;
    background-color: $color-primary;

    h5 {
      color: $color-secondary;
      margin: 0; padding: 0;
    }

    a {
      padding: $hmg_mini $mg_mini;
      position: relative;

      p {
        padding: 1px 0 0 0; margin: 0;
        position: relative;
        z-index: 2;
      }
      span {
        border-radius: 2em;
        display: block;
        width: 100%; height: 100%;
        position: absolute;
        z-index: 1;
        background-color: $color-secondary;
        @extend %transition_4e;
        top: 0; left: 0;
        transform-origin: center;
      }

      &:hover {
        > span {
          transform: scale(1.1)
        }
      }
    }
    &.atendimentos {
      background-color: $color-atendimentos;

      a {
        color: $color-atendimentos
      }
    }
    &.tarefas {
      background-color: $color-tarefas;

      a {
        color: $color-tarefas
      }
    }
    &.tickets {
      background-color: $color-tickets;

      a {
        color: $color-tickets
      }
    }
    &.solicitacoes {
      background-color: $color-solicitacoes;

      a {
        color: $color-solicitacoes
      }
    }
    &.prospects {
      background-color: $color-prospects;

      a {
        color: $color-prospects
      }
    }
  }

  .list-wrap {
    display: flex; width: 100%;
    height: auto;
    padding: $mg_mini $hmg_mid;

    &.kanban {
      flex-direction: row;
      align-content: stretch; align-items: flex-start;

      > div {
        flex: 1;
        padding: $mg_mini;

        &:first-child {
          padding-left: 0
        }
        &:last-child {
          padding-right: 0
        }
      }
    }
    &.list {
      flex-direction: column;

      .lists {
        display: none;

        &.visible {
          display: initial;
        }
      }
    }

    .lists {
      > header {
        display: flex; align-items: center;
        justify-content: space-between;
        padding: 0 $hmg_mid; margin-bottom: $mg_mini;

        h5 {
          margin: 0; padding: 0;
        }
        
        > span {
          display: flex; flex-direction: column;
          align-items: center; justify-content: center;
          width: 27px; height: 27px;
          font-size: $font-size-small;
          border-radius: 100%;
          color: $color-secondary
        }

        &.pendente {
          > span {
            background-color: $color-pendente;
          }
        }
        &.andamento {
          > span {
            background-color: $color-andamento;
          }
        }
        &.finalizado {
          > span {
            background-color: $color-finalizado;
          }
        }
      }

      > ul {
        padding: 0; margin: 0;
        
        li {
          list-style: none;
          margin: 0 0 $mg_mini 0;
          padding: 0;

          .item-card {
            .spinnerWrap { 
              margin-top: 0 !important
            }
            > header {
              display: flex; align-items: center;
              justify-content: space-between;

              &.hasSpinner {
                margin-top: $mg_mini
              }

              >div {
                display: flex; align-items: center;
                gap: $mg_mini;

                h6 {
                  margin: 0; padding: 0;

                  &.atendimentos {
                    color: $color-atendimentos;
                  }
                  &.tarefas {
                    color: $color-tarefas;
                  }
                }

                span {
                  padding: $hmg_mini $mg_mini;
                  border-radius: 2em;
                  line-height: 1;
                  font-size: $font-size-small;

                  &.borderedTag {
                    border: $border-component;
                  }
                }

                &:last-child {
                  justify-content: flex-end;
                }
              }
              
              .timeframe {
                padding: $hmg_mini $mg_mini;
                border-radius: 2em;
                line-height: 1;
                background-color: $color-bg;

                &.alert1 {
                  background-color: $color-warning;
                  color: $color-secondary
                }
                &.alert2 {
                  background-color: $color-error;
                  color: $color-secondary
                }

                .icon {
                  margin-right: 3px;
                  font-size: $font-size;
                }
              }
            }

            > div {
              margin-top: $hmg_mid;

              .atribuicao {
                display: flex; align-items: center; align-content: center;
                margin-bottom: $hmg_mini;
                gap: $mg_mini;
                
                >span {
                  display: flex; align-items: center; align-content: center;
                  gap: $hmg_mini;
                }
                p {
                  font-size: $font-size-small;
                  margin: 0; padding: 0;
                }
              }
            }

            > footer {
              display: flex; align-items: center;
              justify-content: space-between;
              gap: $mg_mini;
              margin-top: $mg_mini;
            }
          }
        }
      }
    }
  }

  .btnEdit {
    font-size: 16px;

    &:hover {
    color: $color-primary
    }
  }

  .wait {
    padding: $mg;
    width: 100%;
    text-align: center;
    margin: auto;

    span {
      padding: $hmg_mini $mg_mini;
      border-radius: 2em;
      background-color: $color-secondary;
      display: inline-flex;
      margin: $mg_large auto 0
    }
  }

  .permissionTags {
    display: flex; align-items:center;
    justify-content: flex-end; gap: $hmg_mini;

    .permissionTag {
      display: flex; flex-direction: column;
      justify-content: center; align-items: center;
      width: 24px; height: 24px;
      border-radius: 100%;
      background-color: $color-primary;
      font-size: 12px;
      color: $color-secondary;
      text-align: center;

      &.view { background-color: $color-view }
      &.edit { background-color: $color-edit }
      &.execute { background-color: $color-execute }
    }  
  }
</style>