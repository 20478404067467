import axios from 'axios'
import authHeader from './auth-header'
// import moment from 'moment'

class AtendimentoService {
  getCategoriasAtendimento () {
    return axios.get(process.env.VUE_APP_API_URL + 'atendimento/categorias/na', { headers: authHeader() })
  }
  getCategoriasAtendimentoPorTipoPessoa ( perfilPessoa ) {
    return axios.get(process.env.VUE_APP_API_URL + `atendimento/categorias/na/perfilpessoa/${perfilPessoa}`, { headers: authHeader() })
  }

  getCanaisAtendimento () {
    return axios.get(process.env.VUE_APP_API_URL + 'atendimento/canais', { headers: authHeader() })
  }
  getPessoaByNome (pessoa, nome) {
    return axios.get(process.env.VUE_APP_API_URL + `atendimento/busca/${pessoa}ByNome/${nome}`, { headers: authHeader() })
  }

  getAtendimento ( id ) {
    return axios.get(process.env.VUE_APP_API_URL + 'atendimento/na/' + id, { headers: authHeader() })
  }
  
  saveAtendimento ( data ) {
    if ( data.idAtendimento ) {
      return axios.put(process.env.VUE_APP_API_URL + 'atendimento/na/' + data.idAtendimento, data, { headers: authHeader() })
    } else {
      return axios.post(process.env.VUE_APP_API_URL + 'atendimento/na', data, { headers: authHeader() })
    }
  }

  getAtividadeAtendimento ( id ) {
    return axios.get(process.env.VUE_APP_API_URL + 'atendimento/registro/na/' + id, { headers: authHeader() })
  }

  saveCategoriaAtividade (data) {
    return axios.post(process.env.VUE_APP_API_URL + 'atendimento/categoria/atividade', data, { headers: authHeader() })
  }

  getCategoriasAtendimentos () {
    return axios.get(process.env.VUE_APP_API_URL + `atendimento/categorias/atendimentos/na`, { headers: authHeader() })
  }
  
  saveCategoriaAtendimento (data) {
    return axios.post(process.env.VUE_APP_API_URL + 'atendimento/categorias/atendimentos/create/na', data, { headers: authHeader() })
  }
}

export default new AtendimentoService()