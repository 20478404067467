<template>
  <section>
    <header>
      <h4>Envie as imagens dos produtos listados abaixo</h4>
      <p>Alguns itens que você quer cancelar necessitam do envio de imagens.</p>
    </header>
    <div>
      <ul class="item-list" v-if="produtos">
        <li v-for="(item, i) in produtos.itens" :key="item" :class="{ error: item.error }">
          <label class="file-button">
            <div>
              <span v-if="item.src" class="thumb">
                <img :src="item.src">
              </span>
              <span>
                <h5>{{ item.label }}</h5>
                <p v-if="item.name">{{ item.name }}</p>
              </span>
            </div>
            <fa :icon="['fas','cloud-arrow-up']" class="icon"></fa>
            <input type="file" @change="setUploadImage($event, i, 'itens')" accept=".jpeg,.jpg,.png,.pdf"/>
          </label>
        </li>
      </ul>
    </div>
    <footer>
      <a href="#" class="btn btn-primary" @click.prevent="createFiles">Continuar</a>
    </footer>
  </section>
</template>

<script>
import {
  toBase64 
  // formatDate
} from '@/services/utils.service'

export default {
  props: {
    scope: {
      type: Object
    }
  },
  data () {
    return {
      produtos: null,
      total: 0,
      resolved: 0
    }
  },
  mounted () {
    console.log("this.produtos", this.produtos)
    this.produtos = Object.assign({}, this.scope)
  },
  methods: {
    setUploadImage ( e, i, target ) {
      const file = e.target.files[0]
      const name = file.name
      const ext = file.type
      const size = file.size
      const src = URL.createObjectURL(file)

      this.produtos[target][i].file = file
      this.produtos[target][i].name = name
      this.produtos[target][i].ext = ext
      this.produtos[target][i].size = size
      this.produtos[target][i].src = src
      this.produtos[target][i].error = null
      // this.documentos[type][i].documentoEntregue.src = URL.createObjectURL(file)
      console.log("file:", file)
      console.log("name:", name)
      console.log("ext:", ext)
      console.log("size:", size)
      console.log("src:", src)
      // console.log("documentoEntregue:", this.documentos[type][i].documentoEntregue)

      // this.allowSubmit()
      // this.convertTo64Bits(e.target.files[0])

    },
    validate () {
      const itensInvalids = this.produtos.itens.filter( a => !a.src )
      const vendasInvalids = this.produtos.vendas.filter( a => !a.src )

      // console.log("itensInvalids:", itensInvalids)
      // console.log("vendasInvalids:", vendasInvalids)

      const itensValid = itensInvalids.length == 0
      if ( !itensValid ) {
        this.produtos.itens.map( k => {
          if ( !k.src ) k.error = true
        })
      }
      const vendasValid = vendasInvalids.length == 0
      if ( !vendasValid ) {
        this.produtos.vendas.map( k => {
          if ( !k.src ) k.error = true
        })
      }
      
      const valid = itensValid && vendasValid

      return valid
    },
    createFiles () {
      const valid = this.validate()
      console.log("valid:", valid)

      this.total = this.produtos.itens.length + this.produtos.vendas.length
      console.log("total:", this.total)

      if ( valid ) {
        this.produtos.itens.map( k => {
          const file = k.file

          toBase64(file).then(
            response => {
              // console.log("toBase64", response)
              k.imagem = response
              this.resolved++

              this.finish()
            }
          )
        })

        this.produtos.vendas.map( k => {
          const file = k.file

          toBase64(file).then(
            response => {
              // console.log("toBase64", response)
              k.imagem = response
              this.resolved++

              this.finish()
            }
          )
        })

      } else {
        window.toaster.emit('open', {style: "warning", message: "Para  prosseguir é necessário enviar imagens de todos os produtos listados.", floater: true})
      }
    },
    finish () {
      if ( this.resolved == this.total ) {
        this.$emit("onSave", this.produtos)
        console.log("done!!!")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  section {
    @extend %transition_3o;
  }
  header {
    margin-bottom: $hmg
  }
  
  .item-list {
    padding: 0; margin: 0 0 $mg 0;

    li {
      list-style: none;
      padding: 0;
      border-bottom: 1px solid $color-bg;

      label {
        display: flex;
        padding: $hmg_mid $hmg $hmg_mid 0;
        justify-content: space-between; align-items: center;
        @extend %transition_3o;
        margin: 0;
        
        &:hover{
          background-color: $color-bg;
          padding: $hmg_mid $hmg $hmg_mid $hmg_mid;
        }
        
        input {
          appearance: none; display: none;
        }

        >div {
          display: flex;
          align-content: center;

          span {
            display: inline-block;
            margin-right: $mg_mini;

            &.thumb {
              width: 40px; height: 40px;
              overflow: hidden;
              position: relative;
              border-radius: $border-radius;
              background-color: $color-bg;
              border: 2px solid $color-secondary;
            }
          }
          
          img {
            position: absolute;
            left: 0; right: 0; top: 50%;
            max-width: 40px; height: auto;
            margin: auto;
            transform: translateY(-50%);
          }

          h5 {
            color: $color-text;
            margin: 0;
          }
          p {
            margin: 0;
          }
        }
      }
      
      &.error {
        border-bottom: 1px solid $color-bg;

        label {
          background-color: $color-neutro;
        }
      }
    }
  
  }

  footer {
    margin-top: $hmg;
    display: flex; justify-content: flex-end;
  }

</style>