<template>
  <section>
    <div class="frame fixed">        
      <ol class="columnList" v-if="finished">
        <li v-for="(turma,i) in turmasOrigem" :key="turma">
          <div class="">
            {{ turma.descricao }}
          </div>
          <fa :icon="['far', 'arrow-right-long']" class="icon" />
          <div class="searchWrap">
            <input-spinner v-if="turma.loading" class="searchSpinner"></input-spinner>
            <input-control v-model="turma.query" placeholder="Buscar Turma.." @keyup="search($event,i)" :disabled="turma.loading" :valid="turma.valid"></input-control>
            <input type="hidden" v-model="turma.idTurmaNova">
            <a href="#" class="remove" @click.prevent="remove(i)" v-if="turma.idTurmaNova">
              <fa :icon="['fas','trash-can']"></fa>
            </a>
            <div class="listWrap" v-if="turma.list && turma.list.length > 0">
              <div class="list">
                <ul>
                  <li v-for="item in turma.list" :key="item" @click="selectItem(item,i)">
                    {{ item.descricao }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </li>
      </ol>
      <footer>
        <a href="#" @click.prevent="save" class="btn btn-primary">
          Salvar
        </a>
      </footer>
    </div>
  </section>
</template>

<script>
import AcademicoService from  '@/services/academico.service.js'
import InputSpinner from '@/components/utilities/InputSpinner.vue'
import { createSelectOptions } from '@/services/utils.service.js'

export default {
  components:{
    InputSpinner
  },
  props: {
    origem: {
      type: Array
    },
    destino: {
      type: Array
    },
    periodo: {
      type: [ Number, String ]
    },
    unidade: {
      type: [ Number, String ]
    }
  },
  watch: {
    origem ( value ) {
      if ( value ) {
        this.turmasOrigem = Object.assign([], value)
      }
    }
  },
  data () {
    return {
      turmasOrigem: [],
      turmasDestino: [],
      finished: false,
      wait: null
    }
  },
  mounted () {
    this.turmasDestino = createSelectOptions(this.destino, 'idTurma', 'descricao')
    this.turmasOrigem = Object.assign([], this.origem)

    this.setTurmas()
  },
  methods: {
    setTurmas () {
      this.turmasOrigem.map( k => {
        k.loading = false
        k.list = null
        k.query = k.descricaoproximaTurma ? k.descricaoproximaTurma : null
        k.valid = true
      })
      
      this.$nextTick(() => {
        this.finished = true
      })
      
    },
    search (e,i) {
      const query = this.turmasOrigem[i].query
      
      if ( query && query.length >= 2 ) {
        if ( this.wait ) clearInterval(this.wait)

        this.wait = setTimeout ( () => {
          this.turmasOrigem[i].loading =  true
          AcademicoService.searchTurmas(this.unidade, this.periodo, query).then(
            response => {
              // console.log("response:", response.data)
              if ( response.data.length > 0 ) {
                this.turmasOrigem[i].list = response.data
              } else {
                this.turmasOrigem[i].valid = false
              }
              this.turmasOrigem[i].loading = false
            },
            () => {
              this.turmasOrigem[i].loading = false
            }
          )
        }, 500)
      } else{
        this.turmasOrigem[i].valid = true
      }

    },
    selectItem ( item, i) {
      this.turmasOrigem[i].query = item.descricao
      this.turmasOrigem[i].idTurmaNova = item.idTurma
      this.turmasOrigem[i].list = null
      this.turmasOrigem[i].valid = true
      
      // console.log("this.turmasOrigem:", this.turmasOrigem[i])
    },
    remove ( i ) {
      this.turmasOrigem[i].idTurmaNova = 0
      this.turmasOrigem[i].query = null
    },
    save () {
      const drop = []

      this.turmasOrigem.map( k => {
        const item = {
          idTurmaAntiga: k.idTurma,
          idTurmaNova: k.idTurmaNova
        }

        if (  k.idTurmaNova || k.idTurmaNova == 0 ) {
          drop.push(item)
        }
        
      })

      console.log("drop:", drop)
      window.spinner.emit("open")

      AcademicoService.createProximasTurmas( drop ).then(
        response => {
          if ( response.status == 200 ) {
            window.toaster.emit('open', {style: "success", message: "Tudo certo! Próximas turmas criadas com sucesso", floater: true})
          }
          window.spinner.emit("close")
          // window.pagescroll.emit('goto', 0)
        },
        () => {
          window.spinner.emit("close")
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu! Não foi possível criar as turmas informadas", floater: true})
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
  .frame {
    padding: $hmg_small $hmg_small $mg_extra;
    margin-top: $mg
  }
  .columnList {
    width: 100%; height: auto;
    padding: 0; margin: 0;

    li {
      padding: 0; margin: 0 0 $hmg_small 0;
      display: flex; align-items: center; align-content: stretch;

      > div {
        flex: 1;
        position: relative;

        &:first-child{
          padding: $hmg_small;
          border-radius: $border-radius;
          background-color: $color-secondary;
        }
      }
      .icon {
        margin: 0 $hmg_mid;
        font-size: 18px;
        color: $color-primary
      }

      .searchWrap {
        position: relative;

        .remove {
          position: absolute;
          right: $hmg_small; top: 50%;
          transform: translateY(-50%);

          >* {
            @extend %transition_3o;
          }
          
          &:hover {
            color: $color-primary
          }
        }
      }
    }
  }

  .listWrap {
    position: relative;
    width: 100%;

    .list {
      position: absolute; 
      width: 100%; height: 180px;
      left: $hmg_mini; top: -$hmg_mini;
      overflow: hidden;
      z-index: 100;
      padding: $hmg_small;
      background-color: $color-secondary;
      border-radius: $border-radius;
      @extend %shadow-high;

      ul {
        padding: 0; margin: 0; max-height: 100%;
        overflow-y: auto;
        @extend %scrollbar;

        li {
          list-style: none;
          padding: $hmg_mini;
          margin: 0;
          @extend %transition_3o;
          cursor: pointer;

          &:hover {
            background-color: $color-bg;
            border-radius: $border-radius-small;
          }
        }
      }
    }
  } 

  .searchSpinner { 
    position: absolute; top: 50%;
    right: $hmg;
    transform: translateY(-55%);
    z-index: 100;
  }

  footer {
    margin-top: $hmg
  }

</style>