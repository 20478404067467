<template>
  <section class="gatilhoForm over-page" ref="gatilhoForm">
    <main class="frame">
      <card :listed="false" :boxed="false">
        <div class="form-wrap">
          <header>
            <div>
              <h3>{{ gatilho.gatilhosDadosId ? 'Editar' : 'Criar' }} Automação</h3>
              <p v-if="gatilho.nome">{{ gatilho.nome }}</p>
            </div>
            <switch-control v-model:checked="gatilho.status" labelOn="Ativo" labelOff="Inativo" labelPos="esq" />
          </header>
          <form-spinner v-if="loading"/>
          <div>
            <div class="inner-content">
              <div class="ebId">
                <fa :icon="['far', 'timeline-arrow']" v-if="gatilho.eventBridgeId"/>
                <fa :icon="['far', 'timeline']" v-else/>
                <span>{{ gatilho.eventBridgeId ?? 'Não integrado' }}</span>
              </div>
              <div class="row nop nom">
                <div class="col-md-4 form-group no-padd-left">
                  <label>Nome</label>
                  <input-control 
                    v-model="gatilho.nome" 
                    placeholder="Ex. Gatilho Intervenção" 
                    name="nome"
                    type="text" 
                    :valid="valid.nome" 
                    :disabled="loadingInputContent || ( gatilho.gatilhosDadosId ? true : false )" 
                    @keyup="valid.nome = true"
                    required
                  />
                </div>
                <div class="col-md-2 form-group no-padd-left">
                  <label>Tipo</label>
                  <select-control 
                    v-model="gatilho.tipo"
                    :options="options.tipos"
                    placeholder="Selecione" 
                    name="tipo" 
                    :valid="valid.tipo" 
                    :disabled="loadingInputContent" 
                    @keyup="valid.tipo = true"
                    required
                  />
                </div>
                <div class="col-md-2 form-group no-padd-left">
                  <input-control 
                    label="Tag Endpoint"
                    v-model="gatilho.endpointTag" 
                    placeholder="Ex. trescx" 
                    name="endpointTag"
                    type="text" 
                    :valid="valid.endpointTag" 
                    :disabled="loadingInputContent" 
                    @keyup="valid.endpointTag = true"
                    required
                  />
                </div>
                <div class="col-md-4 form-group no-padd-left">
                  <select-control 
                    label="Diretoria"
                    v-model="gatilho.idDepartamento"
                    placeholder="Selecione"
                    name="idDiretoria"
                    type="text"
                    :options="options.departamentos"
                    :valid="valid.diretoria"
                    :disabled="loadingInputContent"
                    @change="valid.diretoria = true"
                    required
                  />
                </div>
                <div class="col-md-12 form-group no-padd-left">
                  <label>Descrição</label>
                  <textarea-control 
                    v-model="gatilho.descricao" 
                    placeholder="Ex. Atualiza o ambiente do financeiro do apollo no DW" 
                    name="descricao"
                    type="text" 
                    :valid="valid.descricao" 
                    :disabled="loadingInputContent" 
                    @keyup="valid.descricao = true"
                    pop
                    required
                  />
                </div>
              </div>
            </div>
            <div class="inner-content">
              <h5>Cron</h5>
              <div class="row nop nom">
                <div class="col-md-2 form-group no-padd-left">
                  <input-control 
                    label="Minuto"
                    v-model="gatilho.frequencia.minute" 
                    placeholder="*" 
                    name="minute"
                    type="text" 
                    :valid="valid.minute" 
                    :disabled="loadingInputContent" 
                    @keyup="valid.minute = true"
                    @change="calculateCron()"
                    required
                  />
                </div>
                <div class="col-md-2 form-group no-padd-left">
                  <input-control 
                    label="Hora"
                    v-model="gatilho.frequencia.hour" 
                    placeholder="*" 
                    name="hour"
                    type="text" 
                    :valid="valid.hour" 
                    :disabled="loadingInputContent" 
                    @keyup="valid.hour = true"
                    @change="calculateCron()"
                    required
                  />
                </div>
                <div class="col-md-2 form-group no-padd-left">
                  <input-control 
                    label="Dia"
                    v-model="gatilho.frequencia.day" 
                    placeholder="*" 
                    name="day"
                    type="text" 
                    :valid="valid.day"
                    :disabled="loadingInputContent" 
                    @keyup="valid.day = true"
                    @change="calculateCron()"
                    required
                  />
                </div>
                <div class="col-md-2 form-group no-padd-left">
                  <input-control 
                    label="Mês"
                    v-model="gatilho.frequencia.month" 
                    placeholder="*" 
                    name="month"
                    type="text" 
                    :valid="valid.month" 
                    :disabled="loadingInputContent" 
                    @keyup="valid.month = true"
                    @change="calculateCron()"
                    required
                  />
                </div>
                <div class="col-md-2 form-group no-padd-left">
                  <input-control 
                    label="Dia da semana"
                    v-model="gatilho.frequencia.day_of_week" 
                    placeholder="*" 
                    name="day_of_week"
                    type="text" 
                    :valid="valid.day_of_week" 
                    :disabled="loadingInputContent" 
                    @keyup="valid.day_of_week = true"
                    @change="calculateCron()"
                    required
                  />
                </div>
                <div class="col-md-2 form-group no-padd-left">
                  <input-control 
                    label="Ano"
                    v-model="gatilho.frequencia.year" 
                    placeholder="*" 
                    name="year"
                    type="text" 
                    :valid="valid.year" 
                    :disabled="loadingInputContent" 
                    @keyup="valid.year = true"
                    @change="calculateCron()"
                    required
                  />
                </div>
              </div>
              <div class="cronList" v-if="this.cron">
                <h5>Próximas</h5>
                <ul>
                  <li>
                    Expressão (<b>{{ this.cron.cron_expression }}</b>) : {{ this.humanizeCron(this.cron.cron_expression) }}
                  </li>
                  <li v-for="(c, i) in cron.next_runs" :key="c">
                    <span>{{ i + 1 }}</span>
                    <div>{{ formatDateTime(c) }}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <footer class="form-actions">
          <a href="#" class="btn btn-secondary" @click.prevent="cancel()">
            Cancelar
          </a>
          <a href="#" class="btn btn-primary" @click.prevent="save()">
            Salvar
          </a>
        </footer>
      </card>
    </main>
  </section>
</template>

<script>
import FerramentasService from '@/services/ferramentas.service.js'
import FormService from '@/services/form.service.js'
import { formatFullDate, createSelectOptions } from '@/services/utils.service.js'
import AdmService from '@/services/adm.service.js'
import FormSpinner from '@/components/utilities/FormSpinner.vue'
import cronstrue from 'cronstrue/i18n'

export default {
  components: {
    FormSpinner
  },
  data () {
    return {
      gatilho:{
        nome: null,
        descricao: null,
        endpointTag: null,
        cronSchedule: null,
        status: 1,
        tipo: null,
        eventBridgeId: null,
        frequencia: {
          minute: null,
          hour: null,
          day: null,
          month: null,
          day_of_week: null,
          year : null
        }
      },
      valid: {
        nome: true,
        descricao: true,
        endpointTag: true,
        tipo: true,
        minute: true,
        hour: true,
        day: true,
        month: true,
        day_of_week: true,
        year : true,
        diretoria: true
      },
      options: {
        tipos: [
          { label: 'ETL', value: 'ETL' },
          { label: 'Enpoint', value: 'Enpoint' },
          { label: 'Outros', value: 'Outros' }
        ],
        departamentos: []
      },
      loading: true,
      loadingInputContent: false,
      cron: null
    }
  },
  mounted () {
    // window.spinner.emit("open")

    this.createBkp()
    this.getDepartamentos()
  },
  methods: {
    createBkp () {
      this.bkp = this.gatilho
    },
    editGatilho ( gatilho ) {
      this.gatilho = gatilho

      this.$nextTick(() => {
        this.calculateCron()
      })
    },
    getDepartamentos () {
      AdmService.getDepartamentos().then(
        response => {
          this.loading = false
          this.options.departamentos = createSelectOptions(response.data,'idDepartamento','nome')
        },
        error => {
          this.loading = false
          console.error(error)
        }
      )
    },
    calculateCron () {
      const allCodes = Object.values(this.gatilho.frequencia).every( a => a)
      console.log("allCodes:", allCodes)

      if ( allCodes ) {
        FerramentasService.calculateCron(this.gatilho.frequencia).then(
          response => {
            console.log("calculateCron:", response.data)
            this.cron = response.data
          },
          error => {
            console.log("error:", error)
          }
        )
      }
    },
    humanizeCron( cronExpression ) {
      const standardCronExpression = cronExpression.split(' ').slice(0, 5).join(' ')
      return cronstrue.toString(standardCronExpression, { use24HourTimeFormat: true, locale: 'pt_BR' })
    },
    formatDateTime ( date ) {
      return formatFullDate(date)
    },
    validate () {
      const valid = FormService.validate(this.$refs.gatilhoForm, this.valid)
      return valid.response
    },
    save () {
      const valid = this.validate()
      
      if ( valid ) {
        window.spinner.emit("open")
        
        this.$emit('save', this.gatilho)
      } else {
        window.toaster.emit('open', {style: "warning", message: "Dados Incorretos. Valide o formulário e tente novamente.", floater: true})
      }

    },
    cancel () {
      this.$emit("close")
    }
  }
}
</script>

<style lang="scss" scoped>
  
  .frame {
    padding: $mg
  }

  .form-wrap {
    header {
      display: flex; justify-content: space-between;
      padding: $hmg_mid;
      border-bottom: $border-component;

      h3 {
        margin: 0; padding: 0;
      }
      p {
        margin: 0;
      }
    }
    
    >div {
      .inner-content {
        padding: $hmg_mid;
        border-bottom: $border-component;

        &:last-of-type {
          border-bottom: 0;
        }
      }
    }
  }

  .gatilhoForm {
    h4 {
      padding: $hmg_small 0;
      @extend %transition_4e
    }
    h5 {
      padding: $hmg_small;
      @extend %transition_4e
    }

    .inner-content {
      // padding-top: $hmg_mid; margin-top: $hmg_mid;
      border-top: 1px solid $color-secondary;
      @extend %transition_4e;

      >div {
        @extend %transition_4e
      }
    }
  }

  .form-actions {
    margin: 0;
    padding: $mg_mini $hmg_mid;
    justify-content: space-between;
  }

  .ebId {
    display: inline-flex; align-content: center;
    gap: $mg_mini;
    background-color: $color-bg;
    border-radius: $border-radius;
    padding: $mg_mini; margin-bottom: $hmg_mid;
    line-height: 1;
  }

  .cronList {
    margin-top: $hmg_mid;

    ul {
      padding: 0; margin: 0;
      display: flex; flex-direction: column;

      li {
        list-style: none;
        padding: $hmg_mini;
        border-bottom: $border-component;
        display: flex; align-items: center;
        gap: $mg_mini;

        &:first-child {
          border-top-left-radius: $border-radius; border-top-right-radius: $border-radius;
          background-color: $color-bg;
          padding: $mg_mini;
        }
        >span {
          width: 24px; height: 24px;
          display: flex; flex-direction: column;
          align-items: center; justify-content: center;
          border-radius: 100%; background-color: $color-bg;
        }
      }
    }
  }
</style>