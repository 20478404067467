<template>
  <section class="section">
    <header>
      <h4>Resultado da Busca</h4>
      <p v-if="series">{{ series.length }} {{ series.length == 1 ? 'item' : 'itens' }} encontrados</p>
    </header>
    <div class="planilhasListWrap" v-if="series">        
      <ol class="cardList">
        <li>
          <div class="plItemInner">
            <span>
              ID
            </span>
            <span>
              Nome
            </span>
            <span>
              Curso
            </span>
            <span>
              Turno
            </span>
            <span>
              Período Letivo
            </span>
            <span>
              Unidade
            </span>
            <span>
              Status
            </span>
            <span></span>
          </div>
        </li>
        <li v-for="serie in series" :key="serie">
          <card spacing="small">
            <div class="plItemInner">
              <span>
                {{ serie.idSerie }}
              </span>
              <span class="fixed">
                {{ serie.descricao }}
              </span>
              <span>
                {{ serie.descricaoCurso }}
              </span>
              <span>
                {{ serie.descricaoTurno }}
              </span>
              <span>
                {{ serie.descricaoPeriodoLetivo }}
              </span>
              <span>
                {{ serie.siglaUnidade }}
              </span>
              <span>
                {{ serie.ativa ? 'Ativo' : 'Inativo' }}
              </span>
              <span class="actionWrap">
                <a href="#" @click.prevent="selectSerie(serie)">
                  <fa :icon="['far','magnifying-glass']"></fa>
                </a>
              </span>
            </div>
          </card>
        </li>
      </ol>
    </div>
  </section>
</template>

<script>

export default {
  props: {
    scope: {
      type: Array
    }
  },
  watch: {
    scope ( value ) {
      if ( value ) {
        this.series = value
      }
    }
  },
  data () {
    return {
      series: null
    }
  },
  mounted () {
    this.series = this.scope
    console.log("scope", this.scope)
    // this.addSelector(true)
  },
  methods: {
    selectSerie( serie ) {
      this.$emit('onSelect', serie)
    }
  }
}
</script>

<style lang="scss" scoped>
  .section {
    padding: $hmg $hmg_small $mg_large 
  }
  .plItemInner {
    grid-template-columns: .5fr 2fr 1fr 1.5fr 1.5fr 1.5fr 1.5fr .3fr;
  }
  

  .action-wrap {
    display: flex; align-content: center; align-items: center;
    justify-content: flex-end;
    padding: $hmg $mg;

    a {
      margin-left: $hmg_small
    }

    label{
      margin-right: $hmg
    }
  }
</style>