<template>
  <section>
    <header>
      <h4>Composição</h4>
    </header>
    <div>
      <section class="cross-control">
        <div>
          <div>
            <autocomplete-box src="tipoMaterial" :single="true" reference="idtipomaterial" @select="selectItem('kit',$event)" @changeSelected="resetLists()" ref="tipoMaterialFilter" target="kit"></autocomplete-box>
          </div>
          <div>
            <div class="waiting" v-if="!kit">
              <div>
                <fa :icon="['fat', 'box-open-full']" class="icon"></fa>
                <h1 class="color-text">Itens</h1>
                <p>Primeiro, selecione o kit desejado</p>
              </div>
            </div>
            <autocomplete-box src="itemMaterial" target="itens" reference="iditemmaterial" @select="selectItem('items', $event)" @done="exclude()" ref="itemMaterialFilter" v-else></autocomplete-box>
          </div>
        </div>
        <section class="itemsListWrap" v-if="calendarios.length > 0 && kits.length > 0">
          <h4>Novos Itens</h4>
          <div>
            <ol class="itemsList">
              <li>
                <div class="itemsListInner has-action">
                  <span>Item</span>
                  <span>Calendário</span>
                  <span>Valor Venda</span>
                  <span></span>
                </div>
              </li>
              <li v-for="item in kits" :key="item" :class="{ done: item.done, error: item.error }">
                <card spacing="small" :alert="item.error">
                  <div class="itemsListInner has-action">
                    <span>
                      {{ item.descricao }}
                    </span>
                    <span>
                      <select-control :options="options.calendarios" v-model="item['idcalendarioentrega']" placeholder="Selecione" cssClass="invert" :disabled="item.loading" required></select-control>
                    </span>
                    <span>
                      <input-control v-model="item['valorVenda']" placeholder="123,00" cssClass="invert" :disabled="item.loading" type="number" @keyup="getTotal()" required></input-control>
                    </span>
                    <span class="itemsListActions">
                      <input-spinner v-if="item.loading"></input-spinner>
                    </span>
                  </div>
                </card>
              </li>
            </ol>
          </div>
        </section>
        <transition name="slideup-small">
          <div class="loadingList" v-if="loadingComposicoes">
            <p>Aguarde. Estamos carregando os itens deste kit.</p>
          </div>
        </transition>
        <section class="itemsListWrap" v-if="!loadingComposicoes && composicoes.length > 0" >
          <h4>Composição Atual</h4>
          <div >
            <ol class="itemsList">
              <li>
                <div class="itemsListInner has-action">
                  <span>Item</span>
                  <span>Calendário</span>
                  <span>Valor Venda</span>
                  <span></span>
                </div>
              </li>
              <li v-for="(item, index) in composicoes" :key="item" :class="{ done: item.done, error: item.error }">
                <card spacing="small" :alert="item.error">
                  <div class="itemsListInner has-action">
                    <span>
                      {{ item.descricao }}
                    </span>
                    <span>
                      <select-control :options="options.calendarios" v-model="item.idcalendarioentrega" placeholder="Selecione" cssClass="invert" :disabled="item.loading" required></select-control>
                    </span>
                    <span>
                      <input-control v-model="item.valorVenda" placeholder="123,00" cssClass="invert" :disabled="item.loading" type="number" @keyup="getTotal()" required></input-control>
                    </span>
                    <span class="itemsListActions">
                      <input-spinner v-if="item.loading"></input-spinner>
                      <template v-else>
                        <a href="#" @click.prevent="confirmDelete(index)">
                          <fa :icon="['far','trash-can']"></fa>
                        </a>
                      </template>
                    </span>
                  </div>
                </card>
              </li>
            </ol>
          </div>
        </section>
        <footer>
          <div class="total" v-if="kit && total">
            <p>Valor do Kit</p>
            <h5 class="color-text">
              {{ formatMoney(kit.PrecoVenda) }}
            </h5>
          </div>
          <div class="total" v-if="total && total > 0">
            <p>Total</p>
            <h4 :class="{ 'color-success': total == kit.PrecoVenda, 'color-warning': total != kit.PrecoVenda }">
              {{ formatMoney(total) }}
            </h4>
          </div>
        </footer>
      </section>
      <footer>
        <a href="#" class="btn btn-primary" @click.prevent="saveList()">
          <span>
            Salvar Composição
          </span>
        </a>
      </footer>
    </div>
    <confirm 
      :backdrop="false"
      class="confirm"
      ref="confirm"
    ></confirm>
  </section>
</template>

<script>
import AutocompleteBox from '@/components/marketplace/parametrizacao/utilities/AutocompleteBox'
import MarketplaceService from '@/services/marketplace.service'
import inputSpinner from '@/components/utilities/InputSpinner'
import { createSelectOptions, formatMoney } from "@/services/utils.service"

export default {
  components: {
    AutocompleteBox, inputSpinner
  },
  data () {
    return {
      kits: [],
      kit: null,
      composicoes: [],
      calendarios: [],
      loadingComposicoes: false,
      itemToDelete: null,
      options:{
        calendarios: []
      },
      done: {
        calendarios: false,
        composicao: false,
        itemMaterial: false
      },
      total: null
    }
  },
  mounted () {
    this.getCalendarios()
  },
  methods: {
    selectItem ( src, value ) {
      if ( src === 'kit' ) {
        this.kit = value[0]
        this.getComposicoes(value[0].idtipomaterial)
      } else {
        this.kits = value
      }
      
      console.log("selectItem:", value)
    },
    getComposicoes ( id ) {
      const options = {
        idTipoMaterial: id
      }

      this.loadingComposicoes = true
      MarketplaceService.getComposicaoMaterial( options ).then(
        response => {
          console.log("response composicaomaterial:", response.data)
          if ( Object.keys(response.data).length > 0  && response.data.data.length > 0 ) {
            this.$refs.itemMaterialFilter.restore()
            this.composicoes = response.data.data
            // console.log("response composicaomaterial:", response.data)
          } else {
            this.composicoes = []
            window.toaster.emit('open', {style: "warning", message: "Não encontramos composições.", floater: true})
          }
          
          this.loadingComposicoes = false
          this.getTotal()
        },
        error => {
          console.log("error:", error)
          window.toaster.emit('open', {style: "warning", message: "Não foi possível encontrar composições neste momento.", floater: true})
          window.spinner.emit("close")
        }
      )
    },
    getCalendarios () {
      MarketplaceService.getParams('calendariosentrega').then(
        response => {
          if ( response.data.length > 0 ) {
            // console.log("response calendariosentrega:", response.data)
            this.calendarios = response.data
            this.options.calendarios = createSelectOptions(this.calendarios, 'idcalendarioentrega', 'descricao')
          } else {
            window.toaster.emit('open', {style: "warning", message: "Não encontramos calendários.", floater: true})
          }
          
          this.loadingItems = false
        },
        error => {
          console.log("error:", error)
          window.toaster.emit('open', {style: "warning", message: "Não foi possível encontrar calendários neste momento.", floater: true})
          window.spinner.emit("close")
          // this.loadingItems = false
        }
      )
    },
    saveList () {
      if ( this.composicoes.length > 0 ) {
        this.composicoes.map( k => {
          const options = {
            idtipomaterial: this.kit.idtipomaterial,
            iditemmaterial: k.iditemmaterial,
            idcalendarioentrega: k.idcalendarioentrega,
            valorVenda: k.valorVenda,
            idcalendarioaplicacao: 0
          }
          
          const valid = this.validate(options)

          k['error'] = false
          
          if ( valid ) {
            k['loading'] = true

            MarketplaceService.updateComposicaomaterial(k.idComposicaoMaterial, options).then(
              response => {
                console.log("updateComposicaomaterial: ", response)
                k['loading'] = false
              },
              error => {
                console.log("error:", error)
                k['loading'] = false
                k['error'] = true
                // this.loadingItems = false
              }
            )
          } else {
            k['error'] = true
          }
          console.log("k:", k)
          console.log("options:", options)

          
        })
      }

      if ( this.kits.length > 0 ) {
        this.kits.map( (k, i) => {
          const options = {
            idtipomaterial: this.kit.idtipomaterial,
            iditemmaterial: k.iditemmaterial,
            idcalendarioentrega: k.idcalendarioentrega,
            valorVenda: k.valorVenda,
            idcalendarioaplicacao: 0
          }

          const valid = this.validate(options)
          k['error'] = false

          if ( valid ) {
            k['loading'] = true

            MarketplaceService.saveComposicaomaterial( options ).then(
              response => {
                // console.log("saveComposicaomaterial: ", response)
                k['loading'] = false

                if ( response.data ) {
                  const data = response.data.composicaoMaterial
                  data['descricao'] = k.descricao
                  this.composicoes.push(response.data.composicaoMaterial)
                  this.kits.splice(i,1)
                  this.$refs.itemMaterialFilter.stateItem(true,k)
                }
              },
              error => {
                console.log("error:", error)
                k['loading'] = false
                k['error'] = true
              }
            )
          } else {
            k['error'] = true
          }
          
        })
      }
      
    },
    validate ( object ) {

      const response = Object.values(object).filter( k => {
        return !k && k != 0
      })
      
      return response.length == 0
    },
    confirmDelete ( item ) {
      this.itemToDelete = item
      // 2022 - KIT ANGLO ENSINO MÉDIO 2ª SÉRIE - CADERNO 1 CADERNO DE ESTUDOS - AL
      this.$refs.confirm.run({
        message: "Tem certeza que deseja remover este item?",
        confirmButton: "Sim",
        denyButton: "Não",
        callback: this.delete,
      })
    },
    delete ( response ) {
      if ( response ) {
        const target = this.composicoes[this.itemToDelete]
        target['loading'] = true
        const idComposicaoMaterial = target.idComposicaoMaterial
        MarketplaceService.deleteComposicaomaterial(idComposicaoMaterial).then(
          response => {
            console.log("deleteComposicaomaterial: ", response)
            if ( response.data ) {
              this.$refs.itemMaterialFilter.stateItem(false, target)
              this.$nextTick(() => {
                this.composicoes.splice(this.itemToDelete, 1)
                this.getTotal()
              })
              
            } else {
              target['error'] = true
            }
            
            target['loading'] = false
          },
          error => {
            console.log("error:", error)
            target['loading'] = false
            // this.loadingItems = false
          }
        )
      }      
    },
    exclude () {
      const waitToExclude = setInterval( () => {
        if ( this.composicoes.length > 0 ) {
          clearInterval(waitToExclude)

          this.composicoes.map( k => {
            console.log("exclude - composicoes - k:", k)
            this.$refs.itemMaterialFilter.stateItem(true, k)
          })
        }
      },100)
    },
    formatMoney ( value ) {
      return formatMoney(value)
    },
    getTotal () {
      var sumComp = 0
      var sumNew = 0
      
      if ( this.composicoes.length > 0 ) {
        this.composicoes.map( k => {
          sumComp += k.valorVenda ? parseFloat(k.valorVenda) : 0
        })
      }
      if ( this.kits.length > 0 ) {
        this.kits.map( k => {
          sumNew += k.valorVenda ? parseFloat(k.valorVenda) : 0
        })
      }

      this.total = sumComp + sumNew
    },
    resetLists () {
      this.composicoes = []
      this.getTotal()
    }
  }
}
</script>

<style lang="scss" scoped>
  header {
    padding: 0 0 $hmg_small 0;
  }

  footer {
    display: flex; justify-content: flex-end;
    padding: $hmg_mid;

    .total {
      display: flex; align-content: center; align-items: center;
      justify-content: space-between;
      width: 30%;
      padding: $hmg_mid;
      background-color: $color-secondary;
      border-radius: $border-radius;

      >* {
        margin: 0; padding: 0;
      }

      &:first-child {
        width: 20%;
        margin-right: $hmg_mid;
      }
    }
  }

  .cross-control {
    display: flex; flex-direction: column;

    >div {
      display: flex; width: calc(100% + $mg_mini); height: auto;
      align-content: stretch; align-items: stretch;
      margin-left: -$hmg_mid;

      > div {
        width: 100%; max-width: 50%;
        margin-right: $mg;

        &:last-child {
          margin-right: 0;
        }
      }

      .waiting {
        position: relative;
        width: 100%; height: 100%;
        border: 3px dashed $color-secondary;
        border-radius: $border-radius-larger;

        > div {
          position: absolute;
          margin: auto; top: 50%; transform: translateY(-50%);
          text-align: center;
          left: 0; right: 0;

          .icon {
            font-size: 100px;
            margin-bottom: $hmg_small
          }

        }
      }
    }
    
    .itemsListWrap {
      margin-top: $mg;

      h4 { 
        padding: 0 $hmg_mid;
      }
      > div {
        margin-top: $hmg_small
      }
    }

    .itemsList {
      display: block;
      width: 100%;
      padding: 0; margin: 0;

      li {
        list-style: none;
        width: 100%; 
        padding: 0; margin: 0;

        .itemsListInner {
          display: grid; width: 100%;
          padding: $mg_mini $hmg_small;
          align-items: center; align-content: center;
          grid-template-columns: 3fr 2fr 1fr;

          &.has-action {
            grid-template-columns: 3fr 2fr 1fr .2fr;
          }
          

          > span {
            padding: 0 $hmg_mini;

            &.actionWrap {
              display: flex; align-items: center;
              justify-content: flex-end;
            }

            .syncIcon {
              display: flex; flex-direction: column;
              align-content: center; align-items: center; justify-content: center;
              width: 28px; height: 28px;
              background-color: $color-success-2;
              padding: 0; margin: 0;
              line-height: 1;
              color: $color-secondary;
              border-radius: 100%;
              text-align: center;
            }

            .fixed {
              white-space: nowrap;
            }

            a:not(.btn) {
              color: $color-primary;

              &:hover {
                color: $color-dev
              }
            }
          }
        }

        .plItemDetails {
          padding: $hmg;
        }

        .plTableFooter {
          padding-top: $hmg;
          display: flex;
          align-content: center; align-items: center;
          justify-content: flex-end;

          >.btn {
            margin-left: $mg_small;
          }
        }
      }
    }
  }

  .itemsListActions {
    display: flex; align-items: center; align-content: center;
    justify-content: flex-end;
  }

  
</style>