<template>
  <controller>
    <page-header title="Calculadora de Preços"/>
    <section class="calculadora">
      <simulador v-if="plano" :scope="plano" @onClose="toggleSimulator(null)"/>
      <planos-pagamento :scope="planos" v-if="planos" @onSelect="toggleSimulator($event)"/>
    </section>
  </controller>
</template>

<script>
import MatriculaService from '@/services/matricula.service.js'
import PlanosPagamento from '@/components/matriculas/simulador/PlanosPagamento.vue'
import Simulador from '@/components/matriculas/simulador/Simulador.vue'

export default {
  components: {
    PlanosPagamento, Simulador
  },
  props: {
    idSerie: {
      type: [Number, String]
    },
    idFuncao: {
      type: [Number, String]
    },
    tipoMatricula: {
      type: [Number, String]
    }
  },
  data () {
    return {
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
      planos: null,
      plano: null
    }
  },
  mounted () {
    this.getPlanos()
  },
  methods: {
    getPlanos () {
      const options = {
        idSerie: this.idSerie,
        idFuncao: this.idFuncao,
        tipoMatricula: this.tipoMatriculas
      }
      MatriculaService.getPlanosBySerie(options).then(
        response => {
          console.log('getPlanosBySerie:', response.data)
          const clearPlanos = response.data.filter( a => a.planosPagamento)
          this.planos = MatriculaService.processPlanos(clearPlanos)
          console.log('planos:', this.planos)
        },
        () => {
          
        }
      )
    },
    toggleSimulator ( plano ) {
      console.log("toggleSimulator:", plano)
      this.plano = plano
    }
  }
}
</script>

<style lang="scss" scoped>
  .calculadora {
    width: 100%;
  }

  
</style>