<template>
  <section class="over-page">
    <main class="frame">
      <div class="sidebar">
        <h3>Adicionar novo atendimento</h3>
        <card :listed="false" boxed>
          <p>Preencha o formulário ao lado para criar um novo atendimento.</p>
        </card>
      </div>
      <div class="content">
        <card :listed="false" overflow="visible">
          <criar-editar :id="id" @onCancel="cancel" @onSave="save"></criar-editar>
        </card>
      </div>
    </main>
  </section>
</template>

<script>
import CriarEditar from '@/components/atividades/form/atendimentos/CriarEditarAtendimento.vue'

export default {
  components: {
    CriarEditar
  },
  props: {
    id: {
      type: [Number, String]
    }
  },
  data () {
    return {
      
    }
  },
  mounted () {

  },
  methods: {
    cancel () {
      this.$emit('onCancel')
    },
    save ( data ) {
      this.$emit('onSave', data)
    }
  }
}
</script>

<style lang="scss" scoped>
  main {
    display: flex; align-items: flex-start; align-content: center;
    gap: $hmg_mid;
    padding: $mg;

    @media screen and ( max-width: 1024px ) {
      flex-direction: column;
      padding: $hmg_mid
    }

    .sidebar {
      width: 320px;

      @media screen and ( max-width: 1024px ) {
        width: 100%;
      }
      
      h3 {
        padding: $hmg_mid $hmg_mid $mg_mini $hmg_mid
      }

      p {
        margin: 0; padding: 0;
      }
    }
    .content {
      flex: 1;

      
    }
  }
</style>
