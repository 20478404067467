<template>
  <section class="app">
    <header>
      <h3>{{ title }}</h3>
    </header>
    <div>
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  components: {

  },
  props: {
    title: {
      type: String,
      default: 'App Rede'
    }
  },
  data () {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
  .app {
    display: flex; flex-direction: column;
    align-content: stretch; align-items: stretch;
    background-color: $color-bg;
    height: 100vh;

    > header {
      display: flex; align-content: center; align-items: center;
      justify-content: space-between;
      background-color: $color-secondary;
      border-bottom: $border-component;
      height: 80px;
      padding: 0 $hmg;

      h3 {
        // color: $color-secondary;
        margin: 0; padding: 0;
      }
    }

    > div {
      flex: 1;
      overflow-y: auto;
      @extend %scrollbar;
    }
  }
</style>