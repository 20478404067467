<template>
  <section class="mat-main">
    <header>
      <p>Etapa {{ step.index + 1 }} de {{ totalSteps }}</p>
      <h4>Dados do Responsável Financeiro</h4>
      <p>Confirme se os dados do responsável financeiro estão corretos. Caso algum dado esteja incorreto, por favor, atualize.</p>
    </header>
    <section class="form-wrap">
      <h5 class="color-text">{{ step.index + 1 }}.1. Informacões Pessoais</h5>
      <pessoas branch="respFin" ref="pessoa" @onload="clearLoading('pessoa')" @populateEndereco="populateEndereco($event,'enderecoData')" v-if="loaded" :action="action" :auto="auto" :highrequire="auto" :idMatricula="idMatricula"></pessoas>
    </section>
    <section class="form-wrap">
      <h5 class="color-text">{{ step.index + 1 }}.2. Endereço</h5>
      <enderecos branch="respFin" ref="endereco" @onload="clearLoading('endereco')" :autoPopulate="enderecoData" v-if="loaded" :action="action"></enderecos>
    </section>
  </section>
</template>

<script>
import Pessoas from '@/components/matriculas/form/Pessoas'
import Enderecos from '@/components/matriculas/form/Enderecos'
import MatriculaService from '@/services/matricula.service'
import { unformatDate } from '@/services/utils.service'

export default {
  components: {
    Pessoas, Enderecos
  },
  props: {
    ready: {
      type: Boolean,
      default: false
    },
    idMatricula: {
      type: [String, Number]
    },
    auto: {
      type: Boolean,
      default: false
    },
    action: {
      type: String
    },
    step: {
      type: Object
    },
    totalSteps: {
      type: Number
    }
  },
  data () {
    return {
      wait: {
        pessoa: false,
        endereco: false
      },
      enderecoData: null,
      loaded: false
    }
  },
  methods: {
    clearLoading ( target ) {
      this.wait[target] = true

      this.stopSpinner()
    },
    stopSpinner () {
      const done = !Object.values(this.wait).includes(false)
      if ( done ) window.spinner.emit("close")
    },
    validate () {
      const validPessoa = this.$refs.pessoa.validate()
      const validEndereco = this.$refs.endereco.validate()

      const response = validPessoa.response && validEndereco.response

      if ( !validPessoa.response || !validEndereco.response ) {
        var invalids = []
        Object.keys(validPessoa.valids).map(k => {
          if ( !validPessoa.valids[k] ) {
            invalids.push(k)
          }
        })
        Object.keys(validEndereco.valids).map(k => {
          if ( !validEndereco.valids[k] ) {
            invalids.push(k)
          }
        })
        var msg = invalids.join(', ')
        window.toaster.emit('open', {style: "error", message: "Existem campos não preenchidos: <b>" + msg + "</b>", floater: true})
      }

      return { response: response }
    },
    populateEndereco ( data, target ) {
      this[target] = data
    },
    get () {
      this.$store.dispatch('matricula/get').then(matricula => {
        this.matricula = matricula
        // console.log("get filiacao - matricula response:", response)
        // console.log("idMatricula:", this.idMatricula)
        if ( this.idMatricula ) {
          MatriculaService.getRespFin( this.idMatricula ).then(
            response => {
              console.log("getRespFin:", response.data)
              if ( response.data && response.data.pessoa ) {
                console.log("chegou aqui..")
                this.matricula.respFin.Basicos = response.data.pessoa.Basicos ? response.data.pessoa.Basicos : this.matricula.respFin.Basicos
                this.matricula.respFin.Documento = response.data.pessoa.Documento ? response.data.pessoa.Documento : this.matricula.respFin.Documento
                this.matricula.respFin.Endereco = response.data.pessoa.Endereco ? response.data.pessoa.Endereco : this.matricula.respFin.Endereco
                this.matricula.respFin.Responsavel = response.data.pessoa.Responsavel ? response.data.pessoa.Responsavel : this.matricula.respFin.Responsavel

                if ( !this.matricula.respFin.Basicos.nomeDocumento ) {
                  this.matricula.respFin.Basicos.nomeDocumento = this.matricula.respFin.Basicos.nome
                }

                if ( this.matricula.respFin.Basicos.dataNascimento ) {
                  this.matricula.respFin.Basicos.dataNascimento = unformatDate(this.matricula.respFin.Basicos.dataNascimento)
                }
              }
              
              this.$store.dispatch('matricula/save').then(() => {
                // console.log(response)
                this.$emit('ready')
                this.$nextTick(() => {
                  this.loaded = true
                })
              })
            },
            error => {
              console.error(error)
            }
          )
        } else {
          this.loaded = true
          this.$emit('ready')
        }
        
      })
      
    },
  }
}
</script>

<style lang="scss" scoped>

</style>