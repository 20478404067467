<template>
  <loja-controller :goToTopButton="true">
    <main-banner></main-banner>
    <featured></featured>
    <slider 
      v-for="slider in sliders"
      :key="slider"
      :data="slider.data"
      :slidesPerPage="4"
      :title="slider.title"
      :slug="slider.slug"
      :id="slider.id"
    ></slider>
  </loja-controller>
</template>
<script>
import mainBanner from '@/components/loja/communication/MainBanner.vue'
import Featured from '@/components/loja/communication/Featured.vue'
import Slider from '@/components/loja/utilities/Slider2.vue'
import LojaService from '@/services/loja.service'

export default {
  components: {
    mainBanner, Featured, Slider,
    // slider
  },
  data () {
    return {
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
      uniformes: [],
      sliders: [
        {
          id: 1,
          title: 'Material Didático',
          slug: 'material-didatico',
          data: []
        },
        {
          id: 2,
          title: 'Cursos Extras',
          slug: 'cursos-extras',
          data: []
        },
        {
          id: 5,
          title: 'Uniformes',
          slug: 'uniformes',
          data: []
        },
      ],
      campinas: [29,31],
      loaded: false
    }
  },
  mounted () {
    var unidades =  []
    this.currentUser?.alunos?.map( k => {
      unidades.push(k.idUnidade)
    })

    // if ( unidades.includes(27) ) {
    //   const sliders = this.sliders.filter( k => {
    //     return k.title != "Material Didático" && k.title != "Cursos Extras"
    //   })
    //   this.sliders = sliders
    // }

    // if ( unidades.includes(27) ) {
    //   let sliders = this.sliders.filter( k => {
    //     return k.title != "Material Didático"
    //   })
    //   this.sliders = sliders
    // } else if ( unidades.some(a => this.campinas.includes(a)) ) {
    //   let sliders = this.sliders.filter( k => {
    //     return k.title != "Uniformes"
    //   })
    //   this.sliders = sliders
    // }

    if ( unidades.includes(27) ) {
      let sliders = this.sliders.filter( k => {
        return k.title != "Material Didático"
      })
      this.sliders = sliders
    }


    this.fetchProdutos()
  },
  methods: {
    fetchProdutos () {
      this.sliders.map( (k, i) => {
        const data = {
          "categoriasIds": [k.id],
          "subCategoriasIds": [],
          "text": "",
          "idPessoaResponsavel": this.currentUser?.idPessoa,
          "filter": true
        }
        LojaService.buscaProdutosLoja(data).then(
          response => {
            const eligibilty = LojaService.processProductsAccess(response.data, this.currentUser, k.id)
            k.data = eligibilty
            if ( i == this.sliders.length - 1 ) {
              this.getEmpty()
            }
            console.log('LojaService.getMenuCategoriasLoja()', response.data)
          },
          error => {
            console.log('error', error)
          }
        )
      })
    },
    getEmpty () {
      const filtered = this.sliders.filter( k => {
        const data = k.data
        return data.length > 0
      })
      console.log("filtered:", filtered)
      
      this.loaded = true
      this.sliders = filtered
      

    }
  }
}
</script>
<style lang="scss" scoped>
  .main-scroller {
    max-height: 100vh;
    background-color: $color-light;
  }
</style>