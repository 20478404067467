<template>
  <section>
    <header class="page-title inner">
      <h4 v-if="editar">Editar Produto</h4>
      <h4 v-else-if="novo">Novo Produto</h4>
      <h4 v-else>Produtos</h4>
      <a href="#" class="btn btn-primary" @click.prevent="addProduct()" v-if="!novo && !editar">
        Novo produto &nbsp;
        <fa :icon="['fas','gift']"></fa>
      </a>
      <a href="#" class="btn btn-secondary" @click.prevent="closeForm()" v-if="novo || editar">
        <fa :icon="['far','arrow-left-long']"></fa>
        &nbsp; Voltar
      </a>
    </header>
    <div v-if="novo || editar">
      <produto :scope="produto" @onClose="closeForm()"></produto>
    </div>
    <div v-else>
      <filter-controller @onLoad="dropFilter" @clear="clear"></filter-controller>
      <div class="planilhasListWrap" v-if="produtos.length > 0 && loaded">
      <header>
        <h4>Resultados de busca</h4>
        <p>{{ produtos.length + ( produtos.length == 1 ? ' encontrado' : ' encontrados' ) }}</p>
      </header>
      
      <ol class="cardList">
        <li>
          <div class="plItemInner">
            <span>
                ID
            </span>
            <span>
              Descrição
            </span>
            <span>
              Qtd turmas
            </span>
            <span>
              Precifica por item
            </span>
            <span>
              Serviço extra
            </span>
            <span>
              Início venda
            </span>
            <span>
              Término venda
            </span>
            <span>
              NCM
            </span>
            <span>
              Status
            </span>
            <span></span>
          </div>
        </li>
        <li v-for="produto in produtos" :key="produto">
          <card spacing="small">
            <div class="plItemInner">
              <span>
                {{ produto.idtipomaterial }}
              </span>
              <span class="fixed">
                {{ produto.descricao }}
              </span>
              <span>
                {{ produto.numeroturmas }}
              </span>
              <span>
                {{ produto.precoitem ? 'sim' : 'não' }}
              </span>
              <span>
                {{ produto.servicoextra ? 'sim' : 'não' }}
              </span>
              <span>
                {{ produto.iniciovenda }}
              </span>
              <span>
                {{ produto.terminovenda }}
              </span>
              <span>
                {{ produto.ncm }}
              </span>
              <span>
                {{  produto.ativo ? 'ativo' : 'inativo' }}
                <!-- <switch-control v-model:checked="produto.ativo"></switch-control> -->
              </span>
              <span class="actionWrap">
                <a href="#" @click.prevent="getProduto(produto, i)">
                  <fa :icon="['far','magnifying-glass']"></fa>
                </a>
                
                <!-- <span class="syncIcon" v-else>
                  <fa :icon="['fas','check']" :class="['icon',{ success: null }]"></fa>
                </span> -->
              </span>
            </div>
          </card>
        </li>
      </ol>
    </div>
    </div>
  </section>
</template>

<script>
import Produto from './Produto'
import MarketplaceService from '@/services/marketplace.service'
import { formatDate } from '@/services/utils.service'
import FilterController from '@/components/marketplace/parametrizacao/utilities/FilterProdutos'
// import InputSpinner from '@/components/utilities/InputSpinner'

export default {
  components: {
    Produto, 
    FilterController
    // InputSpinner
  },
  props: {
    mid: {
      type: String
    },
    target: {
      type: String
    }
  },
  data () {
    return {
      produtos: [],
      loaded: false,
      loading: false,
      produto: null,
      activeIndex: null,
      novo: false,
      editar: false,
      model: {
        idtipomaterial: null,
        descricao: null,
        PrecoCusto: null,
        PrecoVenda: null,
        idcliente: 2,
        detalhes: null,
        imgproduto: null,
        numeroturmas: 0,
        ativo: 0,
        instrucaocancelamento: 0,
        termoadesao: null,
        matriculainicial: null,
        matriculafinal: null,
        controlaestoque: 0,
        iniciovenda: null,
        terminovenda: null,
        idcategorialoja: null,
        servicoextra: 0,
        precoitem: 0,
        descricaovenda: null,
        mensagemEmail: null,
        nao_comunica_venda: 0,
        destaqueHome: null,
        ncm: null
      }
    }
  },
  mounted () {
    // this.getProdutos()
    console.log("this.mid:", this.mid)
    console.log("this.target:", this.target)
    
    if ( this.mid || this.target ) {
      this.$router.push({name: 'Parametrização Shop', params: {section: 'parametros', action: 'produtos' }})
    }
  },
  methods: {
    getProdutos () {
      this.loading = true
      window.spinner.emit("open")
      MarketplaceService.getTipoMaterial().then(
        response => {
          this.produtos = response.data.data
          console.log("response:", response.data)
          this.loaded = true
          window.spinner.emit("close")
        },
        error => {
          console.log(error.response.data)
          window.spinner.emit("close")
        } 
      )
    },
    getProduto (produto, i) {
      this.produto = produto
      this.activeIndex = i
      this.editar = true

      this.$router.push({name: 'Parametrização Shop', params: {section: 'parametros', action: 'produtos', mid: 'editar', target: produto.idtipomaterial }})
    },
    formatDate ( date ) {
      return formatDate(date)
    },
    addProduct () {
      this.novo = 
      this.produto = this.model

      this.$router.push({name: 'Parametrização Shop', params: {section: 'parametros', action: 'produtos', mid: 'novo' }})
    },
    closeForm () {
      this.novo = false
      this.editar = false
    },
    clear () {
      this.produtos = []
    },
    dropFilter ( e ) {
      console.log("dropFilter:", e)
      this.loaded = true
      this.produtos = e

      window.pagescroll.emit("goto", 600)
    } 
  }
}
</script>

<style lang="scss" scoped>
  .planilhasListWrap {
    margin-top: $mg;
  }
  header {
    padding: 0 $hmg_small
  }
  .plItemInner {
    grid-template-columns: .3fr 3fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr .3fr;
  }
</style>





