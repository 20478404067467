<template>
  <section class="table-wrap">
    <header>
      <h4>Arrecadações</h4>
    </header>
    <div class="filter">
      <input-control v-model="query" @keyup="search" placeholder="Buscar..." @onsearch="search" search preventSearchButton></input-control>
      <a href="#" class="btn btn-secondary" @click.prevent="clearSearch">
        Limpar
      </a>
      <div>
        <switch-control v-model:checked="active" labelOn="Apenas Ativos" labelOff="Todos" @change="toggleDisabled" invert></switch-control>
      </div>
    </div>
    <table v-if="arrecadacoes" class="transparent-table ">
      <thead>
        <tr>
          <th>ID</th>
          <th>Descrição</th>
          <th>Sigla</th>
          <th>Grupo</th>
          <th>Obrigatório</th>
          <th>Vencimento do responsável</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(arrecadacao,i) in arrecadacoes" :key="arrecadacao">
          <td>
            {{ arrecadacao.idTipoArrecadacao }}
          </td>
          <td>
            {{ arrecadacao.descricao }}
          </td>
          <td>
            {{ arrecadacao.sigla }}
          </td>
          <td>
            <input-control v-model="arrecadacao.grupo" placeholder="Grupo" cssClass="small invert table-input" @change="toggleChange(i)"/>
          </td>
          <td>
            {{ arrecadacao.obrigatorio ? 'Sim' : 'Não' }}
          </td>
          <td class="form-group">
            <div class="switchWrapMiniLoose">
              <switch-control v-model:checked="arrecadacao.responsavelVencimento" labelOn="Sim" labelOff="Não" @change="toggleChange(i)" invert></switch-control>
            </div>
          </td>
          <td>
            {{ arrecadacao.ativo ? 'Ativo' : 'Inativo' }}
          </td>
          <td class="text-right">
            <label class="check-control">
              <input type="checkbox" class="check" v-model="arrecadacao.selected">
            </label>
          </td>
        </tr>
      </tbody>
    </table>
    <footer class="action-wrap">
      <a href="#" @click.prevent="saveArrecadacoes()" class="btn btn-primary">
        Salvar
      </a>
      <a href="#" @click.prevent="cancel()" class="btn btn-secondary">
        Voltar
      </a>
    </footer>
  </section>
</template>

<script>
import FinanceiroService from '@/services/financeiro.service.js'

export default {
  data () {
    return {
      arrecadacoes: null,
      bkp: null,
      query: null,
      active: false
    }
  },
  mounted () {
    this.getArrecadacoes()
  },
  methods: {
    cancel () {
      this.$emit('onCancel')
    },
    toggleChange ( index ) {
      this.arrecadacoes[index].selected = true
    }, 
    getArrecadacoes () {
      window.spinner.emit('open')
      FinanceiroService.getArrecadacoes().then(
        response => {
          console.log("response:", response)
          if ( response.data.length > 0 ) {
            this.arrecadacoes = response.data
            this.bkp = Object.assign([], response.data)
            this.addSelector(true)
          } else {
            window.toaster.emit('open', {style: "error", message: "Não foi possível recuperar as arrecadações. Isto parece ser um problema de integração com o GestorClass.", floater: true})
          }
          
          window.spinner.emit('close')
        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível recuperar as arrecadações.", floater: true})
          window.spinner.emit('close')
        }
      )
    },
    addSelector ( off ) {
      this.arrecadacoes.map( k => {
        k.selected = off ? false : true
      })
    },
    search () {
      if ( this.query && this.query.length > 0 ) {
        const result = this.bkp.filter( k => {
          // console.log("k:", k.descricao)
          const query = this.query.toLowerCase()
          const descricao = k.descricao ? k.descricao.toLowerCase() : ""
          const grupo = k.grupo ? k.grupo.toLowerCase() : ""
          const sigla = k.sigla ? k.sigla.toLowerCase() : ""
          const id = k.idTipoArrecadacao.toString()

          return descricao.includes(query) || grupo.includes(query) || sigla.includes(query) || id.includes(query)
        })

        this.arrecadacoes = result
      } else if ( !this.query || this.query.length == 0 ) {
        this.clearSearch()
      }
    },
    clearSearch () {
      this.arrecadacoes = Object.assign([],this.bkp)
      this.query = null
    },
    toggleDisabled () {
      this.arrecadacoes = this.active ? this.bkp.filter(a => a.ativo) : Object.assign([],this.bkp)
    },
    saveArrecadacoes () {
      const data = this.arrecadacoes.filter( k => k.selected )
      data.map( k => {
        k.responsavelVencimento = k.responsavelVencimento ? 1 : 0
        delete k.selected
      })

      console.log("saveArrecadacoes:", data)

      if ( data.length > 0 ) {
        window.spinner.emit('open')
        FinanceiroService.saveArrecadacoes( data ).then(
          response => {
            if ( response.status == 200 ) {
              window.toaster.emit('open', {style: "success", message: "Arrecadações salvas com sucesso.", floater: true})
            } else {
              window.toaster.emit('open', {style: "error", message: "Não foi possível salvar as arrecadações. Isto parece ser um problema de integração com o GestorClass.", floater: true})
            }

            window.spinner.emit('close')
          },
          () => {
            window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível salvar as arrecadações.", floater: true})
            window.spinner.emit('close')
          }
        )
      } else {
        window.toaster.emit('open', {style: "error", message: "Nenhum item selecionado para salvar.", floater: true})
      }
      
    }
  }
}
</script>

<style lang="scss" scoped>
  .table-wrap {
    padding: $hmg $hmg_small $mg_extra;

    header {
      padding: 0 $hmg_small
    }
  }
  
  .action-wrap {
    display: flex; align-content: center; align-items: center;
    justify-content: flex-start;
    padding: $hmg 0;

    a {
      margin-right: $hmg_small
    }

    label{
      margin-right: $hmg
    }
  }

  .filter {
    display: flex; align-content: center; align-items: center;
    justify-content: flex-start; gap: $hmg_small;
    padding: $hmg_small 0; margin-bottom: $hmg_small;
    .icon {
      margin-right: $hmg_small
    }
    .input-control {
      width: 250px;
    }
    // .btn {
    //   margin-left: $hmg_small
    // }

  }
</style>