<template>
  <section class="section">
    <div class="errorAlert" v-if="error">
      <h4>Atenção.</h4>
      <p><b>Houve um ou mais erros na hora de fazer a progressão.</b></p>
      <p>Por este motivo, você não pode continuar progredindo o período letivo selecionado. Entre em contato com o time de suporte para solucionar o problema.</p>
      <p>Para facilitar o processo, informe o período o letivo para o qual vocês está criando a progressão.</p>
    </div>
    <template v-if="progress">
      <card v-for="(prg, i) in Object.keys(progress)" :key="prg">
        <div class="progressionBox">
          <h4>Etapa {{ i + 1 }} - {{ progress[prg].label }}</h4>
          <div>
            <div class="progressBarWrap" v-if="progress[prg].apollo">
              <h5 class="color-text">Apollo</h5>
              <ul>
                <li v-if="progress[prg].apollo.inicio">
                  {{ formatDateTime(progress[prg].apollo.inicio) }}
                </li>
                <li v-if="progress[prg].apollo.fim">
                  {{ formatDateTime(progress[prg].apollo.fim) }}
                </li>
              </ul>
              <div :class="['progressBar apollo', { on: progress[prg].apollo.status == 'on' , finished: progress[prg].apollo.status == true }]">
                <span></span>
              </div>
            </div>
            <div class="progressBarWrap" v-if="progress[prg].gestor">
              <template v-if="prg != 'sync'">
                <h5 class="color-text">GestorClass</h5>
                <ul>
                  <li v-if="progress[prg].gestor.inicio">
                    {{ formatDateTime(progress[prg].gestor.inicio) }}
                  </li>
                  <li v-if="progress[prg].gestor.fim">
                    {{ formatDateTime(progress[prg].gestor.fim) }}
                  </li>
                </ul>
                <div :class="['progressBar gestor']" >
                  <span :style="{ width: progress[prg].gestor.percent + '%' }"></span>
                </div>
              </template>
              <div v-if="( prg == 'sync' && processing && sync ) || ( prg == 'sync' && progress[prg].gestor?.inicio )" class="syncMessage errorAlert">
                <template v-if="progress[prg].gestor.inicio && progress[prg].gestor.fim">
                  <fa :icon="['fas','circle-check']" class="icon"></fa>
                  <div>
                    <h4 class="color-text">Finalizado</h4>
                    <p>Início: <b>{{ progress[prg].gestor.inicio ? formatDateTime(progress[prg].gestor.inicio) : ' - ' }}</b></p>
                    <p>Término: <b>{{ progress[prg].gestor.fim ? formatDateTime(progress[prg].gestor.fim) : ' - ' }}</b></p>
                  </div>
                </template>
                <template v-else>
                  <fa :icon="['fas','triangle-exclamation']" class="icon"></fa>
                  <div>
                    <h4 class="color-text">Processando</h4>
                    <p>Início: <b>{{ progress[prg].gestor.inicio ? formatDateTime(progress[prg].gestor.inicio) : ' - ' }}</b></p>
                    <p>Esta etapa pode demorar mais de 2 horas para ser finalizada. Você pode sair da tela e voltar quando quiser.</p>
                  </div>
                </template>
              </div>
            </div>
            <div class="logView" v-if="progress[prg].log">
              <ul>
                <li v-for="l in progress[prg].log" :key="l">
                  <span>{{ l.item }}</span>
                  <span>{{ l.resultado }}</span>
                  <span>
                    <fa :icon="['fas','check']" class="icon success" v-if="l.ok"></fa>
                    <fa :icon="['fas','triangle-exclamation']" class="icon warning" v-else></fa>
                  </span>
                </li>
              </ul>
            </div>
            <a href="#" class="btn btn-primary" @click.prevent="progredir(prg)" v-if="allow[prg] && !processing">
              {{ prg != 'sync' ? 'Progredir' : '' }} {{ progress[prg].label }} &nbsp;
              <fa :icon="['far','arrow-right-long']"></fa>
            </a>
          </div>
        </div>
      </card>
    </template>
  </section>
</template>

<script>
import AcademicoService from '@/services/academico.service.js'
import { formatDateTime } from '@/services/utils.service'

export default {
  props: {
    filter: {
      type: Object
    }
  },
  data () {
    return {
      next: null,
      prev: null,
      log: null,
      loaded: false,
      processing: false,
      progress: null,
      scope: {
        periodoLetivo: {
          label: 'Período Letivo',
          apollo: {
            inicio: null,
            termino: null,
            status: false
          },
          gestor: {
            inicio: null,
            termino: null,
            status: false,
            percent: 0
          },
          log: null
        },
        series: {
          label: 'Séries',
          apollo: {
            inicio: null,
            termino: null,
            status: false
          },
          log: null
        },
        turmas: {
          label: 'Turmas',
          apollo: {
            inicio: null,
            termino: null,
            status: false
          },
          log: null
        },
        sync: {
          label: 'Sincronismo Turmas Apollo/GestorClass',
          gestor: {
            inicio: null,
            termino: null,
            status: false
          },
          log: null
        }
      },
      error: false,
      process: null,
      timer: 0,
      allow: {
        periodoLetivo: true,
        turmas: false,
        series: false,
        sync: false
      },
      sync: false
    }
  },
  watch: {
    filter () {
      this.progress = this.cloneScope()
      this.error = false

      this.$nextTick(() => {
        this.filterProgressao()
      })
    },
  },
  mounted () {
    this.progress = this.cloneScope()

    this.prev = this.filter.base
    this.next = this.filter.target

    this.filterProgressao()
  },
  methods: {
    cloneScope () {
      return Object.assign({}, JSON.parse(JSON.stringify(this.scope)))
    },
    allowProgressao ( target, last, tracker ) {

      const log = this.progress[target].log
      const logError = !log ? false : log.some(a => !a.ok)
      const successApollo = this.progress[target].apollo?.status == true
      const successGestor = this.progress[target].gestor ? ( this.progress[target].gestor?.status == true && this.progress[target].gestor?.percent == 100 ) : true
      const success = successApollo && successGestor
      const lastSuccess = !last ? true : ( this.progress[last].apollo?.status == true || this.progress[last].gestor?.status == true )

      if ( logError ) this.error = true

      if ( tracker ) return success && lastSuccess
      else {
        if ( target == 'sync' ) {
          this.allow[target] = !this.progress[target].gestor?.inicio && lastSuccess
        } else {
          this.allow[target] = this.error ? false : ( !success && lastSuccess )
        }
      }

      console.log("this.allow:", this.allow)
    },
    progredir ( target ) {
      if ( target == 'periodoLetivo' ) {
        this.periodoLetivo()
      }
      if ( target == 'series' ) {
        this.series()
      }
      if ( target == 'turmas' ) {
        this.turmas()
      }
      if ( target == 'sync' ) {
        this.sincronismo()
      }
    },
    periodoLetivo () {
      const data = {
        "perCodigo": this.prev,
        "perCodigoNovo": this.next,
        "criaPerseus": 1
      }

      console.log("data:", data)
      this.progress.periodoLetivo.apollo.status = "on"
      this.progress.periodoLetivo.gestor.status = "on"
      this.processing = true
      window.spinner.emit("open")
      
      AcademicoService.progredirPeriodoLetivo( data ).then(
        response => {
          console.log("response", response)
          this.waitProcess('periodoLetivo')
          // this.progress.periodoLetivo.apollo.status == true
          // this.progress.periodoLetivo.gestor.status == true

          // setTimeout(() => {
          //   this.$emit('onProgress')
          // },5000)

          window.spinner.emit("close")
        },
        () => {
          window.spinner.emit("close")
        }
      )
    },
    series () {
      const data = {
        "perCodigo": this.prev,
        "perCodigoNovo": this.next,
        "criaPerseus": 1
      }

      this.progress.series.apollo.status = "on"
      this.processing = true

      AcademicoService.progredirSeries( data ).then(
        response => {
          console.log("response", response)
          this.waitProcess('series')
        },
        () => {
          //
        }
      )
    },
    turmas () {
      const data = {
        "perCodigo": this.prev,
        "perCodigoNovo": this.next,
        "criaPerseus": 1
      }

      this.progress.turmas.apollo.status = "on"
      this.processing = true

      AcademicoService.progredirTurmas( data ).then(
        response => {
          console.log("response", response)
          // this.progress.turmas.apollo.status == true
          this.waitProcess('turmas')
        },
        () => {
          //
        }
      )
    },
    sincronismo () {
      const data = {
        "perCodigo": this.prev,
        "perCodigoNovo": this.next,
        "criaPerseus": 1
      }

      this.processing = true
      this.sync = true
      AcademicoService.syncApolloPerseusTurma( data ).then(
        response => {
          console.log("response", response)
          this.filterProgressao()
          // this.progress.turmas.apollo.status == true
          // this.waitProcess('sincronismo')
        },
        () => {
          //
        }
      )
    },
    filterLog () {
      console.log("log:", this.log)
      if ( this.log.length > 0 ) {
        console.log("has log:", this.log)
        this.log.map( k => {
          var src 

          if ( k.periodoLetivo == 1 ) src = 'periodoLetivo'
          if ( k.series == 1 ) src = 'series'
          if ( k.turmas == 1 && k.dataInicioApollo ) src = 'turmas'
          if ( k.turmas == 1 && k.dataInicioGestorClass ) src = 'sync'

          const hasApollo = this.progress[src].apollo
          const hasGestor = this.progress[src].gestor

          if ( hasApollo ) {
            console.log('hasApollo!!')
            this.progress[src].apollo = {
              inicio: k.dataInicioApollo,
              fim: k.dataTerminoApollo,
              status: k.finalizadoApollo == 1
            }
          }
          
          if ( hasGestor ) {
            console.log('hasGestor!!')
            this.progress[src].gestor = {
              inicio: k.dataInicioGestorClass,
              fim: k.dataTerminoGestorClss,
              status: k.finalizadoGestorClass == 1,
              percent: k.retornoPorcentagemPerseus
            }
          }

          if ( k.retornoPerseus?.itens ) {
            this.progress[src].log = k.retornoPerseus.itens
          } 
          
          this.allowProgressao(src)
        })
    
      }
      
      this.runAllow()
      console.log("this.progress", this.progress)
    },
    formatDateTime ( value ) {
      return formatDateTime(value)
    },
    filterProgressao ( wait, target ) {
      console.log("filterProgressao... wait", wait)
      console.log("filterProgressao... target", target)
      AcademicoService.getLogProgressao( this.filter.target ).then(
        response => {
          console.log('filterProgressao:', response)
          this.log = response.data
          this.loaded = true

          this.filterLog()
          if ( wait ) this.waitProcess(target)
          // this.$emit("onFilter", { prev: this.filter.base, next: this.filter.target, log: response.data })
          window.spinner.emit("close")
        },
        () => {
          // window.spinner.emit("close")
        }
      )
    },
    waitProcess ( target ) {
      if ( this.progress[target].apollo ) {
        if ( !this.progress[target].apollo.fim ) {
          this.progress[target].apollo.status = 'on'
        }
      }

      this.process = setInterval(() => {
        if ( this.timer == 5 ) {
          this.timer = 0
          clearInterval(this.process)

          if ( !this.allowProgressao(target, null, true) ) {
            this.filterProgressao(true, target)
          } else {
            this.processing = false
            this.runAllow()
          }
        } else {
          console.log("timer:", this.timer)
          this.timer ++
        }
      },1000)
    },
    runAllow () {
      Object.keys(this.progress).map( (k,i) => {
        const last = Object.keys(this.progress)[i - 1]
        this.allowProgressao(k, last)
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .section {
    margin: $mg 0 $mg_large;
  }
  .progressionBox {
    padding: $hmg;

    > div {
      margin-top: $hmg;

      .btn {
        margin-top: $hmg
      }
    }

    .progressBarWrap {
      display: flex; flex-direction: column;
      width: 100%; margin-top: $hmg;
      
      >ul {
        display: flex;
        align-items: center; align-content: center;
        justify-content: space-between;
        padding: 0; margin: 0 0 $hmg_mini 0;
        
        li {
          list-style: none;
          padding: 0; margin: 0;
        }
      }

      .progressBar { 
        width: 100%; height: 10px;
        background-color: $color-bg;
        border-radius: 3em;
        overflow: hidden;
        position: relative;

        >span {  
          display: block;
          height: 100%; width: 0;
          top: 0; left: 0;
          transition: 4s ease-out;
          border-radius: 3em;
          position: absolute;
        }

        &.apollo {
          > span {
            background-color: $color-success-2;
            
          }
          &.on {
            > span {
              animation: borealisBar 2s linear infinite;
            }
          }
        }
        &.gestor {
          > span {
            background-color: $color-success;
          }
        }
        &.finished {
          > span {
            width: 100%;
            animation: none;
          }
        }

        
      }
    }
    
    @keyframes borealisBar {
      0% {
        left:0%;
        right:100%;
        width:0%;
      }
      10% {
        left:0%;
        right:75%;
        width:25%;
      }
      90% {
        right:0%;
        left:75%;
        width:30%;
      }
      100% {
        left:100%;
        right:0%;
        width:0%;
      }
    }

    .logView {
      width: 100%; margin-top: $hmg;

      ul {
        padding: 0; margin: 0;
        width: 100%;

        li {
          list-style: none;
          padding: $hmg-mini $hmg-small; margin: 0 0 $hmg_mini 0;
          display: grid; width: 100%;
          grid-template-columns: 1.5fr 4fr .1fr;
          background-color: $color-bg;
          border-radius: $border-radius-larger;

          > span {
            line-height: 1.1;
            position: relative;

            &:last-child{
              text-align: right;
            }
            .icon {
              font-size: 16px;
              position: absolute;
              top: 50%; left: 0; right: 0;
              transform: translateY(-50%);
              margin: auto !important;
              &.success {
                color: $color-success
              }
              &.warning {
                color: $color-warning
              }
            }
          }
        }
      }
    }
  }

  .errorAlert {
    padding: $hmg;
    background-color: $color-neutro;
    border-radius: $border-radius;
    margin-bottom: $hmg;

    p {
      padding: 0; margin: 0;
    }

    &.syncMessage {
      padding: $hmg_mid; margin-bottom: 0;
      display: flex;
      align-items: center; align-content: center;

      .icon {
        font-size: 16px;
        margin-right: $hmg_small;
      }
    }
  }
</style>