<template>
  <section class="over-page">
    <main class="frame" v-if="prospect">
      <div class="sidebar">
        <h3>Prospect</h3>
        <card boxed>
          <h4>{{ prospect.nomeResponsavel }}</h4>
          <span class="dateTag" v-if="prospect.created_at" title="Criado em">
            <fa :icon="['fas','calendar']"/>
            <p>{{ formatDate(prospect.created_at) }}</p>
          </span>
          <span class="dateTag" v-if="prospect.dataGanho" title="Ganho em">
            <fa :icon="['fas','calendar-star']"/>
            <p>{{ formatDate(prospect.dataGanho) }}</p>
          </span>
          <span class="dateTag" v-if="prospect.dataPerda" title="Perdido em">
            <fa :icon="['fas','calendar-xmark']"/>
            <p>{{ formatDate(prospect.dataPerda) }}</p>
          </span>
          
          <footer>
            <a href="#" class="btn btn-primary" @click.prevent="toggleWin(prospect.idProspect)">
              Ganhar Lead
            </a>
          </footer>
        </card>
        <card boxed>
          <div class="qrcodeWrap">
            <h5>Pesquisa de satisfação pós-visita</h5>
            <div class="qrcode-nps">
              <vue-qrcode
                :value="nps()"
                :options="{
                  width: 200,
                  color: { dark: '#444' },
                }"
              ></vue-qrcode>  
            </div>
            <p>Aponte a câmera do celular para o QRCode para acessar a pesquisa.</p>
          </div>
        </card>
      </div>
      <div class="content">
        <card :listed="false" overflow="visible">
          <header>
            <div>
              <h5>Prospect {{ prospect.idProspect }}</h5>
              <div class="tag-block">
                <span :class="prospect.status">{{ status[prospect.status] }}</span>
              </div>
            </div>
          </header>
          <view-controller :scope="prospect"/>
          <!-- <criar-editar :id="id" @onCancel="cancel" @onSave="save"></criar-editar> -->
        </card>
      </div>
    </main>
    <confirm 
      :backdrop="false"
      class="confirm"
      ref="confirm"
    ></confirm>
  </section>
</template>

<script>
import CaptacaoService from '@/services/captacao.service.js'
import ViewController from '@/components/captacao/form/View.vue'
import  { formatDate } from '@/services/utils.service.js'
import VueQrcode from '@chenfengyuan/vue-qrcode'

export default {
  components: {
    ViewController, VueQrcode
  },
  props: {
    idProspect: {
      type: [String, Number]
    }
  },
  data () {
    return {
      prospect: null,
      status: {
        open: 'Em Aberto',
        won: 'Ganho',
        lost: 'Perdido'
      }
    }
  },
  mounted () {
    this.getProspect()
  },
  methods: {
    getProspect () {
      CaptacaoService.getProspect(this.idProspect).then(
        response => {
          this.prospect = response.data
          console.log('getProspect:', response.data)
        },
        () => {
          
        }
      )
    },
    formatDate ( date ) {
      return formatDate(date)
    },
    toggleWin ( lead ) {
      console.log("lead:", lead)
      this.$refs.confirm.run({
        message: "Tem certeza que deseja ganhar este prospect?",
        confirmButton: "Sim",
        denyButton: "Não",
        data: {
          lead
        },
        callback: this.win,
      })
    },
    win ( response, data ) {
      if ( response ) {
        CaptacaoService.ganharLead( data.lead ).then(
          response => {
            if ( response.status == 200 ) {
              // this.leads[data.index].status = 'won'
              window.toaster.emit('open', {style: "success", message: "Tudo certo! Prospect ganho com sucesso.", floater: true})

              setTimeout(() => {
                // this.$router.push({ name: 'Leads', params: { action: 'ver', idProspect: data.lead }})
                this.getProspect()
              },1000 )
            }
          },
          error => {
            var msg = 'Por favor, tente novamente mais tarde. Se o problema persistir, procure nosso time de suporte.'
            const errMsg = error.response.data.error
            console.log( 'errMsg:', errMsg )
            const drop = errMsg ?? msg
            window.toaster.emit('open', {style: "error", message: "Ops! Algo acontece. Não foi possível remover este prospect. " + drop, floater: true})
          }
        )
      }
    },
    nps () {
      if ( this.idProspect ) {
        return "https://apollo.rededecisao.com/nps/resposta?campanha=3&idProspect=" + this.idProspect
      }
      
    }
  }
}
</script>

<style lang="scss" scoped>
  main {
    display: flex; align-items: flex-start; align-content: center;
    gap: $hmg_mid;
    padding: $mg;

    @media screen and ( max-width: 1024px ) {
      flex-direction: column;
      padding: $hmg_mid
    }

    .sidebar {
      width: 320px;
      position: sticky; top: $mg;

      @media screen and ( max-width: 1024px ) {
        width: 100%;
      }
      
      h3 {
        padding: $hmg_mid $hmg_mid $mg_mini $hmg_mid;
        margin-bottom: $hmg_mid;
      }
      
      p {
        margin: 0; padding: 0;
      }

      footer {
        margin-top: $hmg_mid;
      }
    }
    .content {
      flex: 1;

      header {
        padding: $hmg_mid;
        border-bottom: $border-component;

        >div {
          display: block;
          margin-bottom: $hmg;
          display: flex;
          align-content: center;
          justify-content: space-between;       
        }

        .tag-block {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: $mg_mini;

          span {
            padding: $hmg_mini $mg_mini;
            border-radius: 2em;
            background-color: $color-bg;
            white-space: nowrap;
            color: $color-secondary;

            &.open {
              background-color: $color-alert;
            }
            &.won {
              background-color: $color-success-2;
            }
            &.lost {
              background-color: $color-error;
            }
          }
        }
      }
    }
  }

  .dateTag {
    display: flex; align-items: center;
    line-height: 1;
    gap: $hmg_mini;
    margin-top: $hmg_mini;

    p {
      padding: 0; margin: 0;
    }
  }

  .qrcodeWrap {
    h5 {
      margin: 0;
      padding: 0;
    }
    p {
      margin: 0;
      padding: 0;
    }

    .qrcode-nps {
      padding: $mg_mini 0;

      canvas {
        display: block;
        margin: auto;
      }
    }
  }
  
</style>