<template>
  <header>
    <div>
      <toggle-assistent @onEdit="edit" @onSelect="select" @onCreate="create" :assistants="assistants" :active="active" />
    </div>
    <div>
      
    </div>
  </header>
</template>

<script>
import ToggleAssistent from '@/components/ferramentas/assistentes/utilities/ToggleAssistentes.vue'

export default {
  components: {
    ToggleAssistent
  },
  props: {
    assistants: {
      type: Array
    },
    active: {
      type: Object
    }
  },
  data () {
    return {

    }
  },
  mounted () {

  },
  methods: {
    select ( assistant ) {
      this.$emit("onSelect", assistant)
    },
    edit ( assistant ) {
      this.$emit("onEdit", assistant)
    },
    create () {
      this.$emit("onCreate")
    }
  }
}
</script>

<style lang="scss" scoped>
  header {
    display: flex; align-items: center;
    justify-content: space-between;
    height: 62px;
    border-bottom: $border-component;
    position: sticky; top: 0; z-index: 100;
  }
</style>