<template>
  <div class="page-inner">
      <tabs @change="emitTabChange" :active="section"></tabs>
      <section class="fullcomp-wrap">
        <div class="fullcomp-sidebar navigation">
          <div>
            <steps :steps=" section === 'parametros' ? steps.params : steps.match " :navigate="true" :trackCheck="false" @go="toggleView"></steps>
          </div>
        </div>
        <div class="fullcomp-content" v-if="activeStep">
          <composicao v-if="activeStep.slug === 'composicao' "></composicao>
          <segmentacao v-if="activeStep.slug === 'segmentacao' "></segmentacao>
          <categorias v-if="activeStep.slug === 'categorias' "></categorias>
          <produtos v-if="activeStep.slug === 'produtos'" :mid="mid" :target="target"></produtos>
          <fornecedores v-if="activeStep.slug === 'fornecedores'"></fornecedores>
        </div>
        <!-- <div class="fullcomp-sidebar navigation">
          
        </div> -->
      </section>
  </div>
</template>

<script>
import Steps from '@/components/marketplace/parametrizacao/navigation/Steps.vue'
import Tabs from '@/components/marketplace/parametrizacao/navigation/Tabs.vue'
import Composicao from '@/components/marketplace/parametrizacao/form/Composicao.vue'
import Segmentacao from '@/components/marketplace/parametrizacao/form/Segmentacao.vue'
import Categorias from '@/components/marketplace/parametrizacao/form/Categorias.vue'
import Produtos from '@/components/marketplace/parametrizacao/form/Produtos.vue'
import Fornecedores from '@/components/marketplace/parametrizacao/form/Fornecedores.vue'

export default {
  components: {
    Steps, Tabs, Composicao, Categorias, Produtos, Fornecedores, Segmentacao
  },
  props: {
    action: {
      type: String,
    },
    section: {
      type: String,
    },
    mid: {
      type: String
    },
    target: {
      type: String
    }
  },
  data () {
    return {
      steps: 
      {
        params: [
          {
            index: 0,
            label: 'Categorias',
            slug: 'categorias',
            icon: ['fas','warehouse-full'],
            done: false,
            alert: false,
            active: true,
            locked: false,
            saved: false
          },
          {
            index: 1,
            label: 'Subcategorias',
            slug: 'subcategorias',
            icon: ['fas','boxes-stacked'],
            done: false,
            alert: false,
            active: false,
            locked: false,
            saved: false
          },
          {
            index: 2,
            label: 'Fornecedores',
            slug: 'fornecedores',
            icon: ['fas','person-carry-box'],
            done: false,
            alert: false,
            active: false,
            locked: false,
            saved: false
          },
          {
            index: 3,
            label: 'Recebedores',
            slug: 'recebedores',
            icon: ['fas','money-bill-transfer'],
            done: false,
            alert: false,
            active: false,
            locked: false,
            saved: false
          },
          // {
          //   index: 4,
          //   label: 'Imagens',
          //   slug: 'imagens',
          //   icon: ['fas','image'],
          //   done: false,
          //   alert: false,
          //   active: false,
          //   locked: false,
          //   saved: false
          // },
          {
            index: 4,
            label: 'Produtos',
            slug: 'produtos',
            icon: ['fas','gift'],
            done: false,
            alert: false,
            active: false,
            locked: false,
            saved: false
          },
          // {
          //   index: 6,
          //   label: 'Itens',
          //   slug: 'itens',
          //   icon: ['fas','box-open-full'],
          //   done: false,
          //   alert: false,
          //   active: false,
          //   locked: false,
          //   saved: false
          // },
          // {
          //   index: 7,
          //   label: 'Calendários',
          //   slug: 'calendarios',
          //   icon: ['fas','calendar-days'],
          //   done: false,
          //   alert: false,
          //   active: false,
          //   locked: false,
          //   saved: false
          // },
          // {
          //   index: 8,
          //   label: 'Grades',
          //   slug: 'grades',
          //   icon: ['fas','shirt-running'],
          //   done: false,
          //   alert: false,
          //   active: false,
          //   locked: false,
          //   saved: false
          // },
          // {
          //   index: 9,
          //   label: 'Formas de Pagamento',
          //   slug: 'formas-pagamento',
          //   icon: ['fas','credit-card'],
          //   done: false,
          //   alert: false,
          //   active: false,
          //   locked: false,
          //   saved: false
          // }
        ],
        match: [
          {
            index: 0,
            label: 'Composição',
            slug: 'composicao',
            icon: ['fas','calendar-lines-pen'],
            done: false,
            alert: false,
            active: true,
            locked: false,
            saved: false
          },
          // {
          //   index: 1,
          //   label: 'Grade vs Produto',
          //   slug: 'grade-produto',
          //   icon: ['fas','shirt'],
          //   done: false,
          //   alert: false,
          //   active: false,
          //   locked: false,
          //   saved: false
          // },
          // {
          //   index: 2,
          //   label: 'Subcategoria vs Produto',
          //   slug: 'subcategoria-produto',
          //   icon: ['fas','boxes-stacked'],
          //   done: false,
          //   alert: false,
          //   active: false,
          //   locked: false,
          //   saved: false
          // },
          // {
          //   index: 3,
          //   label: 'Categoria vs Recebedor vs Unidade',
          //   slug: 'categoria-recebedor-unidade',
          //   icon: ['fas','cart-flatbed-boxes'],
          //   done: false,
          //   alert: false,
          //   active: false,
          //   locked: false,
          //   saved: false
          // },
          // {
          //   index: 4,
          //   label: 'Formas de Pagamento vs Produto',
          //   slug: 'formas-pagamento-produto',
          //   icon: ['fas','box-dollar'],
          //   done: false,
          //   alert: false,
          //   active: false,
          //   locked: false,
          //   saved: false
          // },
          {
            index: 1,
            label: 'Segmentação',
            slug: 'segmentacao',
            icon: ['fas','box-heart'],
            done: false,
            alert: false,
            active: false,
            locked: false,
            saved: false
          },
          // {
          //   index: 6,
          //   label: 'Segmento vs Turmas',
          //   slug: 'segmento-turmas',
          //   icon: ['fas','school-circle-check'],
          //   done: false,
          //   alert: false,
          //   active: false,
          //   locked: false,
          //   saved: false
          // }
        ]
      },
      activeStep: null
    }
  },
  watch: {
    section ( newValue, oldValue ) {
      if ( newValue != oldValue && this.action ) {
        const target = this.getStepBySlug(this.action)
        console.log('watch - target:', target)
        this.go(target)
      }
    }
  },
  beforeMount () {
    if ( !this.action ) {
      const firstAction = this.getStepBySlug(this.getFirstAction())
      this.activeStep = firstAction
      this.$router.push({name: 'Parametrização Shop', params: { section: this.section, action: firstAction.slug }})
    }
  },
  mounted () {
    if ( this.action ) {
      // console.log("mounted:", this.action)
      const firstAction = this.getStepBySlug(this.getFirstAction())
      this.activeStep = firstAction
      const target = this.getStepBySlug(this.action)
      // console.log("target:", target)
      this.go(target)
    }
  },
  methods: {
    go ( where ) {
      console.log("go:", where)
      const index = where.index

      console.log("index:", index)
      console.log("activeStep:", this.activeStep)
      if ( this.activeStep ) {
        this.filterSteps()[this.activeStep.index].active = false
      }
      
      this.activeStep = where
      this.filterSteps()[index].active = true
      
    },
    toggleView ( where ) {
      this.$router.push({name: 'Parametrização Shop', params: { section: this.section, action: where.slug }})
      this.go(where)
    },
    filterSteps () {
      return this.section === 'parametros' ? this.steps.params : this.steps.match
    },
    emitTabChange ( target ) {
      const firstAction = this.getFirstAction(target)
      this.$router.push({name: 'Parametrização Shop', params: { section: target, action: firstAction }})
    },
    getFirstAction ( section ) {
      // console.log("getFirstAction:", this.section)
      const target = section ? section : this.section
      return target === 'parametros' ? this.steps.params[0].slug : this.steps.match[0].slug
    },
    getStepBySlug ( slug ) {
      const src = this.filterSteps()
      const response = src.filter( k => {
        return k.slug == slug
      })

      return response.length > 0 ? response[0] : []
    }
  }
}
</script>

<style lang="scss" scoped>
  .page-inner {
    @media screen and ( max-width: 640px) {
      padding: $hmg 0 !important;
    }

    .page-inner {
      header {
        // padding: $hmg_small $hmg 0;
      }
    }
  }
  
</style>


