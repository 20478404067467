<template>
  <section class="featuresComm frame">
    <div v-for="ad in ads" :key="ad">
      <card>
        <a href="#" target="_self" :style="{backgroundImage: 'url(' + ad.image + ')'}" @click.prevent="ad.action" v-if="ad.action"></a>
        <a :href="ad.url" target="_self" :style="{backgroundImage: 'url(' + ad.image + ')'}" v-else></a>
      </card>
    </div>
    <!-- <modal ref="modal">
      <h3 class="color-text">Visando melhorar a experiência de compra,</h3>
      <br>
      <p>a categoria de uniformes entrará em manutenção entre <b>23/02</b> e <b>14/03</b>.</p>
      <p>Se você já comprou seu uniforme, fique tranquilo, entregaremos todos os pedidos feitos até <b>23/02</b>. E se você ainda não comprou, também está tudo certo, <b>vamos continuar liberando a entrada de alunos que ainda não tem uniforme</b>.</p>
      <p>E se você <b>precisar trocar</b>, não se preocupe, o processo de troca e devolução continuará funcionando normalmente.</p>
      <p><b>Agradecemos a compreensão</b></p>
      <h4>Time Rede Decisão</h4>
      <br>
      <a class="btn btn-primary" href="#" @click.prevent="closeModal()">Ok. Entendi!</a>
    </modal> -->
  </section>
</template>
<script>
// import Modal from "@/components/utilities/Modal"

export default {
  name: 'Featured',
  components: {
    // Modal
  },
  data () {
    return {
      ads: [
        {
          title: "Uniformes",
          text: "",
          image: process.env.VUE_APP_API_URL_PUBLIC + "marketplace/comunicacao/destaque_1.jpg",
          url: "/loja/categoria/5/Uniformes",
          // action: this.dropAlert
        },
        {
          title: "Material Didático",
          text: "",
          image: process.env.VUE_APP_API_URL_PUBLIC + "marketplace/comunicacao/destaque_2.jpg",
          url: "/loja/categoria/1/Material%20Didático"
        }
      ],
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
      campinas: [29,31]
    }
  },
  mounted () {
    var unidades =  []
    this.currentUser?.alunos?.map( k => {
      unidades.push(k.idUnidade)
    })
    
    // if ( unidades.includes(27) ) {
    //   const ads = this.ads.filter( k => {
    //     return k.title != "Material Didático"
    //   })
    //   this.ads = ads
    // } else if ( unidades.some(a => this.campinas.includes(a)) ) {
    //   let ads = this.ads.filter( k => {
    //     return k.title != "Uniformes"
    //   })
    //   this.ads = ads
    // }

    // if ( unidades.includes(27) ) {
    //   const ads = this.ads.filter( k => {
    //     return k.title != "Material Didático"
    //   })
    //   this.ads = ads
    // }
  },
  methods: {
    dropAlert () {
      const options = {
        title: 'Compra de Uniformes Indisponível',
        titleAlign: "center",
        // message: "Se quiser uma mensagem...", //não obrigatório
        size: "mid", //small, mid, large, full
        backdrop: true,
        backdropColor: "transparent",  //se o backdrop for false, não precisa
        backdropClose: true, //se o backdrop for false, não precisa
        fullHeight: false
      }
      
      this.$refs.modal.open(options)
    },
    closeModal () {
      this.$refs.modal.close()
    }
  }
}
</script>
<style lang="scss">
  .featuresComm {
    display: flex; margin-top: -75px !important;
    align-items: stretch; align-content: center; justify-content: center;
    position: relative; z-index: 1000; 
    padding: $hmg;
    
    > div {
      width: 50%; height: 300px;
      border-radius: 30px;
      padding: $hmg;

      > section {
        width: 100%; height: 100%;

        > .card-controller {
          width: 100%; height: 100%;

          a {
            display: block; width: 100%; height: 100%;
            background-position: center;
            background-position: $color-secondary;
            background-repeat: no-repeat;
            background-size: cover;
          }
        }
      }
    }

    @media screen and ( max-width: 1024px ) {
      flex-wrap: wrap;

      > div {
        width: 100%; height: 150px;
        padding: 0;

        &:first-child {
          margin-bottom: $hmg;
        }
      }
    }
  }
</style>