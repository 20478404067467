<template>
  <header>
    <div>
      <h5 :class="type.slug">{{ type.single }} {{ id }}</h5>
      <div class="tag-block">
        <span>{{ atividade[target].unidade }}</span>
        <span :class="trackStatus().slug">{{ trackStatus().label }}</span>
        <span> {{ 
          fromNow(atividade[target].created_at) 
        }}</span>
      </div>
    </div>
    <div class="atividade-timetrack">
      <span>
        <p>Data Ocorrência</p>
        <h5>
          {{ formatDate(atividade[target].dataOcorrencia) }}
        </h5>
      </span>
      <span>
        <p>Data Limite de Atendimento</p>
        <h5>
          {{ formatDate(atividade[target].dataLimite) }}
        </h5>
      </span>
      <span>
        <p>Data de Expiração</p>
        <h5>
          {{ formatDate(atividade[target].dataExpiracao) }}
        </h5>
      </span>
    </div>
    <div>
      <div class="avatar">
        <span :style="{ backgroundImage: `url('/img/apollinho.png')` }"></span>
      </div>
      <h4>Instruções</h4>
      <p>{{ atividade[target].instrucoes }}</p>
    </div>
    <div v-if="atividade[target].observacoes">
      <h4>Observações</h4>
      <p>{{ atividade[target].observacoes }}</p>
    </div>
    <div v-if="atividade[target].linkExterno">
      <h4>Link Externo</h4>
      <a :href="atividade[target].linkExterno" target="_blank" class="linkExterno">{{ atividade[target].linkExterno }}</a>
    </div>
  </header>
</template>

<script>

import moment from 'moment'
import { fromNow, formatDate } from '@/services/utils.service.js'

export default {
  props: {
    id: {
      type: [Number, String]
    },
    type: {
      type: [Object]
    },
    target: {
      type: String
    },
    atividade: {
      type: Object
    }
  },
  data () {
    return {
      
    }
  },
  mounted () {
    console.log("atividade:", this.atividade)
  },
  methods: {
    trackStatus () {
      var status = {
        label: 'Pendente',
        slug: 'pendente'
      }

      if ( this.atividade[this.target].emAndamento ) {
        status.label = 'Em Andamento'
        status.slug = 'andamento'
      }
      if ( this.atividade[this.target].realizado ) {
        status.label = "Realizado"
        status.slug = 'realizado'
      }
      
      return status
    },
    fromNow ( timestamp ) {
      const time =  moment(timestamp).format("YYYY-MM-DD HH:mm:ss")

      return fromNow(time)
    },
    formatDate ( date ) {
      return formatDate(date)
    }
  }
}
</script>

<style lang="scss" scoped>
  header {
    padding: $hmg_mid;
    border-bottom: $border-component;

    > div {
      display: block;
      margin-bottom: $hmg;

      &:first-child {
        display: flex;
        align-content: center;
        justify-content: space-between;        
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.atividade-timetrack {
        display: flex;
        align-content: center;
        justify-content: flex-start;
        gap: $mg;

        @media screen and ( max-width: 1024px ) {
          flex-wrap: wrap;
        }
      }
    }
    
    h5 {
      margin: 0; padding: 0;
      text-transform: capitalize;

      &.atendimentos { color: $color-atendimentos }
      &.tarefas { color: $color-tarefas }
      &.tickets { color: $color-tickets }
      &.solicitacoes { color: $color-solicitacoes }
      &.prospects { color: $color-prospects }
    }
    p {
      margin: 0; padding: 0
    }
  }

  .tag-block {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: $mg_mini;

    span {
      padding: $hmg_mini $mg_mini;
      border-radius: 2em;
      background-color: $color-bg;
      white-space: nowrap;
      
      &.pendente { background-color: $color-pendente; color: $color-secondary }
      &.andamento { background-color: $color-andamento; color: $color-secondary }
      &.finalizado { background-color: $color-finalizado; color: $color-secondary }
    }

    @media screen and ( max-width: 1024px ) {
      flex-wrap: wrap;
      
      span {}
    }
  }

  .linkExterno { 
    &:hover {
      color: $color-primary
    }
  }

  .avatar {
    margin: $hmg_mid 0 $mg_mini;
    width: 50px;
    height: 50px;
    
    >span {
      display: block;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      background-size: cover; background-position: center;
      background-repeat: no-repeat;
      background-color: $color-bg;
      image-rendering: -webkit-high-quality;
      image-rendering: -webkit-optimize-contrast;
    }
  }
</style>