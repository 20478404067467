<template>
  <section>
    <header>
      <h5>
        Filtrar
      </h5>
    </header>
    <div class="frame fixed">
      <div class="row no-padd" ref="filterForm">
        <div class="col-md-6 form-group">
          <label>Unidade</label>
          <select-control 
            v-model="filter.unidade" 
            name="unidade" 
            :options="options.unidades" 
            placeholder="Selecione" 
            @change="getPeriodosLetivos()" 
            :disabled="loading"
            required
          />
        </div>
      </div>
      <div class="row no-padd" ref="filterForm">
        <div class="col-md-4 form-group">
          <label>Período Letivo Origem</label>
          <select-control 
            v-model="filter.periodoLetivoOrigem" 
            :options="options.periodosLetivos" 
            @change="trackSimilar()" 
            placeholder="Selecione" 
            :disabled="loading || !filter.unidade"
            required
          />
        </div>
        <div class="col-md-4 form-group">
          <label>Período Letivo Destino</label>
          <select-control 
            v-model="filter.periodoLetivoDestino" 
            :options="options.periodosLetivos" 
            placeholder="Selecione" 
            @change="trackSimilar()" 
            :disabled="loading || !filter.unidade"
            required
          />
        </div>
      </div>
      <div class="col-md-12 form-actions form-group">
        <a href="#" @click.prevent="runFilter()" class="btn btn-primary">
          Buscar
        </a>
        <a href="#" @click.prevent="clearFilter()" class="btn btn-secondary">
          Limpar
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import UserService from '@/services/user.service.js'
import AcademicoService from '@/services/academico.service.js'
import FormService from '@/services/form.service.js'
import { createSelectOptions } from '@/services/utils.service.js'


export default {
  data () {
    return {
      filter: {
        unidade: null,
        periodoLetivoOrigem: null,
        periodoLetivoDestino: null,
      },
      wait: {
        unidades: false,
      },
      options: {
        unidades: [],
        periodosLetivos: []
      },
      valid: {
        unidade: true,
        periodoLetivoOrigem: true,
        periodoLetivoDestino: true,
      },
      series: [],
      loading: false
    }
  },
  mounted () {
    this.getUnidades()

    window.spinner.emit("open")
  },
  methods: {
    getUnidades () {
      UserService.getMinhasUnidades().then(
        response => {
          console.log("getMinhasUnidades:", response.data)
          this.options.series = []
          this.options.periodosLetivos = []
          
          if ( response.data.length > 0 ) {
            this.options.unidades = createSelectOptions(response.data, 'idUnidade' ,'descricao')
            this.wait.unidades = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhuma unidade encontrada. Verifique sua conexão e tente novamente.", floater: true})
          }
          
          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma unidade encontrada. Verifique sua conexão e tente novamente.", floater: true})
        }
      )
    },
    getPeriodosLetivos ( ) {
      this.loading = true
      AcademicoService.getPeriodosLetivos(this.filter.unidade).then(
        response => {
          console.log("getPeriodosLetivos:", response.data)
          
          if ( response.data.length > 0 ) {
            this.options.periodosLetivos = createSelectOptions(response.data, 'idPeriodoLetivo' ,'descricao').reverse()
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum período letivo encontrado para esta unidade. Isto parece ser um problema de parametrização.", floater: true})
          }
          this.loading = false
        },
        error => {
          console.error(error)
          this.loading = false
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum período letivo encontrado para esta unidade. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    finishLoad () {
      const finished = !Object.values(this.wait).some( a => !a)

      if ( finished ) {
        window.spinner.emit("close")
        this.loading = false
      }
      // console.log("finished:", finished)

    },
    clearFilter () {
      this.filter = {
        unidade: null,
        periodoLetivoOrigem: null,
        periodoLetivoDestino: null
      }
      
      this.$emit('clear')
      
    },
    trackSimilar () {
      if ( this.filter.periodoLetivoOrigem == this.filter.periodoLetivoDestino ) {
        window.toaster.emit('open', {style: "error", message: "Ops. você não pode selecionar o mesmo período letivo para indicar a próxima turma", floater: true})
        this.filter.periodoLetivoDestino = null
      }
    },
    runFilter () {
      const valid = FormService.validate(this.$refs.filterForm, this.valid)
      console.log("valid:", valid)
      
      if ( valid.response ) {
        this.$emit('onFilter', this.filter)
      }
     
    }
  }
}
</script>

<style scoped lang="scss">
  header {
    margin: $hmg 0;
    padding: 0 $hmg_small;

    h5 {
      margin: 0 0 $hmg_mini 0; padding: 0;
    }
    p {
      margin: 0; padding: 0;
    }
  }

  // .frame {
  //   padding: 0 $hmg_small
  // }
  .form-actions {
    margin-top: $hmg !important
  }
</style>