<template>
  <section class="itemsListWrap">
    <!-- <header>
      <h4>Filtrar</h4>
      <div class="row nop nom">
        <div class="col-md-3 form-group">
          <input-control 
            label="Nome"
            v-model="filter.nome" 
            placeholder="Ex. Gatilho Intervenção" 
            name="nome"
            type="text"
          />
        </div>
        <div class="col-md-2 col-sm-3 form-group no-padd-left">
          <select-control 
            label="Público"
            v-model="filter.publico"
            :options="options.publicos"
            placeholder="Selecione" 
            name="publico"
          />
        </div>
        <div class="col-md-2 col-sm-3 form-group no-padd-left">
          <select-control 
            label="Plataforma"
            v-model="filter.plataforma"
            :options="options.plataformas"
            placeholder="Selecione" 
            name="plataforma"
          />
        </div>
        <div class="col-md-2 col-sm-3 form-group no-padd-left">
          <select-control 
            label="Recorrência"
            v-model="filter.recorrencia"
            :options="options.recorrencias"
            placeholder="Selecione" 
            name="recorrencia" 
          />
        </div>
        <div class="col-md-1 form-group no-padd-left">
          <label>&nbsp;</label>
          <div class="switchWrap">
            <switch-control v-model:checked="filter.ativo" labelOn="Apenas ativos" labelOff="Apenas ativos" invert></switch-control>
          </div>
        </div>
        <div class="col-md-1 form-group no-padd-left">
          <label>&nbsp;</label>
          <div class="switchWrap">
            <switch-control v-model:checked="filter.teste" labelOn="Apenas Testes" labelOff="Apenas Testes" invert></switch-control>
          </div>
        </div>
      </div>
      <footer>
        <a href="" class="btn btn-primary" @click.prevent="getGatilhos()">
          Filtrar
        </a>
        <a href="" class="btn btn-secondary" @click.prevent="clearFilter()">
          Limpar
        </a>
      </footer>
    </header> -->
    <form-spinner v-if="loading"/>
    <div class="listWrap" v-if="!loading">
      <div v-for="(list, key) in gatilhos" :key="key">
        <h3>{{ getDeptName(key) }}</h3>
        <ol class="itemsList">
          <li>
            <div class="itemsListInner has-action">
              <span>Ativo</span>
              <span>Nome</span>
              <span>Descrição</span>
              <span>Diretoria</span>
              <span>Tipo</span>
              <span>Tag Endpoint</span>
              <span>Cron</span>
              <span>Event Bridge</span>
              <span></span>
            </div>
          </li>
          <li v-for="gatilho in list" :key="gatilho">
            <card spacing="small">
              <div class="itemsListInner has-action">
                <span class="toggleWrap">
                  <switch-control v-model:checked="gatilho.status" @change="toggleGatilhoAtivo(gatilho)" :disabled="gatilho.loading"></switch-control>
                </span>
                <span>
                  {{ gatilho.nome }}
                </span>
                <span>
                  {{ gatilho.descricao }}
                </span>
                <span>
                  {{ gatilho.departamento.nome }}
                </span>
                <span>
                  {{ gatilho.tipo }}
                </span>
                <span>
                  {{ gatilho.endpointTag }}
                </span>
                <span>
                  {{ humanizeCron(gatilho.cronSchedule) }}
                </span>
                <span>
                  {{ gatilho.eventBridgeId ? 'Sim' : 'Não' }}
                </span>
                <span class="itemsListActions">
                  <input-spinner v-if="gatilho.loading"></input-spinner>
                  <template v-else>
                    <!-- <a href="#" @click.prevent="confirmCreateIntervencoes(gatilho)" class="btn btn-primary btn-small">
                      Rodar
                    </a> -->
                    <!-- <a href="#" @click.prevent="getIntervencoes(gatilho)" title="Sincronizar Intervenções">
                      <fa :icon="['far','arrows-rotate']"></fa>
                    </a> -->
                    <a href="#" @click.prevent="toggleEdit(gatilho)" title="Editar Intervenção">
                      <fa :icon="['far','pencil']"></fa>
                    </a>
                    <a href="#" @click.prevent="confirmRemove(gatilho)" title="Remover Intervenção">
                      <fa :icon="['far','trash-can']"></fa>
                    </a>
                  </template>
                  
                </span>
              </div>
            </card>
          </li>
        </ol>
      </div>
      
    </div>
    <confirm 
      :backdrop="false"
      class="confirm"
      ref="confirm"
    ></confirm>
  </section>
</template>

<script>
import FerramentasService from '@/services/ferramentas.service.js'
import InputSpinner from '@/components/utilities/InputSpinner.vue'
import FormSpinner from '@/components/utilities/FormSpinner.vue'
import AdmService from '@/services/adm.service.js'
import { createSelectOptions } from '@/services/utils.service.js'
import cronstrue from 'cronstrue/i18n'

export default {
  components: {
    InputSpinner, FormSpinner
  },
  data () {
    return {
      gatilhos: [],
      loading: false,
      options: {
        departamentos: []
      },
      valores: {
        Blip: .35,
        SMS: .08,
        Email: .09
      }
    }
  },
  mounted () {
    this.getDepartamentos()
  },
  methods: {
    getDepartamentos () {
      this.loading = true

      AdmService.getDepartamentos().then(
        response => {
          this.options.departamentos = createSelectOptions(response.data, 'idDepartamento','nome')
          console.log("departamentos:", response.data)
          this.getGatilhosDados()
        },
        error => {
          this.loading = false
          console.error(error)
        }
      )
    },
    getGatilhosDados () {
      FerramentasService.getGatilhosDados().then(
        response => {
          console.log("getGatilhos:", response)
          if ( response.data.length > 0 ) {
            response.data.map( k => {
              k.loading = false
              k.departamento = this.options.departamentos.filter( a => a.value == k.idDepartamento)[0]

              const cronSchedule = k.cronSchedule.split(" ")
              const cron = {
                minute: cronSchedule[0],
                hour: cronSchedule[1],
                day: cronSchedule[2],
                month: cronSchedule[3],
                day_of_week: cronSchedule[4],
                year : cronSchedule[5]
              }
              
              k.frequencia = cron
            })
            
            // this.gatilhos = response.data
            this.groupByDepartment(response.data)
            
          } else {
            window.toaster.emit('open', {style: "warning", message: "Não encontramos gatilhos.", floater: true})
          }
          
          // window.spinner.emit("close")
          this.loading = false
        },
        error => {
          console.log("error:", error)
          this.loading = false
          // window.spinner.emit("close")
          const message = error.response.data.error ? error.response.data.error : 'Desconhecido'

          window.toaster.emit('open', {
            style: "warning", 
            message: "Ops. Algo aconteceu. Não conseguimos encontrar gatilhos no momento. Tente novamente, se o erro persistir, procure o suporte. Err:" + message, 
            floater: true
          })
        }
      )
    },
    groupByDepartment ( gatilhos ) {
      const groupedByDepartamento = gatilhos.reduce((acc, item) => {
        const { idDepartamento } = item

        // If the department doesn't exist in the accumulator, create a new array
        if (!acc[idDepartamento]) {
          acc[idDepartamento] = []
        }

        // Add the current item to the appropriate department
        acc[idDepartamento].push(item);

        return acc
      }, {})

      this.gatilhos = groupedByDepartamento
      console.log("this.gatilhos:", this.gatilhos)
    },
    getDeptName ( id ) {
      return this.options.departamentos.filter( a => a.value == id)[0].nome
    },
    humanizeCron( cronExpression ) {
      const standardCronExpression = cronExpression.split(' ').slice(0, 5).join(' ')
      return cronstrue.toString(standardCronExpression, { use24HourTimeFormat: true, locale: 'pt_BR' })
    },
    toggleEdit ( gatilho ) {
      this.$emit('edit', gatilho)
      // console.log("index:", index)
    },
    confirmRemove ( gatilho ) {
      this.$refs.confirm.run({
        message: "Tem certeza que deseja remover este item?",
        confirmButton: "Sim",
        denyButton: "Não",
        data: gatilho,
        callback: this.remove,
      })
    },
    remove ( response, gatilho ) {
      if ( response ) {
        window.spinner.emit("open")
        this.$emit('remove', gatilho)
      }
    },
    toggleGatilhoAtivo ( gatilho ) {
      gatilho.loading = true
      this.$emit('activate', gatilho)
    },
    closeList ( index ) {
      this.gatilhos[index].intervencoes = null
    },
    clearFilter () {
      this.filter = {
        nome: null,
        ativo: null,
        recorrencia: null,
        plataforma: null,
        publico: null,
        teste: null
      }
    }
  }
}
</script>

<style lang="scss" scoped>

  .itemsListWrap {
    padding: 0;

    >header {
      border-bottom: $border-component;
      padding: $mg_mini;

      h4 {
        padding: $mg_mini
      }
    }

    .listWrap {
      padding: $hmg_mid $hmg_mid $mg_large $hmg_mid;

      h3 {
        padding: $hmg_mid $hmg_mid 0 $hmg_mid
      }
    }

    footer {
      padding: 0 $mg_mini $mg_mini $mg_mini;
      display: flex; align-items: center;
      gap: $hmg_small;
    }
  }

    .itemsList {
      display: block;
      width: 100%;
      padding: 0; margin: 0;

      li {
        list-style: none;
        width: 100%; 
        padding: 0; margin: 0;

        .itemsListInner {
          display: grid; width: 100%;
          padding: $mg_mini $hmg_small;
          align-items: center; align-content: center;
          grid-template-columns: 3fr 2fr 1fr;

          &.has-action {
            grid-template-columns: .3fr 2fr 3fr 1.5fr 1fr 2fr 1.5fr .5fr 1.5fr;
          }
          
          > span {
            display: flex; align-items: center;
            padding: 0 $hmg_mini;

            &.toggleWrap {
              padding: 0
            }

            &.actionWrap {
              justify-content: flex-end;
            }

            .syncIcon {
              display: flex; flex-direction: column;
              align-content: center; align-items: center; justify-content: center;
              width: 28px; height: 28px;
              background-color: $color-success-2;
              padding: 0; margin: 0;
              line-height: 1;
              color: $color-secondary;
              border-radius: 100%;
              text-align: center;
            }

            .fixed {
              white-space: nowrap;
            }

            a:not(.btn) {
              color: $color-primary;

              &:hover {
                color: $color-dev
              }
            }
          }

        }

        .itemsListActions {
          display: flex; align-items: center; align-content: center;
          justify-content: flex-end;

          a:not(.btn) {
            margin-left: $mg_mini;
            font-size: 16px;
          }
        }
        
        .itemToggle {}

        .plItemDetails {
          padding: $hmg;
        }

        .plTableFooter {
          padding-top: $hmg;
          display: flex;
          align-content: center; align-items: center;
          justify-content: flex-end;

          >.btn {
            margin-left: $mg_small;
          }
        }
      }
    }

    .intList {
      padding: $hmg;

      header {
        display: flex; align-content: center; align-items: center;
        justify-content: space-between;
        padding-left: $mg_mini;
        margin-bottom: $hmg;

        h5 {
          padding: 0; margin: 0;
        }

        > div {
          display: flex; align-content: center; align-items: center;

          a {
            margin-left: $hmg;
          }

          > span {
            padding: $mg_mini $mg_small;
            border-radius: $border-radius;
            background-color: $color-bg;
            position: relative;
            margin-left: $hmg_small;

            &:before {
              content: "";
              display: block; width: 15px; height: 15px;
              border-radius: 2px; 
              background-color: $color-bg;
              transform: rotate(45deg) translate(-50%);
              position: absolute; top: 50%; left: 0;
            }

            label {
              padding: 0; margin: 0;
              font-size: $font-size-small;
            }
            h4 {
              padding: 0; margin: 0;
            }
          }
        }

        
      }

      .table-action {
        font-size: 16px;
      
        .icon {
          transform-origin: center;
          @extend %transition_4e;
        }
    
        &:hover{
          color: $color-primary;

          .icon {
            transform: rotate(45deg);
          }
          
        }
      }

      .message {
        p {
          padding: 0; margin: 0;
        }
      }
    }
</style>