<template>
  <div :class="['topbar', { devmode: devmode, alertmode: alertmode }]">
    <div class="">
      <p v-if="devmode">Você está em ambiente de <b>desenvolvimento</b>. Ações executadas neste ambiente não refletem no ambiente de produção.</p>
      <p v-if="hommode">Você está em ambiente de <b>homologação</b>. Ações executadas neste ambiente não refletem no ambiente de produção.</p>
      <p v-if="alertmode">Estamos experinciando problemas técnicos em nossos sistemas, por isso algumas coisas podem não funcionar normalmente. Volte novamente mais tarde.</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'topbar',
  props: {
    devmode: {
      type: Boolean,
      default: false
    },
    hommode: {
      type: Boolean,
      default: false
    },
    alertmode: {
      type: Boolean,
      default: false
    }
  },
}
</script>
<style lang="scss" scoped>
  .topbar {
    height: 0; width: 100%;
    position: relative;
    background-color: $color-primary;
    
    &.devmode {
      height: 50px;
      background-color: $color-dev;
    }
    &.hommode {
      height: 50px;
      background-color: $color-hom;
    }
    &.alertmode {
      height: 50px;
      background-color: $color-error;
    }

    > div {
      position: relative; width: 100%;
      height: 100%; line-height: 1;
      margin: 0 auto;
      display: flex; align-content: center; align-items: center; justify-content: center;

      p {
        padding: 0 2em; margin: 0;
        text-align: center;
        color: $color-bg
      }
    }

    @media screen and ( max-width: 640px ) {
      display: none;
    }
  }
</style>