<template>
  <section class="table-wrap">
    <header v-if="topicos" class="frame fixed">
      <div>
        <h6 class="color-text">{{ topicos[0].descricaoConteudosProgramatico }}</h6>
        <a href="#" @click.prevent="voltar()" class="btn btn-secondary">
          <fa :icon="['far', 'arrow-left-long']" />&nbsp;
          Voltar
        </a>
      </div>
      <div>
        <span>
          <h3>Tópicos</h3>
          <p v-if="topicos">{{ topicos.length }} {{ topicos.length == 1 ? 'item' : 'itens' }} encontrados</p>
        </span>
        <div class="regenerate">
          <div>
            <select-control 
              :options="options.prompts" 
              name="prompts"
              v-model="prompt"
              placeholder="Selecione"
              cssClass="invert"
              :valid="valid.prompt"
              @change="valid.prompt = true"
            />
            <a href="#" @click.prevent="regerar()" :class="['btn btn-primary', { disabled: !prompt}]">
              Regerar Conteúdo
            </a>
          </div>
          <a href="#" @click.prevent="getTopicos()" class="btn btn-primary">
            Atualizar Conteúdo &nbsp;
            <fa :icon="['fas','arrows-rotate']"/>
          </a>
        </div>
      </div>
    </header>
    <div class="wrap frame fixed">
      <template v-for="(topico) in topicos" :key="topico">
        <header>
          <h4 class="color-text">{{ topico.descricao }}</h4>
          <span>Sequenciamento: {{ topico.sequenciamento  }}</span>
        </header>
        <div :class="['detailWrap', { lock: loading }]">
          <ul>
            <template v-if="topico.conteudos.length > 0">
              <li v-for="conteudo in topico.conteudos" :key="conteudo">
                <div>
                  <p class="color-text"><b>{{ conteudo.descricao }}</b></p>
                </div>
                <div>
                  <p>{{ conteudo.descricaoSistema }}</p>
                </div>
                <div>
                  <p>Semana {{ conteudo.semana }}</p>
                </div>
                <div>
                  <p>{{ formatDateTime(conteudo.updated_at) }}</p>
                </div>
                <div class="alert-wrap">
                  <span :class="{ done: conteudo.conteudo && !conteudo.errorConteudo, error: conteudo.errorConteudo }" title="Teoria (Eu)">
                    <fa :icon="['fas', 'folder-grid']" class="icon"/>
                  </span>
                  <span :class="{ done: conteudo.dicasAula && !conteudo.errorDicasAula, error: conteudo.errorDicasAula }" title="Prática (Nós)">
                    <fa :icon="['fas', 'list-check']" class="icon"/>
                  </span>
                  <span :class="{ done: conteudo.questoes && !conteudo.errorQuestoes, error: conteudo.errorQuestoes}" title="Questões (Eles)">
                    <fa :icon="['fas', 'comments-question-check']" class="icon"/>
                  </span>
                </div>
                <div>
                  <a href="#" @click.prevent="editContent(conteudo)" class="bt-details">
                    <span></span>
                    <span></span>
                    <span></span>
                  </a>
                </div>
              </li>
            </template>
            <li v-else>
              <div>
                <fa :icon="['fas', 'triangle-exclamation']" class="icon color-alert"/>
                <p>Nenhum Item para esse tópico</p>
              </div>
              <div></div>
            </li>
          </ul>
        </div>

      </template>
    </div>
  </section>
</template>

<script>
import { formatDateTime, createSelectOptions } from '@/services/utils.service.js'
import EstudAIService from '@/services/estudai.service.js'

export default {
  props: {
    scope: {
      type: [String, Number]
    }
  },
  data () {
    return {
      conteudo: null,
      topicos: null,
      loading: false,
      prompt: null,
      options: {
        prompts: []
      },
      valid: {
        prompt: true
      },
      wait: {
        topicos: false,
        prompts: false
      }
    }
  },
  mounted () {
    this.conteudo = this.scope
    window.spinner.emit("open")
    this.loading = true

    console.log("conteudo:", this.conteudo)

    this.getTopicos()
    this.getPrompts()
  },
  methods: {
    getTopicos () {
      EstudAIService.getTopicos( this.conteudo ).then(
        response => {
          console.log("getTopicos:", response.data)

          const topicos = response.data[0]

          topicos.map( k => {
            k.conteudos.map( kc => {
              const conteudos = kc.conteudo
              const conteudosHasError = conteudos ? conteudos.includes('error') : false
              const dicasAula = kc.dicasAula
              const dicasAulaHasError = dicasAula ? dicasAula.includes('error') : false
              const questoes = kc.questoes
              const questoesHasError = questoes ? questoes.includes('error') : false
              
              kc['errorConteudo'] = conteudosHasError
              kc['errorDicasAula'] = dicasAulaHasError
              kc['errorQuestoes'] = questoesHasError
            })
          })
          
          this.topicos = topicos

          this.wait.topicos = true
          this.finishLoad()
          
        },
        () => {
          window.spinner.emit("close")
          this.loading = false
        }
      )
    },
    getPrompts () {
      EstudAIService.getPrompts().then(
        response => {
          console.log("getPrompts:", response.data)

          this.options.prompts = createSelectOptions(response.data, 'idPromptConteudos' ,'descricao')

          this.wait.prompts = true
          this.finishLoad()
        },
        () => {
          window.spinner.emit("close")
        }
      )
    },
    formatDateTime ( date ) {
      return formatDateTime(date)
    },
    voltar () {
      this.$emit("onReturn")
    },
    editContent ( topico ) {
      this.$emit("onSelect", topico)
    },
    finishLoad () {
      const finished = !Object.values(this.wait).some( a => !a)

      if ( finished ) {
        window.spinner.emit("close")
        this.loading = false
      }
      // console.log("finished:", finished)
    },
    regerar () {
      if ( this.prompt ) {
        // console.log("prompt:" this.prompt)
        window.spinner.emit("open")
        EstudAIService.regerarConteudo(this.conteudo, this.prompt).then(
          response => {
            console.log("regerarConteudo:", response.data)
            this.prompt = null
            window.toaster.emit('open', {style: "success", message: "Tudo certo! O conteúdo está sendo regerado. Você será notificado pelo canal 'LOG: Conteúdos Programáticos', no hangouts.", floater: true})
            window.spinner.emit("close")
          },
          () => {
            window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível regerar o conteúdo solicitado.", floater: true})
            window.spinner.emit("close")
          }
        )
      } else {
        this.valid.prompt = false
      }
      
    }
  }
}
</script>

<style lang="scss" scoped>
  .table-wrap {
    padding: $hmg $hmg_small $mg_extra;

    header {
      width: 100%;
      padding: 0 $hmg_small;

      > div {
        display: flex; align-content: center; align-items: center;
        margin-bottom: $hmg_mid;

        h6 {
          padding: 0; margin: 0;
        }
        .btn { margin-left: $hmg }

        &:last-child {
          justify-content: space-between;
        }
      }
    }
  }

  .wrap {
    header {
      display: flex;
      align-items:center; align-content: center;
      padding-top: $hmg_small;
      margin-bottom: $hmg_small;

      >h4 {
        margin: 0
      }

      >span {
        display: block;
        border-radius: $border-radius-larger;
        padding: $hmg_mini $mg_mini;
        margin-left: $hmg_mid;
        background-color: $color-secondary;
        line-height: 1;
        
      }
    }
    .detailWrap {
      background-color: $color-secondary;
      border-radius: $border-radius;
      margin-bottom: $hmg;
      @extend %transition_4o;
      
      &.lock {
        opacity: .5;
      }
      ul {
        padding: 0; margin: 0;

        li {
          display: grid;
          grid-template-columns: 50% 10% 10% 14% 13% 3%;
          list-style: none;
          padding: 0 $hmg_mini;
          border-bottom: 1px solid $color-bg;
          
          p {
            margin: 0;
          }

          >div {
            display: flex;
            align-content: center; align-items: center;
            padding: $hmg_small;
            
            .icon {
              font-size: 16px;
              margin-right: $hmg_mini
            }

            &.alert-wrap {
              display: flex;
              align-content: center;

              >span {
                width: 34px; height: 34px;
                min-width: 30px; min-height: 30px;
                display: flex; flex-direction: column;
                justify-content: center; align-items: center; align-content: center;
                border-radius: 100%; text-align: center;
                background-color: $color-bg;
                margin-right: $hmg_mini;

                &:last-child {
                  margin: 0
                }

                .icon {
                  font-size: 16px;
                  margin: 0;
                  color: $color-secondary;
                }
                &.done {
                  .icon {
                    color: $color-success
                  }
                }

                &.error {
                  .icon {
                    color: $color-alert
                  }
                }
              }
            }

            &:last-child {
              justify-content: flex-end;
            }
            .bt-details {
              width: 20px; height: 20px;
              padding: $hmg_mini;
              position: relative;
              display: flex;
              align-items: center;
              align-content: center;
              justify-content: center;
              // margin-right: -$hmg_small;

              > span {
                display: block;
                width: 4px; height: 4px;
                min-width: 4px; min-height: 4px;
                border-radius: 100%;
                background-color: $color-text;
                @extend %transition_3e;
                transform-origin: center;

                margin: 0 1px;
              }

              &:hover {
                > span {
                  margin: 0 2px;
                  color: $color-primary
                }
              }
            }
          }
        }
      }
    }
  }
  
  .regenerate {
    display: flex;
    align-items: center; align-content: center;

    > div {
      display: flex;
      align-items: center; align-content: center;
      padding: $hmg_small;
      border-radius: $border-radius-larger;
      background-color: $color-secondary;
    }
    .btn {
      margin-left: $mg_mini
    }
  }
</style>