<template>
  <div :style="{width: size, height: size}">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
    <g transform="translate(-589.25 -216.658)">
      <g transform="translate(603.133 231.132)">
        <path :fill="color" d="M381,212.6c0.4,2.3,0.5,4.6,0.7,6.1c0.1,8.7-2.7,17.3-7.9,24.2c-4.3,5.4-9.9,9.4-16.4,11.7
          c-7.4,2.7-14.9,5.1-22.3,7.6c-9.2,3-18.6,6-27.8,9c-3.1,1-3.2,1.5-1.5,4.2c9.1,13.9,18.7,27.4,28.9,40.5c3.3,4.1,5.6,8.9,6.8,14
          c3.2,13.2-1.5,27-11.9,35.6c-4.4,4.1-9.7,7.3-15.5,9.2c-14.3,4.6-29.9-0.2-39.1-12.1c-8.2-11-16.5-22-24.7-33
          c-0.9-1.2-1.9-2.4-2.9-3.7c-2.2-2.8-6.2-3.3-9-1.1c-0.4,0.3-0.7,0.6-1,1c-1.7,2.1-3.4,4.2-5,6.4c-7.6,10.1-15.1,20.2-22.7,30.3
          c-4.9,6.7-12,11.3-20.1,13c-9.6,2.3-19.7,0.4-27.9-5c-4.6-2.8-8.6-6.4-12-10.6c-9.9-12.8-9.9-30.6-0.1-43.5
          c10.2-13.2,19.8-26.7,29-40.6c0.4-0.6,0.8-1.2,1.1-1.8c0.5-1.1,0.3-1.7-0.8-2.2c-0.5-0.2-1.1-0.4-1.6-0.6
          c-14.5-4.7-29.1-9.4-43.6-14.1c-4-1.2-7.8-2.8-11.5-4.7c-10.7-5.6-17.8-16.1-19-28.1c-2.4-18.5,9.4-35.8,27.4-40.5
          c6.6-1.7,13.6-1.6,20.1,0.2c9.7,2.5,19.4,4.8,29.1,7.2c5.5,1.4,11,2.7,16.6,3.9c1.1,0.3,2.3,0.3,3.4,0.1c2.6-0.6,4.3-3,4.2-5.6
          c0.1-2.1,0.3-4.2,0.3-6.4c0.3-9.9,0.5-19.8,0.8-29.7c0.1-4.6,0.1-9.2,0.6-13.8c0.6-9.7,5.4-18.7,13.1-24.6
          c6.7-5.2,14.9-7.9,23.3-7.7c8.5-0.3,16.8,2.5,23.5,7.7c8.3,6.4,13.2,16.1,13.3,26.6c0.4,12.5,0.7,25,1.1,37.4
          c0.1,3.5,0.3,7,0.5,10.5c-0.1,3.1,2.4,5.8,5.5,5.9c0.7,0,1.5-0.1,2.2-0.4c8.2-1.9,16.3-3.8,24.5-5.8c7.9-1.9,15.7-4,23.6-5.9
          c20.1-4.5,40,8.1,44.6,28.2C380.9,211.9,380.9,212.3,381,212.6z"/>
      </g>
      <path :fill="color" d="M845.4,216.9c-141.4,0-256,114.6-256,256s114.6,256,256,256s256-114.6,256-256
        S986.7,216.9,845.4,216.9L845.4,216.9z M845.4,683.9c-116.5,0-211-94.5-211-211c0-116.5,94.5-211,211-211s211,94.5,211,211
        C1056.4,589.5,961.9,683.9,845.4,683.9C845.4,684,845.4,684,845.4,683.9L845.4,683.9z"/>
    </g>
    </svg>  
  </div>
</template>
<script>
export default {
  props: {
    color: {
      default: "#FFF",
      type: String
    },
    size: {
      default: "60px",
      type: String
    }
  },
  data () {
    return {

    }
  },
  mounted () {

  }
 }
</script>
<style scoped>

</style>