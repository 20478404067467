<template>
  <controller :lockScroll="ls">
    <page-header title="Atividades">
      <type-menu @filterType="toggleType" :types="types"></type-menu>
    </page-header>
    <filter-bar @listState="toggleListState" @onFilter="toggleFilter" :mode="listMode" :isMobile="isMobile" v-if="!isMobile"></filter-bar>
    <painel :mode="listMode" :type="type" :section="section" :id="id" :action="action" @lockScroll="lockScroll" :isMobile="isMobile" v-if="ready" ref="painel"></painel>
  </controller>
</template>

<script>
// import PageHeader from "@/components/template/PageHeader.vue"
import TypeMenu from '@/components/atividades/navigation/TypeMenu.vue'
import FilterBar from '@/components/atividades/utilities/FilterBar.vue'
import Painel from '@/components/atividades/template/Painel.vue'

export default {
  components: {
    TypeMenu, FilterBar, Painel
  },
  props: {
    section: {
      type: [String, Number]
    },
    action: {
      type: [String, Number]
    },
    id: {
      type: [String, Number]
    }
  },
  watch: {
    section ( value ) {
      console.log('watch section:', value)
      if ( value ) {
        this.trackType()
      }
    },
    action ( value ) {
      console.log('watch action:', value)
      if ( value && value != 'novo' && value != 'executar' && value != 'ver' ) {
        this.refresh()
      }
    },
    id ( value ) {
      console.log('watch id:', value)
      if ( !value ) {
        this.refresh()
      }
    }
  },
  data () {
    return {
      listMode: 'kanban',
      ready: false,
      types: [
        {
          label: "Atendimentos",
          slug: "atendimentos",
          single: "Atendimento"
        },
        {
          label: "Tarefas",
          slug: "tarefas",
          single: "Tarefa"
        },
        // {
        //   label: "Tickets",
        //   slug: "tickets",
        //   single: "Ticket",
        // },
        // {
        //   label: "Solicitações",
        //   slug: "solicitacoes",
        //   single: "Solicitação",
        // },
        // {
        //   label: "Prospects",
        //   slug: "prospects",
        //   single: "Prospect",
        // }
      ],
      type: null,
      ls: false,
      isMobile: false
    }
  },
  mounted () {
    this.trackType()
    this.trackMobile()
    this.refresh()

    window.addEventListener('resize', this.trackMobile)
    this.ls = false
  },
  methods: {
    toggleListState ( state ) {
      this.listMode = state
    },
    trackType () {
      // console.log("section:", this.section)
      const getType = this.types.filter( a => a.slug == this.section)
      // console.log("getType:", getType)

      if ( getType.length > 0 ) {
        this.toggleType(getType[0])
      } else {
        this.$router.push({name: "Painel Atividades"})
      } 
    },
    toggleType ( type ) {
      // console.log("toggleType:", type)
      if ( type ) {
        this.type = type
        const params = this.action ? { section: type.slug, action: this.action, id: this.id } : { section: type.slug }
        this.$router.push({name: "Painel Atividades", params: params})
      } else {
        this.$router.push({name: "Painel Atividades"})
      }
      
    },
    toggleFilter ( filter ) {
      this.$refs.painel.rerun(filter)
    },
    lockScroll (state) {
      this.ls = state
      // this.$emit("lockScroll", state)
    },
    trackMobile () {
      if ( this.resizeTimeout ) clearTimeout(this.resizeTimeout)

      this.resizeTimeout = setTimeout(() => {
        this.isMobile = window.innerWidth < 1024

        if ( this.isMobile ) {
          this.toggleListState('list')
        }
      },200)
    },
    refresh () {
      this.ready = false
      this.ls = false

      setTimeout (() => {
        this.ready = true
      },100)
    },
  }
}
</script>

<style lang="scss" scoped>

</style>