<template>
  <form enctype="multipart/form-data" method="post">
    <label class="file-control">
      <h5 class="color-text">
        Selecione o arquivo desejado
      </h5>
      <p v-if="file">{{ file }}</p>
      <fa :icon="['fas','cloud-arrow-up']" class="icon" />
      <input name="file" type="file" ref="file" @change="setUploadImage($event)" accept=".jpeg,.jpg,.png,.pdf,.webp" />
    </label>
  </form>
</template>

<script>
import FerramentasService from '@/services/ferramentas.service'

export default {
  data () {
    return {
      file: null
    }
  },
  methods: {
    setUploadImage (e) {
      const file = e.target.files[0]
      const name = file.name
      const ext = file.type
      const size = file.size

      console.log("e:", e)
      console.log("file:", file)
      console.log("name:", name)
      console.log("ext:", ext)
      console.log("size:", size)
      // const reader = new FileReader()
      // reader.readAsDataURL(file)
      // reader.onload = e =>{
      //     this.previewImage = e.target.result
      //     console.log(this.previewImage)
      // };

      // const image = URL.createObjectURL(file)

      var form = new FormData()
      form.append("image", file)

      // console.log("image:", image)
      console.log("form:", form)

      FerramentasService.uploadImageApp(file).then(
        response => {
          console.log("response:", response)
        },
        error => {
          console.log("error:", error)
        }
      )


      // var xhr = new XMLHttpRequest();
      //   xhr.withCredentials = true;

      //   xhr.addEventListener("readystatechange", function() {
      //     if(this.readyState === 4) {
      //       console.log(this.responseText)
      //     }
      //   });

      //   xhr.open("POST", "https://rededecisao.sasconecta.com.br/api/arquivo/upload")
      //   xhr.setRequestHeader("Authorization", "Bearer 7PfuDvII8GuGkloNwMcxw9kCHx3O+nbjrSHlkKBLKK4Jkcok9Ced3nzYv/UQlpdauoK5SAYnDvjBGI7E6CmtaSpOjOeFOFUu79U3qU/ltpGcHrjPsKgYFwAPgt4lwQ8yEUq09luqiPeIjLIcwhIFoNlXCUVGgaLsz8qPRLaEX98=")

      //   xhr.send(form)

    }
  }
}
</script>

<style lang="scss" scoped>
  .file-control {
    display: block; width: 100%;
    padding: $hmg !important; margin: 0;
    background-color: $color-secondary;
    border-radius: $border-radius;
    position: relative;

    h5 {
      padding: 0;
      margin: 0;
    }

    input {
      opacity: 0; z-index: -1;
      position: absolute;
    }

    .icon {
      position: absolute;
      top: 50%; right: $hmg;
      transform: translateY(-50%);
      font-size: 24px;
    }
  }
</style>