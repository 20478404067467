<template>
  <section>
    <header>
      <h5>
        Filtrar
      </h5>
    </header>
    <div class="frame fixed">
      <div class="row no-padd" ref="progressaoFilterForm">
        <div class="col-md-2 form-group">
          <label>Período base</label>
          <!-- <input-control 
            v-model="filter.base"
            :valid="valid.base" 
            placeholder="2023"
            @keyup="valid.base = true"
            required
          /> -->
          <select-control
            v-model="filter.baseID"
            :valid="valid.base" 
            :options="options.periodosLetivos"
            placeholder="Selecione"
            @change="valid.base = true; setPerBase()"
            required
          />
          <input type="hidden" v-model="filter.base" />
        </div>
        <div class="col-md-2 form-group">
          <label>Período Progressão</label>
          <input-control 
            v-model="filter.target"
            :valid="valid.target" 
            placeholder="2024"
            @keyup="valid.target = true"
            required
          />
        </div>
        <div class="col-md-12 form-actions form-group">
          <a href="#" @click.prevent="filterProgressao()" class="btn btn-primary">
            Filtrar
          </a>
          <a href="#" @click.prevent="clearFilter()" class="btn btn-secondary">
            Limpar
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import FormService from '@/services/form.service.js'
import AcademicoService from '@/services/academico.service.js'
import { createSelectOptions } from '@/services/utils.service.js'
export default {
  data () {
    return {
      filter: {
        base: null,
        baseID: null,
        target: null
      },
      options: {
        periodosLetivos: []
      },
      valid: {
        base: true,
        target: true
      }
    }
  },
  mounted () {
    this.getTodosPeriodosLetivos()
  },
  methods: {
    getTodosPeriodosLetivos () {
      window.spinner.emit("open")
      AcademicoService.getTodosPeriodosLetivos().then(
        response => {
          if ( response.data.length > 0 ) {
            console.log("getTodosPeriodosLetivos:", response.data)
            this.options.periodosLetivos = createSelectOptions(response.data, 'idPeriodoLetivo', 'descricao')
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum período letivo encontrado. Isto parece ser um problema de parametrização.", floater: true})
          }
          window.spinner.emit("close")
        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu! Não foi possível recuperar os períodos letivos necessários", floater: true})
          window.spinner.emit("close")
        }
      )
    },
    filterProgressao () {
      const valid = FormService.validate(this.$refs.progressaoFilterForm, this.valid)
      console.log("valid:", valid)

      if ( valid.response ) {
        window.spinner.emit("open")
        this.$emit('onFilter', this.filter)
      }
    },
    setPerBase () {
      console.log("periodosLetivos:", this.options.periodosLetivos)
      // console.log("periodosLetivos:", this.options.periodosLetivos)

      const base = this.options.periodosLetivos.filter( k => k.idPeriodoLetivo == this.filter.baseID)[0].descricao

      this.filter.base = base
    },
    clearFilter () {
      this.filter = {
        base: null,
        target: null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  header {
    margin: $hmg $hmg_small;
    padding: 0 $hmg_small;

    h5 {
      margin: 0 0 $hmg_mini 0; padding: 0;
    }
    p {
      margin: 0; padding: 0;
    }
  }

  // .frame {
  //   padding: 0 $hmg_small
  // }
  .form-actions {
    margin-top: $hmg !important
  }
</style>