<template>
  <section ref="alacadaForm">
    <header class="header">
      <div>
        <h4>Preenchimento em lote</h4>
        <p>Preencha abaixo os dados que serão aplicados a todas as alçadas listadas.</p>
      </div>
      <!-- <a href="#" @click.prevent="voltar()" class="btn btn-secondary">
        <fa :icon="['far','arrow-left-long']"></fa>&nbsp;
        Voltar
      </a> -->
    </header>
    <div v-if="alcadas" class="frame fixed">
      <div class="col-md-2 form-group" ref="loteForm">
        <label>Limite de Desconto (%)</label>
        <input-control 
          v-model="filter.percentualLimite" 
          name="percentualLimite"
          placeholder="Ex. 25" 
          :disabled="loading"
          :valid="valid.percentualLimite"
          @keyup="valid.percentualLimite = true"
          required
        />
      </div>
      <div class="col-md-12 form-actions form-group">
        <a href="#" @click.prevent="apply()" class="btn btn-primary">
          Aplicar
        </a>
        <a href="#" @click.prevent="clearFilter()" class="btn btn-secondary">
          Limpar
        </a>
      </div>
    </div>
    <div class="table-wrap">
      <header>
        <h4>Resultado da Busca</h4>
        <p v-if="alcadas">{{ alcadas.length }} {{ alcadas.length == 1 ? 'item' : 'itens' }} encontradas</p>
      </header>
      <table v-if="alcadas" class="transparent-table ">
        <thead>
          <tr>
            <th>Série</th>
            <th>ID Alçada</th>
            <th>Função</th>
            <th>Período Letivo</th>
            <th>Unidade</th>
            <th>Tipo Matrícula</th>
            <th>Arrecação</th>
            <th>Percentual</th>
            <th>Status</th>
            <th></th>
            <th class="text-right">
              <label class="check-control">
                <span>Todos</span>
                <input type="checkbox" class="check" v-model="all" @change="toggleAll()">
              </label>
            </th>

          </tr>
        </thead>
        <tbody>
          <tr v-for="(alcada,i) in alcadas" :key="alcada">
            <td>
              {{ alcada.descricaoSerie }}
            </td>
            <td>
              {{ alcada.idlimiteDescontoFuncao ? alcada.idlimiteDescontoFuncao : '-' }}
            </td>
            <td>
              {{ alcada.descricaoFuncao ? alcada.descricaoFuncao : '-' }}
            </td>
            <td>
              {{ alcada.descricaoPeriodoLetivo  }}
            </td>
            <td>
              {{ alcada.sigla }}
            </td>
            <td>
              <template v-if="alcada.tipoMatricula">
                {{ alcada.tipoMatricula == 1 ? 'Matrícula' : 'Rematrícula' }}
              </template>
              <template v-else> - </template>
            </td>
            <td>
              {{ alcada.descricaoArrecadacao ? alcada.descricaoArrecadacao : '-'  }}
            </td>
            <td>
              <input-control v-model="alcada.percentualLimite" placeholder="Ex. 25" cssClass="small invert table-input" @change="toggleChange(i)" v-if="alcada.idlimiteDescontoFuncao"></input-control>
              <template v-else>-</template>
            </td>
            <td>
              <template v-if="alcada.aprovado">
                <fa :icon="['fas','check']" class="color-success" />
                Aprovada
              </template>
              <template v-else>
                <fa :icon="['fas','triangle-exclamation']" class="color-alert" />
                Pendente
              </template>
            </td>
            <td class="actions">
              <a href="#" @click.prevent="confirmRemove(alcada, i)" class="action" v-if="alcada.idlimiteDescontoFuncao">
                <fa :icon="['fas','trash-can']" class="icon"></fa>
              </a>
              <a href="#" @click.prevent="edit(alcada)" class="action" v-else>
                <fa :icon="['fas','pencil']" class="icon"></fa>
              </a>
            </td>
            <td class="text-right">
              <label class="check-control" v-if="alcada.idlimiteDescontoFuncao">
                <input type="checkbox" class="check" v-model="alcada.selected">
              </label>
            </td>
          </tr>
        </tbody>
      </table>
      <footer class="action-wrap">
        <a href="#" class="btn btn-primary" @click.prevent="confirmSave()">
          Salvar Alçadas
        </a>
        <a href="#" class="btn btn-primary" @click.prevent="confirmApprove()" v-if="allowApprove">
          Aprovar Alçadas
        </a>
      </footer>
    </div>
    <confirm 
      :backdrop="false"
      class="confirm"
      ref="confirm"
    ></confirm>
  </section>
</template>

<script>
import AdmService from '@/services/adm.service.js'
import FinanceiroService from '@/services/financeiro.service.js'
import FormService from '@/services/form.service.js'
import UserService from '@/services/user.service.js'
import { createSelectOptions } from '@/services/utils.service.js'

export default {
  props: {
    state: {
      type: String
    },
    scope: {
      type: Array
    },
    filterScope: {
      type: Object
    }
  },  
  data () {
    return {
      filter: {
        funcao: null,
        tipoMatricula: null,
        descricaoFuncao: null,
        percentualLimite: null,
        arrecadacoes: null,
        descricaoArrecadacao: null
      },
      wait: {
        funcoes: false,
        arrecadacoes: false
      },
      options: {
        funcoes: [],
        tipoMatricula: [
          { label: 'Matrícula', value: "1" },
          { label: 'Rematrícula', value: "2" }
        ],
        arrecadacoes: []
      },
      valid: {
        percentualLimite: true
      },
      loading: false,
      alcadas: null,
      all: null,
      allowApprove: false
    }
  },
  watch: {
    scope ( value ) {
      if ( value ) {
        this.alcadas = Object.assign([], value)
      }
    }
  },
  mounted () {
    window.spinner.emit("open")
    this.loading = true;

    this.getFuncoes()
    this.getArrecadacoes()

    this.alcadas = Object.assign([], this.scope)
    this.addSelector( true)

    // console.log("alcadas:", this.alcadas)
    this.allowApprove = UserService.filterPermissions('aprovarAlcadasDesconto')
  },
  methods: {
    getFuncoes () {
      AdmService.getFuncoes().then(
        response => {
          console.log("getFuncoes:", response.data)
          if ( response.data.length > 0 ) {
            this.options.funcoes = createSelectOptions(response.data, 'idFuncao' ,'nome')
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhuma função encontrada. Isto parece um problema de parametrização.", floater: true})
          }

         this.wait.funcoes = true
         this.finishLoad()
        },
        () => {
          window.spinner.emit("close")
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma função encontrada. Verifique sua conexão e tente novamente.", floater: true})
        }
      )
    },
    getArrecadacoes () {
      window.spinner.emit('open')
      FinanceiroService.getArrecadacoes().then(
        response => {
          console.log("getArrecadacoes:", response)
          if ( response.data.length > 0 ) {
            this.options.arrecadacoes = createSelectOptions(response.data, 'idTipoArrecadacao' ,'descricao')
          } else {
            window.toaster.emit('open', {style: "error", message: "Não foi possível recuperar as arrecadações. Isto parece ser um problema de integração com o GestorClass.", floater: true})
          }
          
          this.wait.arrecadacoes = true
          this.finishLoad()
        },
        () => {
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível recuperar as arrecadações.", floater: true})
          window.spinner.emit('close')
        }
      )
    },
    finishLoad () {
      const finished = !Object.values(this.wait).some( a => !a)

      if ( finished ) {
        window.spinner.emit("close")
        this.loading = false
      }
    },
    addSelector ( off ) {
      if ( off ) this.all = false

      this.alcadas.map( k => {
        if ( k.idlimiteDescontoFuncao ) {
          k.selected = off ? false : true
        }
      })
    },
    toggleAll () {
      console.log("all:", this.all)
      if ( this.all ) {
        this.addSelector()
      } else {
        this.addSelector(true)
      }
      // console.log("this.selected", this.selected)
    },
    voltar () {
      this.$emit("onReturn")
      this.clear()
    },
    toggleChange ( index ) {
      this.alcadas[index].selected = true
    }, 
    trackFuncao () {
      const funcao = this.options.funcoes.filter( a => a.value == this.filter.funcao )[0].label
      // console.log('trackFuncao:', funcao)
      this.filter.descricaoFuncao = funcao
    },
    trackArrecadacao () {
      const arrecadao = this.options.arrecadacoes.filter( a => a.value == this.filter.arrecadao )[0].label
      // console.log('trackFuncao:', funcao)
      this.filter.descricaoArrecadacao = arrecadao
    },
    apply () {
      const valid = FormService.validate(this.$refs.loteForm, this.valid)
      
      if ( valid.response ) {
        const funcao = this.filter.funcao
        const percentual = this.filter.percentualLimite
        const tipoMatricula = this.filter.tipoMatricula
        const arrecadacao = this.filter.idTipoArrecadacao

        this.alcadas.map(k => {
          if ( k.idlimiteDescontoFuncao ) {
            if ( funcao ) {
              k.idFuncao = funcao
              k.descricaoFuncao = this.filter.descricaoFuncao
              k.selected = true
              this.all = true
            }
            if ( percentual ) {
              k.percentualLimite = percentual
              k.selected = true
              this.all = true
            }
            if ( tipoMatricula ) {
              k.tipoMatricula = tipoMatricula
              k.selected = true
              this.all = true
            }
            if ( arrecadacao ) {
              k.idTipoArrecadacao = arrecadacao
              k.descricaoArrecadacao = this.filter.descricaoArrecadacao
              k.selected = true
              this.all = true
            }
          }
        })
      }
    },
    confirmSave () {
      this.$refs.confirm.run({
        message: "Tem certeza que deseja salvar as alçadas selecionadas?",
        confirmButton: "Sim",
        denyButton: "Não",
        // data: alcada,
        callback: this.save,
      })
    },
    save () {
      const drop = []
      this.alcadas.map( k => {
        if ( k.selected ) {
          k.aprovado = 0
          drop.push(k)
        }
      })

      if ( drop.length > 0 ) {
        FinanceiroService.saveAlcada( drop ).then(
          response => {
            console.log("response:", response)
            if ( response.status == 200 ) {
              this.$emit("onUpdate")
              const action = this.state == 'criar' ? 'criada(s)' : 'editada(s)'
              window.toaster.emit('open', {style: "success", message: "Tudo certo! Alçada(s) " + action + " com sucesso.", floater: true})
            }
          },
          () => {
            window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível salvar esta(s) alçada(s).", floater: true})
          }
        )
      } else {
        window.toaster.emit('open', {style: "error", message: "Selecione as alçadas que você quer salvar.", floater: true})
      }
      
    },
    confirmRemove ( alcada, i ) {
      this.$refs.confirm.run({
        message: "Tem certeza que deseja remover as alçadas selecionadas?",
        confirmButton: "Sim",
        denyButton: "Não",
        data: [alcada,i],
        callback: this.remove,
      })
    },
    remove ( response, data ) {
      if ( response ) {
        const alcada = data[0]
        window.spinner.emit("open")
        // console.log("remove:", alcada)
        FinanceiroService.removeAlcada( alcada.idlimiteDescontoFuncao ).then(
          response => {
            if ( response.status == 200 ) {
              window.toaster.emit('open', {style: "success", message: "Tudo certo! Alçada(s) removida(s) com sucesso.", floater: true})
              this.$emit('onUpdate')
              window.pagescroll.emit('goto', 0)
            }
          },
          () => {
            window.spinner.emit("close")
            window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível remover a(s) alçada(s) selecionada(s).", floater: true})
          }
        )
      }
    },
    clear() {
      this.alcada = null
    },
    edit ( alcada ) {
      alcada.idFuncao = this.filterScope.idFuncao
      alcada.idTipoArrecadacao = this.filterScope.idTipoArrecadacao
      alcada.tipoMatricula = this.filterScope.tipoMatricula

      const event = alcada.idlimiteDescontoFuncao ? 'onEdit' : 'onCreate' 
      this.$emit(event, alcada)
    },
    confirmApprove () {
      this.$refs.confirm.run({
        message: "Tem certeza que deseja aprovar as alçadas selecionadas?",
        confirmButton: "Sim",
        denyButton: "Não",
        // data: alcada,
        callback: this.approve,
      })
    },
    approve ( response ) {
      if ( response ) {
        const drop = []
      this.alcadas.map( k => {
        if ( k.selected ) {
          k.aprovado = 1
          drop.push(k)
        }
      })

      console.log("drop:", drop)
      if ( drop.length > 0 ) {
        FinanceiroService.saveAlcada( drop ).then(
          response => {
            console.log("response:", response)
            if ( response.status == 200 ) {
              this.$emit("onUpdate")
              window.toaster.emit('open', {style: "success", message: "Tudo certo! Alçada(s) aprovadas com sucesso.", floater: true})
            }
          },
          () => {
            window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível aprovar esta(s) alçada(s).", floater: true})
          }
        )
      } else {
        window.toaster.emit('open', {style: "error", message: "Selecione as alçadas que você quer aprovar.", floater: true})
      }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  header {
    padding: $hmg_mini;

    &.header {
      padding: $hmg_small $hmg $mg_mini;
      display: flex;
      align-items: center;
    }

    h4 {
      margin: 0;
    }
    .btn {
      margin-left: $hmg
    }
  }

  .transparent-table{
    th, td {
      &:last-child {
        max-width: 100px
      }
    }
  }
  .table-wrap {
    padding: $hmg $hmg_small $mg_extra;
  }
  
  .action-wrap {
    display: flex; align-content: center; align-items: center;
    justify-content: flex-end;
    padding: $hmg $hmg_small;

    a {
      margin-left: $hmg_small
    }

    label{
      margin-right: $hmg
    }
  }

  .actions {
    display: flex; align-content: center; align-items: center;
    justify-content: flex-end;
    font-size: 12px;

    a {
      display: flex; flex-direction: column;
      align-items: center; align-content: center; justify-content: center;
      width: 30px; height: 30px;
      border-radius: 100%; 
      @extend %transition_3o;

      .icon {
        font-size: 14px !important
      }
      
      &:hover {
        background-color: $color-bg
      }
    }
  }
</style>