<template>
  <div :class="['assistants', { open: open }]">
    <a href="#" @click.prevent="toggleList()" class="toggle">
      <div class="tracker">
        <fa :icon="['fal', 'user-robot']" class="ic" />
        <h4>{{ active ? ( active.name ? active.name : active.id ) : 'Assistentes' }}</h4>
      </div>
      <div class="toggleArrows">
        <fa :icon="['fas', 'chevron-up']" class="ic" />
        <fa :icon="['fas', 'chevron-down']" class="ic" />
      </div>
    </a>
    <div :class="['assistants-list', { active: open }]">
      <div>
        <span></span>
        <ul>
          <template v-if="assistants && assistants.length > 0">
            <li v-for="assistant in assistants" :key="assistant" :class="{ active: assistant.id == active.id }">
              <div>
                <a href="#" class="select" @click.prevent="toggleSelect(assistant)">
                  <fa :icon="['far', 'robot']" class="ic" />
                  <p>{{ assistant.name ?? assistant.id }}</p>
                  <fa :icon="['fas', 'check']" class="ic" v-if="assistant.id == active.id"/>
                </a>
                <a href="#" class="edit" @click.prevent="toggleEdit(assistant)" v-if="allowCreate">
                  <fa :icon="['fal', 'pencil']" class="ic" />
                </a>
              </div>
            </li>
          </template>
          <li v-if="allowCreate">
            <a href="#" class="btn btn-success-2 btn-squared" @click.prevent="toggleAdd">
              Adicionar novo assistente
            </a>
          </li>
        </ul>
      </div>
    </div>
    <span class="backdrop" v-if="open" @click="toggleList()"></span>
  </div>
</template>

<script>
import { gsap } from "gsap/dist/gsap"
import UserService from '@/services/user.service.js'

export default {
  props: {
    assistants: {
      type: Array
    },
    active: {
      type: Object
    }
  },
  watch: {
    assistants ( value ) {
      console.log('watch assistants',value)
    }
  },
  data () {
    return {
      open: false,
      allowCreate: false
    }
  },
  mounted () {
    const tl = gsap.timeline()

    tl.set('.assistants-list>div', { 
      opacity: 0,
      y: -10,
      rotationX: '-90deg'
    })

    this.allowCreate = UserService.filterPermissions('createEditAssistant')
  },
  methods: {
    toggleList () {
      const tl = gsap.timeline()

      if ( !this.open ) {
        tl.to('.assistants-list>div', {
          rotationX: 0,
          opacity: 1,
          y: 0,
          duration: 0.6,
          ease: 'back.out(2)',
          onComplete: () => {
            this.open = true
          }
        })
      } else {
        tl.to('.assistants-list>div', {
          rotationX: '-45deg',
          opacity: 0,
          y: -10,
          duration: 0.6,
          perspective: 1200,
          ease: 'back.out(2)',
          onComplete: () => {
            this.open = false
          }
        })
      }
    },
    toggleEdit ( assistant ) {
      this.$emit('onEdit', assistant)
      this.toggleList()
    },
    toggleAdd () {
      this.$emit('onCreate')
      this.toggleList()
    },
    toggleSelect ( assistant ) {
      this.$emit('onSelect', assistant)
      this.toggleList()
    }
  }
}
</script>

<style lang="scss" scoped>
.assistants {
  position: relative;
  padding: $hmg_mini;
  height: 62px; width: 310px;
  border-right: $border-component;

  .toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; height: 52px;
    padding: 0 $hmg_mid;
    position: relative;
    z-index: 100;

    .tracker {
      display: flex;
      flex: 1;
      align-items: center;
      max-width: calc(100% - 30px);
      gap: $mg_mini;
      @extend %transition_3o;

      h4 {
        margin: 0; padding: 0;
        @extend %transition_3o;
        white-space: nowrap;
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
      }
      .ic {
        @extend %transition_3o;
        font-size: 18px;
      }
    }
    .toggleArrows {
      display: flex; flex-direction: column;
      font-size: 8px; align-content: center;

      .ic {
        @extend %transition_3o;
      }
    }

    &:hover {
      h4 {
        color: $color-success;
      }

      .toggleArrows {
        .ic {
          &:first-child {
            transform: translateY(-10%)
          }
          &:last-child {
            transform: translateY(10%)
          }
        }
      }
    }
  }

  .assistants-list {
    width: 100%; height: 300px; position: absolute;
    z-index: 99;
    top: 0; left: 0;
    padding: $hmg_mini;
    transform-origin: top center;
    transform-style: preserve-3d;
    perspective: 800px;
    perspective-origin: top right;
    pointer-events: none;

    &.active {
      pointer-events: all;
    }

    >div {
      background-color: $color-secondary;
      border-top-left-radius: 3px; border-top-right-radius: 3px;
      border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;
      box-shadow: 0 3px 16px rgba(0,0,0,.15);
      @extend %transition_4e;

      >span {
        display: block;
        height: 52px;
        border-bottom: $border-component;
      }
    }

    ul {
      padding: 0 $mg_mini $mg_mini $mg_mini; margin: 0;
      
      li {
        padding: $hmg_mini 0; margin: 0;
        list-style: none;
        border-bottom: $border-component;

        &:last-child {
          border-bottom: 0 !important;
          padding: $mg_mini 0 0 0;
        }

        .ic {
          font-size: 14px;
        }

        .btn {
          width: 100%;
        }

        > div {
          padding: $mg_mini;
          display: flex; align-items: center;
          justify-content: space-between;
          border-radius: $border-radius-small;
          @extend %transition_3o;
          
          p {
            margin: 0; padding: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 80%;
          }
          a {
            display: flex; align-items: center;
            gap: $mg_mini; max-width: 90%;

            &.select {
              flex: 1
            }
          }
        }

        &:hover {
          >div {
            background-color: $color-bg
          }
        }

        a {
          &.edit {
            &:hover {
              .ic {
                color: $color-primary;
              }
            }
          }
        }
        &.active {
          color: $color-success;
          
          a.select {
            pointer-events: none
          }
        }
      }
    }
  }

  .backdrop {
    display: block;
    width: calc(100vw - 60px);
    height: calc(100vh - 60px);
    position: fixed; right: 0; bottom: 0;
    z-index: 98;
  }
}
  

</style>