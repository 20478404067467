<template>
  <label :class="['checkbox-control' ,'noselect', { justified: justified }]">
    <fa 
      :icon="['fas', 'check']" 
      :class="['icon leftIcon', {selected: selected, hiddenMode: type == 'hidden' }]"
      v-if=" iconPos === 'left' "
    ></fa>
    <slot></slot>
    <fa
      :icon="['fas', 'check']" 
      :class="['icon rightIcon', {selected: selected, hiddenMode: type == 'hidden' }]"
      v-if=" iconPos === 'right' "
    ></fa>
    <input
      type="checkbox"
      class="checkbox-input"
      :class="[
        { error: !valid },
        cssClass
      ]"
      :name="name"
      @change="change"
      @input="input"
      :value="value" 
      v-model="checked"
    />
  </label>
</template>
<script>
// import styleVars from '../../assets/scss/_variables.scss?type=style&lang=scss' 

export default {
  name: "checkbox-control",
  props: {
    valid: {
      type: Boolean,
      default: true
    },
    modelValue: {
      default: ""
    },
    cssClass: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    iconPos: {
      type: String,
      default: 'right'
    },
    color: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'default'
    },
    value: {
      type: [String, Number, Boolean],
      default: null
    },
    justified: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showPass: false,
      selected: false,
    }
  },
  computed: {
    checked: { 
      get () {
        return this.modelValue
      },
      set () {
        
      }
    }
  },
  watch: {
    modelValue (value) {
      this.selected = value
    }
  },
  mounted () {

  },
  methods: {
    trigger () {

    },
    change (data) {
      this.selected = data.target.checked
      // this.$emit("change", data)
    },
    // input (e) {
    //   console.log("checked on input: ", e.target.checked)
    //   this.$emit('update:modelValue', e.target.checked)
    // }
  }
}
</script>
<style lang="scss" scoped>
//forms
.checkbox-control {
  display: flex; align-items: center; align-content: center;
  position: relative;
  width: 100%; cursor: pointer;
  margin: 0 ; padding: 0;
  @extend %transition_3o;
  font-size: 13px !important; font-weight: normal !important;

  &.justified {
    justify-content: space-between;
  }
  &.flex {
    width: auto; margin-right: $hmg;
  }

  .checkbox-input{
    pointer-events: none;
    opacity: 0; position: absolute;
    z-index: -1;
  }

  .icon {
    color: $color-fade;
    @extend %transition_3e;
    // opacity: .8;

    &.hiddenMode {
      transform: scale(0);
      
      &.selected {
        transform: scale(1);
      }
    }
    &.selected {
      color: $color-primary;
      // animation: pop 0.4s;
    }
    &.leftIcon {margin-right: 10px}
    &.rightIcon {margin-left: 10px}
  }
  
  // &:hover {
  //   .icon { 
  //     // opacity: 1;
  //   }
  // }
}

@keyframes pop {
  0%  {transform: scale(0);}
  75%  {transform: scale(1.1);}
  100%  {transform: scale(1);}
}


</style>