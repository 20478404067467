<template>
  <div class="image-spinner"><div></div></div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
  .image-spinner {
    display: block;
    width: 16px; height: 16px;
    transform: translateZ(1px);

     > div {
      display: block;
      width: 16px;
      height: 16px;
      // margin: $mg_mini;
      border-radius: 50%;
      background: $color-primary;
      animation: image-spinner 2.8s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }
  }

  @keyframes image-spinner {
    0%, 100% {
      animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
    }
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(1800deg);
      animation-timing-function: cubic-bezier(0, 0.5, 0.5, 1);
    }
    100% {
      transform: rotateY(3600deg);
    }
  }

</style>