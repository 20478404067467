import axios from 'axios'
import authHeader from './auth-header'
// import moment from 'moment'

class FormService {
  validate ( form , valid ) {
    console.log("form:", form)
    console.log("valid:", valid)

    var required = form.querySelectorAll(':required')

    const notValids = []
    const radioChecked = {}
    console.log("required:", required)
    
    required.forEach( k => {
      if ( k.type == 'radio' || k.type == 'checkbox' ) {
        radioChecked[k.name] = []
      }
    })

    required.forEach (k => {
      // console.log("k:", k)
      const value = k.value
      const nome = k.name
      const type = k.type

      console.log("value:", value)
      console.log("nome:", nome)
      console.log("type:", type)

      if ( nome ) {
        if ( type == 'radio' || type == 'checkbox' ) {
          const checked = k.checked
          console.log("checked:", checked)
          if ( checked && !radioChecked[nome].includes(nome) ) radioChecked[nome].push(nome)
          
          console.log("radioChecked:", radioChecked)

          if ( radioChecked[nome].length == 0 ) {
            valid[nome] = false
            notValids.push(k)
          } else {
            if (radioChecked[nome].includes(nome))  {
              valid[nome] = true
            }
            
          }

          console.log("notValids:", notValids)
          // console.log("checked:", k.checked)
          // if ( !value ) {
          //   notValids.push(k)
          //   this.valid[nome] = false
          //   // window.toaster.emit('open', {style: "error", message: "Preencha todos os campos obrigatórios, para criar o desconto."})
          //   // k.className += ' error'; 
          // }
        } else {

          if ( !value || value == "" ) {
            notValids.push(k)
            valid[nome] = false
            // window.toaster.emit('open', {style: "error", message: "Preencha todos os campos obrigatórios, para criar o desconto."})
            // k.className += ' error'; 
          } else {
            valid[nome] = true
          }
        }
      }
      
    })

    // console.log('notValids:', notValids)
    // console.log('notValids:', notValids)
    const response = {
      response: Object.values(valid).every(value => value),
      valids: valid
    }
    return response
    // return false
  }
  
  getNPS ( campanha ) {
    return axios.get(process.env.VUE_APP_API_URL + 'get/campanha/nps/' + campanha, { headers: authHeader() })
  }
  saveNPS ( idCampanha, data ) {
    return axios.post(process.env.VUE_APP_API_URL + 'post/nps/resposta/' + idCampanha, data, { headers: authHeader() })
  }
}

export default new FormService()
