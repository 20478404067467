<template>
  <div title="Adicionar ao Carrinho">
      <div class="AddToCart cardMode" v-if="mode ==='card'">
          <!-- <input 
            type="number" 
            inputmode="numeric"
            v-model="amount" 
            maxlength="2" 
            @focus="$event.target.select()"
            @keyup="addToCart()"
            :class="{ on:fieldOn }"
            ref="cardModeInput"
          /> -->
        <fa :icon="['fas', 'bag-shopping']" class="icon" @click="toggleCart()"></fa>
      </div>
      <div class="AddToCart pageMode" v-if="mode ==='page'" @click="toggleCart()">
        <fa :icon="['fas', 'bag-shopping']" class="icon"></fa>
        <h5>Colocar na sacola</h5> 
        <!-- <h5>Adicionar</h5>
        <input 
          type="text" 
          inputmode="numeric" 
          v-model="amount" 
          maxlength="2" 
          class="cartAmoutInput" 
          @focus="$event.target.select()"
          @keyup="addToCart()"
          ref="pageModeInput"
        />
        <h5>{{ itemTag }} ao carrinho</h5> -->
      </div>
  </div>
</template>

<script>
import LojaService from '@/services/loja.service.js'
import moment from 'moment'

export default {
  name: 'addToCart',
  props: {
    mode: {
      type: String,
      default: 'card'
    },
    product: {
      type: Object,
      default: () => {
        return {}
      }
    },
    local: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      amount: 0,
      itemTag: 'item',
      waitToSend: null,
      fieldOn: false,
      produto: null,
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
      hasAluno: false
    }
  },
  mounted () {

  },
  methods: {
    toggleCart () {
      this.fieldOn = !this.fieldOn
      this.hasAluno = this.hasAluno = this.currentUser?.alunos?.length

      console.log("produto:", this.product)

      if ( this.product ) {
        if (
          ( this.product.categoriaLivre == 0 || !this.product.categoriaLivre )  && 
          ( this.product.gradeProduto == 0 || !this.product.gradeProduto ) && 
          ( this.product.servicoextra == 0 || !this.product.servicoextra )
        ) {
          // console.log(">> não é livre nem grade.. vai direto pro carrinho")
          this.addToCart(this.product)
        } else {
          if ( this.hasAluno ) {
            // console.log(">> tem aluno")
            if ( this.aluno ) {
              // console.log(">> já selecionou o aluno")
              if ( this.product.gradeProduto == 1 ) {
                // console.log(">> tem grade")
                if ( this.product.grade ) {
                  // console.log(">> já selecionou a grade")
                } else {
                  // console.log(">> ainda não selecionou a grade")
                  this.$emit('getConfig','grade')
                }
              } else {
                //
              }
            } else {
              // console.log(">> ainda não selecionou o aluno")
              this.$emit('getConfig','aluno')
            }
          } else {
            // console.log(">> não tem aluno")
            this.$emit('getConfig','grade')
          }
        }
        

        // if ( this.product.gradeProduto == 1 || this.product.servicoExtra == 1 || this.product.servicoextra == 1 ) {
        //   console.log("tem grade")
        //   if ( this.fieldOn ) {
        //     if ( this.product.servicoExtra == 1 || this.product.servicoextra == 1  ) {
        //       console.log("get aluno...")
        //       this.$emit('getAluno')
        //     } else {
        //       this.$emit('getConfig')
        //       console.log("get config...")
        //     }
        //   } else{
        //     this.$emit('closeConfig')
        //   }
        // } else {
        //   console.log("não tem grade")
        //   if ( this.product.comprasMultiplas ) {
        //     // console.log("compras múltiplas")
        //     //
        //   } else {
        //     console.log("compra simples")
        //     this.addToCart(this.product)
        //   }
          
        //   // this.product.idGradeProduto
        // }
      }
      
      
    },
    addToCart ( item ) {
      console.log("add product to cart:", item)
      
      item.quantidade = item.quantidade ? item.quantidade : 1
      const quantidade = item.quantidade
      const composicao = item.selectedSize
      
      // delete item.quantidade
      delete item.selectedSize

      let selecao
      
      if ( item.gradeProduto == 1 || item.servicoextra == 1 ) {
        // console.log("tem grade e extra")
        selecao = [{
          quantidade: quantidade,
          composicao: composicao
        }]
      } else {
        // console.log("não tem grade ou extra")
        if ( item.comprasMultiplas == 1 ) {
          // console.log("compras multiplas")
          selecao = [{
            quantidade: quantidade
          }]
        } else {
          // console.log("compra simples")
          selecao = []
        }
      }


      const itemObj = {
        timestamp: moment(),
        produto: item,
        selecao: selecao,
      }
      
      const updateCart = LojaService.updateCart('add', itemObj, this.currentUser.idPessoa)

      if ( updateCart.status ) {
        // window.toaster.emit('open', {style: "success", message: "Produto(s) inserido(s) no carrinho com sucesso.", floater: true})
        window.cart.emit("open", true)
        
      } else {
        window.toaster.emit('open', {style: "error", message: updateCart.message, floater: true})
      }
    },
    resetCartButton () {
      this.fieldOn = false
    }
  }
}
</script>

<style lang="scss" scoped>
  
  .AddToCart {
    display: flex; align-content: center; align-items: center;

    input {
      width: 25px;
      border: 0; padding: 0 6px 0 0; margin: 0;
      text-align: right; font-size: 16px; font-weight: bold;
      appearance: none; color: $color-text;
      line-height: 1;
      -webkit-appearance: none;

      &:focus {
        border: 0; outline: 0; box-shadow: none;
      }

      &.cartAmoutInput {
        width: 50px; height: 50px;
        border-radius: $border-radius;
        background-color: $color-light;
        font-size: 30px; line-height: 1;
        padding: 0; text-align: center
      }
    }

    &.cardMode {
      .icon {
        font-size: 24px;
        cursor: pointer;
        @extend %transition_3o;

        &:hover {
          color: $color-primary;
        }
      }

      input {
        transform: translateX(10px); opacity: 0;
        pointer-events: none;

        &.on {
          transform: translateX(0); opacity: 1;
          pointer-events: all;
        }
      }
    }

    &.pageMode {
      display: flex; line-height: 1; padding: 0; margin: $mg 0 $mg 0;
      align-content: center; align-items: center;
      cursor: pointer;

      h5 {
        margin: 0 $hmg_small;
      }
      .icon {
        font-size: 24px;
      }
    }

  }
</style>