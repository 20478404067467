<template>
  <section>
    <header>
      <h5>
        Filtrar
      </h5>
    </header>
    <div class="frame fixed">
      <div class="row no-padd" ref="produtoFilterForm">
        <div class="col-md-6 form-group">
          <label>Unidade</label>
          <select-control v-model="filter.unidade" name="unidade" :options="options.unidades" placeholder="Selecione" @change="getPeriodosLetivos()" :disabled="loading"/>
        </div>
        <div class="col-md-4 form-group">
          <label>Tipo de Curso</label>
          <select-control v-model="filter.tipoCurso" name="tipoCurso" :options="options.tipoCurso" placeholder="Selecione" :disabled="loading" @change="clearFilter('tipoCurso')"/>
        </div>
        <div class="col-md-4 form-group">
          <label>Período Letivo</label>
          <select-control v-model="filter.periodoLetivo" :options="options.periodosLetivos" placeholder="Selecione" @change="clearFilter('periodoLetivo')" :disabled="loading"/>
        </div>
        <div class="col-md-4 form-group">
          <label>Curso</label>
          <select-control v-model="filter.cursos" :options="options.cursos" placeholder="Selecione" :disabled="loading" @change="clearFilter('curso'); selectCurso(); "/>
        </div>
      </div>
      <div class="col-md-12 form-actions form-group">
        <a href="#" @click.prevent="dropFilter()" class="btn btn-primary">
          Buscar
        </a>
        <a href="#" @click.prevent="clearFilter()" class="btn btn-secondary">
          Limpar
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import UserService from '@/services/user.service.js'
import AcademicoService from '@/services/academico.service.js'
import { createSelectOptions } from '@/services/utils.service.js'

export default {
  props: {
    filterScope: {
      type: Object
    }
  },
  data () {
    return {
      filter: {
        unidade: null,
        periodoLetivo: null,
        tipoCurso: null,
        cursos: null,
        series: null
      },
      wait: {
        unidades: false,
        tipoCurso: false,
        cursos: false
      },
      options: {
        unidades: [],
        tipoCurso: [],
        periodosLetivos: [],
        series: [],
        turmas: [],
        cursos: [],
      },
      series: [],
      drop: [],
      loading: false
    }
  },
  mounted () {
    this.getUnidades()
    this.getTipoCurso()
    this.getCursos()

    if ( this.filterScope ) {
      console.log("filterScope:", this.filterScope)

      this.filter = Object.assign({}, this.filterScope)
      this.getPeriodosLetivos()
    }
    window.spinner.emit("open")
  },
  methods: {
    getUnidades () {
      UserService.getMinhasUnidades().then(
        response => {
          console.log("getMinhasUnidades:", response.data)          
          if ( response.data.length > 0 ) {
            this.options.unidades = createSelectOptions(response.data, 'idUnidade' ,'descricao')
            this.wait.unidades = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhuma unidade encontrada. Verifique sua conexão e tente novamente.", floater: true})
          }
          
          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma unidade encontrada. Verifique sua conexão e tente novamente.", floater: true})
        }
      )
    },
    getTipoCurso () {
      AcademicoService.getTipoCurso().then(
        response => {
          console.log("getTipoCurso:", response.data)
          if ( response.data.length > 0 ) {
            this.options.tipoCurso = createSelectOptions(response.data, 'idTipoCurso' ,'descricao')
            this.wait.tipoCurso = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum tipo de curso encontrado. Verifique sua conexão e tente novamente.", floater: true})
          }
          
          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum tipo de curso encontrado. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    getCursos () {
      AcademicoService.getCursos().then(
        response => {
          console.log("getCursos:", response.data)
          if ( response.data.length > 0 ) {
            this.cursos = response.data
            this.options.cursos = createSelectOptions(response.data, 'idCurso' ,'descricao')
            this.wait.cursos = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum curso encontrado. Isto parece ser um problema de parametrização.", floater: true})
          }

         
          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum curso encontrado. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    getPeriodosLetivos () {
      this.loading = true
      AcademicoService.getPeriodosLetivos(this.filter.unidade).then(
        response => {
          console.log("getPeriodosLetivos:", response.data)
          
          if ( response.data.length > 0 ) {
            this.options.periodosLetivos = createSelectOptions(response.data, 'idPeriodoLetivo' ,'descricao').reverse()
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum período letivo encontrado para esta unidade. Isto parece ser um problema de parametrização.", floater: true})
          }
          this.loading = false
        },
        error => {
          console.error(error)
          this.loading = false
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum período letivo encontrado para esta unidade. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    selectCurso () {
      const selected = this.filter.cursos
      this.loading = true
      
      const result = this.series.filter( k => {
        return k.idCurso == selected
      })

      if ( result.length > 0 ) {
        this.drop = result
      } else {
        this.options.series = []
      }
      this.loading = false
    },
    dropFilter () {
      this.$emit('onFilter', this.filter)
    },
    finishLoad () {
      const finished = !Object.values(this.wait).some( a => !a)

      if ( finished ) {
        window.spinner.emit("close")
        this.loading = false
      }
      // console.log("finished:", finished)

    },
    clearFilter ( part ) {

      if ( !part ) {
        this.filter = {
          unidade: null,
          periodoLetivo: null,
          tipoCurso: null,
          cursos: null,
          series: null
        }

        this.$emit('clear')
      } else if ( part === 'tipoCurso' ) {
        this.filter.cursos = null
        this.filter.periodoLetivo = null
        this.filter.series = null
        this.options.series = this.series
      } else if ( part === 'periodoLetivo' ) {
        this.filter.cursos = null
        this.filter.series = null
        this.options.series = this.series
      } else if ( part === 'curso' ) {
        this.filter.series = null
        this.options.series = this.series
      }
      
    }
  }
}
</script>

<style scoped lang="scss">
  header {
    margin: $hmg $hmg_small;
    padding: 0 $hmg_mini;

    h5 {
      margin: 0 0 $hmg_mini 0; padding: 0;
    }
    p {
      margin: 0; padding: 0;
    }
  }

  // .frame {
  //   padding: 0 $hmg_small
  // }
  .form-actions {
    margin-top: $hmg !important
  }
</style>