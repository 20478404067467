<template>
  <main>
    <filter-controller 
      @onSearch="runFilter"
      @onClear="clearFilter"
      @toggleActive="toggleActive"
      :loading="loading"
    ></filter-controller>
    <div>
      <!-- <a href="#" class="btn btn-primary" @click.prevent="newForm()">Adicionar novo Formulário</a> -->
      <ol class="formList">
        <li v-for="form in forms" :key="form">
          <card :listed="false">
            <div class="form-item">
              <div><b>{{ form.descricao }}</b></div>
              <div>{{ form.nome }}</div>
              <div>
                <switch-control v-model:checked="form.ativo" @change="toggleState(form)" labelOn="Ativo" labelOff="Inativo" invert></switch-control>
              </div>
              <div>
                <nav>
                  <a href="#" @click.prevent="editForm(form.idFormulario)">
                    <fa :icon="['far','pencil']" />
                  </a>
                  <a href="#" @click.prevent="">
                    <fa :icon="['far','trash-can']" />
                  </a>
                </nav>
              </div>
            </div>
          </card>
        </li>
      </ol>
    </div>
  </main>
</template>

<script>
import FilterController from "@/components/ferramentas/formularios/utilities/FilterForms.vue"
import FormulariosService from "@/services/formularios.service.js"

export default {
  components: {
    FilterController
  },
  data () {
    return {
      forms: null,
      bkp: null, 
      loading: false
    }
  },
  mounted () {
    this.getForms()
  },
  methods: {
    getForms () {
      window.spinner.emit("open")
      this.loading = true
      FormulariosService.getFormularios().then(
        response => {
          this.forms = response.data
          this.bkp = response.data

          window.spinner.emit("close")
          this.loading = false
        },
        () => {
          window.spinner.emit("close")
          this.loading = false
        }
      )
    },
    toggleState ( form ) {
      // console.log("form:", form)
      form.ativo = form.ativo ? 1 : 0
      window.spinner.emit("open")
      FormulariosService.updateFormularios( form ).then(
        response => {
          if ( response.data ) {
              this.getForms()
          }
          // window.spinner.emit("close")
        },
        () => {
          window.spinner.emit("close")
        }
      )
      
    },
    runFilter ( options ) {
      console.log("runFilter:", options)
      
      const action = options.action
      const query = options.query.toLowerCase()
      
      if ( action == 'search' ) {
        const result = this.bkp.filter( a => {
          const nome = a.descricao.toLowerCase()
          const id = a.idFormulario.toString()

          const foundName = nome.includes(query)
          const foundId = id.includes(query)

          return foundName || foundId
        })
        this.forms = result
      }
    },
    clearFilter () {
      this.forms = Object.assign([], this.bkp)
    },
    editForm ( id ) {
      window.scroll({
        top: 0,
        behavior: 'instant'
      })

      this.$nextTick(() => {
        this.$emit("lockScroll", true)
        this.$router.push({ name: "Formulários", params:{ action: 'editar', id: id }})
      })
    },
    
    toggleActive ( state ) {
      if ( state ) {
        this.forms = this.bkp.filter( a => a.ativo )
      } else {
        this.clearFilter()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  main {
    > div {
      padding: $hmg_mid
    }
  }

  .formList {
    width: 100%; height: auto;
    padding: 0; margin: 0;

    li {
      padding: 0; margin: $mg_small 0 0 0;
      list-style: none;
    }

    .form-item {
      display: grid; width: 100%;
      grid-template-columns: 2fr 2fr 1fr 2fr;
      grid-gap: $mg_mini;
      padding: $mg_mini;
      vertical-align: middle;

      > div {
        // background-color: #ff9900;
        display: flex;
        flex-direction: column;
        justify-content: center;

        >p, h6 {
          margin: 0; padding: 0;
        }
        span {
          padding: 3px $mg_mini;
          border-radius: 2em;
          color: $color-secondary;
          text-align: center;
          
          &.active {
            background-color: $color-success;
          }
          &.inactive {
            background-color: $color-alert;
          }
        }
        nav {
          display: flex; align-items: center; justify-content: flex-end;
          gap: $mg_mini;
          font-size: 16px;

          a {
            display: flex;

            &:hover {
              color: $color-primary
            }
          }
        }
      }
    }
  }
  
</style>