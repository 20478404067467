<template>
  <section>
    <header>
      <h4>
        Resultados 
        <b v-if="leads">({{ leads.length }} {{ leads.length == 1 ? 'lead' : 'leads' }})</b>
      </h4>
    </header>
    <table class="transparent-table" v-if="leads">
      <thead>
        <tr>
          <!-- <th>ID</th> -->
          <th>ID</th>
          <th>Nome</th>
          <th>Telefone</th>
          <th>Email</th>
          <th>Unidade</th>
          <th>Etapa</th>
          <th>Status</th>
          <th>Data Criação</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(lead, i) in leads" :key="lead">
          <!-- <td>{{ lead.idProspect }}</td> -->
          <td>{{ lead.idProspect }}</td>
          <td>{{ lead.nomeResponsavel }}</td>
          <td>{{ lead.numeroCelular ?? '-' }}</td>
          <td>{{ lead.email ?? '-' }}</td>
          <td>{{ lead.unidadeSigla }}</td>
          <td>{{ lead.etapa }}</td>
          <td><span :class="['pill', lead.status ]">{{ status[lead.status] ?? '-' }}</span></td>
          <td>{{ formatDate(lead.created_at) }}</td>
          <td class="table-actions">
            <ul>
              <li>
                <a href="#" @click.prevent="toggleProspect(lead)">
                  <fa :icon="['fal','pen-to-square']" />
                </a>
              </li>
              <li>
                <a href="#" @click.prevent="toggleRemoveLead(lead.idProspect, i)">
                  <fa :icon="['fal','trash-can']" />
                </a>
              </li>
              <!-- <li>
                <a href="#" @click.prevent="toggleWin(lead.idProspect, i)" class="btn btn-primary btn-small" :disabled="lead.idFormLeadEtapa != 3 && !lead.cpfResponsavel && lead.status != 'won'">
                  Ganhar Lead
                </a>
              </li> -->
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import { formatDate } from '@/services/utils.service.js'
import CaptacaoService from '@/services/captacao.service.js'

export default {
  components: {
    
  },
  props: {
    scope: {
      type: [Array]
    }
  },
  watch: {
    scope ( value ) {
      this.leads = value
    }
  },
  data () {
    return  {
      leads: null,
      status: {
        open: 'Em Aberto',
        won: 'Ganho',
        lost: 'Perdido'
      }
    }
  },
  mounted () {
    this.leads = this.scope
  },
  methods: {
    formatDate ( date ) {
      return formatDate(date)
    },
    toggleProspect ( lead ) {
      this.$router.push({ name: 'Leads', params: { action: 'ver', idProspect: lead.idProspect }})
    },
    toggleRemoveLead ( lead, index ) {
      console.log("lead:", lead)

      this.$refs.confirm.run({
        message: "Tem certeza que deseja remover este prospect?",
        confirmButton: "Sim",
        denyButton: "Não",
        data: {
          lead, index
        },
        callback: this.deleteLead,
      })
    },
    deleteLead ( response, data ) {
      console.log("data:", data)

      if ( response ) {
        CaptacaoService.deleteProspect( data.lead ).then(
          response => {
            if ( response.status == 200 ) {
              window.toaster.emit('open', {style: "success", message: "Tudo certo! Prospect removido com sucesso.", floater: true})
              this.leads.splice( data.index, 1)
            }
          },
          error => {
            console.error( error )
            window.toaster.emit('open', {style: "error", message: "Ops! Algo acontece. Não foi possível remover este prospect. Por favor, tente novamente mais tarde. Se o problema persistir, procure nosso time de suporte.", floater: true})
          }
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  section {
    // border-top: $border-component;
    padding: $hmg_mid; margin-top: $hmg_mid;

    >header {
      display: flex;
      padding: $hmg_mid $mg_mini;
      color: $color-primary
    }

    table {
      .table-actions {
        text-align: right;

        ul {
          display: flex; align-items: center;
          justify-content: flex-end;
          gap: $mg_mini;
          padding: 0; margin: 0;
          line-height: 1; 
          
          li {
            list-style: none;
            font-size: 16px;
          }
        }
        
      }

      .pill {
        display: inline-flex;
        padding: $hmg_mini $mg_mini;
        border-radius: 2em;
        margin: $hmg_mini;
        color: $color-secondary;
        white-space: nowrap;

        &.open {
          background-color: $color-alert;
        }
        &.won {
          background-color: $color-success-2;
        }
        &.lost {
          background-color: $color-error;
        }
      }
    }
  }
</style>