<template>
  <div class="modal-controller" :class="{ on: on, wrapped: wrapped, blur: options.backdropColor == 'blur' }" ref="modal">
    <div class="modalContent" :class="[options.size, { fullHeight: options.fullHeight }]">
      <div :style="{ padding: options.padding }">
        <header :style="{textAlign: options.titleAlign}" v-if="options.title || options.message">
          <h2 :style="{textAlign: options.titleAlign}">{{ options.title }}</h2>
          <p v-if="options.message && options.message != ''" :style="{textAlign: options.titleAlign}">{{ options.message }}</p>
        </header>
        <div class="modalMain" v-if="on" :class="{ fullHeight: options.fullHeight }" :style="{ padding: options.padding }">
          <slot></slot>
        </div>
      </div>
    </div>
    <div 
      :class="['backdrop']" 
      v-if="options.backdrop"
      :style="{backgroundColor: options.backdropColor == 'blur' ? '' : options.backdropColor }"
      @click="options.backdropClose ? close() : $event.preventDefault()"
    >
    </div>
  </div>
</template>
<script>
export default {
  name: "modal",
  props:{
    wrapped: {
      type: Boolean
    }
  },
  data () {
    return {
      on: false,
      options: {
        title: "Modal",
        titleAlign: "center",
        message: "",
        size: "small",
        backdrop: false,
        backdropColor: "#ED1C24",
        backdropClose: false,
        onClose: null,
        fullHeight: false,
        padding: '20px'
      }
    }
  },
  mounted () {

  },
  methods: {
    open (options) {
      // console.log("open - options:", options)
      this.options = options
      this.on = true

      this.$refs.modal.scrollIntoView({ behavior: "smooth" })
    },
    close () {
      this.on = false
      if ( typeof this.options.onClose == "function" ) this.options.onClose()
      else this.$emit('onClose')
    },
  }
}
</script>
<style lang="scss" scoped>
  .modal-controller {
    width: calc(100vw - 60px) !important; height: calc(100vh - 60px) !important;
    position: fixed !important; top: 60px !important; left: 60px !important;
    z-index: 9000 !important;
    opacity: 0; pointer-events: none;
    overflow: hidden;
    
    &.blur {
      backdrop-filter: blur(10px);
    }
    
    &.wrapped {
      top: 0 !important; left: 0 !important;
      width: 100vw !important; height: 100vh !important;
    }

    .modalContent {
      display: flex; flex-direction: column;
      width: 90%; height: auto; max-height: calc(100vh - 100px);
      padding: 0; margin: auto;
      background-color: $color-secondary; 
      border-radius: $border-radius-large;
      border: $border-component;
      position: absolute; top: 50%; left: 0; right: 0;
      z-index: 9000; transition-delay: .4s;
      transform: translateY(-40%); opacity: 0;

      @extend %shadow-high-dark;
      @extend %transition_4e;
      
      @media screen and (max-width: 1024px) {
        padding: 0;
        top: 5%;
      }
      @media screen and (max-width: 440px) {
        // padding: $hmg_mid;
        // max-height: calc(100vh - 15px); 
      }
      
      &.small {
        max-width: 320px;
        overflow-y: visible !important
      }
      &.mid {
        max-width: 540px;
      }
      &.normal {
        max-width: 640px;
      }
      &.large {
        max-width: 960px;
      }
      &.full {
        max-width: 100%;
      }

      
      p {
        // text-align: center;
      }
      header {
        padding: $hmg_mid;
        border-bottom: $border-component;

        h2 {
          margin: 0; padding: 0;
          color: $color-primary;
          text-transform: capitalize;
        }
      }
      > div {
        width: 100%; height: 100%; max-height: 100%;
        overflow: hidden;
        display: flex; flex-direction: column;

        > div {
          padding: $hmg_mid;
          // &:nth-child(1) {
          //   margin-bottom: 2em;
          // }
          &:nth-child(2) {
            a {
              width: 100%;
              margin-top: 10px;
            }
          }
          &.modalMain {
            flex: 1;
            overflow-y: auto;
            @extend %scrollbar_card;
            
            &.fullHeight {
              height: 100%
            }
          }
        }
      }

      &.fullHeight {
        height: calc(100vh - 50px);

        @media screen and (max-width: 1024px) {
          height: calc(100vh - 25px); 
        }

        > div {
          overflow-y: hidden;
        }
      }
    }

    .backdrop {
      display: block; width: 100%; height: 100%;
      background-color: $color-bg; opacity: .7;
      z-index: 8999;
      border-top-left-radius: 6px;
      border-left: $border-component; border-top: $border-component;

      &.blur {
        background-color: $color-bg-transparent;
      }
    }

    &.wrapped {
      .backdrop {
        border-radius: 0;
        border: 0;
      }
    }

    &.on {
      opacity: 1; pointer-events: all;
      
      .modalContent {
        transform: translateY(-50%); 
        opacity: 1;
        top: 50%;
      }
    }
  }

</style>