<template>
  <div v-bind="$attrs" class="sizeSelectorWrap">
    <h3>{{ title[type] }}</h3>
    <div class="configController">
      <input-spinner v-if="loading" class="spinner"/>
      <!-- <flicking v-else :options="options" >
        <div
          v-for="(item,i) in items" 
          :key="item" @click="free && selectSize(item,i)" 
          :class="[
            'configItem',
            {
              blink: item.blink,
              selected: item.selected
            }
          ]">
          <span>
            {{ pessoa ? item.nome : ( extra ? ( item.detalhes ? item.detalhes : item.descricao ) : item.descricaoGrade ) }}
          </span>
          <span v-if="!extra">
            <b>{{ formatMoney(item.valorVendaGrade) }}</b>
          </span>
          <fa :icon="['fas', 'check']" class="icon" v-if="item.selected"></fa>
        </div>
      </flicking> -->
      <div class="viewport">
        <perfect-scrollbar>
          <ul v-if="!loading && items.length > 0">
            <li
              v-for="(item,i) in items" 
              :key="item" 
              @click="select(item,i)" 
              :class="[
                'configItem',
                {
                  blink: item.blink,
                  selected: item.selected
                }
              ]">
              <div>
                <span v-if="type == 'grade'">
                  {{ item.descricaoGrade }}
                </span>
                <span v-if="type == 'aluno'">
                  {{ item.nome }}
                </span>
                <span v-if="type == 'turma'">
                  {{ item.detalhes ? item.detalhes : item.descricao }}
                </span>
                <span v-if="produto.gradeProduto == 1 && type === 'grade'">
                  <b>{{ formatMoney(item.valorVendaGrade) }}</b>
                </span>
                <fa :icon="['fas', 'check']" class="icon" v-if="item.selected"></fa>
              </div>
              <div class="custom-tag" v-if="type == 'turma' && item.nceGratuito">
                <fa :icon="['fas', 'gift']" />
                <span>Disponível para voucher do complementar</span>
              </div>
            </li>
          </ul>
          <p class="emptyMessage" v-else-if="!loading && items.length == 0">Não existem itens disponíveis</p>
        </perfect-scrollbar>
      </div>
    </div>
    <a href="#" @click.prevent="goBack()" class="btGoBack">
      <fa :icon="['far', 'arrow-left-long']" class="icon"></fa>
      {{ goBackLabel() }}
    </a>
  </div>
</template>

<script>
import LojaService from '@/services/loja.service'
import { formatMoney } from '@/services/utils.service'
import InputSpinner from '@/components/utilities/InputSpinner'

export default {
  components: {
    InputSpinner
  },
  props: {
    produto: {
      type: Object,
      default: () => {
        return {}
      }
    },
    selected: {
      type: Object,
      default: () => {
        return {}
      }
    },
    type: {
      type: String,
      default: "grade"
    }
  },
  watch: {
    // selectedSize (value) {
    //   // console.log("selectedSize on change:", value)
    // }
  },
  data () {
    return {
      items: [],
      extra: false,
      pessoa: false,
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null,
      matTest: 0,
      loading: false,
      title: {
        grade: 'Qual o tamanho?',
        aluno: 'Para qual aluno?',
        turma: 'Escolha uma turma'
      }
    }
  },
  mounted () {
    console.log('this.produto:', this.produto)
    this.fetchGrid(this.produto)
    
    console.log("items:", this.items)
  },
  methods: {
    fetchGrid ( produto ) {
      // var found = false
      this.loading = true

      console.log("fetchGrid - produto:", produto)

      switch ( this.type ) {
        case 'grade':
          LojaService.getProductGrid( this.produto.idComposicaoMaterial ).then(
            response => {
              if ( response.data ) {
                this.items = response.data
                // console.log("items:", this.items)
                this.checkSelected()
                this.loading = false
              }
            },
            err => {  
              console.log("err:", err)
              this.loading = false
            }
          )
          break
        case 'aluno':
          console.log("user: ", this.currentUser)
          this.items = this.currentUser.alunos
          console.log("alunos items:", this.items)

          this.loading = false
          this.checkSelected()
          break
        case 'turma':
          console.log("this.produto.selectedAluno", this.produto.selectedAluno)
          var itemIndex = this.produto.selectedAluno.length - 1
          var matriculas = this.produto.selectedAluno[itemIndex].matriculas
          // console.log("itemIndex", itemIndex)
          // console.log("matriculas", matriculas)
          // console.log("this.matTest", this.matTest)

          if ( matriculas[this.matTest] ) {
            LojaService.getDetalhesServicoExtra( this.produto.idtipomaterial, matriculas[this.matTest].idMatricula ).then(
              response => {
                console.log("response.data - getDetalhesServicoExtra:", response.data)
                if ( response.data ) {
                  this.loading = false
                  if ( response.data.turmas && response.data.turmas.length > 0 ) {
                    this.items = response.data.turmas

                    // console.log("items:", this.items)
                    
                    this.checkSelected()
                  } else {
                    this.matTest++
                    this.fetchGrid(produto)
                  }
                }
              },
              err => {  
                console.log("err:", err)
                this.loading = false
              }
            )
          } else {
            this.loading = false
          }
          
        break
      }

      // if ( produto.servicoextra === 1 ||  produto.servicoExtra === 1) {
      //   if ( this.local.length > 0 ) {
      //       this.items = this.local
      //       this.extra = true
      //       this.free = true
      //       this.pessoa = true
      //       this.loading = false
      //   } else {
      //     if ( this.currentUser ) {
      //       const matriculas = this.produto.selectedAluno.matriculas
            
      //       if ( !found ) {

      //         LojaService.getDetalhesServicoExtra( this.produto.idtipomaterial, matriculas[this.matTest].idMatricula ).then(
      //         response => {
      //           console.log("response.data - getDetalhesServicoExtra:", response.data)
      //           if ( response.data ) {
      //             this.loading = false
      //             if ( response.data.turmas && response.data.turmas.length > 0 ) {
      //               this.free = true
      //               this.extra = true
      //               this.pessoa = false
      //               this.items = response.data.turmas

      //               console.log("items:", this.items)
                    
      //               this.checkSelected()
      //             } else {
      //               this.matTest++
      //               this.fetchGrid(produto)
      //             }
      //           }
      //         },
      //         err => {  
      //           console.log("err:", err)
      //           this.loading = false
      //         }
      //       )
      //       } else{
      //         this.loading = false
      //       }
      //     }
      //   }
        
      // } else {
      //   LojaService.getProductGrid( this.produto.idComposicaoMaterial ).then(
      //     response => {
      //       if ( response.data ) {
      //         this.free = true
      //         this.items = response.data
      //         // console.log("items:", this.items)
      //         this.checkSelected()
      //         this.loading = false
      //       }
      //     },
      //     err => {  
      //       console.log("err:", err)
      //       this.loading = false
      //     }
      //   )
      // }
    },
    clearSelection () {
      this.items.map(k => {
        k.selected = false
        k.blink = false
      })
    },
    select ( value, i ) {
      this.clearSelection()
      
      console.log("select value:", value)
      
      setTimeout(() => {
        this.items[i].blink = true
        this.loading = false

        setTimeout(() => {
          this.items[i].blink = false
          this.items[i].selected = true
          this.$emit('select', value)
        },300)
      }, 0)
      
    },
    checkSelected () {
      console.log("checkSelected:", this.produto)
      console.log("type:", this.type)

      if (  this.type == 'grade' && this.produto.selectedSize ) {
        // console.log("items:", this.items)  
        this.items.map(k => {
          if ( k.idGradeProduto === this.produto.selectedSize.idGradeProduto ) {
            k.selected = true
          }
        })
      }

      if ( this.type == 'aluno' && this.produto.selectedAluno?.length > 0 ) {
        console.log("items aluno:", this.items)

        this.items.map(k => {
          this.produto.selectedAluno.map(ka => {
            console.log("k.idPessoa:", k.idPessoa)
            console.log("ka.idPessoa:", ka.idPessoa)

            if ( k.idPessoa === ka.idPessoa ) {
              k.selected = true
            }
          })
        })

      }
    },
    goBackLabel () {
      var label = ""

      // console.log("this.type", this.type)
      if ( this.type == 'aluno' ) {
        label = 'Cancelar'
      } else if ( this.type === 'grade'|| this.type === 'turma') {
        label = 'Alterar Aluno'
      }
      
      // console.log("button label", label)

      return label
      // produto.gradeProduto && type == 'aluno' ? 'Cancelar'
    },
    goBack () {
      this.$emit('goback')
    },
    formatMoney ( value ) {
      return formatMoney(value)
    }
  }
}
</script>

<style lang="scss">

  .sizeSelectorWrap {
    width: 100%;
    h3 {text-align: center; color: $color-primary !important}

    .configController {
      width: 100%; margin: auto;
      max-width: 340px; padding: $hmg_small;

      .spinner {
        position: absolute;
        left: 0; right: 0; margin: auto;
        top: 47%; transform: translateY(-50%);
      }
      
      .viewport {
        height: 200px; width: 100%; overflow: hidden;
        padding: $hmg_small; border-radius: 30px;
        background-color: $color-light;

        .ps {
          max-height: 100%; overflow: hidden;
        }
        ul {
          display: block; width: 100%; height: auto;
          padding: 0;

          li {
            list-style: none;
          }
        }
      }
      .configItem {
        border-radius: $border-radius;
        background-color: $color-secondary;
        margin-bottom: $hmg_mini;
        width: 100%;
        overflow: hidden;

        > div {
          padding: $hmg_small;
          display: flex; align-content: center; align-items: center; justify-content: space-between;
          position: relative;

          &.custom-tag {
            background-color: $color-success-2;
            color: $color-secondary;
            padding: $mg_mini $hmg_small;
            justify-content: flex-start;
            gap: $hmg_mini
          }
        }
        

        .icon {
          position: absolute;
          right: $hmg_small; top: 50%; transform: translateY(-50%);
        }

        &.selected {
          padding-right: 35px;
        }
      }

      .blink {
        animation: blink .3s;
      }

      .selected {
        color: $color-primary;
      }

      @keyframes blink {
        0% {
            background-color: $color-primary;
            color: $color-secondary;
        }
        100% {
            background-color: $color-light;
            color: $color-text;
        }
    }
      // > ul {

      //   display: flex; align-items: flex-start; align-content: flex-start;
      //   justify-content: center;
      //   flex-wrap: wrap; padding: $hmg;

      //   li {
      //     display: flex; align-items: center; align-content: center;
      //     justify-content: center;
      //     font-size: 12px; width: 40px; height: 40px; text-align: center;
      //     list-style: none;
      //     padding: 0; background-color: $color-light; border-radius: $border-radius;
      //     margin: 2px;
      //     cursor: pointer;
      //     @extend %transition_3o;

      //     &:hover {
      //       background-color: $color-secondary;
      //       @extend %shadow-low-light
      //     }
      //   }
      // }
    }

    .btGoBack {
      display: table; margin: $hmg auto 0;
      .icon {margin-right: 3px;}
    }
  }

  .emptyMessage {
    text-align: center;
  }
  
</style>