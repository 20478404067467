import moment from 'moment'

const matriculaData = {
  Aluno: {
    Basicos: {
      idPessoa: null,
      idNacionalidade: null,
      nome: null,
      apelido: null,
      dataCadastro: null,
      dataNascimento: null,
      sexo: null,
      idEstadoCivil: null,
      idRacaCor: null,
      Falecido: null,
      idcliente: null,
      idPessoaPai: null,
      idPessoaMae: null,
      urlFoto: null,
      rescodigo: null,
      email: null,
      telefonefixo: null,
      telefonecelular: null,
      alucodigo: null,
      nomeDocumento: null,
      tipo: null,
      emailVerificado: null,
      nacionalidade: null
    },
    Endereco: {
      idEndereco: null,
      idCidade: null,
      cep: null,
      logradouro: null,
      numero: null,
      complemento: null,
      bairro: null,
      idPessoa: null,
      tipoLogradouro: null,
      idUf: null,
      nomeCidade: null,
      nomeUf: null,
      cidade: null,
      uf: null
    },
    Documento: {
      idDocumento: null,
      rg: null,
      rgEmissor: null,
      cpf: null,
      idPessoa: null,
    },
    Contato: null,
    Aluno: {
      idAluno: null,
      idPessoa: null,
      dataRegistro: null,
      rm: null,
      idUnidade: null,
      escolaAnterior: null,
      emailAcademico: null,
      google: null
    },
  },
  Filiacao: {
    mae: {
      Basicos: {
        idPessoa: null,
        idNacionalidade: null,
        nome: null,
        nomeDocumento: null,
        dataNascimento: null,
        sexo: null,
        idcliente: null,
        idPessoaPai: null,
        idPessoaMae: null,
        email: null,
        telefonefixo: null,
        telefonecelular: null,
        tipo: null
      },
      Endereco: {
        idEndereco: null,
        idCidade: null,
        cep: null,
        tipoLogradouro: null,
        logradouro: null,
        numero: null,
        complemento: null,
        bairro: null,
        idPessoa: null,
        idUf: null
      },
      Documento: {
        idDocumento: null,
        rg: null,
        rgEmissor: null,
        cpf: null,
        idPessoa: null
      },
      Responsavel: {
        idresponsavel: null,
        idPessoa: null,
        idMatricula: null,
        financeiro: null,
        pedagogico: null,
        diavencimento: null
      }
    },
    pai: {
      Basicos: {
        idPessoa: null,
        idNacionalidade: null,
        nome: null,
        nomeDocumento: null,
        dataNascimento: null,
        sexo: null,
        idcliente: null,
        idPessoaPai: null,
        idPessoaMae: null,
        email: null,
        telefonefixo: null,
        telefonecelular: null,
        tipo: null
      },
      Endereco: {
        idCidade: null,
        cep: null,
        logradouro: null,
        numero: null,
        complemento: null,
        bairro: null,
        idPessoa: null,
        idUf: null
      },
      Documento: {
        idDocumento: null,
        rg: null,
        rgEmissor: null,
        cpf: null,
        idPessoa: null
      },
      Responsavel: {
        idresponsavel: null,
        idPessoa: null,
        idMatricula: null,
        financeiro: null,
        pedagogico: null,
        diavencimento: null
      }
    },
    semPai: false
  },
  respFin: {
    Basicos: {
      idPessoa: null,
      idNacionalidade: null,
      nome: null,
      nomeDocumento: null,
      dataNascimento: null,
      sexo: null,
      idcliente: null,
      idPessoaPai: null,
      idPessoaMae: null,
      email: null,
      telefonefixo: null,
      telefonecelular: null,
      tipo: null
    },  
    Endereco: {
      idEndereco: null,
      idCidade: null,
      cep: null,
      logradouro: null,
      numero: null,
      complemento: null,
      bairro: null,
      idPessoa: null,
      idUf: null
    },
    Documento: {
      idDocumento: null,
      rg: null,
      rgEmissor: null,
      cpf: null,
      idPessoa: null
    },
    Aluno: {
      cpf: null
    },
    Responsavel: {
      idresponsavel: null,
      idPessoa: null,
      idMatricula: null,
      financeiro: null,
      pedagogico: null,
      diavencimento: null
    },
  },
  respPed: {
    Basicos: {
      idPessoa: null,
      idNacionalidade: null,
      nome: null,
      nomeDocumento: null,
      dataNascimento: null,
      sexo: null,
      idcliente: null,
      idPessoaPai: null,
      idPessoaMae: null,
      email: null,
      telefonefixo: null,
      telefonecelular: null,
      tipo: 1
    },
    Endereco: {
      idEndereco: null,
      idCidade: null,
      cep: null,
      logradouro: null,
      numero: null,
      complemento: null,
      bairro: null,
      idPessoa: null,
      idUf: null
    },
    Documento: {
      idDocumento: null,
      rg: null,
      rgEmissor: null,
      cpf: null,
      idPessoa: null
    },
    Responsavel: {
      idresponsavel: null,
      idPessoa: null,
      idMatricula: null,
      financeiro: null,
      pedagogico: null,
      diavencimento: null
    }
  },
  fichaSaude: {
    idps_saude: null,
    idPessoa: null,
    tipoSanguineo: null,
    possuiPlanoSaude: null,
    nomePlanoSaude: null,
    possuiNecessidadeEspecial: null,
    nomeNecessidadeEspecial: null,
    possuiAlergiaMedicamento: null,
    nomeAlergiaMedicamento: null,
    realizaTratamentoMedico: null,
    nomeTratamentoMedico: null,
    possuiMedicacaoRegular: null,
    nomeMedicacaoRegular: null,
    informacaoAdicional: null,
    idMatricula: null
  },
  fichaSaida: {
    autorizacaoSaida: {
      idAutorizacaoSaida: null,
      pai: null,
      mae: null,
      responsavelFinanceiro: null,
      responsavelPedagogico: null,
      outros: null,
      idTipoAutorizacaoSaida: null
    },
    autorizacaoRetirarAluno: [
      {
        nome: null,
        cpf: null,
        parentesco: null,
        telefoneResidencial: null,
        telefoneCelular: null
      }
    ],
    transporteEscolar: {
      idTransporteEscolar: null,
      usaTransporteEscolar: null,
      naoDefinido: null,
      nomeCondutor: null,
      telefoneCondutor: null,
      idAutorizacaoSaida: null
    },
    valeTransporte: {
      idValeTransporte: null,
      sptrans: null,
      bom: null,
      naoSolicitado: true,
      idMatricula: null
    }
  },
  planoPag: {
    idprecificacao: null,
    idSerie: null,
    dataInicio: null,
    dataFinal: null,
    ativo: null,
    descricao: null,
    planosPagamento: {
      idPlanoPagamento: null,
      diaVencimento1: null,
      valorParcela: null,
      numeroParcelas: null,
      descontoPlano: null,
      idTipoArrecadacao: null,
      tipoArrecadacao: null,
      idprecificacao: null,
      idTipoDesconto: null,
      idSerie: null,
      dataInicio: null,
      dataFinal: null,
      valorParcelaDesconto: null,
      valorTotalDesconto: null,
      valorTotal: null,
      mesInicio: null,
      vencimento: null
    }
  },
  matricula: {
    idMatricula: null,
    idSituacao: null,
    idSerie: null,
    idTurma: null,
    dataMatricula: null,
    primeiraMatricula: null,
    dataSituacao: null,
    matriculaDependencia: null,
    numeroChamada: null,
    numeroDependencias: null,
    observacao: null,
    idCurso: null,
    confirmada: null,
    matcodigo: null,
    rematriculavel: null,
    idAluno: null,
    idTurmaRegular: null,
    idPeriodoLetivo: null,
    idUnidade: null,
    escolaAnterior: null,
    descricaoSerie: null,
    status: null,
    serieTurno: null,
    descricaoUnidade: null,
    descricaoCurso: null,
    descricaoSituacao: null,
    MatturmasOpicionais: null,
    turmasOptativas: null,
    turmasOptativasIds: [],
    descricaoTurma: null
  }
}

const backup = Object.assign({}, matriculaData)
const matriculaIniState = matriculaData

export const matricula = {
  namespaced: true,
  state: matriculaIniState,
  mutations: {
    save ( state, steps ) {
      console.log("save - steps:", steps)
      // console.log("target:", target)

      const data = localStorage.getItem('matricula')
      const now = moment()

      var created_at
      const updated_at = now

      if ( !data ) {
        created_at = now
      } else {
        const current = JSON.parse(data)
        created_at = current.created_at
      }

      //clear active step
      const stepsToSave = []
      if ( steps ) {
        steps.map( k => {
          const item = Object.assign({}, k)
          item.active = false
          stepsToSave.push(item)
          // k.active = false
        })
      }
      
      const oldSteps = data?.steps
      const matricula = {
        created_at: created_at,
        updated_at: updated_at,
        matricula: state,
        steps: !stepsToSave && oldSteps || stepsToSave.length == 0 && oldSteps ? oldSteps : stepsToSave
      }
      
      // console.log("save - matricula:", matricula)
      const drop = JSON.stringify(matricula)
      localStorage.setItem('matricula', drop)

      return matricula
    }
  },
  actions: {
    get () {
      return Promise.resolve(matriculaData)
    },
    save ({commit, state}, steps) {
      commit('save', steps)
      console.log("usable state:", state)

      return Promise.resolve(matriculaData)
    },
    recover ( state, idMatricula ) {
      var matricula = localStorage.getItem('matricula')
      
      // console.log('localStorage matricula', state)
      if ( matricula ) {
        matricula = JSON.parse(matricula)
        // console.log("matricula:", matricula)
        const recoveredIdMatricula = matricula.matricula.matricula.idMatricula
        // console.log("recoveredIdMatricula:", recoveredIdMatricula)
        // console.log("idMatricula:", idMatricula)
        matricula = idMatricula == recoveredIdMatricula ? matricula : 'invalid'
        
        // console.log("matricula:", matricula)

      } else {
        matricula = null
      }
      
      return Promise.resolve(matricula)
    },
    clear () {
      localStorage.removeItem('matricula')
      Object.assign(matriculaData, backup)
      return Promise.resolve(true)
    }
  },
}

const descontoModel = {
  idTipoDescontoMatricula: null,
  percentual: null,
  observacao: null,
  data: null,
  datainicio: null,
  datafinal: null,
  idTipoDesconto: null,
  idMatricula: null,
  idTipoArrecadacao: null,
  descricao: null,
  acao: null
}

const descontoData = {
  descontos: []
}

const descontoIniState = descontoData
// const descBackup = Object.assign({}, descontoData)

export const desconto = {
  namespaced: true,
  state: descontoIniState,
  mutations: {

  },
  actions: {
    get () {
      return Promise.resolve(descontoData)
    },
    model () {
      return Promise.resolve(descontoModel)
    },
    clear ( state, idTipoDescontoMatricula ) {
      console.log("clear idTipoDescontoMatricula:", idTipoDescontoMatricula)
      var index = null
      descontoData.descontos.map( (k,i) => {
        console.log("k:", k)
        if ( k.idTipoDescontoMatricula == idTipoDescontoMatricula ) index = i
      })
      console.log("index:", index)

      if ( index || index == 0 ) {
        descontoData.descontos.splice(index, 1)
      }
      console.log("descontoData:", descontoData)

      return Promise.resolve(descontoData)
    }
  }
}