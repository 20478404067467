<template>
  <div class="chatWrap">
    <div class="messages frame sm" ref="messageRef">
      <div v-for="msg in messages" :key="msg.id" class="message-item" :class="{ rowReverse: msg.user_id !== userId }">
        <div class="message-contents" :class="{ marginLeft: msg.user_id !== userId, marginRight: msg.user_id === userId }">
          <div class="message-text" :class="getBackgroundClass(msg.role, msg.user_id)">
            <VueMarkdown :source="msg.content" class="markdown"/>
          </div>
        </div>
        <div class="sender" v-if="msg.role !== 'system' && msg.role == 'assistant'">
          <div class="avatar">
            <!-- <IconOpenAI /> -->
            <fa :icon="['fal', 'user-astronaut']" class="ic funcAssist" v-if="funcaoAssistant" />
            <fa :icon="['fal', 'user-robot']" class="ic" v-else />
            
          </div>
        </div>
      </div>
      <div v-if="isAIProcessing" class="loading-text">
        <input-spinner></input-spinner>
      </div>
    </div>
    <div class="inputWrap">
      <div class="frame sm">
        <div>
          <div>

          </div>
          <div  ref="inputRef" class="input" >
            <p 
              ref="content" 
              contenteditable
              @input="handleInput" 
              @keydown.ctrl.enter="handleSend" 
              @click="selectContent('content')"
              :class="{ placeholder: !track }"
            >

            </p>
            <!-- <span class="placeholder" v-if="!message"></span> -->
          </div>
          <nav>
            <a href="#" v-if="track && track.length > 200" class="btnClear" @click.prevent="clearContent">
              <fa :icon="['fal', 'broom-wide']" />
            </a>
            <a href="#" :disabled="!message || isAIProcessing" @click.prevent="handleSend" class="btnSend">
              <fa :icon="['far', 'arrow-up-right']" />
            </a>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { socket, state } from '../socket'
import VueMarkdown from 'vue-markdown-render'
// import IconOpenAI from '../components/icons/IconOpenAI.vue'
// import IconPerson from '../components/icons/IconPerson.vue'
import { getSimpleId } from '../lib/utils.js'
import inputSpinner from '@/components/utilities/InputSpinner'
import { useAppDataStore } from '../stores/appdata'
import { watchEffect } from 'vue'

export default {
  props: {
    active: {
      type: Object
    },
    funcaoAssistant: {
      type: Boolean
    }
  },
  components: {
    VueMarkdown,
    // IconOpenAI,
    // IconPerson,
    inputSpinner
  },
  data() {
    const store = useAppDataStore()
    return {
      timRef: null,
      messageRef: null,
      inputRef: null,
      userName: '',
      userId: '',
      message: '',
      isDialogShown: false,
      isAIProcessing: false,
      isConnecting: false,
      isStreaming: true,
      welcome: false,
      track: null,
      store
    }
  },
  computed: {
    messages() {
      return state.messageEvents.sort((a, b) => {
        if (a.created_at > b.created_at) return 1
        if (a.created_at < b.created_at) return -1
        return 0
      })
    }
  },
  mounted() {
    this.observe()

    console.log("funcaoAssistant:", this.funcaoAssistant)

    if ( state.connected ) {
      this.userId = this.store.id
      this.userName = this.store.name
      
    } else {
      this.handleSubmitName(localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))).nome : "Colaborador Rede Decisão")
      const new_id = getSimpleId()

      this.userId = new_id
      this.store.setId(new_id)
      this.isDialogShown = false
    }

    watchEffect(async () => {
      if (state.connectTrigger) {
        await this.$nextTick()
        socket.emit('assistant_id', { assistant_id: this.active.id })
        socket.emit('register', { user_id: this.userId, name: this.userName })
        this.isConnecting = false
      }
    })

    // watchEffect(async () => {
    //   if (state.messageTrigger) {
    //     await this.$nextTick()
    //     this.clearContent()
    //   }
    // })

    watchEffect(async () => {
      if (state.systemTrigger && state.systemTrigger.length > 0) {
        await this.$nextTick()

        const newval = state.systemTrigger[0]
        // console.log("system-trigger", newval.type, newval.data)
        switch (newval.type) {
          case 'welcome':
            this.isDialogShown = false;
            // this.inputRef.focus();
            this.welcome = true
            // this.showSystemMessage(this.userName, newval.type)
            break;
          case 'disconnect':
            this.showSystemMessage(this.userName, newval.type)
            break;
          case 'leave':
          case 'join':
            this.showSystemMessage(newval.data.name, newval.type)
            break;
          case 'ai-start':
            this.handleAIOnStart()
            break;
          case 'ai-end':
            this.handleAIOnEnd()
            break;
        }
      }
    })
  },
  methods: {
    async sendToSocket(user_message) {
      state.messageEvents.push(user_message)
      socket.emit('message', user_message)
      // this.message = ''
      this.clearContent()
    },
    async sendToStream(user_message) {
      this.isAIProcessing = true
      state.messageEvents.push(user_message)
      // this.message = ''
      this.clearContent()

      try {
        const response = await fetch(process.env.VUE_APP_REDECHAT_STREAM, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(user_message)
        })

        if (!response.ok) {
          console.log('Oops, an error occurred', response.status)
        }

        const msg_id = getSimpleId()
        let assistant_message = {
          user_id: null,
          name: 'Rede Chat',
          content: '',
          role: 'assistant',
          id: msg_id,
          created_at: Date.now()
        }
        state.messageEvents.push(assistant_message)
        const reader = response.body.getReader()
        let flag = true

        while (flag) {
          const { done, value } = await reader.read()

          if (done) {
            flag = false
            break
          }

          const text = new TextDecoder().decode(value)

          state.messageEvents = state.messageEvents.map((item) => {
            return {
              ...item,
              content: item.id === msg_id ? item.content + text : item.content
            }
          })

          // this.resetScroll()
        }
      } catch (error) {
        console.log(error.name, error.message)
      } finally {
        this.isAIProcessing = false
      }
    },
    handleSend() {
      if (this.isAIProcessing) return
      const user_message = {
        user_id: this.userId,
        name: this.userName,
        content: this.message,
        role: 'user',
        id: getSimpleId(),
        created_at: Date.now()
      }

      this.isStreaming ? this.sendToStream(user_message) : this.sendToSocket(user_message)
    },
    handleSubmitName(value) {
      this.isConnecting = true
      this.userName = value
      this.store.setName(value)

      if (state.connected) {
        socket.emit('register', { user_id: this.userId, name: this.userName })
        this.isConnecting = false
      } else {
        socket.connect()
      }
    },
    clearContent() {
      this.$nextTick(() => {
        this.track = null
        this.message = ''
        this.$refs.content.innerText = ''
      })
    },
    showSystemMessage(name, stype) {
      if ( stype == 'welcome' ) {
        console.log("state.messageEvents:", state.messageEvents)
      }
      const message_text = stype === 'welcome' ? `Olá ${name}` : stype === 'disconnect' ? `Você está desconectado do servidor` : `${name} has ${stype === 'join' ? 'entrou' : 'saiu'} no chat`
      const system_message = {
        user_id: '',
        name: 'system',
        content: message_text,
        role: 'system',
        id: getSimpleId(),
        created_at: Date.now()
      }
      state.messageEvents.push(system_message)
      // this.resetScroll()
    },
    getBackgroundClass(role, user_id) {
      return role === 'system' ? 'system' : role === 'assistant' ? 'bot' : user_id !== this.userId ? 'other' : 'user'
    },
    handleAIOnStart() {
      this.isAIProcessing = true
    },
    handleAIOnEnd() {
      this.isAIProcessing = false
    },
    handleInput( e ) {     
      const content = e.target.innerText
      this.track = content

      if ( content.length > 0 ) {
        this.$nextTick(() => {
          this.message = content
        })
      }
    },
    selectContent( ref ) {
      const el = this.$refs[ref]
      // const contentDiv = document.getElementById(ref)
      
      if (el) {
        var sel, range
        // var el = document.getElementById(id); //get element id
        if (window.getSelection && document.createRange) { //Browser compatibility
          sel = window.getSelection()

          if(sel.toString() == ''){ //no text selection
            setTimeout(function(){
              range = document.createRange(); //range object
              range.selectNodeContents(el); //sets Range
              sel.removeAllRanges(); //remove all ranges from selection
              sel.addRange(range);//add Range to a Selection.
            }, 1)
          }
        }
        
      }
    },
    observe () {
      const scrollingDiv = this.$refs.messageRef

      const observer = new MutationObserver(() => {
        scrollingDiv.scrollTop = scrollingDiv.scrollHeight
      })

      observer.observe(scrollingDiv, { childList: true, subtree: true })
    }
  }
}
</script>

<style lang="scss">
  .markdown {
    p {
      margin: 0; padding: 0 0 $mg_mini 0
    }
  }
</style>
<style scoped lang="scss">
.chatWrap {
  display: flex; flex-direction: column;
  width: 100%; height: calc(100vh - 62px - 60px);
  position: relative;
  .messages {
    flex: 1; overflow-y: auto;
    padding: 0 $hmg_mid;
    @extend %scrollbar_card_small
  }
  .inputWrap {
    margin-bottom: $mg;
    
    >.frame {
      padding: 0 $hmg_mid;

      >div {
        position: relative;
        display: flex; align-items: stretch;
        border-radius: 38px;
        min-height: 76px; max-height: 180px;
        border: $border-component;
        padding: $hmg_mid;
        background-color: $color-secondary;
      }
    }

    nav {
      display: flex; flex-direction: column;
      gap: $hmg_mid; justify-content: space-between;
    }

    .btnSend {
      display: flex; flex-direction: column;
      align-items: center; justify-content: center;
      width: 34px; height: 34px;
      border-radius: 100%;
      background-color: $color-primary;
      color: $color-secondary;
      font-size: 22px;
      // position: absolute;
      // right: $hmg_mid; bottom: $hmg_mid
    }

    .btnClear {
      display: flex; flex-direction: column;
      align-items: center; justify-content: center;
      width: 34px; height: 34px;
      border-radius: 100%;
      background-color: $color-bg;
      font-size: 18px;
      @extend %transition_4e;
    }
  }
  .input {
    padding: 0 $hmg_mid;
    font-size: 16px;
    flex: 1;
    display: flex;
    align-items: center;
    @extend %transition_4e;

    p {
      width: 100%;
      margin: 0;
      overflow: auto;
      max-height: 100%;
      padding-inline-end: $hmg_mid;
      @extend %transition_4e;
      @extend %scrollbar_card_small;

      * {
        white-space: normal;
        word-break: break-all;
        background-color: transparent !important;
        background: transparent !important
      }

      &:focus {
        outline: 0; border: 0;
      }

      &.placeholder {
        &::after {
          content: 'Digite sua mensagem...'
        }
      }
    }
  }
  .input-text {
    background-color: #efefef;
    flex-grow: 1;
  }
}
.toggle {
  position: relative;
}
.loading-text {
  padding: 6px 0;
}
.sender {
  width: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.avatar {
  width: 40px;
  height: 40px;
  margin-top: 4px;
  border-radius: 100%;
  background-color: $color-secondary;
  display: flex; flex-direction: column;
  align-items: center; justify-content: center;
  font-size: 18px;

  .funcAssist {
    color: $color-primary
  }
}
.sender-name {
  text-align: center;
}
.message-item {
  padding: 1rem;
  display: flex;
  align-items: flex-start
}
.message-item:last-child {
  padding-bottom: 1rem;
}
.message-contents {
  flex-grow: 1;
  display: flex;

  &.marginRight {
    justify-content: flex-end
  }
}
.message-text {
  background-color: $color-secondary;
  border-radius: $border-radius;
  padding: $hmg_mid $hmg_mid $mg_mini $hmg_mid;
  font-size: $font-size-large;
  max-width: 80%;

  // white-space: pre-wrap;
}
.container {
  padding-bottom: 100px;
}

.rowReverse {
  flex-direction: row-reverse;
}
.user {
  background-color: #fff;
}
.other {
  background-color: #efefef;
}
.bot {
  background-color: transparent;
}
.system {
  background-color: transparent;
  text-align: center;
  // color: #555;
}
</style>