<template>
  <controller>
    <page-header title="POC Precificação" />
  </controller>
</template>

<script>
export default {

}
</script>

<style>

</style>