<template>
  <controller :goToTopButton="true">
    <div class="page-title">
      <h3 class="text-capitalize">Conteúdos Programáticos</h3>
      <!-- <transition name="slideRightToLeft">
        <a href="#" @click.prevent="confirmSync()" class="btn btn-primary" v-if="!syncing">
          <span>Sincronizar Parâmetros</span>&nbsp;
          <fa :icon="['fas', 'cubes']"></fa>
        </a>
      </transition> -->
    </div>
    <div class="page-inner">
      <transition name="slideup-small">
        <!-- <serie v-if="novo || editar" :scope="serie" @onCancel="cancelCreateEdit()" @onSave="onSave"></serie> -->
        <filter-conteudos @onFilter="conteudos = $event" @onClear="clearFilter" v-if="!conteudoDetalhe" ref="filter"></filter-conteudos>
      </transition>
      <conteudos-programaticos v-if="conteudos && !conteudoDetalhe" @onSelect="getTopicos" :scope="conteudos"></conteudos-programaticos>
      <topicos :scope="conteudoDetalhe" v-if="conteudoDetalhe && !topicoDetalhe" @onSelect="getConteudo" @onReturn="clearFilter(true)"></topicos>
      <conteudos :scope="topicoDetalhe" v-if="topicoDetalhe" @onReturn="returnToConteudo()"></conteudos>
    </div>
  </controller>
</template>

<script>
import FilterConteudos from '@/components/solucoesescolares/conteudosprogramaticos/utilities/FilterConteudos.vue'
import ConteudosProgramaticos from '@/components/solucoesescolares/conteudosprogramaticos/form/ConteudosProgramaticos.vue'
import Topicos from '@/components/solucoesescolares/conteudosprogramaticos/form/Topicos.vue'
import Conteudos from '@/components/solucoesescolares/conteudosprogramaticos/form/Conteudos.vue'

export default {
  components: {
    FilterConteudos, ConteudosProgramaticos, Topicos, Conteudos
  },
  props: {
    conteudo: {
      type: [String, Number]
    },
    topico: {
      type: [String, Number]
    }
  },
  data () {
    return {
      conteudos: null,
      conteudoDetalhe: null,
      topicoDetalhe: null
    }
  },
  mounted () {
    if ( this.conteudo ){
      this.getTopicos(this.conteudo)
    }
  },
  methods: {
    getTopicos ( conteudo ) {
      this.conteudoDetalhe = conteudo
      this.$router.push({ name: 'Conteúdos Programáticos', params: { conteudo: conteudo } })
      console.log("getTopicos:", conteudo)
    },
    getConteudo ( topico ) {
      this.topicoDetalhe = topico
      console.log('topico:', topico)
      this.$router.push({ name: 'Conteúdos Programáticos', params: { conteudo: this.conteudo, topico: topico.idConteudo } })
    },
    clearFilter (rollBack) {
      if ( !rollBack ) this.conteudos = null
      else this.$router.push({name: 'Conteúdos Programáticos', params: { conteudo: null }})

      this.conteudoDetalhe = null
      this.topicoDetalhe = null
    },
    returnToConteudo () {
      this.topicoDetalhe = null
      this.$router.push({ name: 'Conteúdos Programáticos', params: { conteudo: this.conteudo } })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>