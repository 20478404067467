<template>
  <section>
    <template v-if="matricula">
      <div class="row no-padd">
        <div class="col-md-12 form-group no-padd-left">
          <ul class="checkbox-card-list">
            <li>
              <label class="check-control full">
                <input type="checkbox" class="check" :value="1" v-model="matricula.fichaSaida.autorizacaoSaida.mae" :disabled="action == 'ver'">
                <span>Filiação 1</span>
              </label>
            </li>
            <li>
              <label class="check-control full">
                <input type="checkbox" class="check" :value="1" v-model="matricula.fichaSaida.autorizacaoSaida.pai" :disabled="action == 'ver'">
                <span>Filiação 2</span>
              </label>
            </li>
            <li>
              <label class="check-control full">
                <input type="checkbox" class="check" :value="1" v-model="matricula.fichaSaida.autorizacaoSaida.responsavelPedagogico" :disabled="action == 'ver'">
                <span>Responsável Pedagógico</span>
              </label>
            </li>
            <li>
              <label class="check-control full">
                <input type="checkbox" class="check" :value="1" v-model="matricula.fichaSaida.autorizacaoSaida.responsavelFinanceiro" :disabled="action == 'ver'">
                <span>Responsável Financeiro</span>
              </label>
            </li>
            <li>
              <label class="check-control full">
                <input type="checkbox" class="check" v-model="matricula.fichaSaida.autorizacaoSaida.outros" @change="toggleOutros()" :disabled="action == 'ver'">
                <span>Outros</span>
              </label>
            </li>
          </ul>
        </div>
      </div>
      <template v-if="matricula.fichaSaida.autorizacaoSaida.outros">
        <h5 class="color-text">7.2.1. Outros</h5>
        <section v-if="outrosReady">
          <section class="outros" v-for="( outro, index ) in matricula.fichaSaida.autorizacaoRetirarAluno" :key="outro">
            <header v-if="action != 'ver'">
              <h6 class="color-text">Outro {{ index + 1 }}</h6>
              <a href="#" @click.prevent="removeOutros(index)" class="btn btn-secondary btn-small" v-if="index > 0">
                Remover Item &nbsp;
                <fa :icon="['fas','trash']"></fa>
              </a>
            </header>
            <div class="row no-padd" v-if="valid.outro.length > 0" :ref="'outros_' + index">
              <div class="col-md-8 form-group no-padd-left">
                <label>Nome Completo</label>
                <input-control 
                  type="text" name="nome" 
                  placeholder="Ex. João da Silva" 
                  v-model="matricula.fichaSaida.autorizacaoRetirarAluno[index].nome" 
                  :valid="valid.outro[index].nome" 
                  @keyup="valid.outro[index].nome = true" 
                  required
                  :disabled="action == 'ver'"
                />
              </div>
              <div class="col-md-4 form-group no-padd-left">
                <label>CPF</label>
                <input-control 
                  type="text" name="cpf" 
                  placeholder="000.000.000-00" 
                  v-model="matricula.fichaSaida.autorizacaoRetirarAluno[index].cpf" 
                  masking="cpf"  
                  :valid="valid.outro[index].cpf" 
                  @keyup="valid.outro[index].cpf = true"
                  required
                  :disabled="action == 'ver'"
                />
              </div>
              <div class="col-md-6 form-group no-padd-left">
                <label>Parentesco</label>
                <input-control 
                  type="text" name="parentesco" 
                  placeholder="Ex. Tio, Avó, Prima, etc.." 
                  v-model="matricula.fichaSaida.autorizacaoRetirarAluno[index].parentesco" 
                  :valid="valid.outro[index].parentesco" 
                  @keyup="valid.outro[index].parentesco = true" 
                  required
                  :disabled="action == 'ver'"
                />
              </div>
              <div class="col-md-3 form-group no-padd-left">
                <label>Telefone Residencial</label>
                <input-control 
                  type="text" name="telefoneResidencial" 
                  placeholder="+55 11 5678 7654" 
                  v-model="matricula.fichaSaida.autorizacaoRetirarAluno[index].telefoneResidencial" 
                  masking="fixo"
                  :disabled="action == 'ver'"
                />
              </div>
              <div class="col-md-3 form-group no-padd-left">
                <label>Telefone Celular</label>
                <input-control 
                  type="text" name="telefoneCelular" 
                  placeholder="+55 11 9 8765 4321" 
                  v-model="matricula.fichaSaida.autorizacaoRetirarAluno[index].telefoneCelular" 
                  masking="celular" 
                  :valid="valid.outro[index].telefoneCelular" 
                  @keyup="valid.outro[index].telefoneCelular = true"
                  required
                  :disabled="action == 'ver'"
                />
              </div>
            </div>
          </section>
        </section>
        <footer class="addOutros" v-if="action != 'ver'">
          <a href="#" @click.prevent="addOutros()" class="btn btn-primary btn-small">
            Adicionar Outros &nbsp;
            <fa :icon="['fas','plus']"></fa>
          </a>
        </footer>
      </template>
    </template>
  </section>
</template>

<script>
import FormService from '@/services/form.service'

export default {
  props: {
    action: {
      type: String
    }
  },
  data () {
    return {
      outrosReady: false,
      matricula: null,
      valid: {
        outro: []
      },
      toggleOutrosFirst: true
    }
  },
  mounted () {
    this.$store.dispatch('matricula/get').then(response => {
      console.log("matricula on mounted...:", response)

      this.matricula = response

      if ( this.matricula.fichaSaida.autorizacaoSaida.outros ) {
        this.matricula.fichaSaida.autorizacaoRetirarAluno.map ( k => {
          // console.log("k:", k)
          this.createValid(k)
        })
        this.outrosReady = true
      }
    })
  },
  methods: {
    toggleOutros () {
      setTimeout(() => {
        if ( this.toggleOutrosFirst && this.matricula.fichaSaida.autorizacaoSaida.outros ) {
          console.log("this.matricula.fichaSaida.autorizacaoRetirarAluno:", this.matricula.fichaSaida.autorizacaoRetirarAluno)
          this.createValid(this.matricula.fichaSaida.autorizacaoRetirarAluno[0])
          this.toggleOutrosFirst = false

          setTimeout (() => {
            this.outrosReady = true
          }, 500)

        } else if ( !this.matricula.fichaSaida.autorizacaoSaida.outros ) {
          this.clearValid()
        }
      }, 200)
      
      // console.log("this.outros:", this.outros)
      // console.log("autorizacao:", this.matricula.fichaSaida.autorizacaoRetirarAluno)
    },
    addOutros () {
      const opt = this.matricula.fichaSaida.autorizacaoRetirarAluno[0]
      const outro = {}

      Object.keys(opt).map( k => {
        outro[k] = null
      })
      
      this.matricula.fichaSaida.autorizacaoRetirarAluno.push(outro)
      this.createValid(outro)

      console.log("valid addOutros:", this.valid)
    },
    removeOutros ( index ) {
      this.matricula.fichaSaida.autorizacaoRetirarAluno.splice(index, 1)
      this.valid.outro.splice(index, 1)

      console.log("valid removeOutros:", this.valid)
    },
    createValid ( autorizacao ) {
      console.log("autorizacao:", autorizacao)
      const valids = {}

      Object.keys(autorizacao).map( k => {
        valids[k] = true
      })

      this.valid.outro.push(valids)

    },
    clearValid () {
      this.valid.outro = []
      this.toggleOutrosFirst = true
      this.outrosReady = false
    },
    validate () {
      const valids = []

      this.valid.outro.map( (k, i) => {
        const ref = this.$refs['outros_' + i]
        const valid = FormService.validate(ref[0], k)

        valids.push(valid.response)
      })

      console.log("valids:", valids)
      const isValid = !valids.includes(false)
      return { response: isValid, valids: valids }
      // const valid = FormService.validate(this.$refs.outros, this.valid.outro)
      // this.valid.outro = valid.valids
      // console.log('validate:', this.valid)
      // return valid
    }
  }
}
</script>

<style lang="scss" scoped>
  
  .outros {
    padding-left: $hmg;
    position: relative;
    margin-bottom: $hmg_mid;

    &::before {
      content: "";
      display: block; width: 6px; height: 100%;
      border-radius: 3px; background-color: $color-secondary;
      position: absolute; top: 0; left: 0;
    }
  }

  header {
    display: flex; align-items: center; align-content: flex-start;
    margin-bottom: $mg_mid;

    h6 {margin: 0; padding: 0 0 0 $hmg_small;}

    .btn {
      margin-left: $hmg_mid;
    }
  }

  footer {
    width: 100%;
    padding: $mg_mini $hmg;
    display: flex; justify-content: flex-end;
  }
</style>