<template>
  <section class="over-page painel">
    <template v-if="dashboard">
      <page-header :title="dash.label + ' - ' + aluno.Basicos.nome" ref="header">
        <template v-slot:side>
          <select-control 
            :options="periodosLetivos" 
            placeholder="Selecione" 
            v-model="periodoLetivo" 
            @change="runFilter('periodoLetivo')"
          />
          <a href="#" @click.prevent="goBack()" class="btn btn-secondary">
            Voltar
          </a>
        </template>
      </page-header>
    </template>
    <form-spinner v-if="!ready" />
    <iframe
      :src="dashboard"
      frameborder="0"
      width="100%"
      :height="frameHeight"
      allowtransparency
      v-if="dashboard"
      @load="loaded()"
    ></iframe>
  </section>
  
</template>

<script>
var sign = require('jwt-encode')
import FormSpinner from '@/components/utilities/FormSpinner.vue'

export default {
  components: {
    FormSpinner
  },
  props: {
    aluno: {
      type: Object
    },
    painel: {
      type: String
    },
    periodosLetivos: {
      type: Array
    },
    pl: {
      type: String
    }
  },
  data() {
    return {
      METABASE_SITE_URL: "https://dados.rededecisao.com",
      METABASE_SECRET_KEY: "ade7ff725d87baf8d7e2fbaf82ea7dba3605f8da0132f720d60f62915dc1ba39",
      dash: null,
      dashboard: null,
      dashboards: {
        frequencias: {
          label: "Frequências",
          id: 696,
          params: {
            "periodo_letivo": "2024",
            "status_matricula": "MAT",
            "email_aluno": null
          },
        },
        orientacoesestudo: {
          label: "Orientações de Estudo",
          id: 697,
          params: {
            "periodo_letivo": "2024",
            "email_aluno": null
          },
        },
        notificacoesescolares: {
          label: "Notificações Escolares",
          id: 698,
          params: {
            "periodo_letivo": "2024",
            "email_aluno": null
          },
        },
        avaliacoes: {
          label: "Avaliações",
          id: 699,
          params: {
            "periodo_letivo": "2024",
            "email_aluno": null
          },
        }
      },
      ready: false,
      frameHeight: 800,
      periodoLetivo: null
    }
  },
  mounted () {
    this.run()
    console.log("aluno", this.aluno)

    this.periodoLetivo = this.pl
  },
  methods: {
    setFrameHeight () {
      const hh = this.$refs.header
      console.log("hh:", hh)
    },
    run () {
      this.dash = this.dashboards[this.painel]

      if ( this.dash ) {
        var params = this.dash.params
        params.email_aluno = this.aluno.Aluno.emailAcademico
        params.periodo_letivo = this.periodoLetivo ?? this.pl

        const payload = {
          resource: { dashboard: this.dash.id },
          params: params,
          exp: Math.round(Date.now() / 1000) + 10 * 60,
        }

        console.log("payload:", payload)

        const token = sign(payload, this.METABASE_SECRET_KEY)
        this.dashboard = this.METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=false&titled=false"

        this.$nextTick(() => {
          this.setFrameHeight()
        })
      }
    },
    runFilter () {
      this.dashboard = null
      this.ready = false
      
      setTimeout(() => {
        this.run()
      }, 500)
      
    },
    goBack () {
      this.$emit('onGoBack')
    },
    loaded () {
      this.ready = true
    }
  }
}

</script>

<style lang="scss" scoped>
  .painel {
    display: flex;
    flex-direction: column;
    
    iframe {
      flex: 1
    }
  }
</style>