<template>
  <section :class="['card-section', { fixed: fixed }]">
    <div class="card-controller" 
      :class="[{listed: listed, active: active, alert: alert, marked: marked, hover: hover, fixed: fixed }, 'spacing_' + spacing]" 
      v-bind="$attrs" 
      :style="{ overflow: overflow }"
    >
      <div class="card-content" v-if="boxed">
        <slot></slot>
      </div>
      <slot v-else></slot>
      <div class="card-footer" v-if="actions">
        <slot v-if="actions" name="actions"></slot>
      </div>
    </div>
    <div class="card-controller sub-card" :class="{ on: subcardVisible }" v-if="subcard">
      <div class="card-content">
        <a href="#" @click.prevent="$emit('closeSub')" class="bt-card-close">
          <span></span>
          <span></span>
        </a>
        <slot name="subcard"></slot>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    boxed: {
      type: Boolean,
      default: false
    },
    listed: {
      type: Boolean,
      default: true
    },
    fixed: {
      type: Boolean,
      default: true
    },
    actions: {
      type: Boolean,
      default: false
    },
    subcard: {
      type: Boolean,
      default: false
    },
    subcardVisible: {
      type: Boolean,
      default: false
    },
    spacing: {
      type: String,
      default: "regular"
    },
    alert: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    marked: {
      type: Boolean,
      default: false
    },
    overflow: {
      type: String,
      default: 'hidden'
    },
    subcardPos: {
      type: String,
      default: '-72px'
    },
    hover: {
      type: Boolean,
      default: false
    }
  },
  watch: {

  },
  data () {
    return {
      subcardSling: 14,
      subcardDropPos: '-86px',
      cardMargin: '-43px'
    }
  },
  beforeMount () {
    this.setSubSling()
  },
  methods: {
    setSubSling () {
      const iniPos = parseFloat(this.subcardPos.replace("px",""))      
      const dropPos = iniPos - this.subcardSling
      
      this.cardMargin = ( dropPos / 2 ) + 'px'
      this.subcardDropPos = dropPos + 'px'
      
    }
  }
}
</script>
<style lang="scss">

  .card-section {
    @extend %transition_4e;
    width: 100%;

    &.fixed {
      height: 100%;
    }
  }
  .card-controller {
    width: 100%; height: auto;
    border-radius: $border-radius;
    background-color: $color-secondary;
    border: $border-component;
    position: relative; z-index: 100;
    // @extend %shadow-high;
    @extend %transition_4e;
    
    &.hover {
      &:hover {
        @extend %shadow-high;
      }
    }

    &.alert {
      background-color: $color-warning-light;
      padding: 0 !important;
      @extend %shadow-high-primary;
    }
    &.active {
      background-color: $color-active-light;
      padding: 0 !important;
      @extend %shadow-high-primary;
    }

    &.marked {
      border: 3px solid $color-primary !important
    }

    &.fixed {
      height: 100%;

      .card-content {
        height: 100%;
      }
    }
    
    &.listed {
      &.spacing_regular {
        margin-bottom: $hmg;
      }
      
      &.spacing_small {
        margin-bottom: $hmg_small;
      }
      
      &.spacing_mini {
        margin-bottom: $mg_mini;
      }
    }
    
    .card-header {
      position: relative;
      background-color: $color-secondary;
      border-bottom: $border-component;
      padding: $hmg_small;

      h1,h2,h3,h4,h5,h6 {
        padding: 0; margin: 0
      }
    }
    .card-content { padding: $hmg_mid; position: relative }
    .card-footer { 
      padding: $hmg_small $hmg_mid; 
      background-color: $color-secondary;
      border-top: $border-component;
    }

    @media screen and (max-width: 1024px) {
      .card-content { padding: $hmg_small }
      &.listed {
        margin-bottom: $hmg_small;
      }
    }

    .actions-wrap {
      display: flex; align-content: center; align-items: center;
      justify-content: space-between;
      // padding-top: 1em;

      > div {
        text-align: right;
      }
    }

    .actions {
      display: flex; align-content: center; align-items: center;
      gap: $mg_mini;

      a:not(.btn) {
        display: block;
        font-size: 16px;
        padding: 3px;

        &:hover { 
          color: $color-primary;
          transform: scale(1.05,1.05);
          // @extend %text-shadow-low-primary;
        }
      }
    }

    &.sub-card {
      position: fixed;
      z-index: 99;
      transform: translate(0, -101%);
      opacity: 0; pointer-events: none;
      @extend %invert-shadow-high;
      @extend %transition_4e;
      border: $border-component;

      &.on {
        position: relative;
        animation-name: subcardSlidein;
        animation-duration: .8s;
        animation-iteration-count: 1;
        pointer-events: all; z-index: 101;
        opacity: 1; transform: translate(11px, v-bind(subcardPos));
        margin-bottom: v-bind(cardMargin);
      }
    }

    @keyframes subcardSlidein {
      0% {
        z-index: 99;
        transform: translate(0, -101%);
        opacity: 0;
      }
      50% {
        z-index: 99;
        transform: translate(21px, -20px);
        opacity: 1;
      }
      90% {
        z-index: 101;
        transform: translate(11px, v-bind(subcardDropPos));
        opacity: 1;
      }
      100% {
        z-index: 101;
        transform: translate(11px, v-bind(subcardPos));
        opacity: 1;
      }
    }


    .card-info-icon {
      margin-right: 3px;
    }

    .bt-card-close {
      display: block; height: 24px; width: 24px;
      position: absolute; top: 16px; right: 20px;

      span {
        display: block; width: 2px; height: 16px;
        position: absolute; top: 50%; left: 0; right: 0;
        border-radius: 2px;
        margin: auto; transform-origin: top center;
        background-color: $color-text;
        @extend %transition_6e;

        &:first-child {
          transform: rotate(45deg) translateY(-50%);
        }
        &:last-child {
          transform: rotate(-45deg) translateY(-50%);
        }
      }

      &:hover {
        span {
          background-color: $color-primary;

          &:first-child {
            transform: rotate(315deg) translateY(-50%);
          }
          &:last-child {
            transform: rotate(-315deg) translateY(-50%);
          }
        }
      }
    }
  }
</style>