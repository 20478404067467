<template>
  <div class="input-spinner"><div></div><div></div><div></div><div></div></div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
  .input-spinner {
    transform: scale(.9);
    display: block;
    position: relative;
    width: 80px;
    height: 50px;
  }
  .input-spinner div {
    position: absolute;
    top: 23px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: $color-primary;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  
  .input-spinner div:nth-child(1) {
    left: 8px;
    animation: input-spinner1 0.5s infinite;
  }
  .input-spinner div:nth-child(2) {
    left: 8px;
    animation: input-spinner2 0.5s infinite;
  }
  .input-spinner div:nth-child(3) {
    left: 32px;
    animation: input-spinner2 0.5s infinite;
  }
  .input-spinner div:nth-child(4) {
    left: 56px;
    animation: input-spinner3 0.5s infinite;
  }

  @keyframes input-spinner1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes input-spinner3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes input-spinner2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }
</style>