<template>
  <controller :goToTopButton="true">
    <page-header title="Alfred">
      <template v-slot:side>
        <transition name="slideRightToLeft">
          <a href="#" @click.prevent="toggleNew()" class="btn btn-primary" v-if="!novo">
            <span>Nova Automação</span>&nbsp;
            <fa :icon="['fas', 'bolt']"></fa>
          </a>
        </transition>
      </template>
    </page-header>
    <div class="page-wrap">
      <gatilho v-if="novo || editar" class="gatilho" @save="save" @close="close" ref="gatilho"></gatilho>
      <alfred-controller ref="gatilhos" @edit="triggerEdit" @activate="save" @remove="remove" />
    </div>
  </controller>
</template>

<script>
import FerramentasService from '@/services/ferramentas.service.js'
import AlfredController from '@/components/ferramentas/alfred/template/AlfredController.vue'
import Gatilho from '@/components/ferramentas/alfred/form/Gatilho.vue'

export default {
  components: {
    AlfredController, Gatilho
  },
  data () {
    return {
      novo: false,
      editar: false
    }
  },
  mounted () {

  },
  methods: {
    toggleNew () {
      this.novo = !this.novo
      window.pagescroll.emit("lock")
    },
    refreshList () {
      this.editar = false
      this.novo = false
      setTimeout(() => {
        this.$refs.gatilhos.getGatilhosDados()
      },1000)
      
    },
    triggerEdit ( gatilho ) {
      this.editar = true
      this.novo = false
      window.pagescroll.emit("lock")
      this.$nextTick(() => {
        this.$refs.gatilho.editGatilho(gatilho)
      })
      
    },
    save ( gatilho ) {
      // if ( gatilho.loading ) delete gatilho.loading
      // if ( gatilho.departamento ) delete gatilho.departamento

      gatilho.status = gatilho.status == true ? 1 : 0

      console.log("save:", gatilho)

      FerramentasService.saveGatilhosDados(gatilho).then(
        response => {
          // console.log("saveGatilho:", response)
          if ( response.status == 200 || response.status == 201 ) {
            gatilho.loading = false
            window.toaster.emit('open', {style: "success", message: "Tudo certo! Gatilho salvo com sucesso.", floater: true})
            this.refreshList()
            window.pagescroll.emit("unlock")
          }
          window.spinner.emit("close")
          
        },
        error => {
          // console.log("error:", error)
          gatilho.loading = false
          const message = error.response.data.error ? error.response.data.error : 'Desconhecido'
          window.spinner.emit("close")
          window.toaster.emit('open', {style: "warning", message: "Ops. Algo aconteceu. Não foi possível gravar o gatilho. Err:" + message, floater: true})
        }
      )
    },
    remove ( gatilho ) {
      FerramentasService.deleteGatilho( gatilho ).then(
        response => {
          if ( response.status == 200 ) {
            window.spinner.emit("close")
            this.refreshList()
          }
        },
        error => {
          // console.log("error:", error.response.data)
          window.spinner.emit("close")
          window.toaster.emit('open', {style: "warning", message: "Ops. Algo aconteceu. Não foi possível remover o item selecionado. Erro:" + error.response.data, floater: true})
        }
      )
    },
    close () {
      this.editar = false
      this.novo = false
      window.pagescroll.emit("unlock")
    }
  }

}
</script>

<style lang="scss" scoped>
  .page-inner {
    margin-bottom: $mg_extra;

    @media screen and ( max-width: 640px) {
      padding: $hmg 0 !important;
    }

    .page-inner {
      header {
        // padding: $hmg_small $hmg 0;
      }
    }
  }

  .gatilho {
    @extend %transition_4e;
  }
</style>