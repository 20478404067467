<template>
  <section class="sidebarPanel">
		<form-spinner v-if="loading"/>
		<div class="formWrap" v-if="assistant">
			<header>
				<a href="#" @click.prevent="togglePanel" class="">
					<fa :icon="['far','arrow-left']" class="ic"></fa>
					<span>Fechar</span>
				</a>
			</header>
			<div>
				<div class="form-group">
					<input-control placeholder="Operador de Captação" label="Nome" v-model="assistant.name" @change="updateAssistant"/>
					<p>{{ assistant.id }}</p>
				</div>
				<div class="form-group">
					<textarea-control placeholder="Operador de Captação 123" label="Descrição" v-model="assistant.description" @change="updateAssistant"/>
				</div>
				<div class="form-group">
					<textarea-control label="Instruções" v-model="assistant.instructions" cssClass="large" @change="updateAssistant" pop />
				</div>
			</div>
			<div>
				<header>
					<h4>Arquivos</h4>
					<div>
						<switch-control v-model:checked="assistant.fileSearch" @change="updateAssistant" invert/>
						<a href="#" class="btn btn-small btn-component" @click.prevent="toggleFiles()">
							+ Arquivos
						</a>
					</div>
				</header>
				<div class="vector" v-if="assistant.idVectorStore">
					<span>
						<fa :icon="['far', 'database']" />
					</span>
					<div>
						<p :title="assistant.id">Vetor do Assistente {{ assistant.id }}</p>
						<p :title="assistant.idVectorStore">{{ assistant.idVectorStore  }}</p>
					</div>
				</div>
			</div>
			<div v-if="allowFuncao">
				<header>
					<h4>Funções</h4>
					<div>
						<switch-control v-model:checked="allowFuncoes" invert/>
					</div>
				</header>
				<div>
					<div class="toggleFuncoes" @click="toggleFuncoes" v-if="allowFuncoes">
						<span>
							{{ totalFuncoes == 0 ? 'Selecione' : totalFuncoes + ( totalFuncoes == 1 ? ' função' : ' funções' ) }}
						</span>
						<div class="toggleArrows">
							<fa :icon="['fas', 'chevron-up']" class="ic" />
							<fa :icon="['fas', 'chevron-down']" class="ic" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<footer>
			<a href="#" @click.prevent>
				<fa :icon="['far', 'trash-can']" />
			</a>
			<template v-if="assistant">
				<div v-if="saving">
					<span>Atualizando</span>
					<mini-spinner />
				</div>
				<div v-else>
					<p v-if="assistant.editedAt">Editado em {{ formatDateTime(assistant.editedAt) }}</p>
					<p v-else-if="assistant.createdAt">Criado em {{ formatDateTime(assistant.createdAt) }}</p>
				</div>
			</template>
		</footer>
		<modal ref="modal">
			<files :assistant="assistant" v-if="modal == 'files'"/>
			<funcoes v-if="modal == 'funcoes'" @onSave="dropFuncoes" @onCountTotal="dropTotalFuncoes" :scope="assistant.idsFuncoes"/>
		</modal>
  </section>
</template>

<script>
import FormSpinner from '@/components/utilities/FormSpinner.vue'
import Files from '@/components/ferramentas/assistentes/utilities/Files.vue'
import Funcoes from '@/components/ferramentas/assistentes/utilities/Funcoes.vue'
import Modal from "@/components/utilities/Modal"
import EstudAIService from '@/services/estudai.service.js'
import MiniSpinner from '@/components/utilities/MiniSpinner.vue'
import { formatDateTime } from '@/services/utils.service.js'
import UserService from '@/services/user.service.js'

export default {
	components: {
		FormSpinner, Files, Modal, MiniSpinner, Funcoes
	},
	props: {
		scope: {
			type: Object
		}
	},
	watch: {
		scope ( value ) {
			this.assistant = Object.assign({}, value)
			this.totalFuncoes = 0
		}
	},
	data () {
		return {
      assistant: null,
			loading: false,
			saving: false,
			allowFuncao: false,
			allowFuncoes: false,
			funcoes: [],
			modal: null,
			totalFuncoes: 0
		}
	},
	mounted () {
		this.assistant = Object.assign({}, this.scope)

		if ( this.assistant.idsFuncoes.length > 0 ) {
			this.allowFuncoes = true
			this.totalFuncoes = this.assistant.idsFuncoes.length
		}

		this.allowFuncao = UserService.filterPermissions('addFuncaoAssistant')
	},
	methods: {
		toggleFiles () {
			const options = {
        size: "mid", //small, mid, normal, large, full
        backdrop: true,
        backdropColor: "transparent",  //se o backdrop for false, não precisa
        backdropClose: true,
				padding: 0,
        onClose: this.modal = null
      }

			this.modal = 'files'
      this.$refs.modal.open(options)
		},
		togglePanel () {
			this.$emit('close')
		},
		updateAssistant () {
      this.saving = true

      EstudAIService.updateAssistant(this.assistant).then(
        response => {
          console.log('updateAssistant:', response.data)
					this.assistant = response.data
					this.saving = false
        },
        error => {
          console.error(error)
					this.saving = false
        }
      )
    },
		formatDateTime( date ) {
			return formatDateTime(date, null, true)
		},
		toggleFuncoes () {
			const options = {
        size: "mid", //small, mid, normal, large, full
        backdrop: true,
        backdropColor: "transparent",  //se o backdrop for false, não precisa
        backdropClose: true,
				padding: 0,
        onClose: this.modal = null
      }

      console.log("toggleTransfer:", options)
			this.modal = 'funcoes'
      this.$refs.modal.open(options)
		},
		dropTotalFuncoes ( value ) {
			this.totalFuncoes = value
		},
		dropFuncoes ( value ) {
			this.assistant.idsFuncoes = value.flatMap( a => a.idFuncao )
			this.$refs.modal.close()

			this.updateAssistant()
		}
	}
}
</script>

<style lang="scss" scoped>
	.sidebarPanel {
		display: flex; flex-direction: column;
		width: 100%; height: auto; height: 100%; min-width: 310px;
		border-right: $border-component;
		position: relative;

		.formWrap {
			flex: 1;
			overflow-y: auto;
			@extend %scrollbar_light_panel;
			position: relative;
			padding-top: $mg_mid;

			>div {
				border-bottom: $border-component;
				padding: 0 $hmg_mid;
				
				&:last-child {
					border-bottom: none;
				}

				p {
					padding: $hmg_mini $hmg_mid 
				}

				>div {
					margin-bottom: $hmg_mid
				}

				>header {
					display: flex; align-items: center;
					justify-content: space-between;
					padding: $hmg_mid 0 $mg_mini;

					h4 {
						margin: 0; padding: 0;
					}
					>div {
						display: flex; align-items: center;
						justify-content: flex-end;
						gap: $mg_mini
					}
				}
			}

			>header {
				position: absolute; top: 0;
				width: 100%;
				left: 0; padding: $mg_mini;
				pointer-events: none;
				display: flex; align-items: center;
				justify-content: flex-end;

				a {
					display: flex; align-items: center;
					gap: $hmg_mini;
					pointer-events: all;
					padding: $hmg_mini;
					border-radius: $border-radius-small;
					line-height: 1;
					
					.ic {
						font-size: 12px;
					}

					&:hover {
						// box-shadow: 0 1px 12px rgba(0,0,0,0.1);
						background-color: $color-secondary;
					}
				}
			}
		}

		
		footer {
			height: 60px; width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-top: $border-component;
			padding: 0 $hmg_mid;

			div {
				display: flex; align-items: center;
				justify-content: flex-end; gap: $mg_mini;

				p{
					margin: 0; padding: 0
				}
			}
		}
	}

	.vector {
		display: flex;
		align-items: center;
		gap: $mg_mini;
		margin-top: $mg_mini;

		span {
			display: flex; flex-direction: column;
			align-items: center; justify-content: center;
			width: 24px; height: 24px;
			background-color: $color-secondary;
			border-radius: $border-radius-small;
			font-size: 12px;
			flex-shrink: 0;
		}

		>div {
			flex: 1;
			position: relative;
			max-width: 90%;
		}
		p {
			display: block; 
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			margin: 0 !important; padding: 0 !important;

			&:last-child {
				font-size: 11px
			}
		}
	}

	.toggleFuncoes {
		display: flex;
		align-items: center;
		background-color: $color-secondary;
		padding: $mg_mini;
		border-radius: $border-radius;
		border: $border-component;
		margin-top: $mg_mini;

		>span {
			flex: 1;
			// padding: $mg_mini
		}
		.toggleArrows {
      display: flex; flex-direction: column;
      font-size: 8px; align-content: center;

      .ic {
        @extend %transition_3o;
      }
    }

		&:hover {
			.toggleArrows {
				.ic {
          &:first-child {
            transform: translateY(-10%)
          }
          &:last-child {
            transform: translateY(10%)
          }
        }
			}
		}
	}
	

		
</style>