<template>
  <section class="section">
    <!-- <header>
      <h4>Resultado da Busca</h4>
      <p v-if="series">{{ series.length }} {{ series.length == 1 ? 'item' : 'itens' }} encontrados</p>
    </header> -->
    <div class="planilhasListWrap" v-if="prompts"> 
      <ol class="cardList">
        <li>
          <div class="plItemInner">
            <span>
              ID
            </span>
            <span>
              Descrição
            </span>
            <span>
              Teoria (Eu)
            </span>
            <span>
              Prática (Nós)
            </span>
            <span>
              Questões (Eles)
            </span>
            <span></span>
          </div>
        </li>
        <li v-for="prompt in prompts" :key="prompt">
          <card spacing="small">
            <div class="plItemInner">
              <span>
                {{ prompt.idPromptConteudos }}
              </span>
              <span>
                {{ prompt.descricao }}
              </span>
              <span>
                <div class="wrap" :title="prompt.promptTeoria">{{ prompt.promptTeoria }}</div>
              </span>
              <span>
                <div class="wrap" :title="prompt.promptPratica">{{ prompt.promptPratica }}</div>
              </span>
              <span>
                <div class="wrap" :title="prompt.promptOrientacaoEstudos">{{ prompt.promptOrientacaoEstudos }}</div>
              </span>
              <span class="actionWrap">
                <a href="#" @click.prevent="editPrompt(prompt)">
                  <fa :icon="['far','magnifying-glass']"></fa>
                </a>
              </span>
            </div>
          </card>
        </li>
      </ol>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    scope: {
      type: Array
    }
  },
  data () {
    return {
      prompts: []
    }
  },
  mounted () {
    if ( this.scope ) {
      this.prompts = Object.assign([], this.scope)
    }
  },
  methods: {
    editPrompt ( prompt ) {
      this.$emit("onEdit", prompt)
    }
  }
}
</script>

<style lang="scss" scoped>
  .section {
    padding: $hmg $hmg_small $mg_large 
  }
  .plItemInner {
    grid-template-columns: .2fr 1fr 2fr 2fr 2fr .3fr;

    >span {
      overflow: hidden;
    }
  }

  .wrap {
    white-space: nowrap;
    overflow: hidden; text-overflow: ellipsis;
    padding-right: $mg_mini;
    max-width: 90%;
  }
</style>