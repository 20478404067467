<template>
  <section>
    <documentos :id="id" model="invert" :cancancel="true" @oncancel="close()" :modalmode="true" :validation="validation" :reenvioContrato="reenvioContrato" :matricula="matricula"></documentos>
  </section>
</template>

<script>
import Documentos from '@/components/matriculas/utilities/Documentos'
// import CharTitle from "@/components/template/CharTitle"

export default {
  components: {
    Documentos
  },
  props: {
    id: {
      type: [Number, String],
      default: null
    },
    validation: {
      type: Boolean
    },
    reenvioContrato: {
      type: Boolean
    },
    matricula: {
      type: Object
    }
  },
  mounted () {

  },
  data () {
    return {

    }
  },
  methods: {
    close() {
      this.$emit('onclose')
    }
  }
}
</script>

<style lang="scss" scoped>

</style>