<template>
  <section>
    <div>
      <header v-if="plano">
        <h3>{{ plano.descricao }}</h3>
        <!-- <p>De <b>{{ plano.competenciaInicial }}</b> a <b>{{ plano.competenciaFinal }}</b></p> -->
      </header>
      <div class="arrecadacoes">
        <table v-if="arrecadacoes" >
          <thead>
            <tr>
              <th></th>
              <th>Vencimento</th>
              <th>Valor</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(arr, i) in arrecadacoes" :key="arr">
              <td>
                {{ i + 1 }}
              </td>
              <td>
                {{ arr.vencimento1 }}
              </td>
              <td>
                {{ arr.valorPrincipal }}
              </td>
            </tr>
            <tr class="result">
              <td>Total</td>
              <td></td>
              <td>
                {{ total(arrecadacoes) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
</template>

<script>
import MatriculaService from '@/services/matricula.service.js'
import { formatMoney } from '@/services/utils.service.js'

export default {
  props: {
    idMatricula: {
      type: [Number, String]
    }
  },
  data () {
    return {
      arrecadacoes: null,
      plano: null
    }
  },
  mounted () {
    this.getMensalidades()
  },
  methods: {
    getMensalidades () {
      MatriculaService.getParcelasPreview( this.idMatricula ).then(
        response => {
          console.log('getMensalidades:', response.data)
          this.arrecadacoes = response.data.arrecadacoes
          this.plano = response.data.planoPagamentoMatricula

          window.spinner.emit("close")
        },
        () => {

        }
      )
    },
    validate () {
      return { response: true }
      // return valid
    },
    formatMoney (valor) {
      return formatMoney(valor)
    },
    total ( arr ) {
      var total = 0
      arr.map( k => {
        total += parseFloat(k.valorPrincipal.split(" ")[1].replace('.','').replace(',','.'))
      })

      return formatMoney(total)
    }
  }
}
</script>

<style lang="scss" scoped>
  header {
    padding: $hmg_small;
  }
  .arrecadacoes {
    padding: 0 $hmg_small $hmg_mid;

    table {
      width: 100%; max-width: 540px;
      border: 0; padding: 0; margin: 0;
      border-collapse: collapse;

      th {
        font-weight: bold;
        padding: $mg_mini;
        border-bottom: $border-component-double;
      }
      td {
        &:first-child {
          width: 30px;
        }
        
        padding: $mg_mini;
        border-bottom: $border-component;
        font-size: $font-size-larger;
      }

      .result {
        td {
          border-top: $border-component-double;
        }
        
        font-weight: bold;
      }
    }
    
  }
</style>